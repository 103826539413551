import React, { useEffect, useState, useRef } from 'react';
import DataTable from 'react-data-table-component';
import { isNumber, getUser, qrCodeMiniStatus, hideFirstCharters } from './../../Utils/Common';
import QRCode from './qrCode';
import AadhaarVerify from './../aadharVerify';
import { GET_DMT_EKO_BANL_LIST, GET_QR_CODE_MINI_REPORT, SAVE_QR_CODE_MINI, SAVE_QR_CODE_MINI_CHANGE_STATUS, QR_CODE_CHECK_CALLBACK_API, UPLOAD_FILE} from './../../Utils/appConstants';
import AfterSave from './../../container/afterpayment';
import PropagateLoader from "react-spinners/PropagateLoader";
import ReactSpinnerTimer from "react-spinner-timer";
import { getRequest, postRequest } from './../../Utils/AppApi';
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import { Divider, Tooltip, Modal, Button, Popconfirm, Select } from 'antd';
import { getUTCToLocalDateTimeFormat } from '../../Utils/function';
// const text = 'Are you sure to delete this data?';

function GenerateQrCode(props) {
    const MODE_SMALL = "S";
    const MODE_BIG = "B";
    const user = getUser();
    const history = useHistory();
    const limit = 10;
    const [time, setTime] = useState({ minutes: "1", seconds: "30" });
    const [intervalId, setIntervalId] = useState(null);
    const [getDataField, setDataField] = useState([]);
    const [getDataError, setDataError] = useState({});
    const [getData, setData] = useState([]);
    const [generateQRCode, setGenerateQRCode] = useState(false);
    const [outletName, setOutletName] = useState(user.data.userDetails.outletName);
    const [getTotalRecord, setTotalRecord] = useState(0);
    const [page, setPage] = useState(1);
    const [isLoading, setLoading] = useState(false);
    const [timeLoadingModal, setTimeLoadingModal] = useState(false);
    const [isKycFlag, setKycFlag] = useState(false);

    const [isModel, setModel] = useState(false);
    const [photo, setPhoto] = useState(null);
    const [isModelData, setModelData] = useState(null);
    const [bankName, setBankName] = useState('');
    // const [bankRRN, setBankRRN] = useState('');

    const [isAmount, setAmount] = useState('');
    const [isMode, setMode] = useState('');
    const [transactionId, setTransactionId] = useState('');

    const [upiString, setUpiString] = useState('');
    const [isSubmit, setSubmit] = useState(false);
    const [isSubmitValue, setSubmitValue] = useState(null);
    const [getBankListData, setBankListData] = useState([{ "value": '', "label": '-- Select Bank --' }]);
    const [responseMessage, setResponseMessage] = useState(" ");

    const amountInput = useRef(null);

    const clearFormField = () => {
        amountInput.current.value = "";
        setDataField({});
        setDataError({});
    }

    const columns = [{
        name: 'Date',
        selector: row => <><Tooltip title={row.createdAtDate}>{getUTCToLocalDateTimeFormat(row.createdAt)}</Tooltip></>,
        width: "200px"
    }, {
        name: 'Tran Id',
        selector: row => <><Tooltip title={row.transactionId}>{row.transactionId}</Tooltip></>,
    }, {
        name: 'Amount',
        selector: row => <><Tooltip title={row.amount}>{row.amount}</Tooltip></>,
    }, {
        name: 'Bank Name',
        selector: row => <><Tooltip title={row.bankName}>{row.bankName ? row.bankName : "-"}</Tooltip></>,
    }, {
        name: 'Bank RRN',
        selector: row => <><Tooltip title={row.isAdminBankRRN ? row.isAdminBankRRN : row.bankRRN}>{(row.isAdminBankRRN && row.status === 2) ? row.isAdminBankRRN : row.bankRRN}</Tooltip></>,
    }, {
        name: 'Status',
        selector: row => qrCodeMiniStatus(row.status),
    }, {
        name: 'Action',
        cell: row => <>{([1, 2, 5].includes(row.status)) ? "-" : <div onClick={() => handleChangeStatus(row)} className='icon-border border border-danger text-danger pointer'><i className="fa fa-edit" aria-hidden="true"></i></div>}</>,
    }, {
        name: 'Remark',
        selector: row => <><Tooltip title={row.rejectReason}>{row.rejectReason}</Tooltip></>,
    }]

    const onChangeValue = (event) => {
        const { name, value } = event.target;
        if (name === "amount") {
            if (value <= 2000) {
                setMode(MODE_SMALL);
            } else {
                setMode(MODE_BIG);
            }
        }
        setDataField({ ...getDataField, [name]: value });
        setAmount(value);
    } // eslint-disable-line react-hooks/exhaustive-deps

    const objectToQueryString = (obj) => {
        var str = [];
        for (var p in obj)
            if (obj.hasOwnProperty(p)) {
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
        return str.join("&");
    } // eslint-disable-line react-hooks/exhaustive-deps

    const getBankList = () => {
        getRequest(GET_DMT_EKO_BANL_LIST, history).then((response) => {
            if (response.success === true) {
                setBankListData(response.data.map((item) => {
                    return { "value": item.BANK_NAME, "label": item.BANK_NAME };
                }));
            }
        }).catch(function (error) {
            console.log(error);
        })
    }

    const params = { page: page, limit: limit };

    const getDataReports = () => {
        // if (startDate && endDate) {
        //   params.startDate = startDate ? startDate : "";
        //   params.endDate = endDate ? endDate : "";
        // }
        params.isAdmin = "false";
        params.searchStatus = "0,1,2,4";//'["0","1","4"]';
        const queryString = objectToQueryString(params);
        getRequest(`${GET_QR_CODE_MINI_REPORT}?${queryString}`, history).then((response) => {
            if (response.success === true) {
                setData(response.data);
                if (response.data.docs.length > 0) {
                    setModelData(response.data.docs[0]);
                }
                setTotalRecord(response.data.totalDocs);
                setPage(response.data.page);
            } else {
                setData([]);
                setTotalRecord(0);
                setPage(1);
            }
        }).catch(function (error) {
            console.log(error)
            // setLoading(false);
        })
    } // eslint-disable-line react-hooks/exhaustive-deps

    const handlePageChange = page => {
        setPage(page);
    }; // eslint-disable-line react-hooks/exhaustive-deps

    const FormValidate = () => {
        let formValid = true;
        let formObject = {};
        setDataError({})
        if (getDataField && getDataField.length === 0) {
            formValid = false;
        }

        if (getDataField.amount === "" || typeof getDataField.amount === "undefined") {
            formObject.amount = "The Field is required";
            formValid = false;
        }

        setDataError(formObject);
        return formValid;
    } // eslint-disable-line react-hooks/exhaustive-deps

    const FormValidate1 = () => {
        let formValid = true;
        let formObject = {};
        setDataError({})
        if (!bankName || bankName === "") {
            formObject.bankName = "The Field is required";
            formValid = false;
        }
        // if (!bankRRN || bankRRN === "") {
        //     formObject.bankRRN = "The Field is required";
        //     formValid = false;
        // } else if (bankRRN && (bankRRN.length > 12 || bankRRN.length < 9)) {
        //     formObject.bankRRN = "Bank RRN must be between 9 to 12 digits.";
        //     formValid = false;
        // }

        setDataError(formObject);
        return formValid;
    } // eslint-disable-line react-hooks/exhaustive-deps

    const closeQr = () => {
        // setModel(true);
        clearInterval(intervalId);
        setIntervalId(null);
        setGenerateQRCode(false);
        // setIfenableCheck(false);
        setTimeLoadingModal(true);
    }

    const handleCheckCallback = (lap) => {
        if (lap.isFinish) {
            // checkCallBackAPI();
            handleStatus(5);
            setTimeLoadingModal(false);
            console.log("Finished!!");
        } else {
            checkCallBackAPI();
            console.log("Running!! Lap:", lap.actualLap);
        }
    };

    const checkCallBackAPI = () => {
        const payload = {
            transactionId: transactionId,
        }
        postRequest(`${QR_CODE_CHECK_CALLBACK_API}`, payload, history).then((response) => {
            if (response.success === true) {
                // console.log("isMode-----------", isMode)
                if (isMode === MODE_SMALL) {
                    handleEdit(MODE_SMALL);
                } else {
                    setModel(true);
                }
                setTimeLoadingModal(false);
                getDataReports();
            }
            // else {
            //     handleStatus(5);
            // }
        }).catch(function (error) {
            console.log(error)
        })
    }

    const handleChangeStatus = (data) => {
        closeQr()
        setModelData(data);
        if (parseInt(data.status) === 4) {
            setBankName(data.bankName);
            // setBankRRN(data.bankRRN);
        }
    }

    const handleFileChange = (event, name) => {
        event.preventDefault();
        const data = new FormData();
        data.append('avatar', event.target.files[0], event.target.files[0].name);
        data.append('name', name);
        postRequest(UPLOAD_FILE, data, history).then((response) => {
            if (response.success === true) {
                setPhoto(response.data.fullpath);
                toast.success(response.message);
            } else {
                toast.error(response.message);
            }
        }).catch(function (error) {
            // console.log(error)
        })
    } // eslint-disable-line react-hooks/exhaustive-deps

    const handleEdit = (arg) => {
        if (arg === MODE_SMALL || FormValidate1(true)) {
            setLoading(true)
            const payload = {
                bankName: bankName,
                // bankRRN: bankRRN,
                photo: photo,
                amount: isModelData.amount,
                id: isModelData._id,
            }
            postRequest(`${SAVE_QR_CODE_MINI}`, payload, history).then((response) => {
                // console.log("response -------", response)
                if (response.success === true) {
                    setSubmit(true);
                    setSubmitValue(1);
                    setTimeLoadingModal(false);
                    setModel(false);
                    setModelData(null);
                    setDataField({});
                    setDataError({});
                    getDataReports();
                    setBankName("");
                    // setBankRRN("");
                    if(response && response.data) {
                        let { amount, bankRRN, payerName, payerVA } = response.data;
                        let message = "Transaction Amount : " +amount+ " | Bank RRN : "+bankRRN+" | Customer Name : "+payerName+" | Customer VPA : "+hideFirstCharters(payerVA, 6);
                        setResponseMessage(message);
                    }
                    
                    toast.success(response.message);
                    setKycFlag(false)
                    clearFormField();
                    window.location.reload(false);
                } else {
                    if (response.showoff === 1) {
                        setModel(true);
                        toast.error(response.message);
                    } else {
                        setSubmit(true);
                        setSubmitValue(0);
                        setModel(false);
                        setModelData(null);
                        setBankName("");
                        // setBankRRN("");
                        setResponseMessage(response.message);
                    }
                }
                setKycFlag(false);
                setLoading(false)
            }).catch(function (error) {
                console.log(error)
                setKycFlag(false);
                setLoading(false)
            })
        }
    } // eslint-disable-line react-hooks/exhaustive-deps

    const getAadharDetail = (data) => {
        // setAadharData(data);
        setKycFlag(false)
        handleSubmit1(data);
    }

    const handleSubmit = () => {
        if (FormValidate(true)) {
            if (isMode === MODE_BIG) {
                setKycFlag(true);
            } else {
                handleSubmit1();
            }
        }
    }

    const handleSubmit1 = (aadharData = null) => {
        if (FormValidate(true)) {
            setLoading(true)
            const payload = {
                amount: getDataField.amount,
                mode: isMode,
                devicetype: "W",
                aadharName: aadharData && aadharData.name,
                aadharRegMobileNumber: aadharData && aadharData.aadharRegMobileNumber,
                aadharNumber: aadharData && aadharData.aadharNumber,
            }
            postRequest(SAVE_QR_CODE_MINI, payload, history).then((response) => {
                if (response.success === true) {
                    // setSubmit(true);
                    // setSubmitValue(1)
                    setTransactionId(response.transactionId);
                    setUpiString(response.upiString)
                    getDataReports();
                    setGenerateQRCode(true)
                    startTimer()
                    setDataField({});
                    setDataError({});
                    clearFormField();
                    setBankName("");
                    // setBankRRN("");
                    setResponseMessage(response.message);
                    // toast.success(response.message);
                } else {
                    if (response.showoff === 1) {
                        toast.error(response.message);
                        setDataField({});
                        setDataError({});
                    } else {
                        setSubmit(true);
                        setSubmitValue(0);
                        setModel(false);
                        setModelData(null);
                        setBankName("");
                        // setBankRRN("");
                        setResponseMessage(response.message);
                    }
                }

                setKycFlag(false);
                setLoading(false)
            }).catch(function (error) {
                // console.log(error)
                setKycFlag(false);
                setLoading(false);
            })
        }
    } // eslint-disable-line react-hooks/exhaustive-deps

    const startTimer = () => {
        clearInterval(intervalId);
        const newIntervalId = setInterval(() => {
            setTime((prevTime) => {
                if (prevTime.minutes === 0 && prevTime.seconds === 0) {
                    setGenerateQRCode(false)
                }
                let { minutes, seconds } = prevTime;
                seconds -= 1;
                if (minutes < 0) {
                    return prevTime;
                } else if (seconds < 0 && minutes !== 0) {
                    minutes -= 1;
                    seconds = 59;
                } else if (seconds < 10) {
                    seconds = '0' + seconds;
                }
                // console.log("seconds---------", seconds)
                if (minutes <= 0 && seconds <= 0) {
                    // window.location.reload();
                    setGenerateQRCode(false)
                    setTime({ minutes: "1", seconds: "30" });
                    // if (isIfenableCheck) {
                    closeQr()
                    // }
                    clearInterval(newIntervalId);
                };

                return { minutes, seconds };
            });
            // console.log("newIntervalId -------------------", newIntervalId)
        }, 1000);

        setIntervalId(newIntervalId);
    };

    const handleStatus = (status) => {
        if (status) {
            setModel(false)
            setLoading(true);
            const payload = {
                id: isModelData && isModelData._id,
                status: status,
                rejectReason: "Failed by Users"
            }
            postRequest(SAVE_QR_CODE_MINI_CHANGE_STATUS, payload, history).then((response) => {
                if (response.success === true) {
                    setTimeLoadingModal(false);
                    setModelData(null)
                    toast.success(response.message);
                } else {
                    setSubmit(true);
                    setSubmitValue(0);
                    setModel(false);
                    setModelData(null);
                    setBankName("");
                    // setBankRRN("");
                    setResponseMessage(" ");
                    // toast.error(response.message);
                }
                getDataReports();
                setLoading(false);
            }).catch(function (error) {
                // console.log(error)
                setLoading(false);
            })
        }
    }

    // useEffect(() => {
    //     const handleBeforeUnload = (event) => {
    //       // Set the status to true when the user is attempting to leave
    //       setIsLeaving(true);
    
    //       // Optionally, set a custom message (note: most browsers won't display this message)
    //       const message = "Are you sure you want to leave this page? Your changes may not be saved.";
    //       event.preventDefault();
    //       event.returnValue = message; // Required for most browsers to trigger the dialog
    
    //       return message; // Some browsers require this return value to display the dialog
    //     };
    
    //     window.addEventListener("beforeunload", handleBeforeUnload);
        
    //     // Clean up the event listener when the component is unmounted
    //     return () => {
    //       window.removeEventListener("beforeunload", handleBeforeUnload);
    //     };
    //   }, []);

    // useEffect(() => {
    //     const checkPageReload = () => {
    //         const navigationType = performance.getEntriesByType('navigation')[0].type;
    //         if (navigationType === 'reload') {
    //             handleReload();
    //             // alert(transactionId);
    //             // if(transactionId) {
    //             //     handleStatus(5);
    //             // }
    //         } else {
    //             // alert("OFF");
    //         }
    //     };

    //     checkPageReload();
    // }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        getBankList();
        getDataReports();
        setOutletName(user && user.data && user.data.userDetails && user.data.userDetails.outletName)
    }, [page]) // eslint-disable-line react-hooks/exhaustive-deps

    if (isLoading) {
        return <div className="content text-center" style={{ margin: "250px 0" }}>
            <PropagateLoader color="#5851D2" loading={isLoading} size={25} />
        </div>
    }

    return (
        <div>
            <div className="content-wrapper pt-2">
                <div className="content-header my-0 py-0">
                    <div className="container-fluid">
                        <div className="row mb-0 align-items-center">
                            <div className="col-sm-6">
                                <h1 className='page-title text-blue font-merriweather'>UPI QR <span className='text-red'>Mini</span></h1>
                            </div>
                        </div>
                    </div>
                </div>
                {timeLoadingModal && <Modal closable={false} title={""} width={600} open={timeLoadingModal} footer={null}>
                    <div className="text-center">
                        <ReactSpinnerTimer
                            timeInSeconds={10}
                            totalLaps={6}
                            isRefresh={false}
                            onLapInteraction={handleCheckCallback}
                            isPause={false}
                        />
                        <p>Please do not reload the page or shut down your computer</p>
                    </div>
                </Modal>}

                <div className="content">
                    <div className="container-fluid pt-2 pb-1">
                        <div className="row">
                            <div className="col-md-12">
                                <div className='card rounded-0'>
                                    <div className='card-body'>
                                        <div className='col-md-12'>
                                            <div className='row'>
                                                <div className='col-sm-6 col-md-4 col-lg-3'>
                                                    <div className="form-group">
                                                        <label>Amount</label>
                                                        <input type="text" className={`form-control normal-light ${getDataError && getDataError.amount ? "is-invalid" : ""}`} name="amount" ref={amountInput} maxLength={6} onChange={(e) => onChangeValue(e)} onKeyDown={isNumber} placeholder="Enter Amount" value={getDataField && getDataField.amount} />
                                                        {getDataError && getDataError.amount && <span className="error text-danger">{getDataError.amount}</span>}
                                                    </div>
                                                </div>
                                                <div className='col-sm-6 col-md-4 col-lg-2'>
                                                    <label className='d-none d-lg-block'>&nbsp;</label>
                                                    <div className="form-group text-lg-center">
                                                        <button type="submit" className="btn btn-primary rounded-pill pl-4 pr-4" onClick={() => handleSubmit()}>Generate QR</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <br />
                                        <p className="text-danger">
                                            <div><strong>*. UPI उपयोगकर्ता/ग्राहक को 2000 रुपये से कम के एक से अधिक लेनदेन की अनुमति नहीं है</strong></div>
                                            <div><strong>*. UPI User/Customer is not allowed to do Multiple transactions less then Rs. 2000.</strong></div>
                                        </p>
                                        <Divider />
                                        <div className="content-header my-0 px-0">
                                            <h1 className='page-title text-blue font-merriweather mb-3'>Transaction <span className='text-red'>List</span></h1>
                                        </div>

                                        <div className='history-table'>
                                            <div className="container-fluid pb-5">
                                                <div className="col-md-12">
                                                    <DataTable
                                                        title={""}
                                                        columns={columns}
                                                        data={getData.docs}
                                                        highlightOnHover
                                                        pagination
                                                        paginationServer
                                                        paginationTotalRows={getTotalRecord}
                                                        onChangeRowsPerPage={getDataReports}
                                                        onChangePage={handlePageChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer position="top-center" autoClose={5000} limit={2} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="colored" />
            </div>

            {isSubmit ? <Modal title={""} width={1000} open={isSubmit} onCancel={() => {setSubmit(false); setResponseMessage(" ")}} footer={null}><AfterSave status={isSubmitValue} message={responseMessage} /></Modal> : ""}

            {generateQRCode && <Modal title={outletName} width={600} open={generateQRCode} onCancel={() => { closeQr(); }} footer={null}>
                <>
                    <div className='row'>
                        <div className='col-12'>
                            <QRCode upiString={upiString} amount={isAmount} time={time} />
                        </div>
                    </div>
                </>
            </Modal>}

            {isModel && <Modal title={"Update Detail"} width={600} open={isModel} footer={null}>
                <>
                    <div className='row'>
                        <div className='col-md-12 form-group'>
                            <label>Bank Name: </label>
                            <Select options={getBankListData} showSearch className={`normal-light w-100`} classNamePrefix="select" placeholder="Select Bank" onChange={(e) => setBankName(e)} />
                            {getDataError && getDataError.bankName && <span className="error text-danger">{getDataError.bankName}</span>}
                            {/* <input type={'text'} className="form-group form-control" onChange={(e) => setBankName(e.target.value)} maxLength={30} value={bankName} />
                            {getDataError && getDataError.bankName && <span className="error text-danger">{getDataError.bankName}</span>} */}
                        </div>
                        {/* <div className='col-md-12 form-group'>
                            <label>Bank RRN / UTR Number: </label>
                            <input type={'text'} className="form-group form-control" onChange={(e) => setBankRRN(e.target.value)} maxLength={12} value={bankRRN} />
                            {getDataError && getDataError.bankRRN && <span className="error text-danger">{getDataError.bankRRN}</span>}
                        </div> */}
                        <div className='col-md-12 form-group'>
                            <label>Customer Photo With Payment success Mobile Screen: </label>
                            <input type={'file'} className="ml-5 border-0" onChange={(event) => handleFileChange(event, "qrcode")} accept="image/png, image/jpeg, image/jpg" />
                        </div>
                        <div className='col-md-12 form-group text-right'>
                            <Popconfirm placement="top" title={"Are you sure want to failed this transaction?"} onConfirm={() => handleStatus(5)} okText="Yes" cancelText="No"><Button className="bg-default pointer mr-2" >Cancel</Button></Popconfirm>
                            <Button className="bg-primary pointer" onClick={() => handleEdit()}>Submit</Button>
                        </div>
                    </div>
                </>
            </Modal>}
            {isKycFlag && <AadhaarVerify aadharDetail={getAadharDetail} policyHindi={"प्रिय SPRAY Partner / USER यह भुगतान ग्राहक KYC दस्तावेज प्रस्तुत करने के अधीन है। भुगतान KYC सत्यापन के बाद आपके वॉलेट में क्रेडिट होगा।"} policyEng={false} addtional={"प्रिय SPRAY Partner / USER यह भुगतान ग्राहक KYC दस्तावेज प्रस्तुत करने के अधीन है। भुगतान KYC सत्यापन के बाद आपके वॉलेट में क्रेडिट होगा।"} />}
        </div>
    )
}

export default GenerateQrCode;