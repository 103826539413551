import React, { useEffect, useState } from "react"
import BeneficiaryList from './beneficiaryList';
import PropagateLoader from "react-spinners/PropagateLoader";
import { INRFormat } from '../../Utils/Common';
// import { useHistory } from "react-router-dom";

function ViewCustomer(props) {
    const [customerData, setCustomerData] = useState(null);
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        setCustomerData(props.isCustomerInfo);
        setLoading(false)
    }, [props.isCustomerInfo]) // eslint-disable-line react-hooks/exhaustive-deps

    if (isLoading) {
        return <div className="content text-center" style={{ margin: "250px 0" }}>
            <PropagateLoader color="#5851D2" loading={isLoading} size={25} />
        </div>
    }

    return (
        <section>
            <div>
                <div className="row d-flex align-items-center mh-100">
                    <div className="col p-0 col-xl-12">
                        <div className="card" style={{ borderRadius: 15 }}>
                            <div className="card-body m-0 d-flex">
                                {customerData && <>
                                    <div className="col-md-6">
                                        {/* <h3 className="mb-3">{customerData.customerName}</h3> */}
                                        <p className="mb-0 fx-14 text-dark">
                                            <i className="fas fa-star fa-lg text-warning" />
                                            <span className="mx-2">|</span>
                                            Mobile : <strong>{customerData.customerMobile}</strong>
                                            {<><span className="mx-2">|</span>
                                                Available Limit : <strong>{INRFormat(customerData.availableLimit)}</strong></>}
                                        </p>
                                    </div>
                                    <hr className="my-2" /></>}
                            </div>
                            {customerData && customerData.isVerified && <BeneficiaryList customerData={customerData} />}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ViewCustomer;