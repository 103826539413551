import React, { useEffect, useState, useRef } from 'react';
import { CSVLink } from 'react-csv';
import DataTable from 'react-data-table-component';
import { GET_QR_CODE_MINI_REPORT } from './../../Utils/appConstants';
import { hideFirstCharters, qrCodeMiniStatus } from './../../Utils/Common';
import { getRequest } from './../../Utils/AppApi';
import { useHistory } from "react-router-dom";
import PropagateLoader from "react-spinners/PropagateLoader";
import { DatePicker, Tooltip } from 'antd';
import { getUTCToLocalDateTimeFormat, toDayDate, queryDate, getExportFormat } from '../../Utils/function';

function History() {
    const history = useHistory();
    const csvLink = useRef();
    const limit = 10;
    const dateFormat = 'DD-MM-YYYY';
    const [searchTransId, setSearchTransId] = useState(null);

    const [endDate, setEndDate] = useState(toDayDate());
    const [startDate, setStartDate] = useState(toDayDate());
    const [getData, setData] = useState([]);
    const [getCsvData, setCsvData] = useState([]);
    const [getTotalRecord, setTotalRecord] = useState(0);
    const [page, setPage] = useState(1);
    const [isLimit, setLimit] = useState(limit);
    const [isLoading, setLoading] = useState(false);

    const columns = [{
        name: 'Date',
        selector: row => <><Tooltip title={row.createdAtDate}>{getUTCToLocalDateTimeFormat(row.createdAt)}</Tooltip></>,
        width: "200px"
    }, {
        name: 'Tran Id',
        selector: row => <><Tooltip title={row.transactionId}>{row.transactionId}</Tooltip></>,
        width: "180px"
    }, {
        name: 'VPA',
        selector: row => hideFirstCharters(row.payerVA, 6),
        width: "200px"
    }, {
        name: 'Amount',
        selector: row => <><Tooltip title={row.amount}>{row.amount}</Tooltip></>,
    }, {
        name: 'Bank Name',
        selector: row => <><Tooltip title={row.bankName}>{row.bankName ? row.bankName : "-"}</Tooltip></>,
        width: "150px"
    }, {
    //     name: 'User Bank RRN',
    //     selector: row => <><Tooltip title={row.bankRRN}>{row.bankRRN ? row.bankRRN : "-"}</Tooltip></>,
    //     width: "150px"
    // }, {
        name: 'Bank RRN',
        selector: row => <><Tooltip title={row.isAdminBankRRN}>{row.isAdminBankRRN}</Tooltip></>,
        width: "150px"
    }, {
        name: 'Customer Name',
        selector: row => <><Tooltip title={row.payerName}>{row.payerName}</Tooltip></>,
        width: "150px"
    }, {
        name: 'Status',
        selector: row => qrCodeMiniStatus(row.status),
        width: "120px"
    }, {
        name: 'Remark',
        selector: row => <><Tooltip title={row.rejectReason}>{row.rejectReason}</Tooltip></>,
        width: "250px"
    }]

    const objectToQueryString = (obj) => {
        var str = [];
        for (var p in obj)
            if (obj.hasOwnProperty(p)) {
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
        return str.join("&");
    }

    const params = { page: page, limit: isLimit };

    const getDataReports = (data) => {
        if (data) {
            setLimit(data)
            params.limit = data;
        }
        if (startDate && endDate) {
            params.startDate = startDate ? queryDate(startDate) : "";
            params.endDate = endDate ? queryDate(endDate) : "";
        }

        params.searchTransId = searchTransId ? searchTransId : '';
        params.isAdmin = false;

        const queryString = objectToQueryString(params);
        getRequest(`${GET_QR_CODE_MINI_REPORT}?${queryString}`, history).then((response) => {
            if (response.success === true) {
                setData(response && response.data);
                setTotalRecord(response && response.data.totalDocs);
                setPage(response && response.data.page);
            } else {
                setData([]);
                setTotalRecord(0);
                setPage(1);
            }
        }).catch(function (error) {
            console.log(error)
            // setLoading(false);
        })
    } // eslint-disable-line react-hooks/exhaustive-deps

    const getCSVDataList = () => {
        let params = {}
        params.exportsCsv = true;
        if (startDate && endDate) {
            params.startDate = startDate ? queryDate(startDate) : "";
            params.endDate = endDate ? queryDate(endDate) : "";
        }

        setLoading(true);
        params.searchTransId = searchTransId ? searchTransId : '';
        params.isAdmin = false;

        const queryString = objectToQueryString(params);
        getRequest(`${GET_QR_CODE_MINI_REPORT}?${queryString}`, history).then((response) => {
            if (response.success === true) {
                let csvdataRecord = [];
                let csvdata = response.data;
                csvdata.map((e) => {
                    return csvdataRecord.push({
                        "Date Time": getExportFormat(e.createdAt),
                        "Trans ID": e.transactionId,
                        "Amount": e.amount,
                        "Bank Name": e.bankName ? e.bankName : "",
                        "Bank RRN": e.bankRRN ? e.bankRRN : "",
                        // "Bank RRN": e.isAdminBankRRN ? e.isAdminBankRRN : "",
                        "Status": qrCodeMiniStatus(e.status),
                        "Remark": e.rejectReason,
                    });
                });
                console.log("csvdataRecord ------", csvdataRecord)
                setCsvData(csvdataRecord);
                setTimeout(() => {
                    csvLink.current.link.click();
                }, 1000);;
            } else {
                setCsvData([]);
            }
            setLoading(false);
        }).catch(function (error) {
            // console.log(error)
            setLoading(false);
        })
    }

    const handlePageChange = (page, size) => {
        // console.log("-----", size)
        setPage(page);
    };

    const onChangeStartDate = (date, dateString) => {
        // console.log(date, dateString);
        setStartDate(date)
    };

    const onChangeEndDate = (date, dateString) => {
        // console.log(date, dateString);
        setEndDate(date)
    };

    const handleSearch = () => {
        getDataReports();
    }

    const handleClear = () => {
        setStartDate("");
        setEndDate("");
        setSearchTransId('');
    }

    useEffect(() => {
        getDataReports();
    }, [page]) // eslint-disable-line react-hooks/exhaustive-deps

    if (isLoading) {
        return <div className="content text-center" style={{ margin: "250px 0" }}>
            <PropagateLoader color="#5851D2" loading={isLoading} size={25} />
        </div>
    }

    return (
        <div>
            <div className="content-wrapper pt-2">
                <div className="content-header my-0 py-0">
                    <div className="container-fluid">
                        <div className="row mb-0 align-items-center">
                            <div className="col-sm-6">
                                <h1 className='page-title text-blue font-merriweather'>QR Mini  <span className='text-red'> History</span></h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content">
                    <div className="container-fluid pt-2 pb-5">
                        <div className="row">
                            <div className='card col-md-12 py-3'>
                                <div className='card-body p-0'>
                                    <div className="row">
                                        <div className='col-md-12 date-search mb-0'>
                                            <div className='row'>
                                                <div className='col-md-3 col-lg-3 d-flex'>
                                                    <div className="form-group mr-1">
                                                        <label className='date-search-label mb-0 text-blue'>From</label>
                                                        <DatePicker className="form-control form-control-border" value={startDate} format={dateFormat} onChange={onChangeStartDate} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label className='date-search-label mb-0 text-red'>To</label>
                                                        <DatePicker className="form-control form-control-border" value={endDate} format={dateFormat} onChange={onChangeEndDate} />
                                                    </div>
                                                </div>
                                                <div className='col-md-2 col-lg-2'>
                                                    <div className="form-group">
                                                        <label className='date-search-label mb-0 text-red'></label>
                                                        <input type="text" className="form-control form-control-border" placeholder="Trans ID" onChange={(e) => setSearchTransId(e.target.value)} value={searchTransId} />
                                                    </div>
                                                </div>
                                                <div className='col-md-5 col-lg-5 d-flex text-right'>
                                                    <div className='form-group col-3'>
                                                        <button type="submit" className="btn btn-primary rounded-pill pl-4 pr-4" onClick={() => handleSearch()}>Search</button>
                                                    </div>
                                                    <div className='form-group col-3'>
                                                        <button type="submit" className="btn btn-default rounded-pill px-4" onClick={() => handleClear()}>Clear</button>
                                                    </div>
                                                    <div className='form-group col-3'>
                                                        <button type="button" className="btn btn-primary mr-3" onClick={() => getCSVDataList()} ><i className="fa fa-file-excel"></i> Export</button>
                                                        <CSVLink
                                                            data={getCsvData}
                                                            filename='qrcode-mini-history'
                                                            className='hidden'
                                                            ref={csvLink}
                                                            target='_blank'
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="container-fluid pt-2 pb-5">
                                        <DataTable
                                            title={""}
                                            columns={columns}
                                            data={getData.docs}
                                            highlightOnHover
                                            pagination
                                            paginationServer
                                            paginationTotalRows={getTotalRecord}
                                            onChangeRowsPerPage={getDataReports}
                                            onChangePage={handlePageChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default History;