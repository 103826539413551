
import React, { useEffect, useState } from "react";
import { isNumber } from "../../Utils/Common";
import PropagateLoader from "react-spinners/PropagateLoader";
import { AADHAAR_CARD_SEND_OTP_CODE, CREATE_USER_AADHARCARD_OTP_VERIFY, POST_PAYOUT_CHECK_EXIST_CUSTOMER_DETAIL } from '../../Utils/appConstants';
import { useHistory } from "react-router-dom";
import { postRequest } from '../../Utils/AppApi';
import { toast } from 'react-toastify';
// import { refId } from './../../Utils/function';
// import moment from "moment";
// import Select from 'react-select';
import { Button, Modal } from "antd";
// import { event } from "jquery";

function AadharVerify(props) {
    const history = useHistory();
    const customerMobile = props && props.mobile;
    const [isLoading, setLoading] = useState(false);
    // const [verifyButton, setVerifyButton] = useState(true);
    const [isOtpModal, setOtpModal] = useState(false);
    const [isOtp, setIsOtp] = useState(false);
    const [isOtpNumber, setIsOtpNumber] = useState(null);
    const [isRefId, setIsRefId] = useState(null);
    const [getDataField, setDataField] = useState({});
    const [aadharData, setAadharData] = useState("");
    const [isCustomerVerifyModal, setCustomerVerifyModal] = useState(false);

    useEffect(() => {
        // if (isAadharNumberVerify) {
        // alert("Aadhar is verified "+ customerMobile)
        // }
    }, [])

    // const onChangeOtpValue = (value) => {
    //     setOtpCode(value);
    // }

    // const FormValidate = () => {
    //     let formValid = true;
    //     let formObject = {};
    //     setDataError({})
    //     if (getDataField && getDataField.length === 0) {
    //         formValid = false;
    //     }
    //     let array = ["aadharNumber"];

    //     array.forEach(element => {
    //         if (getDataField[element] === "" || typeof getDataField[element] === "undefined") {
    //             formObject[element] = true;
    //             formValid = false;
    //         }
    //     });

    //     if (getDataField && getDataField.aadharNumber && getDataField.aadharNumber.length !== 12) {
    //         formObject.aadharNumber = 2;
    //         formValid = false;
    //     }

    //     setDataError(formObject);
    //     return formValid;
    // }

    const onChangeValue = (event) => {
        const { name, value } = event.target;
        setDataField({ ...getDataField, [name]: value });
    }

    const aadharFormValidate = () => {
        let formValid = true;
        if (!getDataField.aadharNumber || getDataField.aadharNumber === "") {
            toast.error("Aadhaar Register Mobile Number is required");
            formValid = false;
        } else if (customerMobile.length !== 10) {
            toast.error("Aadhaar Register Mobile Number is must be 10 digit");
            formValid = false;
        } else if (!getDataField.aadharNumber || getDataField.aadharNumber === "") {
            toast.error("Aadhaar Number is required");
            formValid = false;
        }

        return formValid;
    }

    const existingCustomerDetail = async () => {
        if (getDataField && getDataField.aadharNumber && getDataField.aadharNumber.length === 12 && customerMobile) {
            setLoading(true)
            let payload = { aadharNumber: getDataField.aadharNumber, customerMobile: customerMobile }
            await postRequest(`${POST_PAYOUT_CHECK_EXIST_CUSTOMER_DETAIL}`, payload, history).then((response) => {
                if (response.success === true) {
                    handleAadharCardOtpVerify();
                    // setVerifyButton(false);
                    toast.success(response.message);
                } else {
                    // setVerifyButton(true);
                    toast.error(response.message);
                }
                setLoading(false)
            }).catch(function (error) {
                // console.log(error)
                setLoading(false)
            })
        }
    }

    const handleAadharCardOtpVerify = () => {
        if (!aadharFormValidate()) {
            return false
        }
        
        if (isOtp) {
            setLoading(true)
            const payload = { aadharcard: getDataField.aadharNumber, aadharcardOtp: isOtpNumber, ref_id: isRefId, isInstantCharge: true };
            postRequest(`${CREATE_USER_AADHARCARD_OTP_VERIFY}`, payload, history).then((response) => {
                if (response.success === true || response.alreadysuccess === true) {
                    setIsOtp(false)
                    setCustomerVerifyModal(true)
                    setAadharData(response.data)
                    setOtpModal(false)
                    setDataField({ ...getDataField, "customerName": response.data.name });
                    toast.success(response.message);
                } else {
                    if (!response.aadharNumber || !response.alreadysuccess) {
                        toast.error(response.message);
                    } else {
                        setIsOtp(false);
                        toast.error(response.message);
                    }
                }
                if(response) {
                    setLoading(false)
                }
            }).catch(function (error) {
                // console.log(error)
                setLoading(false)
            })
        } else {
            setLoading(true)
            const payload = { aadharcard: getDataField.aadharNumber, isInstantCharge: true };
            postRequest(`${AADHAAR_CARD_SEND_OTP_CODE}`, payload, history).then((response) => {
                if (response.success === true) {
                    setIsOtp(true);
                    setOtpModal(true)
                    setIsRefId(response.data.ref_id);
                    toast.success(response.message);
                } else {
                    toast.error(response.message);
                }
                setDataField({ ...getDataField, "customerMobile": customerMobile, "customerName": response.data.name });
                if(response) {
                    setLoading(false)
                }
            }).catch(function (error) {
                // console.log(error)
                setLoading(false);
            })
        }
    }

    const handleSubmit = () => {
        let data = {
            customerMobile: customerMobile,
            customerName: aadharData.name,
            customerDob: aadharData.dob,
            address: aadharData.address,
            pincode: aadharData.split_address.pincode,
            state: aadharData.split_address.state,
            city: aadharData.split_address.city ? aadharData.split_address.city : aadharData.split_address.dist,
            district: aadharData.split_address.dist,
            aadharNumber: getDataField.aadharNumber,
            aadharNumberData: aadharData,
        }
        props.addCustomer(data);
        setCustomerVerifyModal(false)
    }

    if (isLoading) {
        return <div className="content text-center" style={{ margin: "250px 0" }}>
            <PropagateLoader color="#5851D2" loading={isLoading} size={25} />
        </div>
    }
    return (
        <div className="row">
            <div className="col-xl-12">
                <div className="card m-0 ">
                    <div className="card-body m-0 py-0">
                        <div className="row gx-3 mb-3">
                            <div className="col-md-6">
                                <label className="small mb-1" >Aadhaar Number</label>
                                <input className="form-control" type="text" placeholder="Enter aadhaar number" name="aadharNumber" value={getDataField.aadharNumber} onChange={(e) => onChangeValue(e)} maxLength={12} onKeyDown={isNumber} />
                                {/* {getDataError && getDataError.aadharNumber !== 2 && <span className="error text-danger">The field is required</span>}
                                {getDataError && getDataError.aadharNumber === 2 && <span className="error text-danger">Please enter valid aahaar number</span>} */}
                            </div>
                        </div>
                        <div className="row gx-3 mb-3">
                            <div className="col-md-6">
                                <button className="btn btn-primary float-right" onClick={() => existingCustomerDetail()} disabled={isLoading} type="button">Verify</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isCustomerVerifyModal && <Modal
                title="Customer Detail"
                width={600}
                open={isCustomerVerifyModal}
                footer={false}
            >
                <div className="row">
                    <div className="col-md-3">
                        {aadharData && <img className="elevation-2" src={"data:image/png;base64," + aadharData.photo_link} alt="User Avatar" />}
                    </div>
                    <div className="col-md-9">
                        <ol>
                            <li><strong>Name : </strong>{aadharData && aadharData.name}</li>
                            <li><strong>Father Name : </strong>{aadharData && aadharData.care_of}</li>
                            <li><strong>Address : </strong>{aadharData && aadharData.address}</li>
                        </ol>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12 col-md-12 text-right">
                        <Button className="btn bg-default mr-3" onClick={() => history.push({ pathname: "/dashboard" })}>Cancel</Button>
                        <Button className="btn bg-primary" onClick={() => handleSubmit()}>Add Customer</Button>
                    </div>
                </div>
            </Modal>}
            {isOtpModal && (<Modal
                title="Aadhaar OTP"
                centered
                open={isOtpModal}
                onCancel={() => history.push({ pathname: "/dashboard" })}
                okText={"Submit"}
                // onOk={() => { (isCheckAadharAlreadyVerify) ? sendOTPOnMobileNumberVerfiy() : handleAadharCardOtpVerify() }}
                onOk={() => { handleAadharCardOtpVerify() }}
            >

                {isOtp &&
                    <div className="input-group">
                        <div className="custom-file">
                            <input type="text" className="form-control" name="otpNumber" placeholder="OTP Number" defaultValue={""} maxLength={8} onChange={(e) => setIsOtpNumber(e.target.value)} />
                        </div>
                    </div>
                }
            </Modal>)}
        </div>
    )
}

export default AadharVerify;
