import React, { useEffect, useState,/* useRef */ } from 'react';
import DataTable from 'react-data-table-component';
import { GET_COMMISSION_LIST } from '../../Utils/appConstants';
import { getRequest } from '../../Utils/AppApi';
import { useHistory } from "react-router-dom";
import PropagateLoader from "react-spinners/PropagateLoader";
import { DatePicker, Tooltip } from 'antd';
import { getUTCToLocalDateTimeFormat, toDayDate, queryDate } from '../../Utils/function';
import { getUser, INRFormat } from '../../Utils/Common';

function CommissionIndex(props) {
    // console.log("props---------", props)
    const history = useHistory();
    const userData = getUser()
    const limit = 10;
    const dateFormat = 'DD-MM-YYYY';
    const [isLoading, setLoading] = useState(false);
    const [endDate, setEndDate] = useState(toDayDate());
    const [startDate, setStartDate] = useState(toDayDate());
    const [getData, setData] = useState([]);
    // const [getCsvData, setCsvData] = useState([]);
    const [getTotalRecord, setTotalRecord] = useState(0);
    const [page, setPage] = useState(1);
    const [isLimit, setLimit] = useState(limit);
    // console.log("userData---------", userData.data.role.code)
    const columns = [{
        name: 'Date Time',
        cell: row => getUTCToLocalDateTimeFormat(row.createdAt),
        width: "180px"
    }, {
        name: 'Transaction Id',
        selector: row => <Tooltip placement="bottom" title={row.transactionId}>{row.transactionId}</Tooltip>,
        width: "180px"
    }, (userData.data.role.code !== "P") ? {
        name: 'Partner Detail',
        selector: row => row.users && <Tooltip placement="bottom" title={row.users.username}>{row.users.username} | {row.users.name}</Tooltip>,
        width: "200px"
    } : { width: "0px" }, {
        name: 'Amount',
        selector: row => row.amount,
        width: "100px"
    }, {
        name: 'Commission Amount',
        selector: row => INRFormat(row.commissionAmount),
        // width: "80px"
    }, {
        name: 'Service Charge',
        selector: row => row.serviceCharge,
    }, {
        name: 'TDS Amount',
        selector: row => row.tdsAmount,
    }, {
        name: 'Service Name',
        selector: row => row.serviceName,
    }]

    const objectToQueryString = (obj) => {
        var str = [];
        for (var p in obj)
            if (obj.hasOwnProperty(p)) {
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
        return str.join("&");
    }

    const params = { page: page, limit: isLimit };

    const getDataReports = (data) => {
        if (data) {
            setLimit(data)
            params.limit = data;
        }
        if (startDate && endDate) {
            params.startDate = startDate ? queryDate(startDate) : "";
            params.endDate = endDate ? queryDate(endDate) : "";
        }
        setLoading(true);
        const queryString = objectToQueryString(params);
        getRequest(`${GET_COMMISSION_LIST}?${queryString}`, history).then((response) => {
            if (response.success === true) {
                setData(response.data);
                setTotalRecord(response.data.totalDocs);
                setPage(response.data.page);
            } else {
                setData([]);
                setTotalRecord(0);
                setPage(1);
            }
            setLoading(false);
        }).catch(function (error) {
            console.log(error)
            setLoading(false);
        })
    } // eslint-disable-line react-hooks/exhaustive-deps

    const handlePageChange = (page, size) => {
        console.log("-----", size)
        setPage(page);
    };

    const onChangeStartDate = (date, dateString) => {
        // console.log(date, dateString);
        setStartDate(date)
    };

    const onChangeEndDate = (date, dateString) => {
        // console.log(date, dateString);
        setEndDate(date)
    };

    const handleSearch = () => {
        getDataReports();
    }

    useEffect(() => {
        if (startDate && endDate && !isLoading) {
            handleSearch();
        }
    }, [startDate, endDate]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if(!isLoading) {
            getDataReports();
        }
        console.log("page ------------", page)
    }, [page]) // eslint-disable-line react-hooks/exhaustive-deps


    if (isLoading) {
        return <div className="content text-center" style={{ margin: "250px 0" }}>
            <PropagateLoader color="#5851D2" loading={isLoading} size={25} />
        </div>
    }

    return (
        <div>
            <div className="content-wrapper pt-2">
                <div className="content-header my-0 py-0">
                    <div className="container-fluid">
                        <div className="row mb-0 align-items-center">
                            <div className="col-sm-6">
                                <h1 className='page-title text-blue font-merriweather'>Commission <span className='text-red'>History</span></h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content">
                    <div className="container-fluid pt-2 pb-5">
                        <div className='card col-md-12'>
                            <div className='card-body'>
                                <div className="row">
                                    <div className='col-md-6 date-search mb-0'>
                                        <div className='row'>
                                            <div className='col-sm-4 col-lg-3'>
                                                <div className="form-group">
                                                    <label className='date-search-label mb-0 text-blue'>From</label>
                                                    <DatePicker className="form-control form-control-border" value={startDate} format={dateFormat} onChange={onChangeStartDate} />
                                                </div>
                                            </div>
                                            <div className='col-sm-4 col-lg-3'>
                                                <div className="form-group">
                                                    <label className='date-search-label mb-0 text-red'>To</label>
                                                    <DatePicker className="form-control form-control-border" value={endDate} format={dateFormat} onChange={onChangeEndDate} />
                                                </div>
                                            </div>
                                            <div className='col-sm-4 col-lg-2'>
                                                <div><label className='date-search-label mb-0 text-red'>&nbsp;</label></div>
                                                <button type="submit" className="btn btn-primary rounded-pill pl-4 pr-4" onClick={() => handleSearch()}>Search</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="container-fluid pt-2 pb-5">
                                    <DataTable
                                        title={""}
                                        columns={columns}
                                        data={getData.docs}
                                        highlightOnHover
                                        pagination
                                        paginationServer
                                        paginationTotalRows={getTotalRecord}
                                        onChangeRowsPerPage={getDataReports}
                                        onChangePage={handlePageChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CommissionIndex