import React, { useEffect, useState } from 'react';
// import { APP_KEY, API_URL } from './../config';
import DataTable from 'react-data-table-component';
import { GET_CLIENT_COMPLAINT_LIST } from './../../Utils/appConstants';
import { getRequest } from './../../Utils/AppApi';
import { complaintStatus } from './../../Utils/Common';
import { useHistory } from "react-router-dom";
// import Moment from 'react-moment';
// import moment from 'moment';
import { DatePicker, Tooltip } from 'antd';
import { getUTCToLocalDateTimeFormat } from '../../Utils/function';

function ComplaintList(props) {

    const history = useHistory();
    // const csvLink = useRef()
    const limit = 10;

    const dateFormat = 'DD-MM-YYYY';
    const [search, setSearch] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [getData, setData] = useState([]);
    // const [getCsvData, setCsvData] = useState([]);
    const [getTotalRecord, setTotalRecord] = useState(0);
    const [page, setPage] = useState(1);
    const [isLimit, setLimit] = useState(limit);

    const columns = [{
        name: 'Date Time',
        selector: row => <><Tooltip>{getUTCToLocalDateTimeFormat(row.createdAt)}</Tooltip></>,
    }, {
        name: 'Complaint Number',
        selector: row => <><Tooltip title={row.complaintNumber}>{row.complaintNumber}</Tooltip></>,
    }, {
        name: 'Transaction ID',
        selector: row => <><Tooltip title={row.transactionId}>{row.transactionId}</Tooltip></>,
    }, {
        name: 'Reason',
        selector: row => <><Tooltip title={row.clientReason}>{row.clientReason}</Tooltip></>,
    }, {
        name: 'Status',
        selector: row => <><Tooltip title={complaintStatus(row.status)}>{complaintStatus(row.status)}</Tooltip></>,
    }]

    const objectToQueryString = (obj) => {
        var str = [];
        for (var p in obj)
            if (obj.hasOwnProperty(p)) {
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
        return str.join("&");
    }

    const params = { page: page, limit: isLimit };

    const getDataReports = (data) => {
        if (data) {
            setLimit(data)
            params.limit = data;
        }
        if (startDate && endDate) {
            params.startDate = startDate ? startDate : "";
            params.endDate = endDate ? endDate : "";
        }

        if (search) {
            params.search = search ? search : "";
        }

        const queryString = objectToQueryString(params);
        getRequest(`${GET_CLIENT_COMPLAINT_LIST}?${queryString}`, history).then((response) => {
            if (response.success === true) {
                setData(response.data);
                setTotalRecord(response.data.totalDocs);
                setPage(response.data.page);
            } else {
                setData([]);
                setTotalRecord(0);
                setPage(1);
            }
        }).catch(function (error) {
            console.log(error)
            // setLoading(false);
        })
    } // eslint-disable-line react-hooks/exhaustive-deps

    const handlePageChange = (page, size) => {
        // console.log("-----", size)
        setPage(page);
    };

    const onChangeStartDate = (date, dateString) => {
        // console.log(date, dateString);
        setStartDate(dateString)
    };

    const onChangeEndDate = (date, dateString) => {
        // console.log(date, dateString);
        setEndDate(dateString)
    };

    const handleSearch = () => {
        getDataReports();
    }

    useEffect(() => {
        handleSearch();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        getDataReports();
    }, [page]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            <div className="row">
                <div className='col-md-8 date-search ml-2 mb-0'>
                    <div className='row'>
                        <div className='col-sm-3 col-lg-3'>
                            <div className="form-group">
                                <label className='date-search-label mb-0 text-blue'>From</label>
                                <DatePicker className="form-control form-control-border" format={dateFormat} onChange={onChangeStartDate} />
                            </div>
                        </div>
                        <div className='col-sm-3 col-lg-3'>
                            <div className="form-group">
                                <label className='date-search-label mb-0 text-red'>To</label>
                                <DatePicker className="form-control form-control-border" format={dateFormat} onChange={onChangeEndDate} />
                            </div>
                        </div>
                        <div className="col-sm-4 col-lg-4">
                            <div className="form-group">
                                <label className='date-search-label mb-0 text-red'></label>
                                <input className='form-control' placeholder='Search ...'  onChange={(e) => setSearch(e.target.value)}/>
                            </div>
                        </div>
                        <div className='col-sm-2 col-lg-2'>
                            <div><label className='date-search-label mb-0 text-red'>&nbsp;</label></div>
                            <button type="submit" className="btn btn-primary rounded-pill pl-4 pr-4" onClick={() => handleSearch()}>Search</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid pt-2 pb-5">
                <DataTable
                    title={""}
                    columns={columns}
                    data={getData.docs}
                    highlightOnHover
                    pagination
                    paginationServer
                    paginationTotalRows={getTotalRecord}
                    onChangeRowsPerPage={getDataReports}
                    onChangePage={handlePageChange}
                />
            </div>
        </div>
    )
}

export default ComplaintList