import React, { useEffect, useState, useRef } from 'react';
import { CSVLink } from 'react-csv';
import DataTable from 'react-data-table-component';
import { POST_AEPS_MONTHLY_COMMISSION } from '../../Utils/appConstants';
import { toDayDate, queryDate } from '../../Utils/function';
import { postRequest } from '../../Utils/AppApi';
import { INRFormat } from '../../Utils/Common';
import { useHistory } from "react-router-dom";
import { DatePicker, Tooltip } from 'antd';
import { toast } from 'react-toastify';

// const user = getUser();

function Index(props) {
    const history = useHistory();
    const csvLink = useRef()
    const dateFormat = 'DD-MM-YYYY';
    const [endDate, setEndDate] = useState(toDayDate());
    const [startDate, setStartDate] = useState(toDayDate());
    const [getData, setData] = useState([]);
    const [getCsvData, setCsvData] = useState([]);

    const [isLoading, setLoading] = useState(false);

    const columns = [{
        name: 'User ID',
        selector: row => <Tooltip placement="bottom" title={row._id[1]}>{row._id[1]}</Tooltip>,
        // width: "100px"
    }, {
        name: 'Name',
        selector: row => <Tooltip placement="bottom" title={row._id[2]}>{row._id[2]}</Tooltip>,
        // width: "100px"
    }, {
        name: 'Total Amt',
        selector: row => INRFormat(row.totalAmount),
        // width: "180px"
    }]

    const params = {};

    const getDataReports = (data) => {

        if (startDate && endDate) {
            params.startDate = startDate ? queryDate(startDate) : "";
            params.endDate = endDate ? queryDate(endDate) : "";
        }
        params.isProcess = data ? true : false;

        postRequest(`${POST_AEPS_MONTHLY_COMMISSION}`, params, history).then((response) => {
            if (response.success === true) {
                if(params.isProcess) {
                    setData([]);
                    toast.success('Commission transfered to wallet successful')
                } else {
                    setData(response.data);
                }
            } else {
                setData([]);
            }
        }).catch(function (error) {
            console.log(error)
            // setLoading(false);
        })
    } // eslint-disable-line react-hooks/exhaustive-deps

    const getCSVDataList = (data) => {
        let params = {}
        if (startDate && endDate) {
            params.startDate = startDate ? queryDate(startDate) : "";
            params.endDate = endDate ? queryDate(endDate) : "";
        }
        params.isProcess = data ? true : false;

        postRequest(`${POST_AEPS_MONTHLY_COMMISSION}`, params, history).then((response) => {
            if (response.success === true) {
                let csvdataRecord = [];
                let csvdata = response.data;
                csvdata.map((e) => {
                    return csvdataRecord.push({
                        "User ID": e._id[1],
                        "Name": e._id[2],
                        "Total Amount": e.totalAmount,
                    });
                });
                setCsvData(csvdataRecord);
                setTimeout(() => {
                    csvLink.current.link.click();
                }, 1000);
            } else {
                setCsvData([]);
            }
        }).catch(function (error) {
            console.log(error)
            // setLoading(false);
        })
    }

    const onChangeStartDate = (date, dateString) => {
        // console.log(date, dateString);
        setStartDate(date)
    };

    const onChangeEndDate = (date, dateString) => {
        // console.log(date, dateString);
        setEndDate(date)
    };

    const handleClear = () => {
        setStartDate("")
        setEndDate("")
    }

    const handleSearch = () => {
        getDataReports();
    }

    const handleProcess = () => {
        setLoading(true)
        getDataReports(true);
    }

    useEffect(() => {
        getDataReports();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            {/* <AppHeader /> */}
            <div className="content-wrapper pt-2">
                <div className="content-header my-0 py-0">
                    <div className="container-fluid">
                        <div className="row mb-0 align-items-center">
                            <div className="col-sm-6">
                                <h1 className='page-title text-blue font-merriweather'>Monthly <span className='text-red'>DMT Commission</span></h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content">
                    <div className="container-fluid pt-2 pb-5">
                        <div className='card col-md-12'>
                            <div className='card-body'>
                                <div className="row">
                                    <div className='col-md-12 date-search mb-0'>
                                        <div className='row'>
                                            <div className='col-md-4 col-lg-4 d-flex'>
                                                <div className="form-group mr-3">
                                                    <label className='date-search-label mb-0 text-blue'>From</label>
                                                    <DatePicker className="form-control form-control-border" value={startDate} format={dateFormat} onChange={onChangeStartDate} />
                                                </div>
                                                <div className="form-group">
                                                    <label className='date-search-label mb-0 text-red'>To</label>
                                                    <DatePicker className="form-control form-control-border" value={endDate} format={dateFormat} onChange={onChangeEndDate} />
                                                </div>
                                            </div>
                                            <div className='col-md-1 col-lg-1'>
                                                <div><label className='date-search-label mb-0 text-red'>&nbsp;</label></div>
                                                <button type="submit" className="btn btn-primary rounded-pill px-3" onClick={() => handleSearch()}>Search</button>
                                            </div>
                                            <div className='col-md-1 col-lg-1'>
                                                <div><label className='date-search-label mb-0 text-red'>&nbsp;</label></div>
                                                <button type="button" className="btn btn-default rounded-pill px-4" onClick={() => handleClear()}>Clear</button>
                                            </div>
                                            <div className='col-md-1 col-lg-1'>
                                                <div><label className='date-search-label mb-0 text-red'>&nbsp;</label></div>
                                                <button type="submit" className="btn btn-info rounded-pill px-3" disabled={isLoading} onClick={() => handleProcess()}>Process</button>
                                            </div>
                                            <div className='col-md-2'>
                                                <div className='float-sm-right export-button'>
                                                    <button type="button" className="btn btn-primary mr-3" onClick={() => getCSVDataList(true)} ><i className="fa fa-file-excel"></i> Export</button>
                                                    <CSVLink
                                                        data={getCsvData}
                                                        filename='monthly-dmt-commission'
                                                        className='hidden'
                                                        ref={csvLink}
                                                        target='_blank'
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="container-fluid pt-2 pb-5">
                                    <DataTable
                                        title={""}
                                        columns={columns}
                                        data={getData}
                                        highlightOnHover
                                    // pagination
                                    // paginationServer
                                    // paginationTotalRows={getTotalRecord}
                                    // onChangeRowsPerPage={getDataReports}
                                    // onChangePage={handlePageChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Index