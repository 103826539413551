import React, { useEffect, useState } from 'react';
// import { APP_KEY, API_URL } from './../config';
import DataTable from 'react-data-table-component';
import { GET_ADMIN_MOBILE_RECHARGE_REPORT } from './../../../Utils/appConstants';
import { getRequest } from './../../../Utils/AppApi';
import { useHistory } from "react-router-dom";
import { DatePicker, Tooltip } from 'antd';
import { getUTCToLocalDateTimeFormat, toDayDate, queryDate } from '../../../Utils/function';

function MobileRechargeReport(props) {

    const history = useHistory();
    // const csvLink = useRef()
    const limit = 10;

    const dateFormat = 'DD-MM-YYYY';
    // const [search, setSearch] = useState(null);
    const [endDate, setEndDate] = useState(toDayDate());
    const [startDate, setStartDate] = useState(toDayDate());
    const [getData, setData] = useState([]);
    // const [getCsvData, setCsvData] = useState([]);
    const [getTotalRecord, setTotalRecord] = useState(0);
    const [page, setPage] = useState(1);
    const [isLimit, setLimit] = useState(limit);
    const [searchMobile, setSearchMobile] = useState(null);
    const [searchTransId, setSearchTransId] = useState(null);
    const [searchUserId, setSearchUserId] = useState(null);

    const columns = [{
        name: 'Date',
        cell: row => getUTCToLocalDateTimeFormat(row.updatedAt),
        width: "180px"
    }, {
        name: 'TXN ID',
        cell: row => row.transactionId,
        width: "180px"
    }, {
        name: 'Partner ID',
        selector: row => <Tooltip placement="bottom" title={row.users.username}>{row.users.username}</Tooltip>,
        width: "130px",
    }, {
        name: 'Mobile No.',
        selector: row => row.mobileNumber,
        width: "120px"
    }, {
        name: 'Mobile Operator',
        selector: row => row.mobileOperator,
    }, {
        name: 'States',
        selector: row => row.states,
    }, {
        name: 'Operator ID',
        selector: row => (row.states) ? row.apiResponse && row.apiResponse.field1 : "-",
    }, {
        name: 'Amount',
        selector: row => row.amount,
    }, {
        name: 'Status',
        cell: row => <>{(row.status) ? <div className="text-success">Success</div> : <><div className="text-danger">Failed</div><br/><div>Refund</div></>}</>,
    }, {
        name: 'Remark',
        cell: row => <>{(row.apiResponse) ? <Tooltip placement="bottom" title={row.apiResponse.remark}>{row.apiResponse.status}</Tooltip> : '-'}</>,
    }]

    const objectToQueryString = (obj) => {
        var str = [];
        for (var p in obj)
            if (obj.hasOwnProperty(p)) {
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
        return str.join("&");
    }

    const params = { page: page, limit: isLimit };

    const getDataReports = (data) => {
        if (data) {
            setLimit(data)
            params.limit = data;
        }
        if (startDate && endDate) {
            params.startDate = startDate ? queryDate(startDate) : "";
            params.endDate = endDate ? queryDate(endDate) : "";
        }

        params.searchMobile = searchMobile ? searchMobile : '';
        params.searchTransId = searchTransId ? searchTransId : '';
        params.searchUserId = searchUserId ? searchUserId : '';

        const queryString = objectToQueryString(params);
        getRequest(`${GET_ADMIN_MOBILE_RECHARGE_REPORT}?${queryString}`, history).then((response) => {
            if (response.success === true) {
                setData(response.data);
                setTotalRecord(response.data.totalDocs);
                setPage(response.data.page);
            } else {
                setData([]);
                setTotalRecord(0);
                setPage(1);
            }
        }).catch(function (error) {
            console.log(error)
            // setLoading(false);
        })
    } // eslint-disable-line react-hooks/exhaustive-deps

    const handlePageChange = (page, size) => {
        console.log("-----", size)
        setPage(page);
    };

    const onChangeStartDate = (date, dateString) => {
        // console.log(date, dateString);
        setStartDate(date)
    };

    const onChangeEndDate = (date, dateString) => {
        // console.log(date, dateString);
        setEndDate(date)
    };

    const handleSearch = () => {
        getDataReports();
    }

    const handleClear = () => {
        setStartDate('');
        setEndDate('');
        setSearchMobile('');
        setSearchTransId('');
        setSearchUserId('');
    }

    useEffect(() => {
        if (searchMobile || searchTransId || searchUserId) {
            handleSearch();
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        getDataReports();
    }, [page]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            <div className="content-wrapper pt-2">
                <div className="content-header my-0 py-0">
                    <div className="container-fluid">
                        <div className="row mb-0 align-items-center">
                            <div className="col-sm-6">
                                <h1 className='page-title text-blue font-merriweather'>Mobile Recharge <span className='text-red'>Report</span></h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content">
                    <div className="container-fluid pt-2 pb-5">
                        <div className="row">
                            <div className='card col-md-12 py-3'>
                                <div className='card-body p-0'>
                                    <div className="row">
                                        <div className='col-md-12 date-search mb-0'>
                                            <div className='row'>
                                                <div className='col-md-3 col-lg-3 d-flex'>
                                                    <div className="form-group col-6 mr-3">
                                                        <label className='date-search-label mb-0 text-blue'>From</label>
                                                        <DatePicker className="form-control form-control-border" format={dateFormat} onChange={onChangeStartDate} />
                                                    </div>
                                                    <div className="form-group col-6 ">
                                                        <label className='date-search-label mb-0 text-red'>To</label>
                                                        <DatePicker className="form-control form-control-border" format={dateFormat} onChange={onChangeEndDate} />
                                                    </div>
                                                </div>
                                                <div className='col-md-3 col-lg-3 d-flex'>
                                                    <div className="form-group col-6 mr-3">
                                                        <label className='date-search-label mb-0 text-red'></label>
                                                        <input type="text" className="form-control form-control-border" placeholder="User ID" onChange={(e) => setSearchUserId(e.target.value)} value={searchUserId} />
                                                    </div>
                                                    <div className="form-group col-6 ">
                                                        <label className='date-search-label mb-0 text-red'></label>
                                                        <input type="text" className="form-control form-control-border" placeholder="Trans ID" onChange={(e) => setSearchTransId(e.target.value)} value={searchTransId} />
                                                    </div>
                                                </div>
                                                <div className='col-md-2 col-lg-2 d-flex'>
                                                    <div className="form-group col-12 mr-3">
                                                        <label className='date-search-label mb-0 text-red'></label>
                                                        <input type="text" className="form-control form-control-border" placeholder="Mobile Number" onChange={(e) => setSearchMobile(e.target.value)} value={searchMobile} />
                                                    </div>
                                                </div>
                                                <div className='col-md-1 col-lg-1'>
                                                    <div><label className='date-search-label mb-0 text-red'>&nbsp;</label></div>
                                                    <button type="submit" className="btn btn-primary rounded-pill pl-4 pr-4" onClick={() => handleSearch()}>Search</button>
                                                </div>
                                                <div className='col-md-1 col-lg-1'>
                                                    <div><label className='date-search-label mb-0 text-red'>&nbsp;</label></div>
                                                    <button type="submit" className="btn btn-default rounded-pill px-4" onClick={() => handleClear()}>Clear</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="container-fluid pt-2 pb-5">
                                        <DataTable
                                            title={""}
                                            columns={columns}
                                            data={getData.docs}
                                            highlightOnHover
                                            pagination
                                            paginationServer
                                            paginationTotalRows={getTotalRecord}
                                            onChangeRowsPerPage={getDataReports}
                                            onChangePage={handlePageChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MobileRechargeReport