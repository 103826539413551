export const SUPER_ADMIN = {
}

export const ADMIN = [{
    text: "USER Management",
    route: "/#",
    submenu: [{
        text: "Manage User Type",
        route: "/#",
    }, {
        text: "Create User",
        route: "/create-users",
    }, {
        text: "Manage User",
        route: "/manage-users",
    }, {
        text: "Create Employee",
        route: "/create-employee",
    }, {
        text: "Manage Employee",
        route: "/#",
    }, {
        text: "Create API Member",
        route: "/#",
    }, {
        text: "Manage API Member",
        route: "/#",
    }, {
        text: "Transfer USER",
        route: "/transfer-user",
    }]
}, {
    text: "Commissions",
    route: "/#",
    submenu: [{
        text: "Create Services",
        route: "/create-services",
    }, {
        text: "Create Slab",
        route: "/create-slab",
    }, {
        text: "Slab List",
        route: "/slab-list",
    }, {
        text: "Create Package",
        route: "/packages",
    }, {
        text: "Discount Module",
        route: "/#",
    }, {
        text: "Commission Module",
        route: "/#",
    }, {
        text: "Bank Detail",
        route: "/admin-bank-detail",
    }]
}, {
    text: "API Management",
    route: "/#",
    submenu: [{
        text: "Add API",
        route: "/#",
    }, {
        text: "Manage API",
        route: "/#",
    }, {
        text: "Provider API Setting",
        route: "/#",
    }, {
        text: "Custom API Routing",
        route: "/#",
    }, {
        text: "API Token",
        route: "/#",
    }, {
        text: "API Document",
        route: "/#",
    }, {
        text: "Add Service / Operator",
        route: "/add-service-and-operator",
    }]
}, {
    text: "KYC Management",
    route: "/#",
    submenu: [{
        //     text : "Approved Users",
        //     route : "/approved-users",
        // },{
        text : "MATM Registration",
        route : "/matm-registration-list",
    },{
        text: "KYC Pending",
        route: "/kyc-pending-users",
    }, {
        text: "Update Aadhaar KYC",
        route: "/kyc-aadhaar-pending-users",
    }, {
        text: "Agent Bank Registered",
        route: "/agent-bank-list"
    }, {
        text: "Shop Registration",
        route: "/shop-registration-list"
    }, {
        text: "e-KYC Verified",
        route: "/e-kyc-verified",
    }, {
        text: "IPAY Merchants",
        route: "/ipay-merchants",
    }, {
        text: "Partner Ledger",
        route: "/partner-ledger",
    }, ]
}, {
    text: "Fund Management",
    route: "/#",
    submenu: [{
        text: "Fund Requisition",
        submenu: [{
            text: "Fund Maker",
            target: false,
            route: "/fund-request-maker",
        }, {
            text: "Fund Approver",
            route: "/fund-request-approver",
        }]
    }, {
        text: "Fund Payout",
        submenu: [{
            text: "Fund Maker",
            target: false,
            route: "/#",
        }, {
            text: "Fund Approver",
            route: "/#",
        }]
    }, {
        text: "QR Mini",
        route: "/qrcode-mini-report",
    }, {
        text: "Services",
        submenu: [{
            text: "Monthly DMT Commission",
            route: "/monthly-dmt-commission",
        }]
    }, ],
}, {
    text: "Reports",
    route: "/#",
    submenu: [{
        text: "Virtual Ledger",
        route: "/myledger",
    }, {
        text: "Transaction History",
        route: "/transaction-type-report",
    }, {
        text: "Daily Transaction Report",
        route: "/daily-transaction-report",
    }, {
        text: "Commission Report",
        route: "/commission-report",
    }, {
        text: "Wallet History",
        route: "/wallet-history",
    }, {
        text: "MATM / AePS Services",
        submenu: [{
            text: "MATM Transaction",
            route: "/matm-transaction-report",
        }, {
            text: "AePS Withdrawal",
            route: "/aeps-withdrawal-report",
        }, {
            text: "AePS 3 Way Report",
            route: "/aeps-threeway-report"
        }, {
            text: "MATM 3 Way Report",
            route: "/matm-threeway-report"
        },{
            text: "AePS Register Download",
            route: "/aeps-register-download-report",
        }, {
            text: "QR Mini History",
            route: "/qrcode-mini-history",
        }, {
            text: "IPAY Statment",
            route: "/ipay-statement",
        }, {
            text: "AePS Login",
            route: "/aeps-login",
        }]
    }, {
        text: "DMT Services",
        submenu: [{
            text: "Money Transfer",
            route: "/money-transfer-report",
        }, {
            text: "Money Transfer Fail",
            route: "/money-transfer-fail-report",
        }, {
            text: "Refunds Pending",
            route: "/refund-pending-report",
        }, {
            text: "DMT Refund History",
            route: "/dmt-refunded-history",
        }, {
            text: "DMT Details History",
            route: "/dmt-detail-history",
        }, {
            text: "UPI Transfer",
            route: "/upi-transfer-report",
        }]
    }, {
        text: "Bank Settlement",
        submenu: [{
            text: "Move To Bank",
            route: "/settlement-to-bank",
        }, {
            text: "UPI Transfer",
            route: "/#",
        }, {
            text: "Remittance Transfer",
            route: "/remittance-admin-report",
        }, {
            text: "Remittance Failed",
            route: "/remittance-failed-report",
        }]
    }, {
        text: "Distribution Reports",
        submenu: [{
            text: "User Primary Reports",
            route: "/#",
        }, {
            text: "Distributor To Partner FTR",
            route: "/#",
        }, {
            text: "SD to Distributor FTR",
            route: "/#",
        }, {
            text: "Partner Topup Report",
            route: "/dth-recharge-report",
        }]
    }, {
        text: "Utility Service",
        submenu: [{
            text: "Utility Maker",
            target: false,
            route: "/utility-payment-offline-maker",
        }, {
            text: "Utility Approver",
            route: "/utility-payment-offline-approver",
        },{
        //     text: "Utility API Request",
        //     route: "/#",
        // }, {
            text: "Utility API Request Status",
            route: "/uitility-reports",
        },{
            text: "Mobile Recharge Report",
            route: "/mobile-recharge-report",
        }, {
            text: "DTH Recharge Report",
            route: "/dth-recharge-report",
        }]
    }, {
        text: "Complaints",
        route: "/#",
    }, {
        text: "Complaint History",
        route: "/complaint-history",
    // }, {
    //     text: "Refunds",
    //     route: "/#",
    // }, {
    //     text: "Refund History",
    //     route: "/#",
    }, {
        text: "Payment History Credits",
        route: "/#",
    }, {
        text: "Fund Approve FTR",
        route: "/fund-request-reports",
    }, {
        text: "UPI Load Money",
        route: "/upiload-money-report",
    }, {
        text: "Instant Topup History",
        route: "/instant-topup-history",
    }, ]
}, {
    text: "Admin Profile",
    route: "/#",
    submenu: [{
        text: "My Profile",
        route: "/myProfile",
    }, {
        text: "Admin Balance",
        route: "/admin-wallet-recharge",
    }, {
        text: "Find Bank Detail",
        route: "/find-bank-detail",
    }, {
        text: "App Setting",
        route: "/appSetting",
    }, {
        text: "Advertisement",
        route: "/advertisement",
    // }, {
    //     text: "Admin Console",
    //     submenu: [{
    //         text: "Partner Ledger",
    //         route: "/partner-ledger",
    //     }, {
    //         text: "Agent Bank Registered",
    //         route: "/agent-bank-list"
    //     }, {
    //         text: "Shop Registration",
    //         route: "/shop-registration-list"
    //     }],
    }]
}]

export const STATE_HEAD = [{
    text: "Accounts",
    route: "/#",
    submenu: [{
        text: "Create User",
        route: "/create-users",
    }, {
        text: "Manage User",
        route: "/manage-users",
    }, {
        text: "KYC Pending",
        route: "/kyc-pending-users",
    }, {
        text: "Create Employee",
        route: "/create-employee",
    }, {
        text: "Manage Employee",
        route: "/#",
    // }, {
    //     text: "E-Collect",
    //     submenu: [{
    //         text: "SBI Collect",
    //         target: true,
    //         route: "https://www.onlinesbi.sbi/sbicollect/icollecthome.htm?corpID=856614",
    //     }, {
    //         text: "PNB Collect",
    //         route: "/#",
    //     }, {
    //         text: "UNION Collect",
    //         route: "/#",
    //     }]
    }, {
        text: "UPI QR Mini",
        route: "/upiqrmini-code",
    }, {
        text: "Generate UPI QR",
        route: "/#",
    }, {
        text: "Bank Details",
        route: "/bank-info-detail",
    }, {
        text: "Instant Topup",
        route: "/instant-topup",
    }, {
        text: "Fund Request",
        route: "/fund-request",
    }, {
        text: "Commissions",
        route: "/commission-list",
    }, {
        text: "Wallet History",
        route: "/wallet-history",
    }]
}, {
    // text : "Wallet",
    // route : "/#",
    // submenu : [{
    //         text : "Fund Topup",
    //         route : "/#",
    //     },{
    //         text : "Revoke Topup",
    //         route : "/#",
    //     }]
    // },{
    text: "Transaction Status",
    route: "/#",
    submenu: [{
        text: "Partner Ledger",
        route: "/partner-ledger",
    }, {
        text: "Transaction Status",
        route: "/#",
    }, {
        text: "Sender Number",
        route: "/#",
    }]
}, {
    text: "Device / Product",
    route: "/#",
    submenu: [{
        text: "Request Product",
        route: "/product-request",
    }, {
        text: "Replace / Repaire",
        route: "/#",
    }]
}, {
    text: "Reports",
    route: "/#",
    submenu: [{
        text: "Ledger",
        route: "/myledger",
    }, {
        text: "QR Mini History",
        route: "/upiqrmini-history",
    }, {
        text: "UPI Load Money",
        route: "/upiload-money-report",
    }, {
        text: "Transaction Recon",
        route: "/#",
    }, {
        text: "Service / Product",
        route: "/#",
    }, {
        text: "SD Performance",
        route: "/#",
    }, {
        text: "MD Performance",
        route: "/#",
    }, {
        text: "Distributor Performance",
        route: "/#",
    }, {
        text: "Topup Report",
        route: "/#",
    }, {
        text: "FTR Status Report",
        route: "/myfund-request-report",
    }]
}, {
    text: "My Profile",
    route: "/#",
    submenu: [{
        text: "My Profile",
        route: "/myProfile",
    }, {
        text: "Monthly Earning",
        route: "/commission-release",
    }, {
        text: "BC Certificate",
        route: "/#",
    }, {
        text: "Distributor Certificate",
        route: "/#",
    }, {
        text: "Marketing Material",
        route: "/#",
    }, {
        text: "TDS Certificate",
        route: "/#",
    }, {
        text: "GST Invoice",
        route: "/#",
    }]
}]

export const SUPER_DISTRIBUTOR = [{
    text: "Accounts",
    route: "/#",
    submenu: [{
        text: "Create User",
        route: "/create-users",
    }, {
        text: "Manage User",
        route: "/manage-users",
    }, {
        text: "KYC Pending",
        route: "/kyc-pending-users",
    }, {
        text: "Create Employee",
        route: "/create-employee",
    }, {
        text: "Manage Employee",
        route: "/#",
    // }, {
    //     text: "E-Collect",
    //     submenu: [{
    //         text: "SBI Collect",
    //         target: true,
    //         route: "https://www.onlinesbi.sbi/sbicollect/icollecthome.htm?corpID=856614",
    //     }, {
    //         text: "PNB Collect",
    //         route: "/#",
    //     }, {
    //         text: "UNION Collect",
    //         route: "/#",
    //     },]
    }, {
        text: "UPI QR Mini",
        route: "/upiqrmini-code",
    }, {
        text: "Generate UPI QR",
        route: "/#",
    }, {
        text: "Bank Details",
        route: "/bank-info-detail",
    }, {
        text: "Instant Topup",
        route: "/instant-topup",
    }, {
        text: "Fund Request",
        route: "/fund-request",
    }, {
        text: "Commissions",
        route: "/commission-list",
    }, {
        text: "Wallet History",
        route: "/wallet-history",
    }]
}, {
    text: "Wallet",
    route: "/#",
    submenu: [{
        text: "Request Approve",
        route: "/request-money-list",
    }, {
        text: "Payment Approve",
        route: "/payment-deposit-list",
    }, {
        text: "COD Approve",
        route: "/cod-request-list",
    }, {
        text: "Khatabook Ledger",
        route: "/channel-wallet-ledger",
    }]
}, {
    text: "Transaction Status",
    route: "/#",
    submenu: [{
        text: "Partner Ledger",
        route: "/partner-ledger",
    }, {
        text: "Transaction Status",
        route: "/#",
    }, {
        text: "Sender Number",
        route: "/#",
    }]
}, {
    text: "Device / Product",
    route: "/#",
    submenu: [{
        text: "Request Product",
        route: "/product-request",
    }, {
        text: "Replace / Repaire",
        route: "/#",
    }]
}, {
    text: "Reports",
    route: "/#",
    submenu: [{
        text: "Ledger",
        route: "/myledger",
    }, {
        text: "QR Mini History",
        route: "/upiqrmini-history",
    }, {
        text: "UPI Load Money",
        route: "/upiload-money-report",
    }, {
        text: "Transaction Recon",
        route: "/#",
    }, {
        text: "Service / Product",
        route: "/#",
    }, {
        text: "MD Performance",
        route: "/#",
    }, {
        text: "Distributor Performance",
        route: "/#",
    }, {
        text: "Legion Report",
        submenu: [{
            text: "Request Approve",
            route: "/request-approved-reports",
        }, {
            text: "Payment Approve",
            route: "/payment-deposit-list",
        }, {
            text: "COD Approve",
            route: "/cod-request-approved-reports",
        }]
    },{
        text: "Topup Report",
        route: "/#",
    }, {
        text: "FTR Status Report",
        route: "/myfund-request-report",
    }]
}, {
    text: "My Profile",
    route: "/#",
    submenu: [{
        text: "My Profile",
        route: "/myProfile",
    }, {
        text: "Monthly Earning",
        route: "/commission-release",
    }, {
        text: "BC Certificate",
        route: "/#",
    }, {
        text: "Distributor Certificate",
        route: "/#",
    }, {
        text: "Marketing Material",
        route: "/#",
    }, {
        text: "TDS Certificate",
        route: "/#",
    }, {
        text: "GST Invoice",
        route: "/#",
    }]
}]

export const MASTER_DISTRIBUTOR = [{
    text: "Accounts",
    route: "/#",
    submenu: [{
        text: "Create User",
        route: "/create-users",
    }, {
        text: "Manage User",
        route: "/manage-users",
    }, {
        text: "KYC Pending",
        route: "/kyc-pending-users",
    }, {
        text: "Create Employee",
        route: "/create-employee",
    }, {
        text: "Manage Employee",
        route: "/#",
    }, {
        text: "Instant Topup",
        route: "/instant-topup",
    }, {
        text: "Fund Request",
        route: "/fund-request",
    // }, {
    //     text: "E-Collect",
    //     submenu: [{
    //         text: "SBI Collect",
    //         target: true,
    //         route: "https://www.onlinesbi.sbi/sbicollect/icollecthome.htm?corpID=856614",
    //     }, {
    //         text: "PNB Collect",
    //         route: "/#",
    //     }, {
    //         text: "UNION Collect",
    //         route: "/#",
    //     },]
    }, {
        text: "Bank Details",
        route: "/bank-info-detail",
    }, {
        text: "Commissions",
        route: "/commission-list",
    }, {
        text: "Revoke Topup",
        route: "/#",
    }, {
        text: "Wallet History",
        route: "/wallet-history",
    }]
}, {
    text: "Wallet",
    route: "/#",
    submenu: [{
        text: "Salf Wallet",
        submenu: [{
            text: "Request Money",
            route: "/request-money",
        }, {
            text: "Payment Deposit",
            route: "/payment-deposit",
        }, {
            text: "COD Request",
            route: "/cod-request",
        }, {
            text: "Move to SD",
            route: "/move-to-distributor",
        }],
    }, {
        text: "Legion Wallet",
        submenu: [{
            text: "Request Approve",
            route: "/request-money-list",
        }, {
            text: "Payment Approve",
            route: "/payment-deposit-list",
        }, {
            text: "COD Approve",
            route: "/cod-request-list",
        }],
    }, {
        text: "Khatabook Ledger",
        route: "/channel-wallet-ledger",
    }]
}, {
    text: "Transaction Status",
    route: "/#",
    submenu: [{
        text: "Partner Ledger",
        route: "/partner-ledger",
    }, {
        text: "Transaction Status",
        route: "/#",
    }, {
        text: "Sender Number",
        route: "/#",
    }]
}, {
    text: "Device / Product",
    route: "/#",
    submenu: [{
        text: "Request Product",
        route: "/product-request",
    }, {
        text: "Replace / Repaire",
        route: "/#",
    }]
}, {
    text: "Reports",
    route: "/#",
    submenu: [{
        text: "Ledger",
        route: "/myledger",
    }, {
        text: "QR Mini History",
        route: "/upiqrmini-history",
    }, {
        text: "UPI Load Money",
        route: "/upiload-money-report",
    }, {
        text: "Transaction Recon",
        route: "/#",
    }, {
        text: "Fund Request Report",
        route: "/#",
    }, {
        text: "Distributor Performance",
        route: "/#",
    }, {
        text: "Distributor Topup Report",
        route: "/#",
    }, {
        text: "Partner Topup Report",
        route: "/#",
    }, {
        text: "FTR Status Report",
        route: "/myfund-request-report",
    }]
}, {
    text: "My Profile",
    route: "/#",
    submenu: [{
        text: "My Profile",
        route: "/myProfile",
    }, {
        text: "Monthly Earning",
        route: "/commission-release",
    }, {
        text: "BC Certificate",
        route: "/#",
    }, {
        text: "Distributor Certificate",
        route: "/#",
    }, {
        text: "Marketing Material",
        route: "/#",
    }, {
        text: "TDS Certificate",
        route: "/#",
    }, {
        text: "GST Invoice",
        route: "/#",
    }]
}]

export const DISTRIBUTOR = [{
    text: "Accounts",
    route: "/#",
    submenu: [{
        text: "Create User",
        route: "/create-users",
    }, {
        text: "Manage User",
        route: "/manage-users",
    }, {
        text: "KYC Pending",
        route: "/kyc-pending-users",
    }, {
        text: "Create Employee",
        route: "/create-employee",
    }, {
        text: "Instant Topup",
        route: "/instant-topup",
    }, {
        text: "Fund Request",
        route: "/fund-request",
    // }, {
    //     text: "E-Collect",
    //     submenu: [{
    //         text: "SBI Collect",
    //         target: true,
    //         route: "https://www.onlinesbi.sbi/sbicollect/icollecthome.htm?corpID=856614",
    //     }, {
    //         text: "PNB Collect",
    //         route: "/#",
    //     }, {
    //         text: "UNION Collect",
    //         route: "/#",
    //     },]
    }, {
        text: "UPI QR Mini",
        route: "/upiqrmini-code",
    }, {
        text: "Generate UPI QR",
        route: "/#",
    }, {
        text: "Commissions",
        route: "/commission-list",
    }, {
        text: "Bank Details",
        route: "/bank-info-detail",
    }, {
        text: "Report A Complaint",
        route: "/complaint",
    }, {
        text: "Wallet History",
        route: "/wallet-history",
    }]
}, {
    text: "Wallet",
    route: "/#",
    submenu: [{
        text: "Self Wallet",
        submenu: [{
            text: "Request Money",
            route: "/request-money",
        }, {
            text: "Payment Deposit",
            route: "/payment-deposit",
        }, {
            text: "COD Request",
            route: "/cod-request",
        }, {
            text: "Move to MD",
            route: "/move-to-distributor",
        }]
    }, {
        text: "Legion Wallet",
        submenu: [{
            text: "Request Approve",
            route: "/request-money-list",
        }, {
            text: "Payment Approve",
            route: "/payment-deposit-list",
        }, {
            text: "COD Approve",
            route: "/cod-request-list",
        }]
    }, {
        text: "Ledger",
        route: "/channel-wallet-ledger",
    }]
}, {
    text: "Transaction Status",
    route: "/#",
    submenu: [{
        text: "Partner Ledger",
        route: "/partner-ledger",
    }, {
        text: "Transaction Status",
        route: "/#",
    }, {
        text: "Sender Number",
        route: "/#",
    }]
}, {
    text: "Device / Product",
    route: "/#",
    submenu: [{
        text: "Request Product",
        route: "/product-request",
    }, {
        text: "Replace / Repaire",
        route: "/#",
    }]
}, {
    text: "Reports",
    route: "/#",
    submenu: [{
        text: "Ledger",
        route: "/myledger",
    }, {
        text: "QR Mini History",
        route: "/upiqrmini-history",
    }, {
        text: "Transaction Recon",
        route: "/#",
    }, {
        text: "Agent Sale MIS",
        route: "/#",
    }, {
        text: "Agent Service MIS",
        route: "/#",
    }, {
        text: "UPI Load Money",
        route: "/upiload-money-report",
    }, {
        text: "Self Wallet Report",
        submenu: [{
            text: "Request Money",
            route: "/request-money-reports",
        }, {
            text: "COD Request",
            route: "/cod-request-reports",
        }, {
            text: "Payment Deposit MD",
            route: "/payment-deposit-history",
        }, {
            text: "Move To MD",
            route: "/move-to-distributor-reports",
        }]
    }, {
        text: "Partner Wallet Report",
        submenu: [{
            text: "Request Money Accepted",
            route: "/request-approved-reports",
        }, {
            text: "COD Request Accepted",
            route: "/cod-request-approved-reports",
        }, {
            text: "Payment Deposit history",
            route: "/payment-deposit-list",
        }, {
            text: "Move To Distributer",
            route: "/move-to-partner-reports",
        }]
    }, {
        text: "Wallet Load Report",
        route: "/#",
    }, {
        text: "FTR Status Report",
        route: "/myfund-request-report",
    }, {
        text: "Partner DMT Report",
        route: "/dmt-distributor-report",
    }, {
        text: "Transaction Wise Commission",
        route: "/#",
    }]
}, {
    text: "My Profile",
    route: "/#",
    submenu: [{
        text: "My Profile",
        route: "/myProfile",
    }, {
        text: "Monthly Earning",
        route: "/commission-release",
    }, {
        text: "BC Certificate",
        route: "/#",
    }, {
        text: "Distributor Certificate",
        route: "/#",
    }, {
        text: "Marketing Material",
        route: "/#",
    }, {
        text: "TDS Certificate",
        route: "/#",
    }, {
        text: "GST Invoice",
        route: "/#",
    }]
}]

export const PARTNER = [{
    text: "Accounts",
    route: "/#",
    submenu: [{
        text: "Instant Topup",
        route: "/instant-topup",
    }, {
        text: "Fund Request",
        route: "/fund-request",
    }, {
        text: "UPI QR Mini",
        route: "/upiqrmini-code",
    }, {
        text: "Generate UPI QR",
        route: "/#",
    // }, {
    //     text: "E-Collect",
    //     submenu: [{
    //         text: "SBI Collect",
    //         target: true,
    //         route: "https://www.onlinesbi.sbi/sbicollect/icollecthome.htm?corpID=856614",
    //     }, {
    //         text: "PNB Collect",
    //         route: "/#",
    //     }, {
    //         text: "UNION Collect",
    //         route: "/#",
    //     },]
    }, {
        text: "Commissions",
        route: "/commission-list",
    }, {
        text: "Bank Details",
        route: "/bank-info-detail",
    }, {
        text: "Report A Complaint",
        route: "/complaint",
    }]
}, {
    text: "Wallet",
    route: "/#",
    submenu: [{
        text: "Request Money",
        route: "/request-money",
    }, {
        text: "Move to Distributor",
        route: "/move-to-distributor",
    }, {
        text: "Payment Deposit ( Cash / Online )",
        route: "/payment-deposit",
    }, {
        text: "COD Request",
        route: "/cod-request",
    }, {
        text: "Channel Wallet Ledger",
        route: "/channel-wallet-ledger",
    }, {
        text: "Settle To Bank",
        route: "/move-to-bank",
    }, {
        text: "Settle To Wallet",
        route: "/settle-to-wallet",
    }, {
        text: "Refund Avalaible",
        route: "/#",
    }]
}, {
    text: "Digital Optimization",
    route: "/#",
    submenu: [{
        text: "AePS NSDL",
        route: "/aeps",
    }, {
        text: "AePS YBL / APB",
        route: "/express-aeps",
    }, {
        text: "Money Transfer",
        route: "/money-transfer",
    }, {
        text: "MATM Registration",
        route: "/matm-registration",
    }]
}, {
    text: "Utility Service",
    route: "/#",
    submenu: [{
        text: "Prepaid Recharge",
        route: "/mobile-recharge",
    }, {
        text: "Utility Payment - Offline",
        route: "/uitility-payment",
    }, {
        text: "Utility Payment 2.0",
        route: "/uitility-payment-2-0",
    }, {
        text: "Bharat Billpay (BBPS)",
        route: "/bharat-billpay-bbps",
    }]
}, {
    text: "Travel",
    route: "/#",
    submenu: [{
        text: "Air Ticket",
        route: "/#",
    }, {
        text: "Hotel Booking",
        route: "/#",
    }, {
        text: "Tour Package",
        route: "/#",
    }, {
        text: "Bus Booking",
        route: "/#",
    }, {
        text: "Taxi Service",
        route: "/#",
    }]
}, {
    text: "CMS",
    route: "/#",
    submenu: [{
        text: "Cash Collection",
        route: "/#",
    }]
}, {
    text: "My Profile",
    route: "/#",
    submenu: [{
        text: "My Profile",
        route: "/myProfile",
    }, {
        text: "Monthly Earning",
        route: "/commission-release",
    }, {
        text: "Bank Settings",
        route: "/bank-setting",
    }, {
        text: "Partner e-KYC",
        route: "/partner-ekyc",
    }, {
        text: "Agent Certificate",
        route: "/#",
    }, {
        text: "BC Certificate",
        route: "/#",
    }, {
        text: "Marketing Material",
        route: "/#",
    }, {
        text: "TDS Certificate",
        route: "/#",
    }, {
        text: "GST Invoice",
        route: "/#",
    }]
}, {
    text: "Reports",
    route: "/#",
    submenu: [{
        text: "Agent Ledger",
        route: "/myledger",
    }, {
        text: "FTR Status Report",
        route: "/myfund-request-report",
    }, {
        text: "Transaction History",
        submenu: [{
            text: "QR Mini History",
            route: "/upiqrmini-history",
        }, {
            text: "MATM Transaction",
            route: "/matm-report",
        }, {
            text: "Prepaid Report",
            route: "/prepaid-reports",
        }, {
            text: "Utility Payments Offline",
            route: "/uitility-reports",
        }, {
            text: "Aeps History",
            route: "/aeps-history-report",
        }, {
            text: "Money Transfer",
            route: "/money-transfer-history",
        }, {
            text: "BBPS Reports",
            route: "/#",
        }, {
            text: "UPI Load Money",
            route: "/upiload-money-report",
        }, {
            text: "Adhaar Pay",
            route: "/#",
        }, {
            text: "E - Collect",
            route: "/#",
        }, {
            text: "Credit Card Payment",
            route: "/#",
        }, {
            text: "Utility Service 2.0",
            route: "/#",
        }, ]
    }, {
        text: "Bank Settlement",
        submenu: [{
            text: "Move TO Bank",
            route: "/#"
        },{
            text: "UPI Transfer",
            route: "/#"
        },{
            text: "Remittance Report",
            route: "/remittance-transfer-report"
        }, {
            text: "Payout History",
            route: "/payout-history",
        }, ]
    }, {
        text: "Wallet Reports",
        submenu: [{
            text: "Wallet History",
            route: "/wallet-history",
        }, {
            text: "UPI QR",
            route: "/#",
        }, {
            text: "Mini ATM Report",
            route: "/#",
        }, {
            text: "Refund Report",
            route: "/#",
        }, {
            text: "Settle To Wallet",
            route: "/#",
        }, {
            text: "Fund Request",
            route: "/myfund-request-report",
        }, {
            text: "Instant Topup",
            route: "/instant-topup-report",
        }, {
            text: "UPI Transfer",
            route: "/upi-transfer-reports",
        }, {
            text: "Wallet Transfer",
            route: "/#",
        }]
    }, {
        text: "Khata Wallet",
        submenu: [{
            text: "Request Money",
            route: "/request-money-reports",
        }, {
            text: "Payment Deposit",
            route: "/payment-deposit-history",
        }, {
            text: "COD Request",
            route: "/cod-request-reports",
        }, {
            text: "Move to Distributor",
            route: "/move-to-distributor-reports",
        }]
    }, {
        text: "Transaction Recon",
        route: "/#",
    }, {
        text: "Login Report",
        route: "/#",
    }]
}]

export const STAFF = [{
    text: "Menu",
    route: "/#",
    submenu: [{
        text: "Create User",
        route: "/create-users",
    }, {
        text: "Manage User",
        route: "/manage-users",
    }, {
        text: "Ledger",
        route: "/myledger",
    }, {
        text: "Payment Approve",
        route: "/payment-deposit-list",
    }, {
        text: "COD Approve",
        route: "/cod-request-list",
    }]
}]

export const VENDER = [{
    text: "Menu",
    route: "/#",
    submenu: [{
        text: "Ledger",
        route: "/myledger",
    },{
        text: "MATM Purchase List",
        route: "/matm-order-list"
    }]
}]

export const getTopBarMenu = () => {
    let data = sessionStorage.getItem('user');
    let user = JSON.parse(data);
    let roleCode = user && user.data.role.code;

    if (user && user.data && user.data.isStaff) {
        return STAFF;
    }

    if (roleCode === "A" || roleCode === "SA") {
        return ADMIN;
    } else if (roleCode === "SH") {
        return STATE_HEAD;
    } else if (roleCode === "SD") {
        return SUPER_DISTRIBUTOR;
    } else if (roleCode === "MD") {
        return MASTER_DISTRIBUTOR;
    } else if (roleCode === "D") {
        return DISTRIBUTOR;
    } else if (roleCode === "P") {
        return PARTNER;
    } else if (roleCode === "V") {
        return VENDER;
    }
}

// export const getTopBarMenu = () => {
//     return new Promise((resolve, reject) => {
//         try {
//             let data = sessionStorage.getItem('user');
//             let user = JSON.parse(data);
//             let roleCode = user && user.data.role.code;

//             if (user && user.data && user.data.isStaff) {
//                 resolve(STAFF);
//             }

//             switch (roleCode) {
//                 case "A":
//                 case "SA":
//                     resolve(ADMIN);
//                     break;
//                 case "SH":
//                     resolve(STATE_HEAD);
//                     break;
//                 case "SD":
//                     resolve(SUPER_DISTRIBUTOR);
//                     break;
//                 case "MD":
//                     resolve(MASTER_DISTRIBUTOR);
//                     break;
//                 case "D":
//                     resolve(DISTRIBUTOR);
//                     break;
//                 case "P":
//                     resolve(PARTNER);
//                     break;
//                 case "V":
//                     resolve(VENDER);
//                     break;
//                 default:
//                     reject(new Error('Invalid role code'));
//             }
//         } catch (error) {
//             reject(error);
//         }
//     });
// };
