import axiosConfig from './axiosConfig';
// import { reactLocalStorage } from 'reactjs-localstorage';
import { removeUserSession } from './../Utils/Common';

import { SIGN_IN_URL, UNAUTHORISED_CODE, UNAUTHORISED_CODE_401 } from './../Utils/appConstants';
import { getToken, getPayUToken } from "./Common";
// import { useHistory } from "react-router-dom";
// const history = useHistory();

// function redirectLogin() {
//   if (typeof window !== 'undefined') {
//     window.location.href = "/login";
//   }
// }

export const getNewRequest = (url, data, history) => {
  const token = getToken()
  const PayUToken = getPayUToken()
  return new Promise((resolve, reject) => {
    return axiosConfig.get(url, { params: data, headers: { 'x-access-token': token, 'payu-access-token': PayUToken } })
      .then((response) => {
        if (response) {
          // console.log("state-----",response)
          return resolve(response.data)
        } else {
          return reject(response.data)
        }
      })
      .catch(function (error) {
        // console.log("err.response.statuscode-----", error.response.status);
        if (error.response && error.response.status && error.response.status.toString() === ((UNAUTHORISED_CODE || UNAUTHORISED_CODE_401))) {
          removeUserSession();
          history.push(SIGN_IN_URL);
        } else {
          return resolve(error && error.response && error.response.data)
        }
      });
  })
};


export const postPayURequest = (url, payload, history) => {
  const token = getToken()
  const PayUToken = getPayUToken()
  return new Promise((resolve, reject) => {
    return axiosConfig.post(url, payload, { headers: { 'x-access-token': token, 'payu-access-token': PayUToken } })
      .then((response) => {
        // console.log("response.data ===========",response.data)
        // if (response && response.data && response.data.code === API_SUCCESS_CODE) {
        if (response && response.data) {
          return resolve(response.data)
        } else {
          return reject(response.data)
        }
      })
      .catch(function (error) {
        // console.log("error ===========",error.response)
        if (error.response && error.response.status && (error.response.status.toString() === UNAUTHORISED_CODE || error.response.status.toString() === UNAUTHORISED_CODE_401)) {
          history.push(SIGN_IN_URL);
        } else {
          return resolve(error && error.response && error.response.data)
        }
      });
  });
};

export const getRequest = (url, history) => {
  const token = getToken()
  return new Promise((resolve, reject) => {
    return axiosConfig.get(url, { headers: { 'x-access-token': token } })
      .then((response) => {
        if (response) {
          // console.log("state-----",response)
          return resolve(response.data)
        }
        else {
          return reject(response.data)
        }
      })
      .catch(function (error) {
        if (error.response && error.response.status && (error.response.status.toString() === UNAUTHORISED_CODE || error.response.status.toString() === UNAUTHORISED_CODE_401)) {
          // console.log("error.response.status--11----", error.response.status)
          removeUserSession();
          history.push(SIGN_IN_URL);
        } else {
          return resolve(error && error.response && error.response.data)
        }
      });
  })
};

export const getRequestNewApi = (url, history) => {
  const token = getToken()
  return new Promise((resolve, reject) => {
    return axiosConfig.get(url, { headers: { 'x-access-token': token } })
      .then((response) => {
        return resolve(response.data)
      })
      .catch(function (error) {
        if (error.response && error.response.status && (error.response.status.toString() === UNAUTHORISED_CODE || error.response.status.toString() === UNAUTHORISED_CODE_401)) {
          history.push(SIGN_IN_URL);
        } else {
          return resolve(error && error.response && error.response.data)
        }
      });
  })
};

export const deleteRequestNewApi = (url, payload, history) => {
  const token = getToken()
  return new Promise((resolve, reject) => {
    let config = {}
    if (payload) {
      config.data = payload
    }
    config.headers = { 'x-access-token': token }
    // console.log("config 11------", config)
    return axiosConfig.delete(url, config)
      .then((response) => {
        // console.log("response 11------", response)
        if (response) {
          return resolve(response.data)
        } else {
          return reject(response.data)
        }
      })
      .catch(function (error) {
        if (error.response && error.response.status && (error.response.status.toString() === UNAUTHORISED_CODE || error.response.status.toString() === UNAUTHORISED_CODE_401)) {
          removeUserSession();
          history.push(SIGN_IN_URL);
        } else {
          return resolve(error && error.response && error.response.data)
        }
      });
  })
};

export const awsGetRequest = (url) => {
  return new Promise((resolve, reject) => {
    return axiosConfig.get(url)
      .then((response) => {
        return resolve(response.data)
      })
      .catch(function (error) {
        return reject(error)
      });
  })
};


export const postRequest = (url, payload, history) => {
  const token = getToken()
  return new Promise((resolve, reject) => {
    return axiosConfig.post(url, payload, { headers: { 'x-access-token': token } })
      .then((response) => {
        // if (response && response.data && response.data.code === API_SUCCESS_CODE) {
        // console.log("response ===========",response.data)
        if (response && response.data) {
          return resolve(response.data)
        } else {
          return reject(response.data)
        }
      })
      .catch(function (error) {
        // console.log("error ===========",error.response)
        if (error.response && error.response.status && (error.response.status.toString() === UNAUTHORISED_CODE || error.response.status.toString() === UNAUTHORISED_CODE_401)) {
          removeUserSession();
          history.push(SIGN_IN_URL);
        }
        else {
          return resolve(error && error.response && error.response.data)
        }
      });
  })
};


export const postDownloadRequest = (url, payload, history) => {
  const token = getToken()
  return new Promise((resolve, reject) => {
    return axiosConfig.post(url, payload, { responseType: "arraybuffer", headers: { 'x-access-token': token, "Content-Type": "application/zip", "Accept": "application/zip" } })
      .then((response) => {
        // if (response && response.data && response.data.code === API_SUCCESS_CODE) {
        // console.log("response ===========",response.data)
        if (response && response.data) {
          return resolve(response.data)
        } else {
          return reject(response.data)
        }
      })
      .catch(function (error) {
        // console.log("error ===========",error.response)
        if (error.response && error.response.status && (error.response.status.toString() === UNAUTHORISED_CODE || error.response.status.toString() === UNAUTHORISED_CODE_401)) {
          removeUserSession();
          history.push(SIGN_IN_URL);
        }
        else {
          return resolve(error && error.response && error.response.data)
        }
      });
  })
};

export const postRequestNewApi = (url, payload, history) => {
  const token = getToken()
  return new Promise((resolve, reject) => {
    return axiosConfig.post(url, payload, { headers: { 'x-access-token': token } })
      .then((response) => {
        return resolve(response.data)
      })
      .catch(function (error) {
        if (error.response && error.response.status && (error.response.status.toString() === UNAUTHORISED_CODE || error.response.status.toString() === UNAUTHORISED_CODE_401)) {
          history.push(SIGN_IN_URL);
        }
        else {
          return resolve(error && error.response && error.response.data)
        }
      });
  })
};

export const postRequestWithoutHistory = (url, payload) => {
  const token = getToken()
  return new Promise((resolve, reject) => {
    return axiosConfig.post(url, payload, { headers: { 'x-access-token': token } })
      .then((response) => {
        if (response && response.data) {
          return resolve(response.data)
        }
        else {
          return reject(response.data)
        }
      })
      .catch(function (error) {
        return reject(error)
      });
  })
};

export const putRequest = (url, payload, history) => {
  const token = getToken()
  // console.log("payload -------",payload)
  return new Promise((resolve, reject) => {
    return axiosConfig.put(url, payload, { headers: { 'x-access-token': token } })
      .then((response) => {
        if (response && response.data) {
          return resolve(response.data)
        }
        else {
          return reject(response.data)
        }
      })
      .catch(function (error) {
        if (error.response && error.response.status && (error.response.status.toString() === UNAUTHORISED_CODE || error.response.status.toString() === UNAUTHORISED_CODE_401)) {
          history.push(SIGN_IN_URL);
        } else {
          return resolve(error && error.response && error.response.data)
        }
      });
  })
};