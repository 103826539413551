import React, { useEffect, useState } from 'react';
// import { APP_KEY, API_URL } from './../config';
// import { CSVLink } from 'react-csv';
import DataTable from 'react-data-table-component';
import { getTransactionId } from '../../Utils/Common';
import { FUND_REQUEST_SAVE } from '../../Utils/appConstants';
// import { getPaymentMode, /* getBankMode */ } from './../../Utils/function';
import { postRequest } from '../../Utils/AppApi';
// import AppHeader from '../Header/Header';
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
// import { data } from 'jquery';
import ComplaintSubmit from './../../container/complaintSubmit';
import { Divider, Tooltip, Modal, DatePicker, Select } from 'antd';

function Complaint(props) {
    const history = useHistory();
    const dateFormat = 'DD-MM-YYYY';
    const [getDataField, setDataField] = useState([]);
    const [getDataError, setDataError] = useState({});
    const [isSubmit, setSubmit] = useState(false)

    const data = [{
        "date": "12-11-2023",
        "transactionId": "txt74372738",
        "mobile": "9786523214",
        "biller": "JVVNL",
        "amount": "276",
        "description": "Hi I have issue",
        "status": "Pending",
    }, {
        "date": "13-11-2023",
        "transactionId": "txt74372739",
        "mobile": "9786523214",
        "biller": "JVVNL",
        "amount": "3000",
        "description": "my bill pending please check",
        "status": "Resolved",
    }, {
        "date": "14-11-2023",
        "transactionId": "txt74372740",
        "mobile": "9786523214",
        "biller": "JVVNL",
        "amount": "2901",
        "description": "my bill is success but still is now showing",
        "status": "Resolved",
    },]

    const columns = [{
        name: 'Date',
        selector: row => <><Tooltip title={row.date}>{row.date}</Tooltip></>,
    }, {
        name: 'Transaction ID',
        selector: row => <><Tooltip title={row.transactionId}>{row.transactionId}</Tooltip></>,
    }, {
        name: 'Mobile',
        selector: row => <><Tooltip title={row.mobile}>{row.mobile}</Tooltip></>,
    }, {
        name: 'Biller',
        selector: row => <><Tooltip title={row.biller}>{row.biller}</Tooltip></>,
    }, {
        name: 'Amount',
        selector: row => <><Tooltip title={row.amount}>{row.amount}</Tooltip></>,
    }, {
        name: 'Description',
        selector: row => <><Tooltip title={row.description}>{row.description}</Tooltip></>,
    }, {
        name: 'Status',
        selector: row => <><Tooltip title={row.status}>{row.status}</Tooltip></>,
    }]

    const FormValidate = () => {
        let formValid = true;
        let formObject = {};
        setDataError({})
        if (getDataField && getDataField.length === 0) {
            formValid = false;
        }

        if (getDataField.description === "" || typeof getDataField.description === "undefined") {
            // console.log("element -------", element)
            formObject.description = true;
            formValid = false;
        }

        setDataError(formObject);
        return formValid;
    }

    const handleSubmit = () => {
        if (FormValidate(true)) {
            const payload = {
                FTRReferenceNumber: getDataField.FTRReferenceNumber,
                amount: getDataField.amount,
                bankName: getDataField.bankName,
                accountNumber: getDataField.accountNumber,
                mode: getDataField.mode,
                branchIfsc: getDataField.branchIfsc,
                location: getDataField.location,
                paymentDepositDate: getDataField.paymentDepositDate,
                uploadSlip: getDataField.uploadSlip,
                transactionId: getDataField.transactionId,
                remark: getDataField.remark,
            }
            postRequest(FUND_REQUEST_SAVE, payload, history).then((response) => {
                if (response.success === true) {
                    // setSubmit(true)
                    setDataField([]);
                    setDataError({});
                    toast.success(response.message);
                } else {
                    toast.error(response.message);
                }
            }).catch(function (error) {
                // console.log(error)
                // setLoading(false);
            })
        }
    } // eslint-disable-line react-hooks/exhaustive-deps

    const onChangeValue = (event) => {
        const { name, value } = event.target;
        // console.log("name -----------", name)
        // console.log("value -----------", value)
        setDataField({ ...getDataField, [name]: value });

    }

    const getID = async () => {
        const transactionId = await getTransactionId();
        console.log("Transaction ID:", transactionId);
        setDataField({ ...getDataField, txnReferenceId: transactionId });
    }

    useEffect(() => {
        getID()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            <div className="row">
                <div className="col-md-12">
                    <div className='card rounded-0'>
                        <div className='card-body'>
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <input type='text' name='txtId' className="form-control" placeholder='Transaction Id' />
                                    </div>
                                    {getDataError && getDataError.txtId && <span className="error text-danger">This field is required</span>}
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <textarea type="text" rows={3} onChange={(e) => onChangeValue(e)} className="form-control" name='description' placeholder="Complaint..." />
                                    </div>
                                    {getDataError && getDataError.description && <span className="error text-danger">Complaint is required</span>}
                                </div>
                            </div>
                            <div className="card-footer text-right">
                                <button type="submit" className="btn btn-primary col-md-2 rounded-pill pl-4 pr-4" onClick={() => handleSubmit()}>Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
                <Divider className='m-0 p-0' />
                <div className='card-body'>
                    <div className="content-header my-0 px-0 d-flex">
                        <h1 className='page-title text-blue font-merriweather mb-3'>Complaint <span className='text-red'>History</span></h1>
                        <div className="col-md-9 ml-4">
                            <div className='row'>
                                <div className='col-md-2'>
                                    <div className="form-group">
                                        <label className='date-search-label mb-0 text-blue'>From</label>
                                        <DatePicker className="form-control form-control-border" format={dateFormat} />
                                    </div>
                                </div>
                                <div className='col-md-2'>
                                    <div className="form-group">
                                        <label className='date-search-label mb-0 text-red'>To</label>
                                        <DatePicker className="form-control form-control-border" format={dateFormat} />
                                    </div>
                                </div>
                                <div className='col-md-2'>
                                    <div className="form-group">
                                        <label className='date-search-label mb-0 text-blue'>&nbsp;</label>
                                        <Select options={[
                                            { "label": "Transaction ID", "value": "ID" },
                                            { "label": "Account Number", "value": "account_number" },
                                            { "label": "Mobile", "value": "mobile" }
                                        ]} className={`normal-light w-100 form-control-border`} classNamePrefix="select" placeholder="Search By" name='month' />
                                    </div>
                                </div>
                                <div className='col-md-3'>
                                    <div className="form-group">
                                        <label className='date-search-label mb-0 text-blue'>&nbsp;</label>
                                        <input type="text" className="form-control form-control-border " placeholder="Search..." />
                                    </div>
                                </div>
                                <div className='col-md-2'>
                                    <div>
                                        <label className='date-search-label mb-0 text-red'>&nbsp;</label>
                                    </div>
                                    <button type="submit" className="btn btn-primary rounded-pill pl-4 pr-4">Search</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='history-table'>
                        <div className="container-fluid">
                            <div className="col-md-12">
                                <DataTable
                                    title={""}
                                    columns={columns}
                                    data={data}
                                    highlightOnHover
                                    pagination
                                    paginationServer
                                // paginationTotalRows={getTotalRecord}
                                // onChangeRowsPerPage={getDataReports}
                                // onChangePage={handlePageChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {isSubmit && (<Modal open={isSubmit} onCancel={() => setSubmit(false)} width={500} footer={false} title={<div className='col-md-12 row text-left'>
                    <div className='col-md-6'>
                        <img className='text-left' src='../logo.png' alt='photos' width={"50%"} />
                    </div>
                    <div className='col-md-6 text-right'>
                        <img className='text-right' src={'../images/bharat-billpay-logo-1@2x.png'} alt='photos' width={"40%"} />
                    </div>
                </div>}>
                    <ComplaintSubmit message="Your complaint has been submitted successfully. Complaint no. #876398" />
                </Modal>)}
                <ToastContainer position="top-center" autoClose={5000} limit={2} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="colored" />
            </div>
        </div>
    )
}

export default Complaint