import React, { useEffect, useState } from 'react';
// import { Tabs, Tab } from 'react-bootstrap-tabs';
// import AppHeader from './../Header/Header';
// import { useHistory } from "react-router-dom";
// import { ToastContainer, toast } from 'react-toastify';
// import AppFooter from './../Footer/Footer';

// import Commingsoon from '../../container/commingsoon';
// import Avatar from 'react-avatar';
import { Avatar } from 'antd';
// import { BBPS_BILLER_CATEGORY } from './../../Utils/appConstants';
import Common from './common';
import Complaint from './complaint';
import History from './history';
// import EletricityBillPayment from './eletricityBillPayment';
// import WaterBillPayment from './waterBillPayment';
// import GasBillPayment from './gasBillPayment';
// import InsuranceBillPayment from './insuranceBillPayment';
// import PipeGasBillPayment from './pipeGasBillPayment';
// import BroadbandBillPayment from './broadbandBillPayment';
// import FasTagBillPayment from './fasTagBillPayment';
// import PostPaidBillPayment from './postPaidBillPayment';
// import { getNewRequest } from '../../Utils/AppApi';
import 'antd/dist/antd.css';
import { Tabs } from 'antd';
const { TabPane } = Tabs;

function UitilityPayment(props) {
  // const dateFormat = 'DD/MM/YYYY';
  // const history = useHistory();
  const [tabName, setTabName] = useState(1);
  // const [billerCategory, setBillerCategory] = useState([]);
  const [selectCategory, setSelectCategory] = useState(null);

  const Recharge = [
    { "name": "DTH Recharge", "category": "DTH" },
    { "name": "Mobile Recharge", "category": "MOBILE PREPAID" },
    { "name": "Fastag Recharge", "category": "FASTAG" },
    { "name": "Metro Recharge", "category": "METRO" },
  ];
  const Home = [
    { "name": "Educational Fees", "category": "EDUCATIONAL FEES" },
    { "name": "Gas Pipe", "category": "GAS" },
    { "name": "Cable TV", "category": "CABLE" },
    { "name": "Mobile Postpaid", "category": "MOBILE POSTPAID" },
    { "name": "Broadband Postpaid", "category": "BROADBAND POSTPAID" },
    { "name": "LPG Gas", "category": "LPG GAS" },
    { "name": "Landline Postpaid", "category": "LANDLINE POSTPAID" },
    { "name": "Water Bill", "category": "WATER" },
    { "name": "OTT Payments", "category": "ENTERTAINMENT" },
    { "name": "Electricity", "category": "ELECTRICITY" },
    { "name": "Subscription", "category": "SUBSCRIPTION" }
  ];
  const Final = [
    { "name": "Loan Repayment", "category": "LOAN" },
    { "name": "Insurance", "category": "INSURANCE" },
    { "name": "Credit Card Payment", "category": "CREDIT CARD" },
    { "name": "Recurring Deposit", "category": "RECURRING DEPOSIT" }
  ];

  const Other = [
    { "name": "Muncipal Taxes", "category": "MUNCIPAL TAXES" },
    { "name": "Housing Society", "category": "HOUSING SOCIETY" },
    { "name": "Tax Challan", "category": "CHALLAN" },
    { "name": "Municipal Services", "category": "MUNICIPAL SERVICES" },
    { "name": "Clubs And Associations", "category": "CLUBS AND ASSOCIATIONS" },
    { "name": "Hospitals", "category": "HOSPITAL" },
    { "name": "Rentals", "category": "RENTAL" }
  ];

  const fixedCategory = [
    { "name": "Recharge", "data": Recharge },
    { "name": "Home Utility & Bills", "data": Home },
    { "name": "Financial Services", "data": Final },
    { "name": "Other Services", "data": Other },
  ];

  // const getBillerCategory = () => {
  //   getNewRequest(BBPS_BILLER_CATEGORY, history).then((response) => {
  //     if (response.success === true) {
  //       let modules = []
  //       response.data.map((ele) => {
  //         if (/[^ a-zA-Z]/.test(ele)) { return false } else {
  //           return modules.push(ele);
  //         }
  //       })
  //       setBillerCategory(modules.sort((a, b) => a > b ? 1 : -1));
  //     }
  //   }).catch(function (error) {
  //     // // console.log(error)
  //   })
  // }

  const onchangeTab = (index) => {
    // console.log("value -------", value)
    console.log("index -------", index)
    setTabName(index)
    // console.log("value -------", value.props.children[1])
  }

  const handleService = (module) => {
    setSelectCategory(module)
  }

  useEffect(() => {
    // getBillerCategory();
  }, [tabName]) // eslint-disable-line react-hooks/exhaustive-deps

  // if(Commingsoon) {
  // return ( 
  //   <div className="content-header my-0 py-0">
  //       <div className="container-fluid">
  //         <div className="row mb-0 align-items-center">
  //           <div class="col-sm-3"></div>
  //           <div className=" col-sm-6">
  //             <Commingsoon />
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //     )
  //   }

  return (
    <div>
      {/* <AppHeader /> */}
      <div className="content-wrapper pt-2">
        <div className="content">
          <div className="container-fluid pt-2 pb-5">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className='card rounded-0'>
                  <div className='card-body pt-2 five-xl-col'>
                    <Tabs className="recharge-tabs" defaultActiveKey={tabName} onChange={(index) => onchangeTab(index)} tabBarExtraContent={<div className='text-right col-lg-12'><img className='text-right pointer' onClick={() => setSelectCategory(false)} src={tabName === 1 ? '../images/bharat-billpay-logo-1@2x.png' : '../images/bharat-billpay-logo-1@2x.png'} width={tabName === 1 ? "30%" : "30%"} alt='photos' /></div>} size={"large"} >
                      <TabPane key={1} forceRender={true} tab={<div className='tabtext' title={"Services"}>Services</div>}>
                        {!selectCategory && <>
                          {fixedCategory.map((element) => {
                            return <><div className='dashboard-header font-merriweather'>{element.name}</div>
                              <div className="row mb-3">
                                {element.data && element.data.map((ele, index) => {
                                  return <div className='col-sm-6 col-md-4 col-lg-3 pointer '>
                                    <div className='dbox-outer' onClick={() => handleService(ele.category)} >
                                      <div className='text-center mb-2'>
                                        <Avatar size={50} style={{
                                          backgroundColor: '#1677ff',
                                        }}>{ele.name.split(" ").map(word => word[0]).join("")}</Avatar>
                                        {/* <Avatar name={ele.name} round={true} size="50" /> */}
                                      </div>
                                      <h5>{ele.name}</h5>
                                    </div>
                                  </div>
                                })}
                              </div></>
                          })}

                        </>}
                        {selectCategory && <Common category={selectCategory} />}
                      </TabPane>
                      <TabPane key={2} tab={<div className='tabtext' title={"History"}>History</div>}>
                        <History />
                      </TabPane>
                      <TabPane key={3} forceRender={true} tab={<div className='tabtext' title={"Complaint"}>Complaint</div>}>
                        <Complaint />
                      </TabPane>

                      {/* <TabPane key={1} tab={<div className='tabtext' title="Eletricity Bill"><span><img src='../images/electricity.svg' alt='' /></span>Eletricity</div>}>
                        <EletricityBillPayment />
                      </TabPane>
                      <TabPane key={2} tab={<div className='tabtext' title='Water Bill'><span><img src='../images/water.svg' alt='' /></span>Water</div>}>
                        <WaterBillPayment />
                      </TabPane>
                      <TabPane key={3} tab={<div className='tabtext' title='GAS Bill'><span><img src='../images/gas.svg' alt='' /></span>GAS</div>}>
                        <GasBillPayment />
                      </TabPane>
                      <TabPane key={4} tab={<div className='tabtext' title='Insurance Premium'><span><img src='../images/insurance.svg' alt='' /></span>Insurance</div>}>
                        <InsuranceBillPayment />
                      </TabPane>
                      <TabPane key={5} tab={<div className='tabtext' title='Pipe Gas'><span><img src='../images/pipe.png' alt='' width={"40px"} /></span>Pipe Gas</div>}>
                        <PipeGasBillPayment />
                      </TabPane>
                      <TabPane key={6} tab={<div className='tabtext' title='Broadband'><span><img src='../images/barodband.png' width={"40px"} alt='' /></span>Broadband</div>}>
                        <BroadbandBillPayment />
                      </TabPane>
                      <TabPane key={7} tab={<div className='tabtext' title='FasTag'><span><img src='../images/netc-fastag-logo-vector.svg' alt='' /></span>FasTag</div>}>
                        <FasTagBillPayment />
                      </TabPane>
                      <TabPane key={8} tab={<div className='tabtext' title='Postpaid'><span><img src='../images/postpaid.svg' alt='' width={"40px"} /></span>Postpaid</div>}>
                        <PostPaidBillPayment />
                      </TabPane> */}
                    </Tabs>
                  </div>
                </div>
              </div>
              {/* <div className="col-md-6">
                <div className='card rounded-0'>
                  <div className='card-body'>
                    <h3 className='text-blue text-center mb-3'>{tabName} <span className='text-red'>Payment</span></h3>
                    <div className='text-center'>
                      <img src='../images/offer.png' alt='img' />
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      {/* <AppFooter /> */}
    </div>
  )
}


export default UitilityPayment