import React, { useEffect, useState, useRef } from 'react';
// import CsvDownloader from 'react-csv-downloader';
import EletrictiyPaymentReport from './eletricityPayment';
import WaterPaymentReport from './waterPayment';
import GasPaymentReport from './gasPayment';
import InsurancePaymentReport from './insurancePayment';
import { uitilityOfflineStatusInArray } from './../../../Utils/Common';
// import { CSVLink } from 'react-csv';
// import DataTable from 'react-data-table-component';
// import AppHeader from './../../Header/Header';
import 'antd/dist/antd.css';
import { uitilityReports } from './../../../config/reportName';
// import { useHistory } from "react-router-dom";
// import { ToastContainer, toast } from 'react-toastify';
// import { MOBILE_RECHARGE_REPORT } from './../../../Utils/appConstants';
import moment from 'moment';
// import AppFooter from './../../Footer/Footer';
import { DatePicker, Input, Button, Select } from 'antd';
const { Option } = Select;
const { RangePicker } = DatePicker;

// import GasBillPayment from './gasBillPayment';
// import InsuranceBillPayment from './insuranceBillPayment';

function UitilityReport(props) {

    // const dateFormat = 'DD/MM/YYYY';
    // const history = useHistory();
    // const ref = React.createRef();
    // const csvLink = useRef()
    // const limit = 10;
    const dateFormat = 'DD-MM-YYYY';
    const modeSelected = useRef(null);
    // const dateRangeClearRef = useRef(null);
    const [getReportMode, setReportMode] = useState([]);
    const [getMode, setMode] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [dateRange, changeDateRange] = useState(null);
    const [date, setDate] = useState(null);
    const [searchStatus, setSearchStatus] = useState(null)
    const [search, setSearch] = useState(null);
    const handleClear = () => {
        setSearch(null)
        setStartDate(null)
        setEndDate(null)
        changeDateRange(null);
    }

    const onChangeDatePickerValue = (dates, dateStrings) => {
        // console.log('From: ', dates[0], ', to: ', dates[1]);
        // console.log('From: ', dateStrings[0], ', to: ', dateStrings[1]);
        setStartDate(dateStrings[0]);
        setEndDate(dateStrings[1]);
        if (dateStrings) {
            changeDateRange(returnMomentDateRange(dateStrings[0], dateStrings[1]));
        } else {
            changeDateRange(null);
        }
    }

    const returnMomentDateRange = (start, finish) => {
        return [moment(start, "DD-MM-YYYY"), moment(finish, "DD-MM-YYYY")];
    };

    const onChangeType = (value) => {
        // console.log(`selected ${value}`);
        setMode(value);
        setSearchStatus(null)
    }

    const onChangeTypeStatus = (value) => {
        setSearchStatus(value);
    }

    const onSearch = (value) => {
        // // console.log(`selected ${value}`);
        setSearch(value)
    }

    const handleSearch = () => {
        // console.log("new Date() ====",new Date())
        setDate(new Date());
    }

    useEffect(() => {
        setReportMode(uitilityReports);
    }, [date]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            {/* <AppHeader /> */}
            <div className="content-wrapper bg-white pt-4">
                <div className="content-header my-0 py-0">
                    <div className="container-fluid">
                        <div className="row mb-2 align-items-center">
                            <div className="col-sm-12 d-flex">
                                <div className="input-group col-md-2 ">
                                    <label className="form-label">Report Type:</label>
                                    <Select style={{ width: 200 }} placeholder="Select a type" ref={modeSelected} name='mode' onChange={onChangeType} >
                                        {getReportMode && getReportMode.map((ele) => {
                                            return <Option value={ele.name}>{ele.name}</Option>
                                        })}
                                    </Select>
                                </div>
                                <div className="input-group col-md-2 ">
                                    <label className="form-label">Report Status:</label>
                                    <Select options={uitilityOfflineStatusInArray} style={{ width: 200 }} placeholder="Select a status" name='status' onChange={onChangeTypeStatus} >
                                    </Select>
                                </div>
                                <div className="input-group col-md-3">
                                    <label>Date Range:</label>
                                    <RangePicker className="col-md-12" format={dateFormat} allowClear={false} onChange={onChangeDatePickerValue} value={dateRange} />
                                </div>
                                <div className="input-group col-md-3">
                                    <label className="form-label">Search:</label>
                                    <div className='col-md-12'>
                                        <Input placeholder="Search ...." onChange={(e) => onSearch(e.target.value)} style={{ width: "100%" }} value={search} />
                                    </div>
                                </div>
                                <div className="input-group col-md-1">
                                    <label className="form-label">&nbsp;</label>
                                    <div className='col-md-12'>
                                        <Button type="default" onClick={handleClear} className='ml-2'>Clear</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <div className="content">
                    { getMode === "Eletricity Report" && <EletrictiyPaymentReport mode={getMode} startDate={startDate} endDate={endDate} search={search} searchStatus={searchStatus} handleSearch={handleSearch}/> }
                    { getMode === "Water Report" && <WaterPaymentReport mode={getMode} startDate={startDate} endDate={endDate} search={search} searchStatus={searchStatus} handleSearch={handleSearch}/> }
                    { getMode === "Gas Report" && <GasPaymentReport mode={getMode} startDate={startDate} endDate={endDate} search={search} searchStatus={searchStatus} handleSearch={handleSearch}/> }
                    { getMode === "Insurance Report" && <InsurancePaymentReport mode={getMode} startDate={startDate} endDate={endDate} search={search} searchStatus={searchStatus} handleSearch={handleSearch}/> }
                </div>
            </div>
            {/* <ToastContainer position="top-center" autoClose={5000} limit={2} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="colored"/> */}
            {/* <AppFooter /> */}
        </div>
    )
}

export default UitilityReport