import React, { useEffect, useState, useRef } from 'react';
import DataTable from 'react-data-table-component';
import { GET_UPI_LOAD_MONEY_REPORT } from '../../Utils/appConstants';
import { getRequest } from '../../Utils/AppApi';
import { useHistory } from "react-router-dom";
import { DatePicker, Tooltip } from 'antd';
import { getUTCToLocalDateTimeFormat, queryDate, toDayDate } from '../../Utils/function';
import { getUser, hideFruntCharters } from '../../Utils/Common';
import { CSVLink } from 'react-csv';

function UpiLoadMoneyReport(props) {

    const history = useHistory();
    const csvLink = useRef();
    const user = getUser();
    const limit = 10;
    const dateFormat = 'DD-MM-YYYY';
    const [role, setRole] = useState(user && user.data && user.data.role.code);
    const [searchUserId, setSearchUserId] = useState(null);
    const [searchTransId, setSearchTransId] = useState(null);
    // const [searchMobile, setSearchMobile] = useState(null);
    // const [searchServiceName, setSearchServiceName] = useState(null);
    // const [searchAadhar, setSearchAadhar] = useState(null);
    const [endDate, setEndDate] = useState(toDayDate());
    const [startDate, setStartDate] = useState(toDayDate());
    const [getData, setData] = useState([]);
    const [getCsvData, setCsvData] = useState([]);
    const [getTotalRecord, setTotalRecord] = useState(0);
    const [page, setPage] = useState(1);
    const [isLimit, setLimit] = useState(limit);

    const columns = [{
        name: 'Trans ID',
        selector: row => <Tooltip placement="bottom" title={row.transactionId && row.transactionId}>{row.transactionId && row.transactionId}</Tooltip>,
        // width: "180px",
        headerStyle: (selector, id) => {
            return { textAlign: "center" };   // removed partial line here
        },
    }, {
        name: 'Date Time',
        cell: row => getUTCToLocalDateTimeFormat(row.createdAt),
        // width: "180px",
        headerStyle: (selector, id) => {
            return { textAlign: "center" };   // removed partial line here
        },
    }, {
        name: 'User ID',
        cell: row => row.userData.username,
        // width: "180px",
        headerStyle: (selector, id) => {
            return { textAlign: "center" };   // removed partial line here
        },
    }, {
        name: 'Name',
        cell: row => row.userData.name,
        // width: "180px",
        headerStyle: (selector, id) => {
            return { textAlign: "center" };   // removed partial line here
        },
    }, {
        name: 'Amount',
        selector: row => row.amount ? row.amount : 0,
        // width: "100px",
        headerStyle: (selector, id) => {
            return { textAlign: "center" };   // removed partial line here
        },
    }, {
        name: 'Status',
        selector: row => row.status,
        // width: "150px",
        headerStyle: (selector, id) => {
            return { textAlign: "center" };   // removed partial line here
        },
    }, {
        name: 'UPI ID',
        selector: row => hideFruntCharters(row.payerUserUpi, 4),
        // width: "100px",
        headerStyle: (selector, id) => {
            return { textAlign: "center" };   // removed partial line here
        },
    }, {
        name: 'bankRRN',
        selector: row => <Tooltip placement="bottom" title={row.bankRRN && row.bankRRN}>{row.bankRRN && row.bankRRN}</Tooltip>,
        // width: "150px",
        headerStyle: (selector, id) => {
            return { textAlign: "center" };   // removed partial line here
        },
    },  ]

    const objectToQueryString = (obj) => {
        var str = [];
        for (var p in obj)
            if (obj.hasOwnProperty(p)) {
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
        return str.join("&");
    }

    const params = { page: page, limit: isLimit };

    const getDataReports = (data) => {
        if (data) {
            setLimit(data)
            params.limit = data;
        }
        if (startDate && endDate) {
            params.startDate = startDate ? queryDate(startDate) : "";
            params.endDate = endDate ? queryDate(endDate) : "";
        }
        params.searchUserId = searchUserId ? searchUserId : "";
        params.searchTransId = searchTransId ? searchTransId : "";
        // params.searchMobile = searchMobile ? searchMobile : "";
        // params.searchServiceName = searchServiceName ? searchServiceName : "";
        // params.searchAadhar = searchAadhar ? searchAadhar : "";
        params.isAdmin = (role === "A") ? true : false;

        const queryString = objectToQueryString(params);
        getRequest(`${GET_UPI_LOAD_MONEY_REPORT}?${queryString}`, history).then((response) => {
            if (response.success === true) {
                setData(response.data);
                setTotalRecord(response.data.totalDocs);
                setPage(response.data.page);
            } else {
                setData([]);
                setTotalRecord(0);
                setPage(1);
            }
        }).catch(function (error) {
            console.log(error)
            // setLoading(false);
        })
    } // eslint-disable-line react-hooks/exhaustive-deps

    const getCSVDataList = () => {
        let params = {}
        params.exportsCsv = true;
        if (startDate && endDate) {
            params.startDate = startDate ? queryDate(startDate) : "";
            params.endDate = endDate ? queryDate(endDate) : "";
        }
        params.searchUserId = searchUserId ? searchUserId : "";
        params.searchTransId = searchTransId ? searchTransId : "";
        // params.searchMobile = searchMobile ? searchMobile : "";
        // params.searchServiceName = searchServiceName ? searchServiceName : "";
        // params.searchAadhar = searchAadhar ? searchAadhar : "";
        params.isAdmin = (role === "A") ? true : false;

        const queryString = objectToQueryString(params);
        getRequest(`${GET_UPI_LOAD_MONEY_REPORT}?${queryString}`, history).then((response) => {
            if (response.success === true) {
                let csvdataRecord = [];
                let csvdata = response.data;
                csvdata.map((e) => {
                    return csvdataRecord.push({
                        "Trans ID": e.transactionId,
                        "Date Time": getUTCToLocalDateTimeFormat(e.createdAt),
                        "User ID": e.userData.username,
                        "Name": e.userData.name,
                        "Amount": e.amount,
                        "Status": e.status_text,
                        "UPI ID": hideFruntCharters(e.payerUserUpi, 4),
                        "Bank RRN": e.bankRRN,
                    });
                });
                setCsvData(csvdataRecord);
                setTimeout(() => {
                    csvLink.current.link.click();
                }, 1000);
            } else {
                setCsvData([]);
            }
        }).catch(function (error) {
            console.log(error)
            // setLoading(false);
        })
    }

    const handlePageChange = (page, size) => {
        console.log("-----", size)
        setPage(page);
    };

    const onChangeStartDate = (date, dateString) => {
        // console.log(date, dateString);
        setStartDate(date)
    };

    const onChangeEndDate = (date, dateString) => {
        // console.log(date, dateString);
        setEndDate(date)
    };

    const handleClear = () => {
        setStartDate('');
        setEndDate('');
    }

    const handleSearch = () => {
        getDataReports();
    }

    // useEffect(() => {
    //     handleSearch();
    // }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        getDataReports();
        setRole(user && user.data && user.data.role.code);
    }, [page]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            <div className="content-wrapper pt-2">
                <div className="content-header my-0 py-0">
                    <div className="container-fluid">
                        <div className="row mb-0 align-items-center">
                            <div className="col-md-6">
                                <h1 className='page-title text-blue font-merriweather'>UPI Load Money <span className='text-red'>Report</span></h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content">
                    <div className="container-fluid pt-2 pb-5">
                        <div className="row">
                            <div className='card col-md-12 py-3'>
                                <div className='card-body p-0'>
                                    <div className="row">
                                        <div className='col-md-12 date-search mb-0'>
                                            <div className='row'>
                                                <div className='col-md-3 col-lg-3 d-flex'>
                                                    <div className="form-group mr-3">
                                                        <label className='date-search-label mb-0 text-blue'>From</label>
                                                        <DatePicker value={startDate} className="form-control form-control-border" format={dateFormat} onChange={onChangeStartDate} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label className='date-search-label mb-0 text-red'>To</label>
                                                        <DatePicker value={endDate} className="form-control form-control-border" format={dateFormat} onChange={onChangeEndDate} />
                                                    </div>
                                                </div>
                                                <div className='col-md-3 col-lg-3 d-flex'>
                                                    <div className="form-group mr-3">
                                                        <label className='date-search-label mb-0 text-red'></label>
                                                        <input type="text" className="form-control form-control-border" placeholder="User ID" onChange={(e) => setSearchUserId(e.target.value)} value={searchUserId} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label className='date-search-label mb-0 text-red'></label>
                                                        <input type="text" className="form-control form-control-border" placeholder="Trans ID" onChange={(e) => setSearchTransId(e.target.value)} value={searchTransId} />
                                                    </div>
                                                </div>

                                                <div className='col-md-1 col-lg-1'>
                                                    <div><label className='date-search-label mb-0 text-red'>&nbsp;</label></div>
                                                    <button type="submit" className="btn btn-primary rounded-pill pl-4 pr-4" onClick={() => handleSearch()}>Search</button>
                                                </div>

                                                <div className='col-md-1 col-lg-1'>
                                                    <div><label className='date-search-label mb-0 text-red'>&nbsp;</label></div>
                                                    <button type="button" className="btn btn-default rounded-pill ml-3 pl-4 pr-4 pull-right" onClick={() => handleClear()}>Reset</button>
                                                </div>

                                                
                                                <div className='col-md-1 col-lg-1'>
                                                    <div><label className='date-search-label mb-0 text-red'>&nbsp;</label></div>
                                                    <button type="button" className="btn btn-primary mr-3" onClick={() => getCSVDataList()} >Export</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-4'>
                                        <div className='float-sm-right'>
                                            {/* <button type="button" className="btn btn-primary mr-3" onClick={() => getCSVDataList()} ><i className="fa fa-file-excel"></i> Export</button> */}
                                            <CSVLink
                                                data={getCsvData}
                                                filename='upiloadmoney'
                                                className='hidden'
                                                ref={csvLink}
                                                target='_blank'
                                            />
                                        </div>
                                    </div>
                                    <div className="container-fluid pt-2 pb-5">
                                        <DataTable
                                            title={""}
                                            columns={columns}
                                            data={getData.docs}
                                            highlightOnHover
                                            pagination
                                            paginationServer
                                            paginationTotalRows={getTotalRecord}
                                            onChangeRowsPerPage={getDataReports}
                                            onChangePage={handlePageChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UpiLoadMoneyReport