import React, { Component } from 'react';
import { APP_KEY, API_URL } from './../config';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import { getToken } from './../Utils/Common';
// import AppHeader from './Header/Header';
import AppSidebar from './Sidebar/Sidebar';
// import AppFooter from './Footer/Footer';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';

const token = getToken();
const headers = { headers : { "app-key" : APP_KEY, "x-access-token" : token } }

class SiteSetting extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
        this.config = {}
        this.state = {
            loading : false,
            time : "12:00",
            fullday_salary : "",
            halfday_salary : "",
            min_service_time_per_day : "",
            salary_after_min_service : "",
            max_radius_delivery_partner : "",
            min_order_for_incentive_limit : "",
            incentive_amount_per_order : "",
            start_time : "",
            end_time : "",
            sender_id : "",
            data : [],
            total : 0,
            page : 0,
            size : 10,
            search : "",
            columns : [{
                name: 'Start Time',
                selector: 'start_time',
            },{
                name: 'End Time',
                selector: `end_time`,
            },{
                name: 'Action',
                cell: row => <><button className="btn btn-danger" id={row._id} onClick={this.handleDelete.bind(this, row._id)}><i className="fa fa-trash-o"></i></button></>,
            }],
            fields: {},
            fields1: {},
            fields2: {},
            fields_shift: {},
            errors: {},
        }

        this.handleUpdateGeneralSetting = this.handleUpdateGeneralSetting.bind(this);
        this.handleUpdateSMTP = this.handleUpdateSMTP.bind(this);
        this.handleUpdateCompanyInfo = this.handleUpdateCompanyInfo.bind(this);
    }

    handleValidationCompanyInfo() {
        let fields2 = this.state.fields2;
        let errors = {};
        let formIsValid = true;
    
        if (!fields2["company_name"]) {
          formIsValid = false;
          errors["company_name"] = "This field is required";
        }

        if (!fields2["company_address"]) {
            formIsValid = false;
            errors["company_address"] = "This field is required";
        }

        if (!fields2["company_phone"]) {
            formIsValid = false;
            errors["company_phone"] = "This field is required";
        }

        if (!fields2["meta_title"]) {
            formIsValid = false;
            errors["meta_title"] = "This field is required";
        } 

        if (!fields2["meta_keyword"]) {
            formIsValid = false;
            errors["meta_keyword"] = "This field is required";
        } 

        if (!fields2["meta_description"]) {
            formIsValid = false;
            errors["meta_description"] = "This field is required";
        } 

        this.setState({ errors: errors });
        return formIsValid;
    }

    getDataCompanyInfo = async (e) => {
        let url = API_URL+`/getcompanyinfo`;
        axios.get(url, headers).then(res => {
            if(res.data.success === true) {
                let fields2 = this.state.fields2;
                fields2 = {
                    company_info_id : res.data.data._id,
                    company_name: res.data.data.company_name,
                    company_address: res.data.data.company_address,
                    company_phone: res.data.data.company_phone,
                    meta_title: res.data.data.meta_title,
                    meta_keyword: res.data.data.meta_keyword,
                    meta_description: res.data.data.meta_description,
                    logo: res.data.data.logo,
                    favican: res.data.data.favican,
                    facebook: res.data.data.facebook,
                    gmail: res.data.data.gmail,
                    instagram: res.data.data.instagram,
                    linkedin: res.data.data.linkedin,
                    youtube: res.data.data.youtube,
                };
                this.setState({
                    fields2,
                    company_info_id : res.data.data._id,
                    company_name: res.data.data.company_name,
                    company_address: res.data.data.company_address,
                    company_phone: res.data.data.company_phone,
                    meta_title: res.data.data.meta_title,
                    meta_keyword: res.data.data.meta_keyword,
                    meta_description: res.data.data.meta_description,
                    logo: res.data.data.logo,
                    favican: res.data.data.favican,
                    facebook: res.data.data.facebook,
                    gmail: res.data.data.gmail,
                    instagram: res.data.data.instagram,
                    linkedin: res.data.data.linkedin,
                    youtube: res.data.data.youtube,
                });
            }
        }).catch(err => {
            console.log(err);
        });
    }

    handleUpdateCompanyInfo(e) {
        e.preventDefault();  
        this.setState({ loading : true });  
        if (this.handleValidationSMTP()) {
            console.log(this.state);
            var data = {
                company_name: this.state.company_name,
                company_address: this.state.company_address,
                company_phone: this.state.company_phone,
                meta_title: this.state.meta_title,
                meta_keyword: this.state.meta_keyword,
                meta_description: this.state.meta_description,
                logo: this.state.logo,
                favican: this.state.favican,
                facebook: this.state.facebook,
                gmail: this.state.gmail,
                instagram: this.state.instagram,
                linkedin: this.state.linkedin,
                youtube: this.state.youtube,
            }

            let id = this.state.company_info_id;
            axios.put(`${API_URL}/updatecompanyinfo/${id}`, data, headers ).then(res => {
                if(res.data.success === true) {
                    this.getDataCompanyInfo();
                    toast.success(res.data.message);
                } else {
                    toast.error(res.data.message);                    
                }
                this.setState({ loading : false });
            }).catch(err => {
                console.log(err);
                this.setState({ loading : false });
            })
        } else {
            //   alert("Form has errors.");
            this.setState({ loading : false });
        }
    }

    handleValidationSMTP() {
        let fields1 = this.state.fields1;
        let errors = {};
        let formIsValid = true;
    
        if (!fields1["host"]) {
          formIsValid = false;
          errors["host"] = "This field is required";
        }

        if (!fields1["port"]) {
            formIsValid = false;
            errors["port"] = "This field is required";
        }

        if (!fields1["email"]) {
            formIsValid = false;
            errors["email"] = "This field is required";
        }

        if (!fields1["sender_id"]) {
            formIsValid = false;
            errors["sender_id"] = "This field is required";
        } 

        this.setState({ errors: errors });
        return formIsValid;
    }

    getDataSMTP = async (e) => {
        let url = API_URL+`/getsmtp`;
        axios.get(url, headers).then(res => {
            if(res.data.success === true) {
                let fields1 = this.state.fields1;
                fields1 = {
                    host: res.data.data.host,
                    port: res.data.data.port,
                    email: res.data.data.email,
                    sender_id: res.data.data.sender_id,
                };
                this.setState({
                    fields1,
                    smtp_id : res.data.data._id,
                    host: res.data.data.host,
                    port: res.data.data.port,
                    email: res.data.data.email,
                    sender_id: res.data.data.sender_id,
                });
            }
        }).catch(err => {
            console.log(err);
        });
    }

    handleUpdateSMTP(e) {
        e.preventDefault();  
        this.setState({ loading : true });  
        if (this.handleValidationSMTP()) {
            var data = {
                host : this.state.host,
                port : this.state.port,
                email : this.state.email,
                sender_id : this.state.sender_id,
            }

            let id = this.state.smtp_id;
            axios.put(`${API_URL}/updatesmtp/${id}`, data, headers ).then(res => {
                if(res.data.success === true) {
                    this.getDataSMTP();
                    toast.success(res.data.message);
                } else {
                    toast.error(res.data.message);                    
                }
                this.setState({ loading : false });
            }).catch(err => {
                console.log(err);
                this.setState({ loading : false });
            })
        } else {
            //   alert("Form has errors.");
            this.setState({ loading : false });
        }
    }

    handleValidationGeneralSetting() {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;
        // console.log(fields)
        if (!fields["max_radius_delivery_partner"]) {
          formIsValid = false;
          errors["max_radius_delivery_partner"] = "This field is required";
        }

        if (!fields["fullday_salary"]) {
            formIsValid = false;
            errors["fullday_salary"] = "This field is required";
        }

        if (!fields["halfday_salary"]) {
            formIsValid = false;
            errors["halfday_salary"] = "This field is required";
        }

        if (!fields["min_service_time_per_day"]) {
            formIsValid = false;
            errors["min_service_time_per_day"] = "This field is required";
        }

        if (!fields["salary_after_min_service"]) {
            formIsValid = false;
            errors["salary_after_min_service"] = "This field is required";
        }

        if (!fields["min_order_for_incentive_limit"]) {
            formIsValid = false;
            errors["min_order_for_incentive_limit"] = "This field is required";
        }

        if (!fields["incentive_amount_per_order"]) {
            formIsValid = false;
            errors["incentive_amount_per_order"] = "This field is required";
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    getDataGeneralSetting = async (e) => {
        let url = API_URL+`/getgeneralsetting`;
        axios.get(url, headers).then( async res => {
            if(res.data.success === true) {
                let fields = this.state.fields;
                fields = {
                    max_radius_delivery_partner: res.data.data.max_radius_delivery_partner,
                    fullday_salary: res.data.data.fullday_salary,
                    halfday_salary: res.data.data.halfday_salary,
                    min_service_time_per_day: res.data.data.min_service_time_per_day,
                    salary_after_min_service: res.data.data.salary_after_min_service,
                    min_order_for_incentive_limit: res.data.data.min_order_for_incentive_limit,
                    incentive_amount_per_order: res.data.data.incentive_amount_per_order,
                };
                await this.setState({
                    fields,
                    general_setting_id : res.data.data._id,
                    max_radius_delivery_partner: res.data.data.max_radius_delivery_partner,
                    fullday_salary: res.data.data.fullday_salary,
                    halfday_salary: res.data.data.halfday_salary,
                    min_service_time_per_day: res.data.data.min_service_time_per_day,
                    salary_after_min_service: res.data.data.salary_after_min_service,
                    min_order_for_incentive_limit: res.data.data.min_order_for_incentive_limit,
                    incentive_amount_per_order: res.data.data.incentive_amount_per_order,
                });
            }
        }).catch(err => {
            console.log(err);
        });
    }

    handleUpdateGeneralSetting(e) {
        e.preventDefault();  
        this.setState({ loading : true });  
        if (this.handleValidationGeneralSetting()) {
            var data = {
                max_radius_delivery_partner : this.state.max_radius_delivery_partner,
                fullday_salary : this.state.fullday_salary,
                halfday_salary : this.state.halfday_salary,
                min_service_time_per_day : this.state.min_service_time_per_day,
                salary_after_min_service : this.state.salary_after_min_service,
                min_order_for_incentive_limit : this.state.min_order_for_incentive_limit,
                incentive_amount_per_order : this.state.incentive_amount_per_order,
            }
            let id = this.state.general_setting_id;
            axios.put(`${API_URL}/updategeneralsetting/${id}`, data, headers ).then(res => {
                if(res.data.success === true) {
                    this.getDataGeneralSetting();
                    toast.success(res.data.message);
                } else {
                    toast.error(res.data.message);                    
                }
                this.setState({ loading : false });
            }).catch(err => {
                console.log(err);
                this.setState({ loading : false });
            })
        } else {
            //   alert("Form has errors.");
            this.setState({ loading : false });
        }
    }

    handleChange = async (e) => {
        await this.setState({ [e.target.name ] : e.target.value });
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({ fields, loading : false });        
    }

    handleShiftChange = async (e) => {
        console.log(e.target.value);
        await this.setState({ [e.target.name ] : e.target.value });
        let fields_shift = this.state.fields_shift;
        fields_shift[e.target.name] = e.target.value;
        this.setState({ fields_shift, loading : false });        
    }

    handleReset = async (e) => {
        // Array.from(document.querySelectorAll("input")).forEach(
        //     input => (input.value = "")
        // );
        document.getElementById('start_time').value = null;
        document.getElementById('end_time').value = null;
        await this.setState({ start_time : "", end_time : "" });        
    }

    handleShiftValidation() {
        let fields_shift = this.state.fields_shift;
        let errors = {};
        let formIsValid = true;

        if (!fields_shift["start_time"]) {
            formIsValid = false;
            errors["start_time"] = "This field is required";
        }

        if (!fields_shift["end_time"]) {
            formIsValid = false;
            errors["end_time"] = "This field is required";
        }
    
        this.setState({ errors: errors });
        return formIsValid;
    }

    handleDelete = async (id) => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
              confirmButton: 'btn btn-success',
              cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
          })
          
        swalWithBootstrapButtons.fire({
            title: 'Are you sure?',
            text: "Once deleted, you will not be able to recover this record!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                let url = API_URL+`/compulsoryshift/${id}`;
                axios.delete(url, headers).then(res => {
                    if(res.data.success === true) {
                        toast.success(res.data.message);
                        this.getShiftList();
                    } else {
                        toast.error(res.data.message);
                    }
                });
                swalWithBootstrapButtons.fire('Deleted!','Your record has been deleted.','success');
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              swalWithBootstrapButtons.fire(
                'Cancelled',
                'Your record is safe',
                'error'
              )
            }
        });
    }
    getShiftList = () => {
        let url = API_URL+`/compulsoryshift?page=${this.state.page}&size=${this.state.size}&search=${this.state.search}`;
        axios.get(url, headers).then(res => {
            if(res.data.success === true) {
                // alert(res.data.data.totalDocs)
                this.setState({
                    total: res.data.data.totalDocs,
                    data: res.data.data.docs
                })
            }
        })
    }

    handleSubmit(e) {
        e.preventDefault();  
        this.setState({ loading : true });  
        if (this.handleShiftValidation()) {
            var data = {
                start_time : this.state.start_time,
                end_time : this.state.end_time
            }

            axios.post(`${API_URL}/compulsoryshift`, data, headers ).then(res => {
                if(res.data.success === true) {
                    this.handleReset();
                    this.getShiftList();
                    toast.success(res.data.message);
                    this.myRef.current.click();
                } else {
                    toast.error(res.data.message);
                    this.setState({ loading : false });
                }
            }).catch(err => {
                console.log(err);
                this.setState({ loading : false });
            })
        } else {
            //   alert("Form has errors.");
            this.setState({ loading : false });
        }
    }
    
    // handleSearch = async (e) => {
    //     await this.setState({ search : e.target.value });
    //     this.getShiftList();
    // }

    handlePage = async (page) => {
        await this.setState({ page : (page - 1)});
        this.getShiftList();
    }

    uploadImage = async (e) => {
        const data = new FormData();
        data.append('avatar', e.target.files[0]);
        var headers = { headers : { "app-key" : APP_KEY, 'content-type' : 'multipart/form-data' } }
        axios.post(`${API_URL}/upload`, data, headers ).then( async res => {
            if(res.data.success === true) {
                await this.setState({ [e.target.name] : res.data.data.path });
            }
        }).catch(err => {
            console.log(err);
        })
    }

    inputNumber = (event) => {
        if (!/[0-9]/.test(event.key)) {
            event.preventDefault();
        }
    }

    componentDidMount(){
        this.getDataGeneralSetting();
        this.getDataSMTP();
        this.getDataCompanyInfo();
        this.getShiftList();
    }

    render() {
        return (<div>
            <ToastContainer position="top-center" autoClose={5000} limit={2} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="colored"/>
            {/* <AppHeader /> */}
            <AppSidebar />  
            <div className="content-wrapper">
                <section className="content-header">
                <h1> Site Settings </h1>
                <ol className="breadcrumb">
                    <li><a href="/#"><i className="fa fa-dashboard"></i> Home</a></li>
                    <li className="active">Site Settings</li>
                </ol>
                </section>
        
                <section className="content">
                    <div className="row">
                        <div className="col-xs-12">
                            <div className="nav-tabs-custom">
                                <ul className="nav nav-tabs">
                                    <li className="active"><a href="#smtp" data-toggle="tab" aria-expanded="false">SMTP Details</a></li>
                                    <li className=""><a href="#company-info" data-toggle="tab" aria-expanded="false">Company Info</a></li>
                                    <li className=""><a href="#general-setting" data-toggle="tab" aria-expanded="true">General Setting</a></li>
                                    <li className=""><a href="#compulsory-shift" data-toggle="tab" aria-expanded="true">Compulsory Shift</a></li>
                                </ul>
                                <div className="tab-content no-padding">
                                    <div className="chart tab-pane active" id="smtp" style={{position: "relative"}}>
                                        <div style={{margin: "0px 20px"}}>
                                            <div className="box-body">
                                                <form onSubmit={this.handleUpdateSMTP}>
                                                    <div className="row">
                                                        <div className="form-group col-md-6">
                                                            <label>Host <i className="text-red">*</i></label><input type="text" name="host" id="host" className="form-control" onChange={this.handleChange} placeholder="Host" value={this.state.host} />
                                                            <div className="text-red">{this.state.errors["host"]}</div>
                                                        </div>
                                                        <div className="form-group col-md-6">
                                                            <label>Port <i className="text-red">*</i></label><input type="text" name="port" id="port" className="form-control " onChange={this.handleChange} placeholder="Port" maxlength="5" value={this.state.port} />
                                                            <div className="text-red">{this.state.errors["port"]}</div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="form-group col-md-6">
                                                            <label>Email <i className="text-red">*</i></label><input type="email" onChange={this.handleChange} name="email" id="email" className="form-control " placeholder="Email" value={this.state.email} />
                                                            <div className="text-red">{this.state.errors["email"]}</div>
                                                        </div>
                                                        <div className="form-group col-md-6">
                                                            <label>Sender Id <i className="text-red">*</i></label><input type="text" name="sender_id" id="sender_id" className="form-control " placeholder="Sender Id" onChange={this.handleChange} maxlength="50" value={this.state.sender_id} />
                                                            <div className="text-red">{this.state.errors["sender_id"]}</div>
                                                        </div>
                                                    </div>
                                                    <button type="submit" className="btn btn-primary pull-right">Submit</button>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="chart tab-pane" id="company-info" style={{position: "relative"}}>
                                        <div style={{margin: "0px 20px"}}>
                                            <div className="box-body">
                                                <form onSubmit={this.handleUpdateCompanyInfo}>
                                                    <div className="row">
                                                        <div className="form-group col-md-6">
                                                            <label>Company Name <i className="text-red">*</i></label><input type="text" name="company_name" id="company_name" className="form-control " placeholder="Company Name" maxlength="40" onChange={this.handleChange} value={this.state.company_name} />
                                                            <div className="text-red">{this.state.errors["company_name"]}</div>
                                                        </div>
                                                        <div className="form-group col-md-6">
                                                            <label>Company Address <i className="text-red">*</i></label><input type="text" name="company_address" id="company_address" className="form-control " placeholder="Company Address" maxlength="50" onChange={this.handleChange} value={this.state.company_address} />
                                                            <div className="text-red">{this.state.errors["company_address"]}</div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="form-group col-md-6">
                                                            <label>Company Phone <i className="text-red">*</i></label><input type="text" name="company_phone" id="company_phone" className="form-control " placeholder="Company Phone" onChange={this.handleChange} maxlength="10" value={this.state.company_phone} />
                                                            <div className="text-red">{this.state.errors["company_phone"]}</div>
                                                        </div>
                                                        <div className="form-group col-md-6">
                                                            <label>Logo</label><input type="file" name="logo" id="logo" onChange={this.uploadImage} />
                                                            <div className="text-red"></div>
                                                            {
                                                                (this.state.logo) ? <div className="pull-right"><img src={this.state.logo} width="80" alt="" /></div> : null
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="form-group col-md-6">
                                                            <label>Meta Title <i className="text-red">*</i></label><input type="text" name="meta_title" id="meta_title" className="form-control " placeholder="Meta Title" onChange={this.handleChange} value={this.state.meta_title} />
                                                            <div className="text-red">{this.state.errors["meta_title"]}</div>
                                                        </div>
                                                        <div className="form-group col-md-6">
                                                            <label>Favican</label><input type="file" name="favican" id="favican" onChange={this.uploadImage} />
                                                            <div className="text-red"></div>
                                                            {
                                                                (this.state.favican) ? <div className="pull-right"><img src={this.state.favican} width="80" alt="" /></div> : null
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="form-group col-md-6">
                                                            <label>Meta Keyword <i className="text-red">*</i></label>
                                                            <textarea name="meta_keyword" id="meta_keyword" className="form-control " onChange={this.handleChange} rows="5" defaultValue={this.state.meta_keyword}></textarea>
                                                            <div className="text-red">{this.state.errors["meta_keyword"]}</div>
                                                        </div>
                                                        <div className="form-group col-md-6">
                                                            <label>Meta Description <i className="text-red">*</i></label>
                                                            <textarea name="meta_description" id="meta_description" className="form-control" onChange={this.handleChange} rows="5" defaultValue={this.state.meta_description}></textarea>
                                                            <div className="text-red">{this.state.errors["meta_description"]}</div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="form-group col-md-6">
                                                            <label>Facebook</label><input type="text" name="facebook" id="facebook" className="form-control" onChange={this.handleChange} placeholder="Facebook" value={this.state.facebook} />
                                                            <div className="text-red">{this.state.errors["facebook"]}</div>
                                                        </div>
                                                        <div className="form-group col-md-6">
                                                            <label>Google</label><input type="text" name="gmail" id="gmail" className="form-control " placeholder="Google" onChange={this.handleChange} value={this.state.gmail} />
                                                            <div className="text-red">{this.state.errors["gmail"]}</div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="form-group col-md-6">
                                                            <label>Youtube</label><input type="text" name="youtube" id="youtube" className="form-control" onChange={this.handleChange} placeholder="Youtube" value={this.state.youtube} />
                                                            <div className="text-red">{this.state.errors["youtube"]}</div>
                                                        </div>
                                                        <div className="form-group col-md-6">
                                                            <label>Linkedin</label><input type="text" name="linkedin" id="linkedin" className="form-control " placeholder="Linked In" onChange={this.handleChange} value={this.state.linkedin} />
                                                            <div className="text-red">{this.state.errors["linkedin"]}</div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="form-group col-md-6">
                                                            <label>Instagram</label><input type="text" name="instagram" id="instagram" className="form-control " placeholder="Instagram" onChange={this.handleChange} value={this.state.instagram} />
                                                            <div className="text-red">{this.state.errors["instagram"]}</div>
                                                        </div>
                                                    </div>
                                                    <button type="submit" className="btn btn-primary pull-right" disabled={this.state.loading}>{this.state.loading ? 'Loading...' : 'Submit'}</button>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="chart tab-pane" id="general-setting" style={{position: "relative"}}>
                                        <div style={{margin: "0px 20px"}}>
                                            <div className="box-body">
                                                <form onSubmit={this.handleUpdateGeneralSetting}>
                                                    <div className="row">
                                                        <div className="form-group col-md-6">
                                                            <label>Full Day Salary (RS) <i className="text-red">*</i></label><input type="text" name="fullday_salary" id="fullday_salary" className="form-control " placeholder="Full Day Salary" maxlength="6" onChange={this.handleChange} onKeyDown={this.inputNumber} value={this.state.fullday_salary} />
                                                            <div className="text-red">{this.state.errors["fullday_salary"]}</div>
                                                        </div>
                                                        <div className="form-group col-md-6">
                                                            <label>Half Day Salary (RS) <i className="text-red">*</i></label><input type="text" name="halfday_salary" id="halfday_salary" className="form-control " placeholder="Half Day Salary" maxlength="6" onChange={this.handleChange} onKeyDown={this.inputNumber} value={this.state.halfday_salary} />
                                                            <div className="text-red">{this.state.errors["halfday_salary"]}</div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="form-group col-md-6">
                                                            <label>Minimum Service Hours (In Hours) <i className="text-red">*</i></label><input type="text" name="min_service_time_per_day" id="min_service_time_per_day" className="form-control " placeholder="Minimum Service Hours" maxlength="6" onChange={this.handleChange} onKeyDown={this.inputNumber} value={this.state.min_service_time_per_day} />
                                                            <div className="text-red">{this.state.errors["min_service_time_per_day"]}</div>
                                                        </div>
                                                        <div className="form-group col-md-6">
                                                            <label>Rate Per Hour After Minimum Hours (RS) <i className="text-red">*</i></label><input type="text" name="salary_after_min_service" id="salary_after_min_service" className="form-control " placeholder="Rate Per Hour After Minimum Hours" maxlength="6" onChange={this.handleChange} onKeyDown={this.inputNumber} value={this.state.salary_after_min_service} />
                                                            <div className="text-red">{this.state.errors["salary_after_min_service"]}</div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="form-group col-md-6">
                                                            <label>Minimum Orders For Incentive <i className="text-red">*</i></label><input type="text" name="min_order_for_incentive_limit" id="min_order_for_incentive_limit" className="form-control " placeholder="Minimum Orders For Incentive" maxlength="6" onChange={this.handleChange} onKeyDown={this.inputNumber} value={this.state.min_order_for_incentive_limit} />
                                                            <div className="text-red">{this.state.errors["min_order_for_incentive_limit"]}</div>
                                                        </div>
                                                        <div className="form-group col-md-6">
                                                            <label>Incentive Per Order(Rs) <i className="text-red">*</i></label><input type="text" name="incentive_amount_per_order" id="incentive_amount_per_order" className="form-control " placeholder="Incentive Per Order(Rs)" maxlength="6" onChange={this.handleChange} onKeyDown={this.inputNumber} value={this.state.incentive_amount_per_order} />
                                                            <div className="text-red">{this.state.errors["incentive_amount_per_order"]}</div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="form-group col-md-6">
                                                            <label>Max Radius Delivery Partners (KM)</label><input type="text" name="max_radius_delivery_partner" id="max_radius_delivery_partner" className="form-control " placeholder="max_radius_delivery_partner" maxlength="6" onChange={this.handleChange} onKeyDown={this.inputNumber} value={this.state.max_radius_delivery_partner} />
                                                            <div className="text-red">{this.state.errors["max_radius_delivery_partner"]}</div>
                                                        </div>
                                                    </div>
                                                    <button type="submit" className="btn btn-primary pull-right" disabled={this.state.loading}>{this.state.loading ? 'Loading...' : 'Submit'}</button>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="chart tab-pane" id="compulsory-shift" style={{position: "relative"}}>
                                        <div style={{margin: "0px 20px"}}>
                                            <div className="box-body">
                                            <DataTable
                                                title={<div id="example1_filter" className="dataTables_filter"><button className="btn btn-success pull-right" data-toggle="modal" data-target="#modal-default" onClick={this.handleReset.bind(this)}>Add Shift</button></div>}
                                                columns={this.state.columns}
                                                data={this.state.data}
                                                highlightOnHover
                                                pagination
                                                paginationServer
                                                paginationTotalRows={this.state.total}
                                                paginationPerPage={this.state.size}
                                                paginationComponentOptions={{
                                                    noRowsPerPage: true
                                                }}
                                                onChangePage={this.handlePage}
                                            />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>                    
                </section>
            </div>
            {/* Add Shift */}
            <div className="modal fade" id="modal-default">
                <div className="modal-dialog modal-sm">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleReset.bind(this)} ref={this.myRef}>
                            <span aria-hidden="true">&times;</span></button>
                            <h4 className="modal-title">Add Shift</h4>
                        </div>
                        <div className="modal-body">
                            <div className="box">
                                <div className="box-body">
                                    <div className="form-group">
                                        <label>Start Time <i className="text-red">*</i></label><input type="time" name="start_time" id="start_time" className="form-control clear" placeholder="Start Time" onChange={this.handleShiftChange}/>
                                        <div className="text-red">{this.state.errors["start_time"]}</div>
                                    </div>
                                    <div className="form-group">
                                        <label>End Time <i className="text-red">*</i></label><input type="time" name="end_time" id="end_time" className="form-control clear" placeholder="End Time" onChange={this.handleShiftChange}/>
                                        <div className="text-red">{this.state.errors["end_time"]}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">                            
                            <button type="button" className="btn btn-default" data-dismiss="modal" ref={this.myRef} onClick={this.handleReset.bind(this)} >Clear</button>
                            <button type="button" className="btn btn-primary" onClick={this.handleSubmit.bind(this)} disabled={this.state.loading}>{this.state.loading ? 'Loading...' : 'Save'}</button>          
                        </div>
                    </div>
                </div>
            </div>
            {/* <AppFooter /> */}
        </div>);
    }
}

export default SiteSetting;