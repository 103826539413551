import React, { useEffect, useState, useRef } from 'react';
import DataTable from 'react-data-table-component';
import { isNumber } from './../../../Utils/Common';
import { GET_SERVICES_LIST, GET_SERVICES } from './../../../Utils/appConstants';
import { getRequest, postRequest, putRequest } from './../../../Utils/AppApi';
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import { Switch, Modal } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import CreateOperator from './createOperator';
// import Select from 'react-select';

function CreateServices(props) {
  const history = useHistory();
  // const csvLink = useRef()
  const limit = 10;
  // const dateFormat = 'DD-MM-YYYY';
  // const [packageName, setPackageName] = useState(null);
  const [serviceId, setServiceId] = useState(null);
  // const [startDate, setStartDate] = useState(null);
  const [getDataField, setDataField] = useState([]);
  const [getDataError, setDataError] = useState([]);
  const [getData, setData] = useState([]);
  //   const [getBankModes, setBankModes] = useState([]);
  const [getTotalRecord, setTotalRecord] = useState(0);
  const [page, setPage] = useState(1);
  const [isEdit, setEditModal] = useState(false);
  const [addOperatorModal, setAddOperatorModal] = useState(false);
  // const [serviceData, setServiceData] = useState([]);
  // const [serviceCode, setServiceCode] = useState(null);
  // const [isFindOperator, setFindOperator] = useState(false);

  // const [isOperatorData, setOperatorData] = useState([]);
  const nameInput = useRef(null);
  const codeInput = useRef(null);
  // const serviceSelect = useRef(null);
  // const maxInput = useRef(null);
  // const minInput = useRef(null);
  // const commissionInput = useRef(null);
  // const serviceChargeInput = useRef(null);
  // const tdsInput = useRef(null);

  const clearFormField = () => {
    nameInput.current.value = "";
    codeInput.current.value = "";
    setDataField([]);
    setDataError({});
    setEditModal(false);
  };

  const removeLabelOpp = ['105','106']

  const columns = [{
    name: 'S. No.',
    selector: (row, index) => page === 1 ? (index + 1) : ((page - 1) * limit) + (index + 1)
  }, {
    name: 'Name',
    selector: row => row.name,
  }, {
    name: 'Code',
    selector: row => row.code,
  }, {
    name: 'Is Operator',
    selector: row => (row.isOperatorCommission) ? "Yes" : "No",
  }, {
    name: 'Total Operator',
    selector: row => (row.serviceoperators && row.serviceoperators.length > 0) ? row.serviceoperators[0] && row.serviceoperators[0].count : 0,
  }, {
    name: 'Action',
    cell: row => <><div className='text-primary pointer' onClick={() => handleEditView(row)}>Edit</div>
      {(!removeLabelOpp.includes(row.code) && row.isOperatorCommission) ? <>&nbsp;&nbsp;|&nbsp;&nbsp;<div className='text-primary pointer' onClick={() => handleAddOperator(row)}>Add Operator</div></> : ""}</>,
  }];

  const objectToQueryString = (obj) => {
    var str = [];
    for (var p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    return str.join("&");
  }; // eslint-disable-line react-hooks/exhaustive-deps

  const params = { page: page, limit: limit };
  const getDataReports = () => {
    const queryString = objectToQueryString(params);
    getRequest(`${GET_SERVICES_LIST}?${queryString}`, history).then((response) => {
      if (response.success === true) {
        setData(response.data);
        setTotalRecord(response.data.totalDocs);
        setPage(response.data.page);
      } else {
        setData([]);
        setTotalRecord(0);
        setPage(1);
      }
    }).catch(function (error) {
      console.log(error);
      // setLoading(false);
    });
  };

  const handlePageChange = page => {
    setPage(page);
  };

  const onChangeValue = (event) => {
    const { name, value } = event.target;
    setDataField({ ...getDataField, [name]: value });
  }

  const onChangeOperator = (checked) => {
    setDataField({ ...getDataField, "isOperatorCommission": checked });
  };

  const FormValidate = () => {
    let formValid = true;
    let formObject = [];
    setDataError({});
    if (getDataField && getDataField.length === 0) {
      formValid = false;
    }

    setDataError(formObject);
    return formValid;
  };

  const handleEditView = (data) => {
    setDataField(data);
    setEditModal(true);
  };

  const handleSubmit = () => {
    // console.log("FormValidate ------------", FormValidate())
    if (FormValidate(true)) {
      const payload = {
        name: getDataField.name,
        code: getDataField.code,
        isOperatorCommission: getDataField.isOperatorCommission
      };

      postRequest(GET_SERVICES, payload, history).then((response) => {
        if (response.success === true) {
          setDataField([]);
          setDataError({});
          clearFormField();
          getDataReports();
          toast.success(response.message);
        } else {
          toast.error(response.message);
        }
      }).catch(function (error) {
        // console.log(error)
        // setLoading(false);
      });
    }
  };

  const handleEdit = () => {
    if (FormValidate(true)) {
      const payload = {
        name: getDataField.name,
        code: getDataField.code,
        isOperatorCommission: getDataField.isOperatorCommission
      };
      putRequest(`${GET_SERVICES}/${getDataField._id}`, payload, history).then((response) => {
        if (response.success === true) {
          setDataField([]);
          setDataError({});
          clearFormField();
          getDataReports();
          toast.success(response.message);
        } else {
          toast.error(response.message);
        }
      }).catch(function (error) {
        // console.log(error)
        // setLoading(false);
      });
    }
  };

  //   const handleOperatorSubmit = (event, id, value) => {
  //     let payload = { status: value };
  //     postRequest(`${GET_SERVICES_LIST}/${id}`, payload, history).then((response) => {
  //       // console.log("response -------", response)
  //       if (response.success === true) {
  //         setDataField([]);
  //         setDataError({});
  //         clearFormField();
  //         getDataReports();
  //         toast.success(response.message);
  //       } else {
  //         toast.error(response.message);
  //       }
  //     }).catch(function (error) {
  //       console.log(error)
  //     })
  //   } // eslint-disable-line react-hooks/exhaustive-deps
  // const handleDelete = (event, id) => {
  //   let payload = { removed: false };
  //   deleteRequestNewApi(`${GET_SERVICES_LIST}/${id}`, payload, history).then((response) => {
  //     // console.log("response -------", response)
  //     if (response.success === true) {
  //       setDataField([]);
  //       setDataError({});
  //       clearFormField();
  //       getDataReports();
  //       toast.success(response.message);
  //     } else {
  //       toast.error(response.message);
  //     }
  //   }).catch(function (error) {
  //     console.log(error)
  //   })
  // } // eslint-disable-line react-hooks/exhaustive-deps

  const handleAddOperator = (data) => {
    setServiceId(data._id)
    setAddOperatorModal(true)
    setDataField([]);
    setDataError({});
  };

  useEffect(() => {
    getDataReports();
  }, [page]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      {/* <AppHeader /> */}
      <div className="content-wrapper pt-2">
        <div className="content-header my-0 py-0">
          <div className="container-fluid">
            <div className="row mb-0 align-items-center">
              <div className="col-sm-6">
                <h1 className='page-title text-blue font-merriweather'>Services <span className='text-red'></span></h1>
              </div>
            </div>
          </div>
        </div>
        <div className="content">
          <div className="container-fluid pt-2 pb-5">
            <div className="row">
              <div className="col-md-12">
                <div className='card rounded-0'>
                  <div className='card-body'>
                    <div className='row'>
                      <div className='col-sm-6 col-md-4 col-lg-3'>
                        <div className="form-group">
                          <label>Service Name</label>
                          <input type="text" className={`form-control normal-light ${getDataError && getDataError.name ? "is-invalid" : ""}`} name="name" ref={nameInput} maxLength={30} onChange={(e) => onChangeValue(e)} placeholder="Service Name*" value={getDataField && getDataField.name} />
                          {getDataError.name === "" && <span className="error text-danger">The field is required</span>}
                        </div>
                      </div>
                      <div className='col-sm-6 col-md-4 col-lg-3'>
                        <div className="form-group">
                          <label>Service Code</label>
                          <input type="text" className={`form-control normal-light ${getDataError && getDataError.code ? "is-invalid" : ""}`} name="code" ref={codeInput} maxLength={6} onKeyDown={isNumber} onChange={(e) => onChangeValue(e)} placeholder="Service Code*" value={getDataField && getDataField.code} />
                          {getDataError.code === "" && <span className="error text-danger">The field is required</span>}
                        </div>
                      </div>
                      <div className='col-sm-6 col-md-4 col-lg-3'>
                        <div className="form-group">
                          <label>Is Operator Wise</label>
                          <div className='m-2'>
                            <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} checked={getDataField && getDataField.isOperatorCommission} onChange={(e) => onChangeOperator(e)} />
                          </div>
                        </div>
                      </div>
                      <div className='col-sm-6 col-md-4 col-lg-2'>
                        <label className='d-none d-lg-block'>&nbsp;</label>
                        <div className="form-group text-lg-center d-flex">
                          {isEdit ?
                            <><button type="submit" className="btn btn-primary rounded-pill pl-4 pr-4" onClick={() => handleEdit()}>Update</button>

                              <button type="clear" className="btn btn-default rounded-pill pl-4 pr-4" onClick={() => clearFormField()}>Cancel</button></>
                            :
                            <button type="submit" className="btn btn-primary rounded-pill pl-4 pr-4" onClick={() => handleSubmit()}>Submit</button>
                          }
                        </div>
                      </div>
                    </div>

                    {/* <div className="content-header px-0"> */}
                    <h1 className='page-title text-blue font-merriweather mb-3'>Service <span className='text-red'>List</span></h1>
                    {/* </div> */}

                    <div className='history-table'>
                      <div className="box-body">
                        <DataTable
                          title={""}
                          columns={columns}
                          data={getData.docs}
                          highlightOnHover
                          pagination
                          paginationServer
                          paginationTotalRows={getTotalRecord}
                          onChangeRowsPerPage={getDataReports}
                          onChangePage={handlePageChange} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {addOperatorModal && (<Modal open={addOperatorModal} onCancel={() => setAddOperatorModal(false)} title={"Add Service Operator"} width={1000} footer={false}>
            <CreateOperator id={serviceId} />
        </Modal>)}
        <ToastContainer position="top-center" autoClose={5000} limit={2} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="colored" />
      </div>
    </div>
  );
}

export default CreateServices;
