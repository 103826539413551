import React, { useEffect, useState, useRef } from 'react';
import { CSVLink } from 'react-csv';
import DataTable from 'react-data-table-component';
import { GET_ALL_MY_COD_RECEIVED_REQUEST_MONEY, COD_REJECT_AND_APPROVED_REQUEST } from './../../Utils/appConstants';
import { getRequest, postRequest } from './../../Utils/AppApi';
import { isNumber, getUser, PaymentDepositStatus, requestStatus } from './../../Utils/Common';
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import moment from 'moment';
import { DatePicker, Modal, Select } from 'antd';
import { getUTCToLocalDateTimeFormat } from '../../Utils/function';
import PropagateLoader from "react-spinners/PropagateLoader";

const userData = getUser();

function AppRequestMoney(props) {

    const history = useHistory();
    const csvLink = useRef()
    const limit = 10;

    const dateFormat = 'DD-MM-YYYY';

    const [isRemark, setIsRemark] = useState("");
    const [isAmount, setAmount] = useState(0);
    const [isLoading, setLoading] = useState(false);
    // const [isStatus, setStatus] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [getData, setData] = useState([]);
    const [getCsvData, setCsvData] = useState([]);
    const [getTotalRecord, setTotalRecord] = useState(0);
    const [isModel, setModel] = useState(false);
    const [page, setPage] = useState(1);
    const [isSelectedData, setIsSelectedData] = useState([]);
    const [searchStatus, setSearchStatus] = useState(0);

    const columns = [{
        name: 'Date',
        cell: row => getUTCToLocalDateTimeFormat(row.createdAt),
    }, {
        name: 'Update Date',
        cell: row => getUTCToLocalDateTimeFormat(row.updatedAt),
    }, {
        name: 'RM Number',
        selector: row => row.FTRReferenceNumber,
    }, {
        name: 'User ID',
        selector: row => row.userData.username,
    }, {
        name: 'Name',
        selector: row => row.userData.name,
    }, {
        name: 'OS Balance',
        selector: row => row.currentOutstanding,
    }, {
        name: 'Wallet Balance',
        selector: row => row.currentWalletBalance,
    }, {
        name: 'Amount',
        selector: row => row.amountRequested,
    }, {
        name: 'Status',
        cell: row => <>{PaymentDepositStatus(row.status)}</>,
    }, {
        name: 'Remark',
        cell: row => row.remark,
    }, {
        name: 'Action',
        cell: row => (row.status === 0 || row.status === 3) ? <><div className='icon-border border border-primary text-primary pointer' title='Edit' onClick={(event) => handleEditView(event, row)}><i className="fa fa-edit" aria-hidden="true"></i></div><div className='icon-border border border-success text-success pointer' title='Approve' onClick={(event) => handleApproved(event, row)}><i className="fa fa-check" aria-hidden="true"></i></div><div className='icon-border border border-danger text-danger pointer' title='Reject' onClick={(event) => handleReject(event, row)}><i className="fa fa-times" aria-hidden="true"></i></div></> : "No Action",
    },]

    const handleEditView = (event, data) => {
        setModel(true)
        setIsSelectedData(data)
        setAmount(data.amountRequested)
    }

    const handleReject = (event, data) => {
        setLoading(true);
        const payload = {
            requestId: data._id,
            status: 2,
            amount: data.amountRequested,
            remark: isRemark,
            devicetype: "W",
        }
        // console.log("payload --------------", payload)
        handleSubmit(payload);
    }

    const handleApproved = (event, data) => {
        setLoading(true);
        const payload = {
            requestId: data._id,
            amount: data.amountRequested,
            status: userData.data.isStaff ? 3 : 1,
            remark: isRemark,
            devicetype: "W",
        }
        // console.log("payload --------------", payload)
        handleSubmit(payload);
    }

    const handleEditApproved = () => {
        const payload = {
            requestId: isSelectedData._id,
            amount: isAmount,
            status: userData.data.isStaff ? 3 : 1,
            remark: isRemark,
            devicetype: "W",
        }
        // console.log("payload --------------", payload)

        if (FormValidate()) {
            setLoading(true);
            handleSubmit(payload);
        }
    }

    const handleSubmit = (payload) => {
        setLoading(true);
        postRequest(COD_REJECT_AND_APPROVED_REQUEST, payload, history).then((response) => {
            if (response.success === true) {
                setModel(false)
                setIsSelectedData([])
                setAmount(0)
                getDataReports();
                toast.success(response.message);
            } else {
                getDataReports();
                toast.error(response.message);
            }
            setLoading(false);
        }).catch(function (error) {
            // console.log(error)
            setLoading(false);
        })
    } // eslint-disable-line react-hooks/exhaustive-deps

    const FormValidate = () => {
        let formValid = true;

        if (isAmount === "" || typeof isAmount === "undefined") {
            toast.error("Please enter amount");
            formValid = false;
        }

        return formValid;
    } // eslint-disable-line react-hooks/exhaustive-deps

    const handleHideModal = () => {
        setModel(false);
        setIsSelectedData([]);
        setIsRemark("");
    };

    const objectToQueryString = (obj) => {
        var str = [];
        for (var p in obj)
            if (obj.hasOwnProperty(p)) {
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
        return str.join("&");
    }

    const params = { page: page, limit: limit };

    const getDataReports = () => {
        if (startDate && endDate) {
            params.startDate = startDate ? startDate : "";
            params.endDate = endDate ? endDate : "";
        }

        params.status = searchStatus;

        const queryString = objectToQueryString(params);
        getRequest(`${GET_ALL_MY_COD_RECEIVED_REQUEST_MONEY}?${queryString}`, history).then((response) => {
            if (response.success === true) {
                setData(response.data);
                setTotalRecord(response.data.totalDocs);
                setPage(response.data.page);
            } else {
                setData([]);
                setTotalRecord(0);
                setPage(1);
            }
        }).catch(function (error) {
            console.log(error)
            // setLoading(false);
        })
    } // eslint-disable-line react-hooks/exhaustive-deps

    const getCSVDataList = () => {
        let params = {}
        params.exportsCsv = true;
        if (startDate && endDate) {
            params.startDate = startDate ? startDate : "";
            params.endDate = endDate ? endDate : "";
        }
        params.status = searchStatus;

        const queryString = objectToQueryString(params);
        getRequest(`${GET_ALL_MY_COD_RECEIVED_REQUEST_MONEY}?${queryString}`, history).then((response) => {
            if (response.success === true) {
                let csvdataRecord = [];
                let csvdata = response.data;
                csvdata.map((e) => {
                    return csvdataRecord.push({ "Date": moment(e.updatedAt).format("DD-MMM-YYYY HH:SS A"), "TXT Id": e.transactionId, "RM Number": e.FTRReferenceNumber, "Out Standing": e.currentOutstanding, "Wallet Balance": e.currentWalletBalance, "Amount": e.amountRequested, "Remark": e.remark, "Status": (e.status === 1) ? "Approved" : (e.status === 2) ? "Rejected" : "pending" });
                });
                setCsvData(csvdataRecord);
                setTimeout(() => {
                    csvLink.current.link.click();
                }, 1000);
            } else {
                setCsvData([]);
            }
        }).catch(function (error) {
            console.log(error)
            // setLoading(false);
        })
    }

    const handlePageChange = page => {
        setPage(page);
    };

    const onChangeStartDate = (date, dateString) => {
        // console.log(date, dateString);
        setStartDate(dateString)
    };

    const onChangeEndDate = (date, dateString) => {
        // console.log(date, dateString);
        setEndDate(dateString)
    };

    const onChangeType = (value) => {
        // const { value } = event;
        setSearchStatus(value);
    }

    const handleSearch = () => {
        getDataReports();
    }

    useEffect(() => {
        getDataReports();
    }, [page]) // eslint-disable-line react-hooks/exhaustive-deps

    if (isLoading) {
        return <div className="content text-center" style={{ margin: "250px 0" }}>
            <PropagateLoader color="#5851D2" loading={isLoading} size={25} />
        </div>
    }

    return (
        <div>
            {/* <AppHeader /> */}
            <div className="content-wrapper pt-2">
                <div className="content-header my-0 py-0">
                    <div className="container-fluid">
                        <div className="row mb-0 align-items-center">
                            <div className="col-sm-6">
                                <h1 className='page-title text-blue font-merriweather'>COD <span className='text-red'>Approve</span></h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content">
                    <div className="container-fluid pt-2 pb-5">
                        <div className='card col-md-12'>
                            <div className='card-body'>
                                <div className="row">
                                    <div className='col-md-8'>
                                        <div className='date-search mb-0'>
                                            <div className='row'>
                                                <div className='col-sm-4 col-lg-3'>
                                                    <div className="form-group">
                                                        <label className='date-search-label mb-0 text-blue'>From</label>
                                                        {/* <input type="text" className="form-control form-control-border" placeholder="27 September 2022" /> */}
                                                        <DatePicker className="form-control form-control-border" format={dateFormat} onChange={onChangeStartDate} />
                                                    </div>
                                                </div>
                                                <div className='col-sm-4 col-lg-3'>
                                                    <div className="form-group">
                                                        <label className='date-search-label mb-0 text-red'>To</label>
                                                        {/* <input type="text" className="form-control form-control-border" placeholder="27 September 2022" /> */}
                                                        <DatePicker className="form-control form-control-border" format={dateFormat} onChange={onChangeEndDate} />
                                                    </div>
                                                </div>
                                                <div className='col-sm-3 col-lg-2'>
                                                    <div className="form-group">
                                                        <label className='date-search-label mb-0 text-red'></label>
                                                        <Select options={requestStatus} className={`normal-light w-100`} classNamePrefix="select" placeholder="-- Status --"  name='status' onChange={(e) => onChangeType(e)} />
                                                    </div>
                                                </div>
                                                <div className='col-sm-4 col-lg-2'>
                                                    <div><label className='date-search-label mb-0 text-red'>&nbsp;</label></div>
                                                    <button type="submit" className="btn btn-primary rounded-pill pl-4 pr-4" onClick={() => handleSearch()}>Search</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-4'>
                                        <div className='float-sm-right'>
                                            <button type="button" className="btn btn-primary mr-3" onClick={() => getCSVDataList()} ><i className="fa fa-file-excel"></i> Export</button>
                                            <CSVLink
                                                data={getCsvData}
                                                filename='request-money-history'
                                                className='hidden'
                                                ref={csvLink}
                                                target='_blank'
                                            />
                                            {/* <button type="button" className="btn btn-danger"><i className="fa fa-file-pdf"></i> Export to PDF</button> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="container-fluid pt-2 pb-5">
                                    <DataTable
                                        title={""}
                                        columns={columns}
                                        data={getData.docs}
                                        highlightOnHover
                                        pagination
                                        paginationServer
                                        paginationTotalRows={getTotalRecord}
                                        onChangeRowsPerPage={getDataReports}
                                        onChangePage={handlePageChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer position="top-center" autoClose={5000} limit={2} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="colored" />
            {/* <AppFooter /> */}
            {isModel && <Modal title={"Edit Request"} width={600} open={isModel} onOk={() => handleReject(isSelectedData)} onCancel={handleHideModal} footer={null}>
                <div className='row'>
                    <div className='col-md-12 form-group'>
                        <label>Amount: </label>
                        <input type={'text'} className="form-group form-control" disabled onChange={(e) => setAmount(e.target.value)} value={isAmount} maxLength={10} onKeyDown={isNumber} />
                    </div>
                    <div className='col-md-12 form-group'>
                        <label>Remark: </label>
                        <textarea className="form-group form-control" onChange={(event) => setIsRemark(event.target.value)} cols={"4"} rows={"5"} >{isRemark ? isRemark : isSelectedData.parentRemark}</textarea>
                    </div>
                    <div className='col-md-12 form-group align-text-right'>
                        <div className='pull-right align-text-right '>
                            <button type='button' onClick={() => handleEditApproved()} className='btn btn-sm btn-primary'>Approved</button>
                        </div>
                    </div>
                </div>
            </Modal>}
        </div>
    )
}

export default AppRequestMoney