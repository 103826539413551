import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import { Link } from 'react-router-dom';
import { isNumber } from '../Utils/Common';
import { FORGET_PASSWORD_MOBILE_NUMBER, MOBILE_SEND_VERFIY, FORGET_USERID, USER_ROLE} from './../Utils/appConstants';
import { postRequest, getRequest } from '../Utils/AppApi';
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { /*APP_KEY*/ API_URL } from '../config';
import bgshape from './../assets/bgshape.svg';
import Footer from "./Footer/Footer";
import OtpInput from "react-otp-input";
import Select from 'react-select';

import AdvertiseSlider from "./AdvertiseSlider";

function ForgetPassword(props) {
  const history = useHistory()
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [appInfo, setAppInfo] = useState("");
  const [otpValue ,setOtpValue] = useState("");
  const [username ,setUsername] = useState("");
  const [roleData, setRoleData] = useState([]);
  const pancard = useFormInput('');
  const [getRole, setRole] = useState(null);
  const mobile = useFormInput('');

  const getRoles = () => {
    getRequest(USER_ROLE+'/4', history).then((response) => {
      if (response.success === true) {
        setRoleData(response.data.map((item) => {
          // setRole(item._id)
          return { "value": item._id, "label": item.name };
        }));
      } else {
        toast.error(response.message);
      }
    }).catch(function (error) {
      console.log(error)
      // setLoading(false);
    })
  }

  const onChangeType = (event) => {
    const { value } = event;
    // // console.log(`selected ${value}`);
    setRole(value);
  }

  const handleStep1 = () => {
    const payload = {
      mobile: mobile.value,
      pancard: pancard.value,
      roleId: getRole,
    }
    setLoading(true);
    postRequest(FORGET_PASSWORD_MOBILE_NUMBER, payload, history).then((response) => {
      if (response.success === true) {
        toast.success(response.message);
        setStep(step + 1)
      } else {
        toast.error(response.message);
      }
      setLoading(false);
    }).catch(function (error) {
      // console.log(error)
      setLoading(false);
    })
  }

  const handleStep2 = () => {
    const payload = {
      mobile: mobile.value,
      otp : otpValue
    }
    setLoading(true);
    postRequest(MOBILE_SEND_VERFIY, payload, history).then((response) => {
      if (response.success === true) {
        toast.success(response.message);
        setStep(step + 1)
        handleStep3();
      } else {
        toast.error(response.message);
      }
      setLoading(false);
    }).catch(function (error) {
      // console.log(error)
      setLoading(false);
    })
  }

  const handleStep3 = () => {
    const payload = {
      mobile: mobile.value,
    }
    setLoading(true);
    postRequest(FORGET_USERID, payload, history).then((response) => {
      if (response.success === true) {
        toast.success(response.message);
        setUsername(response.data.username)
      } else {
        toast.error(response.message);
      }
      setLoading(false);
    }).catch(function (error) {
      // console.log(error)
      setLoading(false);
    })
  }

  const redirectLoginPage = () => {
    history.push({
      pathname: "/login"
    });
  }

  const getAppInfo = () => {
    getRequest(`${API_URL}/appinfo`).then( async (data) => {
      setLoading(false);
      if (data) {
        // console.log("========================", data)
        await setAppInfo(data)
      } else {
        toast.error(data.data.message)
      }
    }).catch(function (error) {
      setLoading(false);
      console.log(error);
    })
  }

  const handleOTPChange = (otp) => {
    setOtpValue(otp)
  } 

  useEffect(() => {
    getRoles();
    getAppInfo();
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <div className='wrapper'>
      <div className='topbar'>
        <nav className="main-header navbar navbar-expand navbar-light flex-wrap">
          <ul className="navbar-nav">
            <li className="nav-item">
              <a href="\#" className="brand-link">
                <img src="../../logo.png" alt="Logo" className="brand-image" />
              </a>
            </li>
          </ul>

          <ul className="navbar-nav ml-auto">
          <li className="nav-item">
              <a href={`https://wa.me/${appInfo.helpNumber}`} target="_blank" rel="noreferrer" className='nav-link'>
                {/* <i className='fas fa-phone-alt mr-1 call-icon'></i> */}
                <i class="fa-brands fa-whatsapp fa-fade fa-lg mr-1 call-icon"></i>
                <span className='helpline-text'>{appInfo.helpNumber}</span>
              </a>
            </li>
            <li className="nav-item pl-0 pl-md-4">
              <a href="mailto:{appInfo.email}" className='nav-link'><i className='fas fa-envelope mr-1 call-icon'></i> <span className='helpline-text'>{appInfo.email}</span></a>
            </li>
          </ul>
        </nav>
      </div>
      <div className='content-wrapper login-outer' style={{ backgroundImage: `url(${bgshape})`, backgroundRepeat: "no-repeat" }}>
        <div className='login-container' >
          <div className=''>
            <div className='row login-background' >
              <bgshape />
              <div className='col-lg-2 text-center'></div>
              <div className='col-md-6 col-lg-5 text-center'>
                <AdvertiseSlider/>
              </div>
              <div className='col-md-6 col-lg-5'>
                <div className="login-box">
                  {/* <p className="login-box-msg">
                    SR PAY Private Limited Registration in Process...
                  </p> */}
                  <div className="">
                    <div className="card-body" style={{ textAlign: "center" }}>
                      {step !== 4 &&
                        <img src="../../logo.png" alt="Logo" width={"222"} className="brand-image" />
                      }
                      {step === 1 && <><h2 className='login-box-heading mt-5 mb-5'>Enter your mobile <br /> Forget User ID</h2>
                        <div className="input-group mb-3">
                          <input type="text" className="form-control" {...mobile} placeholder="Mobile Number" maxLength={"10"} onKeyDown={isNumber} />
                        </div>
                        <div className="input-group mb-3" style={{"textAlign" : "left"}}>
                          <Select options={roleData} className={`normal-light w-100`} classNamePrefix="select" placeholder="Select a Type" name='mode' onChange={(e) => onChangeType(e)} defaultValue={getRole} />
                        </div>
                        <div className="input-group mb-3">
                          <input type="text" className="form-control" {...pancard}  placeholder="Pancard" maxLength={"10"} />
                        </div>
                        <div className="mt-3">
                          <button type="submit" onClick={() => handleStep1()} className="btn btn-block login-button ">Send {loading ? "..." : ""}</button>
                        </div>
                        <div className="mt-3 join-button px-5 py-1 pointer" onClick={() => redirectLoginPage()}>
                          <span className='my-1'>Login Now</span>
                        </div>
                        </>}

                      {step === 2 && <><h2 className='login-box-heading mt-5 mb-5'>Enter OTP</h2>
                        <div className="mb-3 mt-3 otp-justify">
                          <OtpInput
                            value={otpValue}
                            separator={
                              <span>
                                <strong></strong>
                              </span>
                            }
                            onChange={(value) => handleOTPChange(value)}
                            isInputNum={true}
                            className="justify-content-center"
                            inputStyle={{
                              width: "3rem",
                              height: "3rem",
                              margin: "0 0.9rem",
                              fontSize: "2rem",
                              borderRadius: 4,
                              border: "1px solid rgba(0,0,0,0.3)"
                            }}
                          />
                        </div>

                        <div className="mt-3">
                          <button type="submit" onClick={() => handleStep2()} className="btn btn-block login-button ">Continue {loading ? "..." : ""}</button>
                        </div></>}

                      {step === 3 && <>
                        <div>
                          <div className="content-div16">
                            <img className="vector-icon188" alt={"vector"} src="../../images/vector188.svg" />
                            <div className="dear-partner-welcome-to-srpay mt-4">
                                User ID - <span className="xxxxx-span">{username}</span>
                            </div>
                            <div className="flex-row justify-content-center d-flex" onClick={() => redirectLoginPage()}>
                              <div className="continue">Login</div>
                              <img className="arrow-icon" alt={"arrow"} src="../../images/arrow-1.svg" />
                            </div>
                          </div>
                        </div>
                      </>}
                      <div className='my-4'><hr /></div>
                      <div className='mb-5 pointer' target="_blank" onClick={() => {
                        // if(process.env.REACT_APP_SRPAY_LINK === "www.srpay1.com") {
                          window.open('https://play.google.com/store/apps/details?id=com.srpaypartner', '_blank')
                        // }
                      }}>
                        <img src='../../googleplay1@3x.png' alt='google' width={"213.74"} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer position="top-center" autoClose={5000} limit={2} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="colored"/>
      </div>
      <Footer />
    </div>
  );
}

const useFormInput = initialValue => {
  const [value, setValue] = useState(initialValue);
  const handleChange = e => {
    if(e.target.name === 'pancard') {
      setValue(e.target.value.toUpperCase());
    } else {
      setValue(e.target.value);
    }
  }
  return {
    value,
    onChange: handleChange
  }
}

export default ForgetPassword;