import React from 'react';
// import { APP_KEY, API_URL } from './../config';
// import { getToken } from './../Utils/Common';
import 'bootstrap-daterangepicker/daterangepicker.css';

function DistributorDashboard(props) {

  return (
    <div>
      <div className="content pt-5 pb-5">
        <div className="container-fluid">
          <div className="card card-body">
            <div className='dashboard-header font-merriweather'>
              TODAY SALES
            </div>
            <div className="row">
              <div className="col-sm-6 col-md-4 col-lg-3">
                <div className='dbox-outer'>
                  <div className='dbox-cols'>
                    <div className='pr-2 dbox-icon'><img src='../images/aeps-logo-2.svg' alt='icon' /></div>
                    <div className='dbox-text2'>
                      AepS <br />
                      ₹:  120000 <br />
                      Incentive:  12
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-3">
                <div className='dbox-outer'>
                  <div className='dbox-cols'>
                    <div className='pr-2 dbox-icon'><img src='../images/money-icon.svg' alt='icon' /></div>
                    <div className='dbox-text2'>
                      Money Transfer<br />
                      ₹:  120000<br />
                      Incentive:  12
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-3">
                <div className='dbox-outer'>
                  <div className='dbox-cols'>
                    <div className='pr-2 dbox-icon'><img src='../images/recharge-icon.svg' alt='icon' /></div>
                    <div className='dbox-text2'>
                      Recharge<br />
                      ₹:  1000<br />
                      Incentive:  10
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-3">
                <div className='dbox-outer'>
                  <div className='dbox-cols'>
                    <div className='pr-2 dbox-icon'><img src='../images/matm-icon.svg' alt='icon' /></div>
                    <div className='dbox-text2'>
                      MATM<br />
                      ₹:  800000<br />
                      Incentive:  55
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-3">
                <div className='dbox-outer'>
                  <div className='dbox-cols'>
                    <div className='pr-2 dbox-icon'><img src='../images/upiqr-icon.svg' alt='icon' /></div>
                    <div className='dbox-text2'>
                      UPI QR<br />
                      ₹:  120000<br />
                      Count:  12
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-3">
                <div className='dbox-outer'>
                  <div className='dbox-cols'>
                    <div className='pr-2 dbox-icon'><img src='../images/payment-gateway.svg' alt='icon' /></div>
                    <div className='dbox-text2'>
                      Payment Gateway<br />
                      ₹:
                      55000<br />
                      Count:  12
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-3">
                <div className='dbox-outer'>
                  <div className='dbox-cols'>
                    <div className='pr-2 dbox-icon'><img src='../images/money-icon.svg' alt='icon' /></div>
                    <div className='dbox-text2'>
                      Utility Payment<br />
                      ₹:  700<br />
                      Incentive:  1
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-3">
                <div className='dbox-outer'>
                  <div className='dbox-cols'>
                    <div className='pr-2 dbox-icon'><img src='../images/payment-gateway.svg' alt='icon' /></div>
                    <div className='dbox-text2'>
                      GTV Sales<br />
                      ₹:
                      300000<br />
                      Incentive:  55
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <hr />

            <div className='date-search'>
              <div className='row'>
                <div className='col-sm-3 col-lg-2'>
                  <div class="form-group">
                    <label className='date-search-label mb-0 text-blue'>From</label>
                    <input type="text" class="form-control form-control-border" placeholder="27 September 2022" />
                  </div>
                </div>
                <div className='col-sm-3 col-lg-2'>
                  <div class="form-group">
                    <label className='date-search-label mb-0 text-red'>To</label>
                    <input type="text" class="form-control form-control-border" placeholder="27 September 2022" />
                  </div>
                </div>
                <div className='col-sm-3 col-lg-2'>
                  <div><label className='date-search-label mb-0 text-red'>&nbsp;</label></div>
                  <button type="submit" className="btn btn-primary ">Search</button>
                </div>
              </div>
            </div>

            <div>
              <div className="row">
                <div className="col-sm-6 col-md-4 col-lg-3">
                  <div className='dbox-outer'>
                    <div className='dbox-cols'>
                      <div className='pr-2 dbox-icon'><img src='../images/aeps-logo-2.svg' alt='icon' /></div>
                      <div className='dbox-text2'>
                        AepS <br />
                        ₹:  120000 <br />
                        Incentive:  12
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-3">
                  <div className='dbox-outer'>
                    <div className='dbox-cols'>
                      <div className='pr-2 dbox-icon'><img src='../images/money-icon.svg' alt='icon' /></div>
                      <div className='dbox-text2'>
                        Money Transfer<br />
                        ₹:  120000<br />
                        Incentive:  12
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-3">
                  <div className='dbox-outer'>
                    <div className='dbox-cols'>
                      <div className='pr-2 dbox-icon'><img src='../images/recharge-icon.svg' alt='icon' /></div>
                      <div className='dbox-text2'>
                        Recharge<br />
                        ₹:  1000<br />
                        Incentive:  10
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-3">
                  <div className='dbox-outer'>
                    <div className='dbox-cols'>
                      <div className='pr-2 dbox-icon'><img src='../images/matm-icon.svg' alt='icon' /></div>
                      <div className='dbox-text2'>
                        MATM<br />
                        ₹:  800000<br />
                        Incentive:  55
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-3">
                  <div className='dbox-outer'>
                    <div className='dbox-cols'>
                      <div className='pr-2 dbox-icon'><img src='../images/upiqr-icon.svg' alt='icon' /></div>
                      <div className='dbox-text2'>
                        UPI QR<br />
                        ₹:  120000<br />
                        Count:  12
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-3">
                  <div className='dbox-outer'>
                    <div className='dbox-cols'>
                      <div className='pr-2 dbox-icon'><img src='../images/payment-gateway.svg' alt='icon' /></div>
                      <div className='dbox-text2'>
                        Payment Gateway<br />
                        ₹:
                        55000<br />
                        Count:  12
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-3">
                  <div className='dbox-outer'>
                    <div className='dbox-cols'>
                      <div className='pr-2 dbox-icon'><img src='../images/money-icon.svg' alt='icon' /></div>
                      <div className='dbox-text2'>
                        Utility Payment<br />
                        ₹:  700<br />
                        Incentive:  1
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-3">
                  <div className='dbox-outer'>
                    <div className='dbox-cols'>
                      <div className='pr-2 dbox-icon'><img src='../images/payment-gateway.svg' alt='icon' /></div>
                      <div className='dbox-text2'>
                        GTV Sales<br />
                        ₹:
                        300000<br />
                        Incentive:  55
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}


export default DistributorDashboard;