import React, { useEffect } from 'react';

function Header() {

    useEffect(() => {
        // setReportMode(Headers);
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            <header>
                <nav id="navigation4" className="container navigation">
                    <div className="nav-header">
                        <a className="nav-brand" href="/">
                            <img src="img/logos/logo.png" className="main-logo" alt="logo" id="main_logo" />
                        </a>
                        <div className="nav-toggle" />
                    </div>
                    <div className="nav-menus-wrapper">
                        <ul className="nav-menu align-to-right">
                            <li><a href="/">Home </a>
                            </li>
                            <li><a href="/aboutus">About Us</a>
                            </li>
                            <li><a href="/services">Services </a>
                            </li>
                            <li><a href="/download">Download</a>
                                {/* <ul className="nav-dropdown">
                                    <li><a href={"https://www.srpay.net.in/SRPay2.3.apk"}>SR PAY Private Limited Utility Services</a></li>
                                </ul> */}
                            </li>
                            <li><a href="/joinus" target="_blank">Join US </a>
                            </li>

                            <li><a href="/contact-us">Contact us</a>
                            </li>
                            <li><a href="/login" target="_blank">Login</a></li>

                        </ul>
                    </div>
                </nav>
            </header>
        </div>
    )
}

export default Header