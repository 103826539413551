import React, { useEffect } from 'react';
// import RBCarousel from "react-bootstrap-carousel";
// import "bootstrap/dist/css/bootstrap.css";
// import "react-bootstrap-carousel/dist/react-bootstrap-carousel.css";
// import moment from 'moment';
import './../../webcss/default.css';
import './../../webcss/styles.css';
import Header from './header';
import Footer from './footer';
// import { DatePicker, Input, Button, Select } from 'antd';

function Aboutus(props) {

    // const icon_glass = <span className="glyphicon glyphicon-glass" />;
    // const icon_music = <span className="glyphicon glyphicon-music" />;

    useEffect(() => {
        // setReportMode(Aboutuss);
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            <div>
                <Header />
                <div>
                    <div className="notice-section notice-section-sm border-top red">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-10 col-sm-10 col-12">
                                    <div className="mt-5">
                                        <h6 className="fontwhite">Become a SR PAY Private Limited Franchise &amp; Grow your income 30% to 50% per month
                                        </h6>
                                    </div>
                                </div>
                                <div className="col-md-2 col-sm-2 col-12">
                                    <div className="right-holder-md">
                                        <a href="/joinus" className="primary-button button-lg mt-15-xs bluebtn">Join Now</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="page-title-section">
                        <div className="container">
                            <h1>हमारे <span className="white"> बारे में</span></h1>
                        </div>
                    </div>
                    <div className>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 col-sm-12 col-12">
                                    <div className="pl-30-md mt-40">
                                        <div className="text-content-big mt-20">
                                            <p>
                                                उत्कृष्टता के प्रयास और समृद्धि के जुनून के साथ हम “SR PAY Private Limited” आपके लिए सर्वश्रेष्ठ फिनटेक सेवा और उपयोगिता भुगतान प्लेटफॉर्म लेकर आए हैं। बेहतरीन प्रयासों और अपने पूरे दिल और दिमाग से तैयार की गई इस परियोजना के लिए हमें गर्व है कि हम अपने Partners को उनकी बैंकिंग और बुनियादी दैनिक वित्तीय जरूरतों को पूरा करने के लिए अत्याधुनिक तकनीक के माध्यम से सशक्त बनाकर भारत में आम जनता को ऑनलाइन भुगतान सेवाएं प्रदान करने के लिए एक बेहतरीन मंच प्रदान करते हैं।
                                            </p>
                                            <p>
                                                प्रौद्योगिकी से दूर और परे कुछ भी नहीं है और हम "SR PAY Private Limited" का मानना ​​​​है कि हमारे देश में हर किसी को कम से कम निकटतम बिंदु पर बुनियादी वित्तीय सेवाओं की अधिकता होनी चाहिए। प्रौद्योगिकी के इस युग में कई ग्रामीण क्षेत्र अभी भी वंचित हैं और अपने शहरी समकक्षों से पीछे हैं, हम "SR PAY Private Limited" में सभी को बुनियादी बैंकिंग और वित्तीय सेवाएं प्रदान करके ग्रामीण और शहरी भारत के बीच एक डिजिटल पुल प्रदान करने के लिए प्रतिबद्ध हैं - सुरक्षित, तुरंत और परेशानी मुक्त तरीके से।
                                            </p>
                                            <p>
                                                बेजोड़ तकनीक के माध्यम से, हम किसी भी स्थानीय स्टोर को डिजिटल फिनटेक और यूटिलिटी सर्विस सेंटर के रूप में कार्य करने में सक्षम बनाते हैं। "SR PAY Private Limited" भारत भर में हमारे अधिकृत एजेंटों के माध्यम से उद्योग के सर्वश्रेष्ठ ऑपरेटरों के साथ अपनी विभिन्न सेवाओं - जैसे नकद जमा, नकद निकासी, बिल भुगतान, UPI, भारत QR, आधार सक्षम सेवाएं, Mobile & DTH  Recharge, MPOS, रेलवे टिकटिंग सेवाएं इत्यादि के माध्यम से एजेंटों के दरवाजे पर अभिनव बैंकिंग समाधान और डिजिटल पहुंच प्रदान कर रहा है। सर्वोत्तम संभव कमीशन ऑफर और समर्पित एजेंट केयर नेटवर्क के साथ हम इस विशाल देश के सुदूर कोनों में व्यापारियों को न्यूनतम लागत पर सर्वोत्तम सेवाएं प्रदान करने का प्रयास करते हैं।
                                            </p>
                                            <p>
                                                अपने सहयोगियों के लिए अच्छा मुनाफा पैदा करते हुए ऑनलाइन फिनटेक सेवा और उपयोगिता सेवा प्रदाता के क्षेत्र में खुद को एक प्रमुख खिलाड़ी के रूप में स्थापित करना हमारा लक्ष्य है और ऐसा करने में हम सर्वोत्तम सेवाएं और ग्राहक संतुष्टि प्रदान करने के लिए प्रतिबद्ध हैं। हमारे कर्मचारियों को सबसे कठिन परिस्थितियों को आसानी से संभालने और हमारे भागीदारों के प्रति समयबद्ध प्रतिबद्धता के लिए प्रशिक्षित किया जाता है।
                                            </p>
                                            <p>
                                                हमारी टीम सर्वोत्तम श्रेणी की सेवाओं को एकीकृत करने और प्रदान करने के लिए और एक ऐसी प्रणाली विकसित करने के लिए नई सेवाओं को जोड़ने के लिए अथक और निरंतर 24*7 काम कर रही है, जिसमें हम सर्वोत्तम सेवाओं को एकीकृत कर सकें और इस पूरे पारिस्थितिकी तंत्र में सबसे आसान एप्लिकेशन में से एक प्रदान कर सकें, जिसका उपयोग करना और रखरखाव करना आसान हो और हर जगह सर्वोत्तम उपयोगकर्ता अनुभव प्रदान किया जा सके। हमारा पूरा ध्यान हमेशा वर्ग अग्रणी सेवाएं देने और खुद को बेहतर बनाने पर है ताकि ग्राहक को समृद्ध अनुभव प्रदान किया जा सके।
                                            </p>
                                            <p>
                                                फिनटेक दौड़ में अग्रणी बनना और इस तकनीकी क्रांति में सबसे आगे रहना हमारा मकसद है और हम इसे हासिल करने के लिए पूरे दिल से काम कर रहे हैं।
                                            </p>
                                            <div className="section-heading center-holder white-color">
                                                <a href="/aboutus" className="primary-button button-md mt-10">Read in English
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <Footer />
            </div>
        </div>
    )
}

export default Aboutus