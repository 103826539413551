import { React } from 'react';
// import { useHistory } from "react-router-dom";

function CommingSoon(props) {
    return (
        <div classname="text-center my-5">
            <div className="container text-center">
                <div className="wrapper d-flex">
                    <div className='content col-md-2'></div>
                    <div className="content col-md-8">
                        <div className="item">
                            <img src='./coming-soon-stamp-EMCH50.jpeg' alt={''} />
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default CommingSoon;