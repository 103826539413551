import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'antd';
import DataTable from 'react-data-table-component';
import 'antd/dist/antd.css';
import { useHistory } from "react-router-dom";
import { GET_DMT_IMPORT_BENEFICIARY_LIST, GET_DMT_NEW_BANK_LIST } from '../../Utils/appConstants';
import { getRequest } from '../../Utils/AppApi';
import { ToastContainer } from 'react-toastify';
import { isNumber } from '../../Utils/Common';
import { getUTCToLocalDateFormat } from './../../Utils/function';
import Select from 'react-select';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

function Import(props) {
    console.log("props -------",props)
    const history = useHistory();
    const limit = 10;
    const [getData, setData] = useState([]);
    const [isLimit, setLimit] = useState(limit)
    const [page, setPage] = useState(1);
    const [saveCustomerMobile, setCustomerMobile] = useState(props.customerMobile);
    const [searchText, setSearchText] = useState(null);
    const [timer, setTimer] = useState(null);
    const [bankId, setBankId] = useState(null);
    const [isAddBaneModal, setAddBaneModal] = useState(false);
    const [getBeneData, setBeneData] = useState({});
    const [getBeneError, setBeneError] = useState({});
    const [getEKOBankListData, setEKOBankListData] = useState([]);
    const [getBankListData, setBankListData] = useState([{ "value": '', "label": '-- Select Bank --' }]);
    const [selectedOption, setSelectedOption] = useState(null);
    
    const columns = [{
        name: 'Nick Name',
        selector: row => <><div>{row.beneName}</div>{row.beneMobile && <div><strong>Mobile</strong>: {row.beneMobile}</div>}</>,
        // width: "200px",
    }, {
        name: 'IFSC / Bank',
        selector: row => <><div><strong>IFSC</strong>: {row.ifscCode}</div>{row.bankName && <div><strong>Bank</strong>: {row.bankName}</div>}</>,
        // width: "200px",
    }, {
        name: 'Account',
        selector: row => <><div>{row.accountNumber}</div><div><strong>UP</strong>: {getUTCToLocalDateFormat(row.updatedAt)}</div></>,
        // width: "200px",
    }, {
        name: 'Bene Name',
        selector: row => <><div><strong>{row.isVerified ? <CheckOutlined className='text-120 text-success border p-1' /> : <CloseOutlined className='text-120 text-danger border p-1'/> }</strong> {row.beneBankName} </div></>,
        // width: "200px",
    }, {
        name: 'Action',
        selector: (row) => <><Button className={"bg-primary"} onClick={() => addBane(row)} small >Add</Button></>,
        // width: "150px",
        fixed: "right",
    }];

    const getBankList = () => {
        getRequest(GET_DMT_NEW_BANK_LIST, history).then((response) => {
            if (response.success === true) {
                let bankDataRecord
                if(response.data) {
                    bankDataRecord = response.data.filter((item) => item.key === props.mode );
                }
                setEKOBankListData(bankDataRecord);
                setBankListData([...getBankListData, ...bankDataRecord.map((item) => {
                    return { "value": item.BankID, "label": item.BANK_NAME };
                })]);
            }
        }).catch(function (error) {
            console.log(error);
        })
    }

    const onChangeBankIfscCode = (value) => {
        if (value) {
            let findBank = getEKOBankListData.filter(item => item.BankID === value);
            if (findBank.length > 0) {
                setSelectedOption({ "value": findBank[0].BankID, "label": findBank[0].BANK_NAME });
                if(findBank[0].ifsc) {
                    setBeneData({ ...getBeneData, ifscCode: findBank[0].ifsc });
                } else {
                    setBeneData({ ...getBeneData, ifscCode: "" });
                }
                setBankId(findBank[0].BankID);
            } else {
                setBankId(null);
            }
        } else {
            setBankId(null);
            setBeneData({ ...getBeneData, ifscCode: "" });
        }
    }

    const addBane = (data) => {
        setAddBaneModal(true);
        setBeneData(data);
    }

    const FormBaneValidate = () => {
        let formValid = true;
        let formObject = {};
        setBeneError({})
        if (getBeneData && getBeneData.length === 0) {
            formValid = false;
        }

        if (!bankId || bankId === "" || typeof bankId === "undefined") {
            formObject.bankName = true;
            formValid = false;
        }

        setBeneError(formObject);
        return formValid;
    }

    const onChangeAddBene = (event) => {
        let { name, value } = event.target;
        setBeneData({ ...getBeneData, [name]: value });
    }

    const addBaneSave = () => {
        if (FormBaneValidate() === true) {
            const payload = {
                customerMobile: props.customerMobile,
                recipientMobile: getBeneData.beneMobile,
                accountNumber: getBeneData.accountNumber,
                ifscCode: getBeneData.ifscCode,
                bankId: bankId,
                recipientName: getBeneData.beneName,
                beneBankName: getBeneData.beneBankName
            };
            // console.log("payload-------------- ",payload)
            props.addBaneficiary(payload);
        }
    }

    const handleHideModal = () => {
        setAddBaneModal(false)
    };

    const objectToQueryString = (obj) => {
        var str = [];
        for (var p in obj)
            if (obj.hasOwnProperty(p)) {
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
        return str.join("&");
    }

    const params = { page: page, limit: isLimit };

    const getDataReports = (data) => {
        if (data) {
            setLimit(data)
            params.limit = data
        }
        if (searchText) {
            params.search = searchText;
        }
        params.mode = props.mode;
        params.customerMobile = saveCustomerMobile;
        
        const queryString = objectToQueryString(params);
        getRequest(`${GET_DMT_IMPORT_BENEFICIARY_LIST}?${queryString}`, history).then((response) => {
            if (response.success === true) {
                setData(response.data);
                // setTotalRecord(response.data.totalDocs);
                // setPage(response.data.page);
            } else {
                // setData([]);
                // setTotalRecord(0);
                setPage(1);
            }
        }).catch(function (error) {
            console.log(error)
        })
    }

    const handlePageChange = page => {
        setPage(page);
    };

    const handleSearch = (e) => {
        // console.log("searchText----",e.target.value)
        setSearchText(e.target.value);
    }

    useEffect(() => {
        // console.log("props.customerMobile ---" ,props.customerData)
        setCustomerMobile(props.customerMobile);
        getDataReports();
    }, [props.customerMobile]) // eslint-disable-line react-hooks/exhaustive-deps
    
    useEffect(() => {
        if (timer) {
            clearTimeout(timer)
            setTimer(null)
        }
        setTimer(
            setTimeout(() => {
                getDataReports()
            }, 100)
        )
    }, [searchText]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        getBankList();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    // if (isLoading) {
    //     return <div className="content text-center" style={{ margin: "250px 0" }}>
    //         <PropagateLoader color="#5851D2" loading={isLoading} size={25} />
    //     </div>
    // }

    return (
        <div>
            <div className=" pt-2 pb-5">
                <div className="col-md-12">
                    <div className="row pb-3">
                        <div className='col-md-8 date-search'>
                            <div className='row'>
                                <div className="input-group">
                                    <input className="form-control py-2 border-right-0 border" type="search" defaultValue={searchText} value={searchText} onKeyDown={isNumber} placeholder='Search Account Number ...' maxLength={20} onChange={(e) => handleSearch(e)} />
                                    <input className="form-control py-2 border" type="text" value={saveCustomerMobile} onKeyDown={isNumber} placeholder='Search Customer Number ...' maxLength={20} onChange={(e) => setCustomerMobile(e.target.value)} />
                                    {/* <span className="input-group-append"> */}
                                        <button className="btn border btn-primary ml-2" type="button" onClick={() => getDataReports()}>
                                            <i className="fa fa-search" /> Search
                                        </button>
                                    {/* </span> */}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="box-body">
                        <DataTable
                            columns={columns}
                            data={getData.docs}
                            // progressPending={getData.length > 0 ? false : true}
                            highlightOnHover
                            pagination
                            paginationServer
                            paginationTotalRows={getData.totalDocs}
                            onChangeRowsPerPage={getDataReports}
                            onChangePage={handlePageChange}
                        />
                    </div>
                </div>
            </div>
            <ToastContainer position="top-center" autoClose={5000} limit={2} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="colored" />
            {isAddBaneModal && <Modal title={"Confirm Bank Account"} width={400} open={isAddBaneModal} onOk={() => addBaneSave()} onCancel={handleHideModal} okText={"Confirm"} cancelText={"Cancel"}>
                <div className='row'>
                    <div className="form-group col-lg-12 col-md-12">
                        <label>Mobile (Optional)</label>
                        <div className="input-group">
                            <div className="custom-file">
                                <input type="text" className="form-control" name="beneMobile" placeholder="Mobile Number" autoComplete="off" onChange={(e) => onChangeAddBene(e)} value={getBeneData.beneMobile} readOnly={props.mode === "DMT" ? false : true } maxLength={10} onKeyDown={isNumber} />
                            </div>
                        </div>
                        {getBeneError && getBeneError.mobile && <span className="error text-danger">The field is required</span>}
                    </div>
                    <div className="form-group col-lg-12 col-md-12">
                        <label>Account Holder Name</label>
                        <div className="input-group">
                            <div className="custom-file">
                                <input type="text" className="form-control" name="name" placeholder="Account Holder Name" autoComplete="off" value={getBeneData.beneName} readOnly maxLength={20} />
                            </div>
                        </div>
                        {getBeneError && getBeneError.name && <span className="error text-danger">The field is required</span>}
                    </div>
                    <div className="form-group col-lg-12 col-md-12">
                        <label>Bank Name</label>
                        <div className="input-group">
                            <div className="custom-file">
                                <Select options={getBankListData} className={`normal-light w-100`} defaultValue={selectedOption} classNamePrefix="select" placeholder="Select Bank" name='mode' onChange={(e) => onChangeBankIfscCode(e.value)} />
                            </div>
                        </div>
                        {getBeneError && getBeneError.bankName && <span className="error text-danger">The field is required</span>}
                    </div>
                    <div className="form-group col-lg-12 col-md-12">
                        <label>Global IFSC Code</label>
                        <div className="input-group">
                            <div className="custom-file">
                                <input type="text" className="form-control" name="ifscCode" placeholder="Enter IFSC Code" autoComplete="off" readOnly value={getBeneData.ifscCode} maxLength={11} />
                            </div>
                        </div>
                        {getBeneError && getBeneError.ifscCode && <span className="error text-danger">The field is required</span>}
                    </div>
                    <div className="form-group col-lg-12 col-md-12">
                        <label>Account Number</label>
                        <div className="input-group">
                            <div className="custom-file">
                                <input type="text" className="form-control" name="accountNumber" placeholder="Account Number" readOnly autoComplete="off" value={getBeneData.accountNumber} maxLength={20} onKeyDown={isNumber}/>
                            </div>
                        </div>
                        {getBeneError && getBeneError.accountNumber && <span className="error text-danger">The field is required</span>}
                    </div>
                    {getBeneData.beneBankName && <div className="form-group col-lg-12 col-md-12">
                        <label>Bene Bank Name</label>
                        <div className="input-group">
                            <div className="custom-file">
                                <input type="text" className="form-control" readOnly autoComplete="off" value={getBeneData.beneBankName} maxLength={20} />
                            </div>
                        </div>
                    </div>}
                </div>
            </Modal>}
        </div>
    )
}

export default Import