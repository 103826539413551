import React, { useEffect, useState } from 'react';
// import { APP_KEY, API_URL } from './../config';
import { getGeoLocation, refId } from './../../Utils/function';
import { GET_ADMIN_DASHBOARD_DATA, GET_MERCHANT_WALLET_BALANCE} from './../../Utils/appConstants';
import { getRequest, postRequest } from '../../Utils/AppApi';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { useHistory } from 'react-router-dom';

function Dashboard(props) {
  const history = useHistory();
  const Array = [ 4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20]
  const [providerCount, setProviderCount] = useState([]);
  const [dashboardData, setDashboardData] = useState([]);
  const [innerData, setInnerData] = useState([]);
  const [data3, setData3] = useState([]);
  const [providerData, setProviderData] = useState([]);
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");

  const getDashboard = () => {
    getRequest(`${GET_ADMIN_DASHBOARD_DATA}`).then((response) => {
      if (response.success === true) {
        // console.log(response.data)
        setDashboardData(response.data)
        setInnerData(response.data2)
        setData3(response.data3)
      } else {
        // toast.error(response.message);
      }
    }).catch(function (error) {
      // console.log(error)
      // setLoading(false);
    })
  }

  const getInstantPayWalletBalance = () => {
      const payload = {
        "accountNumber" : "9813084030",
        "externalRef" : refId(13),
        "latitude" : latitude,
        "longitude" : longitude
    };
    postRequest(`${GET_MERCHANT_WALLET_BALANCE}`, payload, history).then((response) => {
        if (response.success === true) {
          setProviderData(response.data)
        } else {
          setProviderData([])
            // console.log("response.data-----------", response)
        }
    }).catch(function (error) {
        console.log(error)
    });
  }

const fetchLocation = async () => {
  let data = await getGeoLocation();
  setLatitude(data.latitude);
  setLongitude(data.longitude);
}

useEffect(() => {
  if(latitude && longitude) {
    getInstantPayWalletBalance();
  }
}, [latitude, longitude]) // eslint-disable-line react-hooks/exhaustive-deps


useEffect(() => {
  getDashboard();
  setProviderCount(Array);
  fetchLocation();
}, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <div className="content-wrapper pt-2">
        <div className="content-header my-0 py-0">
          <div className="container-fluid">
            <div className="row mb-0 align-items-center">
              <div className="col-sm-6">
                <h1 className='page-title text-blue font-merriweather'>Admin <span className='text-red'>Dashboard</span></h1>
              </div>
            </div>
          </div>
        </div>
        <div className="content">
          <div className="container-fluid pt-2 pb-5">
            <div className="row">
              <div className="col-md-6">
                {dashboardData.map((element) => {
                  return (<><div key={element.text} className={`row `+element.className+` m-0`}>
                    <div className='col-md-7'>{element.text}</div>
                    <div className='col-md-3'>{element.pending}</div>
                    <div className='col-md-2'>{element.success}</div>
                  </div></>)
                })}
                
                <div className='row bg-default m-0'>
                  <div className='col-md-12'>&nbsp;</div>
                </div>
                <div className='row bg-default m-0'>
                  <div className='col-md-7'></div>
                  <div className='col-md-3'>Pending</div>
                  <div className='col-md-2'>Success</div>
                </div>
                {innerData.map((element) => {
                  return (<><div key={element.text} className={`row `+element.className+` m-0`}>
                    <div className='col-md-7'>{element.text}</div>
                    <div className='col-md-3'>{element.pending}</div>
                    <div className='col-md-2'>{element.success}</div>
                  </div></>)
                })}
                <div className='card m-0 p-0 card-body'>
                  {data3.map((element) => {
                    return (<><div key={element.text} className={`row `+element.className+` m-0`}>
                      <div className='col-md-7'>{element.text}</div>
                      <div className='col-md-3'>{element.pending}</div>
                      <div className='col-md-2'>{element.success}</div>
                    </div></>)
                  })}
                </div>
              </div>
              <div className="col-md-6">
                <div className='card dashboard-table p-0 card-body'>
                  <div className='row m-0 text-secondary'>
                    <div className='col-md-3'>Provider</div>
                    <div className='col-md-2'>Wallet Balance</div>
                    <div className='col-md-2'>Success</div>
                    <div className='col-md-2'>Pendings</div> 
                    <div className='col-md-2'>Lien</div> 
                    <div className='col-md-1'>Failed</div>
                  </div>
                  { providerData && providerData.map((element, key) => {
                      return (<div key={key} className='row m-0 text-secondary'>
                      <div className='col-md-3'>{element.key}</div>
                      <div className='col-md-2'>{parseFloat(element.balance).toFixed(2)}</div>
                      <div className='col-md-2'>0</div>
                      <div className='col-md-2'>0</div>
                      <div className='col-md-2'>{parseFloat(element.lien).toFixed(2)}</div>
                      <div className='col-md-1'>0</div>
                    </div>)
                    })}
                  
                  {
                    providerCount.map(element => {
                      return (<div key={element} className='row m-0 text-secondary'>
                      <div className='col-md-3'>Provider {element}</div>
                      <div className='col-md-2'></div>
                      <div className='col-md-3'></div>
                      <div className='col-md-2'></div>
                      <div className='col-md-2'></div>
                    </div>)
                    })
                  }
                  
                  <div className='row m-0 text-secondary'>
                    <div className='col-md-3'>Total</div>
                    <div className='col-md-2'>0</div>
                    <div className='col-md-3'>0</div>
                    <div className='col-md-2'>0</div>
                    <div className='col-md-2'>0</div>
                  </div>
                  
                  <div className='row m-0 text-secondary'>
                    <div className='col-md-3'></div>
                    <div className='col-md-3'>Opening Balance</div>
                    <div className='col-md-2'>Wallet Load</div>
                    <div className='col-md-2'>Sale</div>
                    <div className='col-md-2'>Wallet Closing</div>
                  </div>

                  <div className='row m-0 text-secondary'>
                    <div className='col-md-3'>API Partner</div>
                    <div className='col-md-3'></div>
                    <div className='col-md-2'></div>
                    <div className='col-md-2'></div>
                    <div className='col-md-2'></div>
                  </div>
                  <div className='row m-0 text-secondary'>
                    <div className='col-md-3'>State Head</div>
                    <div className='col-md-3'></div>
                    <div className='col-md-2'></div>
                    <div className='col-md-2'></div>
                    <div className='col-md-2'></div>
                  </div>
                  <div className='row m-0 text-secondary'>
                    <div className='col-md-3'>Super Distributor</div>
                    <div className='col-md-3'></div>
                    <div className='col-md-2'></div>
                    <div className='col-md-2'></div>
                    <div className='col-md-2'></div>
                  </div>
                  <div className='row m-0 text-secondary'>
                    <div className='col-md-3'>Master Distributor</div>
                    <div className='col-md-3'></div>
                    <div className='col-md-2'></div>
                    <div className='col-md-2'></div>
                    <div className='col-md-2'></div>
                  </div>
                  <div className='row m-0 text-secondary'>
                    <div className='col-md-3'>Distributor</div>
                    <div className='col-md-3'></div>
                    <div className='col-md-2'></div>
                    <div className='col-md-2'></div>
                    <div className='col-md-2'></div>
                  </div>
                  <div className='row m-0 text-secondary'>
                    <div className='col-md-3'>Patner</div>
                    <div className='col-md-3'></div>
                    <div className='col-md-2'></div>
                    <div className='col-md-2'></div>
                    <div className='col-md-2'></div>
                  </div>
                  <div className='row m-0 text-secondary'>
                    <div className='col-md-3'>Members</div>
                    <div className='col-md-3'></div>
                    <div className='col-md-2'></div>
                    <div className='col-md-2'></div>
                    <div className='col-md-2'></div>
                  </div>

                  <div className='row m-0 text-secondary'>
                    <div className='col-md-3'>Total</div>
                    <div className='col-md-3'>0</div>
                    <div className='col-md-2'>0</div>
                    <div className='col-md-2'>0</div>
                    <div className='col-md-2'>0</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;