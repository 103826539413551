import React, { useEffect, useState } from 'react';
import { GET_MY_CHILD_USERS, POST_USER_TRANSFER } from './../../Utils/appConstants';
import { getRequest, postRequest } from './../../Utils/AppApi';
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
// import { passwordStrength } from 'check-password-strength';
// import CheckPassword from './checkPassword'
import { Select } from 'antd';

function TransferUser(props) {
  const history = useHistory();
  const [fromIds, setFromIds] = useState(null);
  const [toUserId, setToUserId] = useState(null);
  const [myUsers, setMyUsers] = useState([]);

  const handleSubmit = () => {
    if (fromIds && toUserId) {
      const payload = {
        fromIds: fromIds,
        toUserId: toUserId,
      }
      postRequest(POST_USER_TRANSFER, payload, history).then((response) => {
        if (response.success === true) {
          toast.success(response.message);
          window.location.reload();
        } else {
          toast.error(response.message);
        }
      }).catch(function (error) {
        // console.log(error)
        // setLoading(false);
      })
    } else {
      toast.error("Please select a values");
    }
  } // eslint-disable-line react-hooks/exhaustive-deps

  const getMyUser = () => {
    getRequest(GET_MY_CHILD_USERS + "?isAdmin=true", history).then((response) => {
      if (response.success === true) {
        setMyUsers(response.data.map((item) => {
          let name = item.username + ' - ' + item.name + ' - ' + item.rolecode;
          return { "value": item._id, "label": name };
        }));
      }
    }).catch(function (error) {
      console.log(error)
      // setLoading(false);
    })
  }

  const onChangeFromValueSelect = (event) => {
    // console.log("-----------", event)
    setFromIds(event);
  }

  const onChangeToValueSelect = (event) => {
    // console.log("-----------", event)
    setToUserId(event);
  }

  useEffect(() => {
    getMyUser()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <div className="content-wrapper pt-2">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-0 align-items-center">
              <div className="col-sm-6">
                <h1 className='page-title text-blue font-merriweather'>Transfer <span className='text-red'> User</span></h1>
              </div>
            </div>
          </div>
        </div>
        <div className="content">
          <div className="container-fluid pt-2 pb-5">
            <div className="row">
              <div className="col-md-6">
                <div className='card rounded-0'>
                  <div className='card-body'>
                    <div className='row'>
                      <div className='col-lg-12'>
                        <div className="form-group">
                          <label className='date-search-label text-blue'>From User</label>
                          <Select options={myUsers} className={`normal-light w-100`} showSearch filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                          } classNamePrefix="select" placeholder="Select " onChange={(e) => onChangeFromValueSelect(e)} />
                        </div>
                      </div>
                      <div className='col-lg-12'>
                        <div className="form-group">
                          <label className='date-search-label text-blue'>To User</label>
                          <Select options={myUsers} className={`normal-light w-100`} showSearch filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                          } classNamePrefix="select" placeholder="Select " onChange={(e) => onChangeToValueSelect(e)} />
                        </div>
                      </div>
                      <div className='col-lg-12'>
                      <div className="form-group text-lg-right">
                          <button type="submit" className="btn btn-primary rounded-pill pl-4 pr-4 pointer" onClick={() => handleSubmit()} > Submit</button>
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer
          position="top-center"
          autoClose={10000}
          limit={3}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </div>
    </div>
  )
}

export default TransferUser