import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import { Link } from 'react-router-dom';
import { isNumber } from '../Utils/Common';
import { FORGET_PASSWORD_MOBILE_NUMBER, MOBILE_SEND_VERFIY, FORGET_PASSWORD, USER_ROLE } from './../Utils/appConstants';
import { postRequest, getRequest } from '../Utils/AppApi';
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { /*APP_KEY*/ API_URL } from '../config';
import { passwordStrength } from 'check-password-strength';
import bgshape from './../assets/bgshape.svg';
import Footer from "./Footer/Footer";
import OtpInput from "react-otp-input";
import Select from 'react-select';

import AdvertiseSlider from "./AdvertiseSlider";

function ForgetPassword(props) {
  const history = useHistory()
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [appInfo, setAppInfo] = useState("");
  const [otpValue, setOtpValue] = useState("");
  const [isPassStrength, setPassStrength] = useState(null);
  const [newPassword, setNewPassword] = useState(null);
  const [roleData, setRoleData] = useState([]);
  const mobile = useFormInput('');
  // const newPassword = useFormInput('');
  const confirmPassword = useFormInput('');
  const pancard = useFormInput('');
  const [getRole, setRole] = useState(null);

  const getRoles = () => {
    getRequest(USER_ROLE+'/4', history).then((response) => {
      if (response.success === true) {
        setRoleData(response.data.map((item) => {
          // setRole(item._id)
          return { "value": item._id, "label": item.name };
        }));
      } else {
        toast.error(response.message);
      }
    }).catch(function (error) {
      console.log(error)
      // setLoading(false);
    })
  }

  const onChangeType = (event) => {
    const { value } = event;
    // // console.log(`selected ${value}`);
    setRole(value);
  }

  const handleStep1 = () => {

    const payload = {
      mobile: mobile.value,
      roleId: getRole,
      pancard: pancard.value
    }
    setLoading(true);
    postRequest(FORGET_PASSWORD_MOBILE_NUMBER, payload, history).then((response) => {
      if (response.success === true) {
        toast.success(response.message);
        setStep(step + 1)
      } else {
        toast.error(response.message);
      }
      setLoading(false);
    }).catch(function (error) {
      // console.log(error)
      setLoading(false);
    })
  }

  const handleStep2 = () => {
    const payload = {
      mobile: mobile.value,
      otp: otpValue
      // otp : firstOTP.value + secondOTP.value + thirdOTP.value + fourthOTP.value
    }
    setLoading(true);
    postRequest(MOBILE_SEND_VERFIY, payload, history).then((response) => {
      if (response.success === true) {
        toast.success(response.message);
        setStep(step + 1)
      } else {
        toast.error(response.message);
      }
      setLoading(false);
    }).catch(function (error) {
      // console.log(error)
      setLoading(false);
    })
  }

  const handleStep3 = () => {
    if(isPassStrength === 'Strong') {
    const payload = {
      mobile: mobile.value,
      roleId: getRole,
      pancard: pancard.value,
      newPassword: newPassword,
      confirmPassword: confirmPassword.value,
    }
    setLoading(true);
    postRequest(FORGET_PASSWORD, payload, history).then((response) => {
      if (response.success === true) {
        toast.success(response.message);
        setStep(step + 1)
      } else {
        toast.error(response.message);
      }
      setLoading(false);
    }).catch(function (error) {
      // console.log(error)
      setLoading(false);
    })
  } else {
    toast.error('It should be password strength is strong');
  }
  }

  const redirectLoginPage = () => {
    history.push({
      pathname: "/login"
    });
  }

  const getAppInfo = () => {
    getRequest(`${API_URL}/appinfo`).then(async (data) => {
      setLoading(false);
      if (data) {
        // console.log("========================", data)
        await setAppInfo(data)
      } else {
        toast.error(data.data.message)
      }
    }).catch(function (error) {
      setLoading(false);
      console.log(error);
    })
  }

  const handleOTPChange = (otp) => {
    setOtpValue(otp)
  }

  const checkPasswordStrength = (e) => {
    // console.log('e---------', e.target.value)
    setNewPassword(e.target.value)
    let data = passwordStrength(e.target.value).value;
    setPassStrength(data);
  }

  useEffect(() => {
    getRoles();
    getAppInfo();
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className='wrapper'>
      <div className='topbar'>
        <nav className="main-header navbar navbar-expand navbar-light flex-wrap">
          <ul className="navbar-nav">
            <li className="nav-item">
              <a href="\#" className="brand-link">
                <img src="../../logo.png" alt="Logo" className="brand-image" />
              </a>
            </li>
          </ul>

          <ul className="navbar-nav ml-auto">
          <li className="nav-item">
              <a href={`https://wa.me/${appInfo.helpNumber}`} target="_blank" rel="noreferrer" className='nav-link'>
                {/* <i className='fas fa-phone-alt mr-1 call-icon'></i> */}
                <i class="fa-brands fa-whatsapp fa-fade fa-lg mr-1 call-icon"></i>
                <span className='helpline-text'>{appInfo.helpNumber}</span>
              </a>
            </li>
            <li className="nav-item pl-0 pl-md-4">
              <a href="mailto:{appInfo.email}" className='nav-link'><i className='fas fa-envelope mr-1 call-icon'></i> <span className='helpline-text'>{appInfo.email}</span></a>
            </li>
          </ul>
        </nav>
      </div>
      <div className='content-wrapper login-outer' style={{ backgroundImage: `url(${bgshape})`, backgroundRepeat: "no-repeat" }}>
        <div className='login-container' >
          <div className=''>
            <div className='row login-background' >
              <bgshape />
              <div className='col-lg-2 text-center'></div>
              <div className='col-md-6 col-lg-5 text-center'>
                <AdvertiseSlider/>
              </div>
              {/* <div className='col-md-6 col-lg-7 align-self-center'> */}
                {/* <img src='../../festival/HappyDhantheres.png'  alt='' /> */}
              {/* </div> */}
              <div className='col-md-6 col-lg-5'>
                <div className="login-box">
                  {/* <p className="login-box-msg">
                    SR PAY Private Limited Registration in Process...
                  </p> */}
                  <div className="">
                    <div className="card-body" style={{ textAlign: "center" }}>
                      {step !== 4 &&
                        <img src="../../logo.png" alt="Logo" width={"222"} className="brand-image" />
                      }
                      {step === 1 && <><h2 className='login-box-heading mt-5 mb-5'>Enter your mobile <br /> Forget Password</h2>
                        <div className="input-group mb-3">
                          <input type="text" className="form-control" {...mobile} placeholder="Mobile Number" maxLength={"10"} onKeyDown={isNumber} />
                        </div>
                        <div className="input-group mb-3" style={{"textAlign" : "left"}}>
                          <Select options={roleData} className={`normal-light w-100`} classNamePrefix="select" placeholder="Select a Type" name='mode' onChange={(e) => onChangeType(e)} defaultValue={getRole} />
                        </div>
                        <div className="input-group mb-3">
                          <input type="text" className="form-control" name='pancard' {...pancard}  placeholder="Pancard" maxLength={"10"} />
                        </div>

                        <div className="mt-3">
                          <button type="submit" onClick={() => handleStep1()} className="btn btn-block login-button ">Send {loading ? "..." : ""}</button>
                        </div>
                        <div className="mt-3 join-button px-5 py-1 pointer" onClick={() => redirectLoginPage()}>
                          <span className='my-1'>Login Now</span>
                        </div>
                      </>}

                      {step === 2 && <><h2 className='login-box-heading mt-5 mb-5'>Enter OTP</h2>
                        <div className="mb-3 mt-3 otp-justify">
                          <OtpInput
                            value={otpValue}
                            separator={
                              <span>
                                <strong></strong>
                              </span>
                            }
                            onChange={(value) => handleOTPChange(value)}
                            isInputNum={true}
                            className="justify-content-md-around"
                            inputStyle={{
                              width: "3rem",
                              height: "3rem",
                              margin: "0 0.9rem",
                              fontSize: "2rem",
                              borderRadius: 4,
                              border: "1px solid rgba(0,0,0,0.3)"
                            }}
                          />
                        </div>

                        <div className="mt-3">
                          <button type="submit" onClick={() => handleStep2()} className="btn btn-block login-button ">Continue {loading ? "..." : ""}</button>
                        </div></>}

                      {step === 3 && <><h2 className='login-box-heading mt-5 mb-5'>Enter your password</h2>
                        <div className="input-group mb-0">
                          <input type="password" className="form-control" name='newPassword' placeholder="New Password" onChange={(e) => checkPasswordStrength(e)} maxLength={"20"} value={newPassword} />
                          
                        </div>
                        <div className="input-group mb-0">
                        <span>Password Strength: <p className={isPassStrength === 'Strong' ? 'text-success' : 'text-danger'}>{isPassStrength} </p></span>
                        </div>
                        <div className="input-group mt-3 mb-3">
                          <input type="password" className="form-control" {...confirmPassword} placeholder="Confirm Password" maxLength={"20"} />
                        </div>

                        <div className="mt-2">
                          <button type="submit" onClick={() => handleStep3()} className="btn btn-block login-button ">Submit {loading ? "..." : ""}</button>
                        </div></>}

                      {step === 4 && <>
                        <div>
                          <div className="content-div16">
                            <img className="vector-icon188" alt={"vector"} src="../../images/vector188.svg" />
                            <div className="dear-partner-welcome-to-srpay mt-4">
                              Your password changed successfully.
                            </div>
                            <div className="flex-row justify-content-center d-flex" onClick={() => redirectLoginPage()}>
                              <div className="continue">Login</div>
                              <img className="arrow-icon" alt={"arrow"} src="../../images/arrow-1.svg" />
                            </div>
                          </div>
                        </div>
                      </>}
                      {step !== 5 &&
                        <div className="mt-1 text-center">
                          <p className='forgot'>
                            By continuing you accept our T&C and Privacy Policy
                          </p>
                        </div>
                      }
                      <div className='my-4'><hr /></div>
                      <div className='mb-5 pointer' target="_blank" onClick={() => {
                        // if(process.env.REACT_APP_SRPAY_LINK === "www.srpay1.com") {
                          window.open('https://play.google.com/store/apps/details?id=com.srpaypartner', '_blank')
                        // }
                        }}>
                        <img src='../../googleplay1@3x.png' alt='google' width={"213.74"} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer position="top-center" autoClose={5000} limit={2} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="colored" />
      </div>
      <Footer />
    </div>
  );
}

const useFormInput = (initialValue) => {
  const [value, setValue] = useState(initialValue);
  // const [isPassStrength, setPassStrength] = useState(initialValue);
  
  const handleChange = e => {
    console.log("e.target.name-------", e.target.name)
    if(e.target.name === 'pancard') {
      setValue(e.target.value.toUpperCase());
    } else {
      setValue(e.target.value);
    }
  }
  return {
    value,
    onChange: handleChange
  }
}

export default ForgetPassword;