import React, { useEffect, useState, useRef, useCallback } from 'react';
// import CsvDownloader from 'react-csv-downloader';
import { Modal, Popconfirm, Switch, Tooltip } from 'antd';
import { CSVLink } from 'react-csv';
import DataTable from 'react-data-table-component';
import 'antd/dist/antd.css';
import { useHistory } from "react-router-dom";
import { ADMIN_GET_ALL_APPROVED_USERS, ADMIN_GET_ALL_APPROVED_EXPORT_USERS, POST_USER_CONTROL_STATUS, CHANGE_USER_STATUS, LEDGER_CORRECT_API } from './../../../Utils/appConstants';
import { getRequest, postRequest } from './../../../Utils/AppApi';
import { ToastContainer, toast } from 'react-toastify';
import ImageViewer from 'react-simple-image-viewer';
import { hideCharters, getUser, kycStatusValue, INRFormat, isNumber } from './../../../Utils/Common';
// import moment from 'moment';
// import Moment from 'react-moment';
import RequestMoney from '../../requestMoney';
import { getUTCToLocalDateFormat } from '../../../Utils/function';
// import { event } from 'jquery';

function App(props) {
    // console.log("props -------",props.search)
    const user = getUser();
    const history = useHistory();
    const csvLink = useRef()
    const limit = 10;
    const getMode = props.mode ? props.mode : "";
    const startDate = props.startDate ? props.startDate : "";
    const endDate = props.endDate ? props.endDate : "";
    const search = props.search ? props.search : "";
    // alert(getMode)
    const [getData, setData] = useState([]);
    const [getCsvData, setCsvData] = useState([]);
    const [getTotalRecord, setTotalRecord] = useState(0);
    const [isLimit, setLimit] = useState(limit)
    const [page, setPage] = useState(1);
    const [isModalUserDetail, setIsModalUserDetail] = useState(false);
    const [isModalUserApprovedReject, setIsModalUserApprovedReject] = useState(false);
    const [isSelectedUser, setIsSelectedUser] = useState([]);
    const [isSelectedUserAadharDetail, setIsSelectedUserAadharDetail] = useState({});
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const [viewImage, setViewImage] = useState("");
    const [isReason, setIsReason] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [pancardNumber, setPancardNumber] = useState("");
    const [accountNumber, setAccountNumber] = useState("");
    const [aadharNumber, setAadharNumber] = useState("");

    const [isSuspendUser, setIsSuspendUser] = useState(false);
    const [isCreditUser, setIsCreditUser] = useState(false);
    const [isDebitUser, setIsDebitUser] = useState(false);

    const [isModalFundTopup, setIsModalFundTopup] = useState(false);
    const [requestMoneyUserId, setRequestMoneyUserId] = useState("");
    const [requestMoneyTitle, setRequestMoneyTitle] = useState("");

    const [capitalAmount, setCapitalAmount] = useState(0);
    let prpHide = {};
    if (props.roleOrderExist === 2) {
        prpHide = {
            name: 'Wallet',
            selector: row => row.walletbalances ? <Tooltip placement="bottom" title={INRFormat(row.walletbalances.balance)}>{INRFormat(row.walletbalances.balance)}</Tooltip> : 0,
        }
    }

    const columns = [{
        name: 'Action',
        cell: row => <>{user.data && user.data.role.code !== 'A' && <div className='icon-border border border-primary text-primary pointer' title='Fund Transfer' onClick={(event) => handleFundTopup(event, row)}><i class="fa fa-solid fa-indian-rupee-sign mx-1"></i><i className="fa fa-share" aria-hidden="true"></i></div>}<div className='icon-border border border-primary text-primary pointer' title='View Details'><i className="fa fa-eye " onClick={(event) => handleUserDetail(event, row)} aria-hidden="true"></i></div><div className='icon-border border border-danger text-danger pointer' onClick={(event) => handleUserModel(event, row)} title='User Control'><i className="fa fa-user-lock" aria-hidden="true"></i></div>
        {/* <Popconfirm placement="left" title={"Are you sure want to Correct Wallet?"} onConfirm={() => handleCorrect(row.user.username)} okText="Yes" cancelText="No"><div className='icon-border border border-success text-success pointer' title='Wallet Correct' ><i class='fas fa-book'></i></div></Popconfirm> */}
        </>,
        omit: user.data.isStaff,
        width: "150px"
    }, {
        name: 'User Id',
        selector: row => <><Popconfirm placement="left" className='pointer' title={"Are you sure want to Correct Wallet?"} onConfirm={() => handleCorrect(row.user.username)} okText="Yes" cancelText="No"><Tooltip placement="bottom" title={row.user.username}>{row.user.username}</Tooltip></Popconfirm></>,
        sortable: true,
        width: "120px"
    }, {
        name: 'User Type',
        selector: row => <Tooltip placement="bottom" title={row.role.name}>{row.user.isStaff ? "STAFF" : row.role.name}</Tooltip>,
        width: "120px",
    }, {
        name: 'Pan No.',
        selector: row => <Tooltip placement="bottom" title={hideCharters(row.pancardNumber)}>{hideCharters(row.pancardNumber)}</Tooltip>,
        width: "120px"
    }, {
        name: 'Name',
        selector: row => <Tooltip placement="bottom" title={row.user.name}>{row.user.name}</Tooltip>,
        width: "150px"
    }, {
        name: 'Mobile',
        selector: row => hideCharters(row.mobile),
        width: "120px"
    }, prpHide, {
        name: 'Capital',
        selector: row => row.user.capitalAmount ? <Tooltip placement="bottom" title={INRFormat(row.user.capitalAmount)}>{INRFormat(row.user.capitalAmount)}</Tooltip> : 0,
    }, {
        name: 'Khata',
        selector: row => (row.khatabooks.length > 0) ? <Tooltip placement="bottom" title={INRFormat(row.khatabooks[0].closingBalance)}>{INRFormat(row.khatabooks[0].closingBalance)}</Tooltip> : 0,
    }, {
        name: 'Parent ID',
        selector: row => <><Tooltip placement="bottom" title={row.userParentDetail && row.userParentDetail.username}>{row.userParentDetail && row.userParentDetail.username}</Tooltip></>,
        width: "150px"
    }, {
        name: 'KYC Status',
        cell: row => <>{kycStatusValue(row.user.KYCStatus)}</>,
    }, {
        name: 'Status',
        cell: row => <>{(row.user.status) ? <span className='pointer' onClick={() => handleChangeStatus(row.userId, false)}><div className="text-success">Active</div></span> : <span className='pointer' onClick={() => handleChangeStatus(row.userId, true)}><div className="text-danger">Deactive</div></span>}</>,
    }, {
        name: 'Date',
        cell: row => getUTCToLocalDateFormat(row.createdAt),
        width: "120px"
    }]

    const handleCorrect = (username) => {
        try {
            if (username) {
                let payload = { username: username }
                postRequest(`${LEDGER_CORRECT_API}`, payload, history).then((response) => {
                    if (response.success === true) {
                        getDataReports();
                    }
                }).catch(function (error) {
                    // console.log(error)
                })
            }
        } catch (error) {
            console.log("error ---------------", error)
        }
    }

    const handleUserModel = (event, data) => {
        setIsModalUserApprovedReject(true)
        setIsSelectedUser(data)
        setIsSuspendUser(data.user.KYCStatus === 3 ? true : false)
        setIsCreditUser(data.user.creditStatus)
        setIsDebitUser(data.user.debitStatus)
        setIsReason(data.rejectReason)
        setCapitalAmount(data.user.capitalAmount)
        // console.log("data ---------------", data)
    }

    const handleUserDetail = (event, data) => {
        setIsModalUserDetail(true)
        setIsSelectedUser(data)

        let user = JSON.parse(JSON.stringify(data.aadharNumberVerifiedDetail));
        setIsSelectedUserAadharDetail(user)

        setMobileNumber(hideCharters(data.mobile))
        setPancardNumber(hideCharters(data.pancardNumber))
        setAccountNumber(hideCharters(data.accountNumber))
        setAadharNumber(hideCharters(data.aadharNumber))
        // console.log("data ---------------", data)
    }

    const handleFundTopup = (event, data) => {
        setIsModalFundTopup(true)
        setRequestMoneyTitle(data.user)
        setRequestMoneyUserId(data.userId)
    }

    const handleChangeStatus = (userId, status) => {
        // const queryString = objectToQueryString(payload);
        if (user.data.role.code === "A") {
            let payload = { userId: userId, status: status }
            postRequest(`${CHANGE_USER_STATUS}`, payload, history).then((response) => {
                if (response.success === true) {
                    getDataReports();
                    toast.success(response.message);
                } else {
                    toast.error(response.message);
                }
            }).catch(function (error) {
                // console.log(error)
            })
        } else {
        }
    }

    const onChangeSuspendUser = (checked) => {
        setIsSuspendUser(checked)
        // console.log(`switch to ${checked}`);
    };

    const onChangeCreditStatus = (checked) => {
        setIsCreditUser(checked)
        // console.log(`switch to ${checked}`);
    };

    const onChangeDebitStatus = (checked) => {
        setIsDebitUser(checked)
        // console.log(`switch to ${checked}`);
    };

    const handleCapitalAmount = (event) => {
        const { value } = event.target;
        setCapitalAmount(value);
    }

    const handleSave = (data) => {
        const payload = {
            userId: data.userId,
            status: isSuspendUser,
            creditStatus: isCreditUser,
            debitStatus: isDebitUser,
            reason: isReason,
            capitalAmount: capitalAmount
        }
        handleSubmit(payload);
    }

    const handleSubmit = (data) => {
        // const queryString = objectToQueryString(payload);
        postRequest(`${POST_USER_CONTROL_STATUS}`, data, history).then((response) => {
            if (response.success === true) {
                toast.success(response.message);
                getDataReports();
                handleHideModal();
            } else {
                toast.error(response.message);
            }
        }).catch(function (error) {
            // console.log(error)
            // setLoading(false);
            handleHideModal();
        })
        // console.log("accept ----",value)
    }

    const handleHideModal = () => {
        setIsModalFundTopup(false)
        setIsModalUserDetail(false);
        setIsModalUserApprovedReject(false)
    };

    const objectToQueryString = (obj) => {
        var str = [];
        for (var p in obj)
            if (obj.hasOwnProperty(p)) {
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
        return str.join("&");
    }

    const params = { page: page, limit: isLimit };

    const getDataReports = (data) => {
        if (data) {
            setLimit(data)
            params.limit = data
        }
        if (startDate && endDate) {
            params.startDate = startDate ? startDate : "";
            params.endDate = endDate ? endDate : "";
        }

        params.roleId = getMode;

        if (search) {
            params.search = search ? search : "";
        }

        const queryString = objectToQueryString(params);
        getRequest(`${ADMIN_GET_ALL_APPROVED_USERS}?${queryString}`, history).then((response) => {
            if (response.success === true) {
                setData(response.data);
                setTotalRecord(response.data.totalDocs);
                setPage(response.data.page);
            } else {
                setData([]);
                setTotalRecord(0);
                setPage(1);
            }
        }).catch(function (error) {
            console.log(error)
            // setLoading(false);
        })
    } // eslint-disable-line react-hooks/exhaustive-deps

    const getCSVDataList = () => {
        let params = {}
        if (startDate && endDate) {
            params.startDate = startDate ? startDate : "";
            params.endDate = endDate ? endDate : "";
        }

        const queryString = objectToQueryString(params);
        getRequest(`${ADMIN_GET_ALL_APPROVED_EXPORT_USERS}?${queryString}`, history).then(async (response) => {
            if (response.success === true) {
                let csvdataRecord = [];
                let csvdata = response.data;
                await csvdata.map((e) => {
                    return csvdataRecord.push({ "User Id": e.user.username, "User Type": e.user.isStaff ? "STAFF" : e.role.name, "Pan No.": e.pancardNumber, "Reff User": e.refmobile, "Name": e.user.name, "Mobile": e.mobile, "Capital": INRFormat(e.user.capitalAmount), "Wallet Balance": INRFormat(e.walletbalances.balance), "Khata": INRFormat(e.khatabooks.length > 0 ? e.khatabooks[0].closingBalance : 0), "Parent ID": e.userParentDetail && e.userParentDetail.username, "KYC Status": kycStatusValue(e.user.KYCStatus), "Status": (e.user.status ? "Active" : "Deactive"), "Date": getUTCToLocalDateFormat(e.createdAt) });
                });
                // console.log("csvdataRecord ------------------------", csvdataRecord)
                setCsvData(csvdataRecord);
                setTimeout(() => {
                    csvLink.current.link.click();
                }, 1000);
            } else {
                setCsvData([]);
            }
        }).catch(function (error) {
            console.log(error)
            // setLoading(false);
        })
    }

    const handlePageChange = page => {
        setPage(page);
    };

    useEffect(() => {
        getDataReports();
    }, [page, props.handleSearch]) // eslint-disable-line react-hooks/exhaustive-deps

    // useEffect(() => {
    //     getDataReports();
    //     // setIsDownloadCsv(isDownloadCsv ? true : false);
    // }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const openImageViewer = useCallback((image) => {
        setViewImage(image)
        setIsModalUserDetail(false);
        setIsViewerOpen(true);
    }, []);

    const closeImageViewer = () => {
        setViewImage("");
        setIsModalUserDetail(true);
        setIsViewerOpen(false);
    };

    const handleMouseOver = (originalString, field) => {
        if (field === "mobile") {
            setMobileNumber(originalString)
        } else if (field === "pancardNumber") {
            setPancardNumber(originalString)
        } else if (field === "accountNumber") {
            setAccountNumber(originalString)
        } else if (field === "aadharNumber") {
            setAadharNumber(originalString)
        }
    };

    const handleMouseOut = (originalString, field) => {
        if (field === "mobile") {
            setMobileNumber(hideCharters(originalString))
        } else if (field === "pancardNumber") {
            setPancardNumber(hideCharters(originalString))
        } else if (field === "accountNumber") {
            setAccountNumber(hideCharters(originalString))
        } else if (field === "aadharNumber") {
            setAadharNumber(hideCharters(originalString))
        }
    };

    return (
        <div>
            <div className="container-fluid pt-2 pb-5">
                <div className="col-md-12">
                    {user.data.role.code === "A" &&
                        <div className='float-sm-right d-flex mb-1'>
                            <button type="button" className="btn btn-primary mr-3" onClick={() => getCSVDataList()} ><i className="fa fa-file-excel"></i> Export</button>
                            <CSVLink
                                data={getCsvData}
                                filename='manage-user-report.csv'
                                className='hidden'
                                ref={csvLink}
                                target='_blank'
                            />
                            {/* <button type="button" className="btn btn-danger">
                            <i className="fa fa-file-pdf"></i> Export to PDF
                        </button> */}
                        </div>}
                    <div className="box-body">
                        <DataTable
                            // title={getMode}
                            columns={columns}
                            data={getData.docs}
                            highlightOnHover
                            pagination
                            paginationServer
                            striped
                            paginationTotalRows={getTotalRecord}
                            onChangeRowsPerPage={getDataReports}
                            onChangePage={handlePageChange}
                        />
                    </div>
                </div>
            </div>
            <ToastContainer position="top-center" autoClose={5000} limit={2} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="colored" />
            {isViewerOpen && (
                <ImageViewer src={[viewImage]} currentIndex={0} disableScroll={false} closeOnClickOutside={true} onClose={closeImageViewer} />
            )}

            {isModalUserApprovedReject && <Modal title={"User Management"} width={600} open={isModalUserApprovedReject} onOk={() => handleSave(isSelectedUser)} onCancel={handleHideModal} okText={"Save"} cancelText={"Cancel"}>
                <div className='row'>
                    <div className='col-md-12 d-flex'>
                        {user.data.role.code === "A" &&
                            <>
                                <div className="form-group col-md-4">
                                    <label>Re KYC Doc: </label> <Switch size="small" defaultChecked={isSuspendUser} onChange={onChangeSuspendUser} /> {isSuspendUser}
                                </div>
                                <div className="form-group col-md-4">
                                    <label>Debit Freeze: </label> <Switch size="small" defaultChecked={isDebitUser} style={{ backgroundColor: isDebitUser ? 'green' : 'red' }} onChange={onChangeDebitStatus} />
                                </div>
                            </>}
                        {user.data.role.code !== "A" &&
                            <div className="form-group col-md-4">
                                <label>Credit Freeze: </label> <Switch size="small" defaultChecked={isCreditUser} style={{ backgroundColor: isCreditUser ? 'green' : 'red' }} onChange={onChangeCreditStatus} />
                            </div>}
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12 form-group'>
                        <label>Reason: </label>
                        <textarea className="form-group form-control" cols={"4"} rows={"5"} >{isReason}</textarea>
                    </div>
                    <div className='col-md-12 form-group'>
                        <label>Capital Amount: </label>
                        <input type='text' name="capital_amount" className="form-group form-control" value={capitalAmount} onChange={(e) => handleCapitalAmount(e)} onKeyDown={isNumber} maxLength={6} />
                    </div>
                </div>
            </Modal>}

            {isModalFundTopup && <Modal title={"Fund Topup"} width={1200} open={isModalFundTopup} onCancel={handleHideModal} footer={false}>
                <RequestMoney userData={requestMoneyTitle} requestMoneyUserId={requestMoneyUserId} isRequestMoney={true} />
            </Modal>}

            {isModalUserDetail && <Modal title={isSelectedUser.user.name} width={500} open={isModalUserDetail} onCancel={handleHideModal} footer={false}>
                {isSelectedUser ?
                    <ul>
                        <li><span>Name</span> : {isSelectedUser.user.name}</li>
                        <li><span>Role Name</span> : {isSelectedUser.role.name}</li>
                        <li><span>Father Name</span> : {isSelectedUser.fatherName}</li>
                        <li><span>Mother Name</span> : {isSelectedUser.motherName}</li>
                        <li><span>Mobile Number</span> : <span onMouseOver={() => handleMouseOver(isSelectedUser.mobile, "mobile")} onMouseOut={() => handleMouseOut(isSelectedUser.mobile, "mobile")}>{mobileNumber}</span></li>
                        <li><span>Email</span> : {isSelectedUser.email}</li>
                        <li><span>Email Verify</span> : {isSelectedUser.emailVerify}</li>
                        <li><span>Phone Number</span> : {isSelectedUser.phone}</li>
                        <li><span>Pincode</span> : {isSelectedUser.pincode}</li>
                        <li><span>City Name</span> : {isSelectedUser.city}</li>
                        <li><span>State Name</span> : {isSelectedUser.stateObjectId && isSelectedUser.state[0].name}</li>
                        <li><span>Outlet Name</span> : {isSelectedUser.outletName}</li>
                        <li><span>Account Number</span> : <span onMouseOver={() => handleMouseOver(isSelectedUser.accountNumber, "accountNumber")} onMouseOut={() => handleMouseOut(isSelectedUser.accountNumber, "accountNumber")}>{accountNumber}</span></li>
                        <li><span>IFSC Code</span> : {isSelectedUser.ifscCode}</li>
                        <li><span>Bank Name</span> : {isSelectedUser.bankName}</li>
                        <li><span>Account Holder Name</span> : {isSelectedUser.accountHolderName}</li>
                        <li><span>Bank Proof</span> : <div className='text-right'><span className='pointer' onClick={() => openImageViewer(isSelectedUser.bankProof)}><u>Show Image</u></span></div></li>
                        <li><span>Pancard Number</span> : <span onMouseOver={() => handleMouseOver(isSelectedUser.pancardNumber, "pancardNumber")} onMouseOut={() => handleMouseOut(isSelectedUser.pancardNumber, "pancardNumber")}>{pancardNumber}</span></li>
                        <li><span>Pancard Number Verify</span> : {isSelectedUser.pancardNumberVerify ? "Yes" : "No"}</li>
                        {/* <li><span>Pancard Number Verified Detail</span> : {isSelectedUser.pancardNumberVerifiedDetail}</li> */}
                        <li><span>Pan Card Photo</span> : <div className='text-right'><span className='pointer' onClick={() => openImageViewer(isSelectedUser.panCardPhoto)}><u>Show Image</u></span></div></li>
                        <li><span>Aadhar Number</span> : <span onMouseOver={() => handleMouseOver(isSelectedUser.aadharNumber, "aadharNumber")} onMouseOut={() => handleMouseOut(isSelectedUser.aadharNumber, "aadharNumber")}>{aadharNumber}</span></li>
                        <li><span>Aadhar Number Verify</span> : {isSelectedUser.aadharNumberVerify ? "Yes" : "No"}</li>
                        <li><span>Aadhar Name</span> : {isSelectedUserAadharDetail && isSelectedUserAadharDetail.name}</li>
                        <li><span>Address</span> : {isSelectedUserAadharDetail && isSelectedUserAadharDetail.address}</li>
                        <li><span>Date Of Birth</span> : {isSelectedUserAadharDetail && isSelectedUserAadharDetail.dob}</li>
                        <li><span>Aadhar Profile Photo</span> : {isSelectedUserAadharDetail && isSelectedUserAadharDetail.photo_link && <div className='text-right'><img className="elevation-2" src={"data:image/png;base64," + isSelectedUserAadharDetail.photo_link} alt="User Avatar" /></div>}</li>
                        <li><span>Aadhar Card Photo</span> : <div className='text-right'><span className='pointer' onClick={() => openImageViewer(isSelectedUser.aadharPhoto)}><u>Show Image</u></span></div></li>
                        {/* <li><span>Aadhar Number Verified Detail</span> : {isSelectedUser.aadharNumberVerifiedDetail}</li> */}
                        <li><span>GST In Number</span> : {isSelectedUser.gstInNumber}</li>
                        <li><span>GST In Number Verify</span> : {isSelectedUser.gstInNumberVerify ? "Yes" : "No"}</li>
                        {/* <li><span>GST In Number Verified Detail</span> : {isSelectedUser.gstInVerifiedDetail}</li> */}
                        <li><span>GST In Photo</span> : <div className='text-right'><span className='pointer' onClick={() => openImageViewer(isSelectedUser.gstInPhoto)}><u>Show Image</u></span></div></li>
                        <li><span>Selfie Photo</span> : <div className='text-right'><span className='pointer' onClick={() => openImageViewer(isSelectedUser.selfiePhoto)}><u>Show Image</u></span></div></li>
                        <li><span>Communication Address</span> : {isSelectedUser.communicationAddress}</li>
                        <li><span>Registered Address</span> : {isSelectedUser.registeredAddress}</li>
                        <li><span>Business Address</span> : {isSelectedUser.businessAddress}</li>
                    </ul>
                    : ""}
            </Modal>}
        </div>
    )
}

export default App