import React, { useEffect, useState } from 'react';
// import { Tabs, Tab } from 'react-bootstrap-tabs';
// import AppHeader from '../Header/Header';
// import { useHistory } from "react-router-dom";
// import { ToastContainer, toast } from 'react-toastify';
import { UTILITY_OFFLINE_PAYMENT_TAB } from '../../Utils/appConstants';

import EletricityBillPayment from './eletricityBillPayment';
import WaterBillPayment from './waterBillPayment';
import GasBillPayment from './gasBillPayment';
import InsuranceBillPayment from './insuranceBillPayment';
import Maintenance from './../Maintenance';
import { reactLocalStorage } from 'reactjs-localstorage';

import 'antd/dist/antd.css';
import { Tabs } from 'antd';

// let tab = reactLocalStorage.get(UTILITY_OFFLINE_PAYMENT_TAB, Empty, true)

function UitilityPayment(props) {
  const [tabKey, setTabKey] = useState(1); 

  useEffect(() => {
    console.log("props.location.tab -----------", props.location.tab)
    if(props.location.tab) {
      setTabKey(parseInt(props.location.tab))
    }
  },[props.location.tab]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
  }, [tabKey]) // eslint-disable-line react-hooks/exhaustive-deps

  const items = [
    {
      key: 1,
      label: <div className='tabtext' title="Eletricity Bill"><span><img src='../images/electricity.svg' alt='' /></span>Eletricity</div>,
      children: <EletricityBillPayment />,
    },
    {
      key: 2,
      label: <div className='tabtext' title='Water Bill'><span><img src='../images/water.svg' alt='' /></span>Water</div>,
      children: <WaterBillPayment />,
    },
    {
      key: 3,
      label: <div className='tabtext' title='GAS Bill'><span><img src='../images/gas.svg' alt='' /></span>GAS</div>,
      children: <GasBillPayment />,
    },
    {
      key: 4,
      label: <div className='tabtext' title='Insurance Premium'><span><img src='../images/insurance.svg' alt='' /></span>Insurance</div>,
      children: <InsuranceBillPayment data={props.location} />,
    },
  ];

  const onchangeTab = (index) => {
    reactLocalStorage.set(UTILITY_OFFLINE_PAYMENT_TAB, index, true)
    setTabKey(index)
  }
  
  return (
    <div>
      <Maintenance />
      <div className="content-wrapper pt-2 d-none">
        <div className="content-header my-0 py-0">
          <div className="container-fluid">
            <div className="row mb-0 align-items-center">
              <div className="col-sm-6">
                <h1 className='page-title text-blue font-merriweather'>Uitility <span className='text-red'>Payment</span> - Offline</h1>
              </div>
            </div>
          </div>
        </div>
        <div className="content">
          <div className="container-fluid pt-2 pb-5">
            <div className="row">
              <div className="col-md-6">
                <div className='card rounded-0'>
                  <div className='card-body pt-0'>
                    <Tabs className="recharge-tabs" activeKey={tabKey} defaultActiveKey={tabKey} items={items} onChange={onchangeTab} />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className='card rounded-0'>
                  <div className='card-body'>
                    <h3 className='text-blue text-center mb-3'><span className='text-red'>Offers</span></h3>
                    <div className='text-center'>
                      <img src='../images/offer.png' alt='img' />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UitilityPayment