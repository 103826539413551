import React, { useEffect, useState } from 'react';
import { getRequest, postRequest } from '../../../Utils/AppApi';
// import { APP_KEY, API_URL } from './../config';
// import DataTable from 'react-data-table-component';
import { DMT_SETTING_URL } from './../../../Utils/appConstants';
// import { getRequest } from './../../../Utils/AppApi';
import { useHistory } from "react-router-dom";
import { toast } from 'react-toastify';
import { Button, Select, Switch } from 'antd';

function DmtSetting(props) {
    const history = useHistory();
    // const csvLink = useRef()
    // const limit = 10;
    // const dateFormat = 'DD-MM-YYYY';
    // const [search, setSearch] = useState(null);
    // const [endDate, setEndDate] = useState(null);
    // const [startDate, setStartDate] = useState(null);
    const [getData, setData] = useState(null);
    // const [getCsvData, setCsvData] = useState([]);
    // const [getTotalRecord, setTotalRecord] = useState(0);
    // const [page, setPage] = useState(1);
    // const [isLimit, setLimit] = useState(limit);
    const bankOption = [{
        value: 'bank1',
        label: 'bank1',
      },{
        value: 'bank2',
        label: 'bank2',
      },{
        value: 'bank3',
        label: 'bank3',
      }];

      const handleBankChange = (value, index) => {
        // console.log(`selected ${value}`);
        // console.log(`selected index ${index}`);
        let data = getData[index];
        data.pipe = value;
        getData[index] = data;
        setData(getData);
      };

    const onChangeValue = (event, index) => {
        const { name, value } = event.target;
        let data = getData[index];
        data[name] = value;
        // console.log('data-----', data)
        // data[index][name] = value
        getData[index] = data;
        setData(getData);
    }

    const handleSubmit = (index) => {
        // console.log("getData ----------------", getData)
        postRequest(DMT_SETTING_URL, getData[index]).then((response) => {
            console.log("response ----------------", response)
            // setDataField(Object.keys(response));
            if (response.success === true) {
                toast.success(response.message)
            }
        }).catch(function (error) {
            console.log(error)
            // setLoading(false);
        })
    }

    const getAppData = () => {
        getRequest(DMT_SETTING_URL, history).then((response) => {
            // console.log("response ----------------", response.data)
            if (response.success === true) {
                setData(response.data);
            }
        }).catch(function (error) {
            console.log(error)
            // setLoading(false);
        })
    }

    const onChangeSwitch = (checked, index) => {
        console.log(`switch to ${checked}`);
        console.log(`index to ${index}`);
        let data = getData[index];
        data.flag = checked;
        console.log('data-----', data)
        // data[index][name] = value
        getData[index] = data;
        setData(getData);
      };

    // useEffect(() => {
    //     console.log("response.data ------=======", getData)
    //     // getAppData()
    // }, [getData]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        getAppData()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <table class="table table-striped table-small">
                <thead>
                    <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Code</th>
                        <th scope="col">Flag</th>
                        <th scope="col">Pipe</th>
                        <th scope="col">Message</th>
                        <th scope="col">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {getData && getData.map((item, index) => {
                        return <tr key={index}>
                            <td>{item.name}</td>
                            <td>{item.code}</td>
                            <td><Switch defaultChecked={item.flag} onChange={(e) => onChangeSwitch(e, index)} /></td>
                            <td>{item.code === "DMT2" ? <Select defaultValue={item.pipe} onChange={(e) => handleBankChange(e, index)} options={bankOption} style={{ width: "100%" }}/> : <input type="text" className={`form-control normal-light`} name="pipe" onChange={(e) => onChangeValue(e, index)} placeholder="Pipe" defaultValue={item.pipe} />}</td>
                            <td><input type="text" className={`form-control normal-light`} name="message" onChange={(e) => onChangeValue(e, index)} placeholder="Message" defaultValue={item.message} /></td>
                            <td><Button size="mediam" onClick={() => handleSubmit(index)}>Save</Button></td>
                        </tr>
                    })}
                </tbody>
            </table>
        </>
    )
}

export default DmtSetting