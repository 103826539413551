import React, { useEffect, useState, useRef } from 'react';
import useForceUpdate from 'use-force-update';
import { isNumber } from '../../Utils/Common';
import { getGeoLocation } from '../../Utils/function';
import PropagateLoader from "react-spinners/PropagateLoader";
import { POST_AEPS_CASH_WITHDRAWAL,/* POST_AEPS_LOGIN */ } from '../../Utils/appConstants';
import { postRequest } from '../../Utils/AppApi';
import { useHistory } from "react-router-dom";
import { toast } from 'react-toastify';
import Select from 'react-select';
import { Checkbox, Modal, Button } from 'antd';
import xmlJs from 'xml-js';
import axios from 'axios';
import AfterSubmit from './../../container/partnerEkyc';
import BioDevice from './../aepsInstant/bioDevice';
// import { reactLocalStorage } from 'reactjs-localstorage';

function Cashwithdrawal(props) {
    // console.log("props.existData------", props.existData)
    // let existData = reactLocalStorage.get("aepsInputValue");
    const history = useHistory();
    const forceUpdate = useForceUpdate();
    const [isLoading, setLoading] = useState(false);
    const [getDataField, setDataField] = useState(props.existData);
    const [getDataError, setDataError] = useState({});
    const [latitude, setLatitude] = useState("");
    const [longitude, setLongitude] = useState("");
    const [isConsent, setConsent] = useState("Y");
    const [biometricData, setBiometricData] = useState(null);
    const [getBankList, setBankList] = useState([]);

    // const bankData = [
    //     {
    //         "bankId": 109005,
    //         "name": "STATE BANK OF INDIA",
    //         "iin": "607094",
    //         "aepsEnabled": true,
    //         "aadhaarpayEnabled": true,
    //         "aepsFailureRate": "78",
    //         "aadhaarpayFailureRate": "45"
    //     },
    //     {
    //         "bankId": 74984,
    //         "name": "Punjab National Bank",
    //         "iin": "607027",
    //         "aepsEnabled": true,
    //         "aadhaarpayEnabled": true,
    //         "aepsFailureRate": "22",
    //         "aadhaarpayFailureRate": "71"
    //     },
    //     {
    //         "bankId": 134157,
    //         "name": "INDIA POST PAYMENTS BANK",
    //         "iin": "608314",
    //         "aepsEnabled": true,
    //         "aadhaarpayEnabled": true,
    //         "aepsFailureRate": "17",
    //         "aadhaarpayFailureRate": "75"
    //     }]

    const [isSubmit, setSubmit] = useState(false);
    const [isAmount, setAmount] = useState(null);
    const [fingerprintXmlData, setFingerprintXmlData] = useState(null)
    const [status, setStatus] = useState(null);
    const [statusMessage, setStatusMessage] = useState(null);
    const [isAuthAePSModel, setAuthAePSModel] = useState(false);
    const [isAuthAePSTitle, setAuthAePSTitle] = useState('Customer Biometric for AePS Cash WithDrawal');
    // const [isPartnerScan, setPartnerScan] = useState(false);

    const bankSelect = useRef("");
    const aadharInput = useRef("");
    const mobileInput = useRef("");
    const amountInput = useRef("");

    const onChangeValueSelect = (event) => {
        // event.preventDefault();
        const { value } = event;
        props.existInputData(event, value);
        // console.log("value 11----------", "bankiin", value);
        setDataField({ ...getDataField, "bankiin": value });
    }

    const clearFormField = () => {
        props.clearExistInputData();
        setConsent('N');
        setDataError({});
        setDataField([])
    }

    const handleRetry = () => {
        // setPartnerScan(false);
        // setAuthAePSTitle("Partner Transation 2FA");
        setFingerprintXmlData(null);
        setBiometricData(null);
        scanFinger();
    }

    const onChangeValue = (event) => {
        props.existInputData(event);
        const { name, value } = event.target;
        if (name === "amount") {
            setAmount(value)
            if (!handleAmountChange(value)) {
                let formObject = {};
                formObject.validamount = true;
                setDataError(formObject);
            } else {
                setDataError({})
            }
            setConsent('N');
            // console.log("value 3----------")
            setDataField({ ...getDataField, [name]: value });
        } else {
            setDataError({});
            // console.log("value 4----------")
            setDataField({ ...getDataField, [name]: value });
        }
        // console.log("getDataField ----------------", getDataField)
        // console.log("value ----------------", name, value)
    }

    const handleAmountChange = (amount) => {
        // const inputAmount = value;
        // // Regular expression to check if the last two digits are '00'
        // const isValidAmount = /\d{1}00$/.test(inputAmount);
        // return isValidAmount;
        if (amount) {
            const amountString = amount.toString();
            // Extract the last two digits
            const lastTwoDigits = amountString.slice(-2);
            // Check if the last two digits are "00" or "50"
            if (lastTwoDigits === "00" || lastTwoDigits === "50") {
                return true; // No change needed
            } else {
                return false
            }
        }
    };

    const FormValidate = () => {
        let formValid = true;
        let formObject = {};
        setDataError({})
        if (getDataField && getDataField.length === 0) {
            formValid = false;
        }
        let array = ["bankiin", "aadhaar", "mobile", "amount"];
        array.forEach(element => {
            if (getDataField[element] === "" || typeof getDataField[element] === "undefined") {
                formObject[element] = true;
                formValid = false;
            }
        });
        console.log("getDataField ----------------", getDataField)
        if (!handleAmountChange(isAmount)) {
            toast.error("Please enter an amount that is a multiple of Rs. 100 and Rs. 50.");
            formValid = false;
        }

        if (getDataField.amount > 10000) {
            toast.error("Maximum cash withdrawal amount is Rs: 10,000");
            formValid = false;
        }

        if (getDataField.aadhaar && getDataField.aadhaar.length !== 12) {
            toast.error("Aadhaar numbers should be 12 digits long");
            formValid = false;
        }

        if (getDataField.mobile && getDataField.mobile.length !== 10) {
            toast.error("Mobile number should be 10 digits long");
            formValid = false;
        }
        // console.log("getDataField ----------------", getDataField)
        setDataError(formObject);
        return formValid;
    }

    const scanFingerPrintData = async () => {
        // console.log("Cash Withdrawal----------------------")
        await axios({
            method: 'CAPTURE',
            url: props.deviceConfig.captureUrl,
            headers: { "Content-Type": "text/xml; charset=UTF-8" },
            data: props.deviceConfig.xmlData
        }).then(response => {
            const jsonData = xmlJs.xml2json(response.data, { compact: true, spaces: 4 });
            let XMLjson = JSON.parse(jsonData);

            if (XMLjson && XMLjson.PidData.Resp._attributes.errCode !== "700") {
                setFingerprintXmlData(response.data)
            } else {
                setFingerprintXmlData(null)
            }
        }).catch(error => {
            console.log("error -----------", error)
        })
    }

    const scanFinger = () => {
        if (FormValidate()) {
            setAuthAePSModel(true)
            // scanFingerPrintData()
        }
    }

    const handleSubmit = () => {
        if (isConsent === 'N') {
            toast.error("Please aadhaar accept consent declaration");
            return false;
        } else if (FormValidate()) {
            setLoading(true)
            const payload = {
                bankiin: getDataField.bankiin,
                mobile: getDataField.mobile,
                amount: getDataField.amount,
                biometricData: biometricData,
                aadhaar: getDataField.aadhaar,
                latitude: latitude,
                longitude: longitude,
                isXml: true,
                consent: isConsent
            }

            postRequest(POST_AEPS_CASH_WITHDRAWAL, payload, history).then((response) => {
                if (response.success === true) {
                    // let record = response.data;
                    setStatus(1)
                    setStatusMessage(response.message);
                    toast.success(response.message);
                } else {
                    setStatus(0)
                    setStatusMessage(response.message);
                    toast.error(response.message);
                }
                setTimeout(() => {
                    clearFormField();
                }, 15000);
                setTimeout(() => {
                    setAuthAePSModel(false);
                    setFingerprintXmlData(null);
                    setBiometricData(null);
                    // setPartnerScan(false);
                    setLoading(false);
                    setSubmit(true);
                }, 1000);
            })
        }
    } // eslint-disable-line react-hooks/exhaustive-deps

    // const aepsPartner2FA = (data) => {
    //     const payload = {
    //         type: "TXN_AUTH",
    //         bankiin: getDataField && getDataField.bankiin,
    //         latitude: latitude,
    //         longitude: longitude,
    //         isXml: true,
    //         biometricData: data,
    //     }

    //     postRequest(POST_AEPS_LOGIN, payload, history).then((response) => {
    //         if (response.success === true) {
    //             // console.log("------response--------------", response)
    //             setAuthAePSModel(false)
    //             setPartnerScan(true)
    //             // setAuthAePSTitle("Customer Biometric for AePS Cash WithDrawal")
    //             setFingerprintXmlData(null)
    //             setBiometricData(null)
    //             toast.success(response.message);
    //             setTimeout(() => {
    //                 scanFinger()
    //             }, 3000);
    //         } else {
    //             setAuthAePSModel(false);
    //             setPartnerScan(false);
    //             setFingerprintXmlData(null);
    //             setBiometricData(null);
    //             // console.log("------response--------------", response)
    //             // setAuthAePSModel(true)
    //             toast.error(response.message);
    //         }
    //     })
    // }

    const onChangeChackBox = (e) => {
        if (e.target.checked === true) {
            if (FormValidate()) {
                if (!handleAmountChange(isAmount)) {
                    setConsent('N')
                } else {
                    setConsent('Y')
                }
            }
        } else {
            setConsent('N')
        }
    };

    const handleCloseModal = () => {
        setAuthAePSModel(false)
    }
    const fetchLocation = async () => {
        let data = await getGeoLocation();
        setLatitude(data.latitude);
        setLongitude(data.longitude);
    }
    useEffect(() => {
        if (fingerprintXmlData) {
            // console.log("fingerprint ---", fingerprintXmlData)
            // let data = biometricDataToJson(fingerprintXmlData)
            setBiometricData(fingerprintXmlData)
        }
        // console.log("---------1 ---")
    }, [fingerprintXmlData]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setAuthAePSTitle("Customer Biometric for AePS Cash WithDrawal")
        fetchLocation();
        // console.log("---------2 ---")
        return () => {
            setSubmit(false)
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        // console.log("---------3 ---")
        if (props.getBankList && props.getBankList.length > 0) {
            setBankList(props.getBankList);
        }
        // setBankList(bankData.map(item => {
        //     return { "value": item.iin, "label": item.name };
        // }));
    }, [props.getBankList])  // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (biometricData) {
            handleSubmit();
            // if (isPartnerScan) {
            //     handleSubmit();
            // } else {
            //     aepsPartner2FA(biometricData);
            // }
        }
    }, [biometricData]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        // console.log("---------5 ---")
        if (props.existData) {
            forceUpdate();
            setDataField(props.existData)
        }
    }, [props.existData])  // eslint-disable-line react-hooks/exhaustive-deps

    if (isLoading) {
        return <div className="content text-center" style={{ margin: "250px 0" }}>
            <PropagateLoader color="#5851D2" loading={isLoading} size={25} />
        </div>
    }

    return (
        <div>
            <div className="row">
                <div className="col-md-12">
                    <div className='card rounded-0 card-body'>{isSubmit ? <><div className='text-right'>
                        <Button className='bg bg-primary mr-3' onClick={() => clearFormField()}>New Transaction</Button>
                        <Button className='bg bg-info' onClick={() => handleRetry()}>Retry</Button>
                    </div><AfterSubmit status={status} message={statusMessage} />
                    </> :
                        <div className='row'>
                            <div className='col-sm-6 col-md-4 col-lg-4'>
                                <div className="form-group">
                                <div className="input-group">
                                            <label>Bank Name</label>
                                            <Select showSearch status={`${getDataError && getDataError.bankiin ? "in-valid" : ""}`} options={getBankList} className={`normal-light w-100`} classNamePrefix="select" name='bankiin' onChange={(e) => onChangeValueSelect(e)} placeholder="-Select Bank-" ref={bankSelect} />
                                            {getDataError && getDataError.bankiin && <span className="error text-danger">The field is required</span>}
                                        </div>
                                </div>
                            </div>
                            <div className='col-sm-6 col-md-4 col-lg-4'>
                                <div className="form-group">
                                    <label>Aadhaar Number</label>
                                    <input type="text" className={`form-control normal-light ${getDataError && getDataError.aadhaar ? "is-invalid" : ""}`} name="aadhaar" maxLength={12} value={getDataField && getDataField.aadhaar} onKeyDown={isNumber} onChange={(e) => onChangeValue(e)} ref={aadharInput} />
                                    {getDataError && getDataError.aadhaar && <span className="error text-danger">The field is required</span>}
                                </div>
                            </div>
                            <div className='col-sm-6 col-md-4 col-lg-4'>
                                <div className="form-group">
                                    <label>Mobile Number</label>
                                    <input type="text" className={`form-control normal-light ${getDataError && getDataError.mobile ? "is-invalid" : ""}`} name="mobile" value={getDataField && getDataField.mobile} onKeyDown={isNumber} onChange={(e) => onChangeValue(e)} maxLength={10} ref={mobileInput} />{getDataError && getDataError.mobile && <span className="error text-danger">The field is required</span>}
                                </div>
                            </div>
                            <div className='col-sm-6 col-md-4 col-lg-4'>
                                <div className="form-group">
                                    <label>Amount</label>
                                    <input type="text" className={`form-control normal-light ${getDataError && getDataError.amount ? "is-invalid" : ""}`} name="amount" value={getDataField && getDataField.amount} onKeyDown={isNumber} maxLength={5} onChange={(e) => onChangeValue(e)} ref={amountInput} />{getDataError && getDataError.amount && <span className="error text-danger">The field is required</span>}
                                    {getDataError && getDataError.validamount && <span className="error text-danger">Please enter an amount that is a multiple of Rs. 100 and Rs. 50.</span>}
                                </div>
                            </div>
                            <div className='col-sm-12 col-md-12 col-lg-12'>
                                <div className='form-group'>
                                    <Checkbox className='d-flex' checked={isConsent === "Y" ? true : false} onChange={onChangeChackBox}>I hereby give my voluntary consent to SR PAY Private Limited, use my Aadhaar/ VID number & biometric to authenticate from UIDAI for the purpose of AEPS activation.</Checkbox>
                                </div>
                            </div>
                            <div className='col-sm-12 col-md-12 col-lg-12'>
                                <div className='col-sm-4 col-md-4 col-lg-4'>
                                    <BioDevice />
                                </div>
                            </div>
                            <div className='col-sm-6 col-md-4 col-lg-4'>
                                <label></label>
                                <div className='col-md-12 d-flex'>
                                    <div className="form-group  pull-right text-lg-right mr-4">
                                        <button type="submit" className="btn btn-default rounded-pill pl-4 pr-4" onClick={() => clearFormField()} >Cancel</button>
                                    </div>
                                    <div className="form-group text-lg-right">
                                        <button type="submit" className="btn btn-primary rounded-pill pl-4 pr-4" onClick={() => scanFinger()} disabled={isConsent === 'N' ? true : false} ><i class="fas fa-fingerprint"></i> Scan Finger</button>
                                    </div>
                                </div>
                            </div>
                        </div>}

                        {isAuthAePSModel && <Modal title={isAuthAePSTitle} width={600} open={isAuthAePSModel} onCancel={() => handleCloseModal()} footer={false} >
                            <>
                                <div className='text-center h-100'>
                                    <img src={'./../fingerprint-svgrepo-com.svg'} alt='' width={'175px'} />
                                </div>
                                <div className='col-sm-12 col-md-12 col-lg-12 mb-120'>
                                    <div className='form-group'>
                                        <Checkbox className='d-flex' checked={isConsent} onChange={onChangeChackBox}>I hereby give my voluntary consent to SR PAY Private Limited, use my Aadhaar/ VID number & biometric to authenticate from UIDAI for the purpose of AEPS transaction.</Checkbox>
                                    </div>
                                </div>
                                <BioDevice />
                                <button className='btn btn-primary btn-lg bg-primary btn-block' onClick={() => scanFingerPrintData()}>Scan Finger</button>
                            </>
                        </Modal>}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Cashwithdrawal;