
import Commingsoon from '../../container/commingsoon';

export default Commingsoon;
// import React, { useEffect, useState, useRef } from 'react';
// // import { APP_KEY, API_URL } from './../config';
// import { getUser, isNumber, getTransactionId } from './../../Utils/Common';

// import { SAVE_MOVE_TO_DISTRIBUTOR, GET_PARENT_PROFILE } from './../../Utils/appConstants';
// import { getRequest, postRequest } from './../../Utils/AppApi';
// // import AppHeader from './../Header/Header';
// import { useHistory } from "react-router-dom";
// import { ToastContainer, toast } from 'react-toastify';
// import AfterSave from './../../container/afterpayment';
// // import UpiComponent from './upi';
// import moment from 'moment';
// const user = getUser();
// function SettleToWallet() {
//     // console.log("user------", user.data)
//     const history = useHistory();
//     const eligibleLimit = user.data.capitalAmount ? user.data.capitalAmount : 0;
//     const [getDataField, setDataField] = useState([]); // eslint-disable-line react-hooks/exhaustive-deps
//     const [getDataError, setDataError] = useState({}); // eslint-disable-line react-hooks/exhaustive-deps
//     const [partnerId, setPartnerId] = useState("");
//     const [partnerName, setPartnerName] = useState("");
//     const [accountNumber, setAccountNumber] = useState("");
//     const [myWalletBalance, setWalletBalance] = useState(0);
//     const [InEligibleLimit, setEligibleLimit] = useState(0);
//     const [ifscCode, setIfscCode] = useState("");
//     const [isSubmit, setSubmit] = useState(false);

//     // const [tabName, setTabName] = useState("Bank");

//     const amountInput = useRef(null);

//     const clearFormField = () => {
//         amountInput.current.value = "";
//     }

//     const onChangeValue = (event) => {
//         const { name, value } = event.target;
//         setDataField({ ...getDataField, [name]: value });
//     }

//     const FormValidate = () => {
//         let formValid = true;
//         let formObject = {};
//         setDataError({})
//         if (getDataField && getDataField.length === 0) {
//             formValid = false;
//         }

//         let array = ["amount"];

//         array.forEach(element => {
//             if (getDataField[element] === "" || typeof getDataField[element] === "undefined") {
//                 formObject[element] = true;
//                 formValid = false;
//             }
//         });

//         setDataError(formObject);
//         return formValid;
//     }

//     const handleSubmit = () => {
//         if (FormValidate()) {
//             const payload = {
//                 transactionId: getDataField.transactionId,
//                 outstandingBalance: ifscCode,
//                 amount: getDataField.amount,
//                 dateTime: moment(new Date()).format("YYYY-MM-DD HH:mm"), //.toLocaleString(),
//                 // remark: getDataField.remark,
//             }

//             postRequest(SAVE_MOVE_TO_DISTRIBUTOR, payload, history).then((response) => {
//                 if (response.success === true) {
//                     setDataField([]);
//                     setDataError({});
//                     clearFormField();
//                     setSubmit(true)
//                     toast.success(response.message);
//                 } else {
//                     toast.error(response.message);
//                 }
//             }).catch(function (error) {
//                 // console.log(error)
//             })
//         }
//     } // eslint-disable-line react-hooks/exhaustive-deps

//     const getParentProfile = () => {
//         getRequest(`${GET_PARENT_PROFILE}`, history).then((response) => {
//             if (response.success === true) {
//                 setWalletBalance(response.data.walletdetail.balance);
//                 setEligibleLimit(response.data.walletdetail.balance - eligibleLimit);
//             } else {
//                 setWalletBalance(0);
//                 setEligibleLimit(0);
//             }
//         }).catch(function (error) {
//             console.log(error)
//         })
//     } // eslint-disable-line react-hooks/exhaustive-deps

//     useEffect(() => {
//         getParentProfile();
//         setAccountNumber(user.data.userDetails.accountNumber);
//         setIfscCode(user.data.userDetails.ifscCode);
//         setPartnerId(user.data.username);
//         setPartnerName(user.data.name);
//         setDataField({ ...getDataField, transactionId: getTransactionId });
//     }, []) // eslint-disable-line react-hooks/exhaustive-deps

//     // const onchangeTab = (index, value) => {
//     //     // setTabName(value.props.title);
//     // }
//     return (
//         <div>
//             {isSubmit ? <AfterSave status={1} message={"Money send successful"} /> :
//                 <div className="content-wrapper pt-2">
//                     <div className="content-header my-0 py-0">
//                         <div className="container-fluid">
//                             <div className="row mb-0 align-items-center">
//                                 <div className="col-sm-6">
//                                     <h1 className='page-title text-blue font-merriweather'>Settle To <span className='text-red'>Wallet</span></h1>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                     <div className="content">
//                         <div className="container-fluid pt-2 pb-5">
//                             <div className="row">
//                                 <div className="col-md-12">
//                                     <div className='card rounded-0 card-body'>
//                                         <div className='row'>
//                                             <div className='col-sm-6 col-md-4 col-lg-4'>
//                                                 <div className="form-group">
//                                                     <label>Transaction ID</label>
//                                                     <input type="text" className={`form-control normal-light pr-110`} name="transactionId" value={getDataField && getDataField.transactionId} readOnly />
//                                                 </div>
//                                             </div>
//                                             <div className='col-sm-6 col-md-4 col-lg-4'>
//                                                 <div className="form-group">
//                                                     <label>Partner ID</label>
//                                                     <input type="text" className={`form-control normal-light pr-110`} name="partnerId" value={partnerId} readOnly />
//                                                 </div>
//                                             </div>
//                                             <div className='col-sm-6 col-md-4 col-lg-4'>
//                                                 <div className="form-group">
//                                                     <label>Name</label>
//                                                     <input type="text" className={`form-control normal-light pr-110`} name="partnerName" value={partnerName} readOnly={true} />
//                                                 </div>
//                                             </div>
//                                             <div className='col-sm-6 col-md-4 col-lg-4'>
//                                                 <div className="form-group">
//                                                     <label>Account Number</label>
//                                                     <input type="text" className={`form-control normal-light pr-110`} name="accountNumber" value={accountNumber} readOnly />
//                                                 </div>
//                                             </div>
//                                             <div className='col-sm-6 col-md-4 col-lg-4'>
//                                                 <div className="form-group">
//                                                     <label>Registered Wallet ID</label>
//                                                     <input type="text" className={`form-control normal-light pr-110`} name="ifscCode" value={ifscCode} readOnly />
//                                                 </div>
//                                             </div>
//                                             <div className='col-sm-6 col-md-4 col-lg-4'>
//                                                 <div className="form-group">
//                                                     <label>Wallet Balance</label>
//                                                     <input type="text" className={`form-control normal-light pr-110`} name="walletbalance" value={myWalletBalance} readOnly />
//                                                 </div>
//                                             </div>
//                                             <div className='col-sm-6 col-md-4 col-lg-4'>
//                                                 <div className="form-group">
//                                                     <label>Eligible Limit</label>
//                                                     <input type="text" className={`form-control normal-light pr-110`} name="eligibleLimit" value={InEligibleLimit} readOnly />
//                                                 </div>
//                                             </div>
//                                             <div className='col-sm-6 col-md-4 col-lg-4'>
//                                                 <div className="form-group">
//                                                     <label>Amount</label>
//                                                     <input type="text" className={`form-control normal-light pr-110 ${getDataError && getDataError.amount ? "is-invalid" : ""}`} name="amount" ref={amountInput} maxLength={10} onChange={(e) => onChangeValue(e)} placeholder="Amount" onKeyDown={isNumber} value={getDataField && getDataField.amount} />
//                                                     {getDataError && getDataError.amount && <span className="error text-danger">The field is required</span>}
//                                                 </div>
//                                             </div>
//                                             <div className='col-sm-6 col-md-4 col-lg-4'>
//                                                 <label></label>
//                                                 <div className='col-md-12 d-flex'>
//                                                     <div className="form-group  pull-right text-lg-right mr-4">
//                                                         <button type="submit" className="btn btn-default rounded-pill pl-4 pr-4" onClick={() => clearFormField()} >Cancel</button>
//                                                     </div>
//                                                     <div className="form-group text-lg-right">
//                                                         <button type="submit" className="btn btn-primary rounded-pill pl-4 pr-4" onClick={() => handleSubmit()}>Process</button>
//                                                     </div>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>

//                         </div>
//                     </div>
//                     <ToastContainer position="top-center" autoClose={5000} limit={2} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="colored" />
//                 </div>
//             }
//         </div>
//     )
// }

// export default SettleToWallet