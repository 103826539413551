import React, { useEffect, useState } from 'react';
// import CsvDownloader from 'react-csv-downloader';
import { Modal } from 'antd';
// import { CSVLink } from 'react-csv';
import DataTable from 'react-data-table-component';
import 'antd/dist/antd.css';
import { useHistory } from "react-router-dom";
import { ADMIN_AADHAR_PENDING_KYC_USERS, AADHARCARD_VERIFY, AADHARCARD_OTP_VERIFY } from '../../../Utils/appConstants';
import { getRequest, postRequest } from '../../../Utils/AppApi';
import { ToastContainer, toast } from 'react-toastify';
import { kycStatusValue, getUser } from '../../../Utils/Common';
import { getUTCToLocalDateFormat } from '../../../Utils/function';

function App(props) {
    // console.log("props -------",props.search)
    const user = getUser();
    const history = useHistory();
    // const csvLink = useRef()
    const limit = 10;
    const search = props.search ? props.search : "";
    const kycStatus = props.kycStatus ? props.kycStatus : "";

    const [getData, setData] = useState([]);
    const [getTotalRecord, setTotalRecord] = useState(0);
    const [page, setPage] = useState(1);
    // const [isModalUserDetail, setIsModalUserDetail] = useState(false);
    // const [isModalUserApprovedReject, setIsModalUserApprovedReject] = useState(false);
    const [isSelectedUser, setIsSelectedUser] = useState([]);
    // const [isSelectedUserAadharDetail, setIsSelectedUserAadharDetail] = useState({});
    // const [imageCropperModal, setImageCropperModal] = useState(false);
    
    const [isAadharCardVerify, setIsAadharCardVerify] = useState(false);
    const [aadharNumber, setAadharNumber] = useState(null);
    const [isOtp, setIsOtp] = useState(false);
    const [isOtpNumber, setIsOtpNumber] = useState(null);
    const [isRefId, setIsRefId] = useState(null);

    const columns = [{
        name: 'User Id',
        selector: row => row.user.username,
    }, {
        name: 'Pan No.',
        selector: row => row.pancardNumber,
    }, {
        name: 'Name',
        selector: row => row.user.name,
    }, {
        name: 'Mobile',
        selector: row => row.mobile,
    }, {
        name: 'User Type',
        selector: row => row.role.name,
    }, {
        name: 'Parent ID',
        selector: row => <>{row.userParentDetail && row.userParentDetail.username}</>,
    }, {
        name: 'KYC Status',
        cell: row => <>{kycStatusValue(row.user.KYCStatus)}</>,
    }, {
        name: 'Active',
        cell: row => <>{(row.user.active) ? <div className="text-success">Active</div> : <div className="text-danger">Deactive</div>}</>,
    }, {
        name: 'Date',
        cell: row => getUTCToLocalDateFormat(row.createdAt),
    }, {
        name: 'Action',
        cell: row => <>{!row.aadharNumberVerify && <div className='icon-border border border-danger text-danger pointer' onClick={(event) => handleAadhaar(event, row)}><i class="fa fa-info-circle" aria-hidden="true"></i></div>}</>,
    }]

    const handleAadhaar = (event, data) => {
        setIsAadharCardVerify(true);
        setIsSelectedUser(data)
        setAadharNumber(data.aadharNumber);
    }

    const handleAadharCardOtpVerify = () => {
        if (isOtp) {
            const payload = { userKycId: isSelectedUser.userId, aadharcard: aadharNumber, aadharcardOtp: isOtpNumber, ref_id: isRefId };
            postRequest(`${AADHARCARD_VERIFY}`, payload, history).then((response) => {
                if (response.success === true || response.alreadysuccess === true) {
                    setIsOtp(false)
                    setIsAadharCardVerify(false)
                    toast.success(response.message);
                } else {
                    if (!response.aadharNumber || !response.alreadysuccess) {
                        toast.error(response.message);
                    } else {
                        setIsOtp(false);
                        toast.error(response.message);
                    }
                }
            }).catch(function (error) {
                setIsAadharCardVerify(false)
            })
        } else {
            const payload = { userKycId: isSelectedUser.userId, aadharcard: aadharNumber };
            postRequest(`${AADHARCARD_OTP_VERIFY}`, payload, history).then((response) => {
                if (response.alreadysuccess === true) {
                    setIsOtp(false)
                    setIsAadharCardVerify(false)
                    user.data.userDetails.aadharNumberVerify = true;
                    //   updateUserSession(user)
                    toast.success(response.message);
                } else if (response.success === true) {
                    setIsOtp(true);
                    setIsRefId(response.data.ref_id);
                    toast.success(response.message);
                } else {
                    toast.error(response.message);
                }
            }).catch(function (error) {
                // console.log(error)
                // setLoading(false);
            })
        }
    }


    const objectToQueryString = (obj) => {
        var str = [];
        for (var p in obj)
            if (obj.hasOwnProperty(p)) {
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
        return str.join("&");
    }

    const params = { page: page, limit: limit };

    const getDataReports = () => {
        if (kycStatus) {
            params.isStatus = kycStatus;
        }

        if (search) {
            params.search = search ? search : "";
        }

        const queryString = objectToQueryString(params);
        getRequest(`${ADMIN_AADHAR_PENDING_KYC_USERS}?${queryString}`, history).then((response) => {
            if (response.success === true) {
                setData(response.data);
                setTotalRecord(response.data.totalDocs);
                setPage(response.data.page);
            } else {
                setData([]);
                setTotalRecord(0);
                setPage(1);
            }
        }).catch(function (error) {
            // console.log(error)
            // setLoading(false);
        })
    }

    const handlePageChange = page => {
        setPage(page);
    };

    useEffect(() => {
        if (props.handleSearch) {
            getDataReports();
        }
        if(!props.handleSearch) {
            setData([])
        }
    }, [page, props.handleSearch]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        getDataReports();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            <div className="container-fluid pt-2 pb-5">
                <div className="col-md-12">
                    <div className="box-body">
                        <DataTable
                            title={""}
                            columns={columns}
                            data={getData.docs}
                            highlightOnHover
                            pagination
                            paginationServer
                            paginationTotalRows={getTotalRecord}
                            onChangeRowsPerPage={getDataReports}
                            onChangePage={handlePageChange}
                        />
                    </div>
                </div>
            </div>
            <ToastContainer position="top-center" autoClose={5000} limit={2} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="colored" />
            {isAadharCardVerify && (<Modal
                title="Aadhar Card Verification"
                centered
                open={isAadharCardVerify}
                onCancel={() => setIsAadharCardVerify(false)}
                onOk={() => handleAadharCardOtpVerify()}
            >
                <ul className='mb-3'>
                    <li><span>User ID</span> : {isSelectedUser.user.username}</li>
                    <li><span>Name</span> : {isSelectedUser.user.name}</li>
                    <li><span>Pancard Number</span> : {isSelectedUser.pancardNumber}</li>
                    <li><span>Pancard Name</span> : {isSelectedUser.pancardNumberVerifiedDetail.registered_name}</li>
                    {/* <li><span>Date Of Birth</span> : {isSelectedUserAadharDetail && isSelectedUserAadharDetail.dob }</li> */}
                </ul>
                <div className="input-group">
                    <div className="custom-file">
                        <input type="text" className="form-control" name="aadharNumber" placeholder="Aadharcard Number" defaultValue={isSelectedUser && isSelectedUser.aadharNumber} maxLength={12} readOnly={((isSelectedUser && isSelectedUser.aadharNumber) || (isOtp)) ? true : false} onChange={(e) => setAadharNumber(e.target.value)} />
                    </div>
                </div>
                <hr />
                {isOtp &&
                    <div className="input-group">
                        <div className="custom-file">
                            <input type="text" className="form-control" name="otpNumber" placeholder="OTP Number" defaultValue={""} maxLength={6} onChange={(e) => setIsOtpNumber(e.target.value)} />
                        </div>
                    </div>
                }
            </Modal>)}
        </div>
    )
}

export default App