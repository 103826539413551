import React, { useEffect, useState } from 'react';
import { Button, Modal, Tooltip } from 'antd';
import PropagateLoader from "react-spinners/PropagateLoader";
import DataTable from 'react-data-table-component';
import 'antd/dist/antd.css';
import { useHistory } from "react-router-dom";
import { POST_PAYOUT_GET_BENEFICIARY, POST_PAYOUT_ADD_BENEFICIARY, POST_PAYOUT_UPDATE_BENEFICIARY_DETAIL, GET_DMT_NEW_BANK_LIST, POST_PAYOUT_UPDATE_STATUS_BENEFICIARY, GET_CUSTOMER_BANK_NAME_FROM_ACCOUNT_NUMBER, IFSC_CODE_VERIFY, CREATE_USER_AADHARCARD_OTP_VERIFY, AADHAAR_CARD_SEND_OTP_CODE, POST_PAYOUT_INITIATED_TRANSACTION, GET_NAME } from '../../Utils/appConstants';
import { getRequest, postRequest } from '../../Utils/AppApi';
import { ToastContainer, toast } from 'react-toastify';
import { isNumber } from '../../Utils/Common';
import { refId, isIfscCodeValid } from './../../Utils/function';
import PayoutInvoice from './../../container/payoutInvoice';
import Select from 'react-select';

function BaneficiaryList(props) {
    // console.log("props -------",props.search)
    // const user = getUser();
    const history = useHistory();
    // const csvLink = useRef()
    // const [getNameButtonDisable, setGetNameButtonDisable] = useState(false);
    const limit = 10;
    const [getData, setData] = useState([]);
    const [isLimit, setLimit] = useState(limit)
    const [page, setPage] = useState(1);
    const [saveCustomerMobile, setCustomerMobile] = useState(props.customerData && props.customerData.customerMobile);
    const [customerData, setCustomerData] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState(null);
    const [getDataField, setDataField] = useState({});
    const [timer, setTimer] = useState(null);
    const [latitude, setLatitude] = useState("");
    const [longitude, setLongitude] = useState("");
    const [bankId, setBankId] = useState(null);
    const [isAddBaneModal, setAddBaneModal] = useState(false);
    const [isSelectedUser, setIsSelectedUser] = useState({});
    const [isSendMoneyModal, setSendMoneyModal] = useState(false);
    const [isSendButtonDisable, setSendButtonDisable] = useState(true);

    const [invoiceDataModal, setInvoiceDataModal] = useState(false);
    const [invoiceData, setInvoiceData] = useState(null);

    const [sendMoneyAmount, setSendMoneyAmount] = useState([]);
    const [getBeneData, setBeneData] = useState({});
    const [getBeneError, setBeneError] = useState({});
    const [customerAadharNumber, setCustomerAadharNumber] = useState(props.customerData.aadharNumber);
    const [getEKOBankListData, setEKOBankListData] = useState([]);
    const [getBankListData, setBankListData] = useState([{ "value": '', "label": '-- Select Bank --' }]);

    const [isOtpModal, setOtpModal] = useState(false);
    const [isOtp, setIsOtp] = useState(false);
    const [isOtpNumber, setIsOtpNumber] = useState(null);
    const [isRefId, setIsRefId] = useState(null);

    const [isIfscEnable, setIfscEnable]= useState(false);
    // const [isCustomerVerifyModal, setCustomerVerifyModal] = useState(false);
    // const [aadharData, setAadharData] = useState("");
    const [selectedOption, setSelectedOption] = useState(null);
    const columns = [{
        name: 'Nick Name',
        selector: row => <><div>{row.beneName}</div>{row.beneMobile && <div><strong>Mobile</strong>: {row.beneMobile}</div>}</>,
        width: "200px",
    }, {
        name: 'IFSC / Bank',
        selector: row => <><div><strong>IFSC</strong>: {row.ifscCode}</div>{row.bankName && <div><strong>Bank</strong>: {row.bankName && row.bankName}</div>}</>,
        width: "240px",
    }, {
        name: 'Account',
        selector: row => row.accountNumber,
        width: "170px",
    }, {
        name: 'Bene Name',
        selector: row => <><Tooltip placement="bottom" title={row.beneBankName}>{row.beneBankName}</Tooltip></>,
        width: "180px",
    }, {
        name: 'Verified',
        selector: row => (row.isVerified) ? 'Verified' : <button onClick={() => getName(row)} className={`pointer text-success`} disabled={row.isActive ? false : true} >{GET_NAME}</button>,
        width: "140px",
    }, {
        name: 'Status',
        selector: row => (row.isActive) ? <button onClick={() => beneStatus(row, false)} className={`pointer text-danger`} ><i class="fas fa-user-times"></i></button> : <button onClick={() => beneStatus(row, true)} className={`pointer text-success`} ><i class="fas fa-light fa-user-check"></i></button>,
        width: "80px",
    }, {
        name: 'Amount',
        selector: (row, index) => <><input type='text' name="amount" className="form-control" onChange={(e) => handleEditRowAmount(e, index)} placeholder='Amount' onKeyDown={isNumber} maxLength={6} disabled={row.isActive && row.isVerified ? false : true} /></>,
        width: "130px",
    }, {
        name: 'Action',
        selector: (row) => <><Button className={"bg-primary"} onClick={() => sendMoney(row)} disabled={row.isActive ? isSendButtonDisable : true} small >Send</Button></>,
        width: "150px",
        fixed: "right",
    }];


    useEffect(() => {
        // console.log("props.customerData.customerMobile ---" ,props.customerData)
        setCustomerMobile(props.customerData && props.customerData.customerMobile);
        setCustomerAadharNumber(props.customerData && props.customerData.aadharNumber);
        setCustomerData(props.customerData);
    }, [props.customerData]) // eslint-disable-line react-hooks/exhaustive-deps

    const beneStatus = (data, status) => {
        let params = {}
        params.id = data._id;
        params.status = status;
        postRequest(POST_PAYOUT_UPDATE_STATUS_BENEFICIARY, params, history).then((response) => {
            if (response.success === true) {
                getDataReports();
            }
            toast.success(response.message, {
                toastId: 'success1',
            });
        }).catch(function (error) {
            console.log(error);
        })
    }

    const updateBeneData = (data) => {
        let params = {}
        params.id = data._id;
        params.beneBankName = data.beneBankName;
        postRequest(POST_PAYOUT_UPDATE_BENEFICIARY_DETAIL, params, history).then((response) => {
            if (response.success === true) {
                getDataReports();
            }
        }).catch(function (error) {
            console.log(error);
        })
    }

    const getBankList = () => {
        getRequest(GET_DMT_NEW_BANK_LIST, history).then((response) => {
            if (response.success === true) {
                let bankDataRecord
                if(response.data) {
                    bankDataRecord = response.data.filter((item) => item.key === "DMT" );
                }
                setEKOBankListData(bankDataRecord);
                setBankListData([...getBankListData, ...bankDataRecord.map((item) => {
                    return { "value": item.BankID, "label": item.BANK_NAME };
                })]);
            }
        }).catch(function (error) {
            console.log(error);
        })
    }

    const onChangeBankIfscCode = (value) => {
        console.log('value -----', value)
        if (value) {
            let findBank = getEKOBankListData.filter(item => item.BankID === value);
            if (findBank.length > 0) {
                // console.log('findBank -----', findBank)
                setSelectedOption({ "value": findBank[0].BankID, "label": findBank[0].BANK_NAME });
                if(findBank[0].ifsc) {
                    onChangeIfscCode(findBank[0].ifsc);
                    setBeneData({ ...getBeneData, bankName: findBank[0].BANK_NAME, ifscCode: findBank[0].ifsc });
                    let obj = {}
                    obj.target = { name: "ifscCode", value: findBank[0].ifsc}
                    setIfscEnable(true)
                } else {
                    setBeneData({ ...getBeneData, ifscCode: "" });
                    setIfscEnable(false)
                }
                // console.log('findBank[0].BankID -----', findBank[0].BankID)
                setBankId(findBank[0].BankID);
            } else {
                setBankId(null);
            }
        } else {
            setBankId(null);
            setIfscEnable(false)
            setBeneData({ ...getBeneData, ifscCode: value });
        }
    }

    const handleEditRowAmount = (event, index) => {
        let array = getData; //[...getData];
        array.docs[index].amount = event.target.value;
        // if (event.target.value > 25000) {
        //     toast.error('Maximum amount is: 25000',{
        //         toastId: 'error1',
        //     });
        //     event.preventDefault();
        //     return false;
        // } else {
        if (event.target.value >= 0) {
            setSendButtonDisable(false)
        } else {
            setSendButtonDisable(true)
        }
        //     setData(array);
        // }
    }

    const handleAmount = (event) => {
        setSendMoneyAmount(event.target.value)
        // console.log("object ---------------", object)
    }

    const onChangeAddBene = (event) => {
        let { name, value } = event.target;
        setBeneData({ ...getBeneData, [name]: value });
    }

    const sendMoney = (row) => {
        if (!row.amount) {
            toast.error("Amount is empty");
            return false;
        }
        setSendMoneyModal(true)
        row.isChannel = 4;
        setIsSelectedUser(row)
    }

    // const handleSyncBeneficiary = () => {
    //     let payload = { customerMobile : saveCustomerMobile }
    //     const queryString = objectToQueryString(payload);
    //     getRequest(`${GET_SYNC_BENEFICIARY_LIST}?${queryString}`, payload, history).then((response) => {
    //         if (response.success === true) {
    //             getDataReports();
    //         }
    //     }).catch(function (error) {
    //         // console.log(error)
    //     })
    // }

    const handleSave = (data) => {
        if (sendMoneyAmount !== data.amount) {
            toast.error("Amount did not matched.")
        } else {
            const payload = {
                customerAutoId: customerData._id,
                customerName: customerData.customerName,
                beneAutoId: data._id,
                baneName: data.beneBankName,
                accountNumber: data.accountNumber,
                ifsccode: data.ifscCode,
                amount: sendMoneyAmount,
                channel: data.isChannel,
                latitude: latitude,
                longitude: longitude
            }
            handleSubmit(payload);
        }
    }

    const handleSubmit = (data) => {
        setLoading(true)
        postRequest(`${POST_PAYOUT_INITIATED_TRANSACTION}`, data, history).then((response) => {
            if (response.success === true) {
                toast.success(response.message);
                handleInvoice(response.data);
                // console.log("data -----------------------", response.data.transactionId);
                getDataReports();
                handleHideModal();
            } else {
                toast.error(response.message);
            }
            setLoading(false);
        }).catch(function (error) {
            // console.log(error)
            setLoading(false);
            handleHideModal();
        })
        // console.log("accept ----",value)
    }

    const handleInvoice = (data) => {
        // console.log("data 2-----------------------", data);
        setInvoiceData(data);
        setInvoiceDataModal(true);
    }

    const handleInvoiceModal = () => {
        setInvoiceDataModal(false);
    }

    const addBane = () => {
        setAddBaneModal(true);
        setBeneData({})
        setDataField({})
    }

    const FormBaneValidate = () => {
        let formValid = true;
        let formObject = {};
        setBeneError({})
        if (getBeneData && getBeneData.length === 0) {
            formValid = false;
        }
        let array = ["name", "ifscCode", "accountNumber"];
        array.forEach(element => {
            if (getBeneData[element] === "" || typeof getBeneData[element] === "undefined") {
                formObject[element] = true;
                formValid = false;
            }
        });

        if(!bankId) {
            formObject["bank"] = true;
            formValid = false;
        }

        setBeneError(formObject);
        return formValid;
    }

    const handleAadharCardOtpVerify = async () => {
        if (FormBaneValidate() === true) {
            setLoading(true)
            if (isOtp) {
                const payload = { aadharcard: customerAadharNumber, aadharcardOtp: isOtpNumber, ref_id: isRefId, isInstantCharge: true };
                postRequest(`${CREATE_USER_AADHARCARD_OTP_VERIFY}`, payload, history).then( async (response) => {
                    if (response.success === true || response.alreadysuccess === true) {
                        setIsOtp(false);
                        setOtpModal(false);
                        await addBaneSave();
                        toast.success(response.message);
                    } else {
                        setIsOtp(false);
                        setOtpModal(false);
                        toast.error(response.message);
                    }
                    setLoading(false)
                }).catch(function (error) {
                    // console.log(error)
                    setLoading(false)
                })
            } else {
                const payload = { aadharcard: customerAadharNumber, isInstantCharge: true };
                postRequest(`${AADHAAR_CARD_SEND_OTP_CODE}`, payload, history).then(async (response) => {
                    if (response.success === true && response.alreadysuccess === true) {
                        setIsOtp(false);
                        setOtpModal(false);
                        await addBaneSave();
                        toast.success(response.message);
                    } else if (response.success === true) {
                        setIsOtp(true);
                        setOtpModal(true)
                        setIsRefId(response.data.ref_id);
                        toast.success(response.message);
                    } else {
                        toast.error(response.message);
                    }
                    setLoading(false)
                }).catch(function (error) {
                    // console.log(error)
                    setLoading(false);
                })
            }
        }
    }

    const addBaneSave = async () => {
        // console.log("-------------- Save");
        // console.log("-------------- ", FormBaneValidate());
        if (FormBaneValidate() === true) {
            // console.log("SAVE-------------- getBeneData",getBeneData)
            const payload = {
                customerMobile: saveCustomerMobile,
                recipientMobile: getBeneData.mobile,
                bankId: bankId,
                bankName: getBeneData.bankName,
                accountNumber: getBeneData.accountNumber,
                ifscCode: getBeneData.ifscCode,
                recipientName: getBeneData.name,
                beneBankName: getBeneData.beneBankName,
                aadharNumber: customerAadharNumber
            };

            await postRequest(`${POST_PAYOUT_ADD_BENEFICIARY}`, payload, history).then((response) => {
                if (response.success === true) {
                    getDataReports();
                    setBeneData({});
                    setAddBaneModal(false);
                    // setCustomerVerifyModal(false);
                    toast.success(response.message);
                } else {
                    toast.error(response.message);
                }
                setLoading(false)
            }).catch(function (error) {
                // console.log(error)
                setLoading(false)
            })
        }
    }

    const handleHideModal = () => {
        setSendMoneyModal(false)
        setAddBaneModal(false)
    };

    const objectToQueryString = (obj) => {
        var str = [];
        for (var p in obj)
            if (obj.hasOwnProperty(p)) {
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
        return str.join("&");
    }

    const params = { page: page, limit: isLimit };

    const getDataReports = (data) => {
        if (data) {
            setLimit(data)
            params.limit = data
        }
        if (searchText) {
            params.search = searchText;
        }
        if (props.customerData && props.customerData.customerMobile) {
            params.customerMobile = props.customerData.customerMobile;
            setCustomerMobile(params.customerMobile)
        }
        const queryString = objectToQueryString(params);
        getRequest(`${POST_PAYOUT_GET_BENEFICIARY}?${queryString}`, history).then((response) => {
            if (response.success === true) {
                setData(response.data);
            } else {
                setPage(1);
            }
        }).catch(function (error) {
            console.log(error)
            // setLoading(false);
        })
    }

    const handlePageChange = page => {
        setPage(page);
    };

    const getName = (data) => {
        if (data.accountNumber && data.ifscCode) {
            // console.log("--- Valid", getBeneData)
            // setGetNameButtonDisable(true);
            setLoading(true);
            const payload = {
                "ifsccode": data.ifscCode,
                "accountNumber": data.accountNumber,
                "externalRef": refId(13),
                "consent": "Y",
                "pennyDrop": "AUTO",
                "latitude": latitude,
                "longitude": longitude
            };
            postRequest(`${GET_CUSTOMER_BANK_NAME_FROM_ACCOUNT_NUMBER}`, payload, history).then((response) => {
                if (response.success === true) {
                    // console.log("response.data-----------", response.data.data)
                    let record = response.data.data;
                    data.beneBankName = record.nameAtBank
                    updateBeneData(data);
                } else {
                    // console.log("response.data-----------", response)
                    toast.error(response.message);
                }
                setLoading(false);
            }).catch(function (error) {
                setLoading(false);
                console.log(error)
            });
        }
    }

    const getBankNameFromAccountNumber = () => {
        // console.log("--- Valid")
        // setGetNameButtonDisable(true);
        // setSeconds(60);
        if (getBeneData.accountNumber && getBeneData.ifscCode) {
            // setGetNameButtonDisable(true);
            setLoading(true);
            // console.log("--- Valid", getBeneData)
            const payload = {
                "ifsccode": getBeneData.ifscCode,
                "accountNumber": getBeneData.accountNumber,
                "externalRef": refId(13),
                "consent": "Y",
                "pennyDrop": "AUTO",
                "latitude": latitude,
                "longitude": longitude
            };
            postRequest(`${GET_CUSTOMER_BANK_NAME_FROM_ACCOUNT_NUMBER}`, payload, history).then((response) => {
                if (response.success === true) {
                    // console.log("response.data-----------", response.data.data)
                    // setGetNameButtonDisable(false);
                    let record = response.data.data;
                    let Object = { "beneBankName": record.nameAtBank }
                    setBeneData({ ...getBeneData, ...Object });
                } else {
                    // console.log("response.data-----------", response)
                    toast.error(response.message);
                }
                setLoading(false);
            }).catch(function (error) {
                console.log(error)
                setLoading(false);
            });
        } else {
            toast.error("Please enter valid account number")
            let Object = { "name": '' }
            setBeneData({ ...getBeneData, ...Object });
        }
    }

    const onChangeIfscCode = (val = null) => {
        let value;
        if(val) {
            value = val;
        } else {
            value = getBeneData && getBeneData.ifscCode;
        }
        if (isIfscCodeValid(value)) {
            // console.log("--- Valid", value)
            const payload = { "ifsccode": value };
            postRequest(`${IFSC_CODE_VERIFY}`, payload, history).then((response) => {
                if (response.success === true) {
                    // console.log("response.data-----------", response.data.data)
                    let record = response.data.data;
                    let Object = {
                        "ifscCode": record.ifsc,
                        "address": record.address,
                        "bankName": record.bank,
                        "branchAddress": record.branch,
                        "city": record.city,
                        "state": record.state,
                    }
                    setDataField(Object);
                } else {
                    // console.log("response.data-----------", response)
                }
            }).catch(function (error) {
                console.log(error)
            });
        } else {
            if (value && value.length === 11) {
                toast.error("Please enter valid ifsccode")
                let Object = {
                    "address": '',
                    "bankName": '',
                    "branchAddress": '',
                    "city": '',
                    "state": '',
                }
                setDataField(Object);
            }
        }
    }

    const handleSearch = (e) => {
        // console.log("searchText----",e.target.value)
        setSearchText(e.target.value);
    }

    // const onChangeChannel = ({ target: { value } }) => {
    //     // console.log("value-------------- value", value)
    //     let isSelectedUserValue = { ...isSelectedUser };
    //     isSelectedUserValue.isChannel = value;
    //     setIsSelectedUser(isSelectedUserValue);
    // };

    useEffect(() => {
        if (timer) {
            clearTimeout(timer)
            setTimer(null)
        }
        setTimer(
            setTimeout(() => {
                getDataReports()
            }, 100)
        )
    }, [searchText]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (props.customerData && props.customerData.customerMobile) {
            getDataReports();
        }
    }, [page, props.handleSearch]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (props.customerData && props.customerData.customerMobile) {
            // getDataReports();
        }
        getBankList();
        navigator.geolocation.getCurrentPosition((data) => {
            setLatitude(data.coords.latitude)
            setLongitude(data.coords.longitude)
        });
        // setIsDownloadCsv(isDownloadCsv ? true : false);
    }, [props.customerData]) // eslint-disable-line react-hooks/exhaustive-deps

    if (isLoading) {
        return <div className="content text-center" style={{ margin: "250px 0" }}>
            <PropagateLoader color="#5851D2" loading={isLoading} size={25} />
        </div>
    }

    return (
        <div>
            <div className="pt-2 pb-5">
                <div className="col-md-12">
                    <div className="row pb-3">
                        <div className='col-md-6 date-search'>
                            <div className='row'>
                                <div className="input-group">
                                    <input className="form-control py-2 border-right-0 border" type="search" defaultValue={searchText} value={searchText} onKeyDown={isNumber} placeholder='Search Account Number...' maxLength={20} onChange={(e) => handleSearch(e)} />
                                    <span className="input-group-append">
                                        <button className="btn btn-outline-secondary border-left-0 border" type="button" onClick={() => getDataReports()}>
                                            <i className="fa fa-search" />
                                        </button>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6 p-0'>
                            <div className='float-sm-right'>
                                {/* <button type="button" className="btn btn-primary mr-2" onClick={() => handleSyncBeneficiary()}>
                                    <i className="fa fa-users"></i> View Beneficiary
                                </button> */}
                                <button type="button" className="btn btn-primary mr-5" onClick={() => addBane()}>
                                    <i className="fa fa-user"></i> Add Beneficiary
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="box-body">
                        <DataTable
                            columns={columns}
                            data={getData.docs}
                            // progressPending={getData.length > 0 ? false : true}
                            highlightOnHover
                            pagination
                            paginationServer
                            paginationTotalRows={getData.totalDocs}
                            onChangeRowsPerPage={getDataReports}
                            onChangePage={handlePageChange}
                        />
                    </div>
                </div>
            </div>
            <ToastContainer position="top-center" autoClose={5000} limit={2} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="colored" />
            {isAddBaneModal && <Modal title={"Add Beneficiary"} width={400} open={isAddBaneModal} footer={false} closable={false} >
                <div className='row'>
                    <div className="form-group col-lg-12 col-md-12">
                        <label>Account Holder Mobile</label>
                        <div className="input-group">
                            <div className="custom-file">
                                <input type="text" className="form-control" name="mobile" placeholder="Account Holder Mobile" onChange={(e) => onChangeAddBene(e)} autoComplete="off" value={getBeneData && getBeneData.mobile} maxLength={10} onKeyDown={isNumber} />
                            </div>
                        </div>
                        {getBeneError && getBeneError.mobile && <span className="error text-danger">The field is required</span>}
                    </div>
                    <div className="form-group col-lg-12 col-md-12">
                        <label>Name</label>
                        <div className="input-group">
                            <div className="custom-file">
                                <input type="text" className="form-control" name="name" placeholder="Name" onChange={(e) => onChangeAddBene(e)} autoComplete="off" value={getBeneData && getBeneData.name} maxLength={20} />
                            </div>
                        </div>
                        {getBeneError && getBeneError.name && <span className="error text-danger">The field is required</span>}
                    </div>
                    <div className="form-group col-lg-12 col-md-12">
                        <label>Bank Name</label>
                        <div className="input-group">
                            <div className="custom-file">
                                <Select options={getBankListData} className={`normal-light w-100`} defaultValue={selectedOption} classNamePrefix="select" placeholder="Select Bank" name='mode' onChange={(e) => onChangeBankIfscCode(e.value)} />
                            </div>
                        </div>
                        {getBeneError && getBeneError.bank && <span className="error text-danger">The field is required</span>}
                    </div>
                    <div className="form-group col-lg-12 col-md-12">
                        <label>Global IFSC Code</label>
                        <div className="input-group">
                            <div className="custom-file">
                                <input type="text" className="form-control" name="ifscCode" placeholder="Enter IFSC Code" onChange={(e) => onChangeBankIfscCode(e.target.value)} autoComplete="off" readOnly={isIfscEnable} value={getBeneData && getBeneData.ifscCode} maxLength={11} />
                                <div className="inputgrouptext cursor-pointer text-success" onClick={() => onChangeIfscCode(getBeneData && getBeneData.ifscCode)} >Verify Branch</div>
                            </div>
                        </div>
                        {getBeneError && getBeneError.ifscCode && <span className="error text-danger">The field is required</span>}
                    </div>
                    {getDataField && getDataField.address && getDataField.bankName && getDataField.branchAddress && getDataField.city && getDataField.state && <div className="form-group col-lg-12 col-md-12">
                        <div className="custom-file">
                            <ul>
                                <li>Address: <strong>{getDataField.address}</strong></li>
                                <li>Bank Name: <strong>{getDataField.bankName}</strong></li>
                                <li>Branch Address: <strong>{getDataField.branchAddress}</strong></li>
                                <li>City: <strong>{getDataField.city}</strong></li>
                                <li>State: <strong>{getDataField.state}</strong></li>
                            </ul>
                        </div>
                    </div>}
                    <div className="form-group col-lg-12 col-md-12">
                        <label>Account Number</label>
                        <div className="input-group">
                            <div className="custom-file">
                                <input type="text" className="form-control" name="accountNumber" placeholder="Account Number" onChange={(e) => onChangeAddBene(e)} autoComplete="off" value={getBeneData && getBeneData.accountNumber} maxLength={20} />
                                <button className={`inputgrouptext cursor-pointer text-success`} onClick={() => getBankNameFromAccountNumber()} >{GET_NAME}</button>
                            </div>
                        </div>
                        {getBeneError && getBeneError.accountNumber && <span className="error text-danger">The field is required</span>}
                    </div>
                    {getBeneData.beneBankName && <div className="form-group col-lg-12 col-md-12">
                        <label>Bene Bank Name</label>
                        <div className="input-group">
                            <div className="custom-file">
                                <input type="text" className="form-control" readOnly autoComplete="off" value={getBeneData && getBeneData.beneBankName} maxLength={20} />
                            </div>
                        </div>
                    </div>}
                    <div className="form-group col-lg-12 col-md-12 text-right pt-4">
                        <Button onClick={() => handleHideModal()}>Cancel</Button>
                        <Button className="ml-2 bg-primary" onClick={() => handleAadharCardOtpVerify()}>Submit</Button>
                    </div>
                </div>
            </Modal>}

            {isSendMoneyModal && <Modal title={"Money Transfer Verification"} width={600} open={isSendMoneyModal} onOk={() => handleSave(isSelectedUser)} onCancel={handleHideModal} okText={"Continue"} cancelText={"Cancel"}>
                <div className='row'>
                    <div className='col-md-12 form-group'>
                        <div> Bank Name : <strong>{isSelectedUser && isSelectedUser.beneBankName}</strong></div>
                        <div> IFSC Code : <strong>{isSelectedUser.ifscCode}</strong></div>
                        <div> Account Number : <strong>{isSelectedUser && isSelectedUser.accountNumber}</strong></div>
                        <div> Customer Name : <strong>{isSelectedUser.beneName}</strong></div>
                    </div>
                    <div className='col-md-12 form-group'>
                        <label>Mode: <strong className='text-danger'>NEFT</strong></label>
                        {/* <br /> */}
                        {/* <Radio.Group defaultValue={5} buttonStyle="solid" onChange={onChangeChannel}
                            value={isSelectedUser.isChannel}
                            optionType="button" >
                            <Radio.Button value={4}> NEFT </Radio.Button>
                            <Radio.Button value={5}> IMPS </Radio.Button>
                        </Radio.Group> */}
                    </div>
                    <div className='col-md-12 form-group'>
                        <label>Confirm Amount: </label>
                        <input type='text' name="amount" className="form-group form-control" value={sendMoneyAmount} onChange={(e) => handleAmount(e)} onKeyDown={isNumber} maxLength={6} />
                    </div>
                </div>
            </Modal>}
            {invoiceDataModal && <Modal open={invoiceDataModal} width={800} footer={false} onCancel={handleInvoiceModal} closable={false}>
                <PayoutInvoice transactionId={invoiceData.transactionId} />
            </Modal>}
            {isOtpModal && (<Modal
                title="Please confirm Aadhaar OTP to Add Beneficiary"
                centered
                open={isOtpModal}
                onCancel={() => history.push({ pathname: "/dashboard" })}
                okText={"Submit"}
                onOk={() => { handleAadharCardOtpVerify() }}
            >
                <div className="input-group">
                    <div className="custom-file">
                        <input type="text" className="form-control" name="otpNumber" placeholder="OTP Number" defaultValue={""} maxLength={8} onChange={(e) => setIsOtpNumber(e.target.value)} />
                    </div>
                </div>
            </Modal>)}
        </div>
    )
}

export default BaneficiaryList