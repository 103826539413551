import React, { useEffect } from 'react';
// import "bootstrap/dist/css/bootstrap.css";
// import "react-bootstrap-carousel/dist/react-bootstrap-carousel.css";
// import moment from 'moment';
import './../../webcss/default.css';
import './../../webcss/styles.css';
import Header from './header';
import Footer from './footer';

function App() {

    useEffect(() => {
        // setReportMode(Apps);
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            <div>
                <Header />
                <div>
                    <div className="notice-section notice-section-sm border-top red">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-10 col-sm-10 col-12">
                                    <div className="mt-5">
                                        <h6 className="fontwhite">Become a SR PAY Private Limited Franchise &amp; Grow your income 30% to 50% per month
                                        </h6>
                                    </div>
                                </div>
                                <div className="col-md-2 col-sm-2 col-12">
                                    <div className="right-holder-md">
                                        <a href="/joinus" className="primary-button button-lg mt-15-xs bluebtn">Join Now</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="page-title-section">
                        <div className="container">
                            <h1>Privacy <span className="white"> Policy</span></h1>
                        </div>
                    </div>
                    <div className>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 col-sm-12 col-12">
                                    <div className="pl-30-md mt-40">
                                        <div className="text-content-big mt-20">
                                            <p>Your privacy is important to us. This Privacy Policy ("Policy") governs the manner in which SR PAY Private Limited. herein after referred to as the ("Company" or "We") collects, gains access to, uses, maintains and discloses information including personal and non personal information of the user or user's applying or using various services provided by the Company either through the company's website, mobile applications or through network telecom operators. To better protect your privacy we are providing this notice explaining our online information practices and the choices you can make henceforth about the way your information is collected and used. Please note that the scope of this Policy is limited to the aforesaid information collected or received by the Company through your use of the Services. By using our Services you are expressing your agreement to this Policy and the processing of your information including your personal information in the manner provided in this Policy. If you do not agree to these terms please do not use the Services and do not provide the permissions when specifically asked by the Company through its website, applications or by network telecom operators.</p>
                                            <h5><u> Information We Collect:</u></h5>
                                            <br />
                                            <p>
                                                We may collect and/or gain access to and/or record certain information including personal information from or of the User in a variety of ways including but not limited to, when User access and uses the Services and in connection with other activities, services, features or resources we make available on different platforms. This information may relate to and include but not limited to your Name, Address, Date of Birth, Passport No., Email address, Phone number, device location, your unique ßdevice ID (persistent / non-persistent), Hardware type, International mobile equipment identity ("IMEI"), the version of operating system ("OS"), your device's name, your email address (if you are connected to Facebook or Google+), and your location (based on your Internet Protocol ("IP") address), your mobile phone contact list, services installed by the User through our mobile application, applications uninstalled by User, all other applications installed by User on his/her device, foreground running apps/process (RUN event; We update server about the app downloaded/installed by our mobile application), read SMS, network information, User behavior analysis, demographic information (such as preferences and interests etc), Credit/Debit Card information of User for Internet banking (Please note that we do not store credit/debit card information on our servers) [hereinafter referred to as "Personal Information"]. User can always refuse to supply Personal Information. However, it may prevent them in engaging certain Services related activities.
                                                How You Can Access Or Correct Your Information&nbsp;You can access all your personally identifiable information that we collect online and maintain by contacting our customer service department. We have put in place this procedure to better safeguard your information. You can correct factual errors in your personally identifiable information by sending us a request that credibly shows an error. To further protect your privacy and security, we will take necessary steps to verify your identity before granting any access or making corrections. </p>
                                            <h5><b> The Way We Use Information </b></h5>
                                            <br />
                                            <p>We use the information you provide about yourself when placing an order only to complete that order. We do not share this information with outside parties except to the extent necessary to complete that order.
                                                We may use your Personal Information to:
                                            </p>
                                            <p>
                                                <ul className="details">
                                                    <ol>Administer the Services.</ol>
                                                    <ol>Personalize the Services for User.</ol>
                                                    <ol>End to User direct links to the Services.</ol>
                                                    <ol>Process transactions.</ol>
                                                    <ol>Process installation.</ol>
                                                    <ol>Sending our newsletters.</ol>
                                                    <ol>Develop, deliver, and improve our products, services, content, and for advertising.</ol>
                                                    <ol>Send important notices, such as communications about purchases/downloads and changes to our Policy.</ol>
                                                    <ol>Auditing, data analysis, and research to improve our Services.</ol>
                                                    <ol>Troubleshooting and helping us to understand usage trends.</ol>
                                                    <ol>Send alerts to User.</ol>
                                                    <ol>Marketing and promotion of the Services.</ol>
                                                </ul>
                                            </p>
                                            <h5><b>Collection and use of non-personal information</b></h5>
                                            <br />
                                            <p><ul className="details">
                                                <ol>We may collect non-personal information about User whenever they use and interact with the Services. Non-personal information may include the browser name, the type of computer and technical information about means used by the User to connect to our Services, such as the information about the operating system and the internet service providers utilized and other similar information.</ol>
                                                <ol>This information is aggregated and used to help us provide more useful information to the User and to understand which part of the Services, its products and services are of most interest to the User.</ol>
                                                <ol>If we do combine non-personal information with Personal Information, the combined information will be treated as Personal Information for as long as it remains combined.</ol>
                                            </ul>
                                            </p>
                                            <h5><b>Information Sharing with relevant Authorities</b></h5>
                                            <br />
                                            <p><ul className="details">
                                                <ol>We may share Personal Information as well as non-personal information of the User with the courts, police authorities or any other government/regulatory/statutory authority, in case these are required by them in relation to any proceedings pending before them and given to us in writing.</ol>
                                            </ul>
                                            </p>
                                            <h5><b>Security</b></h5>
                                            <br />
                                            <p><ul className="details">
                                                <ol>We are committed to ensure that User's Personal Information is secure. We adopt appropriate data collection, storage and processing practices and security measures to protect against unauthorized access, alteration, disclosure or destruction of the User Personal Information like but not limited to username, password, transaction information and data stored in our Services.
                                                </ol>
                                                <ol>When a User uses services and products offered by us through the Services, the Personal Information shared by User is visible to the other users and can be read, collected, or used by them. The User is responsible for the Personal Information User chooses to submit in these instances. For example, if the User lists his/her name and email address in a blog/forum, posting that information is public. Users are requested to exercise caution when using these features.</ol>
                                            </ul>
                                            </p>
                                            <h5><b>How we use cookies?</b></h5>
                                            <br />
                                            <p><ul className="details">
                                                <ol>A cookie is a small file which asks permission to be placed on the User's desktop/laptop/mobile device's memory drive. Once the User agrees, the file is added and the cookie thereon helps analyze web traffic. Cookies allow Services to respond to the User as an individual. The Services can tailor its operations to the User's needs, likes and dislikes by gathering and remembering information about the User's preferences.</ol>
                                                <ol>Cookies help us better understand User's behavior, and tell us which parts of the Services Users have visited and facilitates in measuring the effectiveness of products and services offered by us. We treat information collected by cookies and other technologies as non-personal information. However, to the extent that internet protocol (IP) addresses or similar identifiers are considered Personal Information by local law, we also treat these identifiers as Personal Information.</ol>
                                                <ol>We use traffic log cookies to identify which pages are being used. This helps us analyze data about web page traffic and improve our Services in order to tailor it to the customer needs. We only use this information for statistical analysis purposes and then the data is removed from the system.</ol>
                                                <ol>Overall, cookies help us provide User with a better experience of the Services, by enabling us to monitor which pages Users find useful and which the Users do not. A cookie in no way gives us access to the User's desktop/laptop/mobile device or any information about the User, other than the data a User chooses to share with us.</ol>
                                                <ol>A User can choose to accept or decline cookies. Most web browsers automatically accept cookies, but a User can usually modify its browser setting to decline cookies if the User prefer, although this may prevent the User from taking full advantage of the Services.</ol>
                                            </ul>
                                            </p>
                                            <h5><b>Retention of Personal Information</b></h5>
                                            <br />
                                            <p>
                                                We will retain User's Personal Information for the period necessary to fulfill the purposes outlined in this Policy unless a longer retention period is required or permitted by law.</p>
                                            <h5><b>Sharing Personal Information</b></h5>
                                            <br />
                                            <p>We may use third party service providers to help us operate our business and Services or administer activities on our behalf, such as sending out newsletters or surveys. We may share the User's Personal Information with these third parties for those limited purposes.</p>
                                            <h5><b>Third party websites</b></h5>
                                            <br />
                                            <p>User's may find advertising or other content on the Services that link to the sites and services of our partners, suppliers, advertisers, sponsors, licensors and other third parties. We do not control the content or links that appear on these sites and services and are not responsible for the practices employed by those websites and services linked to or from our Services. In addition, these sites and services including their content and links may be constantly changing. These sites and services may have their own privacy policies and customer service policies. Browsing and interaction on any other website, including websites and services which have a link to our Services is subject to those websites and service's own terms and policies.
                                            </p>
                                            <h5><b>Advertising</b></h5>
                                            <br />
                                            <p>Advertisement appearing on the Services may be delivered to the User by advertising partners, who may set cookies. These cookies allow the ad server to recognize the User's computer each time they send the User an online advertisement to compile non-personal information about the User or others who use the User's computer. This information allows advertisement networks to among other things, deliver targeted advertisements that they believe will be of most interest to the User. This Policy does not cover the use of cookies by any other advertiser.
                                            </p>
                                            <h5><b>Changes to this privacy policy</b></h5>
                                            <br />
                                            <p>The Company has the discretion to update this Policy at any time. We encourage Users to frequently check this page for any changes to stay informed about how we are helping to protect the Personal Information we collect. User hereby acknowledges and agrees that it is his/her responsibility to review this Policy periodically and become aware of modifications.</p>
                                            <h5><b>User's acceptance of these terms</b></h5>
                                            <br />
                                            <p>By using our Services the User signifies his/her acceptance to this Policy. If the User does not agree to this Policy it is advised to not to use the Services. User's continued use of the Services following the posting of changes to this Policy will be deemed as User's acceptance to those changes.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Footer />
            </div>
        </div>
    )
}

export default App