import React, { useEffect, useState } from 'react';
// import { bankModes } from './../config/reportName';
import { getUser } from '../../Utils/Common';
import moment from 'moment';
import PropagateLoader from "react-spinners/PropagateLoader";
import DataTable from 'react-data-table-component';
import { getGeoLocation, dateofBirth } from '../../Utils/function';
import { GET_COMMISSION_WITHDRAWAL_HISTORY, GET_USER_MONTHLY_COMMISSION_CALCULATE, POST_SAVE_COMMISSION_WITHDRAWAL } from '../../Utils/appConstants';
import { getRequest, postRequest } from '../../Utils/AppApi';
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import { Popconfirm, Table, Space, Tag, Divider, Tooltip } from 'antd';
// const text = 'Are you sure to delete this data?';
const user = getUser();

const MonthYearDates = ({ month, year }) => {
    // Validate input values
    if (isNaN(month) || isNaN(year) || month < 1 || month > 12) {
        return <div>Invalid month or year</div>;
    }
    // const firstDate = new Date(year, month - 1, 1);
    const lastDate = new Date(year, month, 1);

    const lastDateString = lastDate.toISOString().split('T')[0];

    return (
        <div>1-{month}-{year} To {dateofBirth(lastDateString)}</div>
    );
};

const MonthConverter = ({ monthDigit }) => {
    // Array of month names
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    return months[monthDigit - 1];
};

function CommissionRelease(props) {
    const history = useHistory();  // eslint-disable-line react-hooks/exhaustive-deps
    // const csvLink = useRef()
    const limit = 10;
    // const dateFormat = 'DD-MM-YYYY';
    const [currentMonth, setCurrentMonth] = useState('');
    const [currentYear, setCurrentYear] = useState('');
    const [latitude, setLatitude] = useState("");
    const [longitude, setLongitude] = useState("");
    // const [getDataField, setDataField] = useState([]);
    const [roleCode, setRoleCode] = useState(null);
    const [getData, setData] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [getTotalRecord, setTotalRecord] = useState(0);
    const [page, setPage] = useState(1);
    const [commissionList, setCommissionList] = useState([]);

    useEffect(() => {
        setRoleCode(user && user.data && user.data.role && user.data.role.code)
        const currentDate = moment();
        setCurrentMonth(currentDate.format('MM')); // Full month name
        setCurrentYear(currentDate.format('YYYY'));
    }, []);

    const columns = [{
        name: 'Release Date',
        selector: row => <><Tooltip title={row.currentDate}>{row.currentDate}</Tooltip></>,
    }, {
        name: 'Transaction Id',
        selector: row => <><Tooltip title={row.transactionId}>{row.transactionId}</Tooltip></>,
    }, {
        name: 'Role Name',
        selector: row => <><Tooltip title={row.roleName}>{row.roleName}</Tooltip></>,
    }, {
        name: 'Commission',
        selector: row => <><Tooltip title={row.commissionAmount}>{row.commissionAmount}</Tooltip></>,
    }, {
        name: 'TDS',
        selector: row => <><Tooltip title={row.tdsAmount}>{row.tdsAmount}</Tooltip></>,
    }, {
        name: 'Net Amount',
        selector: row => row.netAmount ? row.netAmount : "-",
    }, {
        name: 'Year',
        selector: row => row.year ? row.year : "-",
    }, {
        name: 'Month',
        selector: row => <><MonthConverter monthDigit={row.month} /></>,
    }, {
    //     name: 'Period',
    //     selector: row => <><MonthYearDates month={row.month} year={row.year} /></>,
    // }, {
        name: 'Closing Balance',
        selector: row => row.closingBalance,
    }] // eslint-disable-line react-hooks/exhaustive-deps

    const getCommissionMonthly = () => {
        setLoading(true);
        getRequest(`${GET_USER_MONTHLY_COMMISSION_CALCULATE}`, history).then((response) => {
            setCommissionList(response.data);
            setLoading(false);
        }).catch(function (error) {
            setLoading(false);
            console.log(error)
        });
    }

    const objectToQueryString = (obj) => {
        var str = [];
        for (var p in obj)
            if (obj.hasOwnProperty(p)) {
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
        return str.join("&");
    } // eslint-disable-line react-hooks/exhaustive-deps

    const params = { page: page, limit: limit };

    const getDataReports = () => {
        // if (startDate && endDate) {
        //   params.startDate = startDate ? startDate : "";
        //   params.endDate = endDate ? endDate : "";
        // }

        const queryString = objectToQueryString(params);
        getRequest(`${GET_COMMISSION_WITHDRAWAL_HISTORY}?${queryString}`, history).then((response) => {
            if (response.success === true) {
                setData(response.data);
                setTotalRecord(response.data.totalDocs);
                setPage(response.data.page);
            } else {
                setData([]);
                setTotalRecord(0);
                setPage(1);
            }
        }).catch(function (error) {
            console.log(error)
            // setLoading(false);
        })
    } // eslint-disable-line react-hooks/exhaustive-deps

    const handlePageChange = page => {
        setPage(page);
    }; // eslint-disable-line react-hooks/exhaustive-deps

    const handleWithdrawal = (data) => {
        setLoading(true);
        let payload = data;
        payload.latitude = latitude;
        payload.longitude = longitude;
        postRequest(POST_SAVE_COMMISSION_WITHDRAWAL, payload, history).then((response) => {
            if (response.success === true) {
                getCommissionMonthly();
                getDataReports();
                toast.success(response.message);
            } else {
                toast.error(response.message);
            }
            setLoading(false);
        }).catch(function (error) {
            setLoading(false);
            // console.log(error)
            // setLoading(false);
        })

    } // eslint-disable-line react-hooks/exhaustive-deps

    const fetchLocation = async () => {
        let data = await getGeoLocation();
        setLatitude(data.latitude);
        setLongitude(data.longitude);
    }

    useEffect(() => {
        getCommissionMonthly();
        getDataReports();
        fetchLocation();
    }, [page]) // eslint-disable-line react-hooks/exhaustive-deps

    if (isLoading) {
        return <div className="content text-center" style={{ margin: "250px 0" }}>
            <PropagateLoader color="#5851D2" loading={isLoading} size={25} />
        </div>
    }

    return (
        <div>
            <div className="content-wrapper pt-2">
                <div className="content-header my-0 py-0">
                    <div className="container-fluid">
                        <div className="row mb-0 align-items-center">
                            <div className="col-sm-6">
                                <h1 className='page-title text-blue font-merriweather'>Monthly <span className='text-red'>Earning</span></h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content">
                    <div className="container-fluid pt-2 pb-5">
                        <div className="row">
                            <div className="col-md-12">
                                <div className='card rounded-0'>
                                    <div className='card-body'>
                                        <Table align={"center"} columns={[
                                            {
                                                title: 'Month',
                                                // dataIndex: 'month',
                                                render: (record) => (<MonthConverter monthDigit={record.month} />)
                                            }, {
                                                title: 'Year',
                                                dataIndex: 'year',
                                            }, {
                                                title: 'Commission Amount',
                                                dataIndex: 'totalAmount',
                                            }, {
                                                title: 'TDS Amount',
                                                dataIndex: 'tdsAmount',
                                            }, {
                                                title: 'Net Amount',
                                                render: (record) => record.totalAmount - record.tdsAmount
                                            }, {
                                                title: 'Role',
                                                dataIndex: 'roleName',
                                            }, {
                                                title: 'Period',
                                                // dataIndex: 'monthYear',
                                                render: (record) => (<MonthYearDates month={record.month} year={record.year} />)
                                            }, {
                                                title: "Action",
                                                key: "action",
                                                className: roleCode === "P" ? "d-none" : "",
                                                render: (record, key) => (
                                                    <><Space key={key} size="middle">
                                                        {currentYear + "-" + currentMonth !== record.monthYear && <Popconfirm placement="left" title={"Are you sure want to withdrawal this commission?"} onConfirm={() => handleWithdrawal(record)} okText="Yes" cancelText="No"><Tag color={"green"} onClick={""} className='pointer' key={0}>
                                                            Withdrawal
                                                        </Tag></Popconfirm>}
                                                    </Space></>
                                                )
                                            }
                                        ]} dataSource={commissionList} size="small" />
                                    </div>
                                    <Divider />
                                    {roleCode !== "P" && <div className='card-body'>
                                        <div className="content-header my-0 px-0">
                                            <h1 className='page-title text-blue font-merriweather mb-3'>Monthly <span className='text-red'>Earning History</span></h1>
                                        </div>

                                        <div className='history-table'>
                                            <div className="container-fluid pb-5">
                                                <div className="col-md-12">
                                                    <DataTable
                                                        title={""}
                                                        columns={columns}
                                                        data={getData.docs}
                                                        highlightOnHover
                                                        pagination
                                                        paginationServer
                                                        paginationTotalRows={getTotalRecord}
                                                        onChangeRowsPerPage={getDataReports}
                                                        onChangePage={handlePageChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer position="top-center" autoClose={5000} limit={2} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="colored" />
            </div>
        </div>
    )
}

export default CommissionRelease;