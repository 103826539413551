import React, { useEffect, useState } from 'react';
import Invoice from './invoice';
import DataTable from 'react-data-table-component';
// import { isNumber } from '../../Utils/Common';
// import { getGeoLocation, refId, isIfscCodeValid } from '../../Utils/function';

import { BBPS_POST_BILL_PAYMENT_HISTORY, BBPS_POST_BILL_PAYMENT_STATUS } from '../../Utils/appConstants';
import { getNewRequest, postPayURequest } from '../../Utils/AppApi';
import { useHistory } from "react-router-dom";
import { Popconfirm, Tooltip, Modal, Select, DatePicker } from 'antd';

const text = 'Find Transaction Status?';

function History(props) {

    const history = useHistory();  // eslint-disable-line react-hooks/exhaustive-deps
    // const csvLink = useRef()
    const limit = 10;
    const dateFormat = 'DD-MM-YYYY';
    const [getData, setData] = useState([]);
    const [getTotalRecord, setTotalRecord] = useState(0);
    const [page, setPage] = useState(1);
    const [isInvoiceModal, setInvoiceModal] = useState(false);
    const [isInvoiceData, setInvoiceData] = useState(null);

    const columns = [{
        name: 'TXT ID',
        selector: row => <><Tooltip title={row.txtId}>{row.txtId}</Tooltip></>,
        width: "200px"
    }, {
        name: 'Name',
        selector: row => <><Tooltip title={row.customerName}>{row.customerName}</Tooltip></>,
        width: "200px"
    }, {
        name: 'Account Number',
        selector: row => <><Tooltip title={row.accountNumber}>{row.accountNumber}</Tooltip></>,
        width: "180px"
    }, {
        name: 'Biller Name',
        selector: row => <><Tooltip title={row.billerName}>{row.billerName}</Tooltip></>,
        width: "250px"
    }, {
        name: 'Category',
        selector: row => <><Tooltip title={row.category}>{row.category}</Tooltip></>,
        width: "120px"
    }, {
        name: 'Customer Number',
        selector: row => <><Tooltip title={row.customerPhoneNumber}>{row.customerPhoneNumber}</Tooltip></>,
        width: "150px"
    }, {
        name: 'Amount',
        selector: row => row.paidAmount ? row.paidAmount : "-",
    }, {
        name: 'Status',
        selector: row => <><Tooltip title={row.txnStatus}>{row.txnStatus}</Tooltip></>,
        width: "180px"
    }, {
        name: 'TXT Reference Id',
        cell: row => row.txnReferenceId,
        width: "220px"
    }, {
        name: 'Biller Reference Number',
        selector: row => row.billerReferenceNumber ? row.billerReferenceNumber : "-",
        width: "200px"
    }, {
        name: 'Action',
        cell: row => <><Popconfirm placement="left" title={text} onConfirm={(event) => handleStatus(event, row.refId)} okText="Yes" cancelText="No"><div className='icon-border border border-warning text-warning pointer'><i class="fa-solid fa-list"></i></div></Popconfirm> &nbsp; <div className='icon-border border border-success text-success pointer' onClick={() => viewInvoice(row)}><i class="fa-solid fa-file-invoice"></i></div></>,
    }] // eslint-disable-line react-hooks/exhaustive-deps

    const viewInvoice = (data) => {
        setInvoiceModal(true);
        setInvoiceData(data)
    }

    const objectToQueryString = (obj) => {
        var str = [];
        for (var p in obj)
            if (obj.hasOwnProperty(p)) {
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
        return str.join("&");
    } // eslint-disable-line react-hooks/exhaustive-deps

    const params = { page: page, limit: limit };

    const getDataReports = () => {
        // if (startDate && endDate) {
        //   params.startDate = startDate ? startDate : "";
        //   params.endDate = endDate ? endDate : "";
        // }

        const queryString = objectToQueryString(params);
        getNewRequest(`${BBPS_POST_BILL_PAYMENT_HISTORY}?${queryString}`, history).then((response) => {
            if (response.success === true) {
                setData(response.data);
                setInvoiceData(response.data.docs[0])
                setTotalRecord(response.data.totalDocs);
                setPage(response.data.page);
            } else {
                setData([]);
                setTotalRecord(0);
                setPage(1);
            }
        }).catch(function (error) {
            console.log(error)
            // setLoading(false);
        })
    } // eslint-disable-line react-hooks/exhaustive-deps

    const handlePageChange = page => {
        setPage(page);
    }; // eslint-disable-line react-hooks/exhaustive-deps

    const handleStatus = (event, id) => {
        let payload = { refId: id };
        postPayURequest(`${BBPS_POST_BILL_PAYMENT_STATUS}`, payload, history).then((response) => {
            // console.log("response -------", response)
            if (response.success === true) {
                getDataReports();
            }
        }).catch(function (error) {
            console.log(error)
        })
    } // eslint-disable-line react-hooks/exhaustive-deps

    const handleHideModal = () => {
        setInvoiceModal(false);
    }

    useEffect(() => {
        getDataReports();
    }, [page]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            <div className="row">
                <div className='col-md-12 date-search mb-0'>
                    <div className='row'>
                        <div className='col-md-2'>
                            <div className="form-group">
                                <label className='date-search-label mb-0 text-blue'>From</label>
                                <DatePicker className="form-control form-control-border" format={dateFormat} />
                            </div>
                        </div>
                        <div className='col-md-2'>
                            <div className="form-group">
                                <label className='date-search-label mb-0 text-red'>To</label>
                                <DatePicker className="form-control form-control-border" format={dateFormat} />
                            </div>
                        </div>
                        <div className='col-md-2'>
                            <div className="form-group">
                                <label className='date-search-label mb-0 text-blue'>&nbsp;</label>
                                <Select options={[
                                    { "label": "Transaction ID", "value": "ID" },
                                    { "label": "Account Number", "value": "account_number" },
                                    { "label": "Mobile", "value": "mobile" }
                                ]} className={`normal-light w-100 form-control-border`} classNamePrefix="select" placeholder="Search By" name='month' />
                            </div>
                        </div>
                        <div className='col-md-3'>
                            <div className="form-group">
                                <label className='date-search-label mb-0 text-blue'>&nbsp;</label>
                                <input type="text" className="form-control form-control-border " placeholder="Search..." />
                            </div>
                        </div>
                        <div className='col-md-2'>
                            <div>
                                <label className='date-search-label mb-0 text-red'>&nbsp;</label>
                            </div>
                            <button type="submit" className="btn btn-primary rounded-pill pl-4 pr-4">Search</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container-fluid history-table'>
                <div className="pb-5">
                    <DataTable
                        title={""}
                        columns={columns}
                        data={getData.docs}
                        highlightOnHover
                        pagination
                        paginationServer
                        paginationTotalRows={getTotalRecord}
                        onChangeRowsPerPage={getDataReports}
                        onChangePage={handlePageChange}
                    />
                </div>
            </div>
            {isInvoiceModal && <Modal title={<div className='col-md-12 row text-left'>
                <div className='col-md-6'>
                    <img className='text-left' src='../logo.png' alt='photos' width={"50%"} />
                </div>
                <div className='col-md-6 text-right'>
                    <img className='text-right' src={'../images/bharat-billpay-logo-1@2x.png'} alt='photos' width={"40%"} />
                </div>
            </div>} width={800} open={isInvoiceModal} footer={false} onCancel={handleHideModal}>
                <Invoice data={isInvoiceData} />
            </Modal>}
        </div>
    )
}

export default History;