import React from 'react';
import { useLocation } from 'react-router-dom';
import { INRFormat } from '../../Utils/Common';
import { Button } from 'antd';

const PaymentResponse = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const status = 1;
  const message = "Payment has been failed do you want to try again?"
  // Get all query parameters dynamically
  const allQueryParams = {};
  queryParams.forEach((value, key) => {
    allQueryParams[key] = value;
  });

  return (
    <div className='text-center my-5'>
      <div className="container payment-card" style={{ border: "1px solid grey" }}>
        {status === 0 && <><div style={{ borderRadius: 200, height: 200, width: 200, background: '#ffe6e6', margin: '0 auto' }}>
          <b style={{ color: "#e60000" }} className="checkmark">x</b>
        </div><h1>Payment Failed</h1></>}
        {status === 1 && <><div style={{ borderRadius: 200, height: 200, width: 200, background: '#F8FAF5', margin: '0 auto' }}>
          <i className="checkmark">✓</i>
        </div>
          <h1>Success</h1>
        </>
        }
        {status === 2 && <><div style={{ borderRadius: 200, height: 200, width: 200, background: '#ffffb3', margin: '0 auto' }}>
          <i className="checkmark"><i className="fa fa-clock-o" aria-hidden="true"></i></i>
        </div>
          <h1>Pending</h1>
        </>
        }

        <h5>Amount : {INRFormat(45000)}</h5>
        <p className="payment-card-content">{message}</p><Button className="btn btn-small bg-primary" small>Try Again</Button>
      </div>
      {/* <h2>User Payment Failed</h2>
      {Object.entries(allQueryParams).map(([key, value]) => (
        <p key={key}>{`${key}: ${value}`}</p>
      ))} */}
      {/* Other user profile information */}
    </div>
  );
};

export default PaymentResponse;