import React, { useEffect, useState } from 'react';
// import { CSVLink } from 'react-csv';
import DataTable from 'react-data-table-component';
import { GET_DMT_PARTNER_REFUND_PENDING_HISTORY } from './../../../Utils/appConstants';
import { INRFormat, channelStatus, dmtDetailStatus } from './../../../Utils/Common';
import { getRequest } from './../../../Utils/AppApi';
import { useHistory } from "react-router-dom";
// import { toast } from 'react-toastify';
// import PropagateLoader from "react-spinners/PropagateLoader";
// import DmtInvoice from "../../container/dmtInvoice";
import { DatePicker, Tooltip } from 'antd';
import { getUTCToLocalDateTimeFormat, queryDate } from '../../../Utils/function';

function RefundHistory(props) {
    const history = useHistory();
    // const csvLink = useRef()
    const limit = 10;
    const dateFormat = 'DD-MM-YYYY';

    const [searchAccountNumber, setSearchAccountNumber] = useState(null);
    const [searchTransId, setSearchTransId] = useState(null);
    const [searchCustomerId, setSearchCustomerId] = useState(null);

    // const [isLoading, setLoading] = useState(false);
    const [endDate, setEndDate] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [getData, setData] = useState([]);
    // const [getCsvData, setCsvData] = useState([]);
    const [getTotalRecord, setTotalRecord] = useState(0);
    // const [refundData, setRefundData] = useState(null);
    const [page, setPage] = useState(1);
    const [isLimit, setLimit] = useState(limit);
    const [userId, setUserId] = useState(props.userId);

    const columns = [{
        name: 'Date Time',
        cell: row => getUTCToLocalDateTimeFormat(row.createdAt),
        width: "200px",
    }, {
        name: 'Trans ID',
        selector: row => <Tooltip placement="bottom" title={row.transactionId && row.transactionId}>{row.transactionId && row.transactionId}</Tooltip>,
        width: "180px",
    }, {
        name: 'Amount',
        selector: row => INRFormat(row.amount),
        width: "100px",
    }, {
        name: 'Status',
        selector: row => dmtDetailStatus(row.tx_status),
        width: "150px",
    }, {
        name: 'Mode',
        selector: row => channelStatus(row.dmttransactions.channel),
    }, {
        name: 'Partner ID',
        selector: row => <Tooltip placement="bottom" title={row.users.username}>{row.users.username}</Tooltip>,
        width: "130px",
    }, {
        name: 'Customer ID',
        selector: row => <Tooltip placement="bottom" title={row.customer_id}>{row.customer_id}</Tooltip>,
        width: "130px",
    }, {
        name: 'IFSC Code',
        selector: row => row.dmttransactions && row.dmttransactions.ifsccode,
        width: "150px",
    }, {
        name: 'Account Number',
        selector: row => row.dmttransactions && row.dmttransactions.accountNumber,
        width: "170px",
    }, {
        name: 'Bene Name',
        selector: row => row.baneData ? row.baneData.beneName : (row.baneData1) ? row.baneData1.beneName : "",
        width: "190px",
    },{
        name: 'Message',
        selector: row => <Tooltip placement="bottom" title={row.reason && row.reason}>{row.reason && row.reason}</Tooltip>,
        width: "250px",
    }]

    // const handleInvoice = (data) => {
    //     setInvoiceDataModal(true);
    //     setInvoiceData(data);
    // }

    const objectToQueryString = (obj) => {
        var str = [];
        for (var p in obj)
            if (obj.hasOwnProperty(p)) {
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
        return str.join("&");
    }

    const params = { page: page, limit: isLimit };

    const getDataReports = (data) => {
        if (data) {
            setLimit(data)
            params.limit = data;
        }
        if (startDate && endDate) {
            params.startDate = startDate ? queryDate(startDate) : "";
            params.endDate = endDate ? queryDate(endDate) : "";
        }

        if (props.userId) {
            params.userId = userId ? userId : props.userId;
        }

        params.searchTransId = searchTransId ? searchTransId : "";
        params.searchAccountNumber = searchAccountNumber ? searchAccountNumber : "";
        params.searchCustomerId = searchCustomerId ? searchCustomerId : "";
        params.searchStatus = 3;
        params.isAdmin = true;

        const queryString = objectToQueryString(params);
        getRequest(`${GET_DMT_PARTNER_REFUND_PENDING_HISTORY}?${queryString}`, history).then((response) => {
            if (response.success === true) {
                setData(response.data);
                setTotalRecord(response.data.totalDocs);
                setPage(response.data.page);
            } else {
                setData([]);
                setTotalRecord(0);
                setPage(1);
            }
        }).catch(function (error) {
            console.log(error)
            // setLoading(false);
        })
    } // eslint-disable-line react-hooks/exhaustive-deps

    // const calculateTimeDifference = (row) => {
    //     const targetTime = new Date(getUTCToLocalDateTimeFormat(row.createdAt)).getTime(); // "29 Oct 2023, 04:39 PM" in milliseconds
    //     const currentTime = new Date().getTime();

    //     const differenceInMinutes = Math.floor((currentTime - targetTime) / (60 * 1000));

    //     return differenceInMinutes;
    // };

    // const handleSubmit = (data) => {
    //     let time = calculateTimeDifference(data);
    //     // console.log("time ----------------", calculateTimeDifference(data));
    //     // const queryString = objectToQueryString(payload);
    //     if (time >= 60) {
    //         setLoading(true)
    //         const payload = {
    //             transactionId: data.client_ref_id,
    //         }
    //         postRequest(`${POST_DMT_REINITIATE_TRANSACTION}`, payload, history).then((response) => {
    //             if (response.success === true) {
    //                 toast.success(response.message);
    //                 getDataReports();
    //             } else {
    //                 toast.error(response.message);
    //             }
    //             setLoading(false);
    //         }).catch(function (error) {
    //             // console.log(error)
    //             setLoading(false);
    //         })
    //     } else {
    //         toast.error("Please check status after " + (60 - time) + " Minutes");
    //     }
    //     // console.log("accept ----",value)
    // }

    // const transactionInquiry = (data) => {
    //     // console.log("data -------------", data.parentTransactionId)
    //     setLoading(true)
    //     const payload = {
    //         transactionId: data.transactionId,
    //     }
    //     postRequest(`${GET_DMT_TRANSACTION_INQUIRY}`, payload, history).then((response) => {
    //         if (response.success === true) {
    //             toast.success(response.message);
    //             getDataReports();
    //         } else {
    //             toast.error(response.message);
    //         }
    //         setLoading(false);
    //     }).catch(function (error) {
    //         // console.log(error)
    //         setLoading(false);
    //     })
    // }

    // const refundTransaction = (data) => {
    //     // console.log("data -------------", data.parentTransactionId)
    //     setRefundData(data);
    //     setOtpModal(true);
    // }

    // const handleRefundTransaction = () => {
    //     setLoading(true)
    //     const payload = {
    //         transactionId: refundData.transactionId,
    //         otp: isOtp
    //     }
    //     postRequest(`${POST_DMT_REFUND_TRANSACTION}`, payload, history).then((response) => {
    //         if (response.success === true) {
    //             toast.success(response.message);
    //             getDataReports();
    //         } else {
    //             toast.error(response.message);
    //         }
    //         setLoading(false);
    //     }).catch(function (error) {
    //         // console.log(error)
    //         setLoading(false);
    //     })
    // }

    const handlePageChange = (page, size) => {
        // console.log("-----", size)
        setPage(page);
    };

    const onChangeStartDate = (date, dateString) => {
        // console.log(date, dateString);
        setStartDate(date)
    };

    const onChangeEndDate = (date, dateString) => {
        // console.log(date, dateString);
        setEndDate(date)
    };

    // const handleHideModal = () => {
    //     setOtpModal(false);
    //     // setInvoiceDataModal(false);
    // }

    const handleSearch = () => {
        getDataReports();
    }

    const handleClear = () => {
        setStartDate('');
        setEndDate('');
        setSearchAccountNumber('');
        setSearchTransId('');
        setSearchCustomerId('');
    }

    useEffect(() => {
        if(searchAccountNumber || searchCustomerId || searchTransId) {
            handleSearch();
        }
    }, [searchAccountNumber, searchCustomerId, searchTransId]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (props.userId) {
            setUserId(props.userId)
        }
        getDataReports();
    }, [page]) // eslint-disable-line react-hooks/exhaustive-deps
    // trans id | IFSC code | AC | AMT | BANK RRN | Status

    // const BootyCheckbox = React.forwardRef((event, data, row) => (
    //     <div className="form-check">
    //         {console.warn("row ---------", row)}
    //       <input
    //         htmlFor="booty-check"
    //         type="checkbox"
    //         className="form-check-input pointer"
    //         onClick={(e) => onClick(e.target.value)}
    //       />
    //       <label className="form-check-label" id="booty-check" />
    //     </div>
    //   ));

    // const selectProps = (event) => { console.log("isIndeterminate--1--------------", event) };
    // // const selectProps = (data) => { console.log("data--1--------------", data) };

    // const rowDisabledCriteria = row => row.txtStatus;

    return (
        <div className="">
            <div className="content p-0">
                <div className="container-fluid">
                    <div className='card'>
                        <div className='card-body'>
                            <div className="row">
                                <div className='col-md-12 date-search mb-0'>
                                    <div className='row'>
                                        <div className='col-md-3 col-lg-3 d-flex'>
                                            <div className="form-group mr-3">
                                                <label className='date-search-label mb-0 text-blue'>From</label>
                                                <DatePicker className="form-control form-control-border" value={startDate} format={dateFormat} onChange={onChangeStartDate} />
                                            </div>
                                            <div className="form-group">
                                                <label className='date-search-label mb-0 text-red'>To</label>
                                                <DatePicker className="form-control form-control-border" value={endDate} format={dateFormat} onChange={onChangeEndDate} />
                                            </div>
                                        </div>
                                        <div className='col-md-2 col-lg-2'>
                                            <div className="form-group">
                                                <label className='date-search-label mb-0 text-red'></label>
                                                <input type="text" className="form-control form-control-border" placeholder="Trans ID" onChange={(e) => setSearchTransId(e.target.value)} />
                                            </div>
                                        </div>
                                        <div className='col-md-2 col-lg-2'>
                                            <div className="form-group">
                                                <label className='date-search-label mb-0 text-red'></label>
                                                <input type="text" className="form-control form-control-border" placeholder="A/C Number" onChange={(e) => setSearchAccountNumber(e.target.value)} />
                                            </div>
                                        </div>
                                        <div className='col-md-2 col-lg-2'>
                                            <div className="form-group">
                                                <label className='date-search-label mb-0 text-red'></label>
                                                <input type="text" className="form-control form-control-border" placeholder="Customer ID" onChange={(e) => setSearchCustomerId(e.target.value)} />
                                            </div>
                                        </div>
                                        <div className='col-md-1 col-lg-1'>
                                            <div><label className='date-search-label mb-0 text-red'>&nbsp;</label></div>
                                            <button type="submit" className="btn btn-primary rounded-pill px-3" onClick={() => handleSearch()}>Search</button>
                                        </div>
                                        <div className='col-md-1 col-lg-1'>
                                            <div><label className='date-search-label mb-0 text-red'>&nbsp;</label></div>
                                            <button type="submit" className="btn btn-default rounded-pill px-4" onClick={() => handleClear()}>Clear</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="container-fluid pt-2 pb-5">
                                <DataTable
                                    title={""}
                                    columns={columns}
                                    data={getData.docs}
                                    defaultSortFieldID={1}
                                    highlightOnHover
                                    pagination
                                    paginationServer
                                    paginationTotalRows={getTotalRecord}
                                    onChangeRowsPerPage={getDataReports}
                                    onChangePage={handlePageChange}
                                />
                            </div>
                        </div>
                    </div>
                    {/* {isOtpModal && <Modal title={"OTP CODE"} width={600} open={isOtpModal} onOk={""} onCancel={handleHideModal} footer={null}>
                        <><div className='row'>
                            <div className='col-md-12 form-group'>
                                <label>OTP Number: </label>
                                <input type={'text'} className="form-group form-control" onChange={(e) => setOtp(e.target.value)} maxLength={4} value={isOtp} onKeyDown={isNumber} />
                            </div>
                            <div className='col-md-12 form-group text-right'>
                                <button type='button' onClick={() => handleHideModal()} className='btn btn-sm btn-default mr-2'>Cancel</button>
                                <button type='button' onClick={() => handleRefundTransaction()} className='btn btn-sm btn-primary'>Send</button>
                            </div>
                        </div></>
                    </Modal>} */}
                    {/* {invoiceDataModal && <Modal open={invoiceDataModal} width={800} footer={false} onCancel={handleHideModal} closable={false}>
                        <DmtInvoice transactionId={invoiceData.client_ref_id} />
                    </Modal>} */}
                </div>
            </div>
        </div>
    )
}

export default RefundHistory