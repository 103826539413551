import React from 'react';
// import Commingsoon from '../../container/commingsoon';
function InstantTopup(props) {
  return (
    <div className="content-header my-0 py-0">
      <div className="container-fluid">
        <div className="row mb-0 align-items-center">
          <div className="col-sm-12 text-center" style={{ padding: 250 }}>
            {/* <Commingsoon /> */}
            <h1>Service Under Maintenance</h1>
          </div>
        </div>
      </div>
    </div>)
}

export default InstantTopup; 
// import React, { useEffect, useState } from 'react';
// // import { APP_KEY, API_URL } from './../config';
// import DataTable from 'react-data-table-component';
// import { GET_ADMIN_DTH_RECHARGE_REPORT } from './../../../Utils/appConstants';
// import { getRequest } from './../../../Utils/AppApi';
// import { useHistory } from "react-router-dom";
// // import Moment from 'react-moment';
// // import moment from 'moment';
// import { DatePicker, Tooltip } from 'antd';
// import { getUTCToLocalDateTimeFormat } from '../../../Utils/function';

// function DthRechargeReport(props) {

//     const history = useHistory();
//     // const csvLink = useRef()
//     const limit = 10;
//     const dateFormat = 'DD-MM-YYYY';
//     // const [search, setSearch] = useState(null);
//     const [endDate, setEndDate] = useState(null);
//     const [startDate, setStartDate] = useState(null);
//     const [getData, setData] = useState([]);
//     // const [getCsvData, setCsvData] = useState([]);
//     const [getTotalRecord, setTotalRecord] = useState(0);
//     const [page, setPage] = useState(1);
//     const [isLimit, setLimit] = useState(limit);

//     const columns = [{
//         name: 'Date',
//         cell: row => getUTCToLocalDateTimeFormat(row.updatedAt),
//         width: "180px"
//     }, {
//         name: 'TXN ID',
//         cell: row => row.transactionId,
//         width: "180px"
//     }, {
//         name: 'Partner ID',
//         selector: row => <Tooltip placement="bottom" title={row.users.username}>{row.users.username}</Tooltip>,
//         width: "130px",
//     }, {
//         name: 'Provider',
//         selector: row => row.provider,
//         width: "120px"
//     }, {
//         name: 'Customer Id',
//         selector: row => row.customerId,
//         width: "180px"
//     }, {
//         name: 'Amount',
//         selector: row => row.amount,
//         // width: "100px"
//     }, {
//         name: 'Operator ID',
//         selector: row => row.apiResponse && JSON.parse(row.apiResponse).field1,
//         // width: "180px"
//     }, {
//         name: 'Status',
//         cell: row => <>{(row.apiResponse) && JSON.parse(row.apiResponse).status }</>,
//         // width: "120px"
//     }]

//     const objectToQueryString = (obj) => {
//         var str = [];
//         for (var p in obj)
//             if (obj.hasOwnProperty(p)) {
//                 str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
//             }
//         return str.join("&");
//     }

//     const params = { page: page, limit: isLimit };

//     const getDataReports = (data) => {
//         if (data) {
//             setLimit(data)
//             params.limit = data;
//         }
//         if (startDate && endDate) {
//             params.startDate = startDate ? startDate : "";
//             params.endDate = endDate ? endDate : "";
//         }

//         const queryString = objectToQueryString(params);
//         getRequest(`${GET_ADMIN_DTH_RECHARGE_REPORT}?${queryString}`, history).then((response) => {
//             if (response.success === true) {
//                 setData(response.data);
//                 setTotalRecord(response.data.totalDocs);
//                 setPage(response.data.page);
//             } else {
//                 setData([]);
//                 setTotalRecord(0);
//                 setPage(1);
//             }
//         }).catch(function (error) {
//             console.log(error)
//             // setLoading(false);
//         })
//     } // eslint-disable-line react-hooks/exhaustive-deps

//     const handlePageChange = (page, size) => {
//         console.log("-----", size)
//         setPage(page);
//     };

//     const onChangeStartDate = (date, dateString) => {
//         // console.log(date, dateString);
//         setStartDate(dateString)
//     };

//     const onChangeEndDate = (date, dateString) => {
//         // console.log(date, dateString);
//         setEndDate(dateString)
//     };

//     const handleSearch = () => {
//         getDataReports();
//     }

//     useEffect(() => {
//         handleSearch();
//     }, []) // eslint-disable-line react-hooks/exhaustive-deps

//     useEffect(() => {
//         getDataReports();
//     }, [page]) // eslint-disable-line react-hooks/exhaustive-deps

//     return (
//         <div>
//             <div className="content-wrapper pt-2">
//                 <div className="content-header my-0 py-0">
//                     <div className="container-fluid">
//                         <div className="row mb-0 align-items-center">
//                             <div className="col-sm-6">
//                                 <h1 className='page-title text-blue font-merriweather'>UPI Transfer <span className='text-red'>Report</span></h1>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//                 <div className="content">
//                     <div className="container-fluid pt-2 pb-5">
//                         <div className="row">
//                             <div className='card col-md-12 py-3'>
//                                 <div className='card-body p-0'>
//                                     <div className="row">
//                                         <div className='col-md-6 date-search mb-0'>
//                                             <div className='row'>
//                                                 <div className='col-sm-4 col-lg-3'>
//                                                     <div className="form-group">
//                                                         <label className='date-search-label mb-0 text-blue'>From</label>
//                                                         <DatePicker className="form-control form-control-border" format={dateFormat} onChange={onChangeStartDate} />
//                                                     </div>
//                                                 </div>
//                                                 <div className='col-sm-4 col-lg-3'>
//                                                     <div className="form-group">
//                                                         <label className='date-search-label mb-0 text-red'>To</label>
//                                                         {/* <input type="text" className="form-control form-control-border" placeholder="27 September 2022" /> */}
//                                                         <DatePicker className="form-control form-control-border" format={dateFormat} onChange={onChangeEndDate} />
//                                                     </div>
//                                                 </div>
//                                                 <div className='col-sm-4 col-lg-2'>
//                                                     <div><label className='date-search-label mb-0 text-red'>&nbsp;</label></div>
//                                                     <button type="submit" className="btn btn-primary rounded-pill pl-4 pr-4" onClick={() => handleSearch()}>Search</button>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </div>
//                                     <div className="container-fluid pt-2 pb-5">
//                                         <DataTable
//                                             title={""}
//                                             columns={columns}
//                                             data={getData.docs}
//                                             highlightOnHover
//                                             pagination
//                                             paginationServer
//                                             paginationTotalRows={getTotalRecord}
//                                             onChangeRowsPerPage={getDataReports}
//                                             onChangePage={handlePageChange}
//                                         />
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     )
// }

// export default DthRechargeReport