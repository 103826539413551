import { useState, useEffect } from 'react';
import { POST_PAYOUT_CUSTOMER_DETAIL, POST_PAYOUT_ADD_CUSTOMER_DETAIL, MOBILE_SEND_OTP, MOBILE_SEND_VERFIY } from './../../Utils/appConstants';
import { isNumber } from '../../Utils/Common';
import { useHistory } from "react-router-dom";
import PropagateLoader from "react-spinners/PropagateLoader";
import AadhaarVerify from './aadhaarVerify';
import ViewCustomer from './viewCustomer';
import PayoutHistory from './history';
import { postRequest } from '../../Utils/AppApi';
import { toast } from 'react-toastify';
import { Tabs, Modal } from 'antd';
const { TabPane } = Tabs;

const PayoutIndex = () => {
    const history = useHistory();
    const tabPosition = 'left';
    const [isLoading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState(null);
    // const [timer, setTimer] = useState(null);
    const [isCustomerInfo, setCustomerInfo] = useState(null);
    const [isCustomerNotExist, setCustomerNotExist] = useState(false);
    const [searchButton, setSearchButton] = useState(true);

    const [isOtpModal, setOtpModal] = useState(false);
    const [isOtpNumberValue, setOtpNumberValue] = useState(null);
    // const [subTabs, setSubTabs] = useState('1');

    // useEffect(() => {
    //     if (searchText) {
    //         if (timer) {
    //             clearTimeout(timer)
    //             setTimer(null)
    //         }
    //         setTimer(
    //             setTimeout(() => {
    //                 if (searchText.length === 10) {
    //                     setSearchButton(false)
    //                     getCustomerInfo()
    //                 } else {
    //                     setCustomerNotExist(false);
    //                 }
    //             }, 1500)
    //         )
    //     }
    // }, [searchText]) // eslint-disable-line react-hooks/exhaustive-deps

    const handleSearch = (e) => {
        // console.log("searchText----",e.target.value)
        if (e.target.value.length === 10) {
            setSearchButton(false)
        } else {
            setCustomerInfo(null)
            setSearchButton(true)
        }
        setSearchText(e.target.value);
    }

    const findCustomerInfo = (mobile) => {
        setSearchText(null)
        setSearchText(mobile)
    }

    const getCustomerInfo = async () => {
        setLoading(true)
        let payload = { "customerMobile": searchText }
        await postRequest(POST_PAYOUT_CUSTOMER_DETAIL, payload, history).then((response) => {
            if (response.success === true) {
                setCustomerInfo(response.data);
                setCustomerNotExist(false)
                // console.log("----------------", response.data);
            } else {
                setCustomerNotExist(true);
                // console.log("----------------", response.success);
            }
            setLoading(false)
        }).catch(function (error) {
            console.log(error)
            setLoading(false);
        })
    }

    const addCustomer = async (data) => {
        if (data) {
            setLoading(true)
            let payload = data;
            await postRequest(POST_PAYOUT_ADD_CUSTOMER_DETAIL, payload, history).then((response) => {
                if (response.success === true) {
                    findCustomerInfo(searchText)
                    toast.success(response.message)
                    // console.log("----------------", response.data);
                } else {
                    // console.log("----------------", response.success);
                    toast.error(response.message)
                }
                setLoading(false)
            }).catch(function (error) {
                console.log(error)
                setLoading(false);
            })
        }
    }
    const handleHideModal = () => {
        setOtpModal(false);
    }

    const handleVerifyMobileNumber = () => {
        if (searchText) {
            setLoading(true);
            const payload = { mobile: searchText, isremittance: true };
            postRequest(MOBILE_SEND_OTP, payload, history).then((response) => {
                if (response.success === true) {
                    toast.success(response.message);
                    setOtpModal(true)
                } else {
                    toast.error(response.message);
                }
                setLoading(false);
            }).catch(function (error) {
                // console.log(error)
                setLoading(false);
            })
        }
    }

    const handleVerifyOtpMobileNumber = () => {
        setLoading(true);
        const payload = {
            mobile: searchText,
            otp: isOtpNumberValue
        }
        postRequest(MOBILE_SEND_VERFIY, payload, history).then((response) => {
            if (response.success === true) {
                toast.success(response.message);
                setOtpModal(false)
                getCustomerInfo();
            } else {
                toast.error(response.message);
            }
            setLoading(false);
        }).catch(function (error) {
            // console.log(error)
            setLoading(false);
        })
    }

    useEffect(() => {
        // console.log("subTabs-----------", subTabs)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    if (isLoading) {
        return <div className="content text-center" style={{ margin: "250px 0" }}>
            <PropagateLoader color="#5851D2" loading={isLoading} size={25} />
        </div>
    }
    return (
        <div>
            <div className="content-wrapper">
                <div className="">
                    <div className="">
                        <div className="row">
                            <div className="col-md-12">
                                <div className='card rounded-0 py-3'>
                                    <Tabs defaultActiveKey="1" tabPosition={tabPosition} onTabScroll={"right"}>
                                        <TabPane tab={`Remittance`} key={1}>
                                            <div className='row d-flex'>
                                                <div className="col-md-4 input-group mb-100">
                                                    <input className="form-control py-2 border" type="search" defaultValue={searchText} value={searchText} onKeyDown={isNumber} placeholder='Search ...' maxLength={10} onChange={(e) => handleSearch(e)} />
                                                    <button onClick={() => handleVerifyMobileNumber()} className="btn btn-primary ml-3 border-left-0 border" disabled={searchButton} type="button">
                                                        <i className="fa fa-search" /> Search
                                                    </button>
                                                </div>
                                                {isCustomerInfo && isCustomerInfo.aadharNumberData && <><div className="row col-md-2"></div>
                                                    <div className="row col-md-6">
                                                        <div className="col-md-2">
                                                            {isCustomerInfo && <img className="elevation-2" src={"data:image/png;base64," + isCustomerInfo.aadharNumberData.photo_link} alt="User Avatar" />}
                                                        </div>
                                                        <div className="col-md-10">
                                                            <ol>
                                                                <li><h2>{isCustomerInfo && isCustomerInfo.aadharNumberData.name}</h2></li>
                                                                <li><strong>Father Name : </strong>{isCustomerInfo && isCustomerInfo.aadharNumberData.care_of}</li>
                                                                <li><strong>Address : </strong>{isCustomerInfo && isCustomerInfo.aadharNumberData.address}</li>
                                                            </ol>
                                                        </div>
                                                    </div>
                                                </>}
                                            </div>

                                            {(isCustomerNotExist) && <AadhaarVerify mobile={searchText} addCustomer={addCustomer} />}
                                            {(isCustomerInfo && !isCustomerNotExist) && <ViewCustomer isCustomerInfo={isCustomerInfo} />}
                                        </TabPane>
                                        <TabPane tab={`History`} key={2}>
                                            <div className='h-100'>
                                                <PayoutHistory />
                                            </div>
                                        </TabPane>
                                    </Tabs>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {isOtpModal && <Modal title={"Verify Mobile Verification"} width={600} open={isOtpModal} onOk={""} onCancel={handleHideModal} footer={null}><div className='row'>
                    <div className='col-md-12 form-group'>
                        <label>OTP Code: </label>
                        <input type={'text'} className="form-group form-control" onChange={(e) => setOtpNumberValue(e.target.value)} maxLength={4} value={isOtpNumberValue} onKeyDown={isNumber} />
                    </div>
                    <div className='col-md-12 form-group text-right'>
                        <button type='button' onClick={() => handleHideModal()} className='btn btn-sm btn-default mr-2'>Cancel</button>
                        <button type='button' onClick={() => handleVerifyOtpMobileNumber()} className='btn btn-sm btn-primary'>Verify</button>
                    </div>
                </div>
                </Modal>}
            </div>
        </div>
    )
}

export default PayoutIndex;