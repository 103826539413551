import React, { useEffect, useState, useRef } from 'react';
import { isNumberKey } from '../../../Utils/Common';
import { SLABS_SAVE,/* UPLOAD_FILE, GET_ALL_BANK_DETAIL_INFO */ } from '../../../Utils/appConstants';
// import { getPaymentMode, /* getBankMode */ } from './../../Utils/function';
import { /* getRequest,*/ postRequest } from '../../../Utils/AppApi';
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
// import AfterSave from './../../../container/afterpayment';
import { Button, Switch } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { event } from 'jquery';

function SlabComponent(props) {
    const history = useHistory();
    const [getDataField, setDataField] = useState([]);
    const [getDataError, setDataError] = useState([]);
    // const [isSubmit, setSubmit] = useState(false);
    const [slabName, setName] = useState(null);
    const maxInput = useRef(null);
    const minInput = useRef(null);
    const commissionInput = useRef(null);
    const serviceChargeInput = useRef(null);
    const tdsInput = useRef(null);

    const nameInput = useRef(null);

    const InputArray = { "min": "", "max": "", "isPerCommission": false, "commission": "", "serviceCharge": "", "isTdsApplicable": false, "tds": "0" };
    const clearFormField = () => {
        minInput.current.value = "";
        maxInput.current.value = "";
        commissionInput.current.value = "";
        serviceChargeInput.current.value = "";
        tdsInput.current.value = "";
        nameInput.current.value = "";
        setDataError([])
    }

    const addField = () => {
        setDataField([...getDataField, InputArray]);
    }

    const removeFormFields = (i) => {
        let getDataFieldValues = [...getDataField];
        getDataFieldValues.splice(i, 1);
        setDataField(getDataFieldValues)
    }

    const FormValidate = () => {
        let formValid = true;
        let formObject = [];
        setDataError([])
        if (getDataField && getDataField.length === 0) {
            formValid = false;
        }

        if (!slabName) {
            setName('')
        }

        getDataField.map((element, index) => {
            let checkData = Object.keys(element);
            let arrAyy = {}
            checkData.forEach((ele) => {
                // console.log("index -------", index)
                // console.log("element -------", Object.keys(element))
                // console.log("element -------", getDataField[index][ele])
                if (getDataField[index][ele] === "" || typeof getDataField[index][ele] === "undefined") {
                    // console.log("formObject.join() -------", formObject)
                    arrAyy[ele] = true;
                    formValid = false;
                }
            });
            return formObject.push(arrAyy);
        })

        setDataError(formObject);
        return formValid;
    }

    const handleSubmit = () => {
        if (FormValidate(true)) {
            const payload = {
                name: slabName,
                slabArray: JSON.stringify(getDataField)
            }
            postRequest(SLABS_SAVE, payload, history).then((response) => {
                if (response.success === true) {
                    history.push({ pathname: "/slab-list" });
                    setName('');
                    setDataField([InputArray]);
                    setDataError([]);
                    clearFormField();
                    toast.success(response.message);
                } else {
                    toast.error(response.message);
                }
            }).catch(function (error) {
                // console.log(error)
                // setLoading(false);
            })
        }
    } // eslint-disable-line react-hooks/exhaustive-deps

    const onChangeValue = (i, event) => {
        const { name, value } = event.target;
        // console.log("name -----------", name)
        // console.log("value -----------", index)
        let getDataFieldValues = [...getDataField];
        getDataFieldValues[i][name] = value;
        setDataField(getDataFieldValues);
    }

    const onChangeIsTdsApplicable = (checked, i) => {
        // console.log(`index --------`, index);
        // console.log(`switch to ${checked}`);
        let getDataFieldValues = [...getDataField];
        getDataFieldValues[i].isTdsApplicable = checked;
        setDataField(getDataFieldValues);
    };

    const onChangeisPerCommission = (checked, i) => {
        // console.log(`index --------`, index);
        // console.log(`switch to ${checked}`);
        let getDataFieldValues = [...getDataField];
        getDataFieldValues[i].isPerCommission = checked;
        setDataField(getDataFieldValues);
    };

    useEffect(() => {
        setDataField([...getDataField, InputArray]);
        // setDataError([...getDataError, InputArray]);
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            <div className="content-wrapper pt-2">
                <div className="content">
                    <div className="container-fluid pt-0 pb-5">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="content-header my-0 py-0">
                                    <div className="row mb-4 align-items-center">
                                        <div className="col-sm-12">
                                            <h1 className='page-title text-blue font-merriweather'>Create <span className='text-red'>Slab</span></h1>
                                        </div>
                                    </div>
                                </div>
                                <div className='card rounded-0'>
                                    <div className='card-body'>
                                        <div className="row">
                                            <div className="col-md-5">
                                                <div className="form-group">
                                                    <label className={`form-label`} >Name : </label>
                                                    <input type="text" className={`form-control normal-light ${slabName === '' ? "is-invalid" : ""}`} name="name" ref={nameInput} maxLength={50} onChange={(e) => setName(e.target.value)} placeholder="Slab Name*" value={slabName} />
                                                    {slabName === '' && <span className="error text-danger">The field is required</span>}
                                                </div>
                                            </div>
                                            <div className="col-md-1">
                                                <div className="form-group">
                                                    <label className={`form-label`} >&nbsp;</label>
                                                    <Button type="primary" className={`form-control normal-light pr-110`} large onClick={() => addField()} >+ Add</Button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="">
                                            <table class="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">#</th>
                                                        <th scope="col">Min</th>
                                                        <th scope="col">Max</th>
                                                        <th scope="col">IsComm(%)</th>
                                                        <th scope="col">Commission / Discount</th>
                                                        <th scope="col">Service Charge</th>
                                                        <th scope="col">Apply TDS</th>
                                                        <th scope="col">TDS</th>
                                                        <th scope="col">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {getDataField && getDataField.map((element, index) => {
                                                        return <tr key={index}>
                                                            <th scope="row">{index + 1}</th>
                                                            <td><input type="number" className={`form-control normal-light ${getDataError[index] && getDataError[index].min ? "is-invalid" : ""}`} name="min" ref={minInput} maxLength={10} onChange={(e) => onChangeValue(index, e)} placeholder="Min" onKeyPress={() => isNumberKey(this, event)} value={element && element.min} /></td>

                                                            <td><input type="text" className={`form-control normal-light ${getDataError[index] && getDataError[index].max ? "is-invalid" : ""}`} name="max" ref={maxInput} maxLength={10} onChange={(e) => onChangeValue(index, e)} placeholder="Max" onKeyPress={() => isNumberKey(this, event)} value={element && element.max} /></td>

                                                            <td><Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} checked={element && element.isPerCommission} onChange={(e) => onChangeisPerCommission(e, index)} /></td>

                                                            <td><input type="text" className={`form-control normal-light ${getDataError[index] && getDataError[index].commission ? "is-invalid" : ""}`} name="commission" ref={commissionInput} maxLength={10} onChange={(e) => onChangeValue(index, e)} placeholder="Commission" onKeyPress={() => isNumberKey(this, event)} value={element && element.commission} /></td>

                                                            <td><input type="text" className={`form-control normal-light ${getDataError[index] && getDataError[index].serviceCharge ? "is-invalid" : ""}`} name="serviceCharge" ref={serviceChargeInput} maxLength={10} onChange={(e) => onChangeValue(index, e)} placeholder="Service Change" onKeyPress={() => isNumberKey(this, event)} value={element && element.serviceCharge} /></td>
                                                            
                                                            <td><Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} checked={element && element.isTdsApplicable} onChange={(e) => onChangeIsTdsApplicable(e, index)} /></td>

                                                            <td><input type="text" className={`form-control normal-light ${getDataError[index] && getDataError[index].tds ? "is-invalid" : ""}`} name="tds" ref={tdsInput} maxLength={10} onChange={(e) => onChangeValue(index, e)} placeholder="TDS Amount" onKeyPress={() => isNumberKey(this, event)} value={element && element.tds} /></td>
                                                            
                                                            {index ? <td><button type="button" className="button remove" onClick={() => removeFormFields(index)}>Remove</button></td> : <td></td>}
                                                        </tr>
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="card-footer text-right">
                                            {/* <button type="clear" className="btn btn-cancel mr-1 rounded-pill pl-4 pr-4" onClick={() => clearFormField()}>Cancel</button> */}
                                            <button type="submit" className="btn btn-primary  rounded-pill pl-4 pr-4" onClick={() => handleSubmit()}>Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer position="top-center" autoClose={5000} limit={2} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="colored" />
            </div>
        </div>
    )
}

export default SlabComponent