import React, { useEffect, useState, useRef } from 'react';
import { Modal } from 'antd';
// import { APP_KEY, API_URL } from './../config';
import { isNumber } from './../../Utils/Common';
import { Tabs, Tab } from 'react-bootstrap-tabs';
import { MOBILE_RECHARGE_VIEW_PLAN, MOBILE_RECHARGE_BESTOFFER, MOBILE_RECHARGE_OPERATORS, MOBILE_RECHARGE_PAYMENT, DTH_PROVIDERS, STATE_API, DTH_CUSTOMER_INFO, DTH_BEST_OFFER, DTH_HEAVY_REFRESH, DTH_RECHARGE_PAYMENT } from './../../Utils/appConstants';
import { getRequest, postRequest, postRequestNewApi } from '../../Utils/AppApi';
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
// import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import Select from 'react-select';
const { confirm } = Modal;

function MobileRecharge(props) {
  const history = useHistory();
  const mobileInput = useRef(null);
  const mobileOperatorSelect = useRef(null);
  const mobileStaterSelect = useRef(null);
  const mobileAmountInput = useRef(null);

  const dthProviderSelect = useRef(null);
  const dthCustomerIdInput = useRef(null);
  const dthAmountInput = useRef(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalDthCustomerId, setIsModalDthCustomerId] = useState(false);
  const [isModalBestOffer, setIsModalBestOffer] = useState(false);
  const [isModalDthBestOffer, setIsModalDthBestOffer] = useState(false);
  const [isModalDthPlan, setIsModalDthPlan] = useState(false);
  const [isMobileAmountReadOnly, setMobileAmountReadOnly] = useState(false);
  // const [mobileNumber, setMobileNumber] = useState("");

  const [getMobileOperatorData, setMobileOperatorData] = useState([]);
  const [getMobileOperatorState, setMobileOperatorState] = useState([]);
  const [getMobileBestOffer, setMobileBestOffer] = useState([]);
  const [getDthBestOffer, setDthBestOffer] = useState([]);
  const [getMobileViewPlanKey, setMobileViewPlanKey] = useState([]);
  const [getMobileViewPlan, setMobileViewPlan] = useState([]);
  const [getDTHOperatorData, setDTHOperatorData] = useState([]);
  const [getDthCustomerInfo, setDthCustomerInfo] = useState({});

  const [getDthViewPlanKey, setDthViewPlanKey] = useState([]);
  const [getDthViewPlan, setDthViewPlan] = useState([]);

  const [isDthAmountReadOnly, setDthAmountReadOnly] = useState(false);
  const [tabName, setTabName] = useState("Mobile Recharge");
  const [getMobileField, setMobileField] = useState([]);
  const [getMobileError, setMobileError] = useState({});

  const [getDthField, setDthField] = useState([]);
  const [getDthError, setDthError] = useState({});

  useEffect(() => {
    getMobileOperators();
    getDTHOperators();
    getMobileOperatorStates();
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const getMobileOperators = () => {
    getRequest(MOBILE_RECHARGE_OPERATORS, history).then((response) => {
      if (response.success === true) {
        // setMobileOperatorData(response.data);
        setMobileOperatorData(response.data.map((item) => {
          return { "key": item.appcode, "value": item.code, "label": item.name };
        }));
      } else {
        toast.error(response.message);
      }
    }).catch(function (error) {
      // // console.log(error)
      // setLoading(false);
    })
  }

  const getMobileOperatorStates = () => {
    getRequest(STATE_API, history).then((response) => {
      if (response.success === true) {
        setMobileOperatorState(response.data);
      } else {
        toast.error(response.message);
      }
    }).catch(function (error) {
      // // console.log(error)
      // setLoading(false);
    })
  }

  const getDTHOperators = () => {
    getRequest(DTH_PROVIDERS, history).then((response) => {
      if (response.success === true) {
        // setDTHOperatorData(response.data);
        setDTHOperatorData(response.data.map((item) => {
          return { "key": item.appcode, "value": item.code, "label": item.name };
        }));
      } else {
        toast.error(response.message);
      }
    }).catch(function (error) {
      // // console.log(error)
      // setLoading(false);
    })
  }

  const showModalViewPlan = () => {
    if (mobileFormValidate()) {

      const payload = {
        operator: getMobileField.mobileoperator,
        mobile: getMobileField.mobile,
        state: getMobileField.state,
      }

      postRequest(MOBILE_RECHARGE_VIEW_PLAN, payload, history).then((response) => {
        if (response.success === true) {
          const keys = Object.keys(response.data);
          const result = Object.values(response.data);
          setMobileViewPlanKey(keys);
          setMobileViewPlan(result);
          setIsModalOpen(true);
        } else {
          setMobileViewPlan([])
          toast.error(response.message);
        }
      }).catch(function (error) {
        // console.log(error)
        // setLoading(false);
      })
    }
  };


  const showModalBestOffer = async () => {
    if (mobileFormValidate()) {

      const payload = {
        operator: getMobileField.mobileoperator,
        mobile: getMobileField.mobile,
        state: getMobileField.state,
      }
      postRequest(MOBILE_RECHARGE_BESTOFFER, payload, history).then((response) => {
        if (response.success === true) {
          setMobileBestOffer(response.data);
          setIsModalBestOffer(true);
        } else {
          setMobileBestOffer([])
          toast.error(response.message);
        }
      }).catch(function (error) {
        // console.log(error)
        // setLoading(false);
      })
    }
  }

  const processMobileRechargePaymentConfirm = (event) => {
    if (mobileFormValidate(true)) {
      confirm({
        title: 'Do you Want to Proceed to Recharge?',
        icon: <ExclamationCircleOutlined />,
        content: getMobileField.mobileoperator + ', ' + getMobileField.mobile + ', ' + getMobileField.amount,
        onOk() {
          processMobileRechargePayment(event);
          console.log('OK');
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    }
  };

  const onButtonClick = () => {
    mobileInput.current.value = "";
    mobileOperatorSelect.current.value = "";
    mobileStaterSelect.current.value = "";
    mobileAmountInput.current.value = "";
  };

  const onDthButtonClick = () => {
    dthProviderSelect.current.value = "";
    dthCustomerIdInput.current.value = "";
    dthAmountInput.current.value = "";
  };

  const processMobileRechargePayment = async (event) => {
    event.preventDefault()
    if (mobileFormValidate()) {
      const payload = {
        operator: getMobileField.mobileoperator,
        mobile: getMobileField.mobile,
        state: getMobileField.state,
        amount: getMobileField.amount,
        appcode: getMobileField.mobileoperatorkey
      }

      postRequest(MOBILE_RECHARGE_PAYMENT, payload, history).then((response) => {
        if (response.success === true) {
          let clear = { "mobile": "", "mobileoperator": "", "mobileoperatorkey" : ""}
          setMobileField(clear);
          setMobileError({});
          onButtonClick()
          console.log("getMobileField===", getMobileField)
          toast.success(response.message);
        } else {
          setMobileField([]);
          setMobileError({});
          toast.error(response.message);
        }
      }).catch(function (error) {
        // console.log(error)
        // setLoading(false);
      })
    }
  }

  const processDthRechargePaymentConfirm = (event) => {
    if (dthFormValidate(true)) {
      confirm({
        title: 'Do you Want to Proceed to Recharge?',
        icon: <ExclamationCircleOutlined />,
        content: getDthField.dthprovider + ', ' + getDthField.dthcustomerid + ', ' + getDthField.dthamount,
        onOk() {
          processDthRechargePayment(event);
          console.log('OK');
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    }
  };

  const processDthRechargePayment = async (event) => {
    event.preventDefault()
    if (dthFormValidate()) {
      const payload = {
        provider: getDthField.dthprovider,
        customerId: getDthField.dthcustomerid,
        amount: getDthField.dthamount,
        appcode: getDthField.dthproviderkey
      }

      postRequestNewApi(DTH_RECHARGE_PAYMENT, payload, history).then((response) => {
        if (response.success === true) {
          // let clear = { "dthprovider" : "", "dthcustomerid" : "", "dthamount" : ""}
          onDthButtonClick()
          setDthField([]);
          setDthError({});
          toast.success(response.message);
        } else {
          setDthField([]);
          setDthError({});
          toast.error(response.message);
        }
      }).catch(function (error) {
        // console.log(error)
      })
    }
  }

  const handleOk = () => {
    setIsModalDthPlan(false);
    setIsModalOpen(false);
    setIsModalBestOffer(false)
    setIsModalDthCustomerId(false)
    setIsModalDthBestOffer(false)
  };

  const handleCancel = () => {
    setIsModalDthPlan(false);
    setIsModalOpen(false);
    setIsModalBestOffer(false)
    setIsModalDthCustomerId(false)
    setIsModalDthBestOffer(false)
  };

  const onchangeTab = (index, value) => {
    setTabName(value.props.title);
  }

  const mobileFormValidate = (payment) => {
    let formValid = true;
    let formObject = {};
    setMobileError({})
    if (getMobileField && getMobileField.length === 0) {
      formValid = false;
    }
    if (getMobileField.mobile === "" || typeof getMobileField.mobile === "undefined") {
      formObject.mobile = true;
      formValid = false;
    }

    if (getMobileField.mobileoperator === "" || typeof getMobileField.mobileoperator === "undefined") {
      formObject.mobileoperator = true;
      formValid = false;
    }

    if (getMobileField.state === "" || typeof getMobileField.state === "undefined") {
      formObject.state = true;
      formValid = false;
    }
    if (payment) {
      if (getMobileField.amount === "" || typeof getMobileField.amount === "undefined") {
        formObject.amount = true;
        formValid = false;
      }
    }

    setMobileError(formObject);
    // console.log("getMobileField===",getMobileField)
    // console.log("formObject ==",formObject)
    return formValid;
  }

  const dthFormValidate = (payment) => {
    let formValid = true;
    let formObject = {};
    setDthError({})
    if (getDthField && getDthField.length === 0) {
      formValid = false;
    }
    if (getDthField.dthprovider === "" || typeof getDthField.dthprovider === "undefined") {
      formObject.dthprovider = true;
      formValid = false;
    }

    if (getDthField.dthcustomerid === "" || typeof getDthField.dthcustomerid === "undefined") {
      formObject.dthcustomerid = true;
      formValid = false;
    }

    if (payment) {
      if (getDthField.dthamount === "" || typeof getDthField.dthamount === "undefined") {
        formObject.dthamount = true;
        formValid = false;
      }
    }

    setDthError(formObject);
    // console.log("getMobileField===",getDthError)
    // console.log("formObject ==",formObject)
    return formValid;
  }

  const onChangeMobileValueSelect = (event) => {
    const { value, key } = event;
    // console.log("event----------------",event)
    // console.log("----------------",value)
    setMobileField({ ...getMobileField, "mobileoperator": value, "mobileoperatorkey": key });
  }

  const onChangeValue = (event) => {
    const { name, value } = event.target;
    setMobileField({ ...getMobileField, [name]: value });
  }

  const onChangeDthValueSelect = (event) => {
    const { value, label, key } = event;
    console.log("------", value, label)
    setDthField({ ...getDthField, "dthprovider": value, "dthproviderkey": key, "dthproviderlabel": label });
  }

  const onChangeDthValue = (event) => {
    const { name, value } = event.target;
    setDthField({ ...getDthField, [name]: value });
  }

  const onChangePackageValue = (amount) => {
    setMobileField({ ...getMobileField, amount: amount }); // eslint-disable-next-line
    setIsModalOpen(false);
    setIsModalBestOffer(false);
    setMobileAmountReadOnly(true);
  } // eslint-disable-next-line

  const onChangeDthPackageValue = (amount) => {
    setDthField({ ...getDthField, dthamount: amount }); // eslint-disable-next-line
    setIsModalDthCustomerId(false);
    setIsModalDthBestOffer(false);
    setIsModalDthPlan(false);
    setDthAmountReadOnly(true);
  } // eslint-disable-next-line

  const showDthCustomerInfoModal = () => {
    if (dthFormValidate()) {
      const payload = {
        provider: getDthField.dthprovider,
        customerId: getDthField.dthcustomerid,
      }

      postRequest(DTH_CUSTOMER_INFO, payload, history).then((response) => {
        if (response.success === true) {
          setDthCustomerInfo(response.data[0]);
          setIsModalDthCustomerId(true);
        } else {
          setDthCustomerInfo([])
          toast.error(response.message);
        }
      }).catch(function (error) {
        // // console.log(error)
        // setLoading(false);
      })
    }
  };

  const showModalDthPlans = async () => {
    if (dthFormValidate()) {
      const payload = {
        provider: getDthField.dthproviderlabel,
        customerId: getDthField.dthcustomerid,
        mode: "plans"
      }
      postRequest(DTH_BEST_OFFER, payload, history).then((response) => {
        if (response.success === true) {
          const keys = Object.keys(response.data);
          const result = Object.values(response.data);
          setDthViewPlanKey(keys);
          setDthViewPlan(result);
          setIsModalDthPlan(true)
        } else {
          setDthViewPlanKey([])
          setDthViewPlan([])
          toast.error(response.message);
        }
      }).catch(function (error) {
        // console.log(error)
        // setLoading(false);
      })
    }
  }

  const showModalDthBestOffer = async () => {
    if (dthFormValidate()) {
      const payload = {
        provider: getDthField.dthproviderlabel,
        customerId: getDthField.dthcustomerid,
      }
      postRequest(DTH_BEST_OFFER, payload, history).then((response) => {
        if (response.success === true) {
          setDthBestOffer(response.data);
          setIsModalDthBestOffer(true);
        } else {
          setDthBestOffer([])
          toast.error(response.message);
        }
      }).catch(function (error) {
        // console.log(error)
        // setLoading(false);
      })
    }
  }

  const dthHeavyRefresh = async () => {
    if (dthFormValidate()) {
      const payload = {
        provider: getDthField.dthprovider, //.replace(" ",""),
        customerId: getDthField.dthcustomerid,
      }
      postRequest(DTH_HEAVY_REFRESH, payload, history).then((response) => {
        if (response.success === true) {
          toast.success(response.data.desc);
        } else {
          toast.error(response.message);
        }
      }).catch(function (error) {
        // // console.log(error)
        // setLoading(false);
      })
    }
  }

  return (
    <div>
      {/* <AppHeader /> */}
      <div className="content-wrapper pt-2">
        <div className="content-header my-0 py-0">
          <div className="container-fluid">
            <div className="row mb-0 align-items-center">
              <div className="col-sm-6">
                <h1 className='page-title text-blue font-merriweather'>Prepaid <span className='text-red'>Recharge</span></h1>
              </div>
            </div>
          </div>
        </div>
        <div className="content">
          <div className="container-fluid pt-2 pb-5">
            <div className="row">
              <div className="col-md-6">
                <div className='card rounded-0'>
                  <div className='card-body pt-0'>
                    <Tabs className="recharge-tabs" onSelect={(index, label) => onchangeTab(index, label)}>
                      <Tab eventKey={1} label={<div className='tabtext' title='Mobile Recharge'><span><img src='../images/mobile-logo-icon.svg' alt='' /></span>Mobile</div>}>
                        <div className='pt-4 '>
                          <h4 className='mb-3 fa-1x'>Mobile Recharge</h4>
                          <form method='post'>
                            <div className="form-group">
                              <div className="input-group">
                                <input type="text" name="mobile" ref={mobileInput} className={`normal-light form-control ${getMobileError && getMobileError.mobile ? "is-invalid" : ""}`} placeholder="Mobile Number" onKeyDown={(e) => isNumber(e)} onChange={(e) => onChangeValue(e)} maxLength={10} id="inputError" required value={(getMobileField && getMobileField.mobile ? getMobileField.mobile : null)} />
                              </div>
                              {getMobileError && getMobileError.mobile && <span className="error text-danger">The field is required</span>}
                            </div>
                            <div className="form-group">
                              <div className="input-group">
                                <Select options={getMobileOperatorData} className={`normal-light w-100 ${getMobileError && getMobileError.mobileoperator ? "is-invalid" : ""}`} classNamePrefix="select" placeholder="Select Operator" ref={mobileOperatorSelect} name='mobileoperator' onChange={(e) => onChangeMobileValueSelect(e)} />
                              </div>
                              {getMobileError && getMobileError.mobileoperator && <span className="error text-danger">The field is required</span>}
                            </div>
                            <div className="form-group">
                              <div className="input-group">
                                <select className={`form-control normal-light ${getMobileError && getMobileError.mobileoperator ? "is-invalid" : ""}`} ref={mobileStaterSelect} name='state' onChange={(e) => onChangeValue(e)}>
                                  <option value={""}>Select State</option>
                                  {getMobileOperatorState && getMobileOperatorState.map((element) => {
                                    return <option value={element.name}>{element.name}</option>
                                  })
                                  }
                                </select>
                              </div>
                              {getMobileError && getMobileError.state && <span className="error text-danger">The field is required</span>}
                            </div>
                            <div className="form-group">
                              <div className="position-relative">
                                <input type="text" className={`form-control normal-light pr-110 ${getMobileError && getMobileError.amount ? "is-invalid" : ""}`} ref={mobileAmountInput} placeholder="Enter Recharge Amount" name="amount" onKeyDown={(e) => isNumber(e)} onChange={(e) => onChangeValue(e)} maxLength={6} value={(getMobileField && getMobileField.amount ? getMobileField.amount : null)} readOnly={isMobileAmountReadOnly} />
                                <div className="inputgrouptext cursor-pointer" onClick={showModalViewPlan}>View Plans</div>
                              </div>
                              {getMobileError && getMobileError.amount && <span className="error text-danger">The field is required</span>}
                            </div>

                            <div className='row pt-2'>
                              <div className='col-6'> <button type="button" className="btn btn-danger mb-2 btn-block" onClick={() => showModalBestOffer()}>Best Offer’s</button></div>
                              <div className='col-6'> <button type="button" className="btn btn-primary mb-2 btn-block" onClick={(e) => processMobileRechargePaymentConfirm(e)}>Proceed to Recharge</button></div>
                            </div>
                          </form>
                        </div>
                      </Tab>
                      <Tab eventKey={2} label={<div className='tabtext' title='DTH Recharge'><span><img src='../images/dth-logo-icon.svg' alt='' /></span>DTH</div>}>
                        <div className='pt-4'>
                          <h4 className='mb-3 fa-1x'>DTH Recharge</h4>
                          <div className="form-group">
                            <div className="input-group">
                              <Select options={getDTHOperatorData} className={`normal-light w-100 ${getDthError && getDthError.dthprovider ? "is-invalid" : ""}`} classNamePrefix="select" placeholder="Select Your DTH Provider" ref={dthProviderSelect} name='dthprovider' onChange={(e) => onChangeDthValueSelect(e)} />
                            </div>
                            {getDthError && getDthError.dthprovider && <span className="error text-danger">The field is required</span>}
                          </div>
                          <div className="form-group">
                            <div className="input-group">
                              <input type="text" className={`form-control normal-light ${getDthError && getDthError.dthcustomerid ? "is-invalid" : ""}`} placeholder="Enter your customer ID" name='dthcustomerid' onChange={(e) => onChangeDthValue(e)} onKeyDown={(e) => isNumber(e)} maxLength={15} ref={dthCustomerIdInput} value={(getDthField && getDthField.dthcustomerid ? getDthField.dthcustomerid : null)} />
                              <div className="inputgrouptext cursor-pointer" onClick={(e) => showDthCustomerInfoModal(e)}>View Customer Info</div>
                            </div>
                            {getDthError && getDthError.dthcustomerid && <span className="error text-danger">The field is required</span>}
                          </div>

                          <div className="form-group">
                            <div className="position-relative">
                              <input type="text" className={`form-control normal-light pr-110 ${getDthError && getDthError.dthamount ? "is-invalid" : ""}`} placeholder="Enter Recharge Amount" name='dthamount' onChange={(e) => onChangeDthValue(e)} onKeyDown={(e) => isNumber(e)} maxLength={6} value={(getDthField && getDthField.dthamount ? getDthField.dthamount : null)} ref={dthAmountInput} readOnly={isDthAmountReadOnly} />
                              <div className='inputgrouptext cursor-pointer d-flex'>
                                <div className="" onClick={(e) => showModalDthBestOffer(e)}>Best Offer's</div>
                                <p className='mx-2'>|</p>
                                <div className="" onClick={(e) => showModalDthPlans(e)}>View Plans</div>
                              </div>
                            </div>
                            {getDthError && getDthError.dthamount && <span className="error text-danger">The field is required</span>}
                          </div>

                          <div className='row pt-2'>
                            <div className='col-6'> <button type="submit" className="btn btn-danger mb-2 btn-block" onClick={(e) => processDthRechargePaymentConfirm(e)}>Process To Recharge</button></div>
                            <div className='col-6'> <button type="submit" className="btn btn-primary mb-2 btn-block" onClick={() => dthHeavyRefresh()}>DTH Heavy Refresh</button></div>
                          </div>
                        </div>
                      </Tab>

                      <Tab eventKey={3} label={<div className='tabtext' title='Wallet Recharge'><span><img src='../images/wallet-logo-icon.svg' alt='' /></span>Wallet</div>}>
                        <div className='pt-4'>
                          <h4 className='mb-3 fa-1x'>Wallet Recharge</h4>
                          <div className="form-group">
                            <div className="input-group">
                              <input type="text" className="form-control normal-light" placeholder="Wallet / Mobile No" />
                            </div>
                          </div>
                          <div className="form-group">
                            <div className="input-group">
                              <input type="text" className="form-control normal-light" placeholder="Customer Name" />
                            </div>
                          </div>
                          <div className="form-group">
                            <div className="input-group">
                              <select className="form-control normal-light">
                                <option>Operator</option>
                              </select>
                            </div>
                          </div>

                          <div className="form-group">
                            <div className="position-relative">
                              <input type="text" className="form-control normal-light pr-110" placeholder="Enter Recharge Amount" />
                            </div>
                          </div>

                          <div className='row pt-2'>
                            <div className='col-6'> <button type="submit" className="btn btn-danger mb-2 btn-block">Cancel</button></div>
                            <div className='col-6'> <button type="submit" className="btn btn-primary mb-2 btn-block">Process</button></div>
                          </div>
                        </div>
                      </Tab>
                    </Tabs>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className='card rounded-0'>
                  <div className='card-body'>
                    <h3 className='text-blue text-center mb-3'>{tabName} <span className='text-red'>Offers</span></h3>
                    <div className='text-center'>
                      <img src='../images/offer.png' alt='img' />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer position="top-center" autoClose={5000} limit={2} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="colored" />
      </div>
      {/* <AppFooter /> */}
      {isModalOpen &&
        <Modal title={getMobileField.mobileoperator + ", " + getMobileField.state} width={1000} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
          {getMobileViewPlanKey.length > 0 &&
            <Tabs className="recharge-tabs" >
              {getMobileViewPlanKey.map((name, key) => {
                return (
                  <Tab eventKey={key} label={<div className='tabtext' title={name}>{name}</div>}>
                    <ul>
                      {getMobileViewPlan[key]?.map((element, index) => {
                        return <li key={index} onChange={() => onChangePackageValue(element.rs)}>
                          <input type="radio" name="package_amount" onChange={() => onChangePackageValue(element.rs)} />
                          <p><span>Rs</span> : {element.rs}</p>
                          <p><span>Desc</span> : {element.desc}</p>
                          <p><span>Validity</span> : {element.validity}</p>
                          <hr />
                        </li>
                      })
                      }
                    </ul>
                  </Tab>
                )
              })
              }
            </Tabs>
          }
        </Modal>}
      {isModalBestOffer &&
        <Modal title={getMobileField.mobileoperator + ", " + getMobileField.state + " Best Offer's"} width={1000} open={isModalBestOffer} onOk={handleOk} onCancel={handleCancel}>
          {getMobileBestOffer.length > 0 && getMobileBestOffer?.map((element, index) => {
            return <li key={index} onChange={() => onChangePackageValue(element.rs)}>
              <input type="radio" name="package_amount" onChange={() => onChangePackageValue(element.rs)} />
              <p><span>Rs</span> : {element.rs}</p>
              <p><span>Desc</span> : {element.desc}</p>
              <hr />
            </li>
          })
          }
        </Modal>}

      {isModalDthCustomerId && <Modal title={" DTH Customer Info"} width={500} open={isModalDthCustomerId} onOk={handleOk} onCancel={handleCancel} footer={null}>
        {getDthCustomerInfo ?
          <ul>
            <li><span>Customer Name</span> : {getDthCustomerInfo.customerName}</li>
            <li><span>Monthly Recharge</span> : {getDthCustomerInfo.MonthlyRecharge}</li>
            <li><span>status</span> : {getDthCustomerInfo.status}</li>
            <li><span>Next Recharge Date</span> : {getDthCustomerInfo.NextRechargeDate}</li>
            <li><span>plan Name</span> : {getDthCustomerInfo.planname}</li>
          </ul>
          : ""}
      </Modal>}

      {isModalDthBestOffer &&
        <Modal title={"DTH Best Offer's"} width={1000} open={isModalDthBestOffer} footer={null} onCancel={handleCancel} onOk={handleOk}>
          {getDthBestOffer.length > 0 && getDthBestOffer?.map((element, index) => {
            return <li key={index} onChange={() => onChangeDthPackageValue(element.rs)}>
              <input type="radio" name="dth_package_amount" onChange={() => onChangeDthPackageValue(element.rs)} />
              <p><span>Rs</span> : {element.rs}</p>
              <p><span>Desc</span> : {element.desc}</p>
              <hr />
            </li>
          })
          }
        </Modal>}

      {isModalDthPlan &&
        <Modal title={"DTH Plans"} width={1000} open={isModalDthPlan} onOk={handleOk} onCancel={handleCancel}>
          {getDthViewPlanKey.length > 0 &&
            <Tabs className="recharge-tabs" >
              {getDthViewPlanKey.map((name, key) => {
                return (
                  <Tab eventKey={key} label={<div className='tabtext' title={name}>{name}</div>}>
                    <ul>
                      {getDthViewPlan[key]?.map((element, index) => {
                        let AmountObject = Object.keys(element.rs).map((key, i) => {
                          return <><div className='d-flex'><input type="radio" name="package_amount" onChange={() => onChangeDthPackageValue(Object.values(element.rs)[i])} /><p className='mx-5'><span>Amount</span> : {Object.values(element.rs)[i]}</p>
                            <p className='mx-5'><span>Time</span> : {key}</p></div></>
                        })
                        return <li key={index}>
                          <p><span>Rs</span> : {AmountObject} </p>
                          <p><span>Desc</span> : {element.desc}</p>
                          <p><span>Plan Name</span> : {element.plan_name}</p>
                          <hr />
                        </li>
                      })
                      }
                    </ul>
                  </Tab>
                )
              })
              }
            </Tabs>
          }
        </Modal>}
    </div>

  )
}

export default MobileRecharge