import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import { FORGET_MPIN_NUMBER, SAVE_MPIN_NUMBER } from '../Utils/appConstants';
import { postRequest } from '../Utils/AppApi';
import { useHistory } from "react-router-dom";
import { toast } from 'react-toastify';
import { isNumber } from '../Utils/Common';

function ForgetMpin(props) {

    const history = useHistory();

    const [isModalCheckAccount, setModalCheckAccount] = useState(false);
    const [getDataField, setDataField] = useState({});
    const [isUserId, setuserId] = useState(null);

    const handleHideMpinModal = () => {
        props.setMpinChangeModal(false);
    };

    const onChangeValue = (event) => {
        const { name, value } = event.target;
        setDataField({ ...getDataField, [name]: value });
    }

    const checkisUserId = async () => {
        if (!isModalCheckAccount) {
            const payload = { username: getDataField.userId, pancard: getDataField.pancard }
            const response = await postRequest(FORGET_MPIN_NUMBER, payload, history);
            if (response.success === true) {
                setuserId(response.token);
                setModalCheckAccount(true);
                toast.success(response.message);
            } else {
                toast.error(response.message);
            }
        } else {
            if (getDataField && getDataField.isConfirmMpinNumber === getDataField.isMpinNumber) {
                const payload = { token: isUserId, mpin: getDataField.isConfirmMpinNumber }
                const response = await postRequest(SAVE_MPIN_NUMBER, payload, history);
                if (response.success === true) {
                    setModalCheckAccount(false);
                    handleHideMpinModal();
                    toast.success(response.message);
                } else {
                    toast.error(response.message);
                }
            } else {
                toast.error("New Mpin and Confirm Mpin did not match");
            }
        }
    }

    useEffect(() => {
        setDataField({ ...getDataField, userId: props.username})
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            <div className='row'>
                <div className="col-md-12 col-sm-12">
                    {(!isModalCheckAccount) ? <><div className="form-group">
                        <input type="text" name="userId" className="form-control" placeholder="Enter UserID" onChange={(e) => onChangeValue(e)} value={getDataField && getDataField.userId} />
                    </div>
                        <div className="form-group">
                            <input type="text" name="pancard" className="form-control" placeholder="Enter Pan Number" onChange={(e) => onChangeValue(e)} value={getDataField && getDataField.pancard} />
                        </div></> :
                        <><div className='col-md-12 form-group'>
                            <label>New Mpin: </label>
                            <input type={'password'} name="isMpinNumber" className="form-group form-control" onKeyDown={isNumber} onChange={(e) => onChangeValue(e)} maxLength={4} value={getDataField && getDataField.isMpinNumber} />
                        </div>
                            <div className='col-md-12 form-group'>
                                <label>Confirm Mpin: </label>
                                <input type={'password'} name="isConfirmMpinNumber" className="form-group form-control" onChange={(e) => onChangeValue(e)} maxLength={4} value={getDataField && getDataField.isConfirmMpinNumber} onKeyDown={isNumber} />
                            </div></>}
                </div>
                <div className="row form-group text-right col-md-12">
                    <div className="col-md-6">
                        <Button className='btn btn-default col-md-12' onClick={handleHideMpinModal}>Cancel</Button>
                    </div>
                    <div className="col-md-6">
                        <Button className='btn btn-primary bg-primary ml-2 col-md-12' onClick={checkisUserId}>Confirm</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ForgetMpin