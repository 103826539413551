import React, { useEffect, useState, useRef } from 'react';
// import { APP_KEY, API_URL } from './../config';
import { addServiceAndOparetor } from './../../../config/reportName';
import DataTable from 'react-data-table-component';
import { isNumber } from './../../../Utils/Common';
// import { Tabs, Tab } from 'react-bootstrap-tabs';
import { GET_All_OPERATOR_LIST } from './../../../Utils/appConstants';
import { getRequest, postRequest, putRequest, deleteRequestNewApi } from './../../../Utils/AppApi';
// import AppHeader from './../../Header/Header';
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
// import AppFooter from './../../Footer/Footer';
// import Moment from 'react-moment';
// import moment from 'moment';
import { Popconfirm, Select } from 'antd';
const text = 'Are you sure to delete this data?';
function ServiceAndOperator(props) {

  const history = useHistory();  // eslint-disable-line react-hooks/exhaustive-deps
  // const csvLink = useRef()
  const limit = 10;
  // const dateFormat = 'DD-MM-YYYY';
  // const [search, setSearch] = useState(null);
  // const [endDate, setEndDate] = useState(null);
  // const [startDate, setStartDate] = useState(null);
  const [getDataField, setDataField] = useState([]); // eslint-disable-line react-hooks/exhaustive-deps
  const [getDataError, setDataError] = useState({}); // eslint-disable-line react-hooks/exhaustive-deps
  const [getData, setData] = useState([]);
  const [getBankModes, setBankModes] = useState([]);
  const [getTotalRecord, setTotalRecord] = useState(0);
  const [page, setPage] = useState(1);
  const [isEdit, setIsEdit] = useState(false);
  const [isOperator, setOperator] = useState("")
  const nameInput = useRef(null);
  const accountNumberInput = useRef(null);
  const branchAddressInput = useRef(null);
  const bankNameInput = useRef(null);
  const ifscCodeInput = useRef(null);
  const remarkInput = useRef(null);  // eslint-disable-line react-hooks/exhaustive-deps
  // const customerMobileInput = useRef(null); 
  // const mobile = useFormInput('');

  const clearFormField = () => {
    nameInput.current.value = "";
    accountNumberInput.current.value = "";
    branchAddressInput.current.value = "";
    bankNameInput.current.value = "";
    ifscCodeInput.current.value = "";
    remarkInput.current.value = "";
    setDataField([]);
    setDataError({});
    setIsEdit(false);
  }

  const columns = [{
    name: 'Name',
    selector: row => row.name,
  }, {
    name: 'Code',
    selector: row => row.code,
  }, {
    name: 'Status',
    cell: row => <>{(row.status) ? <div className="text-success pointer" onClick={(event) => handleChangeStatus(event, row._id, "false")}><u><b>Active</b></u></div> : <div className="text-danger pointer" onClick={(event) => handleChangeStatus(event, row._id, "true")}>Deactive</div>}</>,
  }, {
    name: 'Action',
    cell: row => <><div className='icon-border border border-primary text-primary pointer' onClick={(event) => handleEditView(event, row)}><i className="fa fa-edit" aria-hidden="true"></i></div><Popconfirm placement="left" title={text} onConfirm={(event) => handleDelete(event, row._id)} okText="Yes" cancelText="No"><div className='icon-border border border-danger text-danger pointer'><i className="fa fa-trash" aria-hidden="true"></i></div></Popconfirm></>,
  }] // eslint-disable-line react-hooks/exhaustive-deps


  const objectToQueryString = (obj) => {
    var str = [];
    for (var p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    return str.join("&");
  } // eslint-disable-line react-hooks/exhaustive-deps

  const params = { page: page, limit: limit };

  const getDataReports = (value) => {
    console.log("value ---------", value)
    if(value) {
      params.operator = value;
    }
    const queryString = objectToQueryString(params);
    getRequest(`${GET_All_OPERATOR_LIST}?${queryString}`, history).then((response) => {
      if (response.success === true) {
        setData(response.data);
        setTotalRecord(response.data.totalDocs);
        setPage(response.data.page);
      } else {
        setData([]);
        setTotalRecord(0);
        setPage(1);
      }
    }).catch(function (error) {
      console.log(error)
      // setLoading(false);
    })
  } // eslint-disable-line react-hooks/exhaustive-deps

  const handlePageChange = page => {
    setPage(page);
  }; // eslint-disable-line react-hooks/exhaustive-deps

  const onChangeValue = (event) => {
    const { name, value } = event.target;
    setDataField({ ...getDataField, [name]: value });
  } // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (value) => {
    setDataField({ ...getDataField, modes: value });
  };

  const FormValidate = () => {
    let formValid = true;
    let formObject = {};
    setDataError({})
    if (getDataField && getDataField.length === 0) {
      formValid = false;
    }
    let array = [
      "name", "bankName", "accountNumber", "branchAddress", "remark", "ifscCode","modes",
    ];

    array.forEach(element => {
      if (getDataField[element] === "" || typeof getDataField[element] === "undefined") {
        formObject[element] = true;
        formValid = false;
      }
    });

    setDataError(formObject);
    return formValid;
  } // eslint-disable-line react-hooks/exhaustive-deps

  const handleEditView = (event, data) => {
    data.modes = data.modes.split(',')
    console.log("data -------------", data)
    setDataField(data)
    setIsEdit(true);
  }

  const handleEdit = () => {
    if (FormValidate(true)) {
      const payload = {
        name: getDataField.name,
        bankName: getDataField.bankName,
        accountNumber: getDataField.accountNumber,
        ifscCode: getDataField.ifscCode,
        branchAddress: getDataField.branchAddress,
        modes: getDataField.modes.toString(),
        remark: getDataField.remark,
      }
      putRequest(`${GET_All_OPERATOR_LIST}/${getDataField._id}`, payload, history).then((response) => {
        if (response.success === true) {
          clearFormField();
          getDataReports();
          toast.success(response.message);
        } else {
          toast.error(response.message);
        }
      }).catch(function (error) {
        // console.log(error)
        // setLoading(false);
      })
    }
  } // eslint-disable-line react-hooks/exhaustive-deps

  const handleChangeStatus = (event, id, value) => {
    let payload = { status: value };
    postRequest(`${GET_All_OPERATOR_LIST}/${id}`, payload, history).then((response) => {
      // console.log("response -------", response)
      if (response.success === true) {
        setDataField([]);
        setDataError({});
        clearFormField();
        getDataReports();
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
    }).catch(function (error) {
      console.log(error)
    })
  } // eslint-disable-line react-hooks/exhaustive-deps

  const handleDelete = (event, id) => {
    let payload = { removed: false };
    deleteRequestNewApi(`${GET_All_OPERATOR_LIST}/${id}`, payload, history).then((response) => {
      // console.log("response -------", response)
      if (response.success === true) {
        setDataField([]);
        setDataError({});
        clearFormField();
        getDataReports();
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
    }).catch(function (error) {
      console.log(error)
    })
  } // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = () => {
    if (FormValidate(true)) {
      const payload = {
        name: getDataField.name,
        bankName: getDataField.bankName,
        accountNumber: getDataField.accountNumber,
        ifscCode: getDataField.ifscCode,
        branchAddress: getDataField.branchAddress,
        modes: getDataField.modes.toString(),
        remark: getDataField.remark,
      }
      postRequest(GET_All_OPERATOR_LIST, payload, history).then((response) => {
        if (response.success === true) {
          setDataField([]);
          setDataError({});
          clearFormField();
          getDataReports();
          toast.success(response.message);
        } else {
          toast.error(response.message);
        }
      }).catch(function (error) {
        // console.log(error)
        // setLoading(false);
      })
    }
  } // eslint-disable-line react-hooks/exhaustive-deps

  const onChangeType = (value) => {
    // console.log("value -----------" , value)
    setOperator(value);
    getDataReports(value);
  }

  useEffect(() => {
    getDataReports();
    setBankModes(addServiceAndOparetor.map((element) => {
      return { label: element.value, value: element.key }
    }))
  }, [page]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      {/* <AppHeader /> */}
      <div className="content-wrapper pt-2">
        <div className="content-header my-0 py-0">
          <div className="container-fluid">
            <div className="row mb-0 align-items-center">
              <div className="col-sm-6">
                <h1 className='page-title text-blue font-merriweather'>Add Service & <span className='text-red'>Operators</span></h1>
              </div>
            </div>
          </div>
        </div>
        <div className="content">
          <div className="container-fluid pt-2 pb-5">
            <div className="row">
              <div className="col-md-12">
                <div className='card rounded-0'>
                  <div className='card-body'>
                    <div>
                      <div className='row'>
                        <div className='col-sm-6 col-md-4 col-lg-3'>
                          <div className="form-group">
                            <label>Service Type</label>
                            <Select mode="multiple" allowClear 
                              className={`form-control normal-light pr-110 ${getDataError && getDataError.modes ? "is-invalid" : ""}`}
                              placeholder="Service Type*"
                              bordered={false}
                              value={getDataField.modes}
                              onChange={handleChange}
                              options={getBankModes}
                            />
                            {getDataError && getDataError.modes && <span className="error text-danger">The field is required</span>}
                          </div>
                        </div>
                        <div className='col-sm-6 col-md-4 col-lg-4'>
                          <div className="form-group">
                            <label>Name</label>
                            <input type="text" className={`form-control normal-light pr-110 ${getDataError && getDataError.name ? "is-invalid" : ""}`} name="name" ref={nameInput} maxLength={50} onChange={(e) => onChangeValue(e)} placeholder="Name*" value={getDataField && getDataField.name} />
                            {getDataError && getDataError.name && <span className="error text-danger">The field is required</span>}
                          </div>
                        </div>
                        <div className='col-sm-6 col-md-4 col-lg-3'>
                          <div className="form-group">
                            <label>Code</label>
                            <input type="text" className={`form-control normal-light pr-110 ${getDataError && getDataError.accountNumber ? "is-invalid" : ""}`} name="accountNumber" ref={accountNumberInput} maxLength={20} onKeyDown={isNumber} onChange={(e) => onChangeValue(e)} placeholder="Code*" value={getDataField && getDataField.accountNumber} />
                            {getDataError && getDataError.accountNumber && <span className="error text-danger">The field is required</span>}
                          </div>
                        </div>
                        <div className='col-sm-6 col-md-4 col-lg-2'>
                          <label className='d-none d-lg-block'>&nbsp;</label>
                          <div className="form-group text-lg-center">
                            {isEdit ?
                              <><button type="submit" className="btn btn-primary rounded-pill pl-4 pr-4" onClick={() => handleEdit()}>Update</button>

                                <button type="clear" className="btn btn-default rounded-pill pl-4 pr-4" onClick={() => clearFormField()}>Cancel</button></>
                              :
                              <button type="submit" className="btn btn-primary rounded-pill pl-4 pr-4" onClick={() => handleSubmit()}>Submit</button>}
                          </div>
                        </div>
                      </div>
                    </div>

                  <div className='d-flex'>
                    <div className="content-header my-0 px-0">
                      <h1 className='page-title text-blue font-merriweather mb-3'>Service & Operator <span className='text-red'>List</span></h1>
                    </div>
                    <div className="align-items-center input-group col-lg-2">
                        <Select options={getBankModes} className={`normal-light w-100`} classNamePrefix="select" placeholder="Select a Type" name='mode' onChange={(e) => onChangeType(e)} value={isOperator} />
                    </div>
                  </div>

                    <div className='history-table'>
                      <div className="container-fluid pt-2 pb-5">
                        <div className="col-md-12">
                          <div className="box-body">
                            <DataTable
                              title={""}
                              columns={columns}
                              data={getData.docs}
                              highlightOnHover
                              pagination
                              paginationServer
                              paginationTotalRows={getTotalRecord}
                              onChangeRowsPerPage={getDataReports}
                              onChangePage={handlePageChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer position="top-center" autoClose={5000} limit={2} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="colored"/>
      </div>
      {/* <AppFooter /> */}
    </div>
  )
}

export default ServiceAndOperator;