import React, { useEffect, useState } from 'react';
// import { APP_KEY, API_URL } from './../config';
// import { getUser } from './../../Utils/Common';
import { useHistory } from "react-router-dom";
import { getTopBarMenu } from './../../Utils/Menu';
import 'bootstrap-daterangepicker/daterangepicker.css';

// const userData = getUser();
const TopMenu = getTopBarMenu();

function StaffDashboard(props) {
    const history = useHistory();

    const [menu, setMenu] = useState(TopMenu);

    useEffect(() => {
        setMenu(TopMenu);
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            <div className="content pt-5 pb-5">
                <div className="container-fluid">
                    <div className="card card-body">
                        {
                            <div className="row">
                                {menu && menu[0].submenu && menu[0].submenu.map((element) => {
                                    return (
                                        <div className="col-sm-6 col-md-4 col-lg-3" onClick={() => history.push({ pathname: element.route })}>
                                            <div className='dbox-outer'>
                                                <div className='dbox-cols'>
                                                    {/* <div className='pr-2 dbox-icon'></div> */}
                                                    <div className='dbox-text2'>
                                                        {element.text}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default StaffDashboard;