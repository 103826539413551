import React, { useEffect } from 'react';
// import "bootstrap/dist/css/bootstrap.css";
// import "react-bootstrap-carousel/dist/react-bootstrap-carousel.css";
// import moment from 'moment';
import './../../webcss/default.css';
import './../../webcss/styles.css';
import Header from './header';
import Footer from './footer';

function App() {

    useEffect(() => {
        // setReportMode(Apps);
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            <div>
                <Header />
                <div>
                    <div className="notice-section notice-section-sm border-top red">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-10 col-sm-10 col-12">
                                    <div className="mt-5">
                                        <h6 className="fontwhite">Become a SR PAY Private Limited Franchise &amp; Grow your income 30% to 50% per month
                                        </h6>
                                    </div>
                                </div>
                                <div className="col-md-2 col-sm-2 col-12">
                                    <div className="right-holder-md">
                                        <a href="/joinus" className="primary-button button-lg mt-15-xs bluebtn">Join Now</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="page-title-section">
                        <div className="container">
                            <h1>Refund <span className="white"> Policy</span></h1>
                        </div>
                    </div>
                    <div className>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 col-sm-12 col-12">
                                    <div className="pl-30-md mt-40">
                                        <div className="text-content-big mt-20">
                                            <p>"Refunds”&nbsp;shall mean the refund as provided for in the Terms &amp; Conditions and "Company/We/Us/we/us/our” mentioned herein shall mean SR PAY Private Limited.
                                                Refunds are reversal transactions wherein complete or partial money is moved back to Customer’s source account/wallet (account/wallet from which actual payment was made) with some exceptional cases as mentioned below and a refund can only be created for a successful or settled transaction only as per the refund policy of the third-party merchant. A refund arises in the following scenarios:</p>
                                            <h5><b>Refund can be initiated by the Agent in the following scenarios:</b></h5>
                                            <br />
                                            <p>
                                                <ul className="details">
                                                    <ol>Customer has changed his mind about the consumption of product pre/post order delivery.</ol>
                                                    <ol>Recharge or bill payment is not successful and money is debited or deducted from the Agent’s/Customer’s wallet or bank account.</ol>
                                                    <ol>Product/service is out of stock.</ol>
                                                    <ol>Mismatch in the transaction status between the Company and Agent wherein the transaction is in failed state at Agent's end but is successful at Company's end.</ol>
                                                    <ol>"SR PAY Private Limited.” will process the refund as and when cancellation request/claim is made from the Customer/Travel Agent/Corporate Company through My Account section or an email to the mentioned Email Id in the section "Contact Us”.</ol>
                                                    <ol>Refund against the cancellation of air tickets will be credited directly in customer’s mode of payment after receiving the refund amount from Airlines/Hotel/Transporter and any other Suppliers.</ol>
                                                    <ol>"We” will process refund within 72 hours only after receiving it from the concerned Airline Operators/Hotels/Transporters/Other Suppliers. Due to Banking Procedures, Refund in credit cards may take around 72 hours while it may take 7 days in case of Net Banking transactions. </ol>
                                                    <ol>"We” shall not be liable to pay any refund whatsoever in cases where the Airlines/Hotels/Transporters and any other Suppliers closes its operations or declares itself as insolvent. The customers or clients or agents shall not hold "us” liable to pay the refunds as assured at the time of booking of tickets in cases where the Airlines/Hotels/Transporters and any other Suppliers closes its operations or declares itself as insolvent.</ol>
                                                    <ol>On the basis of surety given by Airlines/Hotels/Transporters and any other Suppliers "we” may sometimes refund amount to the customer but at the same time SR PAY Private Limited. reserves the right to recover the refunded amount on event of Airlines/Hotels/Transporters and any other Suppliers getting shut down/non-operational/bankrupt or declares itself as insolvent .</ol>
                                                </ul>
                                            </p>
                                            <p>The Customer/Agent can only initiate complete refund to the Customer’s/Agent’s source account/wallet only once meeting the above mentioned criteria.</p>
                                            <h5><b> The process followed for Refunds is as given under:</b></h5>
                                            <br />
                                            <p>
                                                <ul className="details">
                                                    <ol>Agent is to start the process of refund.</ol>
                                                    <ol>The "Company” validates the refund against the original order. These are basic validations like a cumulative refund on a particular order i.e. order value, the order is not more than a year old etc or as per the Agent refund policy.</ol>
                                                    <ol>Sync confirmation after validation of refund is provided in response to the request.</ol>
                                                    <ol>Once the refund is validated at "our” end, the "Company” deducts the refund amount from the Agent’s pending settlement amount.</ol>
                                                    <ol>The "Company” initiates the refund to the source Bank Account. Apart from Net Banking refunds as it is instant in nature most of the Net Banking refunds are file-based and hence, are sent to the Bank on the next working day.</ol>
                                                    <ol>Once the Bank accepts the refund, the Company sends the message ‘Refund successfully initiated’ to the Agent/Customer. This depicts that a refund has been initiated to the Agent’s/Customer’s source account or wallet.</ol>
                                                    <ol>Bank credits the Customer's/Agent’s source account with the refund amount. </ol>
                                                    <ol>There are some exceptional cases where a refund is not processed in the Customer’s/Agent’s source account/wallet when source account/wallet is closed, wallet limit is exhausted, customer requested to get a refund in a different account or when the order is old and hence automatic refund couldn’t be processed in the source account/wallet.</ol>
                                                </ul>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    )
}

export default App