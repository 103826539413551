import React, { useEffect } from 'react';

function Home() {

    useEffect(() => {
        // setReportMode(Homes);
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            <footer>
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 col-sm-6 col-12">
                            <div className="mt-25">
                                <img src="img/logos/logo-footer.png" alt="footer-logo" />
                                <p className="mt-25">At SR PAY Private Limited we are committed to give Digital bridge to urban &amp; rural India by providing basic banking &amp; financial services to everyone – securely and instantly, in a hassle-free manner.</p>
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-6 col-12">
                            <h3>Quick Links</h3>
                            <ul className="footer-list">
                                <li><a href="/">Home</a>
                                </li>
                                <li><a href="/aboutus">About Us</a>
                                </li>
                                <li><a href="/refund-policy">Refund Policy</a>
                                </li>
                                <li><a href="/services">Services</a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-2 col-sm-6 col-12">
                            <h3>Quick Links</h3>
                            <ul className="footer-list">
                                <li><a href="/joinus">Join Us</a>
                                </li>
                                <li><a href="/contact-us">Contact Us</a>
                                </li>
                                <li><a href="/term-condition">Terms &amp; Conditions</a>
                                </li>
                                <li><a href="/privacy-policy">Privacy Policy</a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-3 col-sm-6 col-12">
                            <h3>Follow us</h3>
                            <div className="mt-25">
                                <div className="footer-social-icons mt-25">
                                    <ul>
                                        <li><a href="/#"><i className="fa fa-facebook-square" /></a>
                                        </li>
                                        <li><a href="/#"><i className="fa fa-instagram" /></a>
                                        </li>
                                        <li><a href="/#"><i className="fa fa-pinterest" /></a>
                                        </li>
                                        <li><a href="/#"><i className="fa fa-skype" /></a>
                                        </li>
                                        <li><a href="/#"><i className="fa fa-youtube" /></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer-bar">
                        <p><span className="primary-color">SR PAY Private Limited</span> © 2022. All Rights Reserved.</p>
                    </div>
                </div>
            </footer>
        </div>
    )
}

export default Home