import React, { useEffect, useState, useRef } from 'react';
// import { isNumber, getTransactionId, getParentPageTitleLable } from './../Utils/Common';
import { POST_EKO_FIND_BANK_DETAIL } from './../Utils/appConstants';
import { postRequest } from './../Utils/AppApi';
// import AppHeader from './Header/Header';
import { useHistory } from "react-router-dom";
// import { ToastContainer, toast } from 'react-toastify';
// import AfterSave from './../container/afterpayment';
// import AppFooter from './Footer/Footer';

function FindBank(props) {
    const history = useHistory();
    const [getDataField, setDataField] = useState([]); // eslint-disable-line react-hooks/exhaustive-deps
    const [getDataError, setDataError] = useState({}); // eslint-disable-line react-hooks/exhaustive-deps
    const [isData, setData] = useState({}); // eslint-disable-line react-hooks/exhaustive-deps

    const codeInput = useRef(null);
    const ifscInput = useRef(null);

    const FormValidate = () => {
        let formValid = true;
        let formObject = {};
        setDataError({})
        if (getDataField && getDataField.length === 0) {
            formValid = false;
        }
        let array = ["code","ifsc"];

        array.forEach(element => {
            if (getDataField[element] === "" || typeof getDataField[element] === "undefined") {
                // console.log("element -------", element)
                formObject[element] = true;
                formValid = false;
            }
        });

        setDataError(formObject);
        return formValid;
    }

    const handleSubmit = () => {
        if (FormValidate()) {
            const payload = {
                code: getDataField.code,
                ifsc: getDataField.ifsc,
            }
            postRequest(POST_EKO_FIND_BANK_DETAIL, payload, history).then((response) => {
                if (response.success === true) {
                    setData(response.data);
                    //   toast.success(response.message);
                } else {
                    //   toast.error(response.message);
                }
            }).catch(function (error) {
                // console.log(error)
                // setLoading(false);
            })
        }
    } // eslint-disable-line react-hooks/exhaustive-deps

    const onChangeValue = (event) => {
        const { name, value } = event.target;
        setDataField({ ...getDataField, [name]: value });
    }

    useEffect(() => {
        // getParentProfile();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    // useEffect(() => {
    // }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            <div className="content-wrapper pt-2">
                <div className="content">
                    <div className="container-fluid pt-0 pb-5">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="content-header my-0 py-0">
                                    <div className="row mb-4 align-items-center">
                                        <div className="col-sm-12">
                                            <h1 className='page-title text-blue font-merriweather'>Find <span className='text-red'>Bank</span></h1>
                                        </div>
                                    </div>
                                </div>
                                <div className='card rounded-0'>
                                    <div className='card-body'>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Code</label>
                                                    <input type="text" className={`form-control normal-light`} name="code" ref={codeInput} maxLength={30} onChange={(e) => onChangeValue(e)} placeholder="Short Code*" value={getDataField && getDataField.code} />
                                                    {getDataError && getDataError.code && <span className="error text-danger">The field is required</span>}
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>IFSC Code</label>
                                                    <input type="text" className={`form-control normal-light`} name="ifsc" ref={ifscInput} maxLength={30} onChange={(e) => onChangeValue(e)} placeholder="IFSC Code*" value={getDataField && getDataField.ifsc} />
                                                    {getDataError && getDataError.ifsc && <span className="error text-danger">The field is required</span>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            {isData && <>
                                                <ol className='list-group list-group-horizontal-sm ml-5'>
                                                    <li className="list-group-item"><strong>bank_id</strong> : {isData.bank_id}</li>
                                                    <li className="list-group-item"><strong>name</strong> : {isData.bank}</li>
                                                    <li className="list-group-item"><strong>available_channels</strong> : {isData.available_channels}</li>
                                                    <li className="list-group-item"><strong>isverificationavailable</strong> : {(isData.isverificationavailable) ? "On" : "Off"}</li>
                                                    <li className="list-group-item"><strong>ifsc_status</strong> : {(isData.ifsc_status) ? " Enabled    " : " Disabled    "}</li>
                                                    <li className="list-group-item"><strong>code</strong> : {isData.code}</li>
                                                    <li className="list-group-item"><strong>ifsc</strong> : {isData.ifsc}</li>
                                                    <li className="list-group-item"><strong>branch</strong> : {isData.branch}</li>
                                                </ol>
                                            </>}
                                        </div>
                                        <div className="card-footer text-right">
                                            {/* <button type="clear" className="btn btn-default mr-1 rounded-pill pl-4 pr-4 border border-danger" onClick={() => clearFormField()}>Cancel</button> */}
                                            <button type="submit" className="btn btn-default rounded-pill pl-4 pr-4 border border-danger" onClick={() => handleSubmit()}>Search</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FindBank;