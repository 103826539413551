import React, { useEffect, useState } from 'react';
// import CsvDownloader from 'react-csv-downloader';
import { Modal, Button } from 'antd';
import { ToWords } from 'to-words';
// import { CSVLink } from 'react-csv';
import DataTable from 'react-data-table-component';
import 'antd/dist/antd.css';
import { useHistory } from "react-router-dom";
import { ADMIN_ALL_MAKER_FUND_REQUEST, ADMIN_FUND_REQUEST_STATUS_CHANGE_REQUEST } from '../../../Utils/appConstants';
import { getRequest, postRequest } from '../../../Utils/AppApi';
import { ToastContainer, toast } from 'react-toastify';
import ImageViewer from 'react-simple-image-viewer';
import { FundRequestValue, INRFormat } from '../../../Utils/Common';
import { ArrowRightOutlined } from '@ant-design/icons';
import { getUTCToLocalDateFormat } from '../../../Utils/function';
import PropagateLoader from "react-spinners/PropagateLoader";
// import Moment from 'react-moment';

function FundMakerRequest(props) {
    // console.log("props -------",props.search)
    // const user = getUser();
    const history = useHistory();
    // const csvLink = useRef()
    const toWords = new ToWords();
    const limit = 10;
    const getMode = props.mode ? props.mode : "";
    const startDate = props.startDate ? props.startDate : "";
    const endDate = props.endDate ? props.endDate : "";
    const search = props.search ? props.search : "";

    const [getData, setData] = useState([]);
    // const [getCsvData, setCsvData] = useState([]);
    const [getTotalRecord, setTotalRecord] = useState(0);
    const [page, setPage] = useState(1);
    const [isShowFtrInfo, setShowFtrInfo] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [isSelectedRecord, setIsSelectedRecord] = useState([]);
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const [viewImage, setViewImage] = useState("");
    const [transactionId, setTransactionId] = useState("");
    const [transactionIdError, setTransactionIdError] = useState(false);

    const columns = [{
        name: 'User Id',
        selector: row => row.userData.username + ' (' + row.roles.code + ')',
    }, {
        name: 'Name',
        selector: row => row.userData.name,
    }, {
        name: 'FTR No.',
        selector: row => row.FTRReferenceNumber,
    }, {
        name: 'Amount',
        selector: row => INRFormat(row.amount),
    }, {
        name: 'Bank Name',
        selector: row => row.bankdetails.bankName,
    }, {
        name: 'Location',
        selector: row => row.location,
    }, {
        name: 'Status',
        cell: row => <>{FundRequestValue(row.status)}</>,
    }, {
        name: 'DP Date',
        cell: row => getUTCToLocalDateFormat(row.paymentDepositDate),
    }, {
        name: 'Action',
        cell: row => <><div className='icon-border border border-primary text-primary pointer' title='View Details'><i className="fa fa-eye " onClick={(event) => handleRequestDetail(event, row)} aria-hidden="true"></i></div></>,
    }]

    const handleRequestDetail = (event, data) => {
        setShowFtrInfo(true)
        setIsSelectedRecord(data)
        // console.log("data ---------------", data)
    }

    const handleSave = (status) => {
        if (transactionId) {
            setLoading(true)
            const payload = {
                tranReasonId: transactionId,
                amount: isSelectedRecord.amount,
                devicetype: "W",
                status: status
            }
            handleSubmit(payload);
        } else {
            setTransactionIdError(true)
        }
    }

    const handleSubmit = (data) => {
        setLoading(true)
        // const queryString = objectToQueryString(payload);
        postRequest(`${ADMIN_FUND_REQUEST_STATUS_CHANGE_REQUEST + "/" + isSelectedRecord._id}`, data, history).then((response) => {
            if (response.success === true) {
                getDataReports()
                toast.success(response.message);
                handleHideModal();
            } else {
                toast.error(response.message);
            }
            setLoading(false)
        }).catch(function (error) {
            // console.log(error)
            setLoading(false);
            handleHideModal();
        })
        // console.log("accept ----",value)
    }

    const handleHideModal = () => {
        setShowFtrInfo(false);
    };

    const objectToQueryString = (obj) => {
        var str = [];
        for (var p in obj)
            if (obj.hasOwnProperty(p)) {
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
        return str.join("&");
    }

    const params = { page: page, limit: limit };

    const getDataReports = () => {
        if (startDate && endDate) {
            params.startDate = startDate ? startDate : "";
            params.endDate = endDate ? endDate : "";
        }

        params.roleId = getMode;

        if (search) {
            params.search = search ? search : "";
        }

        const queryString = objectToQueryString(params);
        getRequest(`${ADMIN_ALL_MAKER_FUND_REQUEST}?${queryString}`, history).then((response) => {
            if (response.success === true) {
                setData(response.data);
                setTotalRecord(response.data.totalDocs);
                setPage(response.data.page);
            } else {
                setData([]);
                setTotalRecord(0);
                setPage(1);
            }
        }).catch(function (error) {
            console.log(error)
            // setLoading(false);
        })
    } // eslint-disable-line react-hooks/exhaustive-deps

    const handlePageChange = page => {
        setPage(page);
    };

    const onChangeValue = (event) => {
        const { value } = event.target;
        // console.log("name -----------", name)
        // console.log("value -----------", value)
        setTransactionIdError(false)
        setTransactionId(value);
    }

    useEffect(() => {
        getDataReports();
    }, [page, props.handleSearch]) // eslint-disable-line react-hooks/exhaustive-deps

    // useEffect(() => {
    //     getCSVDataList();
    //     setIsDownloadCsv(isDownloadCsv ? true : false);
    // }, [isDownloadCsv]) // eslint-disable-line react-hooks/exhaustive-deps

    // const openImageViewer = useCallback((image) => {
    //     setViewImage(image)
    //     setShowFtrInfo(false);
    //     setIsViewerOpen(true);
    // }, []);

    const closeImageViewer = () => {
        setViewImage("");
        setShowFtrInfo(true);
        setIsViewerOpen(false);
    };

    if (isLoading) {
        return <div className="content text-center" style={{ margin: "250px 0" }}>
            <PropagateLoader color="#5851D2" loading={isLoading} size={25} />
        </div>
    }

    return (
        <div>
            <div className="container-fluid pt-2 pb-5">
                <div className="col-md-12">
                    {/* <div className='float-sm-right d-flex mb-1'>
                        <button type="button" className="btn btn-primary mr-3" onClick={() => getCSVDataList()} ><i className="fa fa-file-excel"></i> Export</button>
                        <CSVLink
                            data={getCsvData}
                            filename='dth-recharge-report.csv'
                            className='hidden'
                            ref={csvLink}
                            target='_blank'
                        />
                        <button type="button" className="btn btn-danger">
                            <i className="fa fa-file-pdf"></i> Export to PDF
                        </button>
                    </div> */}
                    <div className="box-body">
                        <DataTable
                            // title={getMode}
                            columns={columns}
                            data={getData.docs}
                            highlightOnHover
                            pagination
                            paginationServer
                            paginationTotalRows={getTotalRecord}
                            onChangeRowsPerPage={getDataReports}
                            onChangePage={handlePageChange}
                        />
                    </div>
                </div>
            </div>
            <ToastContainer position="top-center" autoClose={5000} limit={2} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="colored" />
            {isViewerOpen && (
                <ImageViewer
                    src={[viewImage]}
                    currentIndex={0}
                    disableScroll={false}
                    closeOnClickOutside={true}
                    onClose={closeImageViewer}
                />
            )}

            {isShowFtrInfo && <Modal title={isSelectedRecord.FTRReferenceNumber} width={1000} open={isShowFtrInfo} onCancel={handleHideModal} footer={false}>
                {isSelectedRecord ?
                    <>
                        <div className="row">
                            <div className="col-md-6">
                                <ul>
                                    <li><span>Amount</span> : <b>{INRFormat(isSelectedRecord.amount)}</b></li>
                                    <br />
                                    <li><span>Amount (Word)</span> : <b className='text-primary'>{toWords.convert(isSelectedRecord.amount)}</b></li>
                                    <br />
                                    <li><span><strong>USER ID</strong></span> : {isSelectedRecord.userData.username + ' (' + isSelectedRecord.roles.code + ') | ' + isSelectedRecord.userData.name}</li>
                                    <li><span><strong>Parent Name</strong></span> : {isSelectedRecord.userParentData.username + ' (' + isSelectedRecord.rolesParent.code + ') | ' + isSelectedRecord.userParentData.name}</li>
                                    <br />
                                    <li><span>FTR</span> : {isSelectedRecord.FTRReferenceNumber}</li>
                                    <li><span>Bank Name</span> : {isSelectedRecord.bankdetails.bankName}</li>
                                    <li><span>Account Number</span> : {isSelectedRecord.accountNumber}</li>
                                    <li><span>Branch IFSC</span> : {isSelectedRecord.branchIfsc}</li>
                                    <li><span>Location</span> : {isSelectedRecord.location}</li>
                                    <li><span>Payment Deposit Date</span> : {getUTCToLocalDateFormat(isSelectedRecord.paymentDepositDate)}</li>
                                    <li><span>Remarks</span> : {isSelectedRecord.bankRemark}</li>
                                    {isSelectedRecord.status === 4 && <><li><span>Bank Transaction ID</span> : {isSelectedRecord.tranReasonId}</li><li><span>Reason</span> : {isSelectedRecord.rejectReason}</li></>}
                                </ul>
                            </div>
                            <div className="col-md-6">
                                <span>Upload Slip</span> : <div className="col-md-12 text-right"><a href={isSelectedRecord.uploadSlip} target='_blank' rel="noreferrer" className="text-danger">OPEN NEW TAB</a></div><img src={isSelectedRecord.uploadSlip} alt="Slip" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Transaction Id</label>
                                    <input type="text" className={`form-control normal-light pr-110`} name="tranId" maxLength={25} onChange={(e) => onChangeValue(e)} value={transactionId} placeholder="Transaction ID / Reason" />
                                    {transactionIdError && <span className="error text-danger">The field is required</span>}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <Button type="primary" size={"large"} icon={<ArrowRightOutlined />} block onClick={() => handleSave(1)} >Process</Button>
                            </div>
                            <div className="col-md-6 text-right">
                                <Button type="primary" size={"large"} onClick={() => handleSave(2)} danger block>Reject</Button>
                            </div>
                        </div>
                    </>
                    : ""}
            </Modal>}
        </div>
    )
}

export default FundMakerRequest