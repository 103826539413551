import React, { useEffect, useState, useRef } from 'react';
import { Modal, Space, Spin } from 'antd';
import { DatePicker } from 'antd';
// import { APP_KEY, API_URL } from './../config';
// import { getToken } from './../Utils/Common';
import { INSURANCE_OPERATORS, INSURANCE_VIEW_BILL, INSURANCE_BILL_PAYMENT } from '../../Utils/appConstants';
import { getRequest, postRequest } from '../../Utils/AppApi';
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
// import DateRangePicker from 'react-bootstrap-daterangepicker';
// import 'bootstrap-daterangepicker/daterangepicker.css';
import 'antd/dist/antd.css';
import moment from 'moment';
import Select from 'react-select';

function InsuranceBillPayment(props) {
    // console.log("000---------------------", props.data.provider)
    const dateFormat = 'DD/MM/YYYY';
    const queryDateFormat = 'YYYY-MM-DD';
    const history = useHistory();
    const operatorSelect = useRef(null);
    const policyNumberInput = useRef(null);
    const emailInput = useRef(null);
    const dateOfBirthSelect = useRef(null);
    const customerMobileInput = useRef(null);
    const dueDateSelect = useRef(null)

    const [isModalViewBill, setIsModalViewBill] = useState(false);
    // const [selectedDueDate, setSelectedDueDate] = useState(false);
    const [getOperatorData, setOperatorData] = useState([]);
    const [getDataField, setDataField] = useState([]);
    const [getDataError, setDataError] = useState({});
    const [getCustomerInfo, setCustomerInfo] = useState({});
    const [paymentId, setPaymentId] = useState(null);
    const [loading, setLoading] = useState(false);
    const [operatorDate, setOperatorDate] = useState("");

    const clearFormField = () => {
        operatorSelect.current.value = "";
        policyNumberInput.current.value = "";
        emailInput.current.value = "";
        dateOfBirthSelect.current.value = "";
        customerMobileInput.current.value = "";
        dueDateSelect.current.value = "";
        // setSelectedDueDate(false)
        setPaymentId(null)
    };

    const getOperators = () => {
        getRequest(INSURANCE_OPERATORS, history).then((response) => {
            if (response.success === true) {
                setOperatorData(response.data.map((item) => {
                    return { "dateformat": item.dateFormat, "value": item.code, "label": item.name };
                }));
            } else {
                toast.error(response.message);
            }
        }).catch(function (error) {
            // console.log(error)
            // setLoading(false);
        })
    }

    const handleHideModal = () => {
        setIsModalViewBill(false);
        clearFormField()
    };

    const onChangeDueDatePicker = (date) => {
        // console.log(date, dateString);
        if (date) {
            let dateValue = moment(date).format(queryDateFormat);
            setDataField({ ...getDataField, dueDate: dateValue });
            // setSelectedDueDate(true);
        } else {
            setDataField({ ...getDataField, dueDate: moment() });
            // setSelectedDueDate(false);
        }
    };

    const onChangeValueSelect = (event) => {
        const { value, dateformat } = event;
        console.log("Console --------", event);
        setOperatorDate(dateformat)
        setDataField({ ...getDataField, "operator": value, "dateOfBirth": moment() });
    }

    const onChangeValue = (event) => {
        const { name, value } = event.target;
        setDataField({ ...getDataField, [name]: value });
    }

    const onChangeDatePicker = (date) => {
        if (date) {
            let dateValue = moment(date).format(queryDateFormat);
            let dateOperator = "";
            if (operatorDate) {
                dateOperator = moment(date).format(operatorDate);
            }
            // console.log("date --------", date);
            // console.log("dateValue --------", dateValue);
            setDataField({ ...getDataField, dateOfBirth: dateValue, operatorDate: dateOperator });
            // setSelectedDueDate(true)
        } else {
            setDataField({ ...getDataField, dateOfBirth: moment(), operatorDate: "" });
            // setSelectedDueDate(false)
        }
    };

    const FormValidate = () => {
        let formValid = true;
        let formObject = {};
        setDataError({})
        if (getDataField && getDataField.length === 0) {
            formValid = false;
        }
        if (getDataField.operator === "" || typeof getDataField.operator === "undefined") {
            formObject.operator = true;
            formValid = false;
        }

        if (getDataField.policyNumber === "" || typeof getDataField.policyNumber === "undefined") {
            formObject.policyNumber = true;
            formValid = false;
        }

        if (getDataField.dateOfBirth === "" || typeof getDataField.dateOfBirth === "undefined") {
            formObject.dateOfBirth = true;
            formValid = false;
        }

        if (getDataField.dueDate === "" || typeof getDataField.dueDate === "undefined") {
            formObject.dueDate = true;
            formValid = false;
        }

        setDataError(formObject);
        return formValid;
    }

    const viewBill = () => {
        if (FormValidate()) {
            setLoading(true)
            const payload = {
                provider: getDataField.operator,
                policyNumber: getDataField.policyNumber,
                dateOfBirth: getDataField.dateOfBirth,
                dueDateOn: getDataField.dueDate,
                operatorDate: getDataField.operatorDate,
                email: getDataField.email,
                mobile: getDataField.mobile,
            }
            postRequest(INSURANCE_VIEW_BILL, payload, history).then((response) => {
                if (response.success === true) {
                    setIsModalViewBill(true);
                    setCustomerInfo(response.data);
                    setPaymentId(response.id);
                    setDataField({ ...getDataField, amount: response.data.Netamount });
                } else {
                    setCustomerInfo({})
                    toast.error(response.message);
                }
                setLoading(false);
            }).catch(function (error) {
                // console.log(error)
                setLoading(false);
            })
        }
    }

    const billPayment = () => {
        if (FormValidate(true)) {
            setLoading(true);
            const payload = {
                paymentId: paymentId,
                amount: getDataField.amount,
            }
            postRequest(INSURANCE_BILL_PAYMENT, payload, history).then((response) => {
                if (response.success === true) {
                    setCustomerInfo({})
                    setDataField([])
                    setDataError({})
                    setIsModalViewBill(false)
                    clearFormField()
                    toast.success(response.message);
                } else {
                    toast.error(response.message);
                }
                setLoading(false);
            }).catch(function (error) {
                // console.log(error)
                setLoading(false);
            })
        }
    };

    useEffect(() => {
        getOperators();
        setDataField({ ...getDataField, dueDate: moment(), operator: props.data.provider, dateOfBirth: moment() });
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            {loading &&
                <div className={'parentDisable'} width="100%">
                    <Space direction="vertical" style={{ width: '100%', }} >
                        <Spin tip="Loading" size="large">
                            <div className="spin-loader" />
                        </Spin>
                    </Space>
                </div>}
            <div className='pt-4'>
                <h4 className='mb-3 fa-1x'>Insurance Premium Payment</h4>
                <div className="form-group">
                    <div className="input-group">
                        <Select options={getOperatorData} value={props.data.provider && getOperatorData[0]} className={`normal-light w-100 ${getDataError && getDataError.operator ? "is-invalid" : ""}`} classNamePrefix="select" placeholder="Select Your Insurance Provider" ref={operatorSelect} name='operator' onChange={(e) => onChangeValueSelect(e)} />
                    </div>
                    {getDataError && getDataError.operator && <span className="error text-danger">The field is required</span>}
                </div>

                <div className="form-group">
                    <div className="input-group">
                        <input type="text" className={`form-control normal-light ${getDataError && getDataError.policyNumber ? "is-invalid" : ""}`} placeholder="Enter Policy Number" name="policyNumber" ref={policyNumberInput} maxLength={30} onChange={(e) => onChangeValue(e)} />
                    </div>
                    {getDataError && getDataError.policyNumber && <span className="error text-danger">The field is required</span>}
                </div>
                <div className="form-group">
                    <div className="position-relative">
                        {/* defaultValue={moment(new Date(), dateFormat)} */}
                        <DatePicker format={dateFormat} className={`form-control normal-light  ${getDataError && getDataError.dateOfBirth ? "is-invalid" : ""}`} placeholder="Select Date Of Birth" name="dateOfBirth" ref={dateOfBirthSelect} maxLength={10} onChange={onChangeDatePicker} />
                    </div>
                    {getDataError && getDataError.dateOfBirth && <span className="error text-danger">The field is required</span>}
                </div>
                
                <div className="form-group">
                    <div className="position-relative">
                        <DatePicker format={dateFormat} className={`form-control normal-light  ${getDataError && getDataError.dueDate ? "is-invalid" : ""}`} placeholder="Select Due Date" name="dueDate" ref={dueDateSelect} maxLength={10} onChange={onChangeDueDatePicker} />
                    </div>
                    {getDataError && getDataError.dueDate && <span className="error text-danger">The field is required</span>}
                </div>

                <div className="form-group">
                    <div className="position-relative">
                        <input type="text" className={`form-control normal-light  `} placeholder="Customer Mobile (Optional)" name="mobile" ref={customerMobileInput} maxLength={10} onChange={(e) => onChangeValue(e)} />
                    </div>
                </div>

                <div className="form-group">
                    <div className="position-relative">
                        <input type="text" className={`form-control normal-light  ${getDataError && getDataError.email ? "is-invalid" : ""}`} placeholder="Enter Email Address" name="email" ref={emailInput} maxLength={30} onChange={(e) => onChangeValue(e)} />
                    </div>
                    {getDataError && getDataError.email && <span className="error text-danger">The field is required</span>}
                </div>

                <div className='row pt-2'>
                    <div className='col-12'>
                        <button type="button" className="btn btn-danger mb-2 btn-block" onClick={(e) => viewBill(e)}>View Bill</button>
                    </div>
                </div>
            </div>
            <ToastContainer position="top-center" autoClose={5000} limit={2} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="colored" />
            {isModalViewBill && <Modal title={"Customer Info"} width={500} open={isModalViewBill} onOk={billPayment} onCancel={handleHideModal} okText={"Payment (" + getCustomerInfo.Netamount + ")"}>
                {getCustomerInfo ?
                    <ul>
                        <li><span>Customer Name</span> : {getCustomerInfo.CustomerName}</li>
                        <li><span>Bill Amount</span> : {getCustomerInfo.Netamount}</li>
                        <li><span>Due Date</span> : {getCustomerInfo.Duedatefromto}</li>
                    </ul>
                    : ""}
            </Modal>}
        </div>
    )
}

export default InsuranceBillPayment