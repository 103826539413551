import { useState, useEffect } from 'react';
// import axios from 'axios';
import DataTable from 'react-data-table-component';
import { TRANSACTION_LOG_HISTORY } from './../../Utils/appConstants';
import { useHistory } from "react-router-dom";
import PropagateLoader from "react-spinners/PropagateLoader";
import { postRequest } from '../../Utils/AppApi';
import ReactJson from 'react-json-view';

import { Tooltip, Modal, Divider } from 'antd';
import { getUTCToLocalDateTimeFormat } from '../../Utils/function';

const MyComponent = () => {
    const history = useHistory();
    const [isLoading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState(null);
    // const [timer, setTimer] = useState(null);
    const [isData, setData] = useState([]);
    const [getTotalRecord, setTotalRecord] = useState(0);
    const [page, setPage] = useState(1);
    const [isLimit, setLimit] = useState(50);
    const [isModel, setModel] = useState(false);
    const [isModelData, setModelData] = useState(null);
    // const [isCustomerNotExist, setCustomerNotExist] = useState(false);
    // const [subTabs, setSubTabs] = useState('1');
    // useEffect(() => {
    //     if (searchText) {
    //         if (timer) {
    //             clearTimeout(timer)
    //             setTimer(null)
    //         }
    //         setTimer(
    //             setTimeout(() => {
    //                 getLogHistory()
    //             }, 2000)
    //         )
    //     }
    // }, [searchText]) // eslint-disable-line react-hooks/exhaustive-deps

    const handleSearch = (e) => {
        // console.log("searchText----",e.target.value)
        setSearchText(e.target.value);
    }

    const getLogHistory = async (data) => {
        if (data) {
            setLimit(data)
        }
        // console.log("page----------------", page);
        setLoading(true)
        let payload = { "transactionId": searchText, page: page, limit: isLimit }
        await postRequest(TRANSACTION_LOG_HISTORY, payload, history).then((response) => {
            if (response.success === true) {
                setData(response.data);
                setTotalRecord(response.data.totalDocs);
                // setPage(response.data.page);
                // console.log("----------------", response.data);
            } else {
                setData([]);
                // console.log("----------------", response.success);
            }
            setLoading(false)
        }).catch(function (error) {
            console.log(error)
            setLoading(false);
        })
    }

    const columns = [{
        name: 'DateTime',
        selector: row => <><Tooltip title={getUTCToLocalDateTimeFormat(row.createdAt)}>{getUTCToLocalDateTimeFormat(row.createdAt)}</Tooltip></>,
    }, {
        name: 'API',
        selector: row => <><Tooltip title={row.apilink}>{row.apilink}</Tooltip></>,
    }, {
        name: 'Method',
        selector: row => <><Tooltip title={row.method}>{row.method}</Tooltip></>,
    }, {
        name: 'Service',
        selector: row => <><Tooltip title={row.service}>{row.service}</Tooltip></>,
    }, {
        name: 'Txt ID',
        selector: row => <><Tooltip title={row.transactionId}>{row.transactionId}</Tooltip></>,
    }, {
        name: 'User ID/Name',
        selector: row => <><Tooltip title={row.users && row.users.username+' | '+row.users.name}>{row.users && row.users.username+' | '+row.users.name}</Tooltip></>,
    }, {
        name: 'Action',
        selector: row => <><div onClick={() => handleChangeStatus(row)} className='icon-border border pointer'><i class="fa fa-eye" aria-hidden="true"></i></div></>,
    }]

    const handleChangeStatus = (data) => {
        setModel(true);
        setModelData(data);
    }

    const handlePageChange = (page, size) => {
        // console.log("-----", size)
        setPage(page);
    };

    useEffect(() => {
        console.log("useEffect page-----", page)
        if(page > 1){
            getLogHistory();
        }
    }, [page]) // eslint-disable-line react-hooks/exhaustive-deps

    if (isLoading) {
        return <div className="content text-center" style={{ margin: "250px 0" }}>
            <PropagateLoader color="#5851D2" loading={isLoading} size={25} />
        </div>
    }
    return (
        <div>
            <div className="">
                <div className="content">
                    <div className="container-fluid">
                        <div className='card'>
                            <div className='card-body vh-100'>
                                <div className='row mb-3'>
                                    <div className="input-group col-md-4">
                                        <input className="form-control py-2 border-right-0 border" type="search" value={searchText} placeholder='Transaction ID / Partner ID ...' onChange={(e) => handleSearch(e)} />
                                        <span className="input-group-append">
                                            <button onClick={() => getLogHistory()} className="btn btn-outline-secondary border-left-0 border" type="button">
                                                <i className="fa fa-search" />
                                            </button>
                                        </span>
                                    </div>
                                </div>
                                <Divider />
                                <div className="container-fluid pt-2 pb-5">
                                    <DataTable
                                        title={""}
                                        columns={columns}
                                        data={isData.docs}
                                        highlightOnHover
                                        pagination
                                        paginationServer
                                        paginationTotalRows={getTotalRecord}
                                        onChangeRowsPerPage={getLogHistory}
                                        onChangePage={handlePageChange}
                                    />
                                </div>

                                {isModel && <Modal title={"Detail"} width={600} open={isModel} onCancel={() => setModel(false)} footer={null}>
                                    <div className='row'>
                                        <div className='col-md-12 form-group'><div className="project-info-box text-primary">
                                            <p><b>API :</b> {isModelData.apilink}</p>
                                            <p><b>Method:</b> {isModelData.method}</p>
                                            <p><b>Payload:</b> {isModelData.payload && <ReactJson src={isModelData.payload} collapsed={true} theme={"monokai"} />}</p>
                                            <p><b>Full Response:</b> {isModelData.fullResponse && <ReactJson src={isModelData.fullResponse} collapsed={true} theme={"monokai"} />}</p>
                                            <p><b>Response:</b> {isModelData.shortResponse && <ReactJson src={isModelData.shortResponse} collapsed={true} theme={"monokai"} />}</p>
                                        </div>
                                            <Divider />
                                        </div>
                                    </div>
                                </Modal>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MyComponent;