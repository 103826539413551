import React, { useEffect, useState, useRef } from 'react';
// import { bankModes } from './../config/reportName';
import PropagateLoader from "react-spinners/PropagateLoader";
import DataTable from 'react-data-table-component';
import { isNumber } from './../Utils/Common';
import { getGeoLocation, refId, isIfscCodeValid } from './../Utils/function';

import { GET_BANK_SETTING_LIST, IFSC_CODE_VERIFY, GET_BANK_NAME_FROM_ACCOUNT_NUMBER_IN_BANK_SETTING, SAVE_BANK_SETTING_API, BANK_SETTING_CHANGE_STATUS, BANK_SETTING_DELETE, SET_DEFAULT_BANK_SETTING, GET_NAME} from './../Utils/appConstants';
import { getRequest, postRequest, deleteRequestNewApi } from './../Utils/AppApi';
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import { Divider, Tooltip } from 'antd';

import { Popconfirm } from 'antd';
const text = 'Are you sure to delete this data?';

function BankSetting(props) {

    const history = useHistory();  // eslint-disable-line react-hooks/exhaustive-deps
    // const csvLink = useRef()
    const limit = 10;
    // const dateFormat = 'DD-MM-YYYY';
    const [latitude, setLatitude] = useState("");
    const [longitude, setLongitude] = useState("");
    const [getDataField, setDataField] = useState([]);
    const [getDataError, setDataError] = useState({});
    const [getData, setData] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [getTotalRecord, setTotalRecord] = useState(0);
    const [page, setPage] = useState(1);
    // const [isEdit, setIsEdit] = useState(false);
    // const nameInput = useRef(null);
    const accountNumberInput = useRef(null);
    // const branchAddressInput = useRef(null);
    // const bankNameInput = useRef(null);
    const ifscCodeInput = useRef(null);
    // const cityInput = useRef(null);
    // const stateInput = useRef(null); 
    // const mobile = useFormInput('');

    const clearFormField = () => {
        ifscCodeInput.current.value = "";
        accountNumberInput.current.value = "";
        // nameInput.current.value = "";
        // branchAddressInput.current.value = "";
        // bankNameInput.current.value = "";
        // cityInput.current.value = "";
        // stateInput.current.value = "";
        setDataField({});
        setDataError({});
    }

    const columns = [{
        name: '#',
        selector: row => <><input type="radio" onClick={() => handleDefaultBank(row._id)} checked={row.isDefault} value={row._id} /></>,
        width: "50px"
    },{
        name: 'Name',
        selector: row => <><Tooltip title={row.name}>{row.name}</Tooltip></>,
    }, {
        name: 'Account Number',
        selector: row => <><Tooltip title={row.accountNumber}>{row.accountNumber}</Tooltip></>,
    }, {
        name: 'Bank Name',
        selector: row => <><Tooltip title={row.bankName}>{row.bankName}</Tooltip></>,
    }, {
        name: 'IFSC Code',
        selector: row => <><Tooltip title={row.ifscCode}>{row.ifscCode}</Tooltip></>,
    }, {
        name: 'Branch Address',
        selector: row => <><Tooltip title={row.branchAddress}>{row.branchAddress}</Tooltip></>,
    }, {
        name: 'City',
        selector: row => row.city ? row.city : "-",
    }, {
        name: 'State',
        selector: row => row.state ? row.state : "-",
    }, {
        name: 'Status',
        cell: row => <>{(row.isAdminApproved === 1) ? (row.status) ? <div className="text-success pointer" onClick={(event) => handleChangeStatus(event, row._id, "false")} title='Deactive'><u><b>Active</b></u></div> : <div className="text-danger pointer" onClick={(event) => handleChangeStatus(event, row._id, "true")} title='Active'>Deactive</div> : "Pending"}</>,
    }, {
        name: 'Action',
        cell: row => <><Popconfirm placement="left" title={text} onConfirm={(event) => handleDelete(event, row._id)} okText="Yes" cancelText="No"><div className='icon-border border border-danger text-danger pointer'><i className="fa fa-trash" aria-hidden="true"></i></div></Popconfirm></>,
    }, {
        name: 'Remark',
        selector: row => (row.isAdminApproved === 1) ? "Approved" : "Rejected",
    }] // eslint-disable-line react-hooks/exhaustive-deps

    const handleDefaultBank = (id) => {
        const payload = { "id": id };
        postRequest(`${SET_DEFAULT_BANK_SETTING}`, payload, history).then((response) => {
            if (response.success === true) {
                toast.success(response.message);
                getDataReports();
            }
            setLoading(false);
        }).catch(function (error) {
            setLoading(false);
            console.log(error)
        });
    }

    const objectToQueryString = (obj) => {
        var str = [];
        for (var p in obj)
            if (obj.hasOwnProperty(p)) {
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
        return str.join("&");
    } // eslint-disable-line react-hooks/exhaustive-deps

    const params = { page: page, limit: limit };

    const getDataReports = () => {
        // if (startDate && endDate) {
        //   params.startDate = startDate ? startDate : "";
        //   params.endDate = endDate ? endDate : "";
        // }

        const queryString = objectToQueryString(params);
        getRequest(`${GET_BANK_SETTING_LIST}?${queryString}`, history).then((response) => {
            if (response.success === true) {
                setData(response.data);
                setTotalRecord(response.data.totalDocs);
                setPage(response.data.page);
            } else {
                setData([]);
                setTotalRecord(0);
                setPage(1);
            }
        }).catch(function (error) {
            console.log(error)
            // setLoading(false);
        })
    } // eslint-disable-line react-hooks/exhaustive-deps

    const handlePageChange = page => {
        setPage(page);
    }; // eslint-disable-line react-hooks/exhaustive-deps

    // const isIfscCodeValid = (ifsc_Code) => {
    //     let regex = new RegExp(/^[A-Z]{4}0[A-Z0-9]{6}$/);
    //     if (ifsc_Code === null) {
    //         return false;
    //     }
    //     if (regex.test(ifsc_Code) === true) {
    //         return true;
    //     }
    //     else {
    //         return false;
    //     }
    // }

    const onChangeIfscCode = (event) => {
        const { name, value } = event.target;
        setDataField({ ...getDataField, [name]: value });
        if (isIfscCodeValid(value)) {
            console.log("--- Valid", value);
            setLoading(true);
            const payload = { "ifsccode": value };
            postRequest(`${IFSC_CODE_VERIFY}`, payload, history).then((response) => {
                if (response.success === true) {
                    console.log("response.data-----------", response.data.data)
                    let record = response.data.data;
                    let Object = {
                        "ifscCode": record.ifsc,
                        "address": record.address,
                        "bankName": record.bank,
                        "branchAddress": record.branch,
                        "city": record.city,
                        "state": record.state,
                    }
                    setDataField({ ...getDataField, ...Object });
                } else {
                    console.log("response.data-----------", response)
                }
                setLoading(false);
            }).catch(function (error) {
                setLoading(false);
                console.log(error)
            });
        } else {
            if (value.length === 11) {
                toast.error("Please enter valid ifsccode")
                let Object = {
                    "address": '',
                    "bankName": '',
                    "branchAddress": '',
                    "city": '',
                    "state": '',
                }
                setDataField({ ...getDataField, ...Object });
            }
        }
    }

    const getBankNameFromAccountNumber = () => {
        if (getDataField.accountNumber && getDataField.ifscCode) {
            // console.log("--- Valid -- 1", getDataField)
            setLoading(true);
            const payload = {
                "ifsccode": getDataField.ifscCode,
                "accountNumber": getDataField.accountNumber,
                "externalRef": refId(13),
                "consent": "Y",
                "pennyDrop": "AUTO",
                "latitude": latitude,
                "longitude": longitude
            };
            postRequest(`${GET_BANK_NAME_FROM_ACCOUNT_NUMBER_IN_BANK_SETTING}`, payload, history).then((response) => {
                if (response.success === true) {
                    // console.log("response.data-----------", response.data.data)
                    let record = response.data.data;
                    let Object = { "name": record.nameAtBank }
                    setDataField({ ...getDataField, ...Object });
                } else {
                    // console.log("response.data-----------", response)
                    toast.error(response.message);
                }
                setLoading(false);
            }).catch(function (error) {
                setLoading(false);
                console.log(error)
            });
        } else {
            toast.error("Please enter valid account number")
            let Object = { "name": '' }
            setDataField({ ...getDataField, ...Object });
        }
    }

    const onChangeValue = (event) => {
        const { name, value } = event.target;
        setDataField({ ...getDataField, [name]: value });
    } // eslint-disable-line react-hooks/exhaustive-deps

    const FormValidate = () => {
        let formValid = true;
        let formObject = {};
        setDataError({})
        if (getDataField && getDataField.length === 0) {
            formValid = false;
        }

        let array = ["ifscCode", "accountNumber", "name", "bankName", "branchAddress", "city", "state"];

        array.forEach(element => {
            if (getDataField[element] === "" || typeof getDataField[element] === "undefined") {
                formObject[element] = true;
                formValid = false;
            }
        });

        setDataError(formObject);
        return formValid;
    } // eslint-disable-line react-hooks/exhaustive-deps

    const handleChangeStatus = (event, id, value) => {
        let payload = { status: value };
        setLoading(true);
        postRequest(`${BANK_SETTING_CHANGE_STATUS}/${id}`, payload, history).then((response) => {
            // console.log("response -------", response)
            if (response.success === true) {
                setDataField({});
                setDataError({});
                clearFormField();
                getDataReports();
                toast.success(response.message);
            } else {
                toast.error(response.message);
            }
            setLoading(false);
        }).catch(function (error) {
            setLoading(false);
            console.log(error)
        })
    } // eslint-disable-line react-hooks/exhaustive-deps

    const handleDelete = (event, id) => {
        let payload = { removed: false };
        deleteRequestNewApi(`${BANK_SETTING_DELETE}/${id}`, payload, history).then((response) => {
            // console.log("response -------", response)
            if (response.success === true) {
                setDataField({});
                setDataError({});
                clearFormField();
                getDataReports();
                toast.success(response.message);
            } else {
                toast.error(response.message);
            }
        }).catch(function (error) {
            console.log(error)
        })
    } // eslint-disable-line react-hooks/exhaustive-deps

    const handleSubmit = () => {
        if (FormValidate(true)) {
            setLoading(true);
            const payload = {
                name: getDataField.name,
                bankName: getDataField.bankName,
                accountNumber: getDataField.accountNumber,
                ifscCode: getDataField.ifscCode,
                branchAddress: getDataField.branchAddress,
                address: getDataField.address,
                city: getDataField.city,
                state: getDataField.state,
            }
            postRequest(SAVE_BANK_SETTING_API, payload, history).then((response) => {
                if (response.success === true) {
                    setDataField({});
                    setDataError({});
                    clearFormField();
                    getDataReports();
                    toast.success(response.message);
                } else {
                    toast.error(response.message);
                }
                setLoading(false);
            }).catch(function (error) {
                setLoading(false);
                // console.log(error)
                // setLoading(false);
            })
        }
    } // eslint-disable-line react-hooks/exhaustive-deps

    const fetchLocation = async () => {
        let data = await getGeoLocation();
        setLatitude(data.latitude);
        setLongitude(data.longitude);
    }

    useEffect(() => {
        getDataReports();
        fetchLocation();
    }, [page]) // eslint-disable-line react-hooks/exhaustive-deps

    if (isLoading) {
        return <div className="content text-center" style={{ margin: "250px 0" }}>
            <PropagateLoader color="#5851D2" loading={isLoading} size={25} />
        </div>
    }

    return (
        <div>
            <div className="content-wrapper pt-2">
                <div className="content-header my-0 py-0">
                    <div className="container-fluid">
                        <div className="row mb-0 align-items-center">
                            <div className="col-sm-6">
                                <h1 className='page-title text-blue font-merriweather'>Bank <span className='text-red'>Setting</span></h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content">
                    <div className="container-fluid pt-2 pb-5">
                        <div className="row">
                            <div className="col-md-12">
                                <div className='card rounded-0'>
                                    <div className='card-body'>
                                        <div className='col-md-12'>
                                            <div className='row'>
                                                <div className='col-sm-6 col-md-4 col-lg-3'>
                                                    <div className="form-group">
                                                        <label>IFSC Code</label>
                                                        <input type="text" className={`form-control normal-light ${getDataError && getDataError.ifscCode ? "is-invalid" : ""}`} name="ifscCode" ref={ifscCodeInput} maxLength={11} onChange={(e) => onChangeIfscCode(e)} placeholder="IFSC Code*" value={getDataField && getDataField.ifscCode} />
                                                        {getDataError && getDataError.ifscCode && <span className="error text-danger">The field is required</span>}
                                                    </div>
                                                </div>
                                                <div className='col-sm-6 col-md-4 col-lg-3'>
                                                    <div className="form-group">
                                                        <label>Account Number</label>
                                                        <div className="position-relative">
                                                            <input type="text" className={`form-control normal-light   ${getDataError && getDataError.accountNumber ? "is-invalid" : ""}`} name="accountNumber" ref={accountNumberInput} maxLength={20} onKeyDown={isNumber} onChange={(e) => onChangeValue(e)} placeholder="Account Number*" value={getDataField && getDataField.accountNumber} />

                                                            <div className="inputgrouptext cursor-pointer text-success" onClick={getBankNameFromAccountNumber}>{GET_NAME}</div>
                                                        </div>
                                                        {getDataError && getDataError.accountNumber && <span className="error text-danger">The field is required</span>}
                                                    </div>
                                                </div>
                                                <div className='col-sm-6 col-md-4 col-lg-3'>
                                                    <div className="form-group">
                                                        <label>Name</label>
                                                        <input type="text" className={`form-control normal-light ${getDataError && getDataError.name ? "is-invalid" : ""}`} placeholder="Name" value={getDataField && getDataField.name} readOnly />
                                                        {getDataError && getDataError.name && <span className="error text-danger">The field is required</span>}
                                                    </div>
                                                </div>

                                                <div className='col-sm-6 col-md-4 col-lg-3'>
                                                    <div className="form-group">
                                                        <label>Bank Name</label>
                                                        <input type="text" className={`form-control normal-light ${getDataError && getDataError.bankName ? "is-invalid" : ""}`} placeholder="Bank Name*" value={getDataField && getDataField.bankName} readOnly />
                                                        {getDataError && getDataError.bankName && <span className="error text-danger">The field is required</span>}
                                                    </div>
                                                </div>
                                                <div className='col-sm-6 col-md-4 col-lg-3'>
                                                    <div className="form-group">
                                                        <label>Branch Address</label>
                                                        <input type="text" className={`form-control normal-light${getDataError && getDataError.branchAddress ? "is-invalid" : ""}`} placeholder="Branch Address*" value={getDataField && getDataField.branchAddress} readOnly />
                                                        {getDataError && getDataError.branchAddress && <span className="error text-danger">The field is required</span>}
                                                    </div>
                                                </div>

                                                <div className='col-sm-6 col-md-4 col-lg-3'>
                                                    <div className="form-group">
                                                        <label>City</label>
                                                        <input type="text" className={`form-control normal-light`} placeholder="City" value={getDataField && getDataField.city} readOnly />
                                                        {getDataError && getDataError.city && <span className="error text-danger">The field is required</span>}
                                                    </div>
                                                </div>
                                                <div className='col-sm-6 col-md-4 col-lg-3'>
                                                    <div className="form-group">
                                                        <label>State</label>
                                                        <input type="text" className={`form-control normal-light  `} placeholder="State" value={getDataField && getDataField.state} readOnly />
                                                        {getDataError && getDataError.state && <span className="error text-danger">The field is required</span>}
                                                    </div>
                                                </div>
                                                <div className='col-sm-6 col-md-4 col-lg-2'>
                                                    <label className='d-none d-lg-block'>&nbsp;</label>
                                                    <div className="form-group text-lg-center">
                                                        <button type="submit" className="btn btn-primary rounded-pill pl-4 pr-4" onClick={() => handleSubmit()}>Add Account</button>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <Divider />
                                    <div className='card-body'>
                                        <div className="content-header my-0 px-0">
                                            <h1 className='page-title text-blue font-merriweather mb-3'>Registered <span className='text-red'>Bank Account</span></h1>
                                        </div>

                                        <div className='history-table'>
                                            <div className="container-fluid pb-5">
                                                <div className="col-md-12">
                                                    <DataTable
                                                        title={""}
                                                        columns={columns}
                                                        data={getData.docs}
                                                        highlightOnHover
                                                        pagination
                                                        paginationServer
                                                        paginationTotalRows={getTotalRecord}
                                                        onChangeRowsPerPage={getDataReports}
                                                        onChangePage={handlePageChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer position="top-center" autoClose={5000} limit={2} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="colored" />
            </div>
        </div>
    )
}

export default BankSetting;