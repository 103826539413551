import React, { useState, useRef, useEffect } from 'react';

const VideoRecorder = () => {
  const [recording, setRecording] = useState(false);
  const [mediaStream, setMediaStream] = useState(null);
  const videoRef = useRef(null);
  const mediaRecorderRef = useRef(null);

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      setMediaStream(stream);
      setRecording(true);
      console.log("--------", stream)
      videoRef.current.srcObject = stream;
      const mediaRecorder = new MediaRecorder(stream);
      mediaRecorder.ondataavailable = handleDataAvailable;
      mediaRecorderRef.current = mediaRecorder;
      mediaRecorder.start();
      
      console.log("Stop recording after 50 seconds")
      // Stop recording after 50 seconds
    } catch (error) {
      console.error('Error accessing user media:', error);
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current && mediaStream) {
      mediaRecorderRef.current.stop();
      mediaStream.getTracks().forEach(track => track.stop());
      setRecording(false);
    }
  };

  const handleDataAvailable = (event) => {
    const recordedBlob = new Blob([event.data], { type: 'video/webm' });
    console.log("recordedBlob -------------------", recordedBlob)
    // Assuming you have an endpoint to post the recorded video
    // Replace 'your-api-endpoint' with your actual API endpoint
    fetch('your-api-endpoint', {
      method: 'POST',
      body: recordedBlob
    })
    .then(response => {
      // Handle response
    })
    .catch(error => {
      console.error('Error posting video:', error);
    });
  };
  useEffect(() => {
    if(recording) {
      setTimeout(() => {
        stopRecording();
      }, 5000);
    }
  },[recording]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <div>
      <div className="container my-5">
      {recording && <video ref={videoRef} width="100%" height="480" controls={true} autoPlay></video>}
      {!recording ? (
        <button className='btn btn-block btn-primary' onClick={startRecording}>Start Recording</button>
      ) : (
        <button className='btn btn-block btn-danger' onClick={stopRecording}>Stop Recording</button>
      )}
      </div>
    </div>
  );
};

export default VideoRecorder;




// import React, { useEffect, /* useState */ } from 'react';
// // import { APP_KEY, API_URL } from './../config';
// // import { getToken } from './../Utils/Common';
// // import { Tabs, Tab } from 'react-bootstrap-tabs';
// // import { GET_MYPROFILE } from '../../Utils/appConstants';
// // import { getRequest, /*postRequest*/ } from '../../Utils/AppApi';
// // import AppHeader from '../Header/Header';
// // import { useHistory } from "react-router-dom";
// import { ToastContainer, /* toast */ } from 'react-toastify';
// // import AppFooter from '../Footer/Footer';
// // import DateRangePicker from 'react-bootstrap-daterangepicker';
// import 'bootstrap-daterangepicker/daterangepicker.css';
// // import moment from 'moment';
// // import axios from 'axios';
// // import { Line } from 'react-chartjs-2';

// // const token = getToken();
// // const headers = { headers: { "app-key": APP_KEY, "x-access-token": token } }
// // alert(token);
// function CliamPayment(props) {

//   // const history = useHistory();
//   // const [getData, setGetData] = useState({});
//   // const [isProfileCheck, setIsProfileCheck] = useState(false);
//   // const [loading, setLoading] = useState(false);

//   // const [tabName, setTabName] = useState("Mobile Recharge");
//   // const [appInfo, setAppInfo] = useState("");
//   // const [username, setUsername] = useState("");
//   // const [password, setPassword] = useState("");
//   // const [otpValue ,setOtpValue] = useState("");
//   // const mobile = useFormInput('');

//   // const getProfile = () => {
//   //   setLoading(true);
//   //   getRequest(GET_MYPROFILE).then((response) => {
//   //     if (response.success === true) {
//   //       setGetData(response.data);
//   //       console.log("response.data ========", response.data)
//   //       setIsProfileCheck(true);
//   //     } else {
//   //       toast.error(response.message);
//   //       setIsProfileCheck(false);
//   //     }
//   //     setLoading(false);
//   //   }).catch(function (error) {
//   //     // console.log(error)
//   //     setLoading(false);
//   //   })
//   // }

//   // const onchangeTab = (index, value) => {
//   //   console.log("index -------", index)
//   //   setTabName(value.props.title)
//   //   // console.log("value -------", value.props)
//   // }

//   useEffect(() => {
//     // getProfile();
//   }, [])

//   return (
//     <div>
//       {/* <AppHeader /> */}
//       <div className="content-wrapper pt-2">
//         <div className="content-header my-0 py-0">
//           <div className="container-fluid">
//             <div className="row mb-0 align-items-center">
//               <div className="col-sm-6">
//                 <h1 className='page-title text-blue font-merriweather'>Claim <span className='text-red'>Payment</span></h1>
//               </div>
//             </div>
//           </div>
//         </div>
//         <div className="content">
//           <div className="container-fluid pt-2 pb-5">
//             <div className="row">
//               <div className="col-md-12">
//                 <div className='card rounded-0'>
//                   <div className='card-body'>
//                     <div className='row'>
//                       <div className='col-sm-6 col-md-4 col-lg-3'>
//                         <div className="form-group">
//                           <label>USER ID</label>
//                           <input type="text" className="form-control" placeholder="USER ID*" disabled />
//                         </div>
//                       </div>
//                       <div className='col-sm-6 col-md-4 col-lg-3'>
//                         <div className="form-group">
//                           <label>Reference Number</label>
//                           <input type="text" className="form-control" placeholder="Reference Number*" disabled />
//                         </div>
//                       </div>
//                       <div className='col-sm-6 col-md-4 col-lg-3'>
//                         <div className="form-group">
//                           <label>Amount</label>
//                           <input type="text" className="form-control" placeholder="Amount*" disabled />
//                         </div>
//                       </div>
//                       <div className='col-sm-6 col-md-4 col-lg-3'>
//                         <div className="form-group">
//                           <label>Bank Name</label>
//                           <input type="text" className="form-control" placeholder="Bank Name*" disabled />
//                         </div>
//                       </div>
//                       <div className='col-sm-6 col-md-4 col-lg-3'>
//                         <div className="form-group">
//                           <label>Customer Name</label>
//                           <input type="text" className="form-control" placeholder="Customer Name*" disabled />
//                         </div>
//                       </div>
//                       <div className='col-sm-6 col-md-4 col-lg-3'>
//                         <div className="form-group">
//                           <label>Customer Mobile</label>
//                           <input type="text" className="form-control" placeholder="Customer Mobile*" disabled />
//                         </div>
//                       </div>
//                       <div className='col-sm-6 col-md-4 col-lg-3'>
//                         <div className="form-group">
//                           <label>Mode</label>
//                           <input type="text" className="form-control" placeholder="Mode*" disabled />
//                         </div>
//                       </div>
//                       <div className='col-sm-6 col-md-4 col-lg-3'>
//                         <div className="form-group">
//                           <label>Bank Ref Number</label>
//                           <input type="text" className="form-control" placeholder="Bank Ref Number*" disabled />
//                         </div>
//                       </div>
//                     </div>

//                     <div className='row align-items-center'>
//                       <div className='col-md-4'>
//                         <label>Upload your Photo with KYC</label>
//                         <div>File upload</div>
//                       </div>
//                       <div className='col-md-6'>
//                         <div className=" ">
//                           <button type="submit" className="btn btn-primary rounded-pill pl-4 pr-4">Process</button>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//         <ToastContainer position="top-center" autoClose={5000} limit={2} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="colored"/>
//       </div>
//       {/* <AppFooter /> */}
//     </div>
//   )
// }

// export default CliamPayment