import React, { useEffect, useReducer, useState } from 'react';
import { getUser } from './../Utils/Common';
// // import AppHeader from './Header/Header';
import Marquee from "react-fast-marquee";
// // import AppFooter from './Footer/Footer';
import AdminDashboard from './dashboard/AdminDashboard';
import AgentDashboard from './dashboard/AgentDashboard';
import SHDashboard from './dashboard/SHDashboard';
import DistributorDashboard from './dashboard/DistributorDashboard';
// import MDistributorDashboard from './dashboard/MDistributorDashboard';
// import SDistributorDashboard from './dashboard/SDistributorDashboard';
import StaffDashboard from './dashboard/StaffDashboard';
import 'bootstrap-daterangepicker/daterangepicker.css';
// import useForceUpdate from 'use-force-update';

function Dashboard(props) {

  const user = getUser();
  const [isStaff, setStaff] = useState(false);
  const [role, setRole] = useState(user && user.data && user.data.role.code);
  const [, forceUpdate] = useReducer(x => x + 1, 0);

  function handleClick() {
    forceUpdate();
  }

  useEffect(() => {
    setRole(user && user.data && user.data.role.code);
    // DashboardLoad(user && user.data && user.data.role.code);
    // console.log("------- Change Staff 1-------------", user.data);
    setStaff(user && user.data && user.data.isStaff);
  }, [role]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // console.log("------- Change Staff 2-------------", isStaff);
    if(isStaff) {
      handleClick();
    }
  }, [isStaff]) // eslint-disable-line react-hooks/exhaustive-deps

  const DashboardLoad = (role) => {
    if (role === 'V') {
      return "";
    } else if (role === 'P') {
      return <AgentDashboard userData={user} />
    } else if (role === 'D') {
      return <DistributorDashboard />
    } else if (role === 'MD') {
      return <DistributorDashboard />
      // return <MDistributorDashboard />
    } else if (role === 'SD') {
      return <DistributorDashboard />
      // return <SDistributorDashboard />
    } else if (role === 'SH') {
      return <SHDashboard />
    } else {
      return <AdminDashboard />
    }
  }

  return (
    <div>
      <div className="content-wrapper pt-2">
        <div className='bg-white'>
          <div className="text-center">
            <div className='text-red message-text'>
              <Marquee speed={"100"} loop={0}>
                Dear member welcome to the SR PAY Private Limited Family. Hope you enjoy the whole new experience with upgraded facilities on the all new SR PAY Private Limited Portal.
              </Marquee>
            </div>
            <hr className='m-0' />
            <div className='p-2 text-blue message-text'>
              <Marquee speed={"100"} loop={0}>
                प्रिय सदस्य, SR PAY Private Limited परिवार में आपका स्वागत है। आशा है कि आप बिल्कुल नए SR PAY Private Limited पोर्टल पर उन्नत सुविधाओं के साथ बिल्कुल नए अनुभव का आनंद लेंगे।
              </Marquee>
            </div>
          </div>
        </div>
        {isStaff ?
          (<StaffDashboard />)
          :
          <>{DashboardLoad(role)}</>
        }
      </div>
    </div>
  );
}

export default Dashboard;