import React, { useEffect, useState } from 'react';
import { Button, Modal, Radio, Checkbox } from 'antd';
import PropagateLoader from "react-spinners/PropagateLoader";
import DataTable from 'react-data-table-component';
import 'antd/dist/antd.css';
import { useHistory } from "react-router-dom";
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import ImportBaneficiary from './../moneyTransfer/import';
import {
    GET_DMT_PAY_SPRINT_BENEFICIARY_LIST,
    POST_DMT_PAY_SPRINT_CUSTOMER_INFO,
    POST_DMT_PAY_SPRINT_OTP_VERIFICATION,
    POST_DMT_PAY_SPRINT_INITIATE_TRANSACTION,
    POST_DMT_PAY_SPRINT_GENERATE_TRANSACTION,
    POST_DMT_PAY_SPRINT_PAYMENT_TRANSFER,
    POST_DMT_PAY_SPRINT_ADD_BENEFICIARY,
    UPDATE_DMT_PAY_SPRINT_BENEFICIARY_STATUS,
    UPDATE_DMT_PAY_SPRINT_BENEFICIARY_DETAIL,
    POST_DMT_FAILED_BY_PARTNER,
    // GET_DMT_EKO_BANL_LIST,
    // GET_DMT_BANK_LIST,
    GET_DMT_NEW_BANK_LIST,
    GET_CUSTOMER_BANK_NAME_FROM_ACCOUNT_NUMBER,
    IFSC_CODE_VERIFY,
    GET_NAME,
    RE_CHECK,
    POST_DMT_PAY_SPRINT_CONFIRM_INITIATE_TRANSACTION
} from '../../Utils/appConstants';
import { getRequest, postRequest } from '../../Utils/AppApi';
import { ToastContainer, toast } from 'react-toastify';
import { isNumber, isAlphabetOnly } from '../../Utils/Common';
import { refId, isIfscCodeValid, getUTCToLocalDateFormat } from './../../Utils/function';
import DmtInvoice from './../../container/dmtInvoice';
import Select from 'react-select';


function App(props) {
    // console.log("props -------",props.search)
    // const user = getUser();
    const history = useHistory();
    // const csvLink = useRef()
    // const [getNameButtonDisable, setGetNameButtonDisable] = useState(false);
    const limit = 10;
    const [getData, setData] = useState([]);
    const [isLimit, setLimit] = useState(limit)
    const [page, setPage] = useState(1);
    const [saveCustomerMobile, setCustomerMobile] = useState(props.customerData && props.customerData.customerMobile);
    const [isLoading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState(null);
    const [getDataField, setDataField] = useState({});
    const [timer, setTimer] = useState(null);
    const [latitude, setLatitude] = useState("");
    const [longitude, setLongitude] = useState("");
    const [bankId, setBankId] = useState(null);
    const [isAddBaneModal, setAddBaneModal] = useState(false);
    const [isSelectedUser, setIsSelectedUser] = useState({});
    const [isSendMoneyModal, setSendMoneyModal] = useState(false);
    const [isSendButtonDisable, setSendButtonDisable] = useState(true);

    const [invoiceDataModal, setInvoiceDataModal] = useState(false);
    const [invoiceData, setInvoiceData] = useState(null);

    const [transactionData, setTransactionData] = useState(null);
    const [transactionId, setTransactionId] = useState(null);
    const [transactionIndex, setTransactionIndex] = useState(0);
    const [transactionSlab, setTransactionSlab] = useState(null);
    const [transactionLength, setTransactionSlabLength] = useState(null);
    const [successTransaction, setSuccessTransaction] = useState([]);

    const [isOtpModal, setOtpModal] = useState(false);
    const [otpCode, setOtpCode] = useState(null)
    const [otpReference, setOtpReference] = useState(null)

    const [sendMoneyAmount, setSendMoneyAmount] = useState([]);
    const [getBeneData, setBeneData] = useState({});
    const [getBeneError, setBeneError] = useState({});
    const [getEKOBankListData, setEKOBankListData] = useState([]);
    const [getBankListData, setBankListData] = useState([{ "value": '', "label": '-- Select Bank --' }]);
    const [selectedOption, setSelectedOption] = useState(null);

    const [isIfscEnable, setIfscEnable] = useState(false);
    const [importModal, setImportModal] = useState(false);
    const [instantTransfer, setInstantTransfer] = useState(false);
    const [instantTransferError, setInstantTransferError] = useState(false);
    const [transactionStatus, setTransactionStatus] = useState(null);
    const [transactionStatusText, setTransactionStatusText] = useState(null);
    const [isOtpTransactionModal, setOtpTransactionModal] = useState(false);
    const [referenceKey, setReferenceKey] = useState(null)
    const [isPaymentStatusModal, setPaymentStatusModal] = useState(false);
    const [chargesData, setChargesData] = useState(null);

    const [aadharVerifyModal, setAadharVerifyModal] = useState(false);
    const [aadharVerifyDetail, setAadharVerifyDetail] = useState(null);

    const columns = [{
        name: 'Nick Name',
        selector: row => <><div>{row.beneName}</div>{row.beneMobile && <div><strong>Mobile</strong>: {row.beneMobile}</div>}</>,
        width: "200px",
    }, {
        name: 'IFSC / Bank',
        selector: row => <><div><strong>IFSC</strong>: {row.ifscCode}</div>{row.bankName && <div><strong>Bank</strong>: {row.bankName}</div>}</>,
        width: "200px",
    }, {
        name: 'Account',
        selector: row => <><div>{row.accountNumber}</div><div><strong>UP</strong>: {getUTCToLocalDateFormat(row.updatedAt)}</div></>,
        width: "150px",
    }, {
        name: 'Bene Name',
        selector: row => <><div><strong>{row.isVerified ? <CheckOutlined className='text-120 text-success border p-1' /> : <CloseOutlined className='text-120 text-danger border p-1' />}</strong> {row.beneBankName} </div></>,
        width: "200px",
    }, {
        name: 'Verified',
        selector: row => <>{(row.isVerified) ? <button onClick={() => getName(row, true)} className={`pointer text-success`} disabled={row.isActive ? false : true} >{RE_CHECK}</button> : <button onClick={() => getName(row, false)} className={`pointer text-danger`} disabled={row.isActive ? false : true} >{GET_NAME}</button>}</>,
        width: "150px",
    }, {
        name: 'Status',
        selector: row => (row.isActive) ? <button onClick={() => beneStatus(row, false)} className={`pointer text-success`} >Active</button> : <button onClick={() => beneStatus(row, true)} className={`pointer text-danger`} >Deactive</button>,
        width: "80px",
    }, {
        name: 'Amount',
        selector: (row, index) => <><input type='text' name="amount" className="form-control" onChange={(e) => handleEditRowAmount(e, index)} placeholder='Amount' onKeyDown={isNumber} maxLength={5} disabled={row.isActive ? false : true} /></>,
        width: "120px",
    }, {
        name: 'Action',
        selector: (row) => <><Button className={"bg-primary"} onClick={() => sendMoney(row)} disabled={row.isActive ? isSendButtonDisable : true} small >Send</Button></>,
        width: "150px",
        fixed: "right",
    }];


    useEffect(() => {
        // console.log("props.customerData.customerMobile ---" ,props.customerData)
        setCustomerMobile(props.customerData && props.customerData.customerMobile);
    }, [props.customerData]) // eslint-disable-line react-hooks/exhaustive-deps

    const beneStatus = (data, status) => {
        let params = {}
        params.id = data._id;
        params.status = status;
        postRequest(UPDATE_DMT_PAY_SPRINT_BENEFICIARY_STATUS, params, history).then((response) => {
            if (response.success === true) {
                getDataReports();
            }
            toast.success(response.message, {
                toastId: 'success1',
            });
        }).catch(function (error) {
            // console.log(error);
        })
    }

    const updateBeneData = (data) => {
        let params = {}
        params.id = data._id;
        params.beneBankName = data.beneBankName;
        postRequest(UPDATE_DMT_PAY_SPRINT_BENEFICIARY_DETAIL, params, history).then((response) => {
            if (response.success === true) {
                getDataReports();
            }
        }).catch(function (error) {
            // console.log(error);
        })
    }

    const getBankList = () => {
        getRequest(GET_DMT_NEW_BANK_LIST, history).then((response) => {
            if (response.success === true) {
                let bankDataRecord
                if (response.data) {
                    bankDataRecord = response.data.filter((item) => item.key === "DMT2.0");
                }
                setEKOBankListData(bankDataRecord);
                setBankListData([...getBankListData, ...bankDataRecord.map((item) => {
                    return { "value": item.BankID, "label": item.BANK_NAME };
                })]);
            }
        }).catch(function (error) {
            // console.log(error);
        })
    }

    const onChangeBankIfscCode = (value) => {
        // console.log('value -----', value)
        if (value) {
            let findBank = getEKOBankListData.filter(item => item.BankID === value);
            if (findBank.length > 0) {
                // console.log('findBank -----', findBank)
                setSelectedOption({ "value": findBank[0].BankID, "label": findBank[0].BANK_NAME });
                if (findBank[0].ifsc) {
                    setBeneData({ ...getBeneData, ifscCode: findBank[0].ifsc });
                    let obj = {}
                    obj.target = { name: "ifscCode", value: findBank[0].ifsc }
                    enterIfscCode(obj);
                    setIfscEnable(true)
                } else {
                    setBeneData({ ...getBeneData, ifscCode: "" });
                    setIfscEnable(false)
                }
                // console.log('findBank[0].BankID -----', findBank[0].BankID)
                setBankId(findBank[0].BankID);
            } else {
                setBankId(null);
            }
            // setBeneData({ ...getBeneData, ifscCode: value });
            // setIfscCodeModal(false)
        } else {
            setBankId(null);
            setIfscEnable(false);
            setBeneData({ ...getBeneData, ifscCode: "" });
            // setBeneData({ ...getBeneData, ifscCode: value });
        }
    }

    const handleEditRowAmount = (event, index) => {
        // console.log("event ---------------", event)
        // console.log("index ---------------", index)
        // console.log("getData ---------------", getData)
        let array = getData;
        array.docs[index].amount = event.target.value;
        if (event.target.value > 25000) {
            toast.error('Maximum amount is: 25000', {
                toastId: 'error1',
            });
            setSendButtonDisable(true)
            event.preventDefault();
            return false;
        } else {
            if (event.target.value >= 100) {
                setSendButtonDisable(false)
            } else {
                setSendButtonDisable(true)
            }
            setData(array);
        }
    }

    const handleAmount = (event) => {
        setSendMoneyAmount(event.target.value)
        // console.log("object ---------------", object)
    }

    const onChangeAddBeneName = async (event) => {
        let { name, value } = event.target;
        // console.log("isAlphabetOnly 2 name------", name)
        // console.log("isAlphabetOnly value ------", value)
        // console.log("isAlphabetOnly ------", typeof isAlphabetOnly(value))
        if (isAlphabetOnly(value)) {
            // console.log("isAlphabetOnly IF------")
            setBeneData({ ...getBeneData, [name]: value });
        } else {
            event.preventDefault();
        }
    }

    const enterIfscCode = async (event) => {
        let { name, value } = event.target;
        // console.log("event ------", event)
        setBeneData({ ...getBeneData, [name]: value });
        if (isIfscCodeValid(value)) {
            onChangeIfscCode(value);
            setBeneError({ ...getBeneError, "ifscCodeVaild": 1 });
            // console.log("isAlphabetOnly IF------")    
        } else {
            setBeneError({ ...getBeneError, "ifscCodeVaild": 2 });
            // event.preventDefault();
        }
    }

    const onChangeAddBene = (event) => {
        let { name, value } = event.target;
        // console.log("isAlphabetOnly 1 name------", name)
        setBeneData({ ...getBeneData, [name]: value });
    }

    const sendMoney = (row) => {
        if (!row.amount) {
            toast.error("Amount is empty");
            return false;
        }
        setSendMoneyModal(true)
        row.isChannel = 2;
        setIsSelectedUser(row);

        const payload = {
            customer_id: saveCustomerMobile,
            // recipient_id: data.beneId,
            accountNumber: row.accountNumber,
            ifsccode: row.ifscCode,
            beneAutoId: row._id,
            amount: row.amount,
            channel: row.isChannel,
            latitude: latitude,
            longitude: longitude,
            serviceName: "DMT2"
        }
        handleConfirmSubmit(payload);
    }

    const handleConfirmSubmit = (data) => {
        setLoading(true)
        postRequest(`${POST_DMT_PAY_SPRINT_CONFIRM_INITIATE_TRANSACTION}`, data, history).then((response) => {
            if (response.success === true) {
                setChargesData(response.data);
            } else {
                toast.error(response.message);
            }
            setLoading(false);
        }).catch(function (error) {
            // console.log(error)
            setLoading(false);
        });
    }

    const handleSyncBeneficiary = () => {
        let payload = { customerMobile: saveCustomerMobile, isSync: true }
        postRequest(`${POST_DMT_PAY_SPRINT_CUSTOMER_INFO}`, payload, history).then((response) => {
            if (response.success === true) {
                getDataReports();
            }
        }).catch(function (error) {
            // console.log(error)
        })
    }

    const handleSave = (data) => {
        if (sendMoneyAmount !== data.amount) {
            toast.error("Amount did not matched.")
        } else {
            const payload = {
                customer_id: saveCustomerMobile,
                // recipient_id: data.beneId,
                accountNumber: data.accountNumber,
                ifsccode: data.ifscCode,
                beneAutoId: data._id,
                amount: sendMoneyAmount,
                latitude: latitude,
                channel: data.isChannel,
                longitude: longitude,
                serviceName: "DMT2",
                instantTransfer: instantTransfer ? 'Y' : 'N'

            }
            handleSubmit(payload);
        }
    }

    const handleSubmit = (data) => {
        setLoading(true)
        postRequest(`${POST_DMT_PAY_SPRINT_INITIATE_TRANSACTION}`, data, history).then((response) => {
            if (response.success === true) {
                handleHideModal();
                setTransactionData(response.data);
                if (response.invoice) {
                    getDataReports();
                    handleInvoice(response.data);
                } else {
                    setTransactionIndex(0);
                    let slabarray = response.transactionSlab;
                    setTransactionSlab(slabarray);
                    setTransactionSlabLength(slabarray.length);
                    generateTransaction(slabarray[0]);
                }
                setSuccessTransaction([]);
                toast.success(response.message);
            } else {
                toast.error(response.message);
            }
            setLoading(false);
        }).catch(function (error) {
            // console.log(error)
            setLoading(false);
            handleHideModal();
        });
    }

    const generateTransaction = async (data) => {
        // console.log('transactionIndex 1----------------------', transactionIndex);
        // console.log('transactionLength 2----------------------', transactionLength);
        if (data) {
            setLoading(true)
            let payload = {
                "transactionId": data.transactionId,
            }
            await postRequest(`${POST_DMT_PAY_SPRINT_GENERATE_TRANSACTION}`, payload, history).then((response) => {
                if (response.success === true) {
                    setTransactionId(data.transactionId)
                    setOtpCode(null);
                    setOtpTransactionModal(true);
                    setReferenceKey(response.data.referenceKey);
                    toast.success(response.message);
                } else {
                    setOtpTransactionModal(false);
                    toast.dismiss();
                    toast.error(response.message);
                }
                setPaymentStatusModal(false);
                setLoading(false);
            }).catch(function (error) {
                // console.log(error)
                setLoading(false);
                handleHideModal();
            });
        } else {
            getDataReports();
            setTransactionStatus("");
            setTransactionStatusText("")
            handleInvoice(transactionData);
        }
    }

    const generateNextTransaction = async () => {
        // console.log('transactionIndex ----------------------', transactionIndex);
        // console.log('transactionLength ----------------------', transactionLength);
        if (transactionIndex === transactionLength) {
            getDataReports();
            setTransactionStatus("");
            setTransactionStatusText("")
            handleInvoice(transactionData);
        } else {
            // console.log('transactionIndex ----------------------', transactionIndex);
            // console.log('transactionSlab ----------------------', transactionSlab);
            // console.log('data[transactionIndex] ----------------------', transactionIndex, transactionSlab[transactionIndex].transactionId);
            await generateTransaction(transactionSlab[transactionIndex]);
        }
    }

    const handlePayment = async () => {
        setLoading(true)
        let payload = {
            "transactionId": transactionId,
            "referenceKey": referenceKey,
            "otp": otpCode,
        }
        await postRequest(`${POST_DMT_PAY_SPRINT_PAYMENT_TRANSFER}`, payload, history).then((response) => {
            if (response.success === true) {
                let array = successTransaction;
                array.push(transactionId);
                setSuccessTransaction(array);
                // if (successTransaction && successTransaction.length > 0) {
                //     successTransaction.splice(transactionIndex, 1);
                //     // setSuccessTransaction(successTransaction);
                // }
                // console.log("successTransaction ---------------->>>>", successTransaction);
                // console.log("transactionIndex ---------------->>>>", transactionIndex, transactionLength);
                if (response.invoice) {
                    getDataReports();
                    setTransactionStatus("");
                    setTransactionStatusText("")
                    handleInvoice(response.data);
                } else if (transactionIndex === transactionLength) {
                    getDataReports();
                    setTransactionStatus("");
                    setTransactionStatusText("")
                    handleInvoice(transactionData);
                } else {
                    setTransactionStatus(response.statusCode);
                    setTransactionStatusText(response.statusText);
                    setPaymentStatusModal(true);
                }
                // console.log("transactionIndex 1 ------------------", transactionIndex);
                setTransactionIndex(parseInt(transactionIndex) + 1);
                // console.log("transactionIndex 2 ------------------", transactionIndex);
                setOtpTransactionModal(false);
                setOtpModal(false);
                handleHideModal();
            } else {
                setTransactionStatus("1");
                setTransactionStatusText("Failed")
                setOtpTransactionModal(false);
                toast.error(response.message);
            }
            setLoading(false);
        }).catch(function (error) {
            // console.log(error)
            setLoading(false);
            handleHideModal();
        });
    }

    const handlePaymentFail = () => {
        // console.log("successTransaction -------------------------", successTransaction)
        if (successTransaction) {
            const unmatchedObjects = transactionSlab.filter(item => !successTransaction.includes(item.transactionId));

            postRequest(`${POST_DMT_FAILED_BY_PARTNER}`, { data: JSON.stringify(unmatchedObjects) }, history).then((response) => {
                if (response.success === true) {
                    getDataReports();
                    handleInvoice(transactionData);
                } else {
                    toast.error(response.message);
                }
                setLoading(false);
            }).catch(function (error) {
                // console.log(error)
                setLoading(false);
            });

            setTransactionStatus(null);
            setTransactionStatusText(null);
            setPaymentStatusModal(false);
            setOtpTransactionModal(false);
            setOtpModal(false);
            handleHideModal();
        }
    }

    const handleInvoice = (data) => {
        setInvoiceDataModal(true);
        setTransactionStatus("");
        setTransactionStatusText("")
        setTransactionSlab(null);
        setTransactionData(null);
        setPaymentStatusModal(false)
        setInvoiceData(data);
    }

    const handleInvoiceModal = () => {
        setInvoiceDataModal(false);
        setPaymentStatusModal(false)
        setImportModal(false);
    }

    const addBane = () => {
        setAddBaneModal(true);
        setBeneData({})
        setDataField({})
    }

    const FormBaneValidate = () => {
        let formValid = true;
        let formObject = {};
        setBeneError({})
        if (getBeneData && getBeneData.length === 0) {
            formValid = false;
        }
        let array = ["name", "mobile", "ifscCode", "accountNumber"];
        array.forEach(element => {
            if (getBeneData[element] === "" || typeof getBeneData[element] === "undefined") {
                formObject[element] = true;
                formValid = false;
            }
        });

        setBeneError(formObject);
        return formValid;
    }

    const addBaneSave = () => {
        // console.log("-------------- Save")
        // console.log("-------------- getBeneData",getBeneData)
        if (FormBaneValidate() === true) {
            // console.log("SAVE-------------- getBeneData",getBeneData)
            const payload = {
                customerMobile: saveCustomerMobile,
                recipientMobile: getBeneData.mobile,
                accountNumber: getBeneData.accountNumber,
                ifscCode: getBeneData.ifscCode,
                bankId: bankId,
                recipientName: getBeneData.name,
                beneBankName: getBeneData.beneBankName
            };
            setLoading(true)
            postRequest(`${POST_DMT_PAY_SPRINT_ADD_BENEFICIARY}`, payload, history).then((response) => {
                if (response.success === true) {
                    if (response.isotp === true) {
                        setOtpModal(true);
                        setOtpReference(response.otpReference);
                    } else {
                        getDataReports();
                        setBeneData({});
                        setAddBaneModal(false)
                    }
                    toast.success(response.message);
                } else {
                    toast.error(response.message);
                }
                setLoading(false)
            }).catch(function (error) {
                // console.log(error)
                setLoading(false)
            })
        }
    }

    const importBaneficiarySave = (data) => {
        // console.log("-------------- Save")
        // console.log("-------------- getBeneData",getBeneData)
        if (data) {
            // console.log("SAVE-------------- getBeneData",getBeneData)
            const payload = data;

            setLoading(true)
            postRequest(`${POST_DMT_PAY_SPRINT_ADD_BENEFICIARY}`, payload, history).then((response) => {
                if (response.success === true) {
                    setImportModal(false);
                    if (response.isotp === true) {
                        setOtpModal(true);
                        setOtpReference(response.otpReference);
                    } else {
                        getDataReports();
                        setBeneData({});
                        setAddBaneModal(false)
                    }
                    toast.success(response.message);
                } else {
                    toast.error(response.message);
                }
                setLoading(false)
            }).catch(function (error) {
                // console.log(error)
                setLoading(false)
            })
        }
    }

    const onChangeOtpValue = (value) => {
        setOtpCode(value);
    }

    const handleCustomerVerify = () => {
        // console.log("========== verify")
        if (otpCode) {
            setLoading(true)
            // console.log("========== otpCode", otpCode)
            const payload = {
                otpReference: otpReference,
                otp: otpCode,
                isBeneOtp: true,
                customerMobile: saveCustomerMobile,
            };

            postRequest(`${POST_DMT_PAY_SPRINT_OTP_VERIFICATION}`, payload, history).then((response) => {
                if (response.success === true) {
                    setOtpModal(false);
                    getDataReports();
                    setBeneData({});
                    setAddBaneModal(false)
                    toast.success(response.message);
                } else {
                    toast.error(response.message);
                }
                setLoading(false)
            }).catch(function (error) {
                // console.log(error)
                setLoading(false)
            })
        } else {
            toast.error("OTP code is required");
        }
    }

    const handleHideModal = () => {
        setSendMoneyModal(false)
        setAddBaneModal(false)
    };

    const objectToQueryString = (obj) => {
        var str = [];
        for (var p in obj)
            if (obj.hasOwnProperty(p)) {
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
        return str.join("&");
    }

    const params = { page: page, limit: isLimit };

    const getDataReports = (data) => {
        if (data) {
            setLimit(data)
            params.limit = data
        }
        if (searchText) {
            params.search = searchText;
        }
        if (props.customerData && props.customerData.customerMobile) {
            params.customerMobile = props.customerData.customerMobile;
            setCustomerMobile(params.customerMobile)
        }
        const queryString = objectToQueryString(params);
        getRequest(`${GET_DMT_PAY_SPRINT_BENEFICIARY_LIST}?${queryString}`, history).then((response) => {
            if (response.success === true) {
                if (response.data.length === 0) {
                    // props.findCustomerInfo()
                } else {
                    setData(response.data);
                }
                // setTotalRecord(response.data.totalDocs);
                // setPage(response.data.page);
            } else {
                // setData([]);
                // setTotalRecord(0);
                setPage(1);
            }
        }).catch(function (error) {
            // console.log(error)
            // setLoading(false);
        })
    }

    const handlePageChange = page => {
        setPage(page);
    };

    const getName = (data, recheck) => {
        if (data.accountNumber && data.ifscCode) {
            // console.log("--- Valid", getBeneData)
            // setGetNameButtonDisable(true);
            setLoading(true);
            const payload = {
                "ifsccode": data.ifscCode,
                "accountNumber": data.accountNumber,
                "externalRef": refId(13),
                "consent": "Y",
                "pennyDrop": "AUTO",
                "recheck": recheck,
                "latitude": latitude,
                "longitude": longitude
            };
            postRequest(`${GET_CUSTOMER_BANK_NAME_FROM_ACCOUNT_NUMBER}`, payload, history).then((response) => {
                if (response.success === true) {
                    // console.log("response.data-----------", response.data.data)
                    let record = response.data.data;
                    // setGetNameButtonDisable(false);
                    // let Object = { "beneBankName": record.nameAtBank }
                    data.beneBankName = record.nameAtBank
                    updateBeneData(data);
                } else {
                    // console.log("response.data-----------", response)
                    toast.error(response.message);
                }
                setLoading(false);
            }).catch(function (error) {
                setLoading(false);
                // console.log(error)
            });
        }
    }

    const getBankNameFromAccountNumber = () => {
        // console.log("--- Valid")
        // setGetNameButtonDisable(true);
        // setSeconds(60);
        if (getBeneData.accountNumber && getBeneData.ifscCode) {
            // setGetNameButtonDisable(true);
            setLoading(true);
            // console.log("--- Valid", getBeneData)
            const payload = {
                "ifsccode": getBeneData.ifscCode,
                "accountNumber": getBeneData.accountNumber,
                "externalRef": refId(13),
                "consent": "Y",
                "pennyDrop": "AUTO",
                "latitude": latitude,
                "longitude": longitude
            };
            postRequest(`${GET_CUSTOMER_BANK_NAME_FROM_ACCOUNT_NUMBER}`, payload, history).then((response) => {
                if (response.success === true) {
                    // console.log("response.data-----------", response.data.data)
                    // setGetNameButtonDisable(false);
                    let record = response.data.data;
                    setAadharVerifyModal(true)
                    setAadharVerifyDetail(response.data);
                    let Object = { "beneBankName": record.nameAtBank }
                    setBeneData({ ...getBeneData, ...Object });
                } else {
                    // console.log("response.data-----------", response)
                    toast.error(response.message);
                }
                setLoading(false);
            }).catch(function (error) {
                // console.log(error)
                setLoading(false);
            });
        } else {
            toast.error("Please enter valid account number")
            let Object = { "name": '' }
            setBeneData({ ...getBeneData, ...Object });
        }
    }

    const onChangeIfscCode = (val = null) => {
        let value;
        if (val) {
            value = val;
        } else {
            value = getBeneData && getBeneData.ifscCode;
        }

        if (isIfscCodeValid(value)) {
            // console.log("--- Valid", value)
            const payload = { "ifsccode": value };
            postRequest(`${IFSC_CODE_VERIFY}`, payload, history).then((response) => {
                if (response.success === true) {
                    // console.log("response.data-----------", response.data.data)
                    let record = response.data.data;
                    let Object = {
                        "ifscCode": record.ifsc,
                        "address": record.address,
                        "bankName": record.bank,
                        "branchAddress": record.branch,
                        "city": record.city,
                        "state": record.state,
                    }
                    setDataField(Object);
                } else {
                    // console.log("response.data-----------", response)
                }
            }).catch(function (error) {
                // console.log(error)
            });
        } else {
            if (value && value.length === 11) {
                toast.error("Please enter valid ifsccode")
                let Object = {
                    "address": '',
                    "bankName": '',
                    "branchAddress": '',
                    "city": '',
                    "state": '',
                }
                setDataField(Object);
            }
        }
    }

    const handleSearch = (e) => {
        // console.log("searchText----",e.target.value)
        setSearchText(e.target.value);
    }

    const onChangeChannel = ({ target: { value } }) => {
        // console.log("value-------------- value", value)
        let isSelectedUserValue = { ...isSelectedUser };
        isSelectedUserValue.isChannel = value;
        setIsSelectedUser(isSelectedUserValue);
    };

    const onChangeCheckbox = (e) => {
        if (e.target.checked) {
            // console.log(`checked = ${e.target.checked}`);
            if (chargesData && parseFloat(chargesData.instantLimit) < isSelectedUser.amount) {
                setInstantTransferError(true);
            } else {
                setInstantTransfer(e.target.checked);
                setInstantTransferError(false);
            }
        } else {
            setInstantTransfer(e.target.checked);
            setInstantTransferError(false);
        }
    };

    useEffect(() => {
        if (timer) {
            clearTimeout(timer)
            setTimer(null)
        }
        setTimer(
            setTimeout(() => {
                getDataReports()
            }, 100)
        )
    }, [searchText]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (props.customerData && props.customerData.customerMobile) {
            getDataReports();
        }
    }, [page, props.handleSearch]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (props.customerData && props.customerData.customerMobile) {
            // getDataReports();
        }
        getBankList();
        // getEkoBankList();
        navigator.geolocation.getCurrentPosition((data) => {
            setLatitude(data.coords.latitude)
            setLongitude(data.coords.longitude)
        });
        // setIsDownloadCsv(isDownloadCsv ? true : false);
    }, [props.customerData]) // eslint-disable-line react-hooks/exhaustive-deps

    const handleImport = () => {
        setImportModal(true);
    }

    if (isLoading) {
        return <div className="content text-center" style={{ margin: "250px 0" }}>
            <PropagateLoader color="#5851D2" loading={isLoading} size={25} />
        </div>
    }

    return (
        <div>
            <div className=" pt-2 pb-5">
                <div className="col-md-12">
                    <div className="row pb-3">
                        <div className='col-md-6 date-search'>
                            <div className='row'>
                                <div className="input-group">
                                    <input className="form-control py-2 border-right-0 border" type="search" defaultValue={searchText} value={searchText} onKeyDown={isNumber} placeholder='Search Account Number...' maxLength={20} onChange={(e) => handleSearch(e)} />
                                    <span className="input-group-append">
                                        <button className="btn btn-outline-secondary border-left-0 border" type="button" onClick={() => getDataReports()}>
                                            <i className="fa fa-search" />
                                        </button>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6 p-0'>
                            <div className='float-sm-right'>
                                <button type="button" className="btn btn-primary mr-2" onClick={() => handleImport()}>
                                    <i className="fa fa-users"></i> Import
                                </button>
                                <button type="button" className="btn btn-primary mr-2" onClick={() => handleSyncBeneficiary()}>
                                    <i className="fa fa-users"></i> View Beneficiary
                                </button>
                                <button type="button" className="btn btn-primary" onClick={() => addBane()}>
                                    <i className="fa fa-user"></i> Add Beneficiary
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="box-body">
                        <DataTable
                            columns={columns}
                            data={getData.docs}
                            // progressPending={getData.length > 0 ? false : true}
                            highlightOnHover
                            pagination
                            paginationServer
                            paginationTotalRows={getData.totalDocs}
                            onChangeRowsPerPage={getDataReports}
                            onChangePage={handlePageChange}
                        />
                    </div>
                </div>
            </div>
            <ToastContainer position="top-center" autoClose={5000} limit={2} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="colored" />
            {isAddBaneModal && <Modal title={"Add Beneficiary"} width={400} open={isAddBaneModal} onOk={() => addBaneSave()} onCancel={handleHideModal} okText={"Submit"} cancelText={"Cancel"}>
                <div className='row'>
                    <div className="form-group col-lg-12 col-md-12">
                        <label>Account Holder Mobile</label>
                        <div className="input-group">
                            <div className="custom-file">
                                <input type="text" className="form-control" name="mobile" placeholder="Mobile Number" onChange={(e) => onChangeAddBene(e)} autoComplete="off" value={getBeneData.mobile} maxLength={10} onKeyDown={isNumber} />
                            </div>
                        </div>
                        {getBeneError && getBeneError.mobile && <span className="error text-danger">The field is required</span>}
                    </div>
                    <div className="form-group col-lg-12 col-md-12">
                        <label>Account Holder Name</label>
                        <div className="input-group">
                            <div className="custom-file">
                                <input type="text" className="form-control" name="name" placeholder="Account Holder Name" onChange={(e) => onChangeAddBeneName(e)} autoComplete="off" value={getBeneData && getBeneData.name} maxLength={20} />
                            </div>
                        </div>
                        {getBeneError && getBeneError.name && <span className="error text-danger">The field is required</span>}
                    </div>
                    <div className="form-group col-lg-12 col-md-12">
                        <label>Bank Name</label>
                        <div className="input-group">
                            <div className="custom-file">
                                <Select options={getBankListData} className={`normal-light w-100`} defaultValue={selectedOption} classNamePrefix="select" placeholder="Select Bank" name='mode' onChange={(e) => onChangeBankIfscCode(e.value)} />
                            </div>
                        </div>
                    </div>
                    <div className="form-group col-lg-12 col-md-12">
                        <label>Global IFSC Code</label>
                        <div className="input-group">
                            <div className="custom-file">
                                <input type="text" className="form-control" name="ifscCode" placeholder="Enter IFSC Code" onChange={(e) => enterIfscCode(e)} autoComplete="off" readOnly={isIfscEnable} value={getBeneData.ifscCode} maxLength={11} />
                                <div className="inputgrouptext cursor-pointer text-success" onClick={() => onChangeIfscCode(getBeneData && getBeneData.ifscCode)}>Verify Branch</div>
                            </div>
                        </div>
                        {getBeneError && getBeneError.ifscCode && <span className="error text-danger">The field is required</span>}
                        {getBeneError && getBeneData.ifscCode && getBeneError.ifscCodeVaild === 2 && <span className="error text-danger">The IFSC Code format is not valid</span>}
                        {getBeneError && getBeneError.ifscCodeVaild === 1 && <span className=" text-success">The IFSC Code format is valid</span>}
                    </div>
                    {getDataField && getDataField.address && getDataField.bankName && getDataField.branchAddress && getDataField.city && getDataField.state && <div className="form-group col-lg-12 col-md-12">
                        <div className="custom-file">
                            <ul>
                                <li>Address: <strong>{getDataField.address}</strong></li>
                                <li>Bank Name: <strong>{getDataField.bankName}</strong></li>
                                <li>Branch Address: <strong>{getDataField.branchAddress}</strong></li>
                                <li>City: <strong>{getDataField.city}</strong></li>
                                <li>State: <strong>{getDataField.state}</strong></li>
                            </ul>
                        </div>
                    </div>}
                    <div className="form-group col-lg-12 col-md-12">
                        <label>Account Number</label>
                        <div className="input-group">
                            <div className="custom-file">
                                <input type="text" className="form-control" name="accountNumber" placeholder="Account Number" onChange={(e) => onChangeAddBene(e)} autoComplete="off" value={getBeneData.accountNumber} maxLength={20} />
                                <button className={`inputgrouptext cursor-pointer text-success`} onClick={() => getBankNameFromAccountNumber()} >{GET_NAME}</button>
                            </div>
                        </div>
                        {getBeneError && getBeneError.accountNumber && <span className="error text-danger">The field is required</span>}
                    </div>
                    {getBeneData.beneBankName && <div className="form-group col-lg-12 col-md-12">
                        <label>Bene Bank Name</label>
                        <div className="input-group">
                            <div className="custom-file">
                                <input type="text" className="form-control" readOnly autoComplete="off" value={getBeneData.beneBankName} maxLength={20} />
                            </div>
                        </div>
                    </div>}
                </div>
            </Modal>}

            {isOtpModal && <Modal title={"Customer Verification OTP"} width={600} open={isOtpModal} onOk={() => handleCustomerVerify()} onCancel={() => setOtpModal(false)} okText={"Submit"} >
                <div className="form-group">
                    <label>OTP Code</label>
                    <div className="input-group">
                        <div className="custom-file">
                            <input type="text" className="form-control" name="otpCode" placeholder="OTP Code" onChange={(e) => onChangeOtpValue(e.target.value)} autoComplete="off" value={otpCode} maxLength={6} onKeyDown={isNumber} />
                        </div>
                    </div>
                </div>
            </Modal>}

            {isOtpTransactionModal && <Modal title={"Transaction OTP Verification"} width={600} open={isOtpTransactionModal} onOk={() => handlePayment()} onCancel={() => handlePaymentFail()} okText={"Submit"} >
                <div className='col-md-12'>
                    <div> Transaction : <strong>{transactionId}</strong></div>
                    <div> Amount : <strong>{transactionSlab && transactionSlab[transactionIndex].amount}</strong></div>
                </div>

                <div className="form-group pt-5">
                    <label>OTP Code</label>
                    <div className="input-group">
                        <div className="custom-file">
                            <input type="text" className="form-control" name="otpCode" placeholder="OTP Code" onChange={(e) => onChangeOtpValue(e.target.value)} autoComplete="off" value={otpCode} maxLength={6} onKeyDown={isNumber} />
                        </div>
                    </div>
                </div>
            </Modal>}

            {isSendMoneyModal && <Modal title={"Money Transfer Verification"} width={600} open={isSendMoneyModal} onOk={() => handleSave(isSelectedUser)} onCancel={handleHideModal} okText={"Continue"} cancelText={"Cancel"}>
                <div className='row'>
                    <div className='col-md-12 form-group d-flex'>
                        <div className='col-md-8'>
                            <div> Bank Name : <strong>{isSelectedUser.bankName}</strong></div>
                            <div> IFSC Code : <strong>{isSelectedUser.ifscCode}</strong></div>
                            <div> Account Number : <strong>{isSelectedUser.accountNumber}</strong></div>
                            <div> Customer Name : <strong>{isSelectedUser.beneName}</strong></div>
                        </div>
                        <div className='col-md-4'>
                            {chargesData && <><div> Service Charge : <strong>{chargesData.serviceAmount}</strong></div>
                                <div> Commission : <strong>{chargesData.commissionAmount}</strong></div>
                                <div> TDS : <strong>{chargesData.tdsAmount}</strong></div></>}
                        </div>
                    </div>
                    {chargesData && chargesData.instantOption && <div className='col-md-12 form-group'>
                        <Checkbox onChange={onChangeCheckbox} checked={instantTransfer}> Instant Transfer</Checkbox><span className="mt-2">(Remaining Limit: {chargesData.instantLimit})</span>
                    </div>}
                    {instantTransferError && <p className="text-danger">Instant Transfer available limit: {chargesData.instantLimit}</p>}
                    <div className='col-md-12 form-group'>
                        <label>Mode: </label>
                        <br />
                        <Radio.Group defaultValue={2} buttonStyle="solid" onChange={onChangeChannel} value={isSelectedUser.isChannel} optionType="button" >
                            <Radio.Button value={1}> NEFT </Radio.Button>
                            <Radio.Button value={2}> IMPS </Radio.Button>
                        </Radio.Group>
                    </div>
                    <div className='col-md-12 form-group'>
                        <label>Confirm Amount: </label>
                        <input type='text' name="amount" className="form-group form-control" value={sendMoneyAmount} onChange={(e) => handleAmount(e)} onKeyDown={isNumber} maxLength={5} placeholder="Confirm Amount" />
                    </div>
                </div>
            </Modal>}
            {isPaymentStatusModal && <Modal title={"Payment Status"} width={600} open={isPaymentStatusModal} onOk={() => generateNextTransaction()} onCancel={() => handlePaymentFail()} okText={(transactionIndex === transactionLength) ? "Print Invoice" : "Continue"} >
                <div className="form-group">
                    <h5 className='text-center mt-3'>Transaction : {transactionId}</h5>
                    <div className="text-center my-3">
                        {transactionStatus === "1" && <><div style={{ borderRadius: 200, height: 200, width: 200, background: '#ffe6e6', margin: '0 auto' }}>
                            <b style={{ color: "#e60000" }} className="checkmark">x</b>
                        </div><h1>{transactionStatusText}</h1></>}

                        {transactionStatus === "0" && <><div style={{ borderRadius: 200, height: 200, width: 200, background: '#F8FAF5', margin: '0 auto' }}>
                            <i className="checkmark">✓</i>
                        </div>
                            <h1>{transactionStatusText}</h1>
                        </>}

                        {(transactionStatus !== "0" && transactionStatus !== "1") && <><div style={{ borderRadius: 200, height: 200, width: 200, background: '#ffffb3', margin: '0 auto' }}>
                            <i className="checkmark"><i className="fa fa-clock-o" aria-hidden="true"></i></i>
                        </div>
                            <h1>{transactionStatusText}</h1>
                        </>}
                    </div>
                </div>
            </Modal>}
            {invoiceDataModal && <Modal open={invoiceDataModal} width={800} footer={false} onCancel={handleInvoiceModal} closable={false}>
                <DmtInvoice transactionId={invoiceData.client_ref_id} />
            </Modal>}
            {importModal && <Modal open={importModal} width={1200} footer={false} onCancel={handleInvoiceModal} closable={true} >
                <ImportBaneficiary mode={"DMT2.0"} customerMobile={saveCustomerMobile} addBaneficiary={importBaneficiarySave} />
            </Modal>}

            {aadharVerifyModal && <Modal title={"Aadhaar Verification Detail"} width={600} open={aadharVerifyModal} onOk={() => { setAadharVerifyDetail({}); setAadharVerifyModal(false) }} onCancel={() => { setAadharVerifyDetail({}); setAadharVerifyModal(false) }} okText={"Ok"} cancelText={"Cancel"}>
                <div className='row'>
                    <div className='col-md-12 form-group'>
                        <div> Name : <strong>{aadharVerifyDetail && aadharVerifyDetail.data.nameAtBank}</strong></div>
                        {/* <div> Account Status : <strong>{aadharVerifyDetail && aadharVerifyDetail.accountStatus}</strong></div>
                        <div> Bank Name : <strong>{aadharVerifyDetail && aadharVerifyDetail.data.bankName}</strong></div>
                        <div> refId : <strong>{aadharVerifyDetail && aadharVerifyDetail.data.refId}</strong></div>
                        <div> City : <strong>{aadharVerifyDetail && aadharVerifyDetail.data.city}</strong></div>
                        <div> Branch : <strong>{aadharVerifyDetail && aadharVerifyDetail.data.branch}</strong></div> */}

                        <div>{aadharVerifyDetail && aadharVerifyDetail.accountStatus === "VALID" ? <p className="text-bold text-success">{aadharVerifyDetail && aadharVerifyDetail.message}</p> : <p className="text-bold text-danger">{aadharVerifyDetail && aadharVerifyDetail.message}</p>}</div>
                    </div>
                </div>
            </Modal>}
        </div>
    )
}

export default App