import React, { useState, createRef, useEffect } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import "./../webcss/ImageCropper.css";
import { Button } from "antd";

let defaultSrc =
  "https://raw.githubusercontent.com/roadmanfong/react-cropper/master/example/img/child.jpg";

export const ImageCropper = (props) => {
  const [image, setImage] = useState(defaultSrc);
  // const [cropData, setCropData] = useState("");
  const cropperRef = createRef();

  useEffect(() => {
    if (props.image) {
      // onChange(props.image)
      setImage(props.image);
    }
  }, [props.image])

  // const onChange = (e) => {
  //   e.preventDefault();
  //   let files;
  //   if (e.dataTransfer) {
  //     files = e.dataTransfer.files;
  //   } else if (e.target) {
  //     files = e.target.files;
  //     console.log("e.target.files ---", e.target.files)
  //   }
  //   const reader = new FileReader();
  //   reader.onload = () => {
  //     setImage(reader.result);
  //   };
  //   reader.readAsDataURL(files[0]);
  // };

  const getCropData = () => {
    if (typeof cropperRef.current?.cropper !== "undefined") {
      let URL = cropperRef.current?.cropper.getCroppedCanvas().toDataURL();
      // setCropData(URL);
      // console.log("cropData ---------------", URL)
      props.handleFileUpload(URL);
    }
  };

  const rotate = () => {
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;
    cropper.rotate(90);
  };

  return (
    <div className="container">
      <div className="col-12" style={{ width: "100%" }}>
        {/* <input type="file" onChange={onChange} /> */}
        <p className="my-2">Crop Image</p>
        <Cropper
          ref={cropperRef}
          style={{ height: 400, width: "100%" }}
          initialAspectRatio={16 / 9}
          zoomable={false}
          // initialAspectRatio={1}
          preview=".img-preview"
          src={image}
          viewMode={1}
          minCropBoxHeight={10}
          minCropBoxWidth={10}
          background={false}
          responsive={true}
          // autoCropArea={1}
          checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
          guides={true}
        />
        <Button className="mt-2" onClick={rotate}>Rotate &nbsp;<i class="fas fa-light fa-rotate-right"></i></Button>
        <hr />
        <Button className="btn btn-block btn-primary bg-blue" onClick={getCropData}> Crop & Upload Image </Button>
      </div>
      {/* <div className="col-12"> */}
      {/* <div className="box" style={{ width: "50%", float: "right" }}>
          <h1>Preview</h1>
          <div
            className="img-preview"
            style={{ width: "100%", float: "left", height: "300px" }}
          />
        </div>
        <div
          className="box"
          style={{ width: "50%", float: "right", height: "300px" }}
        >
          <h1>
            <span>Crop</span>
          </h1>
          <img style={{ width: "100%" }} src={cropData} alt="cropped" />
        </div>
      </div> */}
    </div>
  );
};

export default ImageCropper;
