import React, { useEffect, useState, useRef } from 'react';
import { Button, Modal } from 'antd';
import { CREATE_USER_PANCARD_VERIFY, MOBILE_SEND_VERFIY, MOBILE_VERFIY_SEND, CREATE_USERS_STAFF } from './../../Utils/appConstants';
import { postRequest } from './../../Utils/AppApi';
import { getUser, isNumber } from "./../../Utils/Common";
// import AppHeader from './../Header/Header';
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
// import AppFooter from './../Footer/Footer';
// import Select from 'react-select';
const userData = getUser();

function CreateStaff(props) {

    const userRoleRef = useRef(null);
    const mobileNumberRef = useRef(null);
    const otpNumberRef = useRef(null);
    const pancardNumberRef = useRef(null);

    const history = useHistory();
    const [isData, setData] = useState([]);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [pancardData, setPancardData] = useState("");
    const [isOtpNumber, setOtpNumber] = useState(false);
    const [roleId, setRoleId] = useState(userData.data.role.code);
    // const [userOrder, setUserOrder] = useState(userData.data.role.order);
    const [isMobileNumberVerify, setMobileNumberVerify] = useState(false);
    const [isPanNumberVerify, setPanNumberVerify] = useState(false);
    // const [isStaff, setStaff] = useState(false);

    const [showingSubmitPopup, setShowingSubmitPopup] = useState(false);

    const clearFormField = () => {
        mobileNumberRef.current.value = "";
        otpNumberRef.current.value = "";
        pancardNumberRef.current.value = "";
        userRoleRef.current.value = "";
    };

    const FormValidate = () => {
        let formValid = true;
        // let formObject = {};

        // if (userOrder === 5 || userOrder === 4 || userOrder === 2) {
        //     if (isData && isData.length === 0) {
        //         formValid = false;
        //     }
        //     if (isData.userRole === "" || typeof isData.userRole === "undefined") {
        //         formObject.userRole = true;
        //         formValid = false;
        //         toast.error("Role is required");
        //     }
        // }
        return formValid;
    } // eslint-disable-line react-hooks/exhaustive-deps

    const handleVerifyMobileNumber = () => {
        if (FormValidate()) {
            const payload = {
                mobile: isData.mobileNumber,
                roleId: roleId
            };
            postRequest(MOBILE_VERFIY_SEND, payload, history).then((response) => {
                if (response.success === true) {
                    toast.success(response.message);
                    setOtpNumber(true)
                } else {
                    toast.error(response.message);
                }
            }).catch(function (error) {
                // console.log(error)
                // setLoading(false);
            })
        }
    } // eslint-disable-line react-hooks/exhaustive-deps

    const handleVerifyOtpMobileNumber = () => {
        const payload = {
            mobile: isData.mobileNumber,
            otp: isData.OtpNumber
        }
        postRequest(MOBILE_SEND_VERFIY, payload, history).then((response) => {
            if (response.success === true) {
                toast.success(response.message);
                setOtpNumber(true)
                setMobileNumberVerify(true)
            } else {
                toast.error(response.message);
            }
        }).catch(function (error) {
            // console.log(error)
            // setLoading(false);
        })
    } // eslint-disable-line react-hooks/exhaustive-deps

    const pancardValidation = (text) => {
        let regex = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
        if (regex.test(text)) {
            return true;
        }
        return false;
    } // eslint-disable-line react-hooks/exhaustive-deps

    const VerifyPanNumber = (pancard) => {
        if (pancard.toUpperCase()) {
            if (pancardValidation(pancard.toUpperCase())) {
                return true;
            } else {
                toast.error("Pancard number is invalid");
                return false;
            }
        } else {
            toast.error("Pancard is required");
            return false;
        }
    } // eslint-disable-line react-hooks/exhaustive-deps

    const handleVerifyPancardNumber = async () => {
        if (VerifyPanNumber(isData.pancardNumber)) {
            if (FormValidate()) {
                const payload = { pancard: isData.pancardNumber, roleId: roleId };
                postRequest(`${CREATE_USER_PANCARD_VERIFY}`, payload, history).then((response) => {
                    if (response.success) {
                        setPanNumberVerify(true)
                        setPancardData(response.data);
                        const [first, middle, last] = response.data.registered_name.split(' ');
                        setFirstName(first);
                        let lastNameAdd = '';
                        if(last) {
                            lastNameAdd = middle+' '+last;
                        } else {
                            lastNameAdd = middle;
                        }
                        setLastName(lastNameAdd);
                        toast.success(response.message);
                    } else {
                        toast.error(response.message);
                    }
                }).catch(function (error) {
                    // console.log(error)
                    // setLoading(false);
                })
            }
        }
    } // eslint-disable-line react-hooks/exhaustive-deps

    const handleSubmit = async () => {
        const payload = {
            pancard: isData.pancardNumber,
            firstName: firstName,
            lastName: lastName,
            mobile: isData.mobileNumber,
            pancardData: pancardData
        };
        postRequest(`${CREATE_USERS_STAFF}`, payload, history).then((response) => {
            if (response.success) {
                setShowingSubmitPopup(true)
                setUsername(response.data.username)
                setPassword(response.data.password)
                setMobileNumberVerify(false);
                setPanNumberVerify(false);
                setPancardData("");
                setFirstName("");
                setLastName("");
                setOtpNumber(false);
                clearFormField();
                toast.success(response.message);
            } else {
                toast.error(response.message);
            }
        }).catch(function (error) {
            // console.log(error)
            // setLoading(false);
        })
    } // eslint-disable-line react-hooks/exhaustive-deps

    const handleCancelPopup = () => {
        setShowingSubmitPopup(false);
        setUsername("");
        setPassword("");
    }
    const onChangeValue = (event) => {
        const { name, value } = event.target;
        if (name === "pancardNumber") {
            setData({ ...isData, [name]: value.toUpperCase() });
        } else {
            setData({ ...isData, [name]: value });
        }
    } // eslint-disable-line react-hooks/exhaustive-deps

    // const onChangeUserRoleValue = (event) => {
    //     const { value } = event;
    //     setData({ ...isData, "userRole": value });
    // } // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        // handleGetRoles();
        setRoleId(userData.data.role.code);
        // setStaff(userData.data.isStaff)
        // setUserOrder(userData.data.role.order);
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            <div className="content-wrapper pt-2">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-0 align-items-center">
                            <div className="col-sm-6">
                                <h1 className='page-title text-blue font-merriweather'>Create <span className='text-red'> Employee</span></h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content">
                    <div className="container-fluid pt-2 pb-5">
                        <div className="row">
                            <div className="col-md-6">
                                <div className='card rounded-0'>
                                    <div className='card-body'>
                                        <div className="form-group">
                                            <label>Mobile Number</label>
                                            <div className="input-group">
                                                <div className="custom-file">
                                                    <input type="text" className="form-control" name="mobileNumber" placeholder="Mobile Number" onChange={(e) => onChangeValue(e)} autoComplete="off" maxLength={10} ref={mobileNumberRef} readOnly={isOtpNumber ? true : false} onKeyDown={isNumber} />
                                                </div>
                                                {!isMobileNumberVerify && !isOtpNumber &&
                                                    <div className="input-group-append uploadbtn cursor-pointer" onClick={() => handleVerifyMobileNumber()}>
                                                        <span className="input-group-text">Verify</span>
                                                    </div>}
                                            </div>
                                        </div>
                                        {isOtpNumber && !isMobileNumberVerify &&
                                            <div className="form-group">
                                                <label>OTP Number</label>
                                                <div className="input-group">
                                                    <div className="custom-file">
                                                        <input type="text" className="form-control" name="OtpNumber" placeholder="OTP Number" onChange={(e) => onChangeValue(e)} autoComplete="off" ref={otpNumberRef} maxLength={4} onKeyDown={isNumber} />
                                                    </div>
                                                    <div className="input-group-append uploadbtn cursor-pointer" onClick={() => handleVerifyOtpMobileNumber()}>
                                                        <span className="input-group-text">Verify</span>
                                                    </div>
                                                </div>
                                            </div>}
                                        {isMobileNumberVerify &&
                                            <div className="form-group">
                                                <label>Pancard Number</label>
                                                <div className="input-group">
                                                    <div className="custom-file">
                                                        <input type="text" className="form-control" name="pancardNumber" placeholder="Enter Pancard Number" onChange={(e) => onChangeValue(e)} maxLength={10} readOnly={isPanNumberVerify ? true : false} ref={pancardNumberRef} value={isData && isData.pancardNumber} />
                                                    </div>
                                                    <div className="input-group-append uploadbtn cursor-pointer" onClick={() => handleVerifyPancardNumber()}>
                                                        <span className="input-group-text">{isPanNumberVerify ? "Verifed" : "Verify"}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {isPanNumberVerify &&
                                            <>
                                                <div className="form-group">
                                                    <label>First Name</label>
                                                    <div className="input-group">
                                                        <div className="custom-file">
                                                            <input type="text" className="form-control" defaultValue={firstName} readOnly />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label>Last Name</label>
                                                    <div className="input-group">
                                                        <div className="custom-file">
                                                            <input type="text" className="form-control" defaultValue={lastName} readOnly />
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        }

                                        {isPanNumberVerify && isMobileNumberVerify && isOtpNumber &&
                                            <div className="form-group">
                                                <div className="input-group pull-right">
                                                    <Button type="default" onClick={() => handleSubmit()} className='ml-2'>Submit</Button>
                                                </div>
                                            </div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {showingSubmitPopup && (<Modal open={showingSubmitPopup} footer={false} onCancel={handleCancelPopup}>
                    <div className="font-weight-bold text-center text-danger" >
                        <div className="your-registration-is-successfull mt-3">
                            <p className="msg1">
                                Your Registration is successful. Please login using
                            </p>
                            <p className="user-id-xxxxx-one-time-pas">
                                Username - <span className="xxxxx-span">{username}</span> &amp; Password
                                - <span className="xxxxx-span">{password}</span>
                            </p>
                        </div>
                    </div>
                </Modal>)}
                <ToastContainer position="top-center" autoClose={5000} limit={2} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="colored" />
            </div>
        </div>
    )
}

export default CreateStaff;