import React, { useEffect } from 'react';
// import RBCarousel from "react-bootstrap-carousel";
// import "bootstrap/dist/css/bootstrap.css";
// import "react-bootstrap-carousel/dist/react-bootstrap-carousel.css";
// import moment from 'moment';
import './../../webcss/default.css';
import './../../webcss/styles.css';
import Header from './header';
import Footer from './footer';
// import { DatePicker, Input, Button, Select } from 'antd';

function ContactUs() {

    // const styles = { height: 480, width: "100%" };
    // const icon_glass = <span className="glyphicon glyphicon-glass" />;
    // const icon_music = <span className="glyphicon glyphicon-music" />;

    useEffect(() => {
        // setReportMode(ContactUss);
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            <div>
                <Header />
                <div>
                    <div className="notice-section notice-section-sm border-top red">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-10 col-sm-10 col-12">
                                    <div className="mt-5">
                                        <h6 className="fontwhite">Become a SR PAY Private Limited Franchise &amp; Grow your income 30% to 50% per month
                                        </h6>
                                    </div>
                                </div>
                                <div className="col-md-2 col-sm-2 col-12">
                                    <div className="right-holder-md">
                                        <a href="/joinus" className="primary-button button-lg mt-15-xs bluebtn">Join Now</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="page-title-section">
                        <div className="container">
                            <h1>Contact <span className="white"> Us</span></h1>
                        </div>
                    </div>
                    <div className="section-block">
                        <div className="container">
                            <div className="row mt-50">
                                <div className="col-md-6 col-sm-6 col-12">
                                    <div className="contact-data-box">
                                        <div className="row">
                                            <div className="col-md-3 col-sm-3 col-12">
                                                <div className="contact-data-box-icon"> <i className="fa fa-globe" />
                                                </div>
                                            </div>
                                            <div className="col-md-8 col-sm-8 col-12">
                                                <div className="contact-data-box-text">
                                                    <h4><span className="white">Registered </span> Office</h4>
                                                    <h6>SR PAY PRIVATE LIMITED <br />Plot No – 79, Sukhija Vihar Block - B, Ghandi Path West , Jaipur , Rajasthan, india – 302012</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-6 col-12">
                                    <div className="contact-data-box">
                                        <div className="row">
                                            <div className="col-md-3 col-sm-3 col-12">
                                                <div className="contact-data-box-icon"> <i className="fa fa-globe" />
                                                </div>
                                            </div>
                                            <div className="col-md-8 col-sm-8 col-12">
                                                <div className="contact-data-box-text">
                                                    <h4><span className="white">Communication </span>  Address </h4>
                                                    <h6>SR PAY PRIVATE LIMITED <br />Plot No – 79, Sukhija Vihar Block - B, Ghandi Path West , Jaipur , Rajasthan, india – 302012</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container">
                            <div className="row mt-50">
                                <div className="col-md-6 col-sm-4 col-12">
                                    <div className="contact-data-box">
                                        <div className="row">
                                            <div className="col-md-3 col-sm-3 col-12">
                                                <div className="contact-data-box-icon"> <i className="fa fa-envelope-open" />
                                                </div>
                                            </div>
                                            <div className="col-md-8 col-sm-8 col-12">
                                                <div className="contact-data-box-text">
                                                    <h4><span className="white">E</span>mail</h4>
                                                    <h6>support@srpay1.com</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-6 col-12">
                                    <div className="contact-data-box">
                                        <div className="row">
                                            <div className="col-md-3 col-sm-3 col-12">
                                                <div className="contact-data-box-icon"> <i className="fa fa-whatsapp" />
                                                </div>
                                            </div>
                                            <div className="col-md-8 col-sm-8 col-12">
                                                <div className="contact-data-box-text">
                                                    <h4><span className="white">Whatsapp </span> Support</h4>
                                                    <h6>+918053767570
                                                    </h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    )
}

export default ContactUs