import React, { useEffect, useState, useRef } from 'react';
import useForceUpdate from 'use-force-update';
import { isNumber } from '../../Utils/Common';
import PropagateLoader from "react-spinners/PropagateLoader";
import { getGeoLocation } from '../../Utils/function';
import { FING_PAY_BALANCE_ENQUIRY_API } from '../../Utils/appConstants';
import { postRequest } from '../../Utils/AppApi';
import { useHistory } from "react-router-dom";
import { toast } from 'react-toastify';
import Select from 'react-select';
import axios from 'axios';
import { Checkbox, Table, Divider, Button } from 'antd';
import BioDevice from './../aepsInstant/bioDevice';
import xmlJs from 'xml-js';
// import moment from 'moment';
// const user = getUser();
function MiniStatement(props) {
    // console.log("user------", user.data)
    const history = useHistory();
    const forceUpdate = useForceUpdate();
    const [isLoading, setLoading] = useState(false);
    const [getDataField, setDataField] = useState(props.existData ? props.existData : []);
    const [getDataError, setDataError] = useState({});
    const [latitude, setLatitude] = useState("");
    const [longitude, setLongitude] = useState("");
    const [isConsent, setConsent] = useState("N");
    const [biometricData, setBiometricData] = useState(null);
    const [getBankList, setBankList] = useState([]);
    const [isSubmit, setSubmit] = useState(false);
    const [dataRecord, setDataRecord] = useState([]);

    const [fingerprintXmlData, setFingerprintXmlData] = useState(null)

    const bankSelect = useRef("");
    const aadharInput = useRef(null);
    const mobileInput = useRef(null);

    const columns = [
        {
            title: 'Date',
            dataIndex: 'date',
        },
        {
            title: 'Type',
            dataIndex: 'txnType',
        },
        {
            title: 'Narration',
            dataIndex: 'narration',
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
        },
    ];

    // const getBankListData = async () => {
    //     await getNewRequest(GET_AEPS_BANK_LIST, history).then((response) => {
    //         if (response.success === true) {
    //             setBankList(response.data.map(item => {
    //                 return { "value": item.iin, "label": item.name };
    //             }));
    //         } else {
    //             toast.error(response.message);
    //         }
    //     }).catch(function (error) {
    //         console.log(error)
    //         // setLoading(false);
    //     })
    // }

    const onChangeValueSelect = (event) => {
        const { value } = event;
        props.existInputData(event, value);
        // console.log("value ----------",value)
        setDataField({ ...getDataField, "bankiin": value });
    }

    const clearFormField = () => {
        props.clearExistInputData();
        setConsent('N');
        // mobileInput.current.valueOf = null;
        // aadharInput.current.valueOf = null;
        // bankSelect.current.value = { value: "", label: "" };
        setDataError({});
        setDataField([])
    }

    const onChangeValue = (event) => {
        props.existInputData(event);
        const { name, value } = event.target;
        setDataField({ ...getDataField, [name]: value });
    }

    const FormValidate = () => {
        let formValid = true;
        let formObject = {};
        setDataError({})
        if (getDataField && getDataField.length === 0) {
            formValid = false;
        }
        let array = ["bankiin", "aadhaar", "mobile"];
        array.forEach(element => {
            if (getDataField[element] === "" || typeof getDataField[element] === "undefined") {
                formObject[element] = true;
                formValid = false;
            }
        });

        if (isConsent === 'N') {
            toast.error("Please aadhaar accept consent declaration");
            formValid = false;
        }

        setDataError(formObject);
        return formValid;
    }
    const scanFingerPrintData = async () => {
        await axios({
            method: 'CAPTURE',
            url: props.deviceConfig.captureUrl,
            headers: { "Content-Type": "text/xml; charset=UTF-8" },
            data: props.deviceConfig.xmlData
        }).then(response => {
            console.log("00----------------------", response.data)
            const jsonData = xmlJs.xml2json(response.data, { compact: true, spaces: 4 });
            let XMLjson = JSON.parse(jsonData);

            if (XMLjson && XMLjson.PidData.Resp._attributes.errCode !== "700") {
                setFingerprintXmlData(response.data)
            } else {
                setFingerprintXmlData(null)
            }
        }).catch(error => {
            console.log("error -----------", error)
        })
    }

    const scanFinger = () => {
        if (FormValidate()) {
            scanFingerPrintData()
        }
    }

    const handleSubmit = () => {
        if (FormValidate()) {
            setLoading(true)
            const payload = {
                bankiin: getDataField.bankiin,
                mobile: getDataField.mobile,
                biometricData: biometricData,
                aadhaar: getDataField.aadhaar,
                latitude: latitude,
                longitude: longitude,
                transactionType: "MS",
                isXml: true,
                consent: isConsent
            }
            // bankName bankAccountBalance
            postRequest(FING_PAY_BALANCE_ENQUIRY_API, payload, history).then((response) => {
                if (response.success === true) {
                    setSubmit(true);
                    setDataRecord(response.data);
                } else {
                    toast.error(response.message);
                }
                setLoading(false)
                setSubmit(true)
            })
        }
    } // eslint-disable-line react-hooks/exhaustive-deps

    const onChangeChackBox = (e) => {
        if (e.target.checked === true) {
            setConsent('Y')
        } else {
            setConsent('N')
        }
    };

    const clearMiniStatement = () => {
        props.clearExistInputData();
        setSubmit(false);
        setDataError({});
        setDataField({});
        setDataRecord([]);
        clearFormField();
    }

    useEffect(() => {
        if (fingerprintXmlData) {
            // let data = biometricDataToJson(fingerprintXmlData)
            setBiometricData(fingerprintXmlData)
        }
    }, [fingerprintXmlData]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (biometricData) {
            // console.log("call cash withdrawal api ---")
            handleSubmit();
        }
        if (props.getBankList.length > 0) {
            setBankList(props.getBankList)
        }
    }, [biometricData, props.getBankList]) // eslint-disable-line react-hooks/exhaustive-deps

    const fetchLocation = async () => {
        let data = await getGeoLocation();
        setLatitude(data.latitude);
        setLongitude(data.longitude);
    }

    useEffect(() => {
        fetchLocation();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if(props.existData) {
            forceUpdate();
            setDataField(props.existData)
        }
    },[props.existData])  // eslint-disable-line react-hooks/exhaustive-deps
    // useEffect(() => {
    //     if(props.component === 3) {
    //         setSubmit(false)
    //         setDataError({})
    //         setDataField({})
    //         setBiometricData(null)
    //     }
    // },[props.component])  // eslint-disable-line react-hooks/exhaustive-deps

    if (isLoading) {
        return <div className="content text-center" style={{ margin: "250px 0" }}>
          <PropagateLoader color="#5851D2" loading={isLoading} size={25} />
        </div>
      }

    return (
        <div>
            <div className="row">
                <div className="col-md-12">
                    {!isSubmit ? <div className='card rounded-0 card-body'>
                        <div className='row'>
                            <div className='col-sm-6 col-md-4 col-lg-4'>
                                <div className="form-group">
                                    <div className="input-group">
                                        <label>Bank Name</label>
                                        <Select showSearch status={`${getDataError && getDataError.bank ? "error" : ""}`} options={getBankList} className={`normal-light w-100`} classNamePrefix="select" ref={bankSelect} name='bankiin' onChange={(e) => onChangeValueSelect(e)} placeholder="-Select Bank-" />
                                        {getDataError && getDataError.bankiin && <span className="error text-danger">The field is required</span>}
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-6 col-md-4 col-lg-4'>
                                <div className="form-group">
                                    <label>Aadhaar Number</label>
                                    <input type="text" className={`form-control normal-light ${getDataError && getDataError.aadhaar ? "is-invalid" : ""}`} name="aadhaar" maxLength={12} value={getDataField && getDataField.aadhaar} onKeyDown={isNumber} onChange={(e) => onChangeValue(e)} ref={aadharInput} />
                                    {getDataError && getDataError.aadhaar && <span className="error text-danger">The field is required</span>}
                                </div>
                            </div>
                            <div className='col-sm-6 col-md-4 col-lg-4'>
                                <div className="form-group">
                                    <label>Mobile Number</label>
                                    <input type="text" className={`form-control normal-light ${getDataError && getDataError.mobile ? "is-invalid" : ""}`} name="mobile" value={getDataField && getDataField.mobile} onKeyDown={isNumber} onChange={(e) => onChangeValue(e)} maxLength={10} ref={mobileInput} />{getDataError && getDataError.mobile && <span className="error text-danger">The field is required</span>}
                                </div>
                            </div>
                            <div className='col-sm-12 col-md-12 col-lg-12'>
                                <div className='form-group'>
                                    <Checkbox className='d-flex' checked={isConsent === "Y" ? true : false} onChange={onChangeChackBox}>I hereby give my voluntary consent to SR PAY Private Limited, use my Aadhaar/ VID number & biometric to authenticate from UIDAI for the purpose of AEPS activation.</Checkbox>
                                </div>
                            </div>
                            <div className='col-sm-12 col-md-12 col-lg-12'>
                                <div className='col-sm-4 col-md-4 col-lg-4'>
                                    <BioDevice />
                                </div>
                            </div>
                            <div className='col-sm-6 col-md-4 col-lg-4'>
                                <label></label>
                                <div className='col-md-12 d-flex'>
                                    <div className="form-group pull-right text-lg-right mr-4">
                                        <button type="submit" className="btn btn-default rounded-pill pl-4 pr-4" onClick={() => clearFormField()} >Cancel</button>
                                    </div>
                                    <div className="form-group text-lg-right">
                                        <button type="submit" className="btn btn-primary rounded-pill pl-4 pr-4" onClick={() => scanFinger()} disabled={isConsent === 'N' ? true : false} ><i class="fas fa-fingerprint"></i> Scan Finger</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> : <>
                        <div>
                            <div>Bank Name: <span className='text-primary fw-bold'>{dataRecord.bankName}</span></div>
                            <div className='text-right'>Bank Balance: <span className='text-primary fw-bold'>{dataRecord.bankAccountBalance} INR</span></div>
                        </div>
                        <Divider><span className='text-danger'>Mini Statement</span></Divider>
                        <Table columns={columns} dataSource={dataRecord.miniStatement} size="small" />
                        <div className='text-right'>
                            <Button className='bg-primary mr-3' onClick={() => props.subChangeTab('1')}>Cash Withdrawal</Button>
                            <Button className='bg-primary mr-3' onClick={() => clearMiniStatement()}>New Transaction</Button>
                            <Button className='bg-info' onClick={() => scanFinger()}>Retry</Button>
                        </div>
                    </>}
                </div>
            </div>
        </div>
    )
}

export default MiniStatement