import React, { useEffect, useState } from "react";
import './../webcss/invoice.css';
import { invoiceDate } from './../Utils/function';
import { UitilityOfflineStatus } from "../Utils/Common";

function Invoice(props) {

    const [idata, setData] = useState(props.data)

    useEffect(() => {
        // console.log("props.data ----------", props.data)
        setData(props.data)
    },[idata]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
            <div className="container px-0">
                <div className="row">
                    <div className="col-12 col-lg-12">
                        {/* <div className="row">
                            <div className="col-12">
                                <div className="text-center text-150">
                                    <i className="fa fa-book fa-2x text-success-m2 mr-1" />
                                    <span className="text-default-d3">Bootdey.com</span>
                                </div>
                            </div>
                        </div> */}
                        {/* .row */}
                        {/* <hr className="row brc-default-l1 mx-n1 mb-4" /> */}
                        <div className="row">
                            <div className={idata.status === 3 ? "col-sm-4" : "col-sm-6"}>
                                <div>
                                    <span className="text-sm text-grey-m2 align-middle">Name :&nbsp;</span>
                                    <span className="text-600 text-110 text-blue align-middle"> {idata && idata.name}</span>
                                </div>
                                <div className="text-grey-m2">
                                    <div className="my-1">
                                        <span className="text-600 text-90">Mobile No:</span> {idata && idata.mobile}
                                    </div>
                                    <div className="my-1">
                                        <span className="text-600 text-90">Category:</span> {props && props.category}
                                    </div>
                                    <div className="my-1">
                                        <span className="text-600 text-90">Status:</span> {UitilityOfflineStatus(idata && idata.status)}
                                    </div>
                                    <div className="my-1">
                                        <i className="fa fa-whatsapp fa-flip-horizontal text-secondary" /> <b className="text-600">8053757670</b>
                                    </div>
                                </div>
                            </div>
                            {/* {idata.status === 3 &&
                            <div className="col-sm-2">
                            <img className='text-right' src={'../bbps/binvoice.jpg'} alt='photos' width={"100%"} />
                            </div>} */}
                            
                            {/* /.col */}
                            <div className="text-95 col-sm-6 align-self-start d-sm-flex justify-content-end">
                                <hr className="d-sm-none" />
                                <div className="text-grey-m2">
                                    {/* <div className="mt-1 mb-2 text-secondary-m1 text-600 text-125">
                                        Invoice
                                    </div> */}
                                    <div className="my-2"><i className="fa fa-circle text-blue-m2 text-xs mr-1" /> <span className="text-600 text-90">Order Number : </span> {idata && idata.transactionId}</div>
                                    <div className="my-2"><i className="fa fa-circle text-blue-m2 text-xs mr-1" /> <span className="text-600 text-90">Trans ID : </span> {idata && idata.operatorId}</div>
                                    <div className="my-2"><i className="fa fa-circle text-blue-m2 text-xs mr-1" /> <span className="text-600 text-90">Biller Ref : </span> {idata && idata.apiId}</div>
                                    <div className="my-2"><i className="fa fa-circle text-blue-m2 text-xs mr-1" /> <span className="text-600 text-90"></span> {idata && invoiceDate(idata.createdAt)}</div>
                                </div>
                            </div>
                            {/* /.col */}
                        </div>
                        <div className="mt-4">
                            <div className="row text-600 text-white bgc-default-tp1 py-25">
                                <div className="d-none d-sm-block col-1">#</div>
                                <div className="col-9 col-sm-5">Description</div>
                                <div className="d-none d-sm-block col-4 col-sm-4">CA Number</div>
                                {/* <div className="d-none d-sm-block col-sm-2">Unit Price</div> */}
                                <div className="col-2">Amount</div>
                            </div>
                            <div className="text-95 text-grey-m2">
                                <div className="row mb-2 mb-sm-0 py-25">
                                    <div className="d-none d-sm-block col-1">1</div>
                                    <div className="col-9 col-sm-5">{idata && idata.electricityproviders && idata.electricityproviders.name}</div>
                                    <div className="d-none d-sm-block col-4">{idata && idata.accountNumber}</div>
                                    <div className="col-2 text-secondary-d2"><i class="fa fa-inr" aria-hidden="true"></i> {idata && idata.billAmount}</div>
                                </div>
                            </div>
                            <div className="row border-b-2 brc-default-l2" />
                            {/* or use a table instead */}
                            {/*
      <div class="table-responsive">
          <table class="table table-striped table-borderless border-0 border-b-2 brc-default-l1">
              <thead class="bg-none bgc-default-tp1">
                  <tr class="text-white">
                      <th class="opacity-2">#</th>
                      <th>Description</th>
                      <th>Qty</th>
                      <th>Unit Price</th>
                      <th width="140">Amount</th>
                  </tr>
              </thead>

              <tbody class="text-95 text-secondary-d3">
                  <tr></tr>
                  <tr>
                      <td>1</td>
                      <td>Domain registration</td>
                      <td>2</td>
                      <td class="text-95">$10</td>
                      <td class="text-secondary-d2">$20</td>
                  </tr> 
              </tbody>
          </table>
      </div>
      */}
                            <div className="row mt-3">
                                <div className="col-12 col-sm-7 text-grey-d2 text-95 mt-2 mt-lg-0">
                                    {/* Extra note such as company or payment information... */}
                                </div>
                                <div className="col-12 col-sm-5 text-grey-m2 text-90 order-first order-sm-last">
                                    <div className="row">
                                        <div className="col-7 text-right ">
                                            Amount Paid
                                        </div>
                                        <div className="col-5 text-right">
                                            <span className="text-success-d3 opacity-2">
                                            <i class="fa fa-inr" aria-hidden="true"></i>&nbsp; {idata && idata.billAmount}</span>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-7 text-right">
                                            Payment Mode
                                        </div>
                                        <div className="col-5 text-right">
                                            <span className="text-success-d3 opacity-2">Cash</span>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-7 text-right">
                                            CCF
                                        </div>
                                        <div className="col-5 text-right">
                                            <span className="text-success-d3 opacity-2">
                                            <i class="fa fa-inr" aria-hidden="true"></i>&nbsp; 0</span>
                                        </div>
                                    </div>
                                    {/* <div className="row align-items-center bgc-primary-l3 p-2">
                                        <div className="col-7 text-right">
                                            Convenience Fee
                                        </div>
                                        <div className="col-5 text-right">
                                            <span className="text-success-d3 opacity-2"><i class="fa fa-inr" aria-hidden="true"></i>&nbsp; 10</span>
                                        </div>
                                    </div> */}
                                    <div className="row align-items-center bgc-primary-l3 p-2">
                                        <div className="col-7 text-right">
                                            Total Amount
                                        </div>
                                        <div className="col-5 text-right">
                                            <span className="text-success-d3 opacity-2">
                                            <i class="fa fa-inr" aria-hidden="true"></i> &nbsp;{idata && idata.billAmount}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div>
                                <span className="text-secondary-d1 text-105">This is a computer generated receipt & does't require signature</span>
                                <a href="#/" onClick={() => window.print()} className="btn bg-white btn-light mx-1px text-95 float-right mt-3 mt-lg-0">
                                    <i className="mr-1 fa fa-print text-primary-m1 text-120 w-2" /> Print
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

    )
}

export default Invoice;