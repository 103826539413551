import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { POST_DMT_INSTANT_PAY_MERCHANT_LIST, POST_DMT_INSTANT_PAY_MERCHANT_UPDATE, POST_DMT_INSTANT_PAY_MERCHANT_VERIFY } from '../../../Utils/appConstants';
// import { dmtStatus, dmtDetailStatus, channelStatus } from '../../../Utils/Common';
import { getRequest, postRequest } from '../../../Utils/AppApi';
import { useHistory } from "react-router-dom";
import { toast } from 'react-toastify';
import PropagateLoader from "react-spinners/PropagateLoader";
import { Tooltip, Modal } from 'antd';
import { formatDate } from '../../../Utils/function';
import { isNumber } from '../../../Utils/Common';

function Index(props) {
    const history = useHistory();
    // const csvLink = useRef()
    const limit = 10;
    // const dateFormat = 'DD-MM-YYYY';

    const [searchMobile, setSearchMobile] = useState(null);
    const [searchPan, setSearchPan] = useState(null);
    const [searchOutlatId, setSearchOutlatId] = useState(null);

    const [isLoading, setLoading] = useState(false);
    const [getData, setData] = useState([]);
    const [isExistMobileNumber, setExistMobileNumber] = useState(null);
    const [isExistMobileNumberOtp, setExistMobileNumberOtp] = useState(null);
    const [isNewMobileNumber, setNewMobileNumber] = useState(null);
    const [isNewMobileNumberOtp, setNewMobileNumberOtp] = useState(null);
    const [getTotalRecord, setTotalRecord] = useState(0);
    const [page, setPage] = useState(1);
    const [isLimit, setLimit] = useState(limit);

    const [isUpdateModal, setUpdateModal] = useState(false);
    const [isOtpFlag, setOtpFlag] = useState(false);

    const columns = [{
        name: 'Outlet Id',
        cell: row => row.outletId,
        width: "100px",
    }, {
        name: 'Name',
        selector: row => <Tooltip placement="bottom" title={row.name}>{row.name}</Tooltip>,
        width: "140px",
    }, {
        name: 'Mobile',
        selector: row => <Tooltip placement="bottom" title={row.mobile}>{row.mobile}</Tooltip>,
        width: "130px",
    }, {
        name: 'Email',
        selector: row => <Tooltip placement="bottom" title={row.email}>{row.email}</Tooltip>,
        width: "220px",
    }, {
        name: 'Pan',
        selector: row => row.pan,
        width: "120px",
    }, {
        name: 'Account No.',
        selector: row => row.bankAccounts && row.bankAccounts.length > 0 ? row.bankAccounts[0].accountNumber : "",
        width: "170px",
    }, {
        name: 'IFSC',
        selector: row => row.bankAccounts && row.bankAccounts.length > 0 ? row.bankAccounts[0].ifsc : "",
        width: "150px",
    }, {
        name: 'addedAt',
        selector: row => formatDate(row.addedAt),
        width: "140px",
    }, {
        name: 'isPrimary',
        selector: row => row.bankAccounts && row.bankAccounts.length > 0 ? row.bankAccounts[0].isPrimary : "",
        width: "100px",
    }, {
        name: 'KYCStatus',
        selector: row => (row.KYCStatus) ? "YES" : "NO",
    }, {
        name: 'isActive',
        selector: row => (row.isActive) ? "YES" : "NO",
    }, {
        name: 'Action',
        selector: row => <><button type="button" className='btn btn-sm border border-success text-success' onClick={() => handleUpdate(row)} title='Update Number'>Update</button></>,
        width: "150px",
    }]

    const handleAllUpdate = () => {
        setUpdateModal(true);
    }
    
    const handleUpdate = (data) => {
        setUpdateModal(true);
        setExistMobileNumber(data.mobile)
    }

    const objectToQueryString = (obj) => {
        var str = [];
        for (var p in obj)
            if (obj.hasOwnProperty(p)) {
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
        return str.join("&");
    }

    const params = { page: page, limit: isLimit };

    const getDataReports = (data) => {
        setLoading(true);
        if (data) {
            setLimit(data)
            params.limit = data;
        }

        params.pan = searchPan ? searchPan : "";
        params.mobile = searchMobile ? searchMobile : "";
        params.outletId = searchOutlatId ? searchOutlatId : "";

        const queryString = objectToQueryString(params);
        getRequest(`${POST_DMT_INSTANT_PAY_MERCHANT_LIST}?${queryString}`, history).then((response) => {
            if (response.success === true) {
                setData(response.data.records);
                setTotalRecord(response.data.meta.totalRecords);
                setPage(response.data.meta.currentPage);
            } else {
                setData([]);
                setTotalRecord(0);
                setPage(1);
            }
            setLoading(false);
        }).catch(function (error) {
            console.log(error)
            setLoading(false);
        })
    } // eslint-disable-line react-hooks/exhaustive-deps

    const handleSubmit = () => {
        if (isOtpFlag) {
            setLoading(true)
            const payload = {
                existingMobileNumber: isExistMobileNumber,
                existingMobileNumberOtp: isExistMobileNumberOtp,
                newMobileNumber: isNewMobileNumber,
                newMobileNumberOtp: isNewMobileNumberOtp,
            }
            postRequest(`${POST_DMT_INSTANT_PAY_MERCHANT_VERIFY}`, payload, history).then((response) => {
                if (response.success === true) {
                    toast.success(response.message);
                    handleHideModal()
                } else {
                    toast.error(response.message);
                }
                setLoading(false);
            }).catch(function (error) {
                // console.log(error)
                setLoading(false);
            })
        } else {
            setLoading(true)
            const payload = {
                existingMobileNumber: isExistMobileNumber,
                newMobileNumber: isNewMobileNumber,
            }
            postRequest(`${POST_DMT_INSTANT_PAY_MERCHANT_UPDATE}`, payload, history).then((response) => {
                if (response.success === true) {
                    toast.success(response.message);
                    setOtpFlag(true)
                } else {
                    toast.error(response.message);
                }
                setLoading(false);
            }).catch(function (error) {
                // console.log(error)
                setLoading(false);
            })
        }
    }

    const handlePageChange = (page, size) => {
        // console.log("-----", size)
        setPage(page);
    };

    const handleSearch = () => {
        getDataReports();
    }

    const handleClear = () => {
        setSearchMobile('');
        setSearchPan('');
        setSearchOutlatId('');
    }

    const handleHideModal = () => {
        setUpdateModal(false);
        setExistMobileNumber(null);
    }

    useEffect(() => {
        getDataReports();
    }, [page]) // eslint-disable-line react-hooks/exhaustive-deps

    if (isLoading) {
        return <div className="content text-center" style={{ margin: "250px 0" }}>
            <PropagateLoader color="#5851D2" loading={isLoading} size={25} />
        </div>
    }

    
    // const BootyCheckbox = React.forwardRef((event, data, row) => (
    //     <div className="form-check">
    //         {console.warn("row ---------", row)}
    //       <input
    //         htmlFor="booty-check"
    //         type="checkbox"
    //         className="form-check-input pointer"
    //         onClick={(e) => onClick(e.target.value)}
    //       />
    //       <label className="form-check-label" id="booty-check" />
    //     </div>
    //   ));

    // const selectProps = (event) => { console.log("isIndeterminate--1--------------", event) };
    // // const selectProps = (data) => { console.log("data--1--------------", data) };

    // const rowDisabledCriteria = row => row.txtStatus;

    return (
        <div className="">
            <div className="content p-0">
                <div className="container-fluid">
                    <div className='card'>
                        <div className='card-body'>
                            <div className="row">
                                <div className='col-md-12 date-search mb-0'>
                                    <div className='row'>
                                        <div className='col-md-2 col-lg-2'>
                                            <div className="form-group">
                                                <label className='date-search-label mb-0 text-red'></label>
                                                <input type="text" className="form-control form-control-border" placeholder="PanNumber" onChange={(e) => setSearchPan(e.target.value)} />
                                            </div>
                                        </div>
                                        <div className='col-md-2 col-lg-2'>
                                            <div className="form-group">
                                                <label className='date-search-label mb-0 text-red'></label>
                                                <input type="text" className="form-control form-control-border" placeholder="Mobile" onChange={(e) => setSearchMobile(e.target.value)} />
                                            </div>
                                        </div>
                                        <div className='col-md-2 col-lg-2'>
                                            <div className="form-group">
                                                <label className='date-search-label mb-0 text-red'></label>
                                                <input type="text" className="form-control form-control-border" placeholder="Outlet ID" onChange={(e) => setSearchOutlatId(e.target.value)} />
                                            </div>
                                        </div>
                                        <div className='col-md-1 col-lg-1'>
                                            <div><label className='date-search-label mb-0 text-red'>&nbsp;</label></div>
                                            <button type="submit" className="btn btn-primary rounded-pill px-3" onClick={() => handleSearch()}>Search</button>
                                        </div>
                                        <div className='col-md-1 col-lg-1'>
                                            <div><label className='date-search-label mb-0 text-red'>&nbsp;</label></div>
                                            <button type="submit" className="btn btn-default rounded-pill px-4" onClick={() => handleClear()}>Clear</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="container-fluid pt-2 pb-5">
                                <DataTable
                                    title={""}
                                    columns={columns}
                                    data={getData}
                                    defaultSortFieldID={1}
                                    highlightOnHover
                                    actions={<><button className="btn btn-sm bg-danger pointer" onClick={() => handleAllUpdate()}>Update</button></>}
                                    progressPending={isLoading}
                                    paginationTotalRows={getTotalRecord}
                                    onChangeRowsPerPage={getDataReports}
                                    onChangePage={handlePageChange}
                                />
                            </div>
                        </div>
                    </div>
                    {isUpdateModal && <Modal title={"Change Merchant Number"} width={600} open={isUpdateModal} onOk={""} onCancel={handleHideModal} footer={null}>
                        <><div className='row'>
                            <div className='col-md-12 form-group'>
                                <label>Existing Mobile Number: </label>
                                <input type={'text'} className="form-group form-control" onChange={(e) => setExistMobileNumber(e.target.value)} maxLength={10} value={isExistMobileNumber} onKeyDown={isNumber} readOnly={isExistMobileNumber}/>
                            </div>
                            { isOtpFlag && <div className='col-md-12 form-group'>
                                <label>OTP Existing Mobile Number: </label>
                                <input type={'text'} className="form-group form-control" onChange={(e) => setExistMobileNumberOtp(e.target.value)} maxLength={8} value={isExistMobileNumberOtp} onKeyDown={isNumber} />
                            </div> }
                            <div className='col-md-12 form-group'>
                                <label>New Mobile Number: </label>
                                <input type={'text'} className="form-group form-control" onChange={(e) => setNewMobileNumber(e.target.value)} maxLength={10} value={isNewMobileNumber} onKeyDown={isNumber} />
                            </div>
                            { isOtpFlag && <div className='col-md-12 form-group'>
                                <label>OTP New Mobile Number: </label>
                                <input type={'text'} className="form-group form-control" onChange={(e) => setNewMobileNumberOtp(e.target.value)} maxLength={8} value={isNewMobileNumberOtp} onKeyDown={isNumber} />
                            </div> }
                            <div className='col-md-12 form-group text-right'>
                                <button type='button' onClick={() => handleHideModal()} className='btn btn-sm btn-default mr-2'>Cancel</button>
                                <button type='button' onClick={() => handleSubmit()} className='btn btn-sm btn-primary'>Send</button>
                            </div>
                        </div></>
                    </Modal>}
                </div>
            </div>
        </div>
    )
}

export default Index