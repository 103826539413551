import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'antd';
import PropagateLoader from "react-spinners/PropagateLoader";
import DataTable from 'react-data-table-component';
import 'antd/dist/antd.css';
import { useHistory } from "react-router-dom";
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import {
    GET_UPI_TRANSFER_BENEFICIARY_LIST,
    POST_UPI_TRANSFER_SYNC_BENEFICIARY_LIST,
    POST_UPI_TRANSFER_CHANGE_BENEFICIARY_STATUS,
} from '../../Utils/appConstants';
import { getRequest, postRequest } from '../../Utils/AppApi';
import { ToastContainer, toast } from 'react-toastify';
import { isNumber } from '../../Utils/Common';
import { getUTCToLocalDateFormat } from '../../Utils/function';

function App(props) {
    // console.log("props -------",props.search)
    // const user = getUser();
    const history = useHistory();
    // const csvLink = useRef()
    // const [getNameButtonDisable, setGetNameButtonDisable] = useState(false);
    const limit = 10;
    const [getData, setData] = useState([]);
    const [isLimit, setLimit] = useState(limit)
    const [page, setPage] = useState(1);
    // const [saveCustomerMobile, setCustomerMobile] = useState(props.customerData && props.customerData.mobile);
    const [isLoading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState(null);
    // const [getDataField, setDataField] = useState({});
    const [timer, setTimer] = useState(null);
    // const [latitude, setLatitude] = useState("");
    // const [longitude, setLongitude] = useState("");
    // const [bankId, setBankId] = useState(null);
    // const [isAddBaneModal, setAddBaneModal] = useState(false);
    // const [isSelectedUser, setIsSelectedUser] = useState({});
    // const [isSendMoneyModal, setSendMoneyModal] = useState(false);
    const [isSendButtonDisable, setSendButtonDisable] = useState(true);

    const [isSyncModal, setSyncModal] = useState(false);
    // const [invoiceDataModal, setInvoiceDataModal] = useState(false);
    // const [invoiceData, setInvoiceData] = useState(null);

    // const [sendMoneyAmount, setSendMoneyAmount] = useState([]);

    const columns = [{
        name: 'Name',
        selector: row => <><div>{row.name}</div>{row.mobile && <div><strong>Mobile</strong>: {row.mobile}</div>}</>,
        // width: "200px",
    }, {
        name: 'VPA',
        selector: row => row.upi,
        // width: "250px",
    }, {
        name: 'Primary',
        selector: row => <><div><strong>{row.isPrimary ? <CheckOutlined className='text-120 text-success border p-1' /> : <CloseOutlined className='text-120 text-danger border p-1'/> }</strong></div></>,
        // width: "200px",
    }, {
        name: 'Status',
        selector: row => (row.isActive) ? <button onClick={() => beneStatus(row, false)} className={`pointer text-success`} >Active</button> : <button onClick={() => beneStatus(row, true)} className={`pointer text-danger`} >Deactive</button>,
        // width: "100px",
    }, {
        name: 'Amount',
        selector: (row, index) => <><input type='text' name="amount" className="form-control" onChange={(e) => handleEditRowAmount(e, index)} placeholder='Amount' onKeyDown={isNumber} maxLength={5} disabled={row.isActive ? false : true} readOnly /></>,
        // width: "150px",
    }, {
        name: 'Action',
        selector: (row) => <><Button className={"bg-primary"} onClick={() => sendMoney(row)} disabled={row.isActive ? isSendButtonDisable : true} small >Send</Button></>,
        // width: "150px",
        fixed: "right",
    },{
        name: 'Updated On',
        selector: (row, index) => getUTCToLocalDateFormat(row.updatedAt),
        // width: "100px",
    },];

    // useEffect(() => {
    //     // console.log("props.customerData.mobile ---" ,props.customerData)
    //     setCustomerMobile(props.customerData && props.customerData.mobile);
    // }, [props.customerData]) // eslint-disable-line react-hooks/exhaustive-deps
    
    const beneStatus = (data, status) => {
        let params = {}
        params.id = data._id;
        params.status = status;
        postRequest(POST_UPI_TRANSFER_CHANGE_BENEFICIARY_STATUS, params, history).then((response) => {
            if (response.success === true) {
                getDataReports();
            }
            toast.success(response.message, {
                toastId: 'success1',
            });
        }).catch(function (error) {
            // console.log(error);
        })
    }

    // const updateBeneData = (data) => {
    //     let params = {}
    //     params.id = data._id;
    //     params.beneBankName = data.beneBankName;
    //     postRequest(UPDATE_DMT_PAY_SPRINT_BENEFICIARY_DETAIL, params, history).then((response) => {
    //         if (response.success === true) {
    //             getDataReports();
    //         }
    //     }).catch(function (error) {
    //         // console.log(error);
    //     })
    // }

    const handleEditRowAmount = (event, index) => {
        // console.log("event ---------------", event)
        // console.log("index ---------------", index)
        // console.log("getData ---------------", getData)
        let array = getData;
        array.docs[index].amount = event.target.value;
        if (event.target.value > 25000) {
            toast.error('Maximum amount is: 25000', {
                toastId: 'error1',
            });
            setSendButtonDisable(true)
            event.preventDefault();
            return false;
        } else {
            if (event.target.value >= 100) {
                setSendButtonDisable(false)
            } else {
                setSendButtonDisable(true)
            }
            setData(array);
        }
    }

    // const handleAmount = (event) => {
    //     setSendMoneyAmount(event.target.value)
    //     // console.log("object ---------------", object)
    // }

    // const onChangeAddBeneName = async (event) => {
    //     let { name, value } = event.target;
    //     // console.log("isAlphabetOnly 2 name------", name)
    //     // console.log("isAlphabetOnly value ------", value)
    //     // console.log("isAlphabetOnly ------", typeof isAlphabetOnly(value))
    //     if(isAlphabetOnly(value)) {
    //         // console.log("isAlphabetOnly IF------")
    //         setBeneData({ ...getBeneData, [name]: value });
    //     } else {
    //         event.preventDefault();
    //     }
    // }

    const sendMoney = (row) => {
        if (!row.amount) {
            toast.error("Amount is empty");
            return false;
        }
        // setSendMoneyModal(true)
        // row.isChannel = 2;
        // setIsSelectedUser(row)
    }

    const modalSyncBeneficiary = () => {
        setSyncModal(true)
    }

    const handleSyncBeneficiary = () => {
        setLoading(true)
        let payload = { mobile: searchText }
        postRequest(`${POST_UPI_TRANSFER_SYNC_BENEFICIARY_LIST}`, payload, history).then((response) => {
            if (response.success === true) {
                getDataReports();
            } else {
                toast.error(response.message)
            }
            setLoading(false)
        }).catch(function (error) {
            // console.log(error)
            setLoading(false)
        })
    }

    // const handleSave = (data) => {
    //     if (sendMoneyAmount !== data.amount) {
    //         toast.error("Amount did not matched.")
    //     } else {
    //         const payload = {
    //             customer_id: saveCustomerMobile,
    //             // recipient_id: data.beneId,
    //             accountNumber: data.accountNumber,
    //             ifsccode: data.ifscCode,
    //             beneAutoId: data._id,
    //             amount: sendMoneyAmount,
    //             latitude: latitude,
    //             channel: data.isChannel,
    //             longitude: longitude
    //         }
    //         handleSubmit(payload);
    //     }
    // }

    // const handleSubmit = (data) => {
    //     setLoading(true)
    //     postRequest(`${POST_DMT_PAY_SPRINT_INITIATE_TRANSACTION}`, data, history).then((response) => {
    //         if (response.success === true) {
    //             toast.success(response.message);
    //             handleInvoice(response.data);
    //             getDataReports();
    //             handleHideModal();
    //         } else {
    //             toast.error(response.message);
    //         }
    //         setLoading(false);
    //     }).catch(function (error) {
    //         // console.log(error)
    //         setLoading(false);
    //         handleHideModal();
    //     });
    // }

    // const handleInvoice = (data) => {
    //     setInvoiceDataModal(true);
    //     setInvoiceData(data);
    // }

    // const handleInvoiceModal = () => {
    //     setInvoiceDataModal(false);
    // }

    const handleHideModal = () => {
        setSyncModal(false)
        // setAddBaneModal(false)
    };

    const objectToQueryString = (obj) => {
        var str = [];
        for (var p in obj)
            if (obj.hasOwnProperty(p)) {
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
        return str.join("&");
    }

    const params = { page: page, limit: isLimit };

    const getDataReports = (data) => {
        if (data) {
            setLimit(data)
            params.limit = data
        }
        if (searchText) {
            params.mobile = searchText;
        }
        setLoading(true);
        const queryString = objectToQueryString(params);
        getRequest(`${GET_UPI_TRANSFER_BENEFICIARY_LIST}?${queryString}`, history).then((response) => {
            if (response.success === true) {
                if(response.data.length === 0) {
                    // props.findCustomerInfo()
                } else {
                    setData(response.data);
                }
                // setTotalRecord(response.data.totalDocs);
                // setPage(response.data.page);
            } else {
                // setData([]);
                // setTotalRecord(0);
                setPage(1);
            }
            setLoading(false);
        }).catch(function (error) {
            // console.log(error)
            setLoading(false);
        })
    }

    const handlePageChange = page => {
        setPage(page);
    };

    const handleSearch = (e) => {
        // console.log("searchText----",e.target.value)
        setSearchText(e.target.value);
    }

    // const onChangeChannel = ({ target: { value } }) => {
    //     // console.log("value-------------- value", value)
    //     let isSelectedUserValue = { ...isSelectedUser };
    //     isSelectedUserValue.isChannel = value;
    //     setIsSelectedUser(isSelectedUserValue);
    // };

    useEffect(() => {
        if (timer) {
            clearTimeout(timer)
            setTimer(null)
        }
        if(searchText) {
            setTimer(
                setTimeout(() => {
                    getDataReports()
                }, 2000)
            )
        }
    }, [searchText]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (page > 1) {
            getDataReports();
        }
    }, [page]) // eslint-disable-line react-hooks/exhaustive-deps

    if (isLoading) {
        return <div className="content text-center" style={{ margin: "250px 0" }}>
            <PropagateLoader color="#5851D2" loading={isLoading} size={25} />
        </div>
    }

    return (
        <div>
            <div className=" pt-2 pb-5">
                <div className="col-md-12">
                    <div className="row pb-3">
                        <div className='col-md-6 date-search'>
                            <div className='row'>
                                <div className="input-group">
                                    <input className="form-control py-2 border" type="search" defaultValue={searchText} value={searchText} placeholder='Receiver Mobile Number / UPI ...' maxLength={50} onChange={(e) => handleSearch(e)} />
                                    <span className="input-group-append">
                                        <button className="btn btn-outline-secondary border-left-0 border" type="button"><i className="fa fa-search" /></button>
                                    </span>
                                    <button onClick={() => getDataReports()} className="btn btn-primary ml-3 border-left-0 border" type="button"> Find</button>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6 p-0'>
                            <div className='float-sm-right'>
                                <button type="button" className="btn btn-primary mr-2" onClick={() => modalSyncBeneficiary()}>
                                    <i className="fa fa-users"></i> Sync Beneficiary
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="box-body">
                        <DataTable
                            columns={columns}
                            data={getData}
                            // progressPending={getData.length > 0 ? false : true}
                            highlightOnHover
                            pagination
                            paginationServer
                            paginationTotalRows={getData.totalDocs}
                            onChangeRowsPerPage={getDataReports}
                            onChangePage={handlePageChange}
                        />
                    </div>
                </div>
            </div>
            <ToastContainer position="top-center" autoClose={5000} limit={2} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="colored" />

            {isSyncModal && <Modal title={"Sync Beneficiary"} width={600} open={isSyncModal} onOk={() => handleSyncBeneficiary()} onCancel={handleHideModal} okText={"Continue"} cancelText={"Cancel"}>
                <div className='row'>
                    <div className='col-md-12 form-group'>
                        <p className="text-danger">Please Note a Charge of Rupee 4 Levied on getting UPI ID Status sync !!</p>
                    </div>
                    <div className='col-md-12 form-group'>
                        <label>Receiver Mobile Number: </label>
                        <input type='text' className="form-group form-control" value={searchText} onChange={(e) => setSearchText(e.target.value)} onKeyDown={isNumber} maxLength={10} placeholder="Receiver Mobile Number" />
                    </div>
                </div>
            </Modal>}
            {/* {invoiceDataModal && <Modal open={invoiceDataModal} width={800} footer={false} onCancel={handleInvoiceModal} closable={false}>
                <DmtInvoice transactionId={invoiceData.client_ref_id} />
            </Modal>} */}
        </div>
    )
}

export default App