import React, { useEffect, useState, useRef } from 'react';
import { saveDateInDatabase } from './../../Utils/function';
// import { Tabs, Tab } from 'react-bootstrap-tabs';
import { AEPS_REGISTER_SAVE, UPLOAD_AEPS_IMAGE_FILE } from './../../Utils/appConstants';
import { postRequest } from './../../Utils/AppApi';
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
// import Moment from 'react-moment';
import moment from 'moment';
import { DatePicker } from 'antd';
// const user = getUser();
function AePSRegister(props) {
    const history = useHistory();
    const dateFormat = 'DD-MM-YYYY';
    const [getDataField, setDataField] = useState([]); // eslint-disable-line react-hooks/exhaustive-deps
    const [getDataError, setDataError] = useState({}); // eslint-disable-line react-hooks/exhaustive-deps
    const [getPhoto, setPhoto] = useState(null)
    const dateTimeInput = useRef(null);

    const FormValidate = () => {
        let formValid = true;
        let formObject = {};
        setDataError({})
        if (getDataField && getDataField.length === 0) {
            formValid = false;
        }

        let array = ["dateTime"];
        array.forEach(element => {
            if (getDataField[element] === "" || typeof getDataField[element] === "undefined") {
                formObject[element] = true;
                formValid = false;
            }
        });

        if(!getPhoto || getPhoto === "") {
            formObject.photo = true;
            formValid = false;
        }

        setDataError(formObject);
        return formValid;
    }

    const handleSubmit = () => {
        if (FormValidate()) {
            const payload = {
                date: getDataField.dateTime,
                image: getPhoto
            }
            postRequest(AEPS_REGISTER_SAVE, payload, history).then((response) => {
                if (response.success === true) {
                    setDataField([]);
                    setDataError({});
                    toast.success(response.message);
                    window.location.reload();
                } else {
                    toast.error(response.message);
                }
            }).catch(function (error) {
                // console.log(error)
                // setLoading(false);
            })
        }
    } // eslint-disable-line react-hooks/exhaustive-deps

    const onChangeDepositDate = (date, dateString) => {
        // console.log("saveDateInDatabase -----", saveDateInDatabase(dateString))
        setDataField({ ...getDataField, "dateTime": saveDateInDatabase(dateString) });
    };

    const handleFileChange = async (event) => {
        event.preventDefault();
        const data = new FormData();
        data.append('avatar', event.target.files[0], event.target.files[0].name);
        // data.append('name', name);
        postRequest(UPLOAD_AEPS_IMAGE_FILE, data, history).then(async (response) => {
            if (response.success === true) {
                // console.log("response.data.fullpath ------------", response.data.fullpath)
                setPhoto(response.data.fullpath)
                toast.success(response.message);
            } else {
                toast.error(response.message);
            }
        }).catch(function (error) {
            // console.log(error)
        })
    } // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setDataField({ ...getDataField, dateTime: saveDateInDatabase(moment().format(dateFormat)) });
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            <div className="">
                <div className="col-md-12">
                    <div className='card rounded-0'>
                        <div className='card-body'>
                            <div className='row'>
                                <div className='col-sm-6 col-md-4 col-lg-4'>
                                    <div className="form-group">
                                        <label>Date & Time</label>
                                        <DatePicker className={`form-control normal-light  ${getDataError && getDataError.dateTime ? "is-invalid" : ""}`} format={dateFormat} ref={dateTimeInput} onChange={onChangeDepositDate} defaultValue={moment()} />
                                        {getDataError && getDataError.dateTime && <span className="error text-danger">The field is required</span>}
                                    </div>
                                </div>
                                <div className='col-sm-6 col-md-4 col-lg-4'>
                                    <div className="form-group">
                                        <label >Photo</label>
                                        <input type="file" className={`form-control normal-light border-0`} onChange={(event) => handleFileChange(event)} accept="image/png, image/jpeg, image/jpg" />
                                        {getDataError && getDataError.photo && <span className="error text-danger">The field is required</span>}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className='col-md-12 d-flex'>
                                    <div className="form-group text-lg-right">
                                        <button type="submit" className="btn btn-primary rounded-pill pl-4 pr-4" onClick={() => handleSubmit()}>Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer position="top-center" autoClose={5000} limit={2} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="colored" />
        </div>
    )
}

export default AePSRegister