
import React, { useEffect, useState } from "react";
import { isNumber } from "../../Utils/Common";
import PropagateLoader from "react-spinners/PropagateLoader";
import { GET_DMT_EKO_BANL_LIST, GET_DMT_BANK_LIST, POST_DMT_CREATE_CUSTOMER, POST_DMT_VERIFY_CUSTOMER, POST_DMT_ADD_BENEFICIARY, GET_BANK_NAME_FROM_ACCOUNT_NUMBER, GET_NAME } from '../../Utils/appConstants';
import { useHistory } from "react-router-dom";
import { getRequest, postRequest } from '../../Utils/AppApi';
import { ToastContainer, toast } from 'react-toastify';
import { refId } from './../../Utils/function';
import moment from "moment";
import Select from 'react-select';
import { DatePicker, Modal } from "antd";
// import { event } from "jquery";

function AddCustomer(props) {
    const history = useHistory();
    const customerMobile = props && props.mobile;
    const [isLoading, setLoading] = useState(false);
    const [ifscCodeModal, setIfscCodeModal] = useState(false);
    const [isOtpModal, setOtpModal] = useState(false);
    const [otpCode, setOtpCode] = useState(null)
    // const [ifscCode, setIfscCode] = useState('');
    const [latitude, setLatitude] = useState("");
    const [longitude, setLongitude] = useState("");
    const [bankId, setBankId] = useState(null);
    const [getDataError, setDataError] = useState({});
    const [getDataField, setDataField] = useState({});
    const [getEKOBankListData, setEKOBankListData] = useState([]);
    const [getBankListData, setBankListData] = useState([{ "value": '', "label": '-- Select Bank --' }]);

    const getIFSCCode = () => {
        setIfscCodeModal(true);
        getBankList();
    }

    const getEkoBankList = () => {
        getRequest(GET_DMT_EKO_BANL_LIST, history).then((response) => {
            if (response.success === true) {
                setEKOBankListData(response.data);
            }
        }).catch(function (error) {
            console.log(error);
        })
    }

    const getBankList = () => {
        getRequest(GET_DMT_BANK_LIST, history).then((response) => {
            if (response.success === true) {
                setBankListData([...getBankListData, ...response.data.map((item) => {
                    return { "value": item.ifscGlobal, "label": item.name };
                })]);
            }
        }).catch(function (error) {
            console.log(error);
        })
    }
    const disabledDate = (d) => {
        return d.isAfter(moment().subtract(19, 'years'))
    };

    const onChangeBankIfscCode = (value) => {
        console.log('value -----', value)
        if(value) {
            let findBank = getEKOBankListData.filter(item => item.ShortCode === value.substring(0, 4));
            // console.log('findBank -----', findBank)
            // console.log('findBank[0].BankID -----', findBank[0].BankID)
            if (findBank.length > 0) {
                setBankId(findBank[0].BankID);
            } else {
                setBankId(null);
            }
            setDataField({ ...getDataField, ifscCode: value });
            setIfscCodeModal(false)
        }
    }

    const onChangeOtpValue = (value) => {
        setOtpCode(value);
    }

    const handleCloseModal = () => {
        setIfscCodeModal(false)
    }

    const onChangeDate = (date) => {
        console.log('From: ', date);
        let dateValue = moment(date).format('YYYY-MM-DD');
        setDataField({ ...getDataField, dateOfBirth: dateValue });
    }

    const FormValidate = () => {
        let formValid = true;
        let formObject = {};
        setDataError({})
        if (getDataField && getDataField.length === 0) {
            formValid = false;
        }
        let array = ["customerName", "customerMobile", "dateOfBirth", "pincode", "area", "accountNumber", "ifscCode", "beneName", "beneMobile"];

        array.forEach(element => {
            if (getDataField[element] === "" || typeof getDataField[element] === "undefined") {
                formObject[element] = true;
                formValid = false;
            }
        });

        if (getDataField && getDataField.area && getDataField.area.length <= 10) {
            formObject.area = 2;
            formValid = false;
        }

        setDataError(formObject);
        return formValid;
    }

    const onChangeValue = (event) => {
        const { name, value } = event.target;
        setDataField({ ...getDataField, [name]: value });
    }

    const handleSubmit = () => {
        // console.log("1========== submit")
        // console.log("1========== FormValidate()", FormValidate())
        // console.log("1========== FormValidate()", getDataField)
        if (FormValidate() === true) {
            setLoading(true)
            // console.log("========== submit")
            const payload = {
                customerMobile: customerMobile,
                customerName: getDataField.customerName,
                customerDob: getDataField.dateOfBirth,
                pincode: getDataField.pincode,
                area: getDataField.area,
            };

            postRequest(`${POST_DMT_CREATE_CUSTOMER}`, payload, history).then((response) => {
                if (response.success === true) {
                    setOtpModal(true)
                    // console.log("========== otp")
                    toast.success(response.message);
                } else {
                    toast.error(response.message);
                }
                setLoading(false)
            }).catch(function (error) {
                // console.log(error)
                setLoading(false)
            })
        }
    }

    const handleCustomerVerify = () => {
        console.log("========== verify")
        if (otpCode) {
            setLoading(true)
            console.log("========== otpCode", otpCode)
            const payload = {
                customerMobile: customerMobile,
                otp: otpCode
            };

            postRequest(`${POST_DMT_VERIFY_CUSTOMER}`, payload, history).then((response) => {
                if (response.success === true) {
                    handleAddBeneficiary()
                    toast.success(response.message);
                } else {
                    toast.error(response.message);
                }
                setLoading(false)
            }).catch(function (error) {
                // console.log(error)
                setLoading(false)
            })
        } else {
            toast.error("OTP code is required");
        }
    }

    const handleAddBeneficiary = () => {
        console.log("========== otpCode", otpCode)
        console.log("bene========== FormValidate", FormValidate)
        if (FormValidate() === true) {
            setLoading(true)
            const payload = {
                customerMobile: customerMobile,
                recipientMobile: getDataField.beneMobile,
                accountNumber: getDataField.accountNumber,
                ifscCode: getDataField.ifscCode,
                bankId: bankId,
                recipientName: getDataField.beneName,
                beneBankName: getDataField.beneBankName
            };

            postRequest(`${POST_DMT_ADD_BENEFICIARY}`, payload, history).then((response) => {
                if (response.success === true) {
                    setTimeout(() => {
                        props.findCustomerInfo(customerMobile);
                    }, 1500);
                    toast.success(response.message);
                } else {
                    toast.error(response.message);
                }
                setLoading(false)
            }).catch(function (error) {
                // console.log(error)
                setLoading(false)
            })
        } else {
            toast.error("OTP code is required");
        }
    }

    const getBankNameFromAccountNumber = () => {
        if (getDataField.accountNumber && getDataField.ifscCode) {
            // console.log("--- Valid", getDataField)
            setLoading(true);
            const payload = {
                "ifsccode": getDataField.ifscCode,
                "accountNumber": getDataField.accountNumber,
                "externalRef": refId(13),
                "consent": "Y",
                "pennyDrop": "AUTO",
                "latitude": latitude,
                "longitude": longitude,
                "serviceCode": "117",
            };
            postRequest(`${GET_BANK_NAME_FROM_ACCOUNT_NUMBER}`, payload, history).then((response) => {
                if (response.success === true) {
                    // console.log("response.data-----------", response.data.data)
                    let record = response.data.data;
                    let Object = { "beneBankName": record.nameAtBank }
                    setDataField({ ...getDataField, ...Object });
                } else {
                    // console.log("response.data-----------", response)
                    toast.error(response.message);
                }
                setLoading(false);
            }).catch(function (error) {
                setLoading(false);
                console.log(error)
            });
        } else {
            toast.error("Please enter valid account number")
            let Object = { "name": '' }
            setDataField({ ...getDataField, ...Object });
        }
    }

    useEffect(() => {
        // console.log("--------", moment().subtract(19, 'years').format('DD-MM-YYYY'))
        getEkoBankList();
        let lastDate = moment().subtract(19, 'years');
        let dateValue = moment(lastDate).format('YYYY-MM-DD');
        setDataField({ ...getDataField, customerMobile: customerMobile, dateOfBirth: dateValue });
        navigator.geolocation.getCurrentPosition((data) => {
            setLatitude(data.coords.latitude)
            setLongitude(data.coords.longitude)
        });
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    if (isLoading) {
        return <div className="content text-center" style={{ margin: "250px 0" }}>
          <PropagateLoader color="#5851D2" loading={isLoading} size={25} />
        </div>
      }
    return (
        <div className="row">
            <div className="col-xl-12">
                {/* Account details card*/}
                <div className="card m-0 ">
                    {/* <div className="card-header m-0"></div> */}
                    <div className="card-body m-0 py-0">
                        <div className="row gx-3 mb-3">
                            <div className="col-md-6">
                                <label className="small mb-1" >Name</label>
                                <input className="form-control" type="text" placeholder="Enter customer name" name="customerName" value={getDataField.customerName} onChange={(e) => onChangeValue(e)} minLength={4} maxLength={20} />
                                {getDataError && getDataError.customerName && <span className="error text-danger">The field is required</span>}

                            </div>
                            <div className="col-md-6">
                                <label className="small mb-1" >PinCode</label>
                                <input className="form-control" type="text" placeholder="Pin Code" name="pincode" onKeyDown={isNumber} onChange={(e) => onChangeValue(e)} value={getDataField.pincode} maxLength={6} />
                                {getDataError && getDataError.pincode && <span className="error text-danger">The field is required</span>}
                            </div>
                        </div>
                        <div className="row gx-3 mb-3">
                            <div className="col-md-6">
                                <label className="small mb-1" >Village/Town</label>
                                <input className="form-control" type="text" placeholder="Village/Town" name="area" minLength={10} onChange={(e) => onChangeValue(e)} value={getDataField.area} maxLength={20} />
                                {getDataError && getDataError.area && getDataError.area !== 2 && <span className="error text-danger">The field is required</span>}
                                {getDataError && getDataError.area === 2 && <span className="error text-danger">The minimum 10 character</span>}
                            </div>
                            <div className="col-md-6">
                                <label className="small mb-1" >Birthday</label>
                                <DatePicker
                                    placeholder="Enter your date of birth DD-MM-YYYY"
                                    className="form-control"
                                    format="DD-MM-YYYY"
                                    allowClear
                                    defaultValue={moment().subtract(19, 'years')}
                                    style={{ borderRadius: 5 }}
                                    onChange={onChangeDate}
                                    disabledDate={disabledDate}
                                />
                                {getDataError && getDataError.dateOfBirth && <span className="error text-danger">The field is required</span>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-xl-12">
                <div className="card">
                    <div className="card-header">Add Beneficiary</div>
                    <div className="card-body">
                        <div className="row gx-3 mb-3">
                            <div className="col-md-4">
                                <label className="small mb-1" >Mobile</label>
                                <input className="form-control" type="text" placeholder="Enter Mobile Number" name="beneMobile" onChange={(e) => onChangeValue(e)} value={getDataField.beneMobile} maxLength={10} />
                                {getDataError && getDataError.beneMobile && <span className="error text-danger">The field is required</span>}
                            </div>
                            <div className="col-md-4">
                                <label className="small mb-1">Name</label>
                                <input className="form-control" type="text" placeholder="Enter Beneficiary name" name="beneName" onChange={(e) => onChangeValue(e)} value={getDataField.beneName} minLength={4} maxLength={20} />
                                {getDataError && getDataError.beneName && <span className="error text-danger">The field is required</span>}
                            </div>
                            <div className="col-md-4">
                                <label className="small mb-1">IFSC Code</label>
                                <input className="form-control" type="text" placeholder="Enter IFSC Code" maxLength={11} name="ifscCode" onChange={(e) => onChangeBankIfscCode(e.target.value)} value={getDataField.ifscCode} />
                                {getDataError && getDataError.ifscCode && <span className="error text-danger">The field is required</span>}
                                <div className="inputgrouptext cursor-pointer text-success mt-30" onClick={getIFSCCode}>Get IFSC</div>

                            </div>
                        </div>
                        <div className="row gx-3">
                            <div className="col-md-4">
                                <label className="small mb-1" >Account Number</label>
                                <input className="form-control" type="text" name="accountNumber" placeholder="Enter Account Number" onChange={(e) => onChangeValue(e)} maxLength={20} value={getDataField.accountNumber} />
                                {getDataError && getDataError.accountNumber && <span className="error text-danger">The field is required</span>}
                                <div className="inputgrouptext cursor-pointer text-success mt-30" onClick={() => getBankNameFromAccountNumber()}>{GET_NAME}</div>
                            </div>
                        </div>
                        {getDataField.beneBankName && <div className="row gx-3">
                            <div className="col-md-4">
                                <label className="small mb-1" >Bank Name</label>
                                <input className="form-control" type="text" readOnly={true} value={getDataField.beneBankName} />
                            </div>
                        </div>}
                        <button className="btn btn-primary float-right" onClick={() => handleSubmit()} type="button">Add Customer</button>
                    </div>
                </div>
                <ToastContainer position="top-center" autoClose={5000} limit={2} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="colored" />
            </div>
            {isOtpModal && <Modal title={"Customer Verification OTP"} width={600} open={isOtpModal} onOk={() => handleCustomerVerify()} onCancel={() => setOtpModal(false)} okText={"Submit"} >
                <div className="form-group">
                    <label>OTP Code</label>
                    <div className="input-group">
                        <div className="custom-file">
                            <input type="text" className="form-control" name="otpCode" placeholder="OTP Code" onChange={(e) => onChangeOtpValue(e.target.value)} autoComplete="off" value={otpCode} maxLength={6} onKeyDown={isNumber} />
                        </div>
                    </div>
                </div>
                {/* <Button className='btn btn-primary btn-lg btn-block' onClick={() => scanFingerPrintData()}>Scan</Button> */}
            </Modal>}
            {ifscCodeModal && <Modal title={"Verification OTP"} width={600} open={ifscCodeModal} onCancel={() => handleCloseModal()} footer={false} >
                <div>
                    <Select options={getBankListData} className={`normal-light w-100`} classNamePrefix="select" placeholder="Select Bank" name='mode' onChange={(e) => onChangeBankIfscCode(e.value)} />
                </div>
                {/* <Button className='btn btn-primary btn-lg btn-block' onClick={() => scanFingerPrintData()}>Scan</Button> */}
            </Modal>}
        </div>
    )
}

export default AddCustomer;
