import React from 'react';
import PropTypes from 'prop-types';
//import { Test } from './Footer.styles';

const Footer = () => (
  <div className="FooterWrapper">
    <footer className="main-footer">
      <div className='row'>
        <div className='col-md-4 text-center text-lg-left'><strong>Copyright © 2022 SR Pay.</strong> All rights reserved.</div>
        <div className='col-md-4 text-center'>
          <ul className="footer-social-media"> 
            <li className="nav-item">
            <a href={"/#"}><i className="fab fa-facebook-f"></i></a>
            </li>
            <li className="nav-item">
            <a href={"/#"}><i className="fab fa-youtube"></i></a>
            </li> 
          </ul>
        </div>
        <div className='col-md-4 text-center text-lg-right'><a rel="noreferrer" target="_blank" href='http://srpay1.com/'>About SR Pay</a></div>
      </div>
      {/* <div className="text-center d-none d-sm-inline">
        <span className='social-media-circle'><a href={"/#"}><i className="fab fa-facebook-f"></i></a></span>
        <span className='social-media-circle'><a href={"/#"}><i className="fab fa-youtube"></i></a></span>
      </div> */}
    </footer>
  </div>
);

Footer.propTypes = {
  bla: PropTypes.string,
};

Footer.defaultProps = {
  bla: 'test',
};

export default Footer;
