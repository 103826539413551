import React, { useEffect } from 'react';
import './../../../webcss/default.css';
import './../../../webcss/styles.css';
import Header from './../header';
import Footer from './../footer';

function TravelServices() {
    useEffect(() => {
        // setReportMode(TravelServicess);
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            <Header />
            <div>
                <div className="notice-section notice-section-sm border-top red">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-10 col-sm-10 col-12">
                                <div className="mt-5">
                                    <h6 className="fontwhite">Become a SR PAY Private Limited Franchise &amp; Grow your income 30% to 50% per month</h6>
                                </div>
                            </div>
                            <div className="col-md-2 col-sm-2 col-12">
                                <div className="right-holder-md">
                                    <a href="/joinus" className="primary-button button-lg mt-15-xs bluebtn">Join Now</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-title-section">
                    <div className="container">
                        <h1><span className="white">Travel </span> Services</h1>
                    </div>
                </div>
                <div className="section-block">
                    <div className="container">
                        <div className="blog-list-simple">
                            <div className="row">
                                <div className="col-md-5 col-sm-5 col-12">
                                    <div className="blog-list-simple-img">
                                        <img src="img/projects/flight.webp" alt="img" />
                                        <small className="comming-soon">Coming Soon</small>
                                    </div>
                                </div>
                                <div className="col-md-7 col-sm-7 col-12">
                                    <div className="blog-list-simple-text">
                                        {/* <span>Services</span> */}
                                        <h4 className="bluefont">Flight <span className="white">Booking</span></h4>
                                        <p>Embark on a dream of a lifetime with air travel – a thrilling and safe mode of transport that captivates people from all walks of life. At SR PAY Private Limited, we believe in making this experience accessible to everyone, and our collaboration with top-notch companies ensures the most affordable rates in the world of air travel. Thanks to initiatives like UDAN (Ude Desh Ka Aam Naagrik) by the Indian government, this dream is closer to reality than ever before</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="blog-list-simple">
                            <div className="row">
                                <div className="col-md-5 col-sm-5 col-12">
                                    <div className="blog-list-simple-img">
                                        <img src="img/projects/train.jpg" alt="img" />
                                        <small className="comming-soon">Coming Soon</small>
                                    </div>
                                </div>
                                <div className="col-md-7 col-sm-7 col-12">
                                    <div className="blog-list-simple-text">
                                        {/* <span>Services</span> */}
                                        <h4 className="bluefont">Train <span className="white">Booking</span></h4>
                                        <p>India boasts the world's third-largest railway network, but many struggle to take advantage of it due to ticket booking challenges and long queues. Understanding these hurdles, SR PAY Private Limited has partnered with industry leaders to provide seamless access to confirmed train tickets, making train travel more convenient and enjoyable for all.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="blog-list-simple">
                            <div className="row">
                                <div className="col-md-5 col-sm-5 col-12">
                                    <div className="blog-list-simple-img">
                                        <img src="img/projects/bus.jpg" alt="img" />
                                        <small className="comming-soon">Coming Soon</small>
                                    </div>
                                </div>
                                <div className="col-md-7 col-sm-7 col-12">
                                    <div className="blog-list-simple-text">
                                        {/* <span>Services</span> */}
                                        <h4 className="bluefont">Bus Ticket <span className="white">Booking</span></h4>
                                        <p>Buses are the lifeline of every Indian, and SR PAY Private Limited has teamed up with the best tour and travel operators across the country to bring you incredibly competitive rates on bus bookings for every route imaginable. Traveling across India has never been more affordable and convenient.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="blog-list-simple">
                            <div className="row">
                                <div className="col-md-5 col-sm-5 col-12">
                                    <div className="blog-list-simple-img">
                                        <img src="img/projects/hotel.jpg" alt="img" />
                                        <small className="comming-soon">Coming Soon</small>
                                    </div>
                                </div>
                                <div className="col-md-7 col-sm-7 col-12">
                                    <div className="blog-list-simple-text">
                                        {/* <span>Services</span> */}
                                        <h4 className="bluefont">Hotel <span className="white">Booking</span></h4>
                                        <p>Whether it's for personal, business, or leisure travel, finding the perfect hotel is essential. SR PAY Private Limited, through our esteemed partners and leading hotel chains ensures that you enjoy very competitive rates on hotels of all categories, allowing you to select accommodations that suit your preferences and needs.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="blog-list-simple">
                            <div className="row">
                                <div className="col-md-5 col-sm-5 col-12">
                                    <div className="blog-list-simple-img">
                                        <img src="img/projects/taxi.webp" alt="img" />
                                        <small className="comming-soon">Coming Soon</small>
                                    </div>
                                </div>
                                <div className="col-md-7 col-sm-7 col-12">
                                    <div className="blog-list-simple-text">
                                        {/* <span>Services</span> */}
                                        <h4 className="bluefont">Taxi Service <span className="white">Booking</span></h4>
                                        <p>In today's fast-paced world, taxi services have become indispensable for seamless travel experiences. SR PAY Private Limited, in partnership with top-notch providers offers an array of luxurious and well-maintained cars with professional drivers, transparent billing, and attractive rates for both local and outstation travel. Your journey begins right at your doorstep with our hassle-free taxi booking service.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    )
}

export default TravelServices