import React, { useEffect, useState } from 'react';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { useHistory } from "react-router-dom";
import { Button, Checkbox, Radio } from 'antd';
// import CommingSoon from '../../container/commingsoon';
import { postRequest } from '../../Utils/AppApi';
import { POST_SEND_MATM_OFFER_API } from '../../Utils/appConstants';
import { toast } from 'react-toastify';
import { isNumber } from '../../Utils/Common';

function ServiceIndex(props) {
    const history = useHistory();
    const [isMatchCheckbox, setMatchCheckbox] = useState(false);
    const [isProductName, setProductName] = useState("Combo");
    const [isProductAmount, setProductAmount] = useState(null);
    const [isProductQuantity, setProductQuantity] = useState(5);

    const serviceOption = [{
        value: 'Combo',
        label: 'Combo',
        amount: '4150',
    }, {
        value: 'Mantra L1',
        label: 'Mantra L1',
        amount: '3099',
    }, {
        value: 'MATM (Morefun MP63)',
        label: 'MATM (Morefun MP63)',
        amount: '1750',
    }]

    const onChangeChackBox = (e) => {
        // console.log("e.target.checked -------------", e.target.checked)
        setMatchCheckbox(e.target.checked)
    };

    const handleSizeChange = ({ target: { value } }) => {
        let data = serviceOption.filter((items) => items.value === value);
        // console.log("e.target.checked -------------", data[0].amount)
        setProductName(value);
        setProductAmount(data[0].amount);
    };

    const onChangeQuantity = ({ target: { value } }) => {
        setProductQuantity(value);
    }

    const sendBuyOffer = () => {
        if (isProductQuantity < 5) {
            toast.error("The total quantity of orders meets the minimum 5 requirement.")
            return false;
        } else {
            let payload = {
                quantity: isProductQuantity,
                amount: isProductAmount,
                product: isProductName,
                comment: ""
            }
            postRequest(POST_SEND_MATM_OFFER_API, payload, history).then((response) => {
                if (response.success === true) {
                    setMatchCheckbox(false);
                    setProductQuantity(null)
                    window.location.reload();
                    toast.success(response.message);
                } else {
                    toast.error(response.message);
                }
            }).catch(function (error) {
                console.log(error)
                // setLoading(false);
            })
        }
    }

    useEffect(() => {
        setProductAmount(serviceOption[0].amount)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            <div className="content pt-3">
                <div className="container-fluid pb-5 five-xl-col">
                    <div className="row">
                        <div className="col-lg-5 ">
                            <img src='../../festival/offer.jpeg' alt='' />
                        </div>
                        <div className="offset-lg-1 col-lg-6">
                            <div className="row">
                                <div className="form-group col-md-12 form-group mt-3">
                                    <label className='form-label'>Product Name:</label>
                                    <br />
                                    <Radio.Group options={serviceOption} value={isProductName} buttonStyle="solid" optionType="button" onChange={handleSizeChange}>
                                    </Radio.Group>
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-md-12 form-group mt-3">
                                    <label className='form-label'>Quantity:</label>
                                    <br />
                                    <input type="number" className="form-control" name="quantity" placeholder="Enter Quantity" onChange={(e) => onChangeQuantity(e)} autoComplete="off" onKeyDown={isNumber} value={isProductQuantity} maxLength={4} />
                                </div>
                            </div>
                            <div className="row">
                                <Checkbox className="mr-2" onChange={onChangeChackBox} /><p className="mt-2 text-primary pl-3">I hereby confirm that i accept the SRPAY MATM Combo offer wroth Rs. {(isProductAmount * isProductQuantity)}.</p>
                                <strong>Note :- <span className="text-danger">The Product/Service you are buying is already in your Portal. For More Details Please contact with Your Distributor or SRPAY Support Team.</span></strong>
                            </div>
                            <div className="row align-items-end col-12 mt-3">
                                <Button className='btn btn-block bg-primary text-center' onClick={() => sendBuyOffer()} disabled={!isMatchCheckbox}>Submit</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ServiceIndex;