import { useState, useEffect } from 'react';
import axios from 'axios';
import { GET_AEPS_DEVICE_CONFIG, POST_AEPS_LOGIN, POST_AEPS_LOGIN_STATUS, GET_AEPS_BANK_LIST, /* BIOMETRIC_CAPTURE_API, BIOMETRIC_CAPTURE_XML_DATA */ } from './../../Utils/appConstants';
import { getUser } from '../../Utils/Common';
import { useHistory } from "react-router-dom";
// import { biometricDataToJson } from '../../Utils/function';
// import { ToastContainer } from 'react-toastify';
import xmlJs from 'xml-js';
import SettelTOBank from './../moveToBank/index';
import BankSetting from './../banksetting';
import { postRequest, getRequest } from '../../Utils/AppApi';
import CashWithdrawal from './CashWithdrawal';
import BioDeviceList from './bioDeviceList';
import BalanceEnquiry from './BalanceEnquiry';
import MiniStatement from './MiniStatement';
import AePSHistory from './AePSHistory';
import BioDevice from './bioDevice';
import { toast } from 'react-toastify';
import { Tabs, Modal, Checkbox } from 'antd';
import { reactLocalStorage } from 'reactjs-localstorage';
import moment from "moment";
const { TabPane } = Tabs;

const MyComponent = () => {

    const history = useHistory();
    const user = getUser();
    const [isConsent, setConsent] = useState("Y");
    // const LoginStatusDate = reactLocalStorage.get("LoginStatusDate");
    const tabPosition = 'left';
    const [fingerprint, setFingerprint] = useState(null)
    // const [fingerprintModal, setFingerprintModal] = useState(null)
    const [latitude, setLatitude] = useState("");
    const [longitude, setLongitude] = useState("");
    const [getBankList, setBankList] = useState([]);
    const [deviceConfig, setDeviceConfig] = useState({});
    const [isAuthAePSModel, setAuthAePSModel] = useState(false);
    const [isErrorAuth, setErrorAuth] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [subTabs, setSubTabs] = useState('1');
    const [getDataField, setDataField] = useState([]);

    useEffect(() => {
        aepsLoginStatus();
        getBankListData();
        aepsConfig();
        navigator.geolocation.getCurrentPosition((data) => {
            setLatitude(data.coords.latitude);
            setLongitude(data.coords.longitude);
        });
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (fingerprint) {
            // let data = biometricDataToJson(fingerprint)
            aepsLogin(fingerprint)
        }
    }, [fingerprint]) // eslint-disable-line react-hooks/exhaustive-deps

    const existInputData = (event, select) => {
        if(select) {
            setDataField({ ...getDataField, "bankiin": select });
        } else {
            const { name, value } = event.target ? event.target : event;
            // console.log("------existInputData--------------",name, value)
            // let aepsInputValue = reactLocalStorage.get("aepsInputValue");
            // reactLocalStorage.set("aepsInputValue", JSON.stringify(getDataField));
            setDataField({ ...getDataField, [name]: value });
        }
    }

    const clearExistInputData = () => {
        window.location.reload()
        // console.log("------response--------------")
        setDataField({});
    }

    const aepsConfig = () => {
        getRequest(GET_AEPS_DEVICE_CONFIG, history).then((response) => {
            if (response.success === true) {
                // console.log("------response--------------", response)
                setDeviceConfig(response.data)
                reactLocalStorage.set("aepsConfig", JSON.stringify(response.data));
                // toast.success(response.message);
            } else {
                setAuthAePSModel(true)
                setErrorAuth(false)
                setErrorMessage(response.message)
            }
        })
    }

    const getBankListData = async () => {
        await getRequest(GET_AEPS_BANK_LIST, history).then((response) => {
            if (response.success === true) {
                setBankList(response.data && response.data.map(item => {
                    return { "value": item.iin, "label": item.name };
                }));
            } else {
                toast.error(response.message);
            }
        }).catch(function (error) {
            console.log(error)
            // setLoading(false);
        })
    }

    const aepsLogin = (data) => {
        const payload = {
            type: "DAILY_LOGIN",
            latitude: latitude,
            longitude: longitude,
            isXml: true,
            devicetype: "W",
            biometricData: data,
        }

        postRequest(POST_AEPS_LOGIN, payload, history).then((response) => {
            if (response.success === true) {
                // console.log("------response--------------", moment().format('DD-MM-YYYY'))
                // reactLocalStorage.set("LoginStatusDate", moment().format('DD-MM-YYYY'));
                setAuthAePSModel(false)
                // toast.success(response.message);
            } else {
                // console.log("------response--------------", response)
                setAuthAePSModel(true)
                toast.error(response.message);
            }
            
        })
    }

    const aepsLoginStatus = () => {
        let payload = { date: moment().format('YYYY-MM-DD'), key: "AEPS_INSTPAY" }
        postRequest(POST_AEPS_LOGIN_STATUS, payload, history).then((response) => {
            if (response.success === true) {
                // console.log("------response--------------", response)
                // toast.success(response.message);
                // if (LoginStatusDate !== moment().format('DD-MM-YYYY')) {
                    // setScan(true)
                    setAuthAePSModel(false)
                // }
            } else {
                // if (LoginStatusDate !== moment().format('DD-MM-YYYY')) {
                    // setScan(true)
                    setAuthAePSModel(true)
                // }
                setErrorAuth(false)
                setErrorMessage(response.message)
            }
            // console.log("------response--------------", moment().format('DD-MM-YYYY'))
        })
    }

    const scanFingerPrintData = async () => {
        await axios({
            method: 'CAPTURE',
            // url: deviceConfig.captureUrl,
            url: "http://127.0.0.1:11100/rd/capture",
            headers: {
                "Content-Type": "text/xml; charset=UTF-8",
            },
            data: '<PidOptions ver="1.0"><Opts fCount="1" fType="2" iCount="0" iType="0" pCount="0" format="0" pidVer="2.0" timeout="10000" posh="UNKNOWN" env="P" wadh=""/></PidOptions>'
            // data: deviceConfig.xmlData
        }).then(response => {
            const jsonData = xmlJs.xml2json(response.data, { compact: true, spaces: 4 });
            let XMLjson = JSON.parse(jsonData);
            console.log("response---------------------------", response)
            if (XMLjson && XMLjson.PidData.Resp._attributes.errCode !== "700") {
                setFingerprint(response.data)
            }
        }).catch(error => {
            console.log("err---------------------------", error)
        })
    };

    // const getRDService = async () => {
    //     await fetch("http://127.0.0.1:11100", {
    //         method: "RDSERVICE",
    //         mode: "cors",
    //         headers: {
    //             "Content-Type": "text/xml; charset=UTF-8",
    //             // "Access-Control-Allow-Methods": "RDSERVICE,DEVICEINFO,CAPTURE"
    //         },
    //         body: '<?xml version="1.0"?><PidOptions ver="1.0"><Opts fCount="1" fType="2" iCount="0" iType="0" pCount="0" format="0" pidVer="2.0" timeout="10000" posh="UNKNOWN" env="P" wadh=""/></PidOptions>'
    //     })
    //         .then(res => {
    //             if (res.status === 200) {
    //                 console.log("-res RD---------", res.json())
    //             } else {
    //                 alert("error");
    //             }
    //         })
    // };

    // const getInfo = async () => {
    //     await axios({
    //         method: 'DEVICEINFO',
    //         url: "http://127.0.0.1:11100/rd/info",
    //         headers: {
    //             "Content-Type": "text/xml; charset=UTF-8",
    //         },
    //         data: '<PidOptions ver="1.0"><Opts fCount="1" fType="2" iCount="0" iType="0" pCount="0" format="0" pidVer="2.0" timeout="10000" posh="UNKNOWN" env="P" wadh=""/></PidOptions>'
    //         // data: deviceConfig.xmlData
    //     }).then(response => {
    //         if (response.status === 200) {
    //             console.log("-res info---------", response)
    //         } else {
    //             alert("error");
    //         }
    //     }).catch(error => {
    //         console.log("errrrrrrrrrr-", error)
    //     })
    // };

    useEffect(() => {
        // console.log("subTabs-----------", subTabs)
        // getInfo()
    }, [subTabs]) // eslint-disable-line react-hooks/exhaustive-deps

    const subChangeTab = (activeKey) => {
        setSubTabs(activeKey)
        // setFingerprintModal(null)
    }

    const handleCloseModal = () => {
        setAuthAePSModel(false)
    }

    if (user.data && !user.data.userDetails.outletId) {
        return (
            <div>
                <div className="content-wrapper pt-2">
                    <div className="content">
                        <div className="container-fluid pt-2 pb-5">
                            <p className='text-danger'>Dear Partner You are not on boarded for AePS, Please Go to Partner KYC section & do Your KYC for AePS.</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const items = [
        {
            key: '1',
            label: 'Cash Withdrawal',
            children: <CashWithdrawal deviceConfig={deviceConfig} getBankList={getBankList} subChangeTab={subChangeTab} existInputData={existInputData} existData={getDataField} clearExistInputData={clearExistInputData} />,
        },
        {
            key: '2',
            label: 'Balance Enquiry',
            children: <BalanceEnquiry deviceConfig={deviceConfig} getBankList={getBankList} subChangeTab={subChangeTab} existInputData={existInputData} existData={getDataField} clearExistInputData={clearExistInputData} />,
        },
        {
            key: '3',
            label: 'Mini Statement',
            children: <MiniStatement deviceConfig={deviceConfig} getBankList={getBankList} subChangeTab={subChangeTab} existInputData={existInputData} existData={getDataField} clearExistInputData={clearExistInputData} />,
        },
        {
            key: '4',
            label: 'AePS History',
            children: <AePSHistory />,
        },
    ];

    const onChangeChackBox = (e) => {
        if (e.target.checked === true) {
            setConsent('Y')
        } else {
            setConsent('N')
        }
    };
    return (
        <div>
            <div className="content-wrapper pt-2">
                <div className="content">
                    <div className="container-fluid pt-2 pb-5">
                        <div className="row">
                            <div className="col-md-12">
                                <div className='card rounded-0 card-body'>
                                    <Tabs defaultActiveKey="1" tabPosition={tabPosition}>
                                        <TabPane tab={`Transact`} key={1}>
                                            <div className="col-12 text-right">
                                                <strong className='text-right text-danger  fa-xl'>Powered By YBL / APB</strong>
                                            </div>
                                            <Tabs defaultActiveKey={'1'} activeKey={subTabs} tabPosition={'top'} items={items} type="card" onChange={subChangeTab}>
                                                {/* <TabPane tab={`Cash Withdrawal`} key={1}>
                                                    { subTabs === '1' && <CashWithdrawal getBankList={getBankList} subChangeTab={subChangeTab} />}
                                                </TabPane>
                                                <TabPane tab={`Balance Enquiry`} key={2}>
                                                    { subTabs === '2' && <BalanceEnquiry getBankList={getBankList} subChangeTab={subChangeTab} />}
                                                </TabPane>
                                                <TabPane tab={`Mini Statement`} key={3}>
                                                    { subTabs === '3' && <MiniStatement getBankList={getBankList} subChangeTab={subChangeTab} />}
                                                </TabPane>
                                                <TabPane tab={`AePS History`} key={4}>
                                                </TabPane> */}
                                            </Tabs>
                                        </TabPane>
                                        <TabPane tab={`Settel To Bank`} key={2}>
                                            <SettelTOBank />
                                        </TabPane>
                                        <TabPane tab={`Device Setting`} key={3}>
                                            {/* <CashWithdrawal /> */}
                                        </TabPane>
                                        <TabPane tab={`Bank Setting`} key={4}>
                                            <BankSetting />
                                        </TabPane>
                                        <TabPane tab={`Biometric Devices`} key={5}>
                                            <BioDeviceList />
                                        </TabPane>
                                    </Tabs>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <ToastContainer position="top-center" autoClose={5000} limit={2} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="colored" /> */}
                {isAuthAePSModel && <Modal title={"AePS Verification Secure Login"} width={600} open={isAuthAePSModel} onCancel={() => handleCloseModal()} footer={false} >
                    {isErrorAuth ?
                        <div><p className='text-danger'>{errorMessage && errorMessage.replace('please try again later', 'Please wait until Bank approval')}</p>
                        </div>
                        :
                        <>
                        <div className='text-center h-100'>
                            <img src={'./../fingerprint-svgrepo-com.svg'} alt='' width={'175px'} />
                        </div>
                        <div className='col-sm-12 col-md-12 col-lg-12 mb-120'>
                            <div className='form-group'>
                                <Checkbox className='d-flex' checked={isConsent}  onChange={onChangeChackBox}>I hereby give my voluntary consent to SR PAY Private Limited, use my Aadhaar/ VID number & biometric to authenticate from UIDAI for the purpose of AEPS transaction.</Checkbox>
                            </div>
                        </div>
                        <BioDevice />
                        <button className='btn btn-primary btn-lg bg-primary btn-block' onClick={() => scanFingerPrintData()}>Scan Finger</button></>}
                </Modal>}
            </div>
        </div>
    )
}

export default MyComponent;