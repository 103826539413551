import React, { useEffect, useState, useRef } from 'react';
import { getUser, isNumber } from './../../Utils/Common';
import PropagateLoader from "react-spinners/PropagateLoader";
import { POST_AEPS_EKYC_INITIATE, POST_AEPS_EKYC_VALIDATE, GET_BANK_SETTING_LIST } from './../../Utils/appConstants';
import { getRequest, postRequest } from './../../Utils/AppApi';
import { useHistory } from "react-router-dom";
import { toast } from 'react-toastify';
import { Divider, Modal, Checkbox } from 'antd';
import BankSetting from '../banksetting';

function PartnerEkyc() {
    // console.log("user------", user.data)
    const user = getUser();
    const history = useHistory();
    const [getBankDetailModal, setBankDetailModal] = useState(false);
    const [checkKycAlreadyExist, setCheckKycAlreadyExist] = useState(null)
    const [getDataField, setDataField] = useState([]);
    const [getDataError, setDataError] = useState({});
    const [consent, setConsent] = useState("Y");
    const [latitude, setLatitude] = useState("");
    const [longitude, setLongitude] = useState("");
    const [isHash, setHashData] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [otpReferenceID, setOtpReferenceID] = useState(null);
    const [bankDetailData, setBankDetailData] = useState([]);

    const [isOtpModal, setOtpModal] = useState(false);
    const [otpCode, setOtpCode] = useState(null)
    const mobileInput = useRef(null);
    const otpNumberRef = useRef(null);

    const getBankDetailList = () => {
        // const queryString = objectToQueryString(params);
        getRequest(`${GET_BANK_SETTING_LIST}`, history).then(async (response) => {
            if (response.success === true) {
                setBankDetailData(response.data.docs);
                let bankRecord = response.data.docs[0];
                setDataField({ ...getDataField, "ifsccode": bankRecord.ifscCode, "accountNumber": bankRecord.accountNumber });
            } else {
            }
        }).catch(function (error) {
            console.log(error)
            // setLoading(false);
        })
    } // eslint-disable-line react-hooks/exhaustive-deps

    const clearFormField = () => {
        mobileInput.current.value = "";
        otpNumberRef.current.value = "";
        setCheckKycAlreadyExist(false)
    }

    const onChangeValue = (event) => {
        const { name, value } = event.target;
        setDataField({ ...getDataField, [name]: value });
    }

    const onChangeChackBox = (e) => {
        // console.log(`checked = ${e.target.checked}`);
        if (e.target.checked === true) {
            setConsent('Y')
        } else {
            setConsent('N')
        }
    };

    const FormValidate = () => {
        let formValid = true;
        let formObject = {};
        setDataError({})
        if (getDataField && getDataField.length === 0) {
            formValid = false;
        }

        let array = ["mobile", "accountNumber", "ifsccode"];
        array.forEach(element => {
            if (getDataField[element] === "" || typeof getDataField[element] === "undefined") {
                formObject[element] = true;
                formValid = false;
            }
        });

        if (consent === 'N') {
            toast.error("Please aadhaar accept consent declaration");
            formValid = false;
        }

        setDataError(formObject);
        return formValid;
    }

    const handleSubmit = () => {
        if (otpCode) {
            setLoading(true)
            handleSubmitOtp()
        } else if (FormValidate()) {
            setLoading(true)
            const payload = {
                mobile: getDataField.mobile,
                accountNumber: getDataField.accountNumber,
                ifsccode: getDataField.ifsccode,
                pan: user.data.userDetails.pancardNumber,
                email: user.data.userDetails.email,
                aadhaar: user.data.userDetails.aadharNumber,
                latitude: latitude,
                longitude: longitude,
                consent: consent,
            }

            postRequest(POST_AEPS_EKYC_INITIATE, payload, history).then((response) => {
                if (response.success === true) {
                    setOtpReferenceID(response.data.otpReferenceID)
                    setHashData(response.data.hash)
                    setOtpModal(true)
                    toast.success("Otp code send on your aadhaar registered mobile number");
                } else {
                    toast.error(response.message);
                }
                setLoading(false)
            }).catch(function (error) {
                // console.log(error)
                setLoading(false)
            })
        }
    }

    const handleSubmitOtp = () => {
        const payload = {
            otpReferenceID: otpReferenceID,
            hash: isHash,
            otp: otpCode,
        }

        postRequest(POST_AEPS_EKYC_VALIDATE, payload, history).then((response) => {
            if (response.success === true) {
                window.location.reload()
                setDataField([]);
                setDataError({});
                setOtpReferenceID(null)
                setHashData(null)
                setCheckKycAlreadyExist(false)
                setOtpModal(false)
                clearFormField();
                toast.success(response.message);
            } else {
                toast.error(response.message);
            }
            setLoading(false)
        }).catch(function (error) {
            // console.log(error)
            setLoading(false)
        })

    }

    const handleCloseModal = () => {
        toast.error("Mandatory in Partner KYC")
        history.push({ pathname: "/dashboard" });
    }

    useEffect(() => {
        getBankDetailList()
        setCheckKycAlreadyExist(user && user.data && user.data.userDetails && user.data.userDetails.outletId);
        navigator.geolocation.getCurrentPosition((data) => {
            setLatitude(data.coords.latitude)
            setLongitude(data.coords.longitude)
        });
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    if (isLoading) {
        return <div className="content text-center" style={{ margin: "250px 0" }}>
            <PropagateLoader color="#5851D2" loading={isLoading} size={25} />
        </div>
    }
    return (
        <div>
            {getBankDetailModal && <Modal title={"Bank Detail"} width={1400} open={getBankDetailModal} onCancel={() => setBankDetailModal(false)} footer={false} >
                <BankSetting />
            </Modal>}
            {!checkKycAlreadyExist && <Modal title={"Partner e-kyc"} width={600} open={!checkKycAlreadyExist} onCancel={() => handleCloseModal()} okText={"Submit"} onOk={() => handleSubmit()} >
                <ul>
                    <li><span>Pan Card</span> : {user.data.userDetails.pancardNumber}</li>
                    <li><span>Email Address</span> : {user.data.userDetails.email}</li>
                    <li><span>Aadhar Number</span> : {user.data.userDetails.aadharNumber}</li>
                    {bankDetailData.length === 0 && <li className='text-right'><button onClick={() => setBankDetailModal(true)} className='btn-btn-sm btn-primary'>Get Bank Details</button></li>}
                    <Divider />
                    <li><div className='col-md-12 col-lg-12 col-sm-12 m-0'>
                        <div>
                                <div className="form-group">
                                    <label>Aadhar Registered Mobile Number: </label>
                                    <input type="number" className={`form-control normal-light`} name="mobile" maxLength='10' onChange={(e) => onChangeValue(e)} placeholder="Aadhar Registered Mobile Number" onKeyPress={isNumber} value={getDataField && getDataField.mobile} ref={mobileInput} />
                                    {getDataError && getDataError.mobile && <span className="error text-danger">The field is required</span>}
                                </div>
                                <div className="form-group">
                                    <label>Account Number: </label>
                                    <input type="number" className={`form-control normal-light`} name="mobile" maxLength='19' onChange={(e) => onChangeValue(e)} placeholder="Account Number" onKeyPress={isNumber} value={getDataField && getDataField.accountNumber} ref={mobileInput} readOnly />
                                    {getDataError && getDataError.accountNumber && <span className="error text-danger">The field is required</span>}
                                </div>
                                <div className="form-group">
                                    <label>IFSC Code: </label>
                                    <input type="text" className={`form-control normal-light`} name="mobile" maxLength='19' onChange={(e) => onChangeValue(e)} placeholder="IFSC Code" value={getDataField && getDataField.ifsccode} ref={mobileInput} readOnly />
                                    {getDataError && getDataError.ifsccode && <span className="error text-danger">The field is required</span>}
                                </div>
                                <div className='form-group'>
                                    <Checkbox className='d-flex' checked={consent === "Y" ? true : false} onChange={onChangeChackBox}>I hereby give my voluntary consent to SR PAY Private Limited, use my Aadhaar/ VID number & biometric to authenticate from UIDAI for the purpose of AEPS activation.</Checkbox>
                                </div>
                            </div>
                    </div></li>
                </ul>
            </Modal>}

            {isOtpModal && <Modal title={"Verification OTP"} width={600} open={isOtpModal} onOk={() => handleSubmit()} onCancel={() => setOtpModal(false)} okText={"Submit"} >
                <div className="form-group">
                    <label>OTP Code</label>
                    <div className="input-group">
                        <div className="custom-file">
                            <input type="text" className="form-control" name="otpCode" placeholder="OTP Code" onChange={(e) => setOtpCode(e.target.value)} autoComplete="off" value={otpCode} maxLength={6} onKeyDown={isNumber} />
                        </div>
                    </div>
                </div>
            </Modal>}
        </div>
    )
}

export default PartnerEkyc