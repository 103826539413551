import React, { useEffect, useState } from 'react';
import { isNumber } from './../../Utils/Common';
import PropagateLoader from "react-spinners/PropagateLoader";
import axios from 'axios';
// import xmlJs from 'xml-js';
// import { Tabs, Tab } from 'react-bootstrap-tabs';
// import { POST_AEPS_EKYC_INITIATE, POST_AEPS_EKYC_VALIDATE, GET_SHOP_REGISTERD, GET_BIO_DEVICE_LIST, POST_AEPS_EKYC_EKO_STEP_1, POST_AEPS_EKYC_EKO_STEP_2, POST_AEPS_EKYC_EKO_STEP_3, POST_AEPS_EKYC_EKO_STEP_4, POST_AEPS_EKYC_EKO_STEP_5, POST_AEPS_EKYC_EKO_STEP_6, GET_AEPS_DEVICE_CONFIG, GET_BANK_SETTING_LIST } from './../../Utils/appConstants';
import { GET_AEPS_DEVICE_CONFIG, FING_PAY_ONBOARDING, FING_PAY_CHECK_EKYC_STATUS, FING_PAY_CHECK_EKYC_SEND_OTP, FING_PAY_CHECK_EKYC_RESEND_OTP, FING_PAY_CHECK_EKYC_SEND_OTP_VERIFY, FING_PAY_CHECK_EKYC_BIOMETRIC_REQUEST, FING_PAY_2FA_BIOMETRIC_REQUEST } from './../../Utils/appConstants';
import { getRequest, postRequest } from './../../Utils/AppApi';
import { Button, Steps, Modal, Checkbox } from 'antd';
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import BioDevice from './../aepsInstant/bioDevice';
import { getGeoLocation } from './../../Utils/function';

function FingPay() {
    const bioTitle = "Bio Metric Request";
    const [isAuthAePSModel, setAuthAePSModel] = useState(false);
    const [userKycStatus, setUserKycStatus] = useState(0);
    const history = useHistory();

    const [latitude, setLatitude] = useState("");
    const [longitude, setLongitude] = useState("");
    const [consent, setConsent] = useState("N");
    const [isLoading, setLoading] = useState(false);
    const [primaryKeyId, setPrimaryKeyId] = useState('');
    const [encodeFPTxnId, setEncodeFPTxnId] = useState('');

    const [fingerprint, setFingerprint] = useState(null)
    const [deviceConfig, setDeviceConfig] = useState({});

    const [otpSent, setOtpSent] = useState(false);
    const [resendTime, setResendTime] = useState(0);

    const [isOtpModal, setOtpModal] = useState(false);
    const [otpCode, setOtpCode] = useState(null);

    const formatTime = seconds => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    };

    const expressOnboarding = () => {
        setLoading(true)
        postRequest(FING_PAY_ONBOARDING, {}, history).then((response) => {
            if (response.success === true) {
                setUserKycStatus(1)
            } else {
                toast.error(response.data);
            }
            setLoading(false)
        }).catch(function (error) {
            // console.log(error)
            setLoading(false)
        })

    } // eslint-disable-line react-hooks/exhaustive-deps

    const expressOnboardingSentOtpRequest = () => {
        setLoading(true)
        postRequest(FING_PAY_CHECK_EKYC_SEND_OTP, {}, history).then((response) => {
            if (response.success === true) {
                let data = response.data;
                setOtpModal(true);
                setPrimaryKeyId(data.primaryKeyId);
                setEncodeFPTxnId(data.encodeFPTxnId);
            } else {
                toast.error(response.data);
            }
            setLoading(false)
        }).catch(function (error) {
            // console.log(error)
            setLoading(false)
        })

    } // eslint-disable-line react-hooks/exhaustive-deps

    const expressOnboardingReSentOtpRequest = () => {
        setLoading(true)
        let payload = { primaryKeyId: primaryKeyId, encodeFPTxnId: encodeFPTxnId }
        postRequest(FING_PAY_CHECK_EKYC_RESEND_OTP, payload, history).then((response) => {
            if (response.success === true) {
                let data = response.data;
                setPrimaryKeyId(data.primaryKeyId);
                setEncodeFPTxnId(data.encodeFPTxnId);
            } else {
                toast.error(response.data);
            }
            handleResendOtp();
            setLoading(false)
        }).catch(function (error) {
            // console.log(error)
            setLoading(false)
        })

    } // eslint-disable-line react-hooks/exhaustive-deps

    const kycOtpSendRequest = () => {
        setLoading(true)
        let payload = { otp: otpCode, primaryKeyId: primaryKeyId, encodeFPTxnId: encodeFPTxnId }
        postRequest(FING_PAY_CHECK_EKYC_SEND_OTP_VERIFY, payload, history).then((response) => {
            if (response.success === true) {
                setOtpModal(false);
                setAuthAePSModel(true);
                setUserKycStatus(2)
            } else {
                toast.error(response.message);
            }
            setLoading(false)
        }).catch(function (error) {
            // console.log(error)
            setLoading(false)
        })
    } // eslint-disable-line react-hooks/exhaustive-deps

    const scanFingerPrintData = async () => {
        await axios({
            method: 'CAPTURE',
            url: deviceConfig.captureUrl,
            headers: {
                "Content-Type": "text/xml; charset=UTF-8",
            },
            data: deviceConfig.rdServiceFPData
        }).then(response => {
            setFingerprint(response.data)
        }).catch(error => {
            console.log("errrrrrrrrrr-", error)
        })
    };

    const ekycBiometricFinal = () => {
        setLoading(true)
        let payload = { isXml: true, biometricData: fingerprint, primaryKeyId: primaryKeyId, encodeFPTxnId: encodeFPTxnId }
        postRequest(FING_PAY_CHECK_EKYC_BIOMETRIC_REQUEST, payload, history).then((response) => {
            if (response.success === true) {
                setAuthAePSModel(false);
                // setUserKycStatus(3)
                // setFingerprint(null)
                toast.success(response.message);
            } else {
                toast.error(response.message);
            }
            setLoading(false)
        }).catch(function (error) {
            // console.log(error)
            setLoading(false)
        })
    } // eslint-disable-line react-hooks/exhaustive-deps

    const twoFAAuth = () => {
        setLoading(true)
        let payload = { isXml: true, biometricData: fingerprint, latitude: latitude, longitude: longitude }
        postRequest(FING_PAY_2FA_BIOMETRIC_REQUEST, payload, history).then((response) => {
            if (response.success === true) {
                setAuthAePSModel(false);
                setFingerprint(null)
                toast.success(response.message);
            } else {
                toast.error(response.message);
            }
            setLoading(false)
        }).catch(function (error) {
            setLoading(false)
        })
    } // eslint-disable-line react-hooks/exhaustive-deps

    const handleCloseModal = () => {
        setAuthAePSModel(false);
    }

    const handleSubmit = () => {
        switch (userKycStatus) {
            case 1: expressOnboardingSentOtpRequest();
                break;
            // case 3: twoFAAuthFingerPrint();
            //     break;
            default: expressOnboarding();
                break;
        }
    }

    const onChangeChackBox = (e) => {
        // console.log(`checked = ${e.target.checked}`);
        if (e.target.checked === true) {
            setConsent('Y')
        } else {
            setConsent('N')
        }
    };

    const aepsConfigCheckStatus = () => {
        getRequest(FING_PAY_CHECK_EKYC_STATUS, history).then((response) => {
            if (response.success === true) {
                // console.log("------response--------------", response)
                if (response.data) {
                    if (response.data.isCompleted) {
                        setUserKycStatus(4);
                    } else if (response.data.merchantBioMetricStatus) {
                        setUserKycStatus(3);
                    } else if (response.data.merchantEkycStatus) {
                        setUserKycStatus(2);
                    } else if (response.data.merchantOnboardStatus) {
                        setUserKycStatus(1);
                    }
                }
            } else {
                setUserKycStatus(0)
            }
        })
    }

    const handleResendOtp = () => {
        setOtpSent(true);
        // Set a timer for 2 minutes (in seconds)
        const timerDuration = 2 * 60;
        setResendTime(timerDuration);

        const resendTimer = setInterval(() => {
            setResendTime(prevTime => (prevTime > 0 ? prevTime - 1 : 0));
        }, 1000); // Update the timer every second

        return () => {
            clearInterval(resendTimer);
        };
    };

    // const twoFAAuthFingerPrint = () => {
    //     // setBioTitle("2FA Authentication");
    //     scanFingerPrintData();
    // }

    useEffect(() => {
        if (fingerprint && userKycStatus === 3) {
            twoFAAuth();
        } else 
        if (fingerprint) {
            ekycBiometricFinal();
        }
    }, [fingerprint]) // eslint-disable-line react-hooks/exhaustive-deps

    const aepsConfig = () => {
        getRequest(GET_AEPS_DEVICE_CONFIG, history).then((response) => {
            if (response.success === true) {
                // console.log("------response--------------", response)
                setDeviceConfig(response.data)
                // toast.success(response.message);
            } else {
                // setAuthAePSModel(true)
                // setErrorAuth(false)
                // setErrorMessage(response.message)
            }
        })
    }

    const fetchLocation = async () => {
        let data = await getGeoLocation();
        setLatitude(data.latitude);
        setLongitude(data.longitude);
    }

    useEffect(() => {
        aepsConfig();
        aepsConfigCheckStatus();
        fetchLocation();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    // useEffect(() => {
    //     aepsConfigCheckStatus();
    // }, [userKycStatus]) // eslint-disable-line react-hooks/exhaustive-deps

    if (isLoading) {
        return <div className="content text-center" style={{ margin: "250px 0" }}>
            <PropagateLoader color="#5851D2" loading={isLoading} size={25} />
        </div>
    }
    return (
        <div>
            <div className="container-fluid">
                <div className="list-group">
                    <div className="list-group-item list-group-item-action active">FP AePS EKYC</div>
                    <div className="list-group-item list-group-item-action">
                        <Steps
                            direction="vertical"
                            size="small"
                            current={userKycStatus}
                            items={[
                                {
                                    title: "Onboarding",
                                }, {
                                    title: 'E - KYC',
                                }, {
                                    title: 'Biometric Auth',
                                }, {
                                    title: 'Bank Pending',
                                }, {
                                    title: 'Completed',
                                }
                            ]}
                        />
                    </div>
                    { userKycStatus < 3 && 
                    <div className="list-group-item">
                        <Button className='btn btn-block btn-lg' onClick={() => handleSubmit()}>Process</Button>
                    </div>}

                    {/* <div className="list-group-item">
                        <Button className='btn btn-block btn-lg' onClick={() => twoFAAuthFingerPrint()}>2FA Auth</Button>
                    </div> */}
                </div>
                <ToastContainer position="top-center" autoClose={5000} limit={2} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="colored" />
                {isOtpModal && <Modal title={"Verification OTP"} width={600} open={isOtpModal} onOk={() => kycOtpSendRequest()} onCancel={() => setOtpModal(false)} okText={"Submit"} >
                    <div className="form-group">
                        <label>OTP Code</label>
                        <div className="input-group">
                            <div className="custom-file">
                                <input type="text" className="form-control" name="otpCode" placeholder="OTP Code" onChange={(e) => setOtpCode(e.target.value)} autoComplete="off" value={otpCode} maxLength={6} onKeyDown={isNumber} />
                            </div>
                            <div className='col-md-12 form-group text-right'>
                                {otpSent && <p>Resend in: {formatTime(resendTime)}</p>}
                                <button type='button' onClick={() => expressOnboardingReSentOtpRequest()} className='btn btn-sm btn-warning mr-2' disabled={otpSent}>Resend OTP</button>
                            </div>
                        </div>
                    </div>
                </Modal>}

                {isAuthAePSModel && <Modal title={bioTitle} width={600} open={isAuthAePSModel} onCancel={() => handleCloseModal()} footer={false} >
                    <div className='text-center h-100'>
                        <img src={'./../fingerprint-svgrepo-com.svg'} alt='' width={'175px'} />
                    </div>
                    <div className='col-sm-12 col-md-12 col-lg-12 mb-120'>
                        <div className='form-group'>
                            <Checkbox className='d-flex' checked={consent} onChange={onChangeChackBox}>I hereby give my voluntary consent to SR PAY Private Limited, use my Aadhaar/ VID number & biometric to authenticate from UIDAI for the purpose of AEPS Onboarding.</Checkbox>
                        </div>
                    </div>
                    <BioDevice />
                    <button className='btn btn-primary btn-lg bg-primary btn-block' onClick={() => scanFingerPrintData()}>Scan Finger</button>
                </Modal>}
            </div>
        </div>
    )
}

export default FingPay