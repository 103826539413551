
import React, { useEffect, useState } from "react";
import { Select } from "antd";
import { useHistory } from "react-router-dom";
import { getRequest, postRequest } from '../../Utils/AppApi';
import { GET_BIO_DEVICE_LIST, POST_BIO_DEVICE_LIST } from './../../Utils/appConstants';

import { ToastContainer, toast } from 'react-toastify';

function Device() {
    const history = useHistory();
    const [getBioDeviceData, setGetBioDeviceData] = useState([])
    const [selectedDevice, setSelectedDevice] = useState({})

    const handleChange = (value) => {
        const payload = { deviceId: value }
        setSelectedDevice(value)
        postRequest(POST_BIO_DEVICE_LIST, payload, history).then((response) => {
            if (response.success === true) {
                toast.success(response.message);
                window.location.reload()
            } else {
                toast.error(response.message);
            }
        }).catch(function (error) {
            // console.log(error)
            // setLoading(false);
        })
    };

    const getBioDeviceList = () => {
        getRequest(GET_BIO_DEVICE_LIST+"?isWeb=true", history).then((response) => {
            if (response.success === true) {
                setGetBioDeviceData(response.data.map((element) => {
                    if(element.isDefault) {
                        setSelectedDevice(element.id)
                    }
                    // return { label: element.name, value: element.id }
                    return { label: <div className="d-flex"><img class="rounded-circle" width={"10%"} alt="device" src={element.image} /><p className="fa-10x fs ml-15 text-110">{element.name}</p></div>, value: element.id }
                }))
            } else {
                toast.error(response.message);
                // setIsProfileCheck(false);
            }
        }).catch(function (error) {
            // console.log(error)
        })
    }

    useEffect(() => {
        getBioDeviceList()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="d-flex my-3 p-2 rounded-border">
            {/* <img class="rounded-circle" width={"10%"} alt="device" src="../../../biometric/mantra.jpg" />
            <p className="fa-10x fs ml-15 text-110">Mantra</p> */}
            <Select value={selectedDevice} className="border" size={"large"} style={{ width: "100%" }} onChange={handleChange} options={getBioDeviceData}>
            </Select>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                limit={3}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </div>
    )
}

export default Device; 