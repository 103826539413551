import React, { useEffect, useState } from 'react';
// import CsvDownloader from 'react-csv-downloader';
import { Modal, Select, Tooltip, Radio } from 'antd';
// import { CSVLink } from 'react-csv';
import DataTable from 'react-data-table-component';
import 'antd/dist/antd.css';
import { useHistory } from "react-router-dom";
import { SLABS_LIST, SLABS_UPDATE, SLABS_NAMES, SLABS_DELETE } from './../../../Utils/appConstants';
import { getRequest, putRequest, deleteRequestNewApi } from './../../../Utils/AppApi';
import { ToastContainer, toast } from 'react-toastify';
// import ImageViewer from 'react-simple-image-viewer';
import { INRFormat, isNumberKey } from './../../../Utils/Common';
// import moment from 'moment';
// import Moment from 'react-moment';
// import { event } from 'jquery';

function SlabList(props) {
    console.log("props isDrop -------",props.isDrop)
    console.log("props slabId -------",props.slabId)
    // const user = getUser();
    const history = useHistory();
    const limit = 10;
    const [isDrop, setDrop] = useState(true);
    const [getData, setData] = useState([]);
    const [getSlabList, setSlabList] = useState([]);
    const [getTotalRecord, setTotalRecord] = useState(0);
    const [isLimit, setLimit] = useState(limit)
    const [page, setPage] = useState(1);
    const [isModalEditDetail, setIsModalEditDetail] = useState(false);
    const [slabId, setSlabId] = useState('');
    const [isSelectedUser, setIsSelectedUser] = useState({});

    const columns = [{
        name: 'Name',
        selector: row => <><Tooltip placement="bottom" title={row.name}>{row.name}</Tooltip></>,
        sortable: true,
        width: "150px"
    }, {
        name: 'Min',
        selector: row => <Tooltip placement="bottom" title={row.min}>{row.min}</Tooltip>,
        // width: "120px"
    }, {
        name: 'Max',
        selector: row => <Tooltip placement="bottom" title={row.max}>{row.max}</Tooltip>,
        // width: "120px"
    }, {
        name: 'Commission(%)',
        selector: row => (row.isPerCommission) ? '%' : "Flat",
        // width: "150px"
    }, {
        name: 'Commission',
        selector: row => (row.commission),
        // width: "120px"
    }, {
        name: 'Ser Charge(%)',
        selector: row => (row.isServiceCharge) ? '%' : 'Flat',
        // width: "120px"
    }, {
        name: 'Service Charge',
        selector: row => row.serviceCharge ? <Tooltip placement="bottom" title={INRFormat(row.serviceCharge)}>{INRFormat(row.serviceCharge)}</Tooltip> : 0,
        // width: "120px"
    }, {
        name: 'Tds Applicable',
        selector: row => (row.isTdsApplicable) ? 'Yes' : 'No',
        // width: "120px"
    }, {
        name: 'TDS',
        selector: row => row.tds ? <Tooltip placement="bottom" title={INRFormat(row.tds)}>{INRFormat(row.tds)}</Tooltip> : 0,
    }, {
        name: 'Action',
        cell: row => <><div className='icon-border border border-primary text-primary pointer' title='Edit Data'><i className="fa fa-edit" onClick={(event) => handleUserDetail(event, row)} aria-hidden="true"></i></div><div className='icon-border border border-danger text-danger pointer' onClick={(event) => handleDelete(event, row._id)} title='Delete'><i className="fa fa-trash" aria-hidden="true"></i></div></>,
    }]

    const getSlab = () => {
        getRequest(SLABS_NAMES, history).then( async (response) => {
            if (response.success === true) {
                setSlabList([...getSlabList, ...response.data.map((item) => {
                    return { "value" : item._id, "label" : item.name};
                })]);
            } else {
                toast.error(response.message);
            }
        }).catch(function (error) {
            console.log(error)
            // setLoading(false);
        })
    }

    const handleDelete = (event, id) => {
        deleteRequestNewApi(`${SLABS_DELETE}/${id}`, {}, history).then((response) => {
          // console.log("response -------", response)
          if (response.success === true) {
            getDataReports();
            toast.success(response.message);
          } else {
            toast.error(response.message);
          }
        }).catch(function (error) {
          console.log(error)
        })
      } // eslint-disable-line react-hooks/exhaustive-deps

    // const handleUserModel = (event, data) => {
    //     setIsSelectedUser(data)
    // }

    const handleUserDetail = (event, data) => {
        setIsModalEditDetail(true)
        setIsSelectedUser(data)
        // console.log("data ---------------", data)
    }

    const onChangeFlatCommission = ({ target: { value } }) => {
        let isSelectedUserValue = { ...isSelectedUser };
        isSelectedUserValue.isPerCommission = value;
        setIsSelectedUser(isSelectedUserValue);
    };

    const onChangeBankPercentageStatus = ({ target: { value } }) => {
        let isSelectedUserValue = { ...isSelectedUser };
        isSelectedUserValue.isTdsApplicable = value;
        setIsSelectedUser(isSelectedUserValue);
    };

    const onChangeServiceChargePercentageStatus = ({ target: { value } }) => {
        let isSelectedUserValue = { ...isSelectedUser };
        isSelectedUserValue.isServiceCharge = value;
        setIsSelectedUser(isSelectedUserValue);
    };

    const handleSave = (data) => {
        const payload = {
            min: data.min,
            max: data.max,
            isPerCommission: data.isPerCommission,
            commission: data.commission,
            isTdsApplicable: data.isTdsApplicable,
            isServiceCharge: data.isServiceCharge,
            serviceCharge: data.serviceCharge,
            tds: data.tds
        }

        putRequest(`${SLABS_UPDATE}/${data._id}`, payload, history).then((response) => {
            if (response.success === true) {
                toast.success(response.message);
                setIsSelectedUser({});
                getDataReports();
                handleHideModal();
            } else {
                toast.error(response.message);
            }
        }).catch(function (error) {
            handleHideModal();
        })
    }

    const onChangeValue = (event) => {
        const { name, value } = event.target;
        // console.log("name -----------", name)
        // console.log("value -----------", index)
        let isSelectedUserValue = { ...isSelectedUser };
        isSelectedUserValue[name] = value;
        setIsSelectedUser(isSelectedUserValue);
    }

    const handleHideModal = () => {
        setIsModalEditDetail(false);
    };

    const objectToQueryString = (obj) => {
        var str = [];
        for (var p in obj)
            if (obj.hasOwnProperty(p)) {
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
        return str.join("&");
    }

    const params = { page: page, limit: isLimit };

    const getDataReports = (data) => {
        if (Number(data)) {
            setLimit(data)
            params.limit = data
        }
        // console.log("-------dd-slabId-----", slabId)
        if(props.slabId) {
            params.slabId = props.slabId ? props.slabId : '';
        } else {
            params.slabId = slabId ? slabId : '';
        }
        const queryString = objectToQueryString(params);
        getRequest(`${SLABS_LIST}?${queryString}`, history).then((response) => {
            if (response.success === true) {
                setData(response.data);
                setTotalRecord(response.data.totalDocs);
                setPage(response.data.page);
            } else {
                setData([]);
                setTotalRecord(0);
                setPage(1);
            }
        }).catch(function (error) {
            console.log(error)
            // setLoading(false);
        })
    } // eslint-disable-line react-hooks/exhaustive-deps

    const handlePageChange = page => {
        setPage(page);
    };

    useEffect(() => {
        if(slabId) {
            getDataReports();
        }
    }, [page, slabId]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if(props.slabId) {
            getDataReports();
        }
        if(props.isDrop === false) {
            setDrop(false)
        }
    }, [page, props.slabId, props.isDrop]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        getSlab();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            <div className="container-fluid pt-2 pb-5">
                {isDrop && <div className="row mb-2 align-items-center">
                    <div className="col-sm-12 d-flex">
                        <div className="input-group col-sm-2 ">
                            <label className="form-label">Slabs:</label>
                            <Select options={getSlabList} className={`normal-light w-100`} classNamePrefix="select" placeholder="Select Slab" name='mode' onChange={(value) => setSlabId(value)} />
                        </div>
                    </div>
                </div>}
                <div className="col-md-12">
                    <div className="box-body">
                        <DataTable
                            // title={getMode}
                            columns={columns}
                            data={getData.docs}
                            highlightOnHover
                            pagination
                            paginationServer
                            striped
                            paginationTotalRows={getTotalRecord}
                            onChangeRowsPerPage={getDataReports}
                            onChangePage={handlePageChange}
                        />
                    </div>
                </div>
            </div>
            <ToastContainer position="top-center" autoClose={5000} limit={2} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="colored" />

            {isModalEditDetail && <Modal title={"Edit Data"} width={400} open={isModalEditDetail} onOk={() => handleSave(isSelectedUser)} onCancel={handleHideModal} okText={"Save"} cancelText={"Cancel"}>
                <div className='row'>
                    <div className='col-md-12 col-lg-12 col-sm-12'>
                        <div className="form-group">
                            <label>Min: </label>
                            <input type="number" className={`form-control normal-light`} name="min" maxLength={10} onChange={(e) => onChangeValue(e)} placeholder="Min" onKeyPress={(e) => isNumberKey(e.target, e)} value={isSelectedUser && isSelectedUser.min} />
                        </div>
                        <div className="form-group">
                            <label>Max: </label>
                            <input type="number" className={`form-control normal-light`} name="max" maxLength={10} onChange={(e) => onChangeValue(e)} placeholder="Max" onKeyPress={(e) => isNumberKey(e.target, e)} value={isSelectedUser && isSelectedUser.max} />
                        </div>
                        <div className="form-group d-flex">
                            <label>Is Commission(%): </label>
                            <div className='text-right col-md'>
                                <Radio.Group
                                    options={[
                                        {
                                            label: 'Flat',
                                            value: false,
                                        },
                                        {
                                            label: '%',
                                            value: true,
                                        }]}
                                    onChange={onChangeFlatCommission}
                                    value={isSelectedUser.isPerCommission}
                                    optionType="button"
                                    buttonStyle="solid"
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <label>Commission: </label>
                            <input type="number" className={`form-control normal-light`} name="commission" maxLength={10} onChange={(e) => onChangeValue(e)} placeholder="Commission" onKeyPress={(e) => isNumberKey(e.target, e)} value={isSelectedUser && isSelectedUser.commission} />
                        </div>
                        <div className="form-group d-flex">
                            <label>Is Service Charge: </label>
                            <div className='text-right col-md'>
                                <Radio.Group
                                    options={[
                                        {
                                            label: 'Flat',
                                            value: false,
                                        }, {
                                            label: '%',
                                            value: true,
                                        }]}
                                    onChange={onChangeServiceChargePercentageStatus}
                                    value={isSelectedUser.isServiceCharge}
                                    optionType="button"
                                    buttonStyle="solid"
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <label>Service Charge: </label>
                            <input type="number" className={`form-control normal-light`} name="serviceCharge" maxLength={10} onChange={(e) => onChangeValue(e)} placeholder="Service Charge" onKeyPress={(e) => isNumberKey(e.target, e)} value={isSelectedUser && isSelectedUser.serviceCharge} />
                        </div>
                        <div className="form-group d-flex">
                            <label>Is TDS Applicable: </label>
                            <div className='text-right col-md'>
                                <Radio.Group
                                    options={[
                                        {
                                            label: 'NO',
                                            value: false,
                                        }, {
                                            label: 'YES',
                                            value: true,
                                        }]}
                                    onChange={onChangeBankPercentageStatus}
                                    value={isSelectedUser.isTdsApplicable}
                                    optionType="button"
                                    buttonStyle="solid"
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <label>TDS: </label>
                            <input type="number" className={`form-control normal-light`} name="tds" maxLength={10} onChange={(e) => onChangeValue(e)} placeholder="TDS" onKeyPress={(e) => isNumberKey(e.target, e)} value={isSelectedUser && isSelectedUser.tds} />
                        </div>
                    </div>
                </div>
            </Modal>}
        </div>
    )
}

export default SlabList