// import React from 'react';
// // import Commingsoon from '../../container/commingsoon';
// function InstantTopup(props) {
//   return (
//     <div className="content-header my-0 py-0">
//       <div className="container-fluid">
//         <div className="row mb-0 align-items-center">
//           <div className="col-sm-12 text-center" style={{ padding: 250 }}>
//             {/* <Commingsoon /> */}
//             <h1>Service Under Maintenance</h1>
//           </div>
//         </div>
//       </div>
//     </div>)
// }
// export default InstantTopup;
import { useState, useEffect } from 'react';
// import { DMT_SETTING_URL } from './../../Utils/appConstants';
// import { useHistory } from "react-router-dom";
// import PropagateLoader from "react-spinners/PropagateLoader";
// import AddCustomer from './addCustomer';
// import ViewCustomer from './viewCustomer';
import DMTHistory from './history';
import DmtHome from '../dmtHome/index';
// import MoneyTransferV2 from '../moneyTransferV2/index';
// import MoneyTransferV3 from '../moneyTransferV3/index';
// import InstantPay from '../partnerEKYC/instantPay';
import RefundHistory from './refund';
// import { getRequest } from '../../Utils/AppApi';
// import { toast } from 'react-toastify';
import { Tabs } from 'antd';

// import { reactLocalStorage } from 'reactjs-localstorage';
// import moment from "moment";
const { TabPane } = Tabs;

const MyComponent = () => {
    // const history = useHistory();
    const tabPosition = 'left';
    // const [isLoading, setLoading] = useState(false);
    // const [searchText, setSearchText] = useState(null);
    // const [isCustomerInfo, setCustomerInfo] = useState(null);
    // const [isCustomerNotExist, setCustomerNotExist] = useState(false);
    // const [dmtAllow, setDMTExist] = useState(null);
    // const [dmtAllow1, setDMTExist1] = useState(null);
    // const [dmtAllow2, setDMTExist2] = useState(null);
    const [defaultKey, setDefaultKey] = useState("1");

    useEffect(() => {
        // getBankListData()
        // let date = moment().subtract(19, 'years');
        // console.log("--------", moment(date).format('DD-MM-YYYY'))
        // navigator.geolocation.getCurrentPosition((data) => {
        //     setLatitude(data.coords.latitude)
        //     setLongitude(data.coords.longitude)
        // });
        // checkDMTAccess();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    // const checkDMTAccess = async () => {
    //     await getRequest(DMT_SETTING_URL, history).then((response) => {
    //         if (response.success === true) {
    //             console.log("------------", response.data.filter((item) => item.code === "DMT")[0])
    //             // setDMTExist(response.data.filter((item) => item.code === "DMT"));
    //             setDMTExist1(response.data.filter((item) => item.code === "DMT1")[0]);
    //             setDMTExist2(response.data.filter((item) => item.code === "DMT2")[0]);
    //         } else {
    //             setDMTExist1(null);
    //             setDMTExist2(null);
    //         }
    //     }).catch(function (error) {
    //         console.log(error)
    //         // setLoading(false);
    //     })
    // }

    // useEffect(() => {
    //     if (searchText) {
    //         if (timer) {
    //             clearTimeout(timer)
    //             setTimer(null)
    //         }
    //         setTimer(
    //             setTimeout(() => {
    //                 if (searchText.length === 10) {
    //                     setSearchButton(false)
    //                     getCustomerInfo()
    //                 } else {
    //                     setCustomerNotExist(false);
    //                     // toast.error("Please enter a valid Mobile Number.")
    //                 }
    //             }, 2000)
    //         )
    //     }
    // }, [searchText]) // eslint-disable-line react-hooks/exhaustive-deps

    // const handleSearch = (e) => {
    //     // console.log("searchText----",e.target.value)
    //     if (e.target.value.length === 10) {
    //         setSearchButton(false)
    //     } else {
    //         setCustomerInfo(null)
    //         setSearchButton(true)
    //     }
    //     setSearchText(e.target.value);
    // }

    // const findCustomerInfo = (mobile) => {
    //     setSearchText(null)
    //     setSearchText(mobile)
    // }
    // const getBankListData = async () => {
    // await getRequest(GET_AEPS_BANK_LIST, history).then((response) => {
    //     if (response.success === true) {
    //         setBankList(response.data.map(item => {
    //             return { "value": item.iin, "label": item.name };
    //         }));
    //     } else {
    //         toast.error(response.message);
    //     }
    // }).catch(function (error) {
    //     console.log(error)
    //     // setLoading(false);
    // })
    // }

    // const getCustomerInfo = async () => {
    //     setLoading(true)
    //     if (!searchText) {
    //         toast.error("Please enter a Customer Number");
    //         setLoading(false)
    //         return false;
    //     } else if (searchText.length !== 10) {
    //         toast.error("Please enter a valid Mobile Number");
    //         setLoading(false)
    //         return false;
    //     }
    //     let payload = { "customerMobile": searchText, "isWeb": true }
    //     await postRequest(POST_DMT_CUSTOMER_INFO, payload, history).then((response) => {
    //         if (response.success === true) {
    //             setCustomerInfo(response.data);
    //             setCustomerNotExist(false)
    //             // console.log("----------------", response.data);
    //         } else {
    //             setCustomerNotExist(true);
    //             // console.log("----------------", response.success);
    //         }
    //         setLoading(false)
    //     }).catch(function (error) {
    //         console.log(error)
    //         setLoading(false);
    //     })
    // }

    const onChangeHandler = (value) => {
        setDefaultKey(value);
    }

    // if (isLoading) {
    //     return <div className="content text-center" style={{ margin: "250px 0" }}>
    //         <PropagateLoader color="#5851D2" loading={isLoading} size={25} />
    //     </div>
    // }
    return (
        <div>
            <div className="content-wrapper pt-2">
                <div className="content">
                    <div className="container-fluid pt-2 pb-5">
                        <div className="row">
                            <div className="col-md-12">
                                <div className='card rounded-0 p-3'>
                                    <Tabs defaultActiveKey={defaultKey} tabPosition={tabPosition} onTabScroll={"right"} onChange={(key) => onChangeHandler(key)}>
                                        <TabPane tab={`Home`} key="1">
                                            <div className='h-100'>
                                                <DmtHome />
                                            </div>
                                        </TabPane>
                                        <TabPane tab={`History`} key="4">
                                            <div className='h-100'>
                                                <DMTHistory />
                                            </div>
                                        </TabPane>
                                        <TabPane tab={`Refund`} key="5">
                                            <div className='h-100'>
                                                <RefundHistory />
                                            </div>
                                        </TabPane>
                                    </Tabs>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MyComponent;