import React, { useEffect, useState, useRef } from 'react';
import { getUser, updateUserSession, removeUserSession } from './../Utils/Common';
import { Divider, Modal } from 'antd';
import { STATE_API, KYC_UPDATE, GET_MYPROFILE, UPLOAD_FILE } from './../Utils/appConstants';
import { postRequest, getRequest } from '../Utils/AppApi';
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { API_URL } from '../config';
import Select from 'react-select';
// import Footer from "../Utils/imageCropper"
function KycUpdate(props) {
    const user = getUser();
    const history = useHistory();
    const firstNameInput = useRef(null);
    const lastNameInput = useRef(null);
    const fatherNameInput = useRef(null);
    const motherNameInput = useRef(null);
    const emailInput = useRef(null);
    const mobileInput = useRef(null);
    const whatsappInput = useRef(null);
    const pincodeInput = useRef(null);
    const aadharNumberInput = useRef(null);
    const accountNumberInput = useRef(null);
    const ifscCodeInput = useRef(null);
    const bankNameInput = useRef(null);
    const accountHolderNameInput = useRef(null);
    const panCardPhotoInput = useRef(null);
    const aadharPhotoInput = useRef(null);
    const bankProofInput = useRef(null);
    const selfiePhotoInput = useRef(null);
    const registeredAddressInput = useRef(null);
    const communicationAddressInput = useRef(null);
    const bankAccountTypeSelect = useRef(null);
    const stateSelect = useRef(null);

    const [appInfo, setAppInfo] = useState("");
    const [submitPopup, setSubmitPopup] = useState(false);

    const [getData, setData] = useState([]); // eslint-disable-line react-hooks/exhaustive-deps
    const [getDataField, setDataField] = useState([]); // eslint-disable-line react-hooks/exhaustive-deps
    const [getDataError, setDataError] = useState({}); // eslint-disable-line react-hooks/exhaustive-deps
    const [getState, setStateData] = useState([]); // eslint-disable-line react-hooks/exhaustive-deps
    const [getStateSaveData, setStateSaveData] = useState(""); // eslint-disable-line react-hooks/exhaustive-deps
    const [getBankTypeSaveData, setBankTypeSaveData] = useState(""); // eslint-disable-line react-hooks/exhaustive-deps
    const [userRole, setUserRole] = useState(""); 

    const getBankAccountType = [{ "value": "Saving Account", "label": "Saving Account" }, { "value": "Current Account", "label": "Current Account" }, { "value": "OD Account", "label": "OD Account" }, { "value": "Individual Account", "label": "Individual Account" }]

    // const clearFormField = () => {
    //     firstNameInput.current.value = "";
    //     lastNameInput.current.value = "";
    //     fatherNameInput.current.value = "";
    //     motherNameInput.current.value = "";
    //     emailInput.current.value = "";
    //     mobileInput.current.value = "";
    //     whatsappInput.current.value = "";
    //     pincodeInput.current.value = "";
    //     aadharNumberInput.current.value = "";
    //     accountNumberInput.current.value = "";
    //     ifscCodeInput.current.value = "";
    //     bankNameInput.current.value = "";
    //     accountHolderNameInput.current.value = "";
    //     panCardPhotoInput.current.value = "";
    //     aadharPhotoInput.current.value = "";
    //     bankProofInput.current.value = "";
    //     selfiePhotoInput.current.value = "";
    //     communicationAddressInput.current.value = "";
    //     registeredAddressInput.current.value = "";
    // };

    const getProfile = () => {
        getRequest(GET_MYPROFILE, history).then((response) => {
            if (response.success === true) {
                setData(response.data[0].userdetail)
                setDataField(response.data[0].userdetail)
                user.data.userDetails = response.data[0].userdetail;
                user.data.kycStatus = response.data[0].KYCStatus;
                user.data.isAdminApprovedStatus = response.data[0].isAdminApprovedStatus;

                updateUserSession(user);
                if (response.data[0].KYCStatus === 3 && response.data[0].isAdminApprovedStatus) {
                    // console.log("user.data.KYCStatus -----", response.data[0].KYCStatus)
                    history.push({ pathname: "/dashboard" });
                }
            } else {
                toast.error(response.message);
            }
        }).catch(function (error) {
        })
    }

    const FormValidate = () => {
        let formValid = true;
        let formObject = {};
        setDataError({})
        if (getDataField && getDataField.length === 0) {
            formValid = false;
        }
        let array = [
            "firstName", "motherName", "fatherName", "email", "mobile",
            "whatsapp", "pincode", "aadharNumber", "state", "accountNumber",
            "ifscCode", "bankName", "accountHolderName", "panCardPhotoUpload",
            "aadharPhotoUpload", "bankProofUpload", "bankAccountType",
            "selfiePhotoUpload", "registeredAddress", "communicationAddress",
        ];

        array.forEach(element => {
            if (getDataField[element] === "" || typeof getDataField[element] === "undefined") {
                formObject[element] = true;
                formValid = false;
            }
        });

        setDataError(formObject);
        return formValid;
    }

    const onChangeValue = (event) => {
        const { name, value } = event.target;
        setDataField({ ...getDataField, [name]: value });
        setData({ ...getDataField, [name]: value });
    }

    const onChangeStateValueSelect = (event) => {
        const { value } = event;
        // console.log("value -----", event);
        setDataField({ ...getDataField, "state": value });
        setData({ ...getDataField, "state": value });
    }

    const onChangeBankAccountTypeValueSelect = (event) => {
        const { value } = event;
        // console.log("value -----", event);
        setDataField({ ...getDataField, "bankAccountType": value });
        setData({ ...getDataField, "bankAccountType": value });
    }

    const getStateData = () => {
        getRequest(STATE_API, history).then((response) => {
            if (response.success === true) {
                setStateData(response.data.map((item) => {
                    return { "value": item._id, "label": item.name };
                }));
            } else {
                toast.error(response.message);
            }
        }).catch(function (error) {
            // console.log(error)
            // setLoading(false);
        })
    }

    const handleSubmit = () => {
        if (FormValidate() === true) {
            const payload = {
                firstName: getDataField.firstName,
                lastName: getDataField.lastName,
                fatherName: getDataField.fatherName,
                motherName: getDataField.motherName,
                email: getDataField.email,
                mobile: getDataField.mobile,
                whatsapp: getDataField.whatsapp,
                pincode: getDataField.pincode,
                state: getDataField.state,
                accountNumber: getDataField.accountNumber,
                ifscCode: getDataField.ifscCode,
                bankName: getDataField.bankName,
                accountHolderName: getDataField.accountHolderName,
                bankAccountType: getDataField.bankAccountType,
                pancardNumber: getDataField.pancardNumber,
                aadharNumber: getDataField.aadharNumber,
                bankProof: getDataField.bankProofUpload,
                selfiePhoto: getDataField.selfiePhotoUpload,
                aadharPhoto: getDataField.aadharPhotoUpload,
                panCardPhoto: getDataField.panCardPhotoUpload,
                communicationAddress: getDataField.communicationAddress,
                registeredAddress: getDataField.registeredAddress,
            }
            postRequest(KYC_UPDATE, payload, history).then((response) => {
                if (response.success === true) {
                    setDataField([])
                    setDataError({})
                    setSubmitPopup(true);
                    // history.push({ pathname: "/dashboard" });
                    // clearFormField()
                    toast.success(response.message);
                } else {
                    toast.error(response.message);
                }
            }).catch(function (error) {
                // console.log(error)
                // setLoading(false);
            })
        }
    } // eslint-disable-line react-hooks/exhaustive-deps

    const getAppInfo = () => {
        getRequest(`${API_URL}/appinfo`).then(async (data) => {
            //   setLoading(false);
            if (data) {
                // console.log("========================", data)
                await setAppInfo(data)
            } else {
                toast.error(data.data.message)
            }
        }).catch(function (error) {
            //   setLoading(false);
            // console.log(error);
        })
    }

    // const redirectForgetPage = () => {
    //     history.push({
    //         pathname: "/forget-password"
    //     });
    // }

    // const redirectForgetUserIDPage = () => {
    //     history.push({
    //         pathname: "/forget-userid"
    //     });
    // }

    const handleFileChange = (event, name) => {
        event.preventDefault();
        const data = new FormData();
        data.append('avatar', event.target.files[0], event.target.files[0].name);
        data.append('name', name);
        postRequest(UPLOAD_FILE, data, history).then((response) => {
            if (response.success === true) {
                setDataField({ ...getDataField, [name]: response.data.fullpath });
                setData({ ...getDataField, [name]: response.data.fullpath });
                toast.success(response.message);
            } else {
                toast.error(response.message);
            }
        }).catch(function (error) {
            // console.log(error)
        })
    } // eslint-disable-line react-hooks/exhaustive-deps

    const handleCancel = () => {
        setSubmitPopup(false)
    }

    const handleLogout = () => {
        removeUserSession();
        window.location.href = '/login';
    }

    useEffect(() => {
        getStateData()
        getProfile()
        getAppInfo()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setUserRole(user.data.role.code)
        if (user.data.role.code !== "A" && user.data.role.code !== "SA") {
            if (user.data.isAdminApprovedStatus) {
                history.push("/dashboard")
            } else if (!user.data.isAdminApprovedStatus && user.data.kycStatus === 1) {
                setSubmitPopup(true)
            }
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        let saveBankType = getBankAccountType.filter((item) => item.value === getData.bankAccountType);
        setBankTypeSaveData(saveBankType);

        let saveState = getState.filter((item) => item.value === getData.state);
        setStateSaveData(saveState);
        // console.log("getData.bankAccountType------",getData.bankAccountType);
        // console.log("------", getBankAccountType.filter((item) => item.value == 'Saving Account'))
    }, [getData]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className='wrapper'>
            <div className='topbar'>
                <nav className="main-header navbar navbar-expand navbar-light flex-wrap">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <div className="brand-link">
                                <img src="../../logo.png" alt="Logo" className="brand-image" />
                            </div>
                        </li>
                    </ul>

                    <ul className="navbar-nav ml-auto">
                        <li className="nav-item">
                            <a href={`https://wa.me/${appInfo.helpNumber}`} target="_blank" rel="noreferrer" className='nav-link'>
                                {/* <i className='fas fa-phone-alt mr-1 call-icon'></i> */}
                                <i class="fa-brands fa-whatsapp fa-fade fa-lg mr-1 call-icon"></i>
                                <span className='helpline-text'>{appInfo.helpNumber}</span>
                            </a>
                        </li>
                        <li className="nav-item pl-0 pl-md-4">
                            <a href="mailto:{appInfo.email}" className='nav-link'><i className='fas fa-envelope mr-1 call-icon'></i> <span className='helpline-text'>{appInfo.email}</span></a>
                        </li>
                    </ul>
                </nav>
            </div>
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-3 mt-3">
                            <div className="col-sm-12 text-center">
                                <h1 className='page-title text-blue'>KYC <span className='text-red'>{userRole==="D" ? 'Update' : 'Pending'}</span></h1>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="content">
                    <div className="container">
                        <div className={userRole==="D" ? 'row d-none' : 'row'}>
                            <div className="col-sm-12 pb-3">
                                <div className="float-right pointer" onClick={() => handleLogout()}>Logout <i class="fa fa-sign-out" aria-hidden="true"></i></div>
                            </div>
                        </div>
                        <div className={userRole==="D" ? 'row d-none' : 'row'}>
                            <div className="col-md-12">
                                <div className="card card-primary">
                                    <div className="card-body">
                                        <p className='text-danger text-center'><b>Dear SR PAY Private Limited Partner, आपका केवाईसी अभी तक पूरा नहीं हुआ है। कृपया प्ले स्टोर से SR PAY Private Limited Partner ऐप डाउनलोड करें और अपना KYC पूरा करें।</b></p>
                                        <Divider />
                                        <p className='text-danger text-center'><b>Dear SR PAY Private Limited Partner, Your KYC is incomplete. Please Download SR PAY Private Limited Partner App from Play store and process KYC.</b></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={userRole==="P" ? 'row d-none' : 'row'}>
                            <div className="col-md-12">
                                <div className="card card-primary">
                                    <div className="card-body">
                                        <div className="row">
                                            {user.data && user.data.userDetails && user.data.userDetails.rejectReason && <div className="col-sm-12 pb-3">
                                                <p className='text-center text-danger'><b>Reject Reason</b> - {user.data.userDetails.rejectReason}
                                                    <div className="float-right pointer" onClick={() => handleLogout()}><i class="fa fa-sign-out" aria-hidden="true"></i></div></p>
                                            </div>}
                                            <div className="col-sm-12 pb-3">
                                                <h3 className="card-title">Personal <span className='text-red'>Details</span></h3>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-12 pb-3">
                                                <div className="float-right pointer" onClick={() => handleLogout()}><i class="fa fa-sign-out" aria-hidden="true"></i></div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <input type="text" className={`form-control normal-light pr-110 ${getDataError && getDataError.firstName ? "is-invalid" : ""}`} name="firstName" ref={firstNameInput} maxLength={20} onChange={(e) => onChangeValue(e)} placeholder="First Name*" value={getData && getData.firstName} readOnly={true} />
                                                    {getDataError && getDataError.firstName && <span className="error text-danger">The field is required</span>}
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <input type="text" className={`form-control normal-light pr-110 ${getDataError && getDataError.lastName ? "is-invalid" : ""}`} name="lastName" ref={lastNameInput} maxLength={20} onChange={(e) => onChangeValue(e)} placeholder="Last Name*" value={getData && getData.lastName} readOnly={true} />
                                                    {getDataError && getDataError.lastName && <span className="error text-danger">The field is required</span>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <input type="text" className={`form-control normal-light pr-110 ${getDataError && getDataError.fatherName ? "is-invalid" : ""}`} name="fatherName" ref={fatherNameInput} maxLength={20} onChange={(e) => onChangeValue(e)} placeholder="Father Name*" value={getData && getData.fatherName} />
                                                    {getDataError && getDataError.fatherName && <span className="error text-danger">The field is required</span>}
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <input type="text" className={`form-control normal-light pr-110 ${getDataError && getDataError.motherName ? "is-invalid" : ""}`} name="motherName" ref={motherNameInput} maxLength={20} onChange={(e) => onChangeValue(e)} placeholder="Mother Name*" value={getData && getData.motherName} />
                                                    {getDataError && getDataError.motherName && <span className="error text-danger">The field is required</span>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <input type="text" className={`form-control normal-light pr-110 ${getDataError && getDataError.email ? "is-invalid" : ""}`} name="email" ref={emailInput} maxLength={40} onChange={(e) => onChangeValue(e)} placeholder="Email Address*" value={getData && getData.email} />
                                                    {getDataError && getDataError.email && <span className="error text-danger">The field is required</span>}
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <input type="text" className={`form-control normal-light pr-110 ${getDataError && getDataError.mobile ? "is-invalid" : ""}`} name="mobile" ref={mobileInput} maxLength={10} onChange={(e) => onChangeValue(e)} placeholder="Mobile No.*" value={getData && getData.mobile} readOnly={true} disabled />
                                                    {getDataError && getDataError.mobile && <span className="error text-danger">The field is required</span>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <input type="text" className={`form-control normal-light pr-110 ${getDataError && getDataError.whatsapp ? "is-invalid" : ""}`} name="whatsapp" ref={whatsappInput} maxLength={10} onChange={(e) => onChangeValue(e)} placeholder="Whatsapp No." value={getData && getData.whatsapp} />
                                                    {getDataError && getDataError.whatsapp && <span className="error text-danger">The field is required</span>}
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <input type="text" className={`form-control normal-light pr-110 ${getDataError && getDataError.pincode ? "is-invalid" : ""}`} name="pincode" ref={pincodeInput} maxLength={7} onChange={(e) => onChangeValue(e)} placeholder="Pin Code*" value={getData && getData.pincode} />
                                                    {getDataError && getDataError.pincode && <span className="error text-danger">The field is required</span>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <input type="text" className={`form-control normal-light pr-110 ${getDataError && getDataError.aadharNumber ? "is-invalid" : ""}`} name="aadharNumber" ref={aadharNumberInput} maxLength={12} onChange={(e) => onChangeValue(e)} placeholder="Aadhar Number*" value={getData && getData.aadharNumber} readOnly={getData && getData.aadharNumber && getData.aadharNumber.length >= 12 ? true : false} />
                                                    {getDataError && getDataError.aadharNumber && <span className="error text-danger">The field is required</span>}
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <Select options={getState} className={`normal-light w-100 ${getDataError && getDataError.state ? "is-invalid" : ""}`} classNamePrefix="select" ref={stateSelect} name='operator' onChange={(e) => onChangeStateValueSelect(e)} placeholder="State*" value={getStateSaveData} />
                                                    {getDataError && getDataError.state && <span className="error text-danger">The field is required</span>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card-body pt-0">
                                        <div class="row"><div class="col-sm-12 pb-3"><h3 class="card-title">Bank <span class="text-red">Details</span></h3></div></div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <Select options={getBankAccountType} className={`normal-light w-100 ${getDataError && getDataError.bankAccountType ? "is-invalid" : ""}`} classNamePrefix="select" ref={bankAccountTypeSelect} name='bankAccountType' onChange={(e) => onChangeBankAccountTypeValueSelect(e)} placeholder="Bank Account Type*" value={getBankTypeSaveData} />
                                                    {getDataError && getDataError.bankAccountType && <span className="error text-danger">The field is required</span>}
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <input type="text" className={`form-control normal-light pr-110 ${getDataError && getDataError.accountNumber ? "is-invalid" : ""}`} name="accountNumber" ref={accountNumberInput} maxLength={20} onChange={(e) => onChangeValue(e)} placeholder="Account Number*" value={getData && getData.accountNumber} />
                                                    {getDataError && getDataError.accountNumber && <span className="error text-danger">The field is required</span>}
                                                </div>
                                            </div>

                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <input type="text" className={`form-control normal-light pr-110 ${getDataError && getDataError.ifscCode ? "is-invalid" : ""}`} name="ifscCode" ref={ifscCodeInput} maxLength={15} onChange={(e) => onChangeValue(e)} placeholder="IFSC Code*" value={getData && getData.ifscCode} />
                                                    {getDataError && getDataError.ifscCode && <span className="error text-danger">The field is required</span>}
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <input type="text" className={`form-control normal-light pr-110 ${getDataError && getDataError.bankName ? "is-invalid" : ""}`} name="bankName" ref={bankNameInput} maxLength={40} onChange={(e) => onChangeValue(e)} placeholder="Bank Name*" value={getData && getData.bankName} />
                                                    {getDataError && getDataError.bankName && <span className="error text-danger">The field is required</span>}
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <input type="text" className={`form-control normal-light pr-110 ${getDataError && getDataError.accountHolderName ? "is-invalid" : ""}`} name="accountHolderName" ref={accountHolderNameInput} maxLength={40} onChange={(e) => onChangeValue(e)} placeholder="Account Holder Name*" value={getData && getData.accountHolderName} />
                                                    {getDataError && getDataError.accountHolderName && <span className="error text-danger">The field is required</span>}
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="card-body pt-0">
                                        <div class="row"><div class="col-sm-12 pb-3"><h3 class="card-title">Document <span class="text-red">Upload</span></h3></div></div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputFile">Pan Number<span className='text-blue'>*</span> (Self Attested) - {user.data && user.data.userDetails && user.data.userDetails.pancardNumber}</label>
                                                    <div className="input-group">
                                                        <div className="custom-file">
                                                            <input type="file" className={`custom-file-input ${getDataError && getDataError.panCardPhotoUpload ? "is-invalid" : ""}`} ref={panCardPhotoInput} name="panCardPhotoUpload" onChange={(event) => handleFileChange(event, "panCardPhotoUpload")} accept="image/png, image/jpeg, image/jpg" />
                                                            <label className="custom-file-label">{getDataField.panCardPhotoUpload ? getDataField.panCardPhotoUpload.substring(getDataField.panCardPhotoUpload.lastIndexOf('/') + 1) : "Choose file"}</label>
                                                        </div>
                                                    </div>
                                                    {getDataError && getDataError.panCardPhotoUpload && <span className="error text-danger">The field is required</span>}
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputFile">Aadhar Number* (Latest Masked Aadhar Self Attested Copy) - {getData && getData.aadharNumber}</label>
                                                    <div className="input-group">
                                                        <div className="custom-file">
                                                            <input type="file" className={`custom-file-input ${getDataError && getDataError.aadharPhotoUpload ? "is-invalid" : ""}`} ref={aadharPhotoInput} name="aadharPhotoUpload" onChange={(event) => handleFileChange(event, "aadharPhotoUpload")} accept="image/png, image/jpeg, image/jpg" />
                                                            <label className="custom-file-label">{getDataField.aadharPhotoUpload ? getDataField.aadharPhotoUpload.substring(getDataField.aadharPhotoUpload.lastIndexOf('/') + 1) : "Choose file"}</label>
                                                        </div>
                                                    </div>
                                                    {getDataError && getDataError.aadharPhotoUpload && <span className="error text-danger">The field is required</span>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputFile">Bank Proof* - {getData && getData.accountNumber}</label>
                                                    <div className="input-group">
                                                        <div className="custom-file">
                                                            <input type="file" className={`custom-file-input ${getDataError && getDataError.bankProofUpload ? "is-invalid" : ""}`} ref={bankProofInput} name="bankProofUpload" onChange={(event) => handleFileChange(event, "bankProofUpload")} accept="image/png, image/jpeg, image/jpg" />
                                                            <label className="custom-file-label">{getDataField.bankProofUpload ? getDataField.bankProofUpload.substring(getDataField.bankProofUpload.lastIndexOf('/') + 1) : "Choose file"}</label>
                                                        </div>
                                                    </div>
                                                    {getDataError && getDataError.bankProofUpload && <span className="error text-danger">The field is required</span>}
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputFile">Selfie Photo*</label>
                                                    <div className="input-group">
                                                        <div className="custom-file">
                                                            <input type="file" className={`custom-file-input ${getDataError && getDataError.selfiePhotoUpload ? "is-invalid" : ""}`} ref={selfiePhotoInput} name="selfiePhotoUpload" onChange={(event) => handleFileChange(event, "selfiePhotoUpload")} accept="image/png, image/jpeg, image/jpg" />
                                                            <label className="custom-file-label">{getDataField.selfiePhotoUpload ? getDataField.selfiePhotoUpload.substring(getDataField.selfiePhotoUpload.lastIndexOf('/') + 1) : "Choose file"}</label>
                                                        </div>
                                                    </div>
                                                    {getDataError && getDataError.selfiePhotoUpload && <span className="error text-danger">The field is required</span>}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <textarea className={`form-control normal-light pr-110 ${getDataError && getDataError.registeredAddress ? "is-invalid" : ""}`} name="registeredAddress" ref={registeredAddressInput} onChange={(e) => onChangeValue(e)} rows={"4"} placeholder="Registered Address*" value={getData && getData.registeredAddress} readOnly={getData && getData.registeredAddress ? true : false}></textarea>
                                            {getDataError && getDataError.registeredAddress && <span className="error text-danger">The field is required</span>}
                                        </div>
                                        <div className="form-group">
                                            <textarea className={`form-control normal-light pr-110 ${getDataError && getDataError.communicationAddress ? "is-invalid" : ""}`} name="communicationAddress" ref={communicationAddressInput} onChange={(e) => onChangeValue(e)} rows={"4"} placeholder="Communication Address*" value={getData && getData.communicationAddress}></textarea>
                                            {getDataError && getDataError.communicationAddress && <span className="error text-danger">The field is required</span>}
                                        </div>
                                    </div>
                                    {/* /.card-body */}
                                    <div className="card-footer">
                                        {/* <button type="clear" onClick={() => clearFormField()} className="btn btn-cancel mr-1">Cancel</button> */}
                                        <button type="submit" onClick={() => handleSubmit()} className="btn btn-primary">Submit</button>
                                    </div>
                                </div>
                                {/* /.card */}
                            </div>
                            {/*/.col (left) */}
                        </div>
                        {/* /.row */}
                    </div>{/* /.container-fluid */}
                </section>
                {/* /.content */}
            </div>
            {submitPopup && (<Modal width={1000} open={submitPopup} onCancel={handleCancel} footer={false}>
                <div className="font-weight-bold text-center text-danger">
                    <p className="text-justify kyc-submit-text">आपके KYC दस्तावेज़ सफलतापूर्वक जमा हो गए।। KYC Docs स्वीकृति के बाद कंपनी आपके व्हाट्सएप नंबर पर पंजीकृत नंबर 8053767570 के माध्यम से एक वीडियो सत्यापन लिंक साझा करेगी। वीडियो सत्यापन के बाद आपका खाता सक्रिय हो जाएगा।</p>
                    <p><br /></p>
                    <p className="text-justify kyc-submit-text"><strong className='text-red'>ध्यान दें:</strong> - SR PAY Private Limited ने व्हाट्सएप संचार के लिए केवल 8053767570 पंजीकृत किया है। कृपया किसी भी स्थिति में किसी अन्य नंबर पर प्रतिक्रिया न दें। इससे धोखाधड़ी हो सकती है. यह नंबर केवल व्हाट्सएप चैट के लिए है कृपया इस पर कॉल न करें</p>
                    <p><hr /></p>
                    <p className="text-justify kyc-submit-text">Your KYC submission is successful. Company will send you a Video verification link after your KYC acceptance from Registered Number 8053767570 to your WhatsApp Number. Your account will get activated after successful completion of your KYC Video Verification.</p>
                    <p><br /></p>
                    <p className="text-justify kyc-submit-text"><strong className='text-red'>Note:</strong> - 8053767570 is the one and only SR PAY Private Limited registered Whatsapp number for any communication. Please do not respond to any other number to avoid being a target of any fraud. This Number is only for WhatApp Chat no voice calls will be entertained on this number so please avoid calling on this number as the same is not going to be answered.</p>

                </div>
            </Modal>)}
            <ToastContainer position="top-center" autoClose={5000} limit={2} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="colored" />
            {/* <Footer /> */}
        </div>
    );
}

// const useFormInput = initialValue => {
//     const [value, setValue] = useState(initialValue);

//     const handleChange = e => {
//         setValue(e.target.value);
//     }
//     return {
//         value,
//         onChange: handleChange
//     }
// }

export default KycUpdate;