import React, { useEffect, useState, useRef } from 'react';
// import { APP_KEY, API_URL } from './../config';
import { CSVLink } from 'react-csv';
import DataTable from 'react-data-table-component';
// import { Tabs, Tab } from 'react-bootstrap-tabs';
import { GET_ALL_MY_SEND_REQUEST_MONEY } from './../../Utils/appConstants';
import { getRequest } from './../../Utils/AppApi';
// import AppHeader from './../Header/Header';
import { useHistory } from "react-router-dom";
// import { ToastContainer, toast } from 'react-toastify';
import { INRFormat } from './../../Utils/Common';
// import Moment from 'react-moment';
import moment from 'moment';

import { DatePicker, Tooltip } from 'antd';
import { getUTCToLocalDateTimeFormat, queryDate, toDayDate } from '../../Utils/function';

function RequestMoneyReport(props) {

    const history = useHistory();
    const csvLink = useRef()
    const limit = 10;

    const dateFormat = 'DD-MM-YYYY';

    const [isLimit, setLimit] = useState(limit);
    const [endDate, setEndDate] = useState(toDayDate());
    const [startDate, setStartDate] = useState(toDayDate());
    const [getData, setData] = useState([]);
    const [getCsvData, setCsvData] = useState([]);
    const [getTotalRecord, setTotalRecord] = useState(0);
    const [page, setPage] = useState(1);
    const [searchTransId, setSearchTransId] = useState(null);

    const columns = [{
        name: 'Date',
        cell: row => getUTCToLocalDateTimeFormat(row.updatedAt),
        width: "120px"
    },{
        name: 'TXT ID',
        selector: row => <Tooltip title={row.transactionId}>{row.transactionId}</Tooltip>,
        width: "180px"
    }, {
        name: 'FTR Ref No.',
        selector: row => <Tooltip title={row.FTRReferenceNumber}>{row.FTRReferenceNumber}</Tooltip>,
        width: "180px"
    }, {
        name: 'Name',
        selector: row => row.parentName,
    }, {
        name: 'Contact Number',
        selector: row => row.contactNumber,
    }, {
        name: 'OS Balance',
        selector: row => INRFormat(row.currentOutstanding),
    }, {
        name: 'Wallet Balance',
        selector: row => INRFormat(row.currentWalletBalance),
    }, {
        name: 'Amount',
        selector: row => INRFormat(row.amountRequested),
    }, {
        name: 'Status',
        cell: row => <>{(row.status===1) ? <div className="text-success">Approved</div> : row.status===2 ? <div className="text-danger">Rejected</div> : <div className="text-warning">Pending</div>}</>,
    },{
        name: 'Remark',
        selector: row => row.remark,
    },{
        name: 'Dis. Remark',
        selector: row => row.parentRemark,
    }]

    const objectToQueryString = (obj) => {
        var str = [];
        for (var p in obj)
            if (obj.hasOwnProperty(p)) {
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
        return str.join("&");
    }

    // const params = { page: page, limit: limit };
    const params = { page: page, limit: isLimit };

    const getDataReports = (data) => {

        if(data) {
            setLimit(data)
            params.limit = data
        }

        if (startDate && endDate) {
            params.startDate = startDate ? queryDate(startDate) : "";
            params.endDate = endDate ? queryDate(endDate) : "";
        }

        params.searchTransId = searchTransId ? searchTransId : '';

        const queryString = objectToQueryString(params);
        getRequest(`${GET_ALL_MY_SEND_REQUEST_MONEY}?${queryString}`, history).then((response) => {
            if (response.success === true) {
                setData(response.data);
                setTotalRecord(response.data.totalDocs);
                setPage(response.data.page);
            } else {
                setData([]);
                setTotalRecord(0);
                setPage(1);
            }
        }).catch(function (error) {
            console.log(error)
            // setLoading(false);
        })
    } // eslint-disable-line react-hooks/exhaustive-deps

    const getCSVDataList = (data) => {
        let params = {}
        params.exportsCsv = true;

        if(data) {
            setLimit(data)
            params.limit = data
        }

        if (startDate && endDate) {
            params.startDate = startDate ? queryDate(startDate) : "";
            params.endDate = endDate ? queryDate(endDate) : "";
        }

        params.searchTransId = searchTransId ? searchTransId : '';

        const queryString = objectToQueryString(params);
        getRequest(`${GET_ALL_MY_SEND_REQUEST_MONEY}?${queryString}`, history).then((response) => {
            if (response.success === true) {
                let csvdataRecord = [];
                let csvdata = response.data;
                csvdata.map((e) => {
                    return csvdataRecord.push({ "Date": moment(e.createdAt).format("DD/MM/YYYY"), "TXT Id": e.transactionId, "FTR Reference Number": e.FTRReferenceNumber, "Name": e.parentName, "Contact Number": e.contactNumber, "Out Standing": e.currentOutstanding, "Wallet Balance": e.currentWalletBalance, "Amount": e.amountRequested, "Remark": e.remark, "TXT Status":  (e.status===1) ? "success" : (e.status===2) ? "rejected" : "pending", "Dis. Remark": e.parentRemark });
                });
                setCsvData(csvdataRecord);
                setTimeout(() => {
                    csvLink.current.link.click();
                }, 1000);
            } else {
                setCsvData([]);
            }
        }).catch(function (error) {
            console.log(error)
            // setLoading(false);
        })
    }

    const handlePageChange = page => {
        setPage(page);
    };

    const onChangeStartDate = (date, dateString) => {
        // console.log(date, dateString);
        setStartDate(date)
    };

    const onChangeEndDate = (date, dateString) => {
        // console.log(date, dateString);
        setEndDate(date)
    };

    const handleSearch = () => {
        getDataReports();
    }

    const handleClear = () => {
        setStartDate('');
        setEndDate('');
    }

    useEffect(() => {
        getDataReports();
    }, [page]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            {/* <AppHeader /> */}
            <div className="content-wrapper pt-2">
                <div className="content-header my-0 py-0">
                    <div className="container-fluid">
                        <div className="row mb-0 align-items-center">
                            <div className="col-sm-6">
                                <h1 className='page-title text-blue font-merriweather'>Request Money <span className='text-red'>History</span></h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content">
                    <div className="container-fluid pt-2 pb-5">
                        <div className="">
                            <div className='card col-md-12'>
                                <div className='card-body'>
                                    <div className="row">
                                        <div className='col-md-8'>
                                            <div className='date-search mb-0'>
                                                <div className='row'>
                                                    <div className='col-sm-4 col-lg-3'>
                                                        <div className="form-group">
                                                            <label className='date-search-label mb-0 text-blue'>From</label>
                                                            <DatePicker className="form-control form-control-border" value={startDate} format={dateFormat} onChange={onChangeStartDate} />
                                                        </div>
                                                    </div>
                                                    <div className='col-sm-4 col-lg-3'>
                                                        <div className="form-group">
                                                            <label className='date-search-label mb-0 text-red'>To</label>
                                                            <DatePicker className="form-control form-control-border" value={endDate} format={dateFormat} onChange={onChangeEndDate} />
                                                        </div>
                                                    </div>
                                                    <div className='col-sm-4 col-lg-3'>
                                                    <div className="form-group">
                                                        <label className='date-search-label mb-0 text-red'></label>
                                                        <input type="text" className="form-control form-control-border" placeholder="FTR Ref No." onChange={(e) => setSearchTransId(e.target.value)} value={searchTransId} />
                                                    </div>
                                                    </div>
                                                    <div className='col-sm-4 col-lg-2'>
                                                        <div><label className='date-search-label mb-0 text-red'>&nbsp;</label></div>
                                                        <button type="submit" className="btn btn-primary rounded-pill pl-4 pr-4" onClick={() => handleSearch()}>Search</button>
                                                    </div>
                                                    <div className='col-sm-4 col-lg-1'>
                                                        <div><label className='date-search-label mb-0 text-red'>&nbsp;</label></div>
                                                        <button type="button" className="btn btn-default rounded-pill ml-3 pl-4 pr-4 pull-right" onClick={() => handleClear()}>Reset</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className='float-sm-right'>
                                                <button type="button" className="btn btn-primary mr-3" onClick={() => getCSVDataList()} ><i className="fa fa-file-excel"></i> Export</button>
                                                <CSVLink
                                                    data={getCsvData}
                                                    filename='request-money-history'
                                                    className='hidden'
                                                    ref={csvLink}
                                                    target='_blank'
                                                />
                                                <button type="button" className="btn btn-danger"><i className="fa fa-file-pdf"></i> Export to PDF</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="container-fluid pt-2 pb-5">
                                        <DataTable
                                            title={""}
                                            columns={columns}
                                            data={getData.docs}
                                            highlightOnHover
                                            pagination
                                            paginationServer
                                            paginationTotalRows={getTotalRecord}
                                            onChangeRowsPerPage={getDataReports}
                                            onChangePage={handlePageChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <AppFooter /> */}
        </div>
    )
}


export default RequestMoneyReport