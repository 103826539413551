import React, { useEffect, useState, useRef } from 'react';
import { Button } from 'antd';
import { POST_OPERATIVE_WALLET_RECHARGE } from './../../../Utils/appConstants';
import { postRequest } from './../../../Utils/AppApi';
import { isNumber } from "./../../../Utils/Common";
// import AppHeader from './../../Header/Header';
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
// import AppFooter from './../../Footer/Footer';
// import Select from 'react-select';

// const userData = getUser();

function WalletRecharge(props) {
    const amountRef = useRef(null);
    const messageRef = useRef(null);
    const tokenRef = useRef(null);

    const history = useHistory();
    const [isData, setData] = useState([]);

    const clearFormField = () => {
        amountRef.current.value = "";
        messageRef.current.value = "";
        tokenRef.current.value = "";
    };

    const FormValidate = () => {
        let formValid = true;
        let formObject = {};
        
        if (isData && isData.length === 0) {
            formValid = false;
        }
        if (isData.amount === "" || typeof isData.amount === "undefined") {
            formObject.amount = true;
            formValid = false;
            toast.error("Amount is required");
        }

        if (isData.tokenNumber === "" || typeof isData.tokenNumber === "undefined") {
            formObject.tokenNumber = true;
            formValid = false;
            toast.error("Token is required");
        }
    
        return formValid;
    } // eslint-disable-line react-hooks/exhaustive-deps

    const handleSubmit = async () => {
        if(FormValidate(true)) {
            const payload = {
                amount: isData.amount,
                message: isData.message,
                tokenNumber: isData.tokenNumber
            };
            postRequest(`${POST_OPERATIVE_WALLET_RECHARGE}`, payload, history).then((response) => {
                if (response.success) {
                    clearFormField();
                    toast.success(response.message);
                } else {
                    toast.error(response.message);
                }
            }).catch(function (error) {
                console.log(error)
                // setLoading(false);
            })
}
    } // eslint-disable-line react-hooks/exhaustive-deps

    const onChangeValue = (event) => {
        const { name, value } = event.target;
        setData({ ...isData, [name]: value });
    } // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            {/* <AppHeader /> */}
            <div className="content-wrapper pt-2">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-0 align-items-center">
                            <div className="col-sm-6">
                                <h1 className='page-title text-blue font-merriweather'>Operative <span className='text-red'> Balance Upgrade</span></h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content">
                    <div className="container-fluid pt-2 pb-5">
                        <div className="row">
                            <div className="col-md-6">
                                <div className='card rounded-0'>
                                    <div className='card-body'>
                                        <div className="form-group">
                                            <label>Amount</label>
                                            <div className="input-group">
                                                <div className="custom-file">
                                                    <input type="text" className="form-control" name="amount" placeholder="Amount" onChange={(e) => onChangeValue(e)} autoComplete="off" onKeyDown={isNumber} maxLength={10} ref={amountRef} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label>Message</label>
                                            <div className="input-group">
                                                <div className="custom-file">
                                                    <input type="text" className="form-control" name="message" placeholder="Message" onChange={(e) => onChangeValue(e)} autoComplete="off" maxLength={10} ref={messageRef} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label>Token</label>
                                            <div className="input-group">
                                                <div className="custom-file">
                                                    <input type="text" className="form-control" name="tokenNumber" placeholder="Token" onChange={(e) => onChangeValue(e)} autoComplete="off" onKeyDown={isNumber} maxLength={10} ref={tokenRef} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <Button type="default" onClick={() => handleSubmit()} className="btn btn-success btn-sm float-end">Submit</Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer position="top-center" autoClose={5000} limit={2} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="colored"/>
            </div>
            {/* <AppFooter /> */}
        </div>
    )
}

export default WalletRecharge