import React from 'react';

import RequestMoneyReceived from './../distributor/requestMoneyReceived';
import PaymentDepositReceived from './../distributor/paymentDepositReceived';
import CodReceivedRequest from './../codRequest/receivedRequest';

function HomeDistributor(props) {
    return (
        <div>
            <RequestMoneyReceived />
            <PaymentDepositReceived />
            <CodReceivedRequest />
        </div>
    )
}

export default HomeDistributor