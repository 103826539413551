import React, { useEffect, useState, useRef } from 'react';
import { Modal, Space, Spin} from 'antd';
// import { DatePicker } from 'antd';
import { GAS_OPERATORS, GAS_VIEW_BILL, GAS_BILL_PAYMENT } from '../../Utils/appConstants';
import { getRequest, postRequest } from '../../Utils/AppApi';
import { ToastContainer, toast } from 'react-toastify';
import 'antd/dist/antd.css';
import { useHistory } from "react-router-dom";

import Select from 'react-select';
// import moment from 'moment';

function GasBillPayment(props) {

    // const dateFormat = 'DD/MM/YYYY';
    // const queryDateFormat = 'YYYY-MM-DD';
    const history = useHistory();
    const operatorSelect = useRef(null);
    const accountNumberInput = useRef(null);
    // const customerMobileInput = useRef(null);

    const [isModalViewBill, setIsModalViewBill] = useState(false);
    const [getOperatorData, setOperatorData] = useState([]);
    const [getDataField, setDataField] = useState([]);
    const [getDataError, setDataError] = useState({});
    const [getCustomerInfo, setCustomerInfo] = useState({});
    const [paymentId, setPaymentId] = useState(null);
    const [loading, setLoading] = useState(false);

    const clearFormField = () => {
        operatorSelect.current.value = "";
        accountNumberInput.current.value = "";
        // customerMobileInput.current.value = "";
        setPaymentId(null)
    };

    const getOperators = () => {
        getRequest(GAS_OPERATORS, history).then((response) => {
            if (response.success === true) {
                setOperatorData(response.data.map((item) => {
                    return { "value" : item.code, "label" : item.name};
                }));
            } else {
                toast.error(response.message);
            }
        }).catch(function (error) {
            // console.log(error)
            // setLoading(false);
        })
    }

    const handleHideModal = () => {
        setIsModalViewBill(false);
        clearFormField()
    };

    const onChangeValueSelect = (event) => {
        const { value } = event;
        setDataField({ ...getDataField, "operator": value });
    }

    const onChangeValue = (event) => {
        const { name, value } = event.target;
        setDataField({ ...getDataField, [name]: value });
    }

    // const onChangeDatePicker = (date) => {
    //     // console.log(date, dateString);
    //     let dateValue = moment(date).format(queryDateFormat);
    //     setDataField({ ...getDataField, dueDate : dateValue });
    // };

    const FormValidate = () => {
        let formValid = true;
        let formObject = {};
        setDataError({})
        if (getDataField && getDataField.length === 0) {
            formValid = false;
        }
        if (getDataField.operator === "" || typeof getDataField.operator === "undefined") {
            formObject.operator = true;
            formValid = false;
        }

        if (getDataField.accountNumber === "" || typeof getDataField.accountNumber === "undefined") {
            formObject.accountNumber = true;
            formValid = false;
        }

        setDataError(formObject);
        return formValid;
    }
    const viewBill = () => {
        if (FormValidate()) {
            setLoading(true)
            const payload = {
                provider: getDataField.operator,
                accountNumber: getDataField.accountNumber,
            }
            postRequest(GAS_VIEW_BILL, payload, history).then((response) => {
                if (response.success === true) {
                    setIsModalViewBill(true);
                    setCustomerInfo(response.data);
                    setPaymentId(response.id);
                    setDataField({ ...getDataField, amount : response.data.Billamount });
                } else {
                    setCustomerInfo({})
                    toast.error(response.message);
                }
                setLoading(false)
            }).catch(function (error) {
                // console.log(error)
                setLoading(false);
            })
        }
    }

    const billPayment = () => {
        if (FormValidate(true)) {
            setLoading(true)
            const payload = {
                paymentId: paymentId,
                amount: getDataField.amount,
            }
            postRequest(GAS_BILL_PAYMENT, payload, history).then((response) => {
                if (response.success === true) {
                    toast.success(response.message);
                    setCustomerInfo({})
                    setDataField([])
                    setDataError({})
                    setIsModalViewBill(false)
                    clearFormField()
                } else {
                    toast.error(response.message);
                }
                setLoading(false)
            }).catch(function (error) {
                // console.log(error)
                setLoading(false);
            })
        }
      };

    useEffect(() => {
        getOperators();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps


    return (
        <div>
            {loading &&
                <div className={'parentDisable'} width="100%">
                    <Space direction="vertical" style={{ width: '100%', }} >
                        <Spin tip="Loading" size="large">
                            <div className="spin-loader" />
                        </Spin>
                    </Space>
                </div>}
            <div className='pt-4'>
                <h4 className='mb-3 fa-1x'>Gas Bill Payment</h4>
                <div className="form-group">
                    <div className="input-group">
                        <Select options={getOperatorData} className={`normal-light w-100 ${getDataError && getDataError.operator ? "is-invalid" : ""}`} classNamePrefix="select" placeholder="Select Your Gas Provider" ref={operatorSelect} name="operator" onChange={(e) => onChangeValueSelect(e)} />
                    </div>
                    {getDataError && getDataError.operator && <span className="error text-danger">The field is required</span>}
                </div>
                <div className="form-group">
                    <div className="input-group">
                        <input type="text" className={`form-control normal-light ${getDataError && getDataError.accountNumber ? "is-invalid" : ""}`} placeholder="Mobile Number" name="accountNumber" ref={accountNumberInput} maxLength={30} onChange={(e) => onChangeValue(e)} />
                    </div>
                    {getDataError && getDataError.accountNumber && <span className="error text-danger">The field is required</span>}
                </div>

                <div className='row pt-2'>
                    <div className='col-12'>
                        <button type="button" className="btn btn-danger mb-2 btn-block" onClick={(e) => viewBill(e)}>View Bill</button>
                    </div>
                </div>
            </div>
            <ToastContainer position="top-center" autoClose={5000} limit={2} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="colored"/>
            {isModalViewBill && <Modal title={"Customer Info"} width={500} open={isModalViewBill} onOk={billPayment} onCancel={handleHideModal} okText={"Payment ("+getCustomerInfo.Billamount+")"}>
                {getCustomerInfo ?
                    <ul>
                        <li><span>Customer Name</span> : {getCustomerInfo.customerName}</li>
                        <li><span>Net Amount</span> : {getCustomerInfo.Netamount}</li>
                        <li><span>Distributor Name</span> : {getCustomerInfo.DistributorName}</li>
                        <li><span>Bill Amount</span> : {getCustomerInfo.Billamount}</li>
                        <li><span>LPG ID</span> : {getCustomerInfo.Lpg_Id}</li>
                    </ul>
                    : ""}
            </Modal>}
        </div>
    )
}


export default GasBillPayment