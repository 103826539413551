import React, { useEffect } from 'react';
import './../../../webcss/default.css';
import './../../../webcss/styles.css';
import Header from './../header';
import Footer from './../footer';

function BankingServices() {
    useEffect(() => {
        // setReportMode(BankingServicess);
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            <Header />
            <div>
                <div className="notice-section notice-section-sm border-top red">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-10 col-sm-10 col-12">
                                <div className="mt-5">
                                    <h6 className="fontwhite">Become a SR PAY Private Limited Franchise &amp; Grow your income 30% to 50% per month</h6>
                                </div>
                            </div>
                            <div className="col-md-2 col-sm-2 col-12">
                                <div className="right-holder-md">
                                    <a href="/joinus" className="primary-button button-lg mt-15-xs bluebtn">Join Now</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-title-section">
                    <div className="container">
                        <h1><span className="white">Banking </span> Services</h1>
                    </div>
                </div>
                <div className="section-block">
                    <div className="container">
                        <div className="blog-list-simple">
                            <div className="row">
                                <div className="col-md-5 col-sm-5 col-12">
                                    <div className="blog-list-simple-img">
                                        <img src="img/projects/aeps.jpg" alt="img" />
                                    </div>
                                </div>
                                <div className="col-md-7 col-sm-7 col-12">
                                    <div className="blog-list-simple-text">
                                        <h4 className="bluefont">Aadhaar Enabled <span className="white">Payment System</span></h4>
                                        <p>Aadhaar is not just limited as your identify proof, it has much more features than you imagine, we the SR PAY Private Limited Private Limited will help you to use your Aadhaar as your primary (ATM) Debit Card without any additional charges.
                                            “Money when you want and where you want” Yes, SR PAY Private Limited Private Limited is here to help you do all the banking activities with a hassle free process including money withdraw, money transfer, balance enquiry and getting mini statement of your account through the fast &amp; secure Biometric Scanner available at all leading stores through SR PAY Private Limited Private Limited retailer.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="blog-list-simple">
                            <div className="row">
                                <div className="col-md-5 col-sm-5 col-12">
                                    <div className="blog-list-simple-img">
                                        <img src="img/projects/money-trns.webp" alt="img" />
                                    </div>
                                </div>
                                <div className="col-md-7 col-sm-7 col-12">
                                    <div className="blog-list-simple-text">
                                        <h4 className="bluefont">Money <span className="white">Transfer</span></h4>
                                        <p>Don’t have a bank account - no worries, SR PAY Private Limited Private Limited is here to provide you the facility of freedom from opening a bank account and standing in long queue just to get your own money or sending it to your loved ones. SR PAY Private Limited Private Limited assist you with all the banking facilities to transfer money without any time limitations or bank holidays. You can connect with us to transfer money 24/7 and 365 days a year within your vicinity at a nearby store through SR PAY Private Limited Private Limited retailers. What’s more we only charge you a minimal amount for providing all these facilities that is not going to hamper your budget at all.</p>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        <div className="blog-list-simple">
                            <div className="row">
                                <div className="col-md-5 col-sm-5 col-12">
                                    <div className="blog-list-simple-img">
                                        <img src="img/projects/mini-atm.webp" alt="img" />
                                    </div>
                                </div>
                                <div className="col-md-7 col-sm-7 col-12">
                                    <div className="blog-list-simple-text">
                                        <h4 className="bluefont">Mini <span className="white">ATM</span></h4>
                                        <p>An ATM in your region, SR PAY Private Limited Private Limited brings the ATM one step closer to your location. You can withdraw cash using most bank’s debit or credit cards with SR PAY Private Limited Private Limited Mini ATM service hence, no long queues at the ATMs. We are introducing the technology which will change the overall experience of withdrawing money from ATM while making the whole process smoother. No worries of stuck money or card and no instances of lack of cash as often experienced at most bank ATM’s. </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="blog-list-simple">
                            <div className="row">
                                <div className="col-md-5 col-sm-5 col-12">
                                    <div className="blog-list-simple-img">
                                        <img src="img/projects/qr-code.jpeg" alt="img" />
                                    </div>
                                </div>
                                <div className="col-md-7 col-sm-7 col-12">
                                    <div className="blog-list-simple-text">
                                        <h4 className="bluefont">Payment Via <span className="white">QR Code</span></h4>
                                        <p>Withdraw money now without visiting any bank and without having any document not even an Aadhaar card. We help you to withdraw (Upto Rs 1,000 in a single transaction) through SR PAY Private Limited Private Limited partners without paying any additional charges.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="blog-list-simple">
                            <div className="row">
                                <div className="col-md-5 col-sm-5 col-12">
                                    <div className="blog-list-simple-img">
                                        <img src="img/projects/mpos.jpg" alt="img" />
                                    </div>
                                </div>
                                <div className="col-md-7 col-sm-7 col-12">
                                    <div className="blog-list-simple-text">
                                        <h4 className="bluefont">m<span className="white">POS</span></h4>
                                        <p>With SR PAY Private Limited Private Limited mPOS service SR PAY Private Limited Private Limited retailers can accept payments from the customers through leading Debit or Credit cards of all major providers like Master Card, Visa, RuPay and Maestro.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Footer />
            </div>
        </div>
    )
}

export default BankingServices