import React, { useEffect, useState } from 'react';
import KYCPendingUser from './User';
import { /* ToastContainer, toast */ } from 'react-toastify';
// import { getUser } from '../../../Utils/Common';
import { Input, Button, Select} from 'antd';

function IndexApp(props) {
    // const user = getUser();
    const kycStatusArray = [
        { "value": "All", "label": 'All' },
        { "value": "1", "label": 'Aadhaar Number Empty' },
        { "value": "2", "label": 'Aadhaar verification is pending' }
    ]

    const [kycStatus, setKYCStatus] = useState(null);
    const [checkSubmit, setCheckSubmit] = useState(0);
    const [search, setSearch] = useState(null);

    const handleClear = () => {
        // setRole("all");
        setSearch(null);
        setKYCStatus(null)
        setCheckSubmit(false)
        handleSearch();
    }

    const onSearch = (value) => {
        // // console.log(`selected ${value}`);
        setSearch(value)
    }

    const handleSearch = () => {
        // console.log("new Date() ====",new Date())
        // setDate(new Date());
        setCheckSubmit(checkSubmit + 1)
        // if (search && search.length > 8) {
        //     setCheckSubmit(checkSubmit + 1)
        // } else {
        //     toast.error("Please enter valid user id")
        // }
    }

    const onChangeTypeMode = (value) => {
        setKYCStatus(value);
    }

    useEffect(() => {
        // setReportMode(uitilityReports);
        // getRoles();
        // setRoleOrderExist(user.data.role.order);
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            <div className="content-wrapper bg-white pt-4">
                <div className="content-header my-0 py-0">
                    <div className="container-fluid mb-3 ml-2">
                        <div className="row mb-0 align-items-center">
                            <div className="col-sm-6">
                                <h1 className='page-title text-blue font-merriweather'>Aadhaar KYC <span className='text-red'> Pending</span></h1>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid">
                        <div className="row mb-2 align-items-center">
                            <div className="col-sm-12 d-flex">
                                <div className="input-group col-md-3 col-sm-6 pull-right">
                                    <label className='date-search-label mb-0 text-red'>&nbsp;</label>
                                    <div className='col-md-12'>
                                        <Input placeholder="User ID Search ...." onChange={(e) => onSearch(e.target.value)} style={{ width: "100%" }} value={search} />
                                    </div>
                                </div>
                                <div className="input-group col-md-2 pull-right">
                                    <label className='date-search-label mb-0 text-red'>&nbsp;</label>
                                    <Select options={kycStatusArray} className={`normal-light w-100`} classNamePrefix="select" placeholder="-- Mode --" name='mode' onChange={(e) => onChangeTypeMode(e)} value={kycStatus} />
                                </div>
                                <div className="input-group col-md-2 pull-right">
                                    <label className='date-search-label mb-0 text-red'>&nbsp;</label>
                                    <div className='col-md-12'>
                                        <Button type="button" className='primary' onClick={handleSearch} >Search</Button>
                                        <Button type="default" onClick={handleClear} className='ml-2'>Clear</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <div className="content">
                    <KYCPendingUser kycStatus={kycStatus} search={search} handleSearch={checkSubmit} />
                </div>
            </div>
            {/* <ToastContainer position="top-center" autoClose={5000} limit={2} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="colored"/> */}
        </div>
    )
}

export default IndexApp