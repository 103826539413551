import React, { useEffect, useState } from 'react';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { useHistory } from "react-router-dom";
import { Modal, Button, Space, Checkbox, Radio } from 'antd';
import CommingSoon from '../../container/commingsoon';
import { postRequest } from '../../Utils/AppApi';
import { POST_SEND_MATM_OFFER_API } from '../../Utils/appConstants';
import { toast } from 'react-toastify';

function AgentDashboard(props) {
  const history = useHistory();
  const [isMatchCheckbox, setMatchCheckbox] = useState(false);
  const [ecollect, setEcollect] = useState(false);
  const [commingSoon, setCommingSoon] = useState(false);
  const [userProfile, setUserProfile] = useState(null);
  const [showService, setShowService] = useState(false);
  const [isMatmModal, setMatmModal] = useState(false);
  const [isProductName, setProductName] = useState("Combo");
  const [isProductAmount, setProductAmount] = useState("4200");

  const serviceOption = [{
    value: 'Combo',
    label: 'Combo',
    amount: '4200',
  }, {
    value: 'Mantra L1',
    label: 'Mantra L1',
    amount: '3200',
  }, {
    value: 'MATM (Morefun MP63)',
    label: 'MATM (Morefun MP63)',
    amount: '1800',
  }]
  const handleOpenECollectClose = () => {
    setEcollect(false);
  }

  const comm = () => {
    setCommingSoon(true)
  }

  const handleOpenClose = () => {
    setCommingSoon(false)
  }

  const handleMatmModal = () => {
    setMatmModal(true)
  }

  const onChangeChackBox = (e) => {
    // console.log("e.target.checked -------------", e.target.checked)
    setMatchCheckbox(e.target.checked)
  };

  const handleSizeChange = ({ target: { value } }) => {
    let data = serviceOption.filter((items) => items.value === value);
    // console.log("e.target.checked -------------", data[0].amount)
    setProductName(value);
    setProductAmount(data[0].amount);
  };

  const sendBuyOffer = () => {
    let payload = {
      quantity: 1,
      amount: isProductAmount,
      product: isProductName,
      comment: ""
    }
    postRequest(POST_SEND_MATM_OFFER_API, payload, history).then((response) => {
      if (response.success === true) {
        setMatchCheckbox(false);
        setMatmModal(false);
        toast.success(response.message);
      } else {
        setMatmModal(false);
        toast.error(response.message);
      }
    }).catch(function (error) {
      setMatmModal(false);
      console.log(error)
      // setLoading(false);
    })
  }

  useEffect(() => {
    // console.log("props.userData.data -------------", props.userData.data)
    if (props.userData || !userProfile) {
      setUserProfile(props.userData.data)
    }
    if (userProfile && userProfile.kycStatus === 3) {
      setShowService(true)
    }
  }, [userProfile]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <div className="content pt-3">
        <div className="container-fluid pb-5 five-xl-col">
          <div className="row">
            <div className="col-sm-6 col-md-4 col-lg-3">
              <div className='dbox-outer' onClick={() => history.push({ pathname: "/mobile-recharge" })}>
                <img src='../images/mobile-dth-1@2x.png' width={"15%"} alt='photos' />
                <div className='dbox-text'>Mobile / DTH</div>
              </div>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-3">
              <div className='dbox-outer' onClick={() => history.push({ pathname: "/uitility-payment" })}>
                <img src='../images/utilityicon-1@2x.png' width={"20%"} alt='photos' />
                <div className='dbox-text'>Utility Payment offline</div>
              </div>
            </div>

            <div className="col-sm-6 col-md-4 col-lg-3">
              <div className='dbox-outer' onClick={() => comm() /* history.push({ pathname: "/bharat-billpay-bbps" }) */}>
                <img src='../bbps/b.png' width={"30%"} alt='photos' />
              </div>
            </div>

            <div className="col-sm-6 col-md-4 col-lg-3">
              <div className='dbox-outer' onClick={() => history.push({ pathname: "/instant-topup" })}>
                <img src='../images/instanttopup-1@2x.png' width={"10%"} alt='photos' />
                <div className='dbox-text'>Instant Topup</div>
              </div>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-3">
              <div className='dbox-outer' onClick={() => history.push({
                pathname: "/uitility-payment",
                tab: 4,
                provider: "LICOF"
              })}>
                <img src='../images/lic.png' width={"15%"} alt='photos' />
                <div className='dbox-text'>LIC Premium</div>
              </div>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-3 " onClick={() => comm()}>
              <div className='dbox-outer'>
                <img src='../images/wallat_recharge.png' width={"15%"} alt='photos' />
                <div className='dbox-text'>Wallet Recharge</div>
              </div>
            </div>
            {/* <div className="col-sm-6 col-md-4 col-lg-3 ">
              <div className='dbox-outer' onClick={() => handleOpenECollect()}>
                <img src='../images/ecollect-1@2x.png' width={"10%"} alt='photos' />
                <div className='dbox-text'>E - Collect</div>
              </div>
            </div> */}

            <div className="col-sm-6 col-md-4 col-lg-3 " onClick={() => comm()}>
              <div className='dbox-outer'>
                <img src='../images/card-1@2x.png' width={"30%"} alt='photos' />
                <div className='dbox-text'>Card Payment</div>
              </div>
            </div>

            {/* <div className="col-sm-6 col-md-4 col-lg-3 col-xl-3">
                  <div className='dbox-outer'>
                    <img src='../images/money-icon.png' alt='photos' />
                    <div className='dbox-text'>Money Transfer</div>
                  </div>
                </div> */}
            <div className="col-sm-6 col-md-4 col-lg-3" onClick={() => comm()}>
              <div className='dbox-outer'>
                <img src='../images/aadharpay-1@2x.png' width={"30%"} alt='photos' />
              </div>
            </div>
            {showService && <div className="col-sm-6 col-md-4 col-lg-3" onClick={() => history.push({ pathname: "/express-aeps" })}>
              <div className='dbox-outer'>
                <img src='../images/aepslogo-1@2x.png' alt='photos' />
                <div className='dbox-text text-primary'><i>Powered By YBL / APB</i></div>
              </div>
            </div>}
            {/* <div className="col-sm-6 col-md-4 col-lg-3" onClick={() => comm()}>
              <div className='dbox-outer'>
                <img src='../images/qr_code.png' alt='photos' />
                <div className='dbox-text'>UPI QR</div>
              </div>
            </div> */}
            <div className="col-sm-6 col-md-4 col-lg-3" onClick={() => handleMatmModal()}>
              <div className='dbox-outer d-flex'>
                <div className="col-10 d-flex">
                  <img src='../images/matm.png' className="mr-3" alt='photos' width={'50px'} />
                  <div>
                    <div className='dbox-text text-danger blink_text'>MATM Combo Offer</div>
                    <div className='dbox-text text-primary'>Purchased New Device</div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-sm-6 col-md-4 col-lg-3" onClick={() => comm()}>
              <div className='dbox-outer'>
                <img src='../images/loan-repayment.png' alt='photos' width={'50px'} />
                <div className='dbox-text'>Loan Repayment</div>
              </div>
            </div> */}
            {/* <div className="col-sm-6 col-md-4 col-lg-3 col-xl-3">
                  <div className='dbox-outer'>
                    <img src='../images/upilogo-1@2x.png' width={"60%"} alt='photos' />
                  </div>
                </div> */}
            {/* <div className="col-sm-6 col-md-4 col-lg-3 col-xl-3">
                  <div className='dbox-outer'>
                    <img src='../images/qrcode_fundtrans.png' alt='photos' />
                    <div className='dbox-text'>QR Fund Transfer</div>
                  </div>
                </div> */}
            {showService && <div className="col-sm-6 col-md-4 col-lg-3" onClick={() => history.push({ pathname: "/move-to-bank" })} >
              <div className='dbox-outer'>
                <img src='../images/deposit-money-bank.jpeg' width={'50px'} alt='photos' />
                <div className='dbox-text'>Settle To Bank</div>
                {/* <div className='dbox-text text-primary'>Upto 500,000</div> */}
              </div>
            </div>}
            {showService && <div className="col-sm-6 col-md-4 col-lg-3" onClick={() => history.push({ pathname: "/aeps" })}>
              <div className='dbox-outer'>
                <img src='../images/aepslogo-1@2x.png' alt='photos' />
                <div className='dbox-text text-primary'><i>Powered By NSDL</i></div>
              </div>
            </div>}
            {/* <div className="col-sm-6 col-md-4 col-lg-3 col-xl-3">
                  <div className='dbox-outer'>
                    <img src='../images/nepalmoney.png' width={"10%"}  alt='photos' />
                    <div className='dbox-text'>Nepal Money Transfer</div>
                  </div>
                </div> */}
            <div className="col-sm-6 col-md-4 col-lg-3" onClick={() => history.push({ pathname: "/partner-ekyc" })}>
              <div className='dbox-outer'>
                <img src='../images/kyc-icon.png' width={"12%"} alt='photos' />
                <div className='dbox-text'>Patner KYC</div>
              </div>
            </div>

            <div className="col-sm-6 col-md-4 col-lg-3">
              <div className='dbox-outer' onClick={() => history.push({ pathname: "/uitility-payment-2-0" })}>
                <img src='../images/utilityicon-1@2x.png' width={"20%"} alt='photos' />
                <div className='dbox-text'>Utility Payment 2.0</div>
              </div>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-3" onClick={() => history.push({ pathname: "/upiqrmini-code" })}>
              <div className='dbox-outer'>
                <div><i class="fa fa-qrcode fa-3x" aria-hidden="true" ></i></div>
                <div className='dbox-text'>QR Payment</div>
                {/* <div className='dbox-text text-primary'>Up To 3,499</div> */}
              </div>
            </div>
            {showService &&
              <div className="col-sm-6 col-md-4 col-lg-3" onClick={() => history.push({ pathname: "/money-transfer" })}>
                <div className='dbox-outer'>
                  <img src='../images/money-icon.png' alt='photos' />
                  <div className='dbox-text'>Money Transfer</div>
                </div>
              </div>}
          </div>
        </div>
      </div>
      {ecollect && (<Modal
        open={ecollect}
        onCancel={() => handleOpenECollectClose()}
        footer={false}
      >
        <Space
          direction="vertical"
          style={{
            width: '100%',
          }}
          className='mt-20'
        >
          <a target='_blank' rel="noreferrer" href='https://www.onlinesbi.sbi/sbicollect/icollecthome.htm?corpID=856614'><Button type="primary" block> SBI Collect </Button></a>
          <Button type="primary" block> PNB Collect </Button>
          <Button type="primary" block> UNION Collect </Button>
        </Space>
      </Modal>)
      }

      {commingSoon && (<Modal
        open={commingSoon}
        onCancel={() => handleOpenClose()}
        footer={false}
        width={"500px"}
      >
        <CommingSoon />
      </Modal>)
      }

      {isMatmModal && (<Modal
        open={isMatmModal}
        onCancel={() => setMatmModal(false)}
        okText={"Confirm"}
        onOk={() => sendBuyOffer()}
        okButtonProps={{ disabled: !isMatchCheckbox }}
        width={500}
      >
        <div className="col-md-6 col-lg-12 mb-1">
          <img src='../../festival/offer.jpeg' width={"540"} alt='' />
          <div className="row">
            <div className='col-md-12 form-group mt-3'>
              <Radio.Group options={serviceOption} value={isProductName} buttonStyle="solid" optionType="button" onChange={handleSizeChange}>
              </Radio.Group>
            </div>
          </div>
          <Checkbox className="mr-2" onChange={onChangeChackBox} /><p className="mt-2 text-primary pl-3">I hereby confirm that i accept the SRPAY MATM Combo offer wroth Rs. {isProductAmount}.</p>
          <strong>Note :- <span className="text-danger">Once the Product is purchased amount is not refundable.</span></strong>
        </div>
      </Modal>)
      }
    </div>
  );
}

export default AgentDashboard;