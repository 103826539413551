import React, { useEffect, useState } from 'react';
// import { APP_KEY, API_URL } from './../config';
import DataTable from 'react-data-table-component';
import { GET_ADMIN_COMPLAINT_LIST, PUT_UPDATE_COMPLAINT_LIST } from './../../Utils/appConstants';
import { getRequest, putRequest } from './../../Utils/AppApi';
import { complaintStatus } from './../../Utils/Common';
import { useHistory } from "react-router-dom";
import { DatePicker, Tooltip, Modal, Radio } from 'antd';
import { getUTCToLocalDateTimeFormat } from '../../Utils/function';
import { ToastContainer, toast } from 'react-toastify';

function ComplaintList(props) {
    const history = useHistory();
    // const csvLink = useRef()
    const limit = 10;
    const dateFormat = 'DD-MM-YYYY';
    const [search, setSearch] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [getData, setData] = useState([]);
    const [editModel, setModel] = useState(false);
    const [getTotalRecord, setTotalRecord] = useState(0);
    const [page, setPage] = useState(1);
    const [isLimit, setLimit] = useState(limit);
    const [isSelectedData, setIsSelectedData] = useState(null);

    const columns = [{
        name: 'Date Time',
        selector: row => <><Tooltip>{getUTCToLocalDateTimeFormat(row.createdAt)}</Tooltip></>,
    }, {
        name: 'Complaint Number',
        selector: row => <><Tooltip title={row.complaintNumber}>{row.complaintNumber}</Tooltip></>,
    }, {
        name: 'Transaction ID',
        selector: row => <><Tooltip title={row.transactionId}>{row.transactionId}</Tooltip></>,
    }, {
        name: 'Reason',
        selector: row => <><Tooltip title={row.clientReason}>{row.clientReason}</Tooltip></>,
    }, {
        name: 'Status',
        selector: row => <><Tooltip title={complaintStatus(row.status)}>{complaintStatus(row.status)}</Tooltip></>,
    }, {
        name: 'Action',
        cell: row => <><div className='icon-border border border-primary text-primary pointer' title='Edit' onClick={(event) => handleEditView(event, row)}><i className="fas fa-edit" aria-hidden="true"></i></div></>,
    }]

    const handleEditView = (event, data) => {
        setModel(true)
        setIsSelectedData(data)
    }

    const handleEdit = (data) => {
        const payload = {
            status: data.status,
            adminReason: data.adminReason
        };
        putRequest(`${PUT_UPDATE_COMPLAINT_LIST}/${data._id}`, payload).then((response) => {
            if (response.success === true) {
                setModel(false)
                getDataReports();
                toast.success(response.message);
            } else {
                toast.error(response.message);
            }
        }).catch(function (error) {
            // console.log(error)
            // setLoading(false);
        })
    }
    const handleHideModal = () => {
        setModel(false);
        setIsSelectedData(null);
    };

    const objectToQueryString = (obj) => {
        var str = [];
        for (var p in obj)
            if (obj.hasOwnProperty(p)) {
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
        return str.join("&");
    }

    const params = { page: page, limit: isLimit };

    const getDataReports = (data) => {
        if (data) {
            setLimit(data)
            params.limit = data;
        }
        if (startDate && endDate) {
            params.startDate = startDate ? startDate : "";
            params.endDate = endDate ? endDate : "";
        }
        if (search) {
            params.search = search ? search : "";
        }

        const queryString = objectToQueryString(params);
        getRequest(`${GET_ADMIN_COMPLAINT_LIST}?${queryString}`, history).then((response) => {
            if (response.success === true) {
                setData(response.data);
                setTotalRecord(response.data.totalDocs);
                setPage(response.data.page);
            } else {
                setData([]);
                setTotalRecord(0);
                setPage(1);
            }
        }).catch(function (error) {
            console.log(error)
            // setLoading(false);
        })
    } // eslint-disable-line react-hooks/exhaustive-deps

    const handlePageChange = (page, size) => {
        // console.log("-----", size)
        setPage(page);
    };

    const onChangeStartDate = (date, dateString) => {
        // console.log(date, dateString);
        setStartDate(dateString)
    };

    const onChangeEndDate = (date, dateString) => {
        // console.log(date, dateString);
        setEndDate(dateString)
    };

    const handleSearch = () => {
        getDataReports();
    }

    const onChangeValue = (event) => {
        const { name, value } = event.target;
        // console.log("name -----------", name)
        // console.log("value -----------", value)
        setIsSelectedData({ ...isSelectedData, [name]: value });
    }
    const onChangeBankPercentageStatus = ({ target: { value } }) => {
        let isSelectedUserValue = { ...isSelectedData };
        isSelectedUserValue.status = value;
        setIsSelectedData(isSelectedUserValue);
    };

    useEffect(() => {
        handleSearch();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        getDataReports();
    }, [page]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            <div className="content-wrapper">
                <div className="content">
                    <div className="container-fluid pt-3">
                        <div className='card rounded-0'>
                            <div className='card-body'>
                                <div className="row">
                                    <div className='col-md-8 date-search ml-2 mb-0'>
                                        <div className='row'>
                                            <div className='col-sm-3 col-lg-3'>
                                                <div className="form-group">
                                                    <label className='date-search-label mb-0 text-blue'>From</label>
                                                    <DatePicker className="form-control form-control-border" format={dateFormat} onChange={onChangeStartDate} />
                                                </div>
                                            </div>
                                            <div className='col-sm-3 col-lg-3'>
                                                <div className="form-group">
                                                    <label className='date-search-label mb-0 text-red'>To</label>
                                                    <DatePicker className="form-control form-control-border" format={dateFormat} onChange={onChangeEndDate} />
                                                </div>
                                            </div>
                                            <div className="col-sm-4 col-lg-4">
                                                <div className="form-group">
                                                    <label className='date-search-label mb-0 text-red'></label>
                                                    <input className='form-control' placeholder='Search ...' onChange={(e) => setSearch(e.target.value)}/>
                                                </div>
                                            </div>
                                            <div className='col-sm-2 col-lg-2'>
                                                <div><label className='date-search-label mb-0 text-red'>&nbsp;</label></div>
                                                <button type="submit" className="btn btn-primary rounded-pill pl-4 pr-4" onClick={() => handleSearch()}>Search</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="container-fluid pt-2 pb-5">
                                    <DataTable
                                        title={""}
                                        columns={columns}
                                        data={getData.docs}
                                        highlightOnHover
                                        pagination
                                        paginationServer
                                        paginationTotalRows={getTotalRecord}
                                        onChangeRowsPerPage={getDataReports}
                                        onChangePage={handlePageChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {editModel && <Modal title={"Edit Complaint"} width={600} open={editModel} onOk={() => handleEdit(isSelectedData)} onCancel={handleHideModal}>
                <div className='row'>
                    <div className='col-md-12 form-group'>
                        <label>Date Time: </label> {getUTCToLocalDateTimeFormat(isSelectedData.createdAt)}
                    </div>
                    <div className='col-md-12 form-group'>
                        <label>transactionId: </label> {isSelectedData.transactionId}
                    </div>
                    <div className='col-md-12 form-group'>
                        <label>Complaint Number: </label> {isSelectedData.complaintNumber}
                    </div>
                    <div className='col-md-12 form-group'>
                        <label>Complaint Text: </label> {isSelectedData.clientReason}
                    </div>
                    <div className='col-md-12 form-group d-flex'>
                        <label>Status: </label> &nbsp; {complaintStatus(isSelectedData.status)}
                        <div className='text-center col-md'>
                            <Radio.Group
                                options={[
                                    {
                                        label: 'Pending',
                                        value: 0,
                                    }, {
                                        label: 'Inprocess',
                                        value: 1,
                                    }, {
                                        label: 'Rejected',
                                        value: 2,
                                    }, {
                                        label: 'Solved',
                                        value: 3,
                                    }]}
                                onChange={onChangeBankPercentageStatus}
                                value={isSelectedData.status}
                                optionType="button"
                            // buttonStyle="solid"
                            />
                        </div>
                    </div>
                    <div className='col-md-12 form-group'>
                        <label>Comment: </label>
                        <textarea className="form-group form-control" onChange={(event) => onChangeValue(event)} name='adminReason' cols={"4"} rows={"5"} >{isSelectedData.adminReason}</textarea>
                    </div>
                    {/* <div className='col-md-12 form-group align-text-right'>
                        <div className='pull-right align-text-right '>
                            <button type='button' onClick={() => handleEditApproved()} className='btn btn-sm btn-primary'>Approved</button>
                        </div>
                    </div> */}
                </div>
            </Modal>}
            <ToastContainer position="top-center" autoClose={5000} limit={2} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="colored" />

        </div>
    )
}

export default ComplaintList