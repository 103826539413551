import React, { useEffect, useState } from 'react';
// import { bankModes } from './../config/reportName';
import DataTable from 'react-data-table-component';
// import { isNumber } from './../../Utils/Common';
import { GET_BANK_SETTING_LIST, BANK_SETTING_APMIN_APPROVE_REQUEST } from './../../Utils/appConstants';
import { getRequest, postRequest } from './../../Utils/AppApi';
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import { Input, Select, Tooltip } from 'antd';
import { requestStatus } from '../../Utils/Common';

function BankSettingList(props) {

    const history = useHistory();  // eslint-disable-line react-hooks/exhaustive-deps
    // const csvLink = useRef()
    const limit = 10;
    const [getData, setData] = useState([]);
    const [getTotalRecord, setTotalRecord] = useState(0);
    const [page, setPage] = useState(1);

    const [searchStatus, setSearchStatus] = useState("0");
    const [search, setSearch] = useState(null);

    const columns = [{
        name: 'Name',
        selector: row => <><Tooltip title={row.users.name}>{row.users.name}</Tooltip></>,
    }, {
        name: 'User ID',
        selector: row => <><Tooltip title={row.users.username}>{row.users.username}</Tooltip></>,
    }, {
        name: 'Account Number',
        selector: row => <><Tooltip title={row.accountNumber}>{row.accountNumber}</Tooltip></>,
    }, {
        name: 'A/C Name',
        selector: row => <><Tooltip title={row.name}>{row.name}</Tooltip></>,
    }, {
        name: 'Bank Name',
        selector: row => <><Tooltip title={row.bankName}>{row.bankName}</Tooltip></>,
    }, {
        name: 'IFSC Code',
        selector: row => <><Tooltip title={row.ifscCode}>{row.ifscCode}</Tooltip></>,
    }, {
        name: 'Branch Address',
        selector: row => <><Tooltip title={row.branchAddress}>{row.branchAddress}</Tooltip></>,
    }, {
        name: 'City',
        selector: row => row.city ? row.city : "-",
    }, {
        name: 'State',
        selector: row => row.state ? row.state : "-",
    }, {
        name: 'Action',
        cell: row => <>{(row.isAdminApproved === 0) ? <><div className="text-success pointer" onClick={(event) => handleChangeStatus(event, row._id, "1")}><b>Approve</b></div>&nbsp; | &nbsp;<div className="text-danger pointer" onClick={(event) => handleChangeStatus(event, row._id, "2")}>Reject</div></> : ''}</>,
        width: "150px"
    }] // eslint-disable-line react-hooks/exhaustive-deps

    const objectToQueryString = (obj) => {
        var str = [];
        for (var p in obj)
            if (obj.hasOwnProperty(p)) {
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
        return str.join("&");
    } // eslint-disable-line react-hooks/exhaustive-deps

    const params = { page: page, limit: limit };

    const getDataReports = () => {
        // if (startDate && endDate) {
        //   params.startDate = startDate ? startDate : "";
        //   params.endDate = endDate ? endDate : "";
        // }
        params.searchStatus = searchStatus ? searchStatus : null;
        params.search = search ? search : "";
        params.admin = true;
        const queryString = objectToQueryString(params);
        getRequest(`${GET_BANK_SETTING_LIST}?${queryString}`, history).then((response) => {
            if (response.success === true) {
                setData(response.data);
                setTotalRecord(response.data.totalDocs);
                setPage(response.data.page);
            } else {
                setData([]);
                setTotalRecord(0);
                setPage(1);
            }
        }).catch(function (error) {
            console.log(error)
            // setLoading(false);
        })
    } // eslint-disable-line react-hooks/exhaustive-deps

    const handlePageChange = page => {
        setPage(page);
    }; // eslint-disable-line react-hooks/exhaustive-deps

    const handleChangeStatus = (event, id, value) => {
        console.log("handleChangeStatus -------")
        let payload = { status: value };
        postRequest(`${BANK_SETTING_APMIN_APPROVE_REQUEST}/${id}`, payload, history).then((response) => {
            // console.log("response -------", response)
            if (response.success === true) {
                getDataReports();
                toast.success(response.message);
            } else {
                toast.error(response.message);
            }
        }).catch(function (error) {
            console.log(error)
        })
    } // eslint-disable-line react-hooks/exhaustive-deps

    const onSearch = (value) => {
        setSearch(value)
    }

    const handleClear = () => {
        setSearch(null);
        setSearchStatus("0");
        getDataReports();
    }

    const onChangeType = (value) => {
        // const { value, label } = event;
        // console.log(`selected ${value}`);
        // console.log(`selected ${label}`);
        setSearchStatus(value.toString());
    }

    const handleSearch = () => {
        getDataReports();
    }

    useEffect(() => {
        getDataReports();
    }, [page]) // eslint-disable-line react-hooks/exhaustive-deps

    // useEffect(() => {
    //     getDataReports();
    // }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            <div className="content-wrapper pt-2">
                <div className="content-header my-0 py-0">
                    <div className="container-fluid">
                        <div className="row mb-0 align-items-center">
                            <div className="col-sm-6">
                                <h1 className='page-title text-blue font-merriweather mb-3'>Registered <span className='text-red'>Bank Account</span></h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content">
                    <div className="container-fluid pt-2 pb-5">
                        <div className="row">
                            <div className="col-md-12">
                                <div className='card card-body'>
                                    <div className="row mb-2 align-items-center">
                                        <div className="col-sm-12 d-flex">
                                            <div className="input-group col-sm-2 ">
                                                <label className="form-label">Status:</label>
                                                <Select options={requestStatus} className={`normal-light w-100`} classNamePrefix="select" placeholder="-- Status --" name='status' value={parseInt(searchStatus) >= 0 ? parseInt(searchStatus) : "All"} onChange={(e) => onChangeType(e)} />
                                            </div>
                                            <div className="input-group col-sm-3 pull-right">
                                                <label className="form-label">Search:</label>
                                                <div className='col-md-12'>
                                                    <Input placeholder="Search ...." onChange={(e) => onSearch(e.target.value)} style={{ width: "100%" }} value={search} />
                                                </div>
                                            </div>
                                            <div className="input-group col-md-2 pull-right">
                                                <label className="form-label">&nbsp;</label>
                                                <div className='col-md-12'>
                                                    <button type="button" className="btn btn-primary" onClick={handleSearch} >Search</button>
                                                    <button type="default" onClick={handleClear} className='btn btn-default ml-2'>Clear</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='history-table'>
                                        <div className="col-md-12">
                                            <DataTable
                                                title={""}
                                                columns={columns}
                                                data={getData.docs}
                                                highlightOnHover
                                                pagination
                                                paginationServer
                                                paginationTotalRows={getTotalRecord}
                                                onChangeRowsPerPage={getDataReports}
                                                onChangePage={handlePageChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer position="top-center" autoClose={5000} limit={2} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="colored" />
            </div>
        </div>
    )
}

export default BankSettingList;