import React, { useEffect, useState, useRef } from 'react';
import { Modal, DatePicker, Switch } from 'antd';
// import { APP_KEY, API_URL } from './../config';
import { isNumber } from '../../Utils/Common';
import { BBPS_ELETRICITY_OPERATORS, BBPS_POST_BILL_FETCH_REQUEST, BBPS_POST_BILL_PAYMENT_REQUEST, MPIN_NUMBER_VERIFY } from '../../Utils/appConstants';
import { getNewRequest, postRequest, postPayURequest } from '../../Utils/AppApi';
import RiseLoader from "react-spinners/RiseLoader";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'antd/dist/antd.css';
import moment from 'moment';
import Select from 'react-select';

function Common(props) {
    const CATEGORY = props.category
    const dateFormat = 'DD/MM/YYYY';
    const queryDateFormat = 'YYYY-MM-DD';
    const history = useHistory();
    const operatorSelect = useRef(null);
    const accountNumberInput = useRef(null);
    const mobileUnitInput = useRef(null);
    const dueDateSelect = useRef(null);
    const customerMobileInput = useRef(null);
    const [isLoading, setLoading] = useState(false);
    const [isDisabled, setDisabled] = useState(false);
    const [isModalViewBill, setIsModalViewBill] = useState(false);
    const [selectedDueDate, setSelectedDueDate] = useState(false);
    const [isModalCheckMpinNumber, setModalCheckMpinNumber] = useState(false);
    const [mPinNumber, setmPinNumber] = useState(null);

    const [isCustomerParams, setCustomerParams] = useState(null);
    const [isCustomerValues, setCustomerValues] = useState(null);

    const [getOperatorData, setOperatorData] = useState([]);
    const [getDataField, setDataField] = useState([]);
    const [getDataError, setDataError] = useState({});
    const [getCustomerInfo, setCustomerInfo] = useState({});

    const clearFormField = () => {
        operatorSelect.current.value = "";
        accountNumberInput.current.value = "";
        mobileUnitInput.current.value = "";
        dueDateSelect.current.value = "";
        customerMobileInput.current.value = "";
        setSelectedDueDate(false)
    };

    const getOperators = () => {
        setLoading(true)
        let data = { category: CATEGORY }
        getNewRequest(BBPS_ELETRICITY_OPERATORS, data, history).then((response) => {
            if (response.success === true) {
                setOperatorData(response.data.map(item => {
                    return { "value": { billerId: item.billerId, customerParams: item.customerParams }, "label": item.billerName };
                }));
            } else {
                toast.error(response.message);
            }
            setLoading(false);
        }).catch(function (error) {
            console.log(error)
            setLoading(false);
        })
    }

    const handleHideModal = () => {
        setCustomerInfo({})
        setDataField([])
        setDataError({})
        setIsModalViewBill(false)
        clearFormField()
    };

    const handleHideMpinModal = () => {
        setModalCheckMpinNumber(false)
        setmPinNumber(null)
    };

    const onChangeValueSelect = (event) => {
        const { value, label } = event;
        console.log("value ----------", value)
        setCustomerParams(value.customerParams);
        setDataField({ ...getDataField, "operator": value.billerId, "CANumberKey": value.customerParams[0].paramName, "billerName": label });
    }

    const onChangeValue = (event) => {
        const { name, value } = event.target;
        setDataField({ ...getDataField, [name]: value });
    }

    const onChangeValueParams = (event) => {
        const { name, value, dataType } = event.target;
        if (dataType === "NUMERIC") {
            setCustomerValues({ ...isCustomerValues, [name]: parseInt(value) })
        } else {
            setCustomerValues({ ...isCustomerValues, [name]: value })
        }
        // console.log("isCustomerValues--------------", isCustomerValues)
        setDataField({ ...getDataField, [name]: value });
    }

    const onChangeMpinValue = (event) => {
        const { value } = event.target;
        setmPinNumber(value);
    }

    const onChangeDatePicker = (date) => {
        // console.log(date, dateString);
        let dateValue = moment(date).format(queryDateFormat);
        setDataField({ ...getDataField, dueDate: dateValue });
        setSelectedDueDate(true)
    };

    const FormValidate = () => {
        let formValid = true;
        let formObject = {};
        setDataError({})
        if (getDataField && getDataField.length === 0) {
            formValid = false;
        }
        if (getDataField.operator === "" || typeof getDataField.operator === "undefined") {
            formObject.operator = true;
            formValid = false;
        }
        // if (getDataField.accountNumber === "" || typeof getDataField.accountNumber === "undefined") {
        //     formObject.accountNumber = true;
        //     formValid = false;
        // }

        if (getDataField.mobileUnitCycle === "" || typeof getDataField.mobileUnitCycle === "undefined") {
            formObject.mobileUnitCycle = true;
            formValid = false;
        }

        if (getDataField.customerMobile === "" || typeof getDataField.customerMobile === "undefined") {
            formObject.customerMobile = true;
            formValid = false;
        }

        isCustomerParams && isCustomerParams.forEach(element => {
            if (getDataField && (getDataField[element.paramName] === "" || typeof getDataField[element.paramName] === "undefined")) {
                formObject[element.paramName] = true;
                formValid = false;
            }
        })

        setDataError(formObject);
        return formValid;
    }
    const viewBill = () => {
        if (FormValidate()) {
            setLoading(true);
            setDisabled(true)
            const payload = {
                billerId: getDataField.operator,
                billerName: getDataField.billerName,
                CANumberKey: getDataField.CANumberKey,
                CANumber: getDataField.accountNumber,
                customerParams: isCustomerValues,
                customerName: getDataField.accountNumber,
                customerPhoneNumber: getDataField.customerMobile,
                mobileUnitCycle: getDataField.mobileUnitCycle,
                dueDate: getDataField.dueDate,
                category: CATEGORY,
            }
            postPayURequest(BBPS_POST_BILL_FETCH_REQUEST, payload, history).then((response) => {
                if (response.success === true) {
                    setIsModalViewBill(true);
                    setCustomerInfo(response.data);
                    setDataField({ ...getDataField, amount: response.data.amount, accountHolderName: response.data.accountHolderName, refId: response.data.refId });
                } else {
                    setCustomerInfo({})
                    toast.error(response.message);
                }
                setDisabled(false)
                setLoading(false);
            }).catch(function (error) {
                setDisabled(false)
                // console.log(error)
                setLoading(false);
            })
        }
    }

    const checkMpinNumber = async () => {
        setModalCheckMpinNumber(true)
        if (isModalCheckMpinNumber) {
            const payload = { mpin: mPinNumber }
            const response = await postRequest(MPIN_NUMBER_VERIFY, payload, history);
            console.log(response)
            if (response.success === true) {
                billPayment();
                handleHideMpinModal()
                setIsModalViewBill(false)
                toast.success(response.message);
            } else {
                toast.error(response.message);
            }
        }
    }

    const billPayment = () => {
        if (FormValidate(true)) {
            setLoading(true);
            const payload = {
                billerId: getDataField.operator,
                billerName: getDataField.billerName,
                CANumberKey: getDataField.CANumberKey,
                CANumber: getDataField.accountNumber,
                customerParams: isCustomerValues,
                customerName: getDataField.accountHolderName,
                customerPhoneNumber: getDataField.customerMobile,
                mobileUnitCycle: getDataField.mobileUnitCycle,
                dueDate: getDataField.dueDate,
                paidAmount: getDataField.amount,
                refId: getDataField.refId,
                category: CATEGORY,
            }

            postPayURequest(BBPS_POST_BILL_PAYMENT_REQUEST, payload, history).then((response) => {
                if (response.success === true) {
                    setCustomerInfo({})
                    setDataField([])
                    setDataError({})
                    setIsModalViewBill(false)
                    clearFormField()
                    handleHideMpinModal()
                    toast.success(response.message);
                } else {
                    toast.error(response.message);
                }
                setLoading(false);
            }).catch(function (error) {
                // console.log(error)
                setLoading(false);
            })
        }
    };

    useEffect(() => {
        getOperators();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    if (isLoading) {
        return <div className="content text-center" style={{ margin: "250px 0" }}>
            <RiseLoader color="#5851D2" loading={isLoading} size={25} />
        </div>
    }

    return (
        <div>
            <div className='pt-4'>
                <div className='col-md-12 row'>
                    <div className='col-md-6 mb-3'>
                        <h4 className='fa-2x text-lightblue'>{CATEGORY.replace(/(^\w|\s\w)(\S*)/g, (_, m1, m2) => m1.toUpperCase() + m2.toLowerCase())}</h4>
                    </div>
                    {/* <div className='col-md-6 text-right'>
                        <img className='text-right' src='../images/bharat-billpay-logo-1@2x.png' width={"20%"} alt='photos' />
                    </div> */}
                </div>
                <div className='col-md-12 row'>
                    <div className='col-md-6'>
                        <div className="form-group">
                            <div className="input-group">
                                <Select options={getOperatorData} className={`normal-light w-100 ${getDataError && getDataError.operator ? "is-invalid" : ""}`} classNamePrefix="select" ref={operatorSelect} name='operator' onChange={(e) => onChangeValueSelect(e)} placeholder="Select Your Provider" />
                            </div>
                            {getDataError && getDataError.operator && <span className="error text-danger">The field is required</span>}
                        </div>
                        {isCustomerParams && isCustomerParams.map((element) => {
                            return <div className="form-group">
                                <div className="input-group">
                                    <input type="text" className={`form-control normal-light ${getDataError && getDataError[element.paramName] ? "is-invalid" : ""}`} placeholder={element.paramName} name={element.paramName} ref={accountNumberInput} dataType={element.dataType} minLength={element.minLength} maxLength={element.maxLength} onChange={(e) => onChangeValueParams(e)} />
                                </div>
                                {getDataError && getDataError[element.paramName] && <span className="error text-danger">The field is required</span>}
                            </div>
                        })}
                        {!isCustomerParams && <div className="form-group">
                            <div className="input-group">
                                <input type="text" className={`form-control normal-light ${getDataError && getDataError.accountNumber ? "is-invalid" : ""}`} placeholder={getDataField.CANumberKey} name="accountNumber" ref={accountNumberInput} maxLength={30} onChange={(e) => onChangeValue(e)} />
                            </div>
                            {getDataError && getDataError.accountNumber && <span className="error text-danger">The field is required</span>}
                        </div>}

                        <div className="form-group">
                            <div className="position-relative">
                                <input type="text" className={`form-control normal-light  ${getDataError && getDataError.mobileUnitCycle ? "is-invalid" : ""}`} placeholder="Cycle / Unit / Mobile" name="mobileUnitCycle" ref={mobileUnitInput} maxLength={30} onChange={(e) => onChangeValue(e)} />
                            </div>
                            {getDataError && getDataError.mobileUnitCycle && <span className="error text-danger">The field is required</span>}
                        </div>

                        <div className="form-group">
                            <div className="position-relative">
                                {/* defaultValue={moment(new Date(), dateFormat)} */}
                                <DatePicker format={dateFormat} className={`form-control normal-light is-invalid`} placeholder="Select Due Date" name="dueDate" ref={dueDateSelect} maxLength={10} value={!selectedDueDate ? moment() : moment(getDataField.dueDate)} onChange={onChangeDatePicker} />
                            </div>
                        </div>

                        <div className="form-group">
                            <div className="position-relative">
                                <input type="text" className={`form-control normal-light  `} placeholder="Customer Mobile" name="customerMobile" ref={customerMobileInput} maxLength={10} onChange={(e) => onChangeValue(e)} />
                            </div>
                            {getDataError && getDataError.customerMobile && <span className="error text-danger">The field is required</span>}
                        </div>

                        <div className='row pt-2'>
                            <div className='col-12'>
                                <button type="button" className="btn btn-danger mb-2 btn-block" disabled={isDisabled} onClick={(e) => viewBill(e)}>View Bill</button>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className='card rounded-0'>
                            <div className='card-body'>
                                {/* <h3 className='text-blue text-center mb-3'><span className='text-red'>Offers</span></h3> */}
                                <div className='text-center'>
                                    <img src='../images/offer.png' alt='img' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer position="top-center" autoClose={5000} limit={2} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="colored" />
            {isModalViewBill && <Modal title={<div className='col-md-12 row text-left'>
                <div className='col-md-3 text-center'></div>
                <div className='col-md-6 text-center'>
                    Customer Info <br/>
                    CA Number : 9782820262
                </div>
                <img className='col-md-3 text-right' src='../images/bharat-billpay-logo-1@2x.png' width={"20%"} alt='photos' />

            </div>} width={500} open={isModalViewBill} onOk={checkMpinNumber} onCancel={handleHideModal} okText={"Process (" + getCustomerInfo.amount + ")"}>
                {getCustomerInfo ?
                    <>
                        <ul>
                            <li><span>Customer Name</span> : {getCustomerInfo.accountHolderName}</li>
                            <li><span>Bill Number</span> : {getCustomerInfo.billNumber}</li>
                            <li><span>Bill Date</span> : {getCustomerInfo.billDate}</li>
                            <li><span>Bill Amount</span> : {getCustomerInfo.amount}</li>
                            <li><span>Due Date</span> : {getCustomerInfo.dueDate}</li>
                            <li><span>Bill Period</span> : {getCustomerInfo.billPeriod}</li>
                            <li className='mt-3'><span>CCF</span> <Switch size="small" /><div className='col-md-3 float-right'> 0</div></li>
                        </ul>
                    </>
                    : ""}
            </Modal>}
            {isModalCheckMpinNumber && <Modal title={<div className='col-md-12 row text-left'>
                <img className='col-md-3 text-right' src='../images/bharat-billpay-logo-1@2x.png' width={"20%"} alt='photos' />
                <div className='col-md-6 text-center'>
                    Confirm Mpin
                </div>
            </div>} width={500} open={isModalCheckMpinNumber} onOk={checkMpinNumber} onCancel={handleHideMpinModal} okText={"Confirm"}>
                {getCustomerInfo ?
                    <><div>
                        <div className="form-group">
                            {/* <label className="col-sm-12 col-form-label">Mpin</label> */}
                            <div className="col-sm-12">
                                <input type="text" maxLength={4} className={`form-control normal-light `} placeholder="Enter Mpin" onChange={(e) => onChangeMpinValue(e)} value={mPinNumber} onKeyDown={(e) => isNumber(e)} />
                            </div>
                        </div>
                    </div>
                    </>
                    : ""}
            </Modal>}
        </div>
    )
}


export default Common