import React, { useEffect } from 'react';
// import "bootstrap/dist/css/bootstrap.css";
// import "react-bootstrap-carousel/dist/react-bootstrap-carousel.css";
// import moment from 'moment';
import './../../webcss/default.css';
import './../../webcss/styles.css';
import Header from './header';
import Footer from './footer';

function Download() {


    useEffect(() => {
        // setReportMode(Servicess);
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            <div>
                <Header />
                <div>
                    <div className="notice-section notice-section-sm border-top red">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-10 col-sm-10 col-12">
                                    <div className="mt-5">
                                        <h6 className="fontwhite">Become a SR PAY Private Limited Franchise &amp; Grow your income 30% to 50% per month</h6>
                                    </div>
                                </div>
                                <div className="col-md-2 col-sm-2 col-12">
                                    <div className="right-holder-md">
                                        <a href="/joinus" className="primary-button button-lg mt-15-xs bluebtn">Join Now</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="page-title-section">
                        <div className="container">
                            <h1><span className="white">S</span>ervices</h1>
                        </div>
                    </div> */}

                    <div className="section-block-grey">
                        <div className="container col-md-8 d-flex">
                            <div className="card col-md-6">
                                <div className="card-body">
                                    <h5 className="card-title">Partner App</h5>
                                    <div className="section-heading center-holder pointer" onClick={() => {
                                        // if (process.env.REACT_APP_SRPAY_LINK === "www.srpay1.com") {
                                        window.open('https://play.google.com/store/apps/details?id=com.srpaypartner', '_blank')
                                        // }
                                    }}>
                                        <img src='./googleplay1@3x.png' className='mt-50' alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="card col-md-6">
                                <div className="card-body">
                                    <h5 className="card-title">Distributor App</h5>
                                    <div className="section-heading center-holder pointer">
                                        <a href={"SRPAY_Distributor.apk"}
                                            download="srpay"
                                            target="_blank"
                                            rel="noreferrer"
                                        ><img src='./googleplay1@3x.png' className='mt-50' alt="" /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    )
}

export default Download