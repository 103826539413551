// Maintenance.js

import React from 'react';

const Maintenance = ({ message }) => {
    return (
        <div>
            <div className="pt-2">
                <div className="content">
                    <div className="container-fluid pt-2 pb-5">
                        <div className="row">
                            <div className='card col-md-12 py-5'>
                                <div className='card-body text-center'>
                                    <h1>Under Maintenance</h1>
                                    <p>{message}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Maintenance;
