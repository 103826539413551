// import React from 'react';
// import Commingsoon from '../../container/commingsoon';
// function InstantTopup(props) {
//   return (
//     <div className="content-header my-0 py-0">
//       <div className="container-fluid">
//         <div className="row mb-0 align-items-center">
//           <div className=" col-sm-12">
//             <Commingsoon />
//           </div>
//         </div>
//       </div>
//     </div>)
// }
// export default InstantTopup;

import React, { useEffect, useState, useRef } from 'react';
// import { APP_KEY, API_URL } from './../config';
import { CSVLink } from 'react-csv';
import DataTable from 'react-data-table-component';
import { instantTopupStatus, isNumber, isNumberKey } from './../../Utils/Common';
import { INSTANT_TOPUP_SAVE, INSTANT_TOPUP_GET, CHECK_CARD_TYPE, GET_PAYU_HASH, AADHAAR_CARD_SEND_OTP_CODE, CREATE_USER_AADHARCARD_OTP_VERIFY, /* INSTANT_TOPUP_KYC_CHARGE, MOBILE_SEND_OTP, MOBILE_SEND_VERFIY */ } from './../../Utils/appConstants';
import { getRequest, postRequest } from './../../Utils/AppApi';
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import moment from 'moment';
import validator from 'validator'
import { DatePicker, Modal, Button, Divider } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import { getUTCToLocalDateTimeFormat } from '../../Utils/function';

export const AutoSubmitForm = (actionUrl, params, formRef) => {
  return new Promise(async () => {
    return (
      <form ref={formRef} method="POST" action={actionUrl}>
        {Object.keys(params).map(name => (
          <input type="hidden" name={name} value={params[name]} ></input>
        ))}
      </form>
    );
  })
};

function InstantTopup(props) {
  // console.log("window.location.hostname ---------------",process.env.REACT_APP_PUBLIC_URL)
  const history = useHistory();
  const csvLink = useRef()
  const limit = 10;
  const dateFormat = 'DD-MM-YYYY';
  const [isWarning, setIsWarning] = useState(true);
  const [isIsOptionModal, setIsOptionModal] = useState(false);
  const [ishash, setHash] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [getDataField, setDataField] = useState({});
  const [getDataError, setDataError] = useState({});
  const [firstname, setFirstName] = useState("");
  const [email, setEmail] = useState("");
  const [getData, setData] = useState([]);
  const [getCsvData, setCsvData] = useState([]);
  const [getTotalRecord, setTotalRecord] = useState(0);
  const [page, setPage] = useState(1);
  const [aadharData, setAadharData] = useState(null);

  // const paymentUrl = 'https://test.payu.in/_payment';
  // const paymentUrl = 'https://secure.payu.in/_payment';

  const paymentUrl = process.env.REACT_APP_PAYU_HOSTNAME;

  const [isCvvModel, setCvvModel] = useState(false)
  // const [afterSave, setAfterSave] = useState(false)
  const [isParams, setParams] = useState(null)
  const [isDomestic, setIsDomestic] = useState("")
  const [issuingBank, setIssuingBank] = useState("")
  const [cardType, setCardType] = useState("")
  const [cardCategory, setCardCategory] = useState("")
  const [marchentKey, setMarchentKey] = useState("")
  const [transactionId, setTransactionId] = useState("")
  const [productinfo, setProductInfo] = useState("")

  const [isCustomerVerifyModal, setCustomerVerifyModal] = useState(false);
  const [isOtpModal, setOtpModal] = useState(false);
  const [isOtp, setIsOtp] = useState(false);
  const [isOtpNumber, setIsOtpNumber] = useState(null);
  const [isRefId, setIsRefId] = useState(null);
  // const [isCheckAadharAlreadyVerify, setCheckAadharAlreadyVerify] = useState(false);
  const [aadharNumber, setAadharNumber] = useState(null)
  const [aadharRegMobileNumber, setAadharRegMobileNumber] = useState(null)

  const ccnumInput = useRef(null);
  const amountInput = useRef(null);
  const bankNameInput = useRef(null);
  const cardHolderNameInput = useRef(null);
  const customerMobileInput = useRef(null);
  const ccexpmonInput = useRef(null);
  const ccexpyrInput = useRef(null);
  const formRef = useRef(null);

  // const mobile = useFormInput('');
  const clearFormField = () => {
    ccnumInput.current.value = "";
    amountInput.current.value = "";
    bankNameInput.current.value = "";
    cardHolderNameInput.current.value = "";
    customerMobileInput.current.value = "";
    ccexpmonInput.current.value = "";
    ccexpyrInput.current.value = "";
    setCvvModel(false)
    setParams(null)
  }

  const columns = [{
    name: 'Date',
    cell: row => getUTCToLocalDateTimeFormat(row.createdAt),
    width: "200px"
  }, {
    name: 'Trans ID',
    selector: row => row.transactionId,
    width: "180px"
  }, {
    name: 'Amount',
    selector: row => row.amount,
    width: "100px"
  }, {
    name: 'Bank Name',
    selector: row => row.bankName,
    width: "150px"
  }, {
    name: 'Card No.',
    selector: row => row.cardNumber ? row.cardNumber : '-',
    width: "180px"
  }, {
    name: 'Card Holder Number',
    selector: row => row.cardHolderName,
    width: "180px"
  }, {
    name: 'Mobile No.',
    selector: row => row.customerMobile,
    width: "120px"
  }, {
    name: 'Card Type',
    selector: row => row.cardType,
  }, {
    name: 'Marchant Ref',
    selector: row => <>{row.responseData ? "-" : '-'}</>,
    width: "120px"
    // }, {
    //   name: 'Bank Ref Number',
    //   selector: row => row.responseData ? JSON.parse(row.responseData).bank_ref_num : '-',
  }, {
    name: 'TXN Status',
    cell: row => instantTopupStatus(row.status),
    width: "120px"
  }, {
    name: 'Action',
    cell: row => <>{row.isClaimed}</>,
    // "0 = Pending / 1 = inprocess / 2 = rejected / 3 = refund / 4 = completed"
  }]

  const objectToQueryString = (obj) => {
    var str = [];
    for (var p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    return str.join("&");
  }

  const params = { page: page, limit: limit };

  const getDataReports = () => {
    if (startDate && endDate) {
      params.startDate = startDate ? startDate : "";
      params.endDate = endDate ? endDate : "";
    }

    // if (search) {
    //   params.search = search ? search : "";
    // }

    const queryString = objectToQueryString(params);
    getRequest(`${INSTANT_TOPUP_GET}?${queryString}`, history).then((response) => {
      if (response.success === true) {
        setData(response.data);
        setTotalRecord(response.data.totalDocs);
        setPage(response.data.page);
      } else {
        setData([]);
        setTotalRecord(0);
        setPage(1);
      }
    }).catch(function (error) {
      console.log(error)
      // setLoading(false);
    })
  } // eslint-disable-line react-hooks/exhaustive-deps

  const getCSVDataList = () => {
    let params = {}
    params.exportsCsv = true;
    if (startDate && endDate) {
      params.startDate = startDate ? startDate : "";
      params.endDate = endDate ? endDate : "";
    }

    // if (search) {
    //   params.search = search ? search : "";
    // }
    const queryString = objectToQueryString(params);
    getRequest(`${INSTANT_TOPUP_GET}?${queryString}`, history).then((response) => {
      if (response.success === true) {
        let csvdataRecord = [];
        let csvdata = response.data;
        csvdata.map((e) => {
          return csvdataRecord.push({ "Date": moment(e.createdAt).format("DD/MM/YYYY"), "Reference Number": e.referenceNumber, "Amount": e.amount, "Bank Name": e.bankName, "Card Number": e.ccnum, "Card Holder Name": e.cardHolderName, "Customer Mobile": e.customerMobile, "Card Type": e.cardType, "Marchant Ref": e.marchantRef, "Bank Ref Number": e.bankRefNumber, "TXT Status": (e.txtStatus) ? "success" : "failed", "Payment Status": (e.paymentStatus) ? "success" : "failed" });
        });
        setCsvData(csvdataRecord);
        setTimeout(() => {
                    csvLink.current.link.click();
                }, 1000);
      } else {
        setCsvData([]);
      }
    }).catch(function (error) {
      console.log(error)
      // setLoading(false);
    })
  }

  const handlePageChange = page => {
    setPage(page);
  };

  const validateCreditCard = (value) => {
    if (validator.isCreditCard(value)) {
      return true
    }
    return false
  }

  const handleHideModal = () => {
    setCvvModel(false);
    clearFormField()
  };

  const onChangeValue = (event) => {
    const { name, value } = event.target;
    console.log('name-----', name)
    console.log('value-----', value)
    if (name === "ccnum") {
      if (validateCreditCard(value)) {
        checkCardType(value)
        setDataField({ ...getDataField, [name]: value });
        // console.log('Valid CreditCard Number-----', value)
      } else {
        if (value.length === 16) {
          toast.error("Invalid Card Number");
        }
        setDataField({ ...getDataField, [name]: value });
      }
    } else {
      setDataField({ ...getDataField, [name]: value });
    }
  }
  // const instantKycCharge = () => {
  //   let payload = {  }
  //   postRequest(INSTANT_TOPUP_KYC_CHARGE, payload, history).then((response) => {
  //     if (response.success === true) {
  //     } else {
  //       toast.error(response.message);
  //     }
  //   }).catch(function (error) {
  //     console.log(error)
  //   })
  // }

  const checkCardType = (value) => {
    let payload = { card: value }
    postRequest(CHECK_CARD_TYPE, payload, history).then((response) => {
      if (response.success === true) {
        console.log('Card Type-----', response.message)
        setIsDomestic(response.message.isDomestic)
        setIssuingBank(response.message.issuingBank)
        setCardType(response.message.cardType)
        setCardCategory(response.message.cardCategory)
        toast.success("Valid Card Number");
      } else {
        toast.error(response.message);
      }
    }).catch(function (error) {
      console.log(error)
    })
  }

  const getHash = (value) => {
    let payload = { amount: value }
    postRequest(GET_PAYU_HASH, payload, history).then((response) => {
      if (response.success === true) {
        setFirstName(response.firstname)
        setEmail(response.email)
        setMarchentKey(response.marchentKey)
        setTransactionId(response.txnid)
        setProductInfo(response.productinfo)
        setHash(response.hash)
      } else {
        toast.error(response.message);
      }
    }).catch(function (error) {
      console.log(error)
    })
  }

  const onChangeAmountValue = (event) => {
    const { name, value } = event.target;
    if (value >= 100) {
      getHash(value)
      setDataField({ ...getDataField, [name]: value });
    } else {
      setDataField({ ...getDataField, [name]: value });
    }
  }

  const onChangeCvvValue = (event) => {
    const { name, value } = event.target;
    setParams({ ...isParams, [name]: value });
  }

  const FormValidate = () => {
    let formValid = true;
    let formObject = {};
    setDataError({})
    if (getDataField && getDataField.length === 0) {
      formValid = false;
    }
    let array = ["amount", "bankName", "ccexpmon", "ccexpyr", "ccnum", "cardHolderName", "customerMobile"];

    array.forEach(element => {
      if (getDataField[element] === "" || typeof getDataField[element] === "undefined") {
        formObject[element] = true;
        formValid = false;
      }
    });

    if (getDataField && getDataField.amount < 500) {
      toast.error('Minimum amount value is Rs 500');
      formObject.amount = true;
      formValid = false;
    }
    if (getDataField && getDataField.amount >= 50000) {
      toast.error('Maxmum amount value is Rs 49999');
      formObject.amount = true;
      formValid = false;
    }

    setDataError(formObject);
    return formValid;
  }

  const handleSubmit = async () => {
    if (FormValidate()) {
      const payload = {
        transactionId: transactionId,
        marchentKey: marchentKey,
        productinfo: productinfo,
        firstname: firstname,
        aadharNumber: aadharNumber,
        aadharRegMobileNumber: aadharRegMobileNumber,
        email: email,
        amount: getDataField.amount,
        customerMobile: getDataField.customerMobile,
        bankName: getDataField.bankName,
        ccnum: getDataField.ccnum,
        ccexpmon: getDataField.ccexpmon,
        ccexpyr: getDataField.ccexpyr,
        cardHolderName: getDataField.cardHolderName,
        cardType: cardType,
        isDomestic: isDomestic,
        issuingBank: issuingBank,
        cardCategory: cardCategory,
        hash: ishash
      }
      setParams(payload)
      setCvvModel(true)
    }
  } // eslint-disable-line react-hooks/exhaustive-deps

  const PaymentSubmit = () => {
    const payload = {
      transactionId: transactionId,
      marchentKey: marchentKey,
      productinfo: productinfo,
      firstname: firstname,
      aadharNumber: aadharNumber,
      aadharRegMobileNumber: aadharRegMobileNumber,
      email: email,
      amount: getDataField.amount,
      customerMobile: getDataField.customerMobile,
      bankName: getDataField.bankName,
      ccnum: getDataField.ccnum,
      ccexpmon: getDataField.ccexpmon,
      ccexpyr: getDataField.ccexpyr,
      cardHolderName: getDataField.cardHolderName,
      cardType: cardType,
      isDomestic: isDomestic,
      issuingBank: issuingBank,
      cardCategory: cardCategory,
      hash: ishash
    }
    setParams(payload)
    postRequest(INSTANT_TOPUP_SAVE, payload, history).then((response) => {
      if (response.success === true) {
        toast.success(response.message);
        setTimeout(() => {
          formRef.current.submit();
        }, 500);
        setDataField({});
        setDataError({});
        getDataReports();
      } else {
        toast.error(response.message);
      }
    }).catch(function (error) {
      // console.log(error)
      // setLoading(false);
    });
  }

  const aadharFormValidate = () => {
    let formValid = true;
    if (!aadharRegMobileNumber || aadharRegMobileNumber === "") {
      toast.error("Aadhaar Register Mobile Number is required");
      formValid = false;
    } else if (aadharRegMobileNumber.length !== 10) {
      toast.error("Aadhaar Register Mobile Number is must be 10 digit");
      formValid = false;
    } else if (!aadharNumber || aadharNumber === "") {
      toast.error("Aadhaar Number is required");
      formValid = false;
    }

    return formValid;
  }

  const handleAadharCardOtpVerify = () => {
    if (!aadharFormValidate()) {
      return false
    }
    if (isOtp) {
      const payload = { aadharcard: aadharNumber, aadharcardOtp: isOtpNumber, ref_id: isRefId, isInstantCharge: true };
      postRequest(`${CREATE_USER_AADHARCARD_OTP_VERIFY}`, payload, history).then((response) => {
        if (response.success === true || response.alreadysuccess === true) {
          setIsOtp(false)
          setCustomerVerifyModal(true)
          // instantKycCharge()
          setAadharData(response.data)
          setOtpModal(false)
          setDataField({ ...getDataField, "cardHolderName": response.data.name });
          toast.success(response.message);
        } else {
          if (!response.aadharNumber || !response.alreadysuccess) {
            toast.error(response.message);
          } else {
            setIsOtp(false);
            toast.error(response.message);
          }
        }
      }).catch(function (error) {
        // console.log(error)
      })
    } else {
      const payload = { aadharcard: aadharNumber, isInstantCharge: true };
      postRequest(`${AADHAAR_CARD_SEND_OTP_CODE}`, payload, history).then((response) => {
        // if (response.alreadysuccess === true) {
        //   // setCheckAadharAlreadyVerify(true);
        //   setIsOtp(true);
        //   setAadharData(response.data)
        //   // sendOTPOnMobileNumber();
        //   // toast.success(response.message);
        // } else 
        if (response.success === true) {
          setIsOtp(true);
          setIsRefId(response.data.ref_id);
          toast.success(response.message);
        } else {
          toast.error(response.message);
        }
        setDataField({ ...getDataField, "customerMobile": aadharRegMobileNumber, "cardHolderName": response.data.name });
      }).catch(function (error) {
        // console.log(error)
        // setLoading(false);
      })
    }
  }

  // const sendOTPOnMobileNumber = () => {
  //   const payload = { mobile: aadharRegMobileNumber }
  //   postRequest(MOBILE_SEND_OTP, payload, history).then((response) => {
  //     if (response.success === true) {
  //       setIsOtp(true);
  //       toast.success(response.message);
  //     } else {
  //       toast.error(response.message);
  //     }
  //   }).catch(function (error) {
  //     // console.log(error)
  //     // setLoading(false);
  //   })
  // }

  // const sendOTPOnMobileNumberVerfiy = () => {
  //   const payload = { mobile: aadharRegMobileNumber, otp: isOtpNumber }
  //   postRequest(MOBILE_SEND_VERFIY, payload, history).then((response) => {
  //     if (response.success === true) {
  //       setIsOtp(false)
  //       setOtpModal(false)
  //       setCustomerVerifyModal(true)
  //       instantKycCharge()
  //       toast.success("Aadhaar is verify successful.");
  //     } else {
  //       toast.error(response.message);
  //     }
  //   }).catch(function (error) {
  //     // setLoading(false);
  //   })
  // }

  const onChangeStartDate = (date, dateString) => {
    // console.log(date, dateString);
    setStartDate(dateString)
  };

  const onChangeEndDate = (date, dateString) => {
    // console.log(date, dateString);
    setEndDate(dateString)
  };

  const handleSearch = () => {
    getDataReports();
  }
  // useEffect(() => {
  //   console.log("getDataField ----------", getDataField)
  // }, [getDataField]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getDataReports();
    // console.log("user ----------", user)
  }, [page]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      {/* {afterSave && <AfterPayment />} */}
      <div className="content-wrapper pt-2">
        <div className="content-header my-0 py-0">
          <div className="container-fluid">
            <div className="row mb-0 align-items-center">
              <div className="col-sm-6">
                <h1 className='page-title text-blue font-merriweather'>Instant <span className='text-red'>Topup</span></h1>
              </div>
            </div>
          </div>
        </div>
        <div className="content">
          <div className="container-fluid pt-2 pb-5">
            <div className="row">
              <div className="col-md-12">
                <div className='card rounded-0'>
                  <div className='card-body'>
                    <div>
                      <div className='row'>
                        <div className='col-sm-6 col-md-4 col-lg-3'>
                          <div className="form-group">
                            <label>Customer Mobile</label>
                            <input type="text" className={`form-control normal-light  ${getDataError && getDataError.customerMobile ? "is-invalid" : ""}`} name="customerMobile" ref={customerMobileInput} maxLength={10} onKeyDown={isNumber} onChange={(e) => onChangeValue(e)} placeholder="Customer Mobile*" readOnly value={getDataField && getDataField.customerMobile} />
                            {getDataError && getDataError.customerMobile && <span className="error text-danger">The field is required</span>}
                          </div>
                        </div>
                        <div className='col-sm-6 col-md-4 col-lg-3'>
                          <div className="form-group">
                            <label>Bank Name</label>
                            <input type="text" className={`form-control normal-light  ${getDataError && getDataError.bankName ? "is-invalid" : ""}`} name="bankName" ref={bankNameInput} maxLength={30} onChange={(e) => onChangeValue(e)} placeholder="Bank Name*" value={getDataField && getDataField.bankName} />
                            {getDataError && getDataError.bankName && <span className="error text-danger">The field is required</span>}
                          </div>
                        </div>
                        <div className='col-sm-6 col-md-4 col-lg-3'>
                          <div className="form-group">
                            <label>Card Number</label>
                            <input type="tel" className={`form-control normal-light  ${getDataError && getDataError.ccnum ? "is-invalid" : ""}`} name="ccnum" inputmode="numeric" ref={ccnumInput} maxLength={16} onChange={(e) => onChangeValue(e)} placeholder="XXXX XXXX XXXX XXXX" onKeyDown={isNumber} value={getDataField && getDataField.ccnum} />
                            {getDataError && getDataError.ccnum && <span className="error text-danger">The field is required</span>}
                          </div>
                        </div>
                        <div className='col-sm-6 col-md-4 col-lg-3'>
                          <div className="form-group">
                            <label>Card Holder Name</label>
                            <input type="text" className={`form-control normal-light  ${getDataError && getDataError.cardHolderName ? "is-invalid" : ""}`} name="cardHolderName" ref={cardHolderNameInput} maxLength={20} onChange={(e) => onChangeValue(e)} placeholder="Card Holder Name*" readOnly value={getDataField && getDataField.cardHolderName} />
                            {getDataError && getDataError.cardHolderName && <span className="error text-danger">The field is required</span>}
                          </div>
                        </div>
                        <div className='col-sm-6 col-md-4 col-lg-3'>
                          <div className="form-group">
                            <label>Amount</label>
                            <input type="text" className={`form-control normal-light  ${getDataError && getDataError.amount ? "is-invalid" : ""}`} name="amount" ref={amountInput} maxLength={8} onChange={(e) => onChangeAmountValue(e)} placeholder="Amount*" onKeyDown={isNumber} value={getDataField && getDataField.amount} />
                            {getDataError && getDataError.amount && <span className="error text-danger">The field is required</span>}
                          </div>
                        </div>
                        <div className='col-sm-6 col-md-3 col-lg-2'>
                          <div className="form-group">
                            <label>Expiry Month</label>
                            <input type="text" className={`form-control normal-light  ${getDataError && getDataError.ccexpmon ? "is-invalid" : ""}`} name="ccexpmon" ref={ccexpmonInput} maxLength={2} onKeyDown={isNumber} onChange={(e) => onChangeValue(e)} placeholder="MM" value={getDataField && getDataField.ccexpmon} />
                            {getDataError && getDataError.ccexpmon && <span className="error text-danger">The field is required</span>}
                          </div>
                        </div>
                        <div className='col-sm-6 col-md-2 col-lg-2'>
                          <div className="form-group">
                            <label>Expiry Year</label>
                            <input type="text" className={`form-control normal-light  ${getDataError && getDataError.ccexpyr ? "is-invalid" : ""}`} name="ccexpyr" ref={ccexpyrInput} maxLength={2} onKeyDown={isNumber} onChange={(e) => onChangeValue(e)} placeholder="YY" value={getDataField && getDataField.ccexpyr} />
                            {getDataError && getDataError.ccexpyr && <span className="error text-danger">The field is required</span>}
                          </div>
                        </div>
                        <div className='col-sm-6 col-md-4 col-lg-2'>
                          <label className='d-none d-lg-block'>&nbsp;</label>
                          <div className="form-group text-lg-center">
                            <button type="submit" className="btn btn-primary rounded-pill pl-4 pr-4" onClick={() => handleSubmit()}>Process</button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="content-header my-0 px-0 d-none">
                      <h1 className='page-title text-blue font-merriweather mb-3'>Request <span className='text-red'>History</span></h1>
                      <div className="row align-items-center">
                        <div className='col-md-6'>
                          <div className='date-search mb-0'>
                            <div className='row'>
                              <div className='col-sm-4 col-lg-3'>
                                <div className="form-group">
                                  <label className='date-search-label mb-0 text-blue'>From</label>
                                  <DatePicker className="form-control form-control-border" format={dateFormat} onChange={onChangeStartDate} />
                                </div>
                              </div>
                              <div className='col-sm-4 col-lg-3'>
                                <div className="form-group">
                                  <label className='date-search-label mb-0 text-red'>To</label>
                                  {/* <input type="text" className="form-control form-control-border" placeholder="27 September 2022" /> */}
                                  <DatePicker className="form-control form-control-border" format={dateFormat} onChange={onChangeEndDate} />
                                </div>
                              </div>
                              <div className='col-sm-4 col-lg-2'>
                                <div><label className='date-search-label mb-0 text-red'>&nbsp;</label></div>
                                <button type="submit" className="btn btn-primary rounded-pill pl-4 pr-4" onClick={() => handleSearch()}>Search</button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='col-md-6'>
                          <div className='float-sm-right'>
                            <button type="button" className="btn btn-primary mr-3" onClick={() => getCSVDataList()} ><i className="fa fa-file-excel"></i> Export</button>
                            <CSVLink
                              data={getCsvData}
                              filename='instant-topup-request-history'
                              className='hidden'
                              ref={csvLink}
                              target='_blank'
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="history-table d-none">
                      <div className="container-fluid pt-2 pb-5">
                        <div className="col-md-12">
                          <div className="box-body">
                            <DataTable
                              title={""}
                              columns={columns}
                              data={getData.docs}
                              highlightOnHover
                              pagination
                              paginationServer
                              paginationTotalRows={getTotalRecord}
                              onChangeRowsPerPage={getDataReports}
                              onChangePage={handlePageChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <form ref={formRef} method="POST" action={paymentUrl}>
          {/* {Object.keys(isParams).map((name, i) => (
          <input type="hidden" name={name} value={Object.values(isParams)[i]} ></input>
        ))} */}
          {isParams && isParams.marchentKey &&
            <><input type="hidden" name="key" defaultValue={isParams.marchentKey} />
              <input type="hidden" name="txnid" defaultValue={isParams.transactionId} />
              <input type="hidden" name="productinfo" defaultValue={isParams.productinfo} />
              <input type="hidden" name="amount" defaultValue={isParams.amount} />
              <input type="hidden" name="email" defaultValue={isParams.email} />
              <input type="hidden" name="firstname" defaultValue={isParams.firstname} />
              <input type="hidden" name="lastname" defaultValue="" />
              {/* isParams.cardCategory */}
              <input type="hidden" name="pg" defaultValue={"CC"} />
              <input type="hidden" name="bankcode" defaultValue={isParams.cardType} />
              <input type="hidden" name="ccnum" defaultValue={isParams.ccnum} />
              <input type="hidden" name="ccname" defaultValue={isParams.cardHolderName} />
              <input type="hidden" name="ccvv" defaultValue={isParams.ccvv} />
              <input type="hidden" name="ccexpmon" defaultValue={isParams.ccexpmon} />
              <input type="hidden" name="ccexpyr" defaultValue={isParams.ccexpyr} />
              <input type="hidden" name="surl" defaultValue={`${process.env.REACT_APP_PUBLIC_URL}/instant-topup/paymentResponse`} />
              <input type="hidden" name="furl" defaultValue={`${process.env.REACT_APP_PUBLIC_URL}/instant-topup/paymentResponseFailed`} />
              <input type="hidden" name="phone" defaultValue={isParams.customerMobile} />
              <input type="hidden" name="hash" defaultValue={isParams.hash} /></>
          }
        </form>
        <ToastContainer position="top-center" autoClose={5000} limit={2} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="colored" />
      </div>
      {isCvvModel && <Modal title={"Enter CVV"} width={500} open={isCvvModel} onCancel={handleHideModal} footer={false}>
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label>CVV</label>
              <input type="text" className={`form-control normal-light `} name="ccvv" maxLength={3} onChange={(e) => onChangeCvvValue(e)} value={isParams.ccvv} placeholder="CVV" />
              {isParams && !isParams.ccvv && <span className="error text-danger">The field is required</span>}
            </div>
          </div>
          <div className="col-md-6">
            <Button type="primary" size={"large"} icon={<ArrowRightOutlined />} block onClick={() => PaymentSubmit()} >Payment</Button>
          </div>
          <div className="col-md-6 text-right">
            <Button type="primary" size={"large"} onClick={() => clearFormField()} danger block>Reject</Button>
          </div>
        </div>
      </Modal>}
      {isWarning && (<Modal width={800} title={"Important Information"} open={isWarning} onCancel={() => history.push({ pathname: "/dashboard" })} onOk={() => { setIsWarning(false); setIsOptionModal(true) }} okText="I Agree" cancelText={false}>
        <div className="font-weight-bold text-center text-danger">
          <p className="text-justify text-danger">प्रिय SR PAY Private Limited Partner कृपया ध्यान दें कि इस राशि का उपयोग Bank Settlement के लिए नहीं किया जाता है। आप इस राशि का उपयोग केवल Bill Payments, BBPS Services and Utility Payments में कर सकते हैं। किसी भी प्रकार के लेनदेन के लिए कृपया केवल ग्राहक के मोबाइल नंबर का ही उपयोग करें।</p>
          <p><Divider /></p>
          <p className="text-justify text-danger">Dear SR PAY Private Limited User Please note that this amount is not used for Bank Settlement. You can only Use this amount in Bills Payments , BBPS Services and Utility Payments. Any kind of transaction Please use customer Mobile number only.</p>
        </div>
      </Modal>)}
      {isIsOptionModal && <Modal
        title="Options"
        width={600}
        open={isIsOptionModal}
        footer={false}
        onCancel={() => history.push({ pathname: "/dashboard" })}
      ><div className="row d-flex">
          <Button className="btn bg-primary pointer mb-2" block size={"large"} onClick={() => history.push({ pathname: "/instant-topup-report", state: { isClaimed: true } })}>Claim Payment</Button>
          <Button className="btn bg-warning pointer mb-2" block size={"large"} onClick={() => history.push({ pathname: "/instant-topup-report" })} >Instant Topup History</Button>
          <Button className="btn bg-success pointer" block onClick={() => {
            setOtpModal(true);
            setIsOptionModal(false);
          }} size={"large"}>New Transaction</Button>
        </div>
      </Modal>}
      {isCustomerVerifyModal && <Modal
        title="Customer Detail"
        width={600}
        open={isCustomerVerifyModal}
        onCancel={() => history.push({ pathname: "/dashboard" })}
        okText={"Confirm"}
        onOk={() => setCustomerVerifyModal(false)}
      ><div className="row"><div className="col-md-3">
        {aadharData && <img className="elevation-2" src={"data:image/png;base64," + aadharData.photo_link} alt="User Avatar" />}
      </div>
          <div className="col-md-9">
            <ol>
              <li><strong>Name : </strong>{aadharData && aadharData.name}</li>
              <li><strong>Father Name : </strong>{aadharData && aadharData.care_of}</li>
              <li><strong>Address : </strong>{aadharData && aadharData.address}</li>
            </ol>
          </div>
        </div>
      </Modal>}
      {isOtpModal && (<Modal
        title="Customer KYC"
        centered
        open={isOtpModal}
        onCancel={() => history.push({ pathname: "/dashboard" })}
        okText={"Submit"}
        // onOk={() => { (isCheckAadharAlreadyVerify) ? sendOTPOnMobileNumberVerfiy() : handleAadharCardOtpVerify() }}
        onOk={() => { handleAadharCardOtpVerify() }}
      >
        <div className=" mb-3">
          <div className="form-group">
            <label>Mobile Number</label>
            <input type="text" className="form-control" name="aadharRegMobileNumber" placeholder="" onChange={(e) => setAadharRegMobileNumber(e.target.value)} autoComplete="off" value={aadharRegMobileNumber} maxLength={10} onKeyDown={isNumber} />
          </div>
        </div>
        <div className="">
          <div className="form-group">
            <label>Aadhaar Number</label>
            <input type="text" className="form-control" name="aadharNumber" placeholder="" onChange={(e) => setAadharNumber(e.target.value)} autoComplete="off" value={aadharNumber} maxLength={12} onKeyDown={() => isNumberKey} />
          </div>
        </div>
        <hr />
        {isOtp &&
          <div className="input-group">
            <div className="custom-file">
              <input type="text" className="form-control" name="otpNumber" placeholder="OTP Number" defaultValue={""} maxLength={8} onChange={(e) => setIsOtpNumber(e.target.value)} />
            </div>
          </div>
        }
      </Modal>)}
    </div>
  )
}

export default InstantTopup;