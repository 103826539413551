import React, { useEffect, useState, useRef } from 'react';
// import { APP_KEY, API_URL } from './../config';
// import { bankModes } from './../../config/reportName';
import DataTable from 'react-data-table-component';
import { isNumberKey, capitalize } from './../../Utils/Common';
import { PACKAGE_LIST, PACKAGE_SAVE, USER_ROLE, PACKAGE_DETAIL_LIST, GET_SERVICES, MOBILE_RECHARGE_OPERATORS, DTH_PROVIDERS, SLABS_NAMES, GET_ALL_SERVICES_OPERATOR, PACKAGE_DELETE, PACKAGE_UPDATE } from './../../Utils/appConstants';
import { getRequest, postRequest, putRequest, deleteRequestNewApi } from './../../Utils/AppApi';
// import AppHeader from './../../Header/Header';
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import SlabData from './slab/list';
import { Switch, Modal, Select, Popconfirm, Radio } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
// import moment from 'moment';
import Select1 from 'react-select';

function Package(props) {
  const history = useHistory();
  // const csvLink = useRef()
  const limit = 10;
  // const dateFormat = 'DD-MM-YYYY';
  const [pageLimit, setPageLimit] = useState(limit);
  const [packageName, setPackageName] = useState(null);
  // const [packageDetail, setPackageDetail] = useState(null);
  const [serviceId, setServiceId] = useState(null);
  const [roleId, setRoleId] = useState(null);
  // const [startDate, setStartDate] = useState(null);
  const [getDataField, setDataField] = useState([]);
  const [getDataError, setDataError] = useState([]);
  const [getData, setData] = useState([]);
  //   const [getBankModes, setBankModes] = useState([]);
  const [getTotalRecord, setTotalRecord] = useState(0);
  const [page, setPage] = useState(1);
  const [isDetailModal, setDetailModal] = useState(false);
  const [slabData, setSlabData] = useState([{ "value": "", "label": "-- Select Slab --" }]);
  const [serviceData, setServiceData] = useState([]);
  const [serviceCode, setServiceCode] = useState(null);
  const [isFindOperator, setFindOperator] = useState(false);

  const [isOperatorData, setOperatorData] = useState([]);
  const [getRoleData, setRoleData] = useState(null);
  const [isModalEditDetail, setIsModalEditDetail] = useState(false);
  const [isSelectedUser, setIsSelectedUser] = useState({});
  const [packageDetailData, setPackageDetailData] = useState([]);
  const [getTotalDetailRecord, setTotalDetailRecord] = useState(0);
  const [pageDetail, setDetailPage] = useState(1);


  const [editSlabSelectedIndex, setEditSlabSelectedIndex] = useState(0);

  const nameInput = useRef(null);
  const slabSelect = useRef(null);
  const serviceSelect = useRef(null);
  const roleSelect = useRef(null);
  const maxInput = useRef(null);
  const minInput = useRef(null);
  const commissionInput = useRef(null);
  const serviceChargeInput = useRef(null);
  const tdsInput = useRef(null);

  const InputArray = { "min": "0", "max": "0", "isPerCommission": true, "commission": "0", "serviceCharge": "0", "isTdsApplicable": false, "tds": "0" };

  const clearFormField = () => {
    nameInput.current.value = "";
    serviceSelect.current.value = "";
    roleSelect.current.value = "";
    setDataField([]);
    setDataError({});
    // setDetailModal(false);
  }

  const columns = [{
    name: 'S. No.',
    selector: (row, index) => page === 1 ? (index + 1) : ((page - 1) * pageLimit) + (index + 1),
    width: "80px"
  }, {
    name: 'Name',
    selector: row => row._id.name,
  }, {
    name: 'Service Name',
    selector: row => row._id.serviceName,
  }, {
    name: 'User Type',
    selector: row => row._id.roleName,
  }, {
    name: 'Action',
    cell: row => <><div className='text-primary pointer' onClick={() => handleEditView(row._id)}>Detail</div></>,
  }]


  const columnsDetail = [{
    name: 'S. No.',
    selector: (row, index) => pageDetail === 1 ? (index + 1) : ((pageDetail - 1) * pageLimit) + (index + 1),
    width: "80px"
  }, {
    name: 'Service Name',
    selector: row => row.services && row.services.name,
    width: "180px"
  }, {
    name: 'Operator Name',
    selector: row => row.operatorName ? row.operatorName : "-",
    width: "150px"
  }, {
    name: 'User Type',
    selector: row => row.roles && row.roles.name,
    width: "180px"
  }, {
    name: 'Min',
    selector: row => row.min,
  }, {
    name: 'Max',
    selector: row => row.max,
  }, {
    name: 'Comm (%)',
    selector: row => (row.isPerCommission) ? "%" : "Flat",
  }, {
    name: 'Comm',
    selector: row => row.commission,
  }, {
    name: 'SC',
    selector: row => row.serviceCharge,
  }, {
    name: 'Is TDS',
    selector: row => row.isTdsApplicable ? "YES" : "NO",
    width: "80px"
  }, {
    name: 'TDS Amt',
    selector: row => row.tdsAmount,
  }, {
    name: 'Action',
    cell: row => <><div className='icon-border border border-primary text-primary pointer px-2' title='Edit Data'><i className="fa fa-edit" onClick={() => handleEdit(row)} aria-hidden="true"></i></div> <Popconfirm placement="left" title={'Are you sure to delete this data?'} onConfirm={(event) => handleDelete(event, row._id)} okText="Yes" cancelText="No"><div className='icon-border border border-danger text-danger pointer px-2'><i className="fa fa-trash" aria-hidden="true"></i></div></Popconfirm></>,
  }]

  const handleEdit = (data) => {
    setIsModalEditDetail(true)
    setIsSelectedUser(data)
    let slab = slabData.findIndex((items) => items.value === data.slabId)
    setEditSlabSelectedIndex(slab);
  }

  const onChangeFlatCommission = ({ target: { value } }) => {
    let isSelectedUserValue = { ...isSelectedUser };
    isSelectedUserValue.isPerCommission = value;
    setIsSelectedUser(isSelectedUserValue);
  };

  const onChangeBankPercentageStatus = ({ target: { value } }) => {
    let isSelectedUserValue = { ...isSelectedUser };
    isSelectedUserValue.isTdsApplicable = value;
    setIsSelectedUser(isSelectedUserValue);
  };

  const onChangeValueEditSlab = (event) => {
    const { value } = event;
    let isSelectedUserValue = { ...isSelectedUser };
    isSelectedUserValue.slabId = value;
    isSelectedUserValue.slabData = event;
    setIsSelectedUser(isSelectedUserValue);
  }

  const handleSave = (data) => {
    // console.log("Edit Data ---------", data)
    const payload = {
      name: data.name,
      min: data.min,
      max: data.max,
      isPerCommission: data.isPerCommission,
      commission: data.commission,
      isTdsApplicable: data.isTdsApplicable,
      serviceCharge: data.serviceCharge,
      tds: data.tdsAmount,
      slabId: data.slabId
    }

    putRequest(`${PACKAGE_UPDATE}/${isSelectedUser._id}`, payload, history).then((response) => {
      if (response.success === true) {
        toast.success(response.message);
        setIsSelectedUser({});
        setIsModalEditDetail(false);
        getDataReports();
        handleHideModal();
      } else {
        toast.error(response.message);
      }
    }).catch(function (error) {
      handleHideModal();
    })
  }

  const handleDelete = (event, id) => {
    deleteRequestNewApi(`${PACKAGE_DELETE}/${id}`, {}, history).then((response) => {
      if (response.success === true) {
        getDataReports();
        setDetailModal(false);
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
    }).catch(function (error) {
      console.log(error)
    })
  } // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    switch (serviceCode) {
      case "105":
        findOperators(MOBILE_RECHARGE_OPERATORS);
        break;
      case "106":
        findOperators(DTH_PROVIDERS);
        break;
      default: getSubOperator(); break;
      // default: setDataField([InputArray]); break;
    }
    // setDataField([...getDataField, InputArray]);
  }, [serviceCode, isFindOperator]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isOperatorData.length > 0) {
      let array = []
      isOperatorData.map(async (element) => {
        // console.log("------------------",  element.name)
        let obj = {}
        obj.min = '0';
        obj.max = '0';
        obj.isPerCommission = true;
        obj.commission = '0';
        obj.serviceCharge = '0';
        obj.isTdsApplicable = false;
        obj.tds = '0';
        obj.slab = '';
        obj.operatorId = element._id;
        obj.name = element.name;
        array.push(obj);
      })
      setDataField(array);
    }
  }, [isOperatorData]) // eslint-disable-line react-hooks/exhaustive-deps

  // useEffect(() => {
  //   if (getRoleData && getRoleData.length > 0) {
  //     let array = []
  //     getRoleData.map(async (element) => {
  //       // console.log("------------------",  element.name)
  //       let obj = {}
  //       obj.min = '';
  //       obj.max = '';
  //       obj.isPerCommission = false;
  //       obj.commission = '';
  //       obj.serviceCharge = '';
  //       obj.isTdsApplicable = false;
  //       obj.tds = '';
  //       obj.slab = '';
  //       obj.operatorId = element._id;
  //       obj.name = element.name;
  //       array.push(obj);
  //     })
  //     setDataField(array);
  //   }
  // }, [getRoleData]) // eslint-disable-line react-hooks/exhaustive-deps

  const getSubOperator = () => {
    if (serviceId) {
      let payload = { serviceId: serviceId };
      const queryString = objectToQueryString(payload);
      getRequest(`${GET_ALL_SERVICES_OPERATOR}?${queryString}`, history).then((response) => {
        if (response.success === true) {
          setOperatorData(response.data);
        }
      }).catch(function (error) {
        console.log(error)
        // setLoading(false);
      })
    }
  }

  const getRoles = () => {
    getRequest(USER_ROLE + '/2', history).then((response) => {
      if (response.success === true) {
        setRoleData(response.data.map((item) => {
          return { "value": item._id, "label": item.name };
        }));
      }
    }).catch(function (error) {
      console.log(error)
      // setLoading(false);
    })
  }

  const getSlab = () => {
    getRequest(SLABS_NAMES, history).then((response) => {
      if (response.success === true) {
        // setMobileOperatorData(response.data);
        setSlabData([...slabData, ...response.data.map((item) => {
          return { "value": item._id, "label": item.name };
        })]);
      }
    }).catch(function (error) {
      // console.log(error)
      // setLoading(false);
    })
  }

  const findOperators = (URL) => {
    getRequest(URL, history).then((response) => {
      if (response.success === true) {
        // setMobileOperatorData(response.data);
        setOperatorData(response.data);
      } else {
        // toast.error(response.message);
      }
    }).catch(function (error) {
      // console.log(error)
      // setLoading(false);
    })
  }

  const getServices = () => {
    getRequest(GET_SERVICES, history).then((response) => {
      if (response.success === true) {
        setServiceData(response.data.map((item) => {
          return { "value": item.code, "label": item.name, "isOperatorCommission": item.isOperatorCommission, "id": item._id };
        }));
      } else {
        // toast.error(response.message);
      }
    }).catch(function (error) {
      // console.log(error)
      // setLoading(false);
    })
  }
  const objectToQueryString = (obj) => {
    var str = [];
    for (var p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    return str.join("&");
  } // eslint-disable-line react-hooks/exhaustive-deps

  const params = { page: page, limit: limit };
  const getDataReports = (limit) => {
    if (limit) { setPageLimit(limit); }
    params.limit = limit ? limit : pageLimit;
    // console.log("params.limit ---------------", params.limit);
    const queryString = objectToQueryString(params);
    getRequest(`${PACKAGE_LIST}?${queryString}`, history).then((response) => {
      if (response.success === true) {
        setData(response.data);
        setTotalRecord(response.data.totalDocs);
        setPage(response.data.page);
      } else {
        setData([]);
        setTotalRecord(0);
        setPage(1);
      }
    }).catch(function (error) {
      console.log(error)
      // setLoading(false);
    })
  }

  const handlePageChange = page => {
    setPage(page);
  }

  const handleDetailPageChange = page => {
    setDetailPage(page);
  }

  const onChangeValue = (index, event) => {
    const { name, value } = event.target;
    // console.log("name -----------", name)
    // console.log("value -----------", index)
    let getDataFieldValues = [...getDataField];
    getDataFieldValues[index][name] = value;
    setDataField(getDataFieldValues);
  }

  const onChangeValueSlab = (index, event) => {
    const { value } = event;
    let getDataFieldValues = [...getDataField];
    getDataFieldValues[index].slab = value;
    getDataFieldValues[index].slabData = event;
    setDataField(getDataFieldValues);
  }

  const onChangeValueSelect = (event) => {
    console.log("event -----------", event)
    const { value, isOperatorCommission, id } = event;
    setServiceCode(value);
    setFindOperator(isOperatorCommission);
    setServiceId(id);
    // setDataField({ ...getDataField, "service": id });
  }

  const onChangeValueRole = (event) => {
    console.log("-event-----------", event)
    // const { value } = event;
    setRoleId(event);
  }

  const onChangeisPerCommission = (checked, i) => {
    let getDataFieldValues = [...getDataField];
    getDataFieldValues[i].isPerCommission = checked;
    setDataField(getDataFieldValues);
  };

  const onChangeIsTdsApplicable = (checked, i) => {
    let getDataFieldValues = [...getDataField];
    getDataFieldValues[i].isTdsApplicable = checked;
    setDataField(getDataFieldValues);
  };

  // const removeFormFields = (i) => {
  //   let getDataFieldValues = [...getDataField];
  //   getDataFieldValues.splice(i, 1);
  //   setDataField(getDataFieldValues)
  // }

  const FormValidate = () => {
    let formValid = true;
    let formObject = [];
    setDataError({})
    if (getDataField && getDataField.length === 0) {
      formValid = false;
    }

    if (!packageName) { setPackageName(''); }
    if (!serviceId) { setServiceId(''); }
    if (!roleId) { setRoleId(''); }

    getDataField.length > 0 && getDataField.map((element, index) => {
      let checkData = Object.keys(element);
      let arrAyy = {}
      checkData.forEach((ele) => {
        if (ele !== "slab" && ele !== "isTdsApplicable" && ele !== "isPerCommission" && ele !== "operatorId") {
          console.log("ele-----------------", ele)
          if (getDataField[index][ele] === "" || typeof getDataField[index][ele] === "undefined") {
            console.log("ele-arrAyy----------------", ele)
            arrAyy[ele] = true;
            formValid = false;
          }
        }
      });
      return formObject.push(arrAyy);
    })

    setDataError(formObject);
    return formValid;
  }

  const handleEditView = (data) => {
    setPackageName(data.name);
    // setPackageDetail(data);
    setDetailModal(true);
    getDataDetailReports(data.roleId, data.serviceId);
  }

  const getDataDetailReports = (roleId, serviceId) => {
    if (roleId && serviceId) {
      params.roleId = roleId;
      params.serviceId = serviceId;
      params.page = pageDetail;
      params.limit = limit;
      // console.log("params.limit ---------------", params.limit);
      const queryString = objectToQueryString(params);
      getRequest(`${PACKAGE_DETAIL_LIST}?${queryString}`, history).then((response) => {
        if (response.success === true) {
          setPackageDetailData(response.data);
          setTotalDetailRecord(response.data.totalDocs);
          setDetailPage(response.data.page);
        } else {
          setPackageDetailData([]);
          setTotalDetailRecord(0);
          setDetailPage(1);
        }
      }).catch(function (error) {
        console.log(error)
        // setLoading(false);
      })
    }
  }

  const handleSubmit = () => {
    // console.log("FormValidate ------------", FormValidate())
    if (FormValidate(true)) {
      const payload = {
        name: packageName,
        serviceId: serviceId,
        serviceCode: serviceCode,
        roleId: roleId,
        data: getDataField
      }
      postRequest(PACKAGE_SAVE, payload, history).then((response) => {
        if (response.success === true) {
          // window.location.reload();
          setDataField([]);
          setDataError({});
          clearFormField();
          getDataReports();
          toast.success(response.message);
        } else {
          toast.error(response.message);
        }
      }).catch(function (error) {
        // console.log(error)
        // setLoading(false);
      })
    }
  }

  const handleHideModal = () => {
    setDetailModal(false);
  }

  const onChangeMasterPerCommission = (checked) => {
    let getDataFieldValues = [...getDataField];
    let data = getDataFieldValues.map((element) => {
      let object = element;
      object.isPerCommission = checked;
      return object;
    })
    setDataField(data);
  };

  const onChangeMasterApplyTds = (checked) => {
    let getDataFieldValues = [...getDataField];
    let data = getDataFieldValues.map((element) => {
      // console.log("isTdsApplicable ---------", checked)
      let object = element;
      object.isTdsApplicable = checked;
      return object;
    })
    setDataField(data);
  };

  const changeMasterValue = async (e) => {
    let { name, value } = e.target;
    let count = getDataField.length;
    if (count > 0) {
      let data = getDataField.map((element) => {
        let object = element;
        object[name] = value;
        return object;
      })
      setDataField(data)
    }
  }

  const changeMasterValueSelect = async (event) => {
    // console.log("event --------?-", event)
    const { value } = event;
    let count = getDataField.length;
    if (count > 0) {
      let data = getDataField.map((element) => {
        let object = element;
        object.slab = value;
        object.slabData = event;
        return object;
      })
      setDataField(data);
    }
  }

  const onChangeEditValue = (event) => {
    const { name, value } = event.target;
    // console.log("name -----------", name)
    // console.log("value -----------", index)
    let isSelectedUserValue = { ...isSelectedUser };
    isSelectedUserValue[name] = value;
    setIsSelectedUser(isSelectedUserValue);
  }

  useEffect(() => {
    setDataField([InputArray]);
    getDataReports();
    getServices();
    getSlab();
    getRoles();
  }, [page]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getDataDetailReports();
  }, [pageDetail]) // eslint-disable-line react-hooks/exhaustive-deps

  const showingSlabData = (expandData) => (<div><div className='col-md-12 my-2 text-center'><h5>{expandData.data.roles && capitalize(expandData.data.roles.name.toLowerCase())} Slab Detail </h5></div><SlabData isDrop={false} slabId={expandData.data && expandData.data.slabId} /></div>)

  return (
    <div>
      {/* <AppHeader /> */}
      <div className="content-wrapper pt-2">
        <div className="content-header my-0 py-0">
          <div className="container-fluid">
            <div className="row mb-0 align-items-center">
              <div className="col-sm-6">
                <h1 className='page-title text-blue font-merriweather'>Packages <span className='text-red'></span></h1>
              </div>
            </div>
          </div>
        </div>
        <div className="content">
          <div className="container-fluid pt-2 pb-5">
            <div className="row">
              <div className="col-md-12">
                <div className='card rounded-0'>
                  <div className='card-body'>
                    <div className='row'>
                      <div className='col-sm-6 col-md-4 col-lg-3'>
                        <div className="form-group">
                          <label>Services</label>
                          <Select1 options={serviceData} className={`normal-light w-100 ${serviceId === "" ? "is-invalid" : ""}`} classNamePrefix="select" placeholder="Select Service" ref={serviceSelect} name="service" onChange={(e) => onChangeValueSelect(e)} />
                          {serviceId === "" && <span className="error text-danger">The field is required</span>}
                        </div>
                      </div>
                      <div className='col-sm-6 col-md-4 col-lg-3'>
                        <div className="form-group">
                          <label>User Type</label>
                          <Select options={getRoleData} mode="multiple" status={`${roleId === "" ? "error" : ""}`} className={`w-100`} classNamePrefix="select" placeholder="Select User Type" ref={roleSelect} name="roleId" onChange={(e) => onChangeValueRole(e)} />
                          {roleId === "" && <span className="error text-danger">The field is required</span>}
                        </div>
                      </div>
                      <div className='col-sm-6 col-md-4 col-lg-3'>
                        <div className="form-group">
                          <label>Package Name</label>
                          <input type="text" className={`form-control normal-light ${getDataError && getDataError.name ? "is-invalid" : ""}`} name="name" ref={nameInput} maxLength={30} onChange={(e) => setPackageName(e.target.value)} placeholder="Package Name*" value={packageName && packageName} />
                          {packageName === "" && <span className="error text-danger">The field is required</span>}
                        </div>
                      </div>
                      <div className='col-sm-6 col-md-4 col-lg-2'>
                        <label className='d-none d-lg-block'>&nbsp;</label>
                        <div className="form-group text-lg-center d-flex">
                          <button type="submit" className="btn btn-primary rounded-pill pl-4 pr-4" onClick={() => handleSubmit()}>Submit</button>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <table class="table table-striped">
                        <thead>
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col">Min {serviceCode && <input type='text' name='min' className='form-control form-control-sm' onChange={(e) => changeMasterValue(e)} placeholder='0' />}</th>
                            <th scope="col">Max {serviceCode && <input type='text' name='max' className='form-control form-control-sm' onChange={(e) => changeMasterValue(e)} placeholder='0' />}</th>
                            <th scope="col">IsComm (%) {serviceCode && <Switch defaultChecked checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} className='my-1' onChange={(e) => onChangeMasterPerCommission(e)} />}</th>
                            <th scope="col">Commission {serviceCode && <input type='text' name='commission' className='form-control form-control-sm' onChange={(e) => changeMasterValue(e)} placeholder='0' />}</th>
                            <th scope="col">Service Charge {serviceCode && <input type='text' name='serviceCharge' className='form-control form-control-sm' onChange={(e) => changeMasterValue(e)} placeholder='0' />}</th>
                            <th scope="col">Apply TDS {serviceCode && <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} className='my-1' onChange={(e) => onChangeMasterApplyTds(e)} />}</th>
                            <th scope="col">TDS {serviceCode && <input type='text' name='tds' className='form-control form-control-sm' onChange={(e) => changeMasterValue(e)} placeholder='0' />}</th>
                            <th scope="col">Slab {serviceCode && <Select1 options={slabData} className={`normal-light`} placeholder="Select Slab" name="slab" onChange={(e) => changeMasterValueSelect(e)} />}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {getDataField && getDataField.length > 0 && getDataField.map((element, index) => {
                            return <tr key={index}>
                              <td className="text-bold text-uppercase">{element.name}</td>
                              <td><input type="number" className={`form-control normal-light ${getDataError[index] && getDataError[index].min ? "is-invalid" : ""}`} name="min" ref={minInput} maxLength={5} onChange={(e) => onChangeValue(index, e)} placeholder="Min" onKeyPress={() => isNumberKey} value={element && element.min} /></td>

                              <td><input type="text" className={`form-control normal-light ${getDataError[index] && getDataError[index].max ? "is-invalid" : ""}`} name="max" ref={maxInput} maxLength={5} onChange={(e) => onChangeValue(index, e)} placeholder="Max" onKeyPress={() => isNumberKey} value={element && element.max} /></td>

                              <td><Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} checked={element && element.isPerCommission} onChange={(e) => onChangeisPerCommission(e, index)} /></td>

                              <td><input type="text" className={`form-control normal-light ${getDataError[index] && getDataError[index].commission ? "is-invalid" : ""}`} name="commission" ref={commissionInput} maxLength={5} onChange={(e) => onChangeValue(index, e)} placeholder="Commission" onKeyPress={() => isNumberKey} value={element && element.commission} /></td>

                              <td><input type="text" className={`form-control normal-light ${getDataError[index] && getDataError[index].serviceCharge ? "is-invalid" : ""}`} name="serviceCharge" ref={serviceChargeInput} maxLength={5} onChange={(e) => onChangeValue(index, e)} placeholder="Service Charge" onKeyPress={() => isNumberKey} value={element && element.serviceCharge} /></td>

                              <td><Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} checked={element && element.isTdsApplicable} onChange={(e) => onChangeIsTdsApplicable(e, index)} /></td>

                              <td><input type="text" className={`form-control normal-light ${getDataError[index] && getDataError[index].tds ? "is-invalid" : ""}`} name="tds" ref={tdsInput} maxLength={5} onChange={(e) => onChangeValue(index, e)} placeholder="TDS Amount" onKeyPress={() => isNumberKey} value={element && element.tds} /></td>
                              <td>
                                <Select1 options={slabData} className={`normal-light ${getDataError[index] && getDataError[index].slab ? "is-invalid" : ""}`} classNamePrefix="select" placeholder="Select Slab" ref={slabSelect} name="slab" onChange={(e) => onChangeValueSlab(index, e)} value={element && element.slabData} />
                              </td>
                              {/* {index ? <td><button type="button" className="button remove" onClick={() => removeFormFields(index)}>Remove</button></td> : <td></td>} */}
                            </tr>
                          })}
                        </tbody>
                      </table>
                    </div>

                    <div className="content-header my-0 px-0">
                      <h1 className='page-title text-blue font-merriweather mb-3'>Package <span className='text-red'>List</span></h1>
                    </div>

                    <div className='history-table'>
                      <div className="container-fluid pt-2 pb-5">
                        <div className="col-md-12">
                          <div className="box-body">
                            <DataTable
                              title={""}
                              columns={columns}
                              data={getData.docs}
                              highlightOnHover
                              pagination
                              paginationServer
                              paginationTotalRows={getTotalRecord}
                              onChangeRowsPerPage={getDataReports}
                              onChangePage={handlePageChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer position="top-center" autoClose={5000} limit={2} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="colored" />
        {isDetailModal && <Modal title={packageName + " Package Detail "} width={1500} open={isDetailModal} onOk={""} onCancel={handleHideModal} footer={null}>
          <div className="col-md-12">
            <div className="box-body">
              <DataTable
                title={""}
                columns={columnsDetail}
                data={packageDetailData.docs}
                highlightOnHover
                pagination
                paginationServer
                expandableRows
                expandableRowsComponent={showingSlabData}
                // expandableRowsComponentProps={''}
                paginationTotalRows={getTotalDetailRecord}
                onChangeRowsPerPage={getDataDetailReports}
                onChangePage={handleDetailPageChange}
              />
            </div>
          </div>
        </Modal>}
        {isModalEditDetail && <Modal title={"Edit Data"} width={800} open={isModalEditDetail} onOk={() => handleSave(isSelectedUser)} onCancel={() => setIsModalEditDetail(false)} okText={"Save"} cancelText={"Cancel"}>
          <div className='row'>
            <div className='col-md-12'>
              <div className="form-group col-12">
                <label>Package Name: </label>
                <input type="text" className={`form-control normal-light`} name="name" maxLength={30} onChange={(e) => onChangeEditValue(e)} placeholder="Name" value={isSelectedUser && isSelectedUser.name} />
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-12 d-flex'>
              <div className="form-group col-md-6">
                <label>Min: </label>
                <input type="number" className={`form-control normal-light`} name="min" maxLength={10} onChange={(e) => onChangeEditValue(e)} placeholder="Min" onKeyPress={(e) => isNumberKey(e.target, e)} value={isSelectedUser && isSelectedUser.min} />
              </div>
              <div className="form-group col-md-6">
                <label>Max: </label>
                <input type="number" className={`form-control normal-light`} name="max" maxLength={10} onChange={(e) => onChangeEditValue(e)} placeholder="Max" onKeyPress={(e) => isNumberKey(e.target, e)} value={isSelectedUser && isSelectedUser.max} />
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-12 d-flex'>
              <div className="form-group col-md-6">
                <label>Is Commission(%): </label>
                <div className='text-right col-md'>
                  <Radio.Group
                    options={[
                      {
                        label: 'Flat',
                        value: false,
                      },
                      {
                        label: '%',
                        value: true,
                      }]}
                    onChange={onChangeFlatCommission}
                    value={isSelectedUser.isPerCommission}
                    optionType="button"
                    buttonStyle="solid"
                  />
                </div>
              </div>
              <div className="form-group col-md-6">
                <label>Commission: </label>
                <input type="number" className={`form-control normal-light`} name="commission" maxLength={10} onChange={(e) => onChangeEditValue(e)} placeholder="Commission" onKeyPress={(e) => isNumberKey(e.target, e)} value={isSelectedUser && isSelectedUser.commission} />
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-12 d-flex'>
              <div className="form-group col-md-6">
                <label>Is TDS Applicable: </label>
                <div className='text-right col-md'>
                  <Radio.Group
                    options={[
                      {
                        label: 'NO',
                        value: false,
                      }, {
                        label: 'YES',
                        value: true,
                      }]}
                    onChange={onChangeBankPercentageStatus}
                    value={isSelectedUser.isTdsApplicable}
                    optionType="button"
                    buttonStyle="solid"
                  />
                </div>
              </div>
              <div className="form-group col-md-6">
                <label>TDS: </label>
                <input type="number" className={`form-control normal-light`} name="tdsAmount" maxLength={10} onChange={(e) => onChangeEditValue(e)} placeholder="TDS" onKeyPress={(e) => isNumberKey(e.target, e)} value={isSelectedUser && isSelectedUser.tdsAmount} />
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-12 d-flex'>
              <div className="form-group col-md-6">
                <label>Service Charge: </label>
                <input type="number" className={`form-control normal-light`} name="serviceCharge" maxLength={10} onChange={(e) => onChangeEditValue(e)} placeholder="Service Charge" onKeyPress={(e) => isNumberKey(e.target, e)} value={isSelectedUser && isSelectedUser.serviceCharge} />
              </div>
              <div className="form-group col-md-6">
                <label>Slab: </label>
                <Select1 options={slabData} defaultValue={slabData[editSlabSelectedIndex]} className={`normal-light`} placeholder="Select Slab" name="slab" onChange={(e) => onChangeValueEditSlab(e)} />
              </div>
            </div>
          </div>
        </Modal>}
      </div >
    </div >
  )
}

export default Package;