import React, { useEffect, useState, useRef } from 'react';
import Maker from './maker';
import { getUtilityOfflineTypes } from './../../../Utils/Common';
// import { useHistory } from "react-router-dom";
// import { /* ToastContainer, */ toast } from 'react-toastify';
// import { USER_ROLE } from './../../../Utils/appConstants';
// import { getRequest } from './../../../Utils/AppApi';
import moment from 'moment';
// import AppFooter from './../../Footer/Footer';
import { DatePicker, Input, Button, Select} from 'antd';
const { RangePicker } = DatePicker;

function IndexApp(props) {
    const dateFormat = 'DD-MM-YYYY';
    const modeSelected = useRef(null);
    // const dateRangeClearRef = useRef(null);
    const [getReportMode, setReportMode] = useState([]);
    const [getRole, setRole] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [dateRange, changeDateRange] = useState(null);
    // const [date, setDate] = useState(null);
    // const [roleOrderExist, setRoleOrderExist] = useState(user.data.role.order)

    const [checkSubmit, setCheckSubmit] = useState(false);
    const [search, setSearch] = useState(null);

    const handleClear = () => {
        setRole([])
        setSearch(null)
        setStartDate(null)
        setEndDate(null)
        changeDateRange(null);
        setCheckSubmit(false)
    }

    const onChangeDatePickerValue = (dates, dateStrings) => {
        setStartDate(dateStrings[0]);
        setEndDate(dateStrings[1]);
        if (dateStrings) {
            changeDateRange(returnMomentDateRange(dateStrings[0], dateStrings[1]));
        } else {
            changeDateRange(null);
        }
    }

    const returnMomentDateRange = (start, finish) => {
        return [moment(start, "DD-MM-YYYY"), moment(finish, "DD-MM-YYYY")];
    };

    const onChangeType = (value) => {
        // const { value } = event;
        // console.log(`selected ${value}`);
        setRole(value);
        setCheckSubmit(false)
    }

    const onSearch = (value) => {
        // console.log(`selected ${value}`);
        setSearch(value)
    }

    const handleSearch = () => {
        // console.log("new Date() ====",new Date())
        // setDate(new Date());
        setCheckSubmit(true)
        // setTimeout(() => {
        //     setCheckSubmit(false)
        // }, 1000);
    }

    useEffect(() => {
        // setReportMode(uitilityReports);
        setReportMode(getUtilityOfflineTypes);
        // setRoleOrderExist(user.data.role.order);
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            <div className="content-wrapper bg-white pt-4">
                <div className="content-header my-0 py-0">
                    <div className="container-fluid mb-3 ml-2">
                        <div className="row mb-0 align-items-center">
                            <div className="col-sm-6">
                                <h1 className='page-title text-blue font-merriweather'>Utility <span className='text-red'> Payment Maker</span></h1>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid">
                        <div className="row mb-2 align-items-center">
                            <div className="col-sm-12 d-flex">
                                <div className="input-group col-sm-2 ">
                                    <label className="form-label">Uitility Type:</label>
                                    <Select options={getReportMode} className={`normal-light w-100`} classNamePrefix="select" placeholder="Select a Type" ref={modeSelected} name='mode' onChange={(e) => onChangeType(e)} value={getRole ? getRole : []} />
                                </div>
                                <div className="input-group col-sm-3 pull-right">
                                    <label>Date Range:</label>
                                    <RangePicker format={dateFormat} allowClear={false} onChange={onChangeDatePickerValue} className="col-12" value={dateRange} />
                                </div>
                                <div className="input-group col-sm-3 pull-right">
                                    <label className="form-label">Search:</label>
                                    <div className='col-md-12'>
                                        <Input placeholder="Search ...." onChange={(e) => onSearch(e.target.value)} style={{ width: "100%" }} value={search} />
                                    </div>
                                </div>
                                <div className="input-group col-md-2 pull-right">
                                    <label className="form-label">&nbsp;</label>
                                    <div className='col-md-12'>
                                        <Button type="button" onClick={handleSearch} >Search</Button>
                                        <Button type="default" onClick={handleClear} className='ml-2'>Clear</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <div className="content">
                    { checkSubmit && <Maker mode={getRole} startDate={startDate} endDate={endDate} search={search} handleSearch={handleSearch}/> }
                </div>
            </div>
        </div>
    )
}

export default IndexApp