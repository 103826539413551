import React, { useEffect, useState } from 'react';
import { getUser, removeUserSession, updateUserSession } from '../../Utils/Common';

import { ToastContainer, toast } from 'react-toastify';
import { getRequest, postRequest } from '../../Utils/AppApi';
import { getTopBarMenu } from '../../Utils/Menu';
import { useHistory } from 'react-router-dom';
// import ShopIndex from './../shop/index';
import BankSetting from './../banksetting';

import { GET_BANK_SETTING_LIST, PANCARD_VERIFY, AADHARCARD_OTP_VERIFY, AADHARCARD_VERIFY, GET_MYPROFILE, GET_SHOP_REGISTERD } from '../../Utils/appConstants';
import { Modal, Button, Space, Divider } from 'antd';
const TopMenu = getTopBarMenu();
const user = getUser();

const Header = (props) => {
  const history = useHistory();
  const [menu, setMenu] = useState(getTopBarMenu());
  const [role, setRole] = useState("");
  const [getAgentOfficialId, setAgentOfficialId] = useState("");
  const [isPancardVerify, setIsPancardVerify] = useState(false);
  const [pancardNumber, setPancardNumber] = useState(null);
  const [isAadharCardVerify, setIsAadharCardVerify] = useState(false);
  const [aadharNumber, setAadharNumber] = useState(null);
  const [isOtp, setIsOtp] = useState(false);
  const [isOtpNumber, setIsOtpNumber] = useState(null);
  const [isRefId, setIsRefId] = useState(null);
  const [walletBalance, setWalletBalance] = useState(null);
  const [ecollect, setEcollect] = useState(false);
  const [aadharcardVerifyFailed, setAadharcardVerifyFailed] = useState(false);
  const [aadharcardVerifyFailedMessage, setAadharcardVerifyFailedMessage] = useState("");
  const [shopRegisteredStatusMessage, setShopRegisteredStatusMessage] = useState(false)
  const [checkShopRegistered, setCheckShopRegistered] = useState(false)
  const [bankDetailData, setBankDetailData] = useState([]);
  const [getBankDetailModal, setBankDetailModal] = useState(false)
  // const [refreshInterval, setRefreshInterval] = useState(0);
  const [userData, setUserData] = useState(null);
  // const navigate = useNavigate();

  useEffect(() => {
    if (user && user.data && user.data.role && user.data.role.code === "P") {
      getBankDetailList()
      getShopRegister()
    }
    if (user && user.data && user.data.role && user.data.role.code !== "A" && user.data.role.code !== "SA") {
      // console.log("user.data.userDetails -----", user.data.kycStatus)
      if (!user.data.isAdminApprovedStatus) {
        // history.push("/kyc-details");
        // window.location.href = '/kyc-details';
        // window.location.href = '/dashboard';
      } else if (!user.data.userDetails || user.data.kycStatus === 0 || user.data.kycStatus === 2) {
        // history.push("/kyc-details");
        // window.location.href = '/kyc-details';
        // window.location.href = '/dashboard';
      } else if (user.data.userDetails && !user.data.userDetails.pancardNumberVerify) {
        setIsPancardVerify(true);
        setPancardNumber(user.data.userDetails.pancardNumber)
      } else if (user.data.userDetails && !user.data.userDetails.aadharNumberVerify) {
        setIsAadharCardVerify(true);
        setAadharNumber(user.data.userDetails.aadharNumber)
      }
    }

    setRole(user && user.data && user.data.role.code);
  }, [userData]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (menu && menu.length <= 0) {
      // console.log("-----------userData ------------")
      setMenu(TopMenu);
    }
  }, [menu]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (userData && userData.KYCStatus !== 3) {
      // console.log("-----------userData ------------", userData)
      // console.log("-----------walletBalance ------------", walletBalance)
      // console.log("-----------error ------------", userData.KYCStatus)
      // console.log("-----------error ------------", JSON.stringify(menu))
      const newArray = menu.filter((obj) => obj.text !== "Digital Optimization");
      // console.log("-----------error ------------", fArray)
      setMenu(newArray);
    }
  }, [userData]) // eslint-disable-line react-hooks/exhaustive-deps

  const getBankDetailList = () => {
    getRequest(`${GET_BANK_SETTING_LIST}`, history).then(async (response) => {
      if (response.success === true) {
        setBankDetailData(response.data && response.data.docs);
      }
    }).catch(function (error) {
      console.log(error)
      // setLoading(false);
    })
  } // eslint-disable-line react-hooks/exhaustive-deps

  const getShopRegister = () => {
    getRequest(GET_SHOP_REGISTERD, history).then((response) => {
      if (response.success === true) {
        setCheckShopRegistered(false)
        if (response.data.status === 1) {
          setShopRegisteredStatusMessage("Your shop registration is in process. Please wait untill approval.");
        }
        if (response.data.status === 3) {
          setShopRegisteredStatusMessage(response.data.rejectReason);
          setCheckShopRegistered(true);
        }
      } else {
        setCheckShopRegistered(true);
      }
    }).catch(function (error) {
      // console.log(error)
      // setLoading(false)
    })
  } // eslint-disable-line react-hooks/exhaustive-deps

  const getProfile = async () => {
    await getRequest(GET_MYPROFILE, history).then((response) => {
      if (response.success === true) {
        // console.log("response.data[0]---------------", response.data)
        setUserData(response.data[0])
        user.data.userDetails = response.data[0].userdetail;
        user.data.kycStatus = response.data[0].KYCStatus;
        user.data.isAdminApprovedStatus = response.data[0].isAdminApprovedStatus;
        user.data.walletBalance = response.data[0].walletBalance;
        user.data.capitalAmount = response.data[0].capitalAmount;
        user.data.isStaff = response.data[0].isStaff;
        user.data.walletBalance.khatabookOS = response.data[0].khatabookOS ? response.data[0].khatabookOS : 0;
        setWalletBalance(response.data[0].walletBalance)
        setAgentOfficialId(response.data[0].username)
        updateUserSession(user);
      } else {
        toast.error(response.message);
      }
    }).catch(function (error) {
      console.log("error------------", error)
    })
  }

  const handlePancardVerify = () => {
    const payload = { pancard: pancardNumber };
    postRequest(`${PANCARD_VERIFY}`, payload, history).then((response) => {
      if (response.success === true) {
        setIsPancardVerify(false)
        user.data.userDetails.pancardNumberVerify = true;
        updateUserSession(user)
        toast.success(response.message);
        setIsPancardVerify(false)
        if (!user.data.userDetails.aadharNumberVerify) {
          setIsAadharCardVerify(true);
        }
      } else {
        toast.error(response.message);
      }
    }).catch(function (error) {
      // console.log(error)
      // setLoading(false);
    })
  }

  const handleAadharCardOtpVerify = () => {
    if (isOtp) {
      const payload = { aadharcard: aadharNumber, aadharcardOtp: isOtpNumber, ref_id: isRefId };
      postRequest(`${AADHARCARD_VERIFY}`, payload, history).then((response) => {
        if (response.success === true || response.alreadysuccess === true) {
          setIsOtp(false)
          setIsAadharCardVerify(false)
          user.data.userDetails.aadharNumberVerify = true;
          updateUserSession(user)
          toast.success(response.message);
        } else {
          if (!response.aadharNumber || !response.alreadysuccess) {
            setAadharcardVerifyFailedMessage(response.message);
            setAadharcardVerifyFailed(true);
            toast.error(response.message);
          } else {
            setIsOtp(false);
            toast.error(response.message);
          }
        }
      }).catch(function (error) {
        setIsAadharCardVerify(false)
      })
    } else {
      const payload = { aadharcard: aadharNumber };
      postRequest(`${AADHARCARD_OTP_VERIFY}`, payload, history).then((response) => {
        if (response.alreadysuccess === true) {
          setIsOtp(false)
          setIsAadharCardVerify(false)
          user.data.userDetails.aadharNumberVerify = true;
          updateUserSession(user)
          toast.success(response.message);
        } else if (response.success === true) {
          setIsOtp(true);
          setIsRefId(response.data.ref_id);
          toast.success(response.message);
        } else {
          toast.error(response.message);
        }
      }).catch(function (error) {
        // console.log(error)
        // setLoading(false);
      })
    }
  }

  const onChangeValue = (event) => {
    const { name, value } = event.target;
    if (name === "otpNumber") {
      setIsOtpNumber(value)
    }
  }

  const handleInstantTopup = () => {
    history.push("/instant-topup");
  }

  const handleFundRequest = () => {
    history.push("/fund-request");
  }

  const redirect = (url) => {
    history.push("/" + url);
  }

  const handleLogout = () => {
    removeUserSession();
    window.location.href = '/login';
  }

  // const handleOpenECollect = () => {
  //   setEcollect(true);
  // }

  const handleOpenECollectClose = () => {
    setAadharcardVerifyFailed(false)
    setEcollect(false);
  }

  useEffect(() => {
    if (!userData) {
      getProfile()
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <div className='header-outer'>
        <div className='content-header main-header top-header'>
          <div className='container-fluid'>
            <div className='logo'>
              <a href="/login" className="navbar-brand">
                <img src="../../logo.png" alt="AdminLTE Logo" className="brand-image" style={{ opacity: '.8' }} />
              </a>
            </div>
            <div style={{ fontStyle: "italic" }}>
              {user && user.data.role.code === "A" ?
                <li><b>Welcome</b>, {userData && userData.name}</li>
                :
                <li><b>Welcome</b>, {userData && userData.userdetail && userData.userdetail.firstName}</li>
              }
              <li>User ID : {getAgentOfficialId}</li>
              <li>User Type: {user && user.data.role.name}</li>
            </div>
            <div className={user && user.data.role.code === "A" ? "col-md-8" : "col-md-9"}>
              <div className='header-right'>
                {!(userData && userData.isStaff) && walletBalance &&
                  <><div className='header-top-links ml-4'>
                    {walletBalance && walletBalance.operativeBalance !== 0 && (
                      <div className='header-link-col'>
                        <div className='nav-link'> <i class="fas fa-wallet mr-1"></i> <span class="header-text">Op. Bal({(walletBalance) ? Number(walletBalance.operativeBalance).toFixed(2) : 0})</span></div>
                      </div>)}
                    <div className='header-link-col'>
                      <div className='nav-link'> <i class="fas fa-wallet mr-1"></i> <span class="header-text">Wallet({(walletBalance) ? walletBalance.balance : 0})</span></div>
                    </div>
                    <div className='header-link-col'>
                      <div className='nav-link'> <i class="fas fa-wallet mr-1"></i> <span class="header-text">Res. Balance({(walletBalance) ? walletBalance.reserveBalance?.toFixed(2) : 0})</span></div>
                    </div>
                    <div className='header-link-col'>
                      <div className='nav-link'> <i class="fas fa-wallet mr-1"></i> <span class="header-text">Earning ({(walletBalance) ? walletBalance.earning?.toFixed(2) : 0})</span></div></div>
                    <div className='header-link-col'>
                      <div className='nav-link'> <i class="fas fa-wallet mr-1"></i> <span class="header-text">Remaining Payout({(walletBalance) ? walletBalance.remainingPayout?.toFixed(2) : 0})</span></div>
                    </div>
                    <div className='header-link-col'>
                      <div className='nav-link'> <i class="fas fa-wallet mr-1"></i> <span class="header-text">Khata ({(walletBalance) ? walletBalance.khatabookOS?.toFixed(2) : 0})</span></div>
                    </div>
                  </div>

                    <div className='d-flex flex-wrap header-buttons '>
                      {/* <div className='nav-link'>
                    <span class="header-text">User Type: <b><u>{user.data.role.name}</u></b></span>
                  </div> */}
                      <div className='nav-link'>
                        <a href='/request-money' rel="noreferrer">
                          <button type="button" className="btn "><i className="fas fa-money-bill-wave mr-1"></i> Request Money</button>
                        </a>
                      </div>
                      <div className='nav-link'>
                        <button type="button" className="btn qr-btn"><i className="fas fa-qrcode mr-1"></i> UPI QR</button>
                      </div>
                      {/* <div className='nav-link' onClick={() => handleOpenECollect()}>
                        <button type="button" className="btn ecollect-btn pointer"><i className="fas fa-database mr-1"></i>E-Collect</button>
                      </div> */}
                      <div className='nav-link'>
                        <button type="button" className="btn topup-btn" onClick={() => handleInstantTopup()}><i className="fas fa-database mr-1"></i> Instant Topup</button>
                      </div>
                      {role && role !== "A" && <div className='nav-link'>
                        <button type="button" className="btn fund-btn" onClick={() => handleFundRequest()}><i className="fas fa-user mr-1"></i> Fund Request</button>
                      </div>}
                    </div></>}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer position="top-center" autoClose={5000} limit={2} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="colored" />
      <nav className="main-header navbar navbar-expand-md navbar-dark navbar-blue main-menu">
        <div className="container-fluid">
          <button className="navbar-toggler order-1" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse order-3" id="navbarCollapse">
            <ul className="navbar-nav px-4">
              {menu && menu.map((element, i) => {
                return (
                  <><li key={element.text + i} className="nav-item dropdown">
                    <a href="/#" data-toggle="dropdown" aria-expanded="false" className="nav-link dropdown-toggle" >{element.text}</a>
                    <ul aria-labelledby="dropdownSubMenu1" className="dropdown-menu border-0 shadow">
                      {element.submenu.length > 0 && element.submenu.map((ele, index) => (
                        <>
                          {ele.submenu && ele.submenu.length > 0 ?
                            <li key={ele.text+index} className="dropdown-submenu dropdown-hover">
                              <a key={ele.text+index+"a"} id="dropdownSubMenu2" href={ele.route} role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="dropdown-item dropdown-toggle">{ele.text} {ele.route}</a>
                              <ul aria-labelledby="dropdownSubMenu2" className="dropdown-menu border-0 shadow">
                                {ele.submenu.map((subelement, index1) => (
                                  (user.data.kycStatus !== 3 && (subelement.text.includes('Money Transfer') || subelement.text.includes('Aeps History') || subelement.text.includes('Settle')) ? <></> :
                                    <><li key={subelement.text+index1}><a tabIndex={-1} href={subelement.route} target={subelement.target ? "_blank" : "_self"} rel="noreferrer" className="dropdown-item">{subelement.text}</a></li></>)
                                ))}
                              </ul>
                            </li> : (
                              <li><a href={ele.route} className="dropdown-item">{ele.text}</a></li>
                            )}
                        </>
                      ))}
                    </ul>
                  </li></>
                )
              })
              }
            </ul>
          </div>
          <ul className="order-1 order-md-3 navbar-nav navbar-no-expand ml-auto">
            {user && user.data && user.data.role && user.data.role.code === "D" &&
              <><li className="nav-item" title='Sales Dashboard'>
                <button className="nav-link" onClick={() => redirect('distributor-sales-dashboard')} >
                  <i className="fa fa-windows"></i>
                </button>
              </li>
                {/* <li className="nav-item" title='Home Page'>
              <button className="nav-link" onClick={() => redirect('home')} >
                <i className="fas fa-home"></i>
              </button>
            </li> */}
              </>}
            <li className="nav-item" title='Dashboard'>
              <button className="nav-link" onClick={() => redirect('dashboard')}>
                <i class="fa fa-tachometer" aria-hidden="true"></i>
              </button>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="tel:9813084030" title='9813084030' >
                <i className="fas fa-phone-alt"></i>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/#" title='Support@srpay1.com' >
                <i className="fas fa-envelope"></i>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/login" onClick={() => handleLogout()} >
                <i className="fas fa-sign-out-alt"></i>
              </a>
            </li>
          </ul>
        </div>
        {isPancardVerify && (<Modal
          title="Pancard Verification"
          centered
          open={isPancardVerify}
          onOk={() => handlePancardVerify()}
        >
          <div className="input-group">
            <div className="custom-file">
              <input type="text" className="form-control" name="pancard" placeholder="Pancard Number" defaultValue={userData && userData.userdetail && userData.userdetail.pancardNumber} maxLength={10} disabled />
            </div>
          </div>
        </Modal>)
        }

        {isAadharCardVerify && (<Modal
          title="Aadhar Card Verification"
          centered
          open={isAadharCardVerify}
          onOk={() => handleAadharCardOtpVerify()}
        >
          <div className="input-group">
            <div className="custom-file">
              <input type="text" className="form-control" name="aadharNumber" placeholder="Aadharcard Number" defaultValue={userData && userData.userdetail && userData.userdetail.aadharNumber} maxLength={12} disabled />
            </div>
          </div>
          <hr />
          {isOtp &&
            <div className="input-group">
              <div className="custom-file">
                <input type="text" className="form-control" name="otpNumber" placeholder="OTP Number" defaultValue={""} maxLength={6} onChange={(e) => onChangeValue(e)} />
              </div>
            </div>
          }
        </Modal>)
        }{ecollect && (<Modal
          open={ecollect}
          onCancel={() => handleOpenECollectClose()}
          footer={false}
        >
          <Space
            direction="vertical"
            style={{
              width: '100%',
            }}
            className='mt-20'
          >
            <a target='_blank' rel="noreferrer" href='https://www.onlinesbi.sbi/sbicollect/icollecthome.htm?corpID=856614'><Button type="primary" block> SBI Collect </Button></a>
            <Button type="primary" block> PNB Collect </Button>
            <Button type="primary" block> UNION Collect </Button>
          </Space>
        </Modal>)
        }
        {aadharcardVerifyFailed && (<Modal
          open={aadharcardVerifyFailed}
          onCancel={() => handleOpenECollectClose()}
          footer={false}
        >
          <Space direction="vertical" style={{ width: '100%', }} className='mt-20'>
            <p className='text-danger text-center'><b>{aadharcardVerifyFailedMessage}</b></p>
          </Space>
        </Modal>)
        }
        {checkShopRegistered && <Modal title={"KYC Pending"} width={600} open={checkShopRegistered} onCancel={() => handleLogout()} footer={false} >
          {shopRegisteredStatusMessage && <p className="text-center text-danger">{shopRegisteredStatusMessage}</p>}
          {bankDetailData.length === 0 && <div className="mb-3 text-right"><button onClick={() => setBankDetailModal(true)} className='btn btn-primary'>Get Bank Details</button></div>}
          {/* <ShopIndex shopregistered={shopregistered} /> */}
          <p className='text-danger text-center'><b>Dear SR PAY Private Limited Partner, आपका केवाईसी अभी तक पूरा नहीं हुआ है। कृपया प्ले स्टोर से SR PAY Private Limited Partner ऐप डाउनलोड करें और अपना KYC पूरा करें।</b></p>
          <Divider />
          <p className='text-danger text-center'><b>Dear SR PAY Private Limited Partner, Your KYC is incomplete. Please Download SR PAY Private Limited Partner App from Play store and process KYC.</b></p>
        </Modal>}

        {getBankDetailModal && <Modal title={"Bank Detail"} width={1400} open={getBankDetailModal} onCancel={() => setBankDetailModal(false)} footer={false} >
          <BankSetting />
        </Modal>}

        {/* {shopRegisteredStatusMessage && <Modal title={"Shop Registration"} width={600} open={shopRegisteredStatusMessage} onCancel={() => setShopRegisteredStatusMessage(false)} footer={false} >
                <p className="text-center text-info">{shopRegisteredStatusMessage}</p>
            </Modal>} */}
      </nav>
    </div>
  );
}

export default Header;