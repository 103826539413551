import React, { useEffect, useState, useRef } from 'react';
import { getUser, isNumber, ekoPartnerEkycStatus } from './../../Utils/Common';
import PropagateLoader from "react-spinners/PropagateLoader";
import axios from 'axios';
import xmlJs from 'xml-js';
// import { Tabs, Tab } from 'react-bootstrap-tabs';
import { POST_AEPS_EKYC_INITIATE, POST_AEPS_EKYC_VALIDATE, GET_SHOP_REGISTERD, GET_BIO_DEVICE_LIST, POST_AEPS_EKYC_EKO_STEP_1, POST_AEPS_EKYC_EKO_STEP_2, POST_AEPS_EKYC_EKO_STEP_3, POST_AEPS_EKYC_EKO_STEP_4, POST_AEPS_EKYC_EKO_STEP_5, POST_AEPS_EKYC_EKO_STEP_6, GET_AEPS_DEVICE_CONFIG, GET_BANK_SETTING_LIST } from './../../Utils/appConstants';
import { getRequest, postRequest } from './../../Utils/AppApi';
// import AppHeader from './../Header/Header';
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import AfterSubmit from './../../container/partnerEkyc';
import ShopIndex from './../shop/index';
import { Divider, Modal, Checkbox, Select } from 'antd';
import BankSetting from '../banksetting';
import FingPay from './fingPay';
// import { reactLocalStorage } from 'reactjs-localstorage';
// import moment from 'moment';

function PartnerEkyc() {
    // console.log("user------", user.data)
    const user = getUser();
    const [otpData, setOTPData] = useState({})
    const [isContinue, setContinue] = useState(false)
    const [getBankDetailModal, setBankDetailModal] = useState(false)
    const [getBioDeviceData, setGetBioDeviceData] = useState([])
    const [serviceDeviceModel, setServiceDeviceModel] = useState(false)
    const [isAuthAePSModel, setAuthAePSModel] = useState(false);
    const [shopRegisteredStatusMessage, setShopRegisteredStatusMessage] = useState(false)

    const [checkShopRegistered, setCheckShopRegistered] = useState(false)
    const [checkKycAlreadyExist, setCheckKycAlreadyExist] = useState(null)
    const [checkKycEkoAlreadyExist, setCheckKycEkoAlreadyExist] = useState(null)
    const history = useHistory();
    const [getDataField, setDataField] = useState([]); // eslint-disable-line react-hooks/exhaustive-deps
    const [getDataError, setDataError] = useState({}); // eslint-disable-line react-hooks/exhaustive-deps
    const [isModalAepsKyc, setModalAepsKyc] = useState(false);
    const [isModalEkoAepsKyc, setModalEkoAepsKyc] = useState(false);
    const [isOtpModel, setOtpModel] = useState(false);
    const [consent, setConsent] = useState("N");
    const [latitude, setLatitude] = useState("");
    const [longitude, setLongitude] = useState("");
    const [isHash, setHashData] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [otpReferenceID, setOtpReferenceID] = useState(null);
    const [isSubmit, setSubmit] = useState(false);
    const [status, setStatus] = useState(null);
    const [statusMessage, setStatusMessage] = useState(null);
    const [devicenumber, setDeviceNumber] = useState(null);
    const [modelname, setModelName] = useState(null);

    const [pancardPhoto, setPancardPhoto] = useState(null);
    const [aadhaarCardFront, setAadhaarCardFront] = useState(null);
    const [aadhaarCardBack, setAadhaarCardBack] = useState(null);

    const [fingerprint, setFingerprint] = useState(null)
    const [deviceConfig, setDeviceConfig] = useState({});
    const [bankDetailData, setBankDetailData] = useState([]);
    // const [bankDetailDataDefault, setBankDetailDefault] = useState(null);

    const [isOtpModal, setOtpModal] = useState(false);
    const [otpCode, setOtpCode] = useState(null)
    const mobileInput = useRef(null);
    const otpNumberRef = useRef(null)

    const getBankDetailList = () => {
        // const queryString = objectToQueryString(params);
        getRequest(`${GET_BANK_SETTING_LIST}`, history).then(async (response) => {
            if (response.success === true) {
                setBankDetailData(response.data.docs);
                let defaultbank = await response.data.docs.filter(item => item.isDefault === true);
                // console.log("defaultbank --------------", defaultbank[0])
                if(defaultbank[0]) {
                    setDataField({ ...getDataField, "accountNumber": defaultbank[0].accountNumber, "ifsccode": defaultbank[0].ifscCode });
                    // setBankDetailDefault(defaultbank[0]);
                }
            } else {
            }
        }).catch(function (error) {
            console.log(error)
            // setLoading(false);
        })
    } // eslint-disable-line react-hooks/exhaustive-deps

    const getBioDeviceList = () => {
        getRequest(GET_BIO_DEVICE_LIST + "?isWeb=true", history).then((response) => {
            if (response.success === true) {
                setGetBioDeviceData(response.data.map((element) => {
                    // return { label: element.name, value: element.id }
                    return { label: <div className="d-flex"><img class="rounded-circle" width={"10%"} alt="device" src={element.image} /><p className="fa-10x fs ml-15 text-110">{element.name}</p></div>, value: element.id }
                }))
            } else {
                toast.error(response.message);
                // setIsProfileCheck(false);
            }
        }).catch(function (error) {
            // console.log(error)
        })
    }

    const handleChange = async () => {
        await axios({
            method: 'DEVICEINFO',
            url: deviceConfig.captureUrl,
            headers: { "Content-Type": "text/xml; charset=UTF-8" },
            data: deviceConfig.xmlData
        }).then(response => {
            // console.log("00----------------------", response.data)
            const jsonData = xmlJs.xml2json(response.data, { compact: true, spaces: 4 });
            let XMLjson = JSON.parse(jsonData);

            setModelName(XMLjson.DeviceInfo._attributes.mi)
            setDeviceNumber(XMLjson.DeviceInfo.additional_info.Param[0]._attributes.value)
        }).catch(error => {
            toast.error("Please connect biometric device in your system")
            // console.log("error -----------", error)
        })

        // await fetch(deviceConfig.infoUrl, {
        //     method: "DEVICEINFO",
        //     mode: "cors",
        //     headers: {
        //         "Content-Type": "text/xml; charset=UTF-8",
        //         // "Access-Control-Allow-Methods": "RDSERVICE,DEVICEINFO,CAPTURE"
        //     },
        //     body: deviceConfig.xmlData
        // }).then(res => {
        //     if (res.status === 200) {
        //         // const jsonData = xmlJs.xml2json(res.data, { compact: true, spaces: 4 });
        //         // let XMLjson = JSON.parse(jsonData);
        //         console.log("XMLjson ---------", res)
        //         // setModelName(XMLjson.DeviceInfo._attributes.mi)
        //         // setDeviceNumber(XMLjson.DeviceInfo.additional_info.Param[0]._attributes.value)
        //     } else {
        //         // alert("error");
        //     }
        // })
    };

    const getShopRegister = () => {
        getRequest(GET_SHOP_REGISTERD, history).then((response) => {
            if (response.success === true) {
                setCheckShopRegistered(false)
                if (response.data.status === 1) {
                    setShopRegisteredStatusMessage("Your shop registration  is in process. Please wait untill approval.");
                }
                if (response.data.status === 3) {
                    setShopRegisteredStatusMessage(response.data.rejectReason);
                    setCheckShopRegistered(false)
                }
            } else {
                setCheckShopRegistered(false)
            }
        }).catch(function (error) {
            // console.log(error)
            // setLoading(false)
        })

    } // eslint-disable-line react-hooks/exhaustive-deps

    const clearFormField = () => {
        mobileInput.current.value = "";
        otpNumberRef.current.value = "";
        setModalAepsKyc(false)
    }

    const onChangeValue = (event) => {
        const { name, value } = event.target;
        setDataField({ ...getDataField, [name]: value });
    }

    const onChangeChackBox = (e) => {
        // console.log(`checked = ${e.target.checked}`);
        if (e.target.checked === true) {
            setConsent('Y')
        } else {
            setConsent('N')
        }
    };

    const FormValidate = () => {
        let formValid = true;
        let formObject = {};
        setDataError({})
        if (getDataField && getDataField.length === 0) {
            formValid = false;
        }

        let array = ["mobile","accountNumber","ifsccode"];
        array.forEach(element => {
            if (getDataField[element] === "" || typeof getDataField[element] === "undefined") {
                formObject[element] = true;
                formValid = false;
            }
        });

        if (consent === 'N') {
            toast.error("Please aadhaar accept consent declaration");
            formValid = false;
        }

        setDataError(formObject);
        return formValid;
    }

    const handleSubmit = () => {
        if (getDataField.otpCode) {
            setLoading(true)
            handleSubmitOtp()
        } else if (FormValidate()) {

            setLoading(true)
            const payload = {
                mobile: getDataField.mobile,
                accountNumber: getDataField.accountNumber,
                ifsccode: getDataField.ifsccode,
                pan: user.data.userDetails.pancardNumber,
                email: user.data.userDetails.email,
                aadhaar: user.data.userDetails.aadharNumber,
                latitude: latitude,
                longitude: longitude,
                consent: consent,
            }

            postRequest(POST_AEPS_EKYC_INITIATE, payload, history).then((response) => {
                if (response.success === true) {
                    setOtpReferenceID(response.data.otpReferenceID)
                    setHashData(response.data.hash)
                    setOtpModel(true)
                    toast.success("Otp code send on your aadhaar registered mobile number");
                } else {
                    toast.error(response.message);
                }
                setLoading(false)
            }).catch(function (error) {
                // console.log(error)
                setLoading(false)
            })
        }
    } // eslint-disable-line react-hooks/exhaustive-deps

    const handleSubmitOtp = () => {
        const payload = {
            otpReferenceID: otpReferenceID,
            hash: isHash,
            otp: getDataField.otpCode,
        }

        postRequest(POST_AEPS_EKYC_VALIDATE, payload, history).then((response) => {
            if (response.success === true) {
                setDataField([]);
                setDataError({});
                setOtpReferenceID(null)
                setHashData(null)
                setModalAepsKyc(false)
                setOtpModel(false)
                clearFormField();
                setStatus(1)
                setStatusMessage("Your AePS Kyc is successful. Please wait until Bank approved your profile. you can use AePS services once your profil approved by Bank. It will take upto 2 working Days")
                setSubmit(true)
                toast.success(response.message);
            } else {
                setStatus(0)
                setStatusMessage(response.message)
                toast.error(response.message);
            }
            setLoading(false)
        }).catch(function (error) {
            // console.log(error)
            setLoading(false)
        })

    } // eslint-disable-line react-hooks/exhaustive-deps


    const handleCloseModal = () => {
        toast.error("Shop Registration is mandatory in Partner KYC")
        history.push({ pathname : "/dashboard"});
        // setModalAepsKyc(false);
        // setModalEkoAepsKyc(false);
        // setCheckShopRegistered(false);
        // setAuthAePSModel(false)
    }

    const shopregistered = () => {
        handleCloseModal();
    }

    const expressOnboarding = () => {
        setLoading(true)
        postRequest(POST_AEPS_EKYC_EKO_STEP_1, {}, history).then((response) => {
            if (response.success === true) {
                getBioDeviceList()
                setServiceDeviceModel(true);
            } else {
                toast.error(response.message);
            }
            setLoading(false)
        }).catch(function (error) {
            // console.log(error)
            setLoading(false)
        })

    } // eslint-disable-line react-hooks/exhaustive-deps

    const handleFileUpload = (event, mode) => {
        const file = event.target.files[0];
        console.log("file -----------------", file);
        if(mode === 1) {
            setPancardPhoto(file);
        } else if(mode === 2) {
            setAadhaarCardFront(file);
        } else if(mode === 3) {
            setAadhaarCardBack(file);
        }
    }

    const FormValidateAepsServiceActive = () => {
        let formValid = true;
        if (devicenumber === "" || typeof devicenumber === "undefined") {
            toast.error("Device number is required");
            formValid = false;
        }
        if (modelname === "" || typeof modelname === "undefined") {
            toast.error("Model name is required");
            formValid = false;
        }
        if (pancardPhoto === null || typeof pancardPhoto.name === "undefined") {
            toast.error("Pancard is required");
            formValid = false;
        }
        if (aadhaarCardFront === null || typeof aadhaarCardFront.name === "undefined") {
            toast.error("Aadhaar Card Front is required");
            formValid = false;
        }
        if (aadhaarCardBack === null || typeof aadhaarCardBack.name === "undefined") {
            toast.error("Aadhaar Card Back is required");
            formValid = false;
        }

        return formValid;
    }

    const expressServiceOnboarding = async () => {
        if(FormValidateAepsServiceActive()) {
            // setLoading(true)
            let response = await new Promise((resolve) => {
                let data = { "devicenumber": devicenumber, 'modelname': modelname }
                let record = postRequest(POST_AEPS_EKYC_EKO_STEP_2, data, history).then((response) => {
                    if (response.success === true) {
                        console.log("response- 11---------------", response)
                        return response;
                    }
                    }).catch(function (error) {
                        // console.log(error)
                    })
                resolve(record);
            })
            console.log("response----------------", response)
            const postData = new FormData();
            postData.append('form-data', response.data);
            postData.append('pan_card', pancardPhoto);
            postData.append('aadhar_front', aadhaarCardFront);
            postData.append('aadhar_back', aadhaarCardBack);
            const response1 = await axios.put(response.url, postData, {
                headers: response.headers,
            }).then(response => {
                console.log('Success: step 2', response.data);
                return response.data
            }).catch(error => {
                // console.log("error-------------", error)
                // console.error('Error:', error.message);
            });
        // console.log("response1-------------", response1)
        if(response1.response_status_id === 0) {
            toast.success(response1.message);
            eKycOtpRequest()
        }
        if(response1.response_status_id === 1) {
            toast.success(response1.message);
            eKycOtpRequest()
        }
            // const data = new FormData();
            // data.append('devicenumber', devicenumber);
            // data.append('modelname', modelname);
            // data.append('pancardPhoto', pancardPhoto, pancardPhoto.name);
            // data.append('aadhaarCardFront', aadhaarCardFront, aadhaarCardFront.name);
            // data.append('aadhaarCardBack', aadhaarCardBack, aadhaarCardBack.name);
            // postRequest(POST_AEPS_EKYC_EKO_STEP_2, data, history).then((response) => {
            //     if (response.success === true) {
            //         setServiceDeviceModel(false)
            //         // eKycOtpRequest()
            //     } else {
            //         toast.error(response.message);
            //     }
            //     setLoading(false)
            // }).catch(function (error) {
            //     // console.log(error)
            //     setLoading(false)
            // })
        }
    } // eslint-disable-line react-hooks/exhaustive-deps

    const eKycOtpRequest = () => {
        setLoading(true)
        let payload = { latitude: latitude, longitude: longitude }
        postRequest(POST_AEPS_EKYC_EKO_STEP_3, payload, history).then((response) => {
            if (response.success === true) {
                // console.log("response.data -------", response.data)
                setServiceDeviceModel(false)
                setOtpModal(true);
                setOTPData(response.data)
            } else {
                toast.error(response.message);
            }
            setLoading(false)
        }).catch(function (error) {
            // console.log(error)
            setLoading(false)
        })
    } // eslint-disable-line react-hooks/exhaustive-deps

    const kycOtpSendRequest = () => {
        setLoading(true)
        let payload = { otpCode: otpCode, otp_ref_id: otpData.otp_ref_id, reference_tid: otpData.reference_tid, latitude: latitude, longitude: longitude }
        postRequest(POST_AEPS_EKYC_EKO_STEP_4, payload, history).then((response) => {
            if (response.success === true) {
                setServiceDeviceModel(false)
                setOtpModal(false);
                setAuthAePSModel(true)
                scanFingerPrintData()
            } else {
                toast.error(response.message);
            }
            setLoading(false)
        }).catch(function (error) {
            // console.log(error)
            setLoading(false)
        })
    } // eslint-disable-line react-hooks/exhaustive-deps

    const ekycFinal = (data) => {
        setLoading(true)
        let payload = { piddata: data, otp_ref_id: otpData.otp_ref_id, reference_tid: otpData.reference_tid, latitude: latitude, longitude: longitude }
        postRequest(POST_AEPS_EKYC_EKO_STEP_5, payload, history).then((response) => {
            if (response.success === true) {
                // isAuthAePSModel(false);
                setContinue(true)
            } else {
                toast.error(response.message);
            }
            setLoading(false)
        }).catch(function (error) {
            // console.log(error)
            setLoading(false)
        })
    } // eslint-disable-line react-hooks/exhaustive-deps

    const ekycDailyAuth = (data) => {
        setLoading(true)
        let payload = { piddata: data, otp_ref_id: otpData.otp_ref_id, reference_tid: otpData.reference_tid, latitude: latitude, longitude: longitude }
        postRequest(POST_AEPS_EKYC_EKO_STEP_6, payload, history).then((response) => {
            if (response.success === true) {
                setContinue(false);
            } else {
                toast.error(response.message);
            }
            isAuthAePSModel(false);
            setLoading(false)
        }).catch(function (error) {
            // console.log(error)
            setLoading(false)
        })
    } // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        getShopRegister();
        setCheckKycAlreadyExist(user && user.data && user.data.userDetails && user.data.userDetails.outletId);
        setCheckKycEkoAlreadyExist(user && user.data && user.data.userDetails && user.data.userDetails.ekoOnboardCode);
        navigator.geolocation.getCurrentPosition((data) => {
            setLatitude(data.coords.latitude)
            setLongitude(data.coords.longitude)
        });
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const aepsConfig = () => {
        getRequest(GET_AEPS_DEVICE_CONFIG, history).then((response) => {
            if (response.success === true) {
                // console.log("------response--------------", response)
                setDeviceConfig(response.data)
                // toast.success(response.message);
            } else {
                // setAuthAePSModel(true)
                // setErrorAuth(false)
                // setErrorMessage(response.message)
            }
        })
    }

    const scanFingerPrintData = async () => {
        await axios({
            method: 'CAPTURE',
            url: deviceConfig.captureUrl,
            headers: {
                "Content-Type": "text/xml; charset=UTF-8",
            },
            data: deviceConfig.xmlData
        }).then(response => {
            setFingerprint(response.data)
        }).catch(error => {
            console.log("errrrrrrrrrr-", error)
        })
    };

    const continueAuthAePSModel = () => {
        scanFingerPrintData();
    }

    useEffect(() => {
        if (fingerprint && !isContinue) {
            ekycFinal(fingerprint);
        }
        if (fingerprint && isContinue) {
            ekycDailyAuth(fingerprint);
        }
    }, [fingerprint]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        aepsConfig();
        getBankDetailList()
        if (isModalEkoAepsKyc) {
            expressOnboarding();
        }
    }, [isModalEkoAepsKyc]) // eslint-disable-line react-hooks/exhaustive-deps

    if (isLoading) {
        return <div className="content text-center" style={{ margin: "250px 0" }}>
            <PropagateLoader color="#5851D2" loading={isLoading} size={25} />
        </div>
    }
    return (
        <div>
            {isSubmit ? <AfterSubmit message={statusMessage} status={status} /> :
                <div className="content-wrapper pt-2">
                    <div className="content-header my-0 py-0">
                        <div className="container-fluid">
                            <div className="row mb-0 align-items-center">
                                <div className="col-sm-6">
                                    <h1 className='page-title text-blue font-merriweather'>Parter <span className='text-red'>E KYC</span></h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="content row">
                        <div className="container-fluid d-flex pt-2 pb-5">
                            <div className="col-sm-6 col-md-4 col-lg-4">
                                <FingPay />
                            </div>
                            <div className="col-sm-6 col-md-4 col-lg-4">
                                <div className="dbox-outer" onClick={() => (!checkKycAlreadyExist) ? setModalAepsKyc(true) : ""}>
                                    <img src='../images/aepslogo-1@2x.png' alt='photos' width={'250px'} />
                                    <div className='dbox-text text-gray'><h3><i>AEPS KYC - {!checkKycAlreadyExist && <span className='text-warning'>Pending</span>}  {checkKycAlreadyExist && <span className='text-success'>Approved</span>}</i></h3></div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-4 col-lg-4 d-none">
                                <div className='dbox-outer' onClick={() => (checkKycEkoAlreadyExist !== 2 || checkKycEkoAlreadyExist !== 3) ? setModalEkoAepsKyc(true) : ""}>
                                    <img src='../images/aepslogo-1@2x.png' alt='photos' width={'250px'} />
                                    <div className='dbox-text text-gray'><h3><i className='d-flex'>AEPS2 -  {ekoPartnerEkycStatus(user && user.data && user.data.userDetails && user.data.userDetails.ekoOnboardStatus)}</i></h3></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ToastContainer position="top-center" autoClose={5000} limit={2} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="colored" />
                </div>}
                {getBankDetailModal && <Modal title={"Bank Detail"} width={1400} open={getBankDetailModal} onCancel={() => setBankDetailModal(false)} footer={false} >
                <BankSetting />
            </Modal>}
            {isModalAepsKyc && <Modal title={"Partner e-kyc"} width={600} open={isModalAepsKyc} onCancel={() => handleCloseModal()} okText={"Submit"} onOk={() => handleSubmit()} >
                <ul>
                    <li><span>Pan Card</span> : {user.data.userDetails.pancardNumber}</li>
                    <li><span>Email Address</span> : {user.data.userDetails.email}</li>
                    <li><span>Aadhar Number</span> : {user.data.userDetails.aadharNumber}</li>
                    { bankDetailData.length === 0 && <li className='text-right'><button onClick={() => setBankDetailModal(true)} className='btn-btn-sm btn-primary'>Get Bank Details</button></li>}
                    <Divider />
                    <li><div className='col-md-12 col-lg-12 col-sm-12 m-0'>
                        {isOtpModel ?
                            <div className="form-group">
                                <label>OTP Code</label>
                                <div className="input-group">
                                    <div className="custom-file">
                                        <input type="text" className="form-control" name="otpCode" placeholder="OTP Code" onChange={(e) => onChangeValue(e)} autoComplete="off" ref={otpNumberRef} maxLength={6} onKeyDown={isNumber} />
                                    </div>
                                </div>
                            </div> : <div>
                                <div className="form-group">
                                    <label>Aadhar Registered Mobile Number: </label>
                                    <input type="number" className={`form-control normal-light`} name="mobile" maxLength='10' onChange={(e) => onChangeValue(e)} placeholder="Aadhar Registered Mobile Number" onKeyPress={isNumber} value={getDataField && getDataField.mobile} ref={mobileInput}  />
                                    {getDataError && getDataError.mobile && <span className="error text-danger">The field is required</span>}
                                </div>
                                <div className="form-group">
                                    <label>Account Number: </label>
                                    <input type="number" className={`form-control normal-light`} name="mobile" maxLength='19' onChange={(e) => onChangeValue(e)} placeholder="Account Number" onKeyPress={isNumber} value={getDataField && getDataField.accountNumber} ref={mobileInput} readOnly />
                                    {getDataError && getDataError.accountNumber && <span className="error text-danger">The field is required</span>}
                                </div>
                                <div className="form-group">
                                    <label>IFSC Code: </label>
                                    <input type="text" className={`form-control normal-light`} name="mobile" maxLength='19' onChange={(e) => onChangeValue(e)} placeholder="IFSC Code" value={getDataField && getDataField.ifsccode} ref={mobileInput} readOnly />
                                    {getDataError && getDataError.ifsccode && <span className="error text-danger">The field is required</span>}
                                </div>
                                <div className='form-group'>
                                    <Checkbox className='d-flex' onChange={onChangeChackBox}>I hereby give my voluntary consent to SR PAY Private Limited, use my Aadhaar/ VID number & biometric to authenticate from UIDAI for the purpose of AEPS activation.</Checkbox>
                                </div>
                            </div>}
                    </div></li>
                </ul>
            </Modal>}

            {checkShopRegistered && <Modal title={"Shop Registration"} width={600} open={checkShopRegistered} onCancel={() => handleCloseModal()} footer={false} >
                {shopRegisteredStatusMessage && <p className="text-center text-danger">{shopRegisteredStatusMessage}</p>}
                { bankDetailData.length === 0 && <div className="mb-3 text-right"><button onClick={() => setBankDetailModal(true)} className='btn btn-primary'>Get Bank Details</button></div>}
                <ShopIndex shopregistered={shopregistered} />
            </Modal>}

            {shopRegisteredStatusMessage && <Modal title={"Shop Registration"} width={600} open={shopRegisteredStatusMessage} onCancel={() => handleCloseModal()} footer={false} >
                <p className="text-center text-info">{shopRegisteredStatusMessage}</p>
            </Modal>}

            {serviceDeviceModel && <Modal title={"Select Device"} width={600} open={serviceDeviceModel} onOk={() => expressServiceOnboarding()} onCancel={() => setServiceDeviceModel(false)} okText={"Submit"} >
                <div className="form-group">
                    <label>Select Device</label>
                    <Select className="border" size={"large"} style={{ width: "100%" }} onChange={handleChange} options={getBioDeviceData}>
                    </Select>
                </div>
                <div className="form-group">
                    <label>Device Number: </label>
                    <input type="text" className={`form-control normal-light`} name="devicenumber" placeholder="Device Number" value={devicenumber} readOnly />
                </div>

                <div className="form-group">
                    <label>Model Name: </label>
                    <input type="text" className={`form-control normal-light`} name="devicenumber" placeholder="Model Name" value={modelname} readOnly />
                </div>

                <div className="form-group">
                    <label>Pancard: </label>
                    <div className="input-group">
                        <div className="custom-file">
                            <input type="file" name="pancard" className="custom-file-input" onChange={(event) => handleFileUpload(event, 1)} />
                            <label className="custom-file-label">Choose file</label>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <label>Aadhaar Card Front: </label>
                    <div className="input-group">
                        <div className="custom-file">
                            <input type="file" name="aadhaarcardfront" className="custom-file-input" onChange={(event) => handleFileUpload(event, 2)} />
                            <label className="custom-file-label">Choose file</label>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <label>Aadhaar Card Back: </label>
                    <div className="input-group">
                        <div className="custom-file">
                            <input type="file" name="aadhaarcardback" className="custom-file-input" onChange={(event) => handleFileUpload(event, 3)} />
                            <label className="custom-file-label">Choose file</label>
                        </div>
                    </div>
                </div>
            </Modal>}

            {isOtpModal && <Modal title={"Verification OTP"} width={600} open={isOtpModal} onOk={() => kycOtpSendRequest()} onCancel={() => setOtpModal(false)} okText={"Submit"} >
                <div className="form-group">
                    <label>OTP Code</label>
                    <div className="input-group">
                        <div className="custom-file">
                            <input type="text" className="form-control" name="otpCode" placeholder="OTP Code" onChange={(e) => setOtpCode(e.target.value)} autoComplete="off" value={otpCode} maxLength={6} onKeyDown={isNumber} />
                        </div>
                    </div>
                </div>
            </Modal>}
            {isAuthAePSModel && <Modal title={"AePS Verification Secure Login"} width={600} open={isAuthAePSModel} onCancel={() => handleCloseModal()} footer={false} >
                <div className='text-center h-100'>
                    <img src={'./../fingerprint-svgrepo-com.svg'} alt='' width={'175px'} />
                </div>
                <div className='col-sm-12 col-md-12 col-lg-12 mb-120'>
                    <div className='form-group'>
                        <Checkbox className='d-flex' checked={consent} onChange={onChangeChackBox}>I hereby give my voluntary consent to SR PAY Private Limited, use my Aadhaar/ VID number & biometric to authenticate from UIDAI for the purpose of AEPS Onboarding.</Checkbox>
                    </div>
                </div>
                {!isContinue && <button className='btn btn-primary btn-lg bg-primary btn-block' onClick={() => scanFingerPrintData()}>Scan Finger</button>}
                {isContinue && <button className='btn btn-primary btn-lg bg-success btn-block' onClick={() => continueAuthAePSModel()}>Continue</button>}
            </Modal>}
        </div>
    )
}

export default PartnerEkyc