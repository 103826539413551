import React, { useEffect } from 'react';
// import "bootstrap/dist/css/bootstrap.css";
// import "react-bootstrap-carousel/dist/react-bootstrap-carousel.css";
// import moment from 'moment';
import './../../../webcss/default.css';
import './../../../webcss/styles.css';
import Header from './../header';
import Footer from './../footer';

function Services() {
    useEffect(() => {
        // setReportMode(Servicess);
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            <Header />
            <div>
                <div className="notice-section notice-section-sm border-top red">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-10 col-sm-10 col-12">
                                <div className="mt-5">
                                    <h6 className="fontwhite">Become a SR PAY Private Limited Franchise &amp; Grow your income 30% to 50% per month</h6>
                                </div>
                            </div>
                            <div className="col-md-2 col-sm-2 col-12">
                                <div className="right-holder-md">
                                    <a href="/joinus" className="primary-button button-lg mt-15-xs bluebtn">Join Now</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-title-section">
                    <div className="container">
                        <h1><span className="white">S</span>ervices</h1>
                    </div>
                </div>
                <div className="section-block-grey">
                    <div className="container">
                        <div className="section-heading center-holder">
                            <p>
                                At SR PAY Private Limited we are committed to provide a Digital bridge between urban &amp; rural India by providing basic banking &amp; financial services to everyone – securely and instantly and in a hassle-free manner. SR PAY Private Limited is offering innovative banking solutions and Digital reach at Agents doorstep through its various services with industry Best Operators - like Cash Deposit, Cash Withdrawal, Balance Inquiry, Bill Payments, UPI, Bharat QR, Aadhaar Enabled Services, Air Time Recharge, POS Services, Railway Ticketing Services, etc. through our authorized agents across India.
                            </p>
                        </div>
                        <div className="row mt-40">
                            <div className="col-md-6 col-sm-12 col-12">
                                <div className="case-block">
                                    <div className="case-block-img">
                                        <img src="img/projects/project-1b.jpg" alt="case" />
                                    </div>
                                    <div className="case-block-inner">
                                        <a href="/banking-services">
                                            <h4 className="bluefont">Banking &amp; <span className="white">Payment Solutions</span> </h4>
                                        </a>
                                        <div className="mt-20">
                                            <ul className="primary-list">
                                                <li><i className="fa fa-caret-right" />
                                                    AEPS</li>
                                                <li><i className="fa fa-caret-right" />
                                                    {/* Money Transfer */}
                                                    PG Services
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12 col-12">
                                <div className="case-block">
                                    <div className="case-block-img">
                                        <img src="img/projects/project-2b.jpg" alt="case" />
                                    </div>
                                    <div className="case-block-inner">
                                        <a href="/bbps-Service">
                                            <h4 className="bluefont">BBPS <span className="white">Service</span></h4>
                                        </a>
                                        <div className="mt-20">
                                            <ul className="primary-list">
                                                <li><i className="fa fa-caret-right" />
                                                    Water, Electricity, Gas </li>
                                                <li><i className="fa fa-caret-right" />
                                                    Fastag, Prepaid Recharge
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12 col-12">
                                <div className="case-block">
                                    <div className="case-block-img">
                                        <img src="img/projects/project-3b.png" alt="case" />
                                    </div>
                                    <div className="case-block-inner">
                                        <a href="/travel-services">
                                            <h4 className="bluefont">Travel <span className="white">Services</span></h4>
                                        </a>
                                        <div className="mt-20">
                                            <ul className="primary-list">
                                                <li><i className="fa fa-caret-right" />
                                                    Flight Booking</li>
                                                <li><i className="fa fa-caret-right" />
                                                    Train Booking
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12 col-12">
                                <div className="case-block">
                                    <div className="case-block-img">
                                        <img src="img/projects/project-4b.jpg" alt="case" />
                                    </div>
                                    <div className="case-block-inner">
                                        <a href="/utility-services">
                                            <h4 className="bluefont">Utility <span className="white">Services</span></h4>
                                        </a>
                                        <div className="mt-20">
                                            <ul className="primary-list">
                                                <li><i className="fa fa-caret-right" />
                                                    UTI Pan Card</li>
                                                <li><i className="fa fa-caret-right" />
                                                    NSDL Pan Card
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    )
}

export default Services