import React, { useEffect, useState } from 'react';
// import { CSVLink } from 'react-csv';
import DataTable from 'react-data-table-component';
import { GET_DMT_TRANSACTION_FAILED, POST_DMT_REFUND_FAILED_TRANSACTION } from './../../../Utils/appConstants';

import { Modal } from 'antd';
// import { dmtStatus, dmtDetailStatus } from './../../../Utils/Common';
import { getRequest, postRequest } from './../../../Utils/AppApi';
import { useHistory } from "react-router-dom";
import { toast } from 'react-toastify';
import PropagateLoader from "react-spinners/PropagateLoader";
import { DatePicker, Tooltip } from 'antd';
import { getUTCToLocalDateTimeFormat, queryDate } from '../../../Utils/function';
import { channelStatus } from '../../../Utils/Common';

function MoneyTransferFail(props) {
    const history = useHistory();
    // const csvLink = useRef()
    const limit = 10;
    const dateFormat = 'DD-MM-YYYY';

    const [searchUserId, setSearchUserId] = useState(null);
    const [searchAccountNumber, setSearchAccountNumber] = useState(null);
    const [searchTransId, setSearchTransId] = useState(null);
    const [searchCustomerId, setSearchCustomerId] = useState(null);

    const [isLoading, setLoading] = useState(false);
    const [endDate, setEndDate] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [getData, setData] = useState([]);
    // const [getCsvData, setCsvData] = useState([]);
    const [getTotalRecord, setTotalRecord] = useState(0);
    const [refundData, setRefundData] = useState(null);
    const [page, setPage] = useState(1);
    const [isLimit, setLimit] = useState(limit);
    const [userId, setUserId] = useState(props.userId);
    // const [isChecked, setChecked] = useState([]);

    const [isConfirmModal, setConfirmModal] = useState(false);

    const columns = [{
        name: 'Date Time',
        cell: row => getUTCToLocalDateTimeFormat(row.dmttransactions.createdAt),
        width: "200px",
    }, {
        name: 'Trans ID',
        selector: row => <Tooltip placement="bottom" title={row.transactionId && row.transactionId}>{row.transactionId && row.transactionId}</Tooltip>,
        width: "180px",
    }, {
        name: 'Amount',
        selector: row => row.amount + ' ' + row.dmttransactions.currency,
        width: "100px",
    }, {
        name: 'Mode',
        selector: row => channelStatus(row.channel),
    }, {
        name: 'Customer ID',
        selector: row => <Tooltip placement="bottom" title={row.customer_id}>{row.customer_id}</Tooltip>,
        width: "130px",
    }, {
        name: 'Partner ID',
        selector: row => <Tooltip placement="bottom" title={row.users.username}>{row.users.username}</Tooltip>,
        width: "130px",
    }, {
        name: 'Bank Name',
        selector: row => (row.baneData && row.baneData.bankName) || (row.baneData1 && row.baneData1.bankName),
        width: "220px",
    }, {
        name: 'IFSC Code',
        selector: row => row.dmttransactions.ifsccode,
        width: "120px",
    }, {
        name: 'Account Number',
        selector: row => row.dmttransactions.accountNumber,
        width: "170px",
    }, {
        name: 'Bene Name',
        selector: row => row.baneData ? row.baneData.beneName : (row.baneData1) ? row.baneData1.beneName : "",
        width: "150px",
    }, {
        name: 'Action',
        selector: row => <button type="button" className='btn btn-sm border border-dark btn-success' onClick={() => handleConfirm(row)} title='Refund'><i className="fas fa-sync"></i> Refund</button>,
        width: "150px",
    },{
        name: 'Message',
        selector: row => <Tooltip placement="bottom" title={row.reason && row.reason}>{row.reason && row.reason}</Tooltip>,
        width: "250px",
    }]

    const handleConfirm = (data) => {
        setRefundData(data);
        setConfirmModal(true);
    }

    const handleSubmit = (data) => {
        setLoading(true)
        const payload = {
            transactionId: data.transactionId,
        }
        postRequest(`${POST_DMT_REFUND_FAILED_TRANSACTION}`, payload, history).then((response) => {
            if (response.success === true) {
                toast.success(response.message);
                setConfirmModal(false);
                getDataReports();
            } else {
                toast.error(response.message);
            }
            setLoading(false);
        }).catch(function (error) {
            // console.log(error)
            setLoading(false);
        })
    }

    // const failTransaction = () => {
    //     console.log("Checked ------------", isChecked)
    //     if(isChecked.length > 0) {
    //         postRequest(`${POST_DMT_REFUND_FAILED_TRANSACTION}`, isChecked,  history).then((response) => {
    //             if (response.success === true) {
    //                 getDataReports();
    //                 toast.success(response.message);
    //             }
    //         }).catch(function (error) {
    //             console.log(error)
    //             // setLoading(false);
    //         })
    //     } else {
    //         toast.error("Minimum one record is selected")
    //     }
    // }
    const objectToQueryString = (obj) => {
        var str = [];
        for (var p in obj)
            if (obj.hasOwnProperty(p)) {
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
        return str.join("&");
    }

    const params = { page: page, limit: isLimit };

    const getDataReports = (data) => {
        if (data) {
            setLimit(data)
            params.limit = data;
        }
        if (startDate && endDate) {
            params.startDate = startDate ? queryDate(startDate) : "";
            params.endDate = endDate ? queryDate(endDate) : "";
        }

        if (props.userId) {
            params.userId = userId ? userId : props.userId;
        }

        params.searchAccountNumber = searchAccountNumber ? searchAccountNumber : '';
        params.searchCustomerId = searchCustomerId ? searchCustomerId : '';
        params.searchTransId = searchTransId ? searchTransId : '';
        params.searchUserId = searchUserId ? searchUserId : '';

        const queryString = objectToQueryString(params);
        getRequest(`${GET_DMT_TRANSACTION_FAILED}?${queryString}`, history).then((response) => {
            if (response.success === true) {
                setData(response.data);
                setTotalRecord(response.data.totalDocs);
                setPage(response.data.page);
            } else {
                setData([]);
                setTotalRecord(0);
                setPage(1);
            }
        }).catch(function (error) {
            console.log(error)
            // setLoading(false);
        })
    } // eslint-disable-line react-hooks/exhaustive-deps

    const handlePageChange = (page, size) => {
        console.log("-----", size)
        setPage(page);
    };

    const onChangeStartDate = (date, dateString) => {
        // console.log(date, dateString);
        setStartDate(date)
    };

    const onChangeEndDate = (date, dateString) => {
        // console.log(date, dateString);
        setEndDate(date)
    };

    const handleSearch = () => {
        getDataReports();
    }

    const handleClear = () => {
        setStartDate('')
        setEndDate('')
        setSearchAccountNumber('');
        setSearchTransId('');
        setSearchCustomerId('');
        setSearchUserId('');
    }

    useEffect(() => {
        if(searchAccountNumber || searchTransId || searchCustomerId || searchUserId) {
            handleSearch();
        }
    }, [searchAccountNumber, searchTransId, searchCustomerId, searchUserId]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (props.userId) {
            setUserId(props.userId)
        }
        getDataReports();
    }, [page]) // eslint-disable-line react-hooks/exhaustive-deps
    // trans id | IFSC code | AC | AMT | BANK RRN | Status

if (isLoading) {
    return <div className="content text-center" style={{ margin: "250px 0" }}>
        <PropagateLoader color="#5851D2" loading={isLoading} size={25} />
    </div>
}

    return (
        <div>
            <div className="content-wrapper pt-2">
                <div className="content-header my-0 py-0">
                    <div className="container-fluid">
                        <div className="row mb-0 align-items-center">
                            <div className="col-sm-6">
                                <h1 className='page-title text-blue font-merriweather'>Money Transfer <span className='text-red'>Failed Report</span></h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content">
                    <div className="container-fluid pt-2 pb-5">
                        <div className="row">
                            <div className='card col-md-12 py-3'>
                                <div className='card-body p-0'>
                                    <div className="row">
                                        <div className='col-md-12 date-search mb-0'>
                                            <div className='row'>
                                                <div className='col-md-3 col-lg-3 d-flex'>
                                                    <div className="form-group mr-3">
                                                        <label className='date-search-label mb-0 text-blue'>From</label>
                                                        <DatePicker className="form-control form-control-border" value={startDate} format={dateFormat} onChange={onChangeStartDate} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label className='date-search-label mb-0 text-red'>To</label>
                                                        <DatePicker className="form-control form-control-border" value={endDate} format={dateFormat} onChange={onChangeEndDate} />
                                                    </div>
                                                </div>
                                                <div className='col-md-3 col-lg-3 d-flex'>
                                                    <div className="form-group mr-3">
                                                        <label className='date-search-label mb-0 text-red'></label>
                                                        <input type="text" className="form-control form-control-border" placeholder="User ID" onChange={(e) => setSearchUserId(e.target.value)} value={searchUserId} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label className='date-search-label mb-0 text-red'></label>
                                                        <input type="text" className="form-control form-control-border" placeholder="Trans ID" onChange={(e) => setSearchTransId(e.target.value)} value={searchTransId} />
                                                    </div>
                                                </div>
                                                <div className='col-md-2 col-lg-2'>
                                                    <div className="form-group">
                                                        <label className='date-search-label mb-0 text-red'></label>
                                                        <input type="text" className="form-control form-control-border" placeholder="A/C Number" onChange={(e) => setSearchAccountNumber(e.target.value)} value={searchAccountNumber} />
                                                    </div>
                                                </div>
                                                <div className='col-md-2 col-lg-2'>
                                                    <div className="form-group">
                                                        <label className='date-search-label mb-0 text-red'></label>
                                                        <input type="text" className="form-control form-control-border" placeholder="Customer ID" onChange={(e) => setSearchCustomerId(e.target.value)} value={searchCustomerId} />
                                                    </div>
                                                </div>
                                                <div className='col-md-1 col-lg-1'>
                                                    <div><label className='date-search-label mb-0 text-red'>&nbsp;</label></div>
                                                    <button type="submit" className="btn btn-primary rounded-pill px-3" onClick={() => handleSearch()}>Search</button>
                                                </div>
                                                <div className='col-md-1 col-lg-1'>
                                                    <div><label className='date-search-label mb-0 text-red'>&nbsp;</label></div>
                                                    <button type="submit" className="btn btn-default rounded-pill px-4" onClick={() => handleClear()}>Clear</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="container-fluid pt-2 pb-5">
                                        <DataTable
                                            title={""}
                                            columns={columns}
                                            data={getData.docs}
                                            highlightOnHover
                                            pagination
                                            paginationServer
                                            paginationTotalRows={getTotalRecord}
                                            onChangeRowsPerPage={getDataReports}
                                            onChangePage={handlePageChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            {isConfirmModal && (<Modal open={isConfirmModal} onCancel={() => setConfirmModal(false)} onOk={() => handleSubmit(refundData)}>
                <div className="font-weight-bold mt-15">
                    <p className="text-danger" style={{ lineHeight: "20px" }}>Are you sure want to refund this transaction : {refundData.transactionId} ?</p>
                    <ul>
                        <li><strong>Amount</strong>: <span className='text-success'>Rs {refundData.amount}</span></li>
                        <li><strong>TID</strong>: <span className='text-success'>{refundData.tid}</span></li>
                        <li><strong>Transaction Description </strong>: <span className='text-success'> {refundData.txstatus_desc}</span></li>
                        <li><strong>API NAME</strong>: <span className='text-success'>EKO</span></li>
                    </ul>
                </div>
            </Modal>)}
            </div>
        </div>
    )
}

export default MoneyTransferFail