import React, { useEffect, useState, useRef } from 'react';
import { CSVLink } from 'react-csv';
import DataTable from 'react-data-table-component';
import { GET_TRANSACTION_REPORT_API } from './../../Utils/appConstants';
import { getExportFormat, getUTCToLocalDateTimeFormat, toDayDate, queryDate } from './../../Utils/function';
import { getRequest } from './../../Utils/AppApi';
import { getUser, matmModeStatus, matmModeStatusDrop, matmTransStatus, matmTranStatusDrop } from './../../Utils/Common';
import { useHistory } from "react-router-dom";
import { DatePicker, Tooltip, Select } from 'antd';

const user = getUser();

function MatmReport(props) {

    const history = useHistory();
    const csvLink = useRef()
    const limit = 10;
    const dateFormat = 'DD-MM-YYYY';
    const [searchUserId, setSearchUserId] = useState(null);
    const [searchTransId, setSearchTransId] = useState(null);
    const [modeType, setModeType] = useState('');
    const [transStatus, setTransStatus] = useState('');
    // const [searchServiceName, setSearchServiceName] = useState(null);
    const [endDate, setEndDate] = useState(toDayDate());
    const [startDate, setStartDate] = useState(toDayDate());
    const [getData, setData] = useState([]);
    const [getCsvData, setCsvData] = useState([]);
    const [getTotalRecord, setTotalRecord] = useState(0);
    const [page, setPage] = useState(1);
    const [isLimit, setLimit] = useState(limit);

    const columns = [{
        name: 'Date Time',
        cell: row => getUTCToLocalDateTimeFormat(row.createdAt),
        width: "200px"
    }, {
        name: 'Mode',
        selector: row => matmModeStatus(row.typeOfTransaction),
        width: "180px"
    }, {
        name: 'Bank RRN',
        selector: row => <Tooltip placement="bottom" title={row.bankRRN}>{row.bankRRN}</Tooltip>,
        width: "150px"
    }, {
        name: 'Mobile',
        selector: row => <Tooltip placement="bottom" title={row.mobile}>{row.mobile}</Tooltip>,
        width: "120px"
    }, {
        name: 'Card Number',
        selector: row => <Tooltip placement="bottom" title={row.cardNumber}>{row.cardNumber}</Tooltip>,
        width: "160px"
    }, {
        name: 'Amount',
        selector: row => row.amount,
        width: "100px"
    }, {
        name: 'Transaction Id',
        selector: row => <Tooltip placement="bottom" title={row.transactionId}>{row.transactionId}</Tooltip>,
        width: "180px"
    }, {
        name: 'Status',
        selector: row => matmTransStatus(row.transactionStatus),
        width: "120px"
    }, {
        name: 'Terminal Id',
        selector: row => <Tooltip placement="bottom" title={row.terminalId}>{row.terminalId}</Tooltip>,
        width: "180px"
    }, {
        name: 'Message',
        selector: row => <Tooltip placement="bottom" title={row.message}>{row.message}</Tooltip>,
        width: "180px"
    }]

    const objectToQueryString = (obj) => {
        var str = [];
        for (var p in obj)
            if (obj.hasOwnProperty(p)) {
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
        return str.join("&");
    }

    const params = { page: page, limit: isLimit };

    const getDataReports = ( data ) => {
        if(data) {
            setLimit(data)
            params.limit = data;
        }
        if (startDate && endDate) {
            params.startDate = startDate ? queryDate(startDate) : "";
            params.endDate = endDate ? queryDate(endDate) : "";
        }
        
        params.searchTransId = searchTransId ? searchTransId : "";
        params.mode = modeType;
        params.status = transStatus;

        const queryString = objectToQueryString(params);
        getRequest(`${GET_TRANSACTION_REPORT_API}?${queryString}`, history).then((response) => {
            if (response.success === true) {
                setData(response.data);
                setTotalRecord(response.data.totalDocs);
                setPage(response.data.page);
            } else {
                setData([]);
                setTotalRecord(0);
                setPage(1);
            }
        }).catch(function (error) {
            console.log(error)
            // setLoading(false);
        })
    } // eslint-disable-line react-hooks/exhaustive-deps

    const getCSVDataList = () => {
        let params = {}
        params.exportsCsv = true;
        if (startDate && endDate) {
            params.startDate = startDate ? queryDate(startDate) : "";
            params.endDate = endDate ? queryDate(endDate) : "";
        }
        
        params.searchTransId = searchTransId ? searchTransId : "";
        params.mode = modeType;
        params.status = transStatus;

        const queryString = objectToQueryString(params);
        getRequest(`${GET_TRANSACTION_REPORT_API}?${queryString}`, history).then((response) => {
            if (response.success === true) {
                let csvdataRecord = [];
                let csvdata = response.data;
                csvdata.map((e) => {
                    return csvdataRecord.push({
                        "Date Time": getExportFormat(e.createdAt),
                        "Mode": matmModeStatus(e.typeOfTransaction),
                        "Bank RRN": e.bankRRN,
                        "Mobile": e.mobile,
                        "Card Number": e.cardNumber,
                        "Amount": e.amount,
                        "Transaction Id": e.transactionId,
                        "Status": matmTransStatus(e.transactionStatus),
                        "Terminal Id": e.terminalId,
                        "Message": e.message,
                    });
                });
                setCsvData(csvdataRecord);
                setTimeout(() => {
                    csvLink.current.link.click();
                }, 1000);
            } else {
                setCsvData([]);
            }
        }).catch(function (error) {
            console.log(error)
            // setLoading(false);
        })
    }

    const handlePageChange = (page, size) => {
        console.log("-----", size)
        setPage(page);
    };
    
    const onChangeStartDate = (date, dateString) => {
        // console.log(date, dateString);
        setStartDate(date)
    };

    const onChangeEndDate = (date, dateString) => {
        // console.log(date, dateString);
        setEndDate(date)
    };

    const handleClear = () => {
        setStartDate("")
        setEndDate("")
        setModeType('');
        setTransStatus('');
        setSearchTransId('');
        setSearchUserId('');
    }

    const handleSearch = () => {
        getDataReports();
    }

    const onChangeModeType = (value) => {
        // const { value } = event;
        // console.log(value);
        setModeType(value);
    }

    const onChangeTranStatus = (value) => {
        setTransStatus(value);
    }

    // useEffect(() => {
    //     if(searchTransId || searchUserId) {
    //         handleSearch();
    //     }
    // }, [searchTransId, searchUserId]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        getDataReports();
    }, [page]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            {/* <AppHeader /> */}
            <div className="content-wrapper pt-2">
                <div className="content-header my-0 py-0">
                    <div className="container-fluid">
                        <div className="row mb-0 align-items-center">
                            <div className="col-sm-6">
                                <h1 className='page-title text-blue font-merriweather'>Matm <span className='text-red'>Transaction</span></h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content">
                    <div className="container-fluid pt-2 pb-5">
                        <div className='card col-md-12'>
                            <div className='card-body'>
                                <div className="row">
                                    <div className='col-md-10 date-search mb-0'>
                                        <div className='row'>
                                            <div className='col-md-4 col-lg-4 d-flex'>
                                                <div className="form-group mr-3">
                                                    <label className='date-search-label mb-0 text-blue'>From</label>
                                                    <DatePicker className="form-control form-control-border" value={startDate} format={dateFormat} onChange={onChangeStartDate} />
                                                </div>
                                                <div className="form-group">
                                                    <label className='date-search-label mb-0 text-red'>To</label>
                                                    <DatePicker className="form-control form-control-border" value={endDate} format={dateFormat} onChange={onChangeEndDate} />
                                                </div>
                                            </div>
                                            {(user.data.role.code === "A") && <div className='col-md-2 col-lg-2'>
                                                <div className="form-group">
                                                    <label className='date-search-label mb-0 text-red'></label>
                                                    <input type="text" className="form-control form-control-border" placeholder="User ID" onChange={(e) => setSearchUserId(e.target.value)} value={searchUserId} />
                                                </div>
                                            </div>}
                                            <div className='col-md-2 col-lg-2'>
                                                <div className="form-group">
                                                    <label className='date-search-label mb-0 text-red'></label>
                                                    <input type="text" className="form-control form-control-border" placeholder="Trans ID" onChange={(e) => setSearchTransId(e.target.value)} />
                                                </div>
                                            </div>
                                            <div className='col-md-4 col-lg-4 d-flex'>
                                                <div className="form-group mr-3">
                                                    <label className='date-search-label mb-0'>Mode</label>
                                                    <Select options={matmModeStatusDrop} className={`normal-light w-100`} classNamePrefix="select" placeholder="Select" name='mode' onChange={(e) => onChangeModeType(e)} value={modeType} />
                                                </div>
                                                <div className="form-group">
                                                    <label className='date-search-label mb-0'>Status</label>
                                                    <Select options={matmTranStatusDrop} className={`normal-light w-100`} classNamePrefix="select" placeholder="Select" name='mode' onChange={(e) => onChangeTranStatus(e)} value={transStatus} />
                                                </div>
                                            </div>
                                            <div className='col-md-1 col-lg-1'>
                                                <div><label className='date-search-label mb-0 text-red'>&nbsp;</label></div>
                                                <button type="submit" className="btn btn-primary rounded-pill px-3" onClick={() => handleSearch()}>Search</button>
                                            </div>
                                            <div className='col-md-1 col-lg-1'>
                                                <div><label className='date-search-label mb-0 text-red'>&nbsp;</label></div>
                                                <button type="button" className="btn btn-default rounded-pill px-4" onClick={() => handleClear()}>Clear</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-2'>
                                        <div className='float-sm-right'>
                                            <button type="button" className="btn btn-primary mr-3" onClick={() => getCSVDataList()} ><i className="fa fa-file-excel"></i> Export</button>
                                            <CSVLink
                                                data={getCsvData}
                                                filename='matm-transaction-report'
                                                className='hidden'
                                                ref={csvLink}
                                                target='_blank'
                                            />
                                            {/* <button type="button" className="btn btn-danger"><i className="fa fa-file-pdf"></i> Export to PDF</button> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="container-fluid pt-2 pb-5">
                                    <DataTable
                                        title={""}
                                        columns={columns}
                                        data={getData.docs}
                                        highlightOnHover
                                        pagination
                                        paginationServer
                                        paginationTotalRows={getTotalRecord}
                                        onChangeRowsPerPage={getDataReports}
                                        onChangePage={handlePageChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MatmReport