// import React from 'react';
// // import Commingsoon from '../../container/commingsoon';
// function InstantTopup(props) {
//   return (
//     <div className="content-header my-0 py-0">
//       <div className="container-fluid">
//         <div className="row mb-0 align-items-center">
//           <div className="col-sm-12 text-center" style={{ padding: 250 }}>
//             {/* <Commingsoon /> */}
//             <h1>Service Under Maintenance</h1>
//           </div>
//         </div>
//       </div>
//     </div>)
// }
// export default InstantTopup;
import { useState, useEffect } from 'react';
import { POST_DMT_CUSTOMER_INFO } from './../../Utils/appConstants';
import { useHistory } from "react-router-dom";
import PropagateLoader from "react-spinners/PropagateLoader";
import AddCustomer from './addCustomer';
import ViewCustomer from './viewCustomer';
// import DMTHistory from './history';
// import DmtHome from '../dmtHome/index';
// import MoneyTransferV2 from '../moneyTransferV2/index';
// import MoneyTransferV3 from '../moneyTransferV3/index';
// import InstantPay from '../partnerEKYC/instantPay';
// import RefundHistory from './refund';
import { postRequest } from '../../Utils/AppApi';
import { toast } from 'react-toastify';
// import { Tabs } from 'antd';

// import { reactLocalStorage } from 'reactjs-localstorage';
// import moment from "moment";
// const { TabPane } = Tabs;

const MyComponent = (props) => {
    const history = useHistory();
    const [isLoading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState(null);
    const [isCustomerInfo, setCustomerInfo] = useState(null);
    const [isCustomerNotExist, setCustomerNotExist] = useState(false);
    const [timer, setTimer] = useState(null);

    useEffect(() => {
        if(props.mobile) {
            setSearchText(props.mobile)
        }
    }, [props.mobile]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        // getBankListData()
        // let date = moment().subtract(19, 'years');
        // console.log("--------", moment(date).format('DD-MM-YYYY'))
        // navigator.geolocation.getCurrentPosition((data) => {
        //     setLatitude(data.coords.latitude)
        //     setLongitude(data.coords.longitude)
        // });
        // checkDMTAccess();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (searchText) {
            if (timer) {
                clearTimeout(timer)
                setTimer(null)
            }
            setTimer(
                setTimeout(() => {
                    if (searchText.length === 10) {
                        // setSearchButton(false)
                        getCustomerInfo()
                    } else {
                        setCustomerNotExist(false);
                        // toast.error("Please enter a valid Mobile Number.")
                    }
                }, 1000)
            )
        }
    }, [searchText]) // eslint-disable-line react-hooks/exhaustive-deps

    // const handleSearch = (e) => {
    //     // console.log("searchText----",e.target.value)
    //     if (e.target.value.length === 10) {
    //         setSearchButton(false)
    //     } else {
    //         setCustomerInfo(null)
    //         setSearchButton(true)
    //     }
    //     setSearchText(e.target.value);
    // }

    const findCustomerInfo = (mobile) => {
        setSearchText(null)
        setSearchText(mobile)
    }
    // const getBankListData = async () => {
    // await getRequest(GET_AEPS_BANK_LIST, history).then((response) => {
    //     if (response.success === true) {
    //         setBankList(response.data.map(item => {
    //             return { "value": item.iin, "label": item.name };
    //         }));
    //     } else {
    //         toast.error(response.message);
    //     }
    // }).catch(function (error) {
    //     console.log(error)
    //     // setLoading(false);
    // })
    // }

    const getCustomerInfo = async () => {
        setLoading(true)
        if (!searchText) {
            toast.error("Please enter a Customer Number");
            setLoading(false)
            return false;
        } else if (searchText.length !== 10) {
            toast.error("Please enter a valid Mobile Number");
            setLoading(false)
            return false;
        }
        let payload = { "customerMobile": searchText, "isWeb": true }
        await postRequest(POST_DMT_CUSTOMER_INFO, payload, history).then((response) => {
            if (response.success === true) {
                setCustomerInfo(response.data);
                setCustomerNotExist(false)
                // console.log("----------------", response.data);
            } else {
                setCustomerNotExist(true);
                // console.log("----------------", response.success);
            }
            setLoading(false)
        }).catch(function (error) {
            console.log(error)
            setLoading(false);
        })
    }

    if (isLoading) {
        return <div className="content text-center" style={{ margin: "250px 0" }}>
            <PropagateLoader color="#5851D2" loading={isLoading} size={25} />
        </div>
    }
    return (
        <div>
            <div className="">
                <div className="">
                {(isCustomerNotExist) && <AddCustomer mobile={searchText} findCustomerInfo={findCustomerInfo} />}
                {(isCustomerInfo && !isCustomerNotExist) && <ViewCustomer isCustomerInfo={isCustomerInfo} />}
                </div>
            </div>
        </div>
    )
}

export default MyComponent;