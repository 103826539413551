import React, { useEffect, useState, useRef } from 'react';
import { CSVLink } from 'react-csv';
import DataTable from 'react-data-table-component';
import { getParentPageTitleLable } from './../../Utils/Common';
import { GET_ALL_MY_SEND_PAYMENT_DEPOSIT } from './../../Utils/appConstants';
import { getRequest } from './../../Utils/AppApi';
import { useHistory } from "react-router-dom";
// import Moment from 'react-moment';
import moment from 'moment';

import { DatePicker } from 'antd';
import { getUTCToLocalDateFormat, getUTCToLocalDateTimeFormat, queryDate, toDayDate } from '../../Utils/function';
// const user = getUser();
function PaymentDepositHistory(props) {

    const history = useHistory();
    const csvLink = useRef()
    const limit = 10;

    const dateFormat = 'DD-MM-YYYY';

    const [isLabel, setLabel] = useState(null);
    const [endDate, setEndDate] = useState(toDayDate());
    const [startDate, setStartDate] = useState(toDayDate());
    const [getData, setData] = useState([]);
    const [getCsvData, setCsvData] = useState([]);
    const [getTotalRecord, setTotalRecord] = useState(0);
    const [page, setPage] = useState(1);
    const [searchUserId, setSearchUserId] = useState(null);

    const columns = [{
        name: 'Date & Time',
        cell: row => getUTCToLocalDateTimeFormat(row.createdAt),
        width: "200"
    }, {
        name: 'Reference Number',
        selector: row => row.UTRReferenceNumber,
        width: "180px"
    }, {
        name: 'Deposit Mode',
        selector: row => row.paymentMode,
    }, {
        name: 'Current Outstanding Amount',
        selector: row => row.currentOutStandingBalance,
    }, {
        name: 'Deposit Amount',
        selector: row => row.depositAmount,
    }, {
        name: 'Deposit Date',
        cell: row => getUTCToLocalDateFormat(row.dateTime),
    }, {
        name: 'Agent Remark',
        selector: row => row.agentRemark,
    }, {
        name: 'Status',
        cell: row => <>{(row.status === 1) ? <div className="text-success">Approved</div> : row.status === 2 ? <div className="text-danger">Rejected</div> : <div className="text-warning">Pending</div>}</>,
    }, {
        name: 'Dis. Remark',
        selector: row => row.remark,
    }]

    const objectToQueryString = (obj) => {
        var str = [];
        for (var p in obj)
            if (obj.hasOwnProperty(p)) {
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
        return str.join("&");
    }

    const params = { page: page, limit: limit };

    const getDataReports = () => {
        if (startDate && endDate) {
            params.startDate = startDate ? queryDate(startDate) : "";
            params.endDate = endDate ? queryDate(endDate) : "";
        }

        params.searchUserId = searchUserId ? searchUserId : "";

        const queryString = objectToQueryString(params);
        getRequest(`${GET_ALL_MY_SEND_PAYMENT_DEPOSIT}?${queryString}`, history).then((response) => {
            if (response.success === true) {
                setData(response.data);
                setTotalRecord(response.data.totalDocs);
                setPage(response.data.page);
            } else {
                setData([]);
                setTotalRecord(0);
                setPage(1);
            }
        }).catch(function (error) {
            console.log(error)
            // setLoading(false);
        })
    } // eslint-disable-line react-hooks/exhaustive-deps

    const getCSVDataList = () => {
        let params = {}
        params.exportsCsv = true;
        if (startDate && endDate) {
            params.startDate = startDate ? queryDate(startDate) : "";
            params.endDate = endDate ? queryDate(endDate) : "";
        }
        params.searchUserId = searchUserId ? searchUserId : "";
        // if (search) {
        //   params.search = search ? search : "";
        // }
        const queryString = objectToQueryString(params);
        getRequest(`${GET_ALL_MY_SEND_PAYMENT_DEPOSIT}?${queryString}`, history).then((response) => {
            if (response.success === true) {
                let csvdataRecord = [];
                let csvdata = response.data;
                csvdata.map((e) => {
                    return csvdataRecord.push({ "Date & Time": moment(e.dateTime).format("DD/MM/YYYY"), "UTR Reference Number": e.UTRReferenceNumber, "Outstanding Balance": e.currentOutStandingBalance, "Deposit Amount": e.depositAmount, "Payment Mode": e.paymentMode, "Agent Remark": e.agentRemark, "Status": (e.status === 1) ? "success" : (e.status === 2) ? "rejected" : "pending", "Remark": e.remark });
                });
                setCsvData(csvdataRecord);
                setTimeout(() => {
                    csvLink.current.link.click();
                }, 1000);
            } else {
                setCsvData([]);
            }
        }).catch(function (error) {
            console.log(error)
            // setLoading(false);
        })
    }

    const handlePageChange = page => {
        setPage(page);
    };

    const onChangeStartDate = (date, dateString) => {
        // console.log(date, dateString);
        setStartDate(date)
    };

    const onChangeEndDate = (date, dateString) => {
        // console.log(date, dateString);
        setEndDate(date)
    };

    const handleSearch = () => {
        getDataReports();
    }

    const handleClear = () => {
        setStartDate('');
        setEndDate('');
        setSearchUserId('');
    }

    useEffect(() => {
        setLabel(getParentPageTitleLable())
        getDataReports();
        // console.log("user ----------", user)
    }, [page]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            <div className="container-fluid">
                <div className='card rounded-0'>
                    <div className="content-header my-0 px-0">
                        <h1 className='page-title text-blue font-merriweather mb-3'>Payment <span className='text-red'>{isLabel} History</span></h1>
                        <div className="row align-items-center">
                            <div className='col-md-8'>
                                <div className='date-search mb-0'>
                                    <div className='row'>
                                        <div className='col-sm-4 col-lg-2'>
                                            <div className="form-group">
                                                <label className='date-search-label mb-0 text-blue'>From</label>
                                                <DatePicker className="form-control form-control-border" value={startDate} format={dateFormat} onChange={onChangeStartDate} />
                                            </div>
                                        </div>
                                        <div className='col-sm-4 col-lg-2'>
                                            <div className="form-group">
                                                <label className='date-search-label mb-0 text-red'>To</label>
                                                <DatePicker className="form-control form-control-border" value={endDate} format={dateFormat} onChange={onChangeEndDate} />
                                            </div>
                                        </div>
                                        <div className='col-sm-4 col-lg-3'>
                                            <div className="form-group mr-3">
                                                <label className='date-search-label mb-0 text-red'></label>
                                                <input type="text" className="form-control form-control-border" placeholder="User ID / Name" onChange={(e) => setSearchUserId(e.target.value)} value={searchUserId} />
                                            </div>
                                        </div>
                                        <div className='col-sm-4 col-lg-2'>
                                            <div><label className='date-search-label mb-0 text-red'>&nbsp;</label></div>
                                            <button type="submit" className="btn btn-primary rounded-pill pl-4 pr-4" onClick={() => handleSearch()}>Search</button>
                                        </div>
                                        <div className='col-sm-4 col-lg-2'>
                                            <div><label className='date-search-label mb-0 text-red'>&nbsp;</label></div>
                                            <button type="button" className="btn btn-default rounded-pill ml-3 pl-4 pr-4 pull-right" onClick={() => handleClear()}>Reset</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='float-sm-right'>
                                    <button type="button" className="btn btn-primary mr-3" onClick={() => getCSVDataList()} ><i className="fa fa-file-excel"></i> Export</button>
                                    <CSVLink
                                        data={getCsvData}
                                        filename='payment-deposit-history'
                                        className='hidden'
                                        ref={csvLink}
                                        target='_blank'
                                    />
                                    <button type="button" className="btn btn-danger"><i className="fa fa-file-pdf"></i> Export to PDF</button>
                                </div>
                            </div>
                        </div>

                        <div className='history-table'>
                            <div className="">
                                <div className="box-body">
                                    <DataTable
                                        title={""}
                                        columns={columns}
                                        data={getData.docs}
                                        highlightOnHover
                                        pagination
                                        paginationServer
                                        paginationTotalRows={getTotalRecord}
                                        onChangeRowsPerPage={getDataReports}
                                        onChangePage={handlePageChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PaymentDepositHistory