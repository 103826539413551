import React, { useEffect, useState, useRef } from 'react';
import { CSVLink } from 'react-csv';
import DataTable from 'react-data-table-component';
import { GET_ADMIN_FUND_REQUEST_REPORT } from './../../../Utils/appConstants';
import { getRequest } from './../../../Utils/AppApi';
import { INRFormat, FundRequestValue, setDefaultStatus, FundRequestValueCSV } from './../../../Utils/Common';
import { useHistory } from "react-router-dom";
import moment from 'moment';

import { DatePicker, Select } from 'antd';
import { getUTCToLocalDateFormat, queryDate, toDayDate } from '../../../Utils/function';

function FundRequestReport(props) {
    const history = useHistory();
    const csvLink = useRef()
    const limit = 10;

    const dateFormat = 'DD-MM-YYYY';
    const dateDBFormat = 'YYYY-MM-DD';
    const dateDBFormatCSV = 'YYYY-MM-DD HH:MM:SS';

    // const [search, setSearch] = useState(null);
    const [endDate, setEndDate] = useState(toDayDate());
    const [startDate, setStartDate] = useState(toDayDate());
    const [getData, setData] = useState([]);
    const [getCsvData, setCsvData] = useState([]);
    const [getTotalRecord, setTotalRecord] = useState(0);
    const [page, setPage] = useState(1);
    // const [isClear, setIsClear] = useState(false);
    const [isStatus, setStatus] = useState(null)

    const columns = [{
        name: 'User ID',
        selector: row => row.userData.username,
        width: "120px"
    }, {
        //     name: 'Name',
        //     selector: row => row.userData.name,
        // }, {
        name: 'DP Date',
        cell: row => getUTCToLocalDateFormat(row.paymentDepositDate),
        width: "120px"
    }, {
        name: 'FTR Ref. No.',
        selector: row => row.FTRReferenceNumber,
        width: "180px"
    }, {
        name: 'Amount',
        selector: row => INRFormat(row.amount),
        width: "120px"
    }, {
        name: 'Trans Id',
        selector: row => row.transactionId,
        width: "190px"
    }, {
        name: 'Bank Name',
        selector: row => row.bankdetails.bankName,
        width: "190px"
    }, {
        //     name: 'AC Number',
        //     selector: row => row.accountNumber,
        // }, {
        name: 'Mode',
        selector: row => row.mode,
        width: "120px"
    }, {
        //     name: 'Location',
        //     selector: row => row.location,
        // }, {
        name: 'Bank Ref',
        selector: row => row.tranReasonId,
        width: "180px"
    }, {
        name: 'Create Date',
        cell: row => getUTCToLocalDateFormat(row.createdAt),
        width: "120px"
    }, {
        name: 'Update Date',
        cell: row => getUTCToLocalDateFormat(row.updatedAt),
        width: "120px"
    }, {
        name: 'Status',
        cell: row => <>{FundRequestValue(row.status)}</>,
        width: "120px"
        // }, {
        //     name: 'Bank Remark',
        //     selector: row => row.bankRemark,
        // }, {
        //     name: 'Reject Reason',
        //     selector: row => row.rejectReason,
    }]

    const objectToQueryString = (obj) => {
        var str = [];
        for (var p in obj)
            if (obj.hasOwnProperty(p)) {
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
        return str.join("&");
    }

    const params = { page: page, limit: limit };

    const getDataReports = () => {
        if (startDate && endDate) {
            params.startDate = startDate ? queryDate(startDate) : "";
            params.endDate = endDate ? queryDate(endDate) : "";
        }

        params.status = isStatus;

        const queryString = objectToQueryString(params);
        getRequest(`${GET_ADMIN_FUND_REQUEST_REPORT}?${queryString}`, history).then((response) => {
            if (response.success === true) {
                // setIsClear(false)
                setData(response.data);
                setTotalRecord(response.data.totalDocs);
                setPage(response.data.page);
            } else {
                setData([]);
                setTotalRecord(0);
                setPage(1);
            }
        }).catch(function (error) {
            console.log(error)
            // setLoading(false);
        })
    } // eslint-disable-line react-hooks/exhaustive-deps

    const getCSVDataList = () => {
        let params = {}
        params.exportsCsv = true;
        if (startDate && endDate) {
            params.startDate = startDate ? startDate : "";
            params.endDate = endDate ? endDate : "";
        }

        params.status = isStatus;

        const queryString = objectToQueryString(params);
        getRequest(`${GET_ADMIN_FUND_REQUEST_REPORT}?${queryString}`, history).then((response) => {
            if (response.success === true) {
                let csvdataRecord = [];
                let csvdata = response.data;
                csvdata.map((e) => {
                    return csvdataRecord.push({
                        "User Id": e.userData.username,
                        "Name": e.userData.name,
                        "Deposit Date": moment(e.paymentDepositDate).format("DD/MM/YYYY"),
                        "FTR Reference Number": e.FTRReferenceNumber,
                        "Amount": INRFormat(e.amount),
                        "Trans ID": e.transactionId,
                        "Mode": e.mode,
                        "Bank Ref": e.tranReasonId,
                        "Bank Name": e.bankdetails.bankName,
                        "Account Number": e.accountNumber.toString(),
                        "Branch IFSC": e.branchIfsc,
                        "Location": e.location,
                        "Upload Slip": e.uploadSlip,
                        "created Date": moment(e.createdAt, dateDBFormat).format(dateDBFormatCSV),
                        "Update Date": moment(e.updatedAt, dateDBFormat).format(dateDBFormatCSV),
                        "Status": FundRequestValueCSV(e.status),
                        "Bank Remark": e.bankRemark,
                        "Reject Reason": e.rejectReason,
                    });
                });
                setCsvData(csvdataRecord);
                setTimeout(() => {
                    csvLink.current.link.click();
                }, 1000);
            } else {
                setCsvData([]);
            }
        }).catch(function (error) {
            console.log(error)
            // setLoading(false);
        })
    }

    const handlePageChange = page => {
        setPage(page);
    };

    const onChangeStartDate = (date, dateString) => {
        // console.log(date, dateString);
        // setStartDate(moment(dateString, dateFormat).format(dateDBFormat))
        setStartDate(date)
    };

    const onChangeEndDate = (date, dateString) => {
        // console.log(date, dateString);
        // setEndDate(moment(dateString, dateFormat).format(dateDBFormat))
        setEndDate(date)
    };

    const onChangeType = (value) => {
        // const { value } = event;
        console.log(value);
        setStatus(value);
    }

    const handleClear = () => {
        setStartDate('');
        setEndDate('');
        setStartDate(null);
        setEndDate(null);
        setStatus(null);
        // setIsClear(true)
    }

    const handleSearch = () => {
        getDataReports();
    }

    useEffect(() => {
        getDataReports();
    }, [page]) // eslint-disable-line react-hooks/exhaustive-deps

    // useEffect(() => {
    //     if (isClear) {
    //         getDataReports();
    //     }
    // }, [isClear]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            {/* <AppHeader /> */}
            <div className="content-wrapper pt-2">
                <div className="content-header my-0 py-0">
                    <div className="container-fluid">
                        <div className="row mb-0 align-items-center">
                            <div className="col-sm-6">
                                <h1 className='page-title text-blue font-merriweather'>Fund Request <span className='text-red'>History</span></h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content">
                    <div className="container-fluid pt-2 pb-5">
                        <div className="row">
                            <div className='card rounded-0 col-md-12'>
                                <div className="row align-items-center mt-4 mx-3">
                                    <div className='col-md-10'>
                                        <div className='date-search mb-0'>
                                            <div className='row'>
                                                <div className='col-md-2 col-lg-2'>
                                                    <div className="form-group">
                                                        <label className='date-search-label mb-0 text-blue'>From</label>
                                                        <DatePicker className="form-control form-control-border" value={startDate} format={dateFormat} onChange={onChangeStartDate} allowClear />
                                                    </div>
                                                </div>
                                                <div className='col-md-2 col-lg-2'>
                                                    <div className="form-group">
                                                        <label className='date-search-label mb-0 text-red'>To</label>
                                                        <DatePicker className="form-control form-control-border" value={endDate} format={dateFormat} onChange={onChangeEndDate} allowClear />
                                                    </div>
                                                </div>
                                                <div className='col-md-3 col-lg-3'>
                                                    <div className="form-group">
                                                        <label className='date-search-label mb-0'>FTR Status</label>
                                                        <Select options={setDefaultStatus} className={`normal-light w-100`} classNamePrefix="select" placeholder="Select" name='mode' onChange={(e) => onChangeType(e)} value={isStatus} />
                                                    </div>
                                                </div>

                                                <div className='col-md-3 col-lg-3 mt-3'>
                                                    <button type="submit" className="btn btn-primary rounded-pill pl-4 pr-4" onClick={() => handleSearch()}>Search</button>
                                                    <button type="button" className="btn btn-default rounded-pill ml-3 pl-4 pr-4 pull-right" onClick={() => handleClear()}>Reset</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-2'>
                                        <div className='float-sm-right'>
                                            <button type="button" className="btn btn-primary mr-3" onClick={() => getCSVDataList()} ><i className="fa fa-file-excel"></i> Export</button>
                                            <CSVLink
                                                data={getCsvData}
                                                filename='fund-request-reports'
                                                className='hidden'
                                                ref={csvLink}
                                                target='_blank'
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="container-fluid">
                                    <DataTable
                                        title={""}
                                        columns={columns}
                                        data={getData.docs}
                                        highlightOnHover
                                        pagination
                                        paginationServer
                                        paginationTotalRows={getTotalRecord}
                                        onChangeRowsPerPage={getDataReports}
                                        onChangePage={handlePageChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default FundRequestReport