
import Commingsoon from '../../container/commingsoon';
export default Commingsoon;
// import React, { useEffect, useState } from 'react';
// import { Tabs, Tab } from 'react-bootstrap-tabs';
// // import { useHistory } from "react-router-dom";
// // import { ToastContainer, toast } from 'react-toastify';
// // import AppFooter from '../Footer/Footer';
// import EletricityBillPayment from './eletricityBillPayment';
// import WaterBillPayment from './waterBillPayment';
// import GasBillPayment from './gasBillPayment';
// import InsuranceBillPayment from './insuranceBillPayment';
// import FastagPayment from './fastagPayment';

// import 'antd/dist/antd.css';
// // import { Modal } from 'antd';

// function UitilityPayment(props) {
//   // const dateFormat = 'DD/MM/YYYY';
//   // const history = useHistory();
//   const [tabName, setTabName] = useState("Eletricity Bill");

//   useEffect(() => {
//     // getInsuranceOperators();
//   }, [])

//   const onchangeTab = (index, value) => {
//     // console.log("index -------", index)
//     setTabName(value.props.title)
//     // console.log("value -------", value.props.children[1])
//   }
//   return ( 
//     <div className="content-header my-0 py-0">
//         <div className="container-fluid">
//           <div className="row mb-0 align-items-center">
//             <div class="col-sm-3"></div>
//             <div className=" col-sm-6">
//               <Commingsoon />
//             </div>
//           </div>
//         </div>
//       </div>
//       )
//   return (
//     <div>
//       {/* <AppHeader /> */}
//       <div className="content-wrapper pt-2">
//         <div className="content-header my-0 py-0">
//           <div className="container-fluid">
//             <div className="row mb-0 align-items-center">
//               <div className="col-sm-6">
//                 <h1 className='page-title text-blue font-merriweather'>Uitility <span className='text-red'>Payment 2.0</span></h1>
//               </div>
//             </div>
//           </div>
//         </div>
//         <div className="content">
//           <div className="container-fluid pt-2 pb-5">
//             <div className="row">
//               <div className="col-md-6">
//                 <div className='card rounded-0'>
//                   <div className='card-body pt-0'>
//                     <Tabs className="recharge-tabs" onSelect={(index, label) => onchangeTab(index, label)}>
//                       <Tab eventKey={1} label={<div className='tabtext' title="Eletricity Bill"><span><img src='../images/electricity.svg' alt='' /></span>Eletricity</div>}>
//                         <EletricityBillPayment />
//                       </Tab>
//                       <Tab eventKey={2} label={<div className='tabtext' title='Water Bill'><span><img src='../images/water.svg' alt='' /></span>Water</div>}>
//                         <WaterBillPayment />
//                       </Tab>
//                       <Tab eventKey={3} label={<div className='tabtext' title='GAS Bill'><span><img src='../images/gas.svg' alt='' /></span>GAS</div>}>
//                         <GasBillPayment />
//                       </Tab>
//                       <Tab eventKey={4} label={<div className='tabtext' title='Insurance Premium'><span><img src='../images/insurance.svg' alt='' /></span>Insurance</div>}>
//                         <InsuranceBillPayment />
//                       </Tab>
//                       <Tab eventKey={5} label={<div className='tabtext' title='FASTag Recharge'><span><img src='../images/fastag-logo-icon.svg' alt='' /></span>FASTag</div>}>
//                         <FastagPayment />
//                       </Tab>
//                     </Tabs>
//                   </div>
//                 </div>
//               </div>
//               <div className="col-md-6">
//                 <div className='card rounded-0'>
//                   <div className='card-body'>
//                     <h3 className='text-blue text-center mb-3'>{tabName} <span className='text-red'>Payment Offers</span></h3>
//                     <div className='text-center'>
//                       <img src='../images/offer.png' alt='img' />
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       {/* <AppFooter /> */}
//     </div>
//   )
// }


// export default UitilityPayment