import React, { useEffect, useState } from 'react';
import { INRFormat } from './../Utils/Common';
import { GET_MYPROFILE } from './../Utils/appConstants';
import { getRequest } from './../Utils/AppApi';
import { useHistory } from "react-router-dom";

function Afterpayment(props) {

  const history = useHistory();

  const [wallet, setWallet] = useState(0);

  const [status, setStatus] = useState(0);

  const [message, setMessage] = useState("Your transaction is success");

  const getProfile = () => {
    getRequest(GET_MYPROFILE, history).then((response) => {
      if (response.success === true) {
        setWallet(response.data[0].walletBalance.balance)
        console.log("response.data ========", response.data[0].walletBalance.balance)
      }
    }).catch(function (error) {
      // console.log(error)
    })
  }

  useEffect(() => {
    getProfile()

    if (props.message)
      setMessage(props.message)

    if (props.status)
      setStatus(props.status)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <div className="text-center my-5">
      <div className="container payment-card" style={{ border: "1px solid grey" }}>
        {status === 0 && <><div style={{ borderRadius: 200, height: 200, width: 200, background: '#ffe6e6', margin: '0 auto' }}>
          <b style={{ color: "#e60000" }} className="checkmark">x</b>
        </div><h1>Failed</h1></>}
        {status === 1 && <><div style={{ borderRadius: 200, height: 200, width: 200, background: '#F8FAF5', margin: '0 auto' }}>
          <i className="checkmark">✓</i>
        </div>
          <h1>Success</h1>
        </>
        }
        {status === 2 && <><div style={{ borderRadius: 200, height: 200, width: 200, background: '#ffffb3', margin: '0 auto' }}>
          <i className="checkmark"><i className="fa fa-clock-o" aria-hidden="true"></i></i>
        </div>
          <h1>Pending</h1>
        </>
        }

        <h5>Wallet Balance : {INRFormat(wallet)}</h5>
        <p className="payment-card-content">{message}</p>
      </div>
    </div>
  )
}

export default Afterpayment;