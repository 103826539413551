import React, { useEffect, useState } from "react"
import BeneficiaryList from './beneficiaryList';

import { isNumber } from "../../Utils/Common";
import { Modal } from "antd";
import { toast } from 'react-toastify';
import PropagateLoader from "react-spinners/PropagateLoader";
import { POST_DMT_INSTANT_PAY_OTP_VERIFICATION } from '../../Utils/appConstants';
// import {dateofBirth} from './../../Utils/function';
import { postRequest } from '../../Utils/AppApi';
import { useHistory } from "react-router-dom";
import DmtAadharVerify from '../dmtAadharVerify';
import moment from "moment";

function ViewCustomer(props) {
    const history = useHistory();
    const [customerData, setCustomerData] = useState(null);
    const [isLoading, setLoading] = useState(false);
    // const [isDataRequired, setDataRequired] = useState(false);
    const [isOtpModal, setOtpModal] = useState(false);
    const [otpCode, setOtpCode] = useState(null)
    const [getDataField, setDataField] = useState({});

    const [isAadharModal, setAadharModal] = useState(false);

    useEffect(() => {
        setCustomerData(props.isCustomerInfo);
        let lastDate = moment().subtract(19, 'years');
        let dateValue = moment(lastDate).format('YYYY-MM-DD');
        // console.log("useEffect --------------",dateValue)
        // console.log("props.isCustomerInfo-------", props.isCustomerInfo)
        if (!props.isCustomerInfo.isVerified) {
            setDataField({ ...getDataField, ...props.isCustomerInfo, customerDob: dateValue });
        }
    }, [props.isCustomerInfo]) // eslint-disable-line react-hooks/exhaustive-deps

    const onChangeOtpValue = (value) => {
        setOtpCode(value);
    }

    const handleCustomerVerify = () => {
        console.log("========== verify")
        if (otpCode) {
            setLoading(true)
            console.log("========== otpCode", otpCode)
            const payload = {
                customerMobile: customerData.customerMobile,
                otp: otpCode
            };

            postRequest(`${POST_DMT_INSTANT_PAY_OTP_VERIFICATION}`, payload, history).then((response) => {
                if (response.success === true) {
                    // handleAddBeneficiary()
                    setOtpModal(false)
                    toast.success(response.message);
                } else {
                    toast.error(response.message);
                }
                setLoading(false)
            }).catch(function (error) {
                // console.log(error)
                setOtpModal(false)
                setLoading(false)
            })
        } else {
            toast.error("OTP code is required");
        }
    }

    // const onChangeDate = (date) => {
    //     console.log('From: ', date);
    //     let dateValue = moment(date).format('YYYY-MM-DD');
    //     setDataField({ ...getDataField, customerDob: dateValue });
    // }

    // const onChangeValue = (event) => {
    //     const { name, value } = event.target;
    //     setDataField({ ...getDataField, [name]: value });
    // }

    // const disabledDate = (d) => {
    //     return d.isAfter(moment().subtract(19, 'years'))
    // };

    if (isLoading) {
        return <div className="content text-center" style={{ margin: "250px 0" }}>
            <PropagateLoader color="#5851D2" loading={isLoading} size={25} />
        </div>
    }

    return (
        <section>
            <div>
                <div className="row d-flex align-items-center mh-100">
                    <div className="col p-0 col-xl-12">
                        <div className="card" style={{ borderRadius: 15 }}>
                            <div className="card-body m-0">
                                <div className="d-flex">
                                    {customerData && <>
                                        <div className="col-md-11">
                                            <h3 className="mb-3">{customerData.customerName}</h3>
                                            <p className="mb-0 fx-14 text-dark">
                                                <i className="fas fa-star fa-lg text-warning" />
                                                <span className="mx-2">|</span>
                                                Mobile : <strong>{customerData.customerMobile}</strong>
                                                {/* {customerData.customerDob && <><span className="mx-2">|</span>
                                    Date Of Birth : <strong>{dateofBirth(customerData.customerDob)}</strong>
                                    </>} */}
                                                {<><span className="mx-2">|</span> Available Limit : <strong>{customerData && customerData.availableLimit}</strong></>}
                                                {/* {<><span className="mx-2">|</span>
                                            Available Limit : <strong>{(customerData.availableLimit >= 25000 || !customerData.availableLimit) ? "25000" : customerData.availableLimit}</strong></>} */}
                                                <span className="mx-2">|</span>
                                                Status : <strong>{customerData.pipeInstantTransfer ? customerData.pipeInstantTransfer : "N"}</strong>
                                                <span className="mx-2">|</span>
                                                {customerData && customerData.pipeInstantTransfer === "N" && <button className="btn btn-sm btn-primary" onClick={() => setAadharModal(true)}>Instant Transfer KYC</button>}
                                                {/* {!customerData.isVerified && <button className="btn btn-xs btn-info">Verify Customer</button>} */}
                                            </p>
                                        </div>
                                        {customerData && customerData.pipeInstantTransfer === "Y" && <>
                                            <div className="">
                                                <div className="col-md-12">
                                                    {customerData && <img className="elevation-2" src={"data:image/png;base64," + customerData.photo_link} alt="User Avatar" />}
                                                </div>
                                            </div>
                                        </>}
                                        <hr className="my-2" /></>}</div>

                                {isAadharModal && <DmtAadharVerify serviceName={"DMT1"} mobile={customerData.customerMobile} aadhar={customerData.aadhar} findCustomerInfo={props.findCustomerInfo} />}

                                {customerData && customerData.isVerified && <BeneficiaryList customerData={customerData} />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {isOtpModal && <Modal title={"Customer Verification OTP"} width={600} open={isOtpModal} onOk={() => handleCustomerVerify()} onCancel={() => setOtpModal(false)} okText={"Submit"} >
                <div className="form-group">
                    <label>OTP Code</label>
                    <div className="input-group">
                        <div className="custom-file">
                            <input type="text" className="form-control" name="otpCode" placeholder="OTP Code" onChange={(e) => onChangeOtpValue(e.target.value)} autoComplete="off" value={otpCode} maxLength={6} onKeyDown={isNumber} />
                        </div>
                    </div>
                </div>
                {/* <Button className='btn btn-primary btn-lg btn-block' onClick={() => scanFingerPrintData()}>Scan</Button> */}
            </Modal>}
        </section>
    )
}

export default ViewCustomer;