import React, { useEffect, useState } from "react"
import BeneficiaryList from './beneficiaryList';

import { isNumber } from "../../Utils/Common";
import { DatePicker, Modal } from "antd";
import { toast } from 'react-toastify';
import PropagateLoader from "react-spinners/PropagateLoader";
import { POST_DMT_CREATE_CUSTOMER, POST_DMT_VERIFY_CUSTOMER } from '../../Utils/appConstants';
// import {dateofBirth} from './../../Utils/function';
import { postRequest } from '../../Utils/AppApi';
import { useHistory } from "react-router-dom";
import moment from "moment";

function ViewCustomer(props) {
    const history = useHistory();
    const [customerData, setCustomerData] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [isDataRequired, setDataRequired] = useState(false);
    const [isOtpModal, setOtpModal] = useState(false);
    const [otpCode, setOtpCode] = useState(null)
    const [getDataField, setDataField] = useState({});

    useEffect(() => {
        setCustomerData(props.isCustomerInfo);
        let lastDate = moment().subtract(19, 'years');
        let dateValue = moment(lastDate).format('YYYY-MM-DD');
        // console.log("useEffect --------------",dateValue)
        // console.log("props.isCustomerInfo-------", props.isCustomerInfo)
        if (!props.isCustomerInfo.isVerified) {
            customerRegister(props.isCustomerInfo);
            setDataField({ ...getDataField, ...props.isCustomerInfo, customerDob: dateValue  });
        }
    }, [props.isCustomerInfo]) // eslint-disable-line react-hooks/exhaustive-deps

    const customerRegister = (data) => {
        if (data) {
            setLoading(true)
            console.log("========== submit", data)
            const payload = {
                customerMobile: data.customerMobile,
                customerName: data.customerName,
                customerDob: data.customerDob,
                pincode: data.pincode,
                area: data.area,
            };

            postRequest(`${POST_DMT_CREATE_CUSTOMER}`, payload, history).then((response) => {
                if (response.success === true) {
                    if(response.data.isVerified) {
                        window.location.reload()
                    }
                    if(!response.data.isVerified) {
                        setOtpModal(true)
                        setDataRequired(false)
                    }
                    // if(response.data.pincode)
                    // console.log("========== otp")
                    toast.success(response.message);
                } else {
                    setDataRequired(true)
                    toast.error(response.message);
                }
                setLoading(false)
            }).catch(function (error) {
                // console.log(error)
                setLoading(false)
            })
        }
    }

    const onChangeOtpValue = (value) => {
        setOtpCode(value);
    }

    const handleCustomerVerify = () => {
        console.log("========== verify")
        if (otpCode) {
            setLoading(true)
            console.log("========== otpCode", otpCode)
            const payload = {
                customerMobile: customerData.customerMobile,
                otp: otpCode
            };

            postRequest(`${POST_DMT_VERIFY_CUSTOMER}`, payload, history).then((response) => {
                if (response.success === true) {
                    // handleAddBeneficiary()
                    setOtpModal(false)
                    toast.success(response.message);
                } else {
                    toast.error(response.message);
                }
                setLoading(false)
            }).catch(function (error) {
                // console.log(error)
                setOtpModal(false)
                setLoading(false)
            })
        } else {
            toast.error("OTP code is required");
        }
    }

    const onChangeDate = (date) => {
        console.log('From: ', date);
        let dateValue = moment(date).format('YYYY-MM-DD');
        setDataField({ ...getDataField, customerDob: dateValue });
    }

    const onChangeValue = (event) => {
        const { name, value } = event.target;
        setDataField({ ...getDataField, [name]: value });
    }

    const disabledDate = (d) => {
        return d.isAfter(moment().subtract(19, 'years'))
    };

    if (isLoading) {
        return <div className="content text-center" style={{ margin: "250px 0" }}>
            <PropagateLoader color="#5851D2" loading={isLoading} size={25} />
        </div>
    }

    return (
        <section>
            <div>
                <div className="row d-flex align-items-center mh-100">
                    <div className="col p-0 col-xl-12">
                        <div className="card" style={{ borderRadius: 15 }}>
                            <div className="card-body m-0">
                                {customerData && <><h3 className="mb-3">{customerData.customerName}</h3>
                                    <p className="mb-0 fx-14 text-dark">
                                        <i className="fas fa-star fa-lg text-warning" />
                                        <span className="mx-2">|</span>
                                        Mobile : <strong>{customerData.customerMobile}</strong>
                                        {/* {customerData.customerDob && <><span className="mx-2">|</span>
                                    Date Of Birth : <strong>{dateofBirth(customerData.customerDob)}</strong>
                                    </>} */}
                                        {<><span className="mx-2">|</span> Available Limit : <strong>{customerData.availableLimit}</strong></>}
                                        <span className="mx-2">|</span>
                                        Status : <strong>Non-Kyc</strong>
                                        <span className="mx-2">|</span>
                                        {/* {!customerData.isVerified && <button className="btn btn-xs btn-info">Verify Customer</button>} */}
                                    </p>
                                    <hr className="my-2" /></>}

                                {customerData && customerData.isVerified && <BeneficiaryList customerData={customerData} />}
                                {/* <div className="d-flex justify-content-start align-items-center">
                                    <p className="mb-0 text-uppercase"><i className="fas fa-cog me-2" /> <span className="text-muted small">settings</span></p>
                                    <p className="mb-0 text-uppercase"><i className="fas fa-link ms-4 me-2" /> <span className="text-muted small">program link</span></p>
                                    <p className="mb-0 text-uppercase"><i className="fas fa-ellipsis-h ms-4 me-2" /> <span className="text-muted small">program link</span>
                                        <span className="ms-3 me-4">|</span></p>
                                    <a href="#!">
                                        <img src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/avatar-2.webp" alt="avatar" className="img-fluid rounded-circle me-1" width={35} />
                                    </a>
                                    <a href="#!">
                                        <img src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/avatar-3.webp" alt="avatar" className="img-fluid rounded-circle me-1" width={35} />
                                    </a>
                                    <a href="#!">
                                        <img src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/avatar-4.webp" alt="avatar" className="img-fluid rounded-circle me-1" width={35} />
                                    </a>
                                    <a href="#!">
                                        <img src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/avatar-5.webp" alt="avatar" className="img-fluid rounded-circle me-3" width={35} />
                                    </a>
                                    <button type="button" className="btn btn-outline-dark btn-sm btn-floating">
                                        <i className="fas fa-plus" />
                                    </button>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isDataRequired && <Modal title={"Customer Detail"} width={600} open={isDataRequired} onOk={() => customerRegister(getDataField)} onCancel={() => setDataRequired(false)} okText={"Submit"} >
                <div className="form-group">
                    <label className="small mb-1" >Birthday</label>
                    <DatePicker
                        placeholder="Enter your date of birth DD-MM-YYYY"
                        className="form-control"
                        format="DD-MM-YYYY"
                        allowClear
                        defaultValue={moment().subtract(19, 'years')}
                        style={{ borderRadius: 5 }}
                        onChange={onChangeDate}
                        disabledDate={disabledDate}
                    />
                </div>
                <div className="form-group">
                    <label className="small mb-1" >PinCode</label>
                    <input className="form-control" type="text" placeholder="Pin Code" name="pincode" onKeyDown={isNumber} onChange={(e) => onChangeValue(e)} value={getDataField.pincode} maxLength={6} />
                </div>
                <div className="form-group">
                    <label className="small mb-1" >Village/Town</label>
                    <input className="form-control" type="text" placeholder="Village/Town" name="area" minLength={10} onChange={(e) => onChangeValue(e)} value={getDataField.area} maxLength={20} />
                </div>
                {/* <Button className='btn btn-primary btn-lg btn-block' onClick={() => scanFingerPrintData()}>Scan</Button> */}
            </Modal>}
            {isOtpModal && <Modal title={"Customer Verification OTP"} width={600} open={isOtpModal} onOk={() => handleCustomerVerify()} onCancel={() => setOtpModal(false)} okText={"Submit"} >
                <div className="form-group">
                    <label>OTP Code</label>
                    <div className="input-group">
                        <div className="custom-file">
                            <input type="text" className="form-control" name="otpCode" placeholder="OTP Code" onChange={(e) => onChangeOtpValue(e.target.value)} autoComplete="off" value={otpCode} maxLength={6} onKeyDown={isNumber} />
                        </div>
                    </div>
                </div>
                {/* <Button className='btn btn-primary btn-lg btn-block' onClick={() => scanFingerPrintData()}>Scan</Button> */}
            </Modal>}
        </section>
    )
}

export default ViewCustomer;