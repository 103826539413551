import React, { useEffect } from 'react';
import AppInfo from './appInfo';
import DmtSetting from './dmtSetting';
import { Collapse } from 'antd';
const { Panel } = Collapse;

function AppSetting(props) {

    // const history = useHistory();
    // const csvLink = useRef()
    // const limit = 10;
    // const dateFormat = 'DD-MM-YYYY';
    // const [search, setSearch] = useState(null);
    // const [endDate, setEndDate] = useState(null);
    // const [startDate, setStartDate] = useState(null);
    // const [getData, setData] = useState([]);
    // const [getCsvData, setCsvData] = useState([]);
    // const [getTotalRecord, setTotalRecord] = useState(0);
    // const [page, setPage] = useState(1);
    // const [isLimit, setLimit] = useState(limit);

    function callback(key) {
        console.log(key);
    }

    useEffect(() => {
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            <div className="content-wrapper pt-2">
                <div className="content-header my-0 py-0">
                    <div className="container-fluid">
                        <div className="row mb-0 align-items-center">
                            <div className="col-sm-6">
                                <h1 className='page-title text-blue font-merriweather'>App <span className='text-red'>Setting</span></h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content">
                    <div className="container-fluid pt-2 pb-5">
                        <div className="row">
                            <div className='card col-md-12 py-3'>
                                <div className='card-body p-0'>
                                    <div>
                                        <Collapse defaultActiveKey={['1']} onChange={callback}>
                                            <Panel header="App Setting" key="1">
                                                <AppInfo />
                                            </Panel>
                                            <Panel header="DMT Setting" key="2">
                                                <DmtSetting />
                                            </Panel>
                                            {/* <Panel header="This is panel header 2" key="2">
                                        <p>{text}</p>
                                        </Panel>
                                        <Panel header="This is panel header 3" key="3">
                                        <p>{text}</p>
                                        </Panel> */}
                                        </Collapse>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AppSetting