import React, { useEffect } from 'react';
import './../../../webcss/default.css';
import './../../../webcss/styles.css';
import Header from './../header';
import Footer from './../footer';

function BBPSServices() {
    useEffect(() => {
        // setReportMode(BBPSServicess);
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            <Header />
            <div>
                <div className="notice-section notice-section-sm border-top red">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-10 col-sm-10 col-12">
                                <div className="mt-5">
                                    <h6 className="fontwhite">Become a SR PAY Private Limited Franchise &amp; Grow your income 30% to 50% per month</h6>
                                </div>
                            </div>
                            <div className="col-md-2 col-sm-2 col-12">
                                <div className="right-holder-md">
                                    <a href="/joinus" className="primary-button button-lg mt-15-xs bluebtn">Join Now</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-title-section">
                    <div className="container">
                        <h1><span className="white">BBPS </span> Services</h1>
                    </div>
                </div>
                <div className="section-block">
                    <div className="container">
                        <div className="blog-list-simple">
                            <div className="row">
                                <div className="col-md-5 col-sm-5 col-12">
                                    <div className="blog-list-simple-img">
                                        <img src="img/projects/bill-payment.jpg" alt="img" />
                                    </div>
                                </div>
                                <div className="col-md-7 col-sm-7 col-12">
                                    <div className="blog-list-simple-text">
                                        {/* <span>Bill Pay </span> */}
                                        <h4 className="bluefont">Water, Electricity, Gas, <span className="white">Postpaid Bill, Landline Bill</span></h4>
                                        <p>Experience the utmost convenience of paying utility bills across India at any SR PAY Private Limited retail outlet. Empowered by Bharat Bill Pay Service (BBPS), the SR PAY Private Limited app offers a seamless bill payment service, exclusively designed for our discerning customers. Enjoy the ease of settling all your utility bills, from electricity and gas to water, mobile postpaid, and landline bills, regardless of the operator, 24/7, without incurring any late charges – all to keep your pocket happy!
                                            At SR PAY Private Limited, we believe in providing our customers with unparalleled assistance in paying their utility bills while also earning an extra income as a commission for offering these invaluable services.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="blog-list-simple">
                            <div className="row">
                                <div className="col-md-5 col-sm-5 col-12">
                                    <div className="blog-list-simple-img">
                                        <img src="img/projects/recharge.png" alt="img" />
                                    </div>
                                </div>
                                <div className="col-md-7 col-sm-7 col-12">
                                    <div className="blog-list-simple-text">
                                        {/* <span>Recharge</span> */}
                                        <h4 className="bluefont">Fastag, Prepaid Recharge, <span className="white">DTH Recharge</span></h4>
                                        <p>Discover the ultimate convenience of recharging all your prepaid mobiles, FASTag and DTH connections from the leading operators, all under one roof. Save valuable time and energy while avoiding the unnecessary hassle of visiting multiple service provider outlets and enduring long queues.
                                            As online apps gain popularity by the day, we at SR PAY Private Limited have taken this into account, offering our valued customers the seamless ability to recharge and top up all major entertainment apps. Enjoy hassle-free access to Amazon Prime, Disney Hotstar, Zee5, Sony Liv, MNX, and more, all at your fingertips.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="blog-list-simple">
                            <div className="row">
                                <div className="col-md-5 col-sm-5 col-12">
                                    <div className="blog-list-simple-img">
                                        <img src="img/projects/loan-payment.webp" alt="img" />
                                    </div>
                                </div>
                                <div className="col-md-7 col-sm-7 col-12">
                                    <div className="blog-list-simple-text">
                                        {/* <span>Services</span> */}
                                        <h4 className="bluefont">Loan <span className="white">Repayment</span></h4>
                                        <p>Elevate your financial experience with SR PAY Private Limited! Seamlessly pay your Loan EMI's and life insurance premiums for all major service providers through our user-friendly app, while earning enticing incentives to boost your monthly income. Take control of your finances and embrace the rewards of responsible financial management.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="blog-list-simple">
                            <div className="row">
                                <div className="col-md-5 col-sm-5 col-12">
                                    <div className="blog-list-simple-img">
                                        <img src="img/projects/utility-s11.jpg" alt="img" />
                                        <small className="comming-soon">Coming Soon</small>
                                    </div>
                                </div>
                                <div className="col-md-7 col-sm-7 col-12">
                                    <div className="blog-list-simple-text">
                                        {/* <span>Services</span> */}
                                        <h4 className="bluefont">Life <span className="white">Insurance</span></h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    )
}

export default BBPSServices