import React, { useEffect } from 'react';
import RBCarousel from "react-bootstrap-carousel";
// import "bootstrap/dist/css/bootstrap.css";
// import "react-bootstrap-carousel/dist/react-bootstrap-carousel.css";
// import moment from 'moment';
import './../../../webcss/default.css';
import './../../../webcss/styles.css';
import Header from './../header';
import Footer from './../footer';
// import { DatePicker, Input, Button, Select } from 'antd';

function Home() {

    const styles = { height: 480, width: "100%" };
    // const icon_glass = <span className="glyphicon glyphicon-glass" />;
    // const icon_music = <span className="glyphicon glyphicon-music" />;

    useEffect(() => {
        // setReportMode(Homes);
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            <div>
                <Header />
                <RBCarousel
                    animation={true}
                    autoplay={true}
                    slideshowSpeed={2000}
                    defaultActiveIndex={0}
                    //   leftIcon={this.state.leftIcon}
                    //   rightIcon={this.state.rightIcon}
                    //   onSelect={this._onSelect}
                    //   ref={this.slider}
                    version={4}
                >
                    <div style={{ ...styles }}>
                        <img style={{ width: "100%", height: "100%" }} alt={""} src={'img/slider/slide13.png'} />
                    </div>
                    <div style={{ ...styles }}>
                        <img style={{ width: "100%", height: "100%" }} alt={""} src={'img/slider/Aeps.jpg'} />
                    </div>
                    {/* <div style={{ ...styles }}>
                        <img style={{ width: "100%", height: "100%" }} alt={""} src={'img/slider/DMT.jpg'} />
                    </div> */}
                    <div style={{ ...styles }}>
                        <img style={{ width: "100%", height: "100%" }} alt={""} src={'img/slider/MultiRecharge.jpg'} />
                    </div>
                </RBCarousel>
                <div className="section-block">
                    <div className="container">
                        <div className="section-heading center-holder">
                            <h3>Our Services</h3>
                            <div className="section-heading-line" />
                        </div>
                        <div className="row mt-50">
                            <div className="col-lg-3 col-md-6 col-12">
                                <div className="team-box-2">
                                    <div className="team-box-2-image">
                                        <img src="img/service-1.jpg" alt="member" />
                                        <div className="team-box-2-overlay">
                                            <div className="team-box-2-name">
                                                <h4>Banking Services
                                                </h4>
                                                <a href="/banking-services"><h5>Read More</h5> </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-12">
                                <div className="team-box-2">
                                    <div className="team-box-2-image">
                                        <img src="img/service-2.jpg" alt="member" />
                                        <div className="team-box-2-overlay">
                                            <div className="team-box-2-name">
                                                <h4>BBPS Service</h4>
                                                <a href="/bbps-service"><h5>Read More</h5> </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-12">
                                <div className="team-box-2">
                                    <div className="team-box-2-image">
                                        <img src="img/service-3.jpg" alt="member" />
                                        <div className="team-box-2-overlay">
                                            <div className="team-box-2-name">
                                                <h4>Travel Services</h4>
                                                <a href="/travel-services"><h5>Read More</h5> </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-12">
                                <div className="team-box-2">
                                    <div className="team-box-2-image">
                                        <img src="img/service-4.jpg" alt="member" />
                                        <div className="team-box-2-overlay">
                                            <div className="team-box-2-name">
                                                <h4>Utility Services</h4>
                                                <a href="/utility-services"><h5>Read More</h5> </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="notice-section notice-section-sm border-top red mt-45">
                    <div className="container mt-15">
                        <div className="row">
                            <div className="col-md-10 col-sm-10 col-12">
                                <div className="mt-5">
                                    <h6 className="fontwhite">Become a SR PAY Private Limited Franchise &amp; Grow your income 30% to 50% per month
                                    </h6>
                                </div>
                            </div>
                            <div className="col-md-2 col-sm-2 col-12">
                                <div className="right-holder-md">
                                    <a href="/joinus" className="primary-button button-lg mt-15-xs bluebtn">Join Now</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    )
}

export default Home