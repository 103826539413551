import React, { useEffect, useState, useRef } from 'react';
import { Modal, DatePicker, Space, Spin } from 'antd';
import { ELETRICITY_OPERATORS, ELETRICITY_VIEW_BILL, ELETRICITY_BILL_PAYMENT } from '../../Utils/appConstants';
import { getRequest, postRequest } from '../../Utils/AppApi';
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'antd/dist/antd.css';
import moment from 'moment';
import Select from 'react-select';

function EletricityBillPayment(props) {
    const dateFormat = 'DD/MM/YYYY';
    const queryDateFormat = 'YYYY-MM-DD';
    const history = useHistory();
    const operatorSelect = useRef(null);
    const accountNumberInput = useRef(null);
    const mobileUnitInput = useRef(null);
    const dueDateSelect = useRef(null);
    const customerMobileInput = useRef(null);

    const [isModalViewBill, setIsModalViewBill] = useState(false);
    const [selectedDueDate, setSelectedDueDate] = useState(false);

    const [getOperatorData, setOperatorData] = useState([]);
    const [getDataField, setDataField] = useState([]);
    const [getDataError, setDataError] = useState({});
    const [getCustomerInfo, setCustomerInfo] = useState({});
    const [paymentId, setPaymentId] = useState(null);
    const [loading, setLoading] = useState(false);

    const clearFormField = () => {
        operatorSelect.current.value = "";
        accountNumberInput.current.value = "";
        mobileUnitInput.current.value = "";
        dueDateSelect.current.value = "";
        customerMobileInput.current.value = "";
        setSelectedDueDate(false)
        setPaymentId(null)
    };

    useEffect(() => {
        getOperators();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const getOperators = () => {
        getRequest(ELETRICITY_OPERATORS, history).then((response) => {
            if (response.success === true) {
                setOperatorData(response.data.map((item) => {
                    return { "value": item.code, "label": item.name };
                }));
            } else {
                toast.error(response.message);
            }
        }).catch(function (error) {
            console.log(error)
            // setLoading(false);
        })
    }

    const handleHideModal = () => {
        setIsModalViewBill(false);
        clearFormField()
    };

    const onChangeValueSelect = (event) => {
        const { value } = event;
        setDataField({ ...getDataField, "operator": value });
    }

    const onChangeValue = (event) => {
        const { name, value } = event.target;
        setDataField({ ...getDataField, [name]: value });
    }

    const onChangeDatePicker = (date) => {
        // console.log(date, dateString);
        let dateValue = moment(date).format(queryDateFormat);
        setDataField({ ...getDataField, dueDate: dateValue });
        setSelectedDueDate(true)
    };

    const FormValidate = () => {
        let formValid = true;
        let formObject = {};
        setDataError({})
        if (getDataField && getDataField.length === 0) {
            formValid = false;
        }
        if (getDataField.operator === "" || typeof getDataField.operator === "undefined") {
            formObject.operator = true;
            formValid = false;
        }

        if (getDataField.accountNumber === "" || typeof getDataField.accountNumber === "undefined") {
            formObject.accountNumber = true;
            formValid = false;
        }

        if (getDataField.mobileUnitCycle === "" || typeof getDataField.mobileUnitCycle === "undefined") {
            formObject.mobileUnitCycle = true;
            formValid = false;
        }

        if (getDataField.dueDate === "" || typeof getDataField.dueDate === "undefined") {
            formObject.dueDate = true;
            formValid = false;
        }

        setDataError(formObject);
        return formValid;
    }
    const viewBill = () => {
        if (FormValidate()) {
            setLoading(true)
            const payload = {
                provider: getDataField.operator,
                accountNumber: getDataField.accountNumber,
                mobileUnitCycle: getDataField.mobileUnitCycle,
                billDueDate: getDataField.dueDate,
                mobile: getDataField.customerMobile,
            }
            postRequest(ELETRICITY_VIEW_BILL, payload, history).then((response) => {
                if (response.success === true) {
                    setIsModalViewBill(true);
                    setCustomerInfo(response.data);
                    setPaymentId(response.id)
                    setDataField({ ...getDataField, amount: response.data.Billamount });
                } else {
                    setCustomerInfo({})
                    toast.error(response.message);
                }
                setLoading(false)
            }).catch(function (error) {
                // console.log(error)
                setLoading(false);
            })
        }
    }

    const billPayment = () => {
        if (FormValidate(true)) {
            setLoading(true)
            const payload = {
                provider: getDataField.operator,
                paymentId: paymentId,
                amount: getDataField.amount,
            }
            postRequest(ELETRICITY_BILL_PAYMENT, payload, history).then((response) => {
                if (response.success === true) {
                    setCustomerInfo({})
                    setDataField([])
                    setDataError({})
                    setIsModalViewBill(false)
                    clearFormField()
                    toast.success(response.message);
                } else {
                    toast.error(response.message);
                }
                setLoading(false);
            }).catch(function (error) {
                // console.log(error)
                setLoading(false);
            })
        }
    };

    

    return (
        <div>
            {loading &&
                <div className={'parentDisable'} width="100%">
                    <Space direction="vertical" style={{ width: '100%', }} >
                        <Spin tip="Loading" size="large">
                            <div className="spin-loader" />
                        </Spin>
                    </Space>
                </div>}
            <div className='pt-4'>
                <h4 className='mb-3 fa-1x'>Eletricity Bill Payment</h4>
                <div className="form-group">
                    <div className="input-group">
                        <Select options={getOperatorData} className={`normal-light w-100 ${getDataError && getDataError.operator ? "is-invalid" : ""}`} classNamePrefix="select" ref={operatorSelect} name='operator' onChange={(e) => onChangeValueSelect(e)} placeholder="Select Your Eletricity Provider" />
                    </div>
                    {getDataError && getDataError.operator && <span className="error text-danger">The field is required</span>}
                </div>
                <div className="form-group">
                    <div className="input-group">
                        <input type="text" className={`form-control normal-light ${getDataError && getDataError.accountNumber ? "is-invalid" : ""}`} placeholder="Enter Consumer Account (CA) Number" name="accountNumber" ref={accountNumberInput} maxLength={30} onChange={(e) => onChangeValue(e)} />
                    </div>
                    {getDataError && getDataError.accountNumber && <span className="error text-danger">The field is required</span>}
                </div>

                <div className="form-group">
                    <div className="position-relative">
                        <input type="text" className={`form-control normal-light  ${getDataError && getDataError.mobileUnitCycle ? "is-invalid" : ""}`} placeholder="Cycle / Unit / Mobile" name="mobileUnitCycle" ref={mobileUnitInput} maxLength={30} onChange={(e) => onChangeValue(e)} />
                    </div>
                    {getDataError && getDataError.mobileUnitCycle && <span className="error text-danger">The field is required</span>}
                </div>

                <div className="form-group">
                    <div className="position-relative">
                        {/* defaultValue={moment(new Date(), dateFormat)} */}
                        <DatePicker format={dateFormat} className={`form-control normal-light  ${getDataError && getDataError.dueDate ? "is-invalid" : ""}`} placeholder="Select Due Date" name="dueDate" ref={dueDateSelect} maxLength={10} value={!selectedDueDate ? "" : moment(getDataField.dueDate)} onChange={onChangeDatePicker} />
                    </div>
                    {getDataError && getDataError.dueDate && <span className="error text-danger">The field is required</span>}
                </div>

                <div className="form-group">
                    <div className="position-relative">
                        <input type="text" className={`form-control normal-light  `} placeholder="Customer Mobile (Optional)" name="customerMobile" ref={customerMobileInput} maxLength={10} onChange={(e) => onChangeValue(e)} />
                    </div>
                </div>

                <div className='row pt-2'>
                    <div className='col-12'>
                        <button type="button" className="btn btn-danger mb-2 btn-block" onClick={(e) => viewBill(e)}>View Bill</button>
                    </div>
                </div>
            </div>
            <ToastContainer position="top-center" autoClose={5000} limit={2} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="colored" />
            {isModalViewBill && <Modal title={"Customer Info"} width={500} open={isModalViewBill} onOk={billPayment} onCancel={handleHideModal} okText={"Payment (" + getCustomerInfo.Billamount + ")"}>
                {getCustomerInfo ?
                    <ul>
                        <li><span>Customer Name</span> : {getCustomerInfo.CustomerName}</li>
                        <li><span>Bill Number</span> : {getCustomerInfo.BillNumber}</li>
                        <li><span>Bill Date</span> : {getCustomerInfo.Billdate}</li>
                        <li><span>Bill Amount</span> : {getCustomerInfo.Billamount}</li>
                        <li><span>Due Date</span> : {getCustomerInfo.Duedate}</li>
                        <li><span>NetaAmount after Due Date</span> : {getCustomerInfo.NetAmtafterDuedate}</li>
                    </ul>
                    : ""}
            </Modal>}
        </div>
    )
}


export default EletricityBillPayment