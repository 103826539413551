
import axios from 'axios';

import { APP_KEY, API_URL} from './../config';

const instance = axios.create({ baseURL : API_URL, withCredentials: false,
    headers: {
      'Access-Control-Allow-Origin' : '*',
      'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
      "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept, Appkey, Host"
      // 'Content-Type': 'application/json', //'application/x-www-form-urlencoded',   
    //   'responseType':'json',   
  }
});

instance.defaults.headers.common['appKey'] = APP_KEY;
// instance.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
instance.defaults.headers.common['Content-Type'] = "application/json";

export default instance