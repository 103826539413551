
import React, { useEffect, useState } from "react";
// import { isNumber } from "../../Utils/Common";
import PropagateLoader from "react-spinners/PropagateLoader";
import { MOBILE_SEND_OTP, MOBILE_SEND_VERFIY, POST_UPI_TRANSFER_ADD_CUSTOMER } from '../../Utils/appConstants';
import { useHistory } from "react-router-dom";
import { postRequest } from '../../Utils/AppApi';
import { ToastContainer, toast } from 'react-toastify';
import { Modal } from "antd";
import { isNumber } from "../../Utils/Common";
// import moment from "moment";
// import { Modal, DatePicker, Divider } from "antd";

function AddCustomer(props) {
    // const dateFormat = 'DD-MM-YYYY';
    const history = useHistory();
    const customerMobile = props && props.mobile;
    const [isLoading, setLoading] = useState(false);

    const [isOtpModal, setOtpModal] = useState(false);
    const [isOtpNumberValue, setOtpNumberValue] = useState(null);
    // const [otpCode, setOtpCode] = useState(null)
    // const [otpReference, setOtpReference] = useState(null);
    const [getDataError, setDataError] = useState({});
    const [getDataField, setDataField] = useState({});
    // const [dateOfBirth, setDateOfBirth] = useState('');

    const FormValidate = () => {
        let formValid = true;
        let formObject = {};
        setDataError({})
        if (getDataField && getDataField.length === 0) {
            formValid = false;
        }

        let array = ["name", "address", "pincode", "customerMobile"];
        array.forEach(element => {
            if (getDataField[element] === "" || typeof getDataField[element] === "undefined") {
                formObject[element] = true;
                formValid = false;
            }
        });
        setDataError(formObject);
        return formValid;
    }

    const onChangeValue = (event) => {
        const { name, value } = event.target;
        setDataField({ ...getDataField, [name]: value });
    }

    const handleVerifyMobileNumber = () => {
        if (FormValidate()) {
            setLoading(true);
            const payload = { mobile: customerMobile };
            postRequest(MOBILE_SEND_OTP, payload, history).then((response) => {
                if (response.success === true) {
                    toast.success(response.message);
                    setOtpModal(true)
                } else {
                    toast.error(response.message);
                }
                setLoading(false);
            }).catch(function (error) {
                // console.log(error)
                setLoading(false);
            })
        }
    }

    const handleVerifyOtpMobileNumber = () => {
        setLoading(true);
        const payload = {
            mobile: customerMobile,
            otp: isOtpNumberValue
        }
        postRequest(MOBILE_SEND_VERFIY, payload, history).then((response) => {
            if (response.success === true) {
                toast.success(response.message);
                setOtpModal(false)
                handleSubmit();
            } else {
                toast.error(response.message);
            }
            setLoading(false);
        }).catch(function (error) {
            // console.log(error)
            setLoading(false);
        })
    }

    const handleSubmit = () => {
        if (FormValidate()) {
            setLoading(true)
            // console.log("========== submit")
            const payload = {
                mobile: customerMobile,
                name: getDataField && getDataField.name,
                email: getDataField && getDataField.email,
                address: getDataField && getDataField.address,
                pincode: getDataField && getDataField.pincode,
            };

            postRequest(`${POST_UPI_TRANSFER_ADD_CUSTOMER}`, payload, history).then((response) => {
                if (response.success === true) {
                    props.findCustomerInfo();
                    toast.success(response.message);
                } else {
                    toast.error(response.message);
                }
                setLoading(false)
            }).catch(function (error) {
                // console.log("error -----------", error)
                setLoading(false)
            })
        }
    }

    useEffect(() => {
        setDataField({ ...getDataField, customerMobile: customerMobile });
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const handleHideModal = () => {
        setOtpModal(false);
    }

    if (isLoading) {
        return <div className="content text-center" style={{ margin: "250px 0" }}>
            <PropagateLoader color="#5851D2" loading={isLoading} size={25} />
        </div>
    }
    return (
        <div className="row">
            <div className="col-xl-12">
                {/* Account details card*/}
                <div className="card m-0 ">
                    {/* <div className="card-header m-0"></div> */}
                    <div className="card-body m-0 py-0">
                        <div className="row gx-3 mb-3">
                            <div className="col-md-6">
                                <label className="small mb-1" >Mobile:</label>
                                <input className="form-control" type="text" placeholder="First Name & Last Name" name="customerMobile" value={customerMobile} onChange={(e) => onChangeValue(e)} readOnly />
                            </div>
                        </div>
                        <div className="row gx-3 mb-3">
                            <div className="col-md-6">
                                <label className="small mb-1" >Full Name:</label>
                                <input className="form-control" type="text" placeholder="First Name & Last Name" name="name" value={getDataField && getDataField.name} onChange={(e) => onChangeValue(e)} />
                                {getDataError && getDataError.name && <span className="error text-danger">The field is required</span>}
                            </div>
                            <div className="col-md-6">
                                <label className="small mb-1" >Email Address:</label>
                                <input className="form-control" type="text" placeholder="Email Address" name="email" value={getDataField && getDataField.email} onChange={(e) => onChangeValue(e)} />
                                {getDataError && getDataError.email && <span className="error text-danger">The field is required</span>}
                            </div>
                        </div>
                        <div className="row gx-3 mb-3">
                            <div className="col-md-6">
                                <label className="small mb-1" >Address:</label>
                                <input className="form-control" type="text" placeholder="Address" name="address" value={getDataField && getDataField.address} onChange={(e) => onChangeValue(e)} />
                                {getDataError && getDataError.address && <span className="error text-danger">The field is required</span>}
                            </div>
                            <div className="col-md-6">
                                <label className="small mb-1" >Pincode:</label>
                                <input className="form-control" type="text" placeholder="Pincode" name="pincode" value={getDataField && getDataField.pincode} onChange={(e) => onChangeValue(e)} />
                                {getDataError && getDataError.pincode && <span className="error text-danger">The field is required</span>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-xl-12">
                <button className="btn btn-primary float-right" onClick={() => handleVerifyMobileNumber()} type="button">Add Customer</button>
                <ToastContainer position="top-center" autoClose={5000} limit={2} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="colored" />
            </div>
            {isOtpModal && <Modal title={"Verify Mobile Verification"} width={600} open={isOtpModal} onOk={""} onCancel={handleHideModal} footer={null}><div className='row'>

                <div className='col-md-12 form-group'>
                    <label>OTP Code: </label>
                    <input type={'text'} className="form-group form-control" onChange={(e) => setOtpNumberValue(e.target.value)} maxLength={4} value={isOtpNumberValue} onKeyDown={isNumber} />
                </div>
                <div className='col-md-12 form-group text-right'>
                    <button type='button' onClick={() => handleHideModal()} className='btn btn-sm btn-default mr-2'>Cancel</button>
                    <button type='button' onClick={() => handleVerifyOtpMobileNumber()} className='btn btn-sm btn-primary'>Verify</button>
                </div>
            </div>
            </Modal>}
        </div>
    )
}

export default AddCustomer;