import React, { useEffect, useState } from "react";
import QRcode from "react-qr-code";

function GenQRCode(props) {
    const [data, setData] = useState("");
    // upi://pay?pa=srpay1@icici&pn=SRPAYSERVICES&tr=EZY12863789012&am=" + props.amount + "&cu=INR&mc=5411
    
    const [isAmount, setAmount] = useState(props.amount);
    
    useEffect(() => {
        setData(props.upiString)
        setAmount(props.amount)
    }, [props.upiString, props.amount]) // eslint-disable-line react-hooks/exhaustive-deps
    
    return (
        <>
            <div className=''>
                <div className='text-center'>
                    <div style={{fontSize: "25px", color: "red", paddingBottom: "11px"}}>0{props.time.minutes}:{props.time.seconds}</div>
                    <QRcode value={data} />
                    <h5 className="mt-3">
                        <span style={{ color : "blue"}}>This QR Code is valid for Rs:</span> <span style={{color:"red"}}>{isAmount}</span></h5>
                        <span className="text-danger">लेनदेन सफल होने के बाद QR को बंद कर दे। यह QR केवल 1 बार उपयोग के लिए वैध है ।</span>
                </div>
            </div>
        </>
    );
};

export default GenQRCode;
