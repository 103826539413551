// import React from 'react';
// import Commingsoon from '../../container/commingsoon';
// function Index(props) {
//   return (
//     <div className="content-header my-0 py-0">
//       <div className="container-fluid">
//         <div className="row mb-0 align-items-center">
//           <div className=" col-sm-12">
//             <Commingsoon />
//           </div>
//         </div>
//       </div>
//     </div>)
// }
// export default Index;

import React, { useEffect, useState, useRef } from 'react';
// import { APP_KEY, API_URL } from './../config';
import { getUser, isNumber, getTransactionId, dmtModeInArray } from './../../Utils/Common';

// import Commingsoon from '../../container/commingsoon';
import { Tabs, Tab } from 'react-bootstrap-tabs';
import { POST_SETTLEMENT_TO_BANK_SAVE, GET_PARENT_PROFILE } from './../../Utils/appConstants';
import { getRequest, postRequest } from './../../Utils/AppApi';
import Payout from './../payout';
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import AfterSave from './../../container/afterpayment';
import { Select } from "antd";
// import UpiComponent from './upi';
import UpiComponent from './../upiTransfer';
import { getGeoLocation } from '../../Utils/function';

function MoveToBank() {
  // console.log("user------", user.data)
  const user = getUser();
  const history = useHistory();
  const eligibleLimit = 10000;
  const [getDataField, setDataField] = useState([]);
  const [getDataError, setDataError] = useState({});
  const [partnerId, setPartnerId] = useState("");
  const [partnerName, setPartnerName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [myWalletBalance, setWalletBalance] = useState(0);
  const [InEligibleLimit, setEligibleLimit] = useState(0);
  const [ifscCode, setIfscCode] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [isSubmit, setSubmit] = useState(false);
  const [searchMode, setSearchMode] = useState(null);
  const amountInput = useRef(null);

  const clearFormField = () => {
    amountInput.current.value = "";
  }

  const onChangeValue = (event) => {
    const { name, value } = event.target;
    setDataField({ ...getDataField, [name]: value });
  }

  const FormValidate = () => {
    let formValid = true;
    let formObject = {};
    setDataError({})
    if (getDataField && getDataField.length === 0) {
      formValid = false;
    }

    let array = ["amount"];

    array.forEach(element => {
      if (getDataField[element] === "" || typeof getDataField[element] === "undefined") {
        formObject[element] = true;
        formValid = false;
      }

      if (getDataField.amount < eligibleLimit) {
        formValid = false;
        toast.error("Minimum amount per transaction is Rs." + eligibleLimit);
      }
      if (!searchMode) {
        formValid = false;
        toast.error("Please select transfer mode");
      }
    });

    setDataError(formObject);
    return formValid;
  }

  const handleSubmit = () => {
    if (FormValidate()) {
      const payload = {
        transactionId: getDataField.transactionId,
        ifsccode: ifscCode,
        accountNumber: accountNumber,
        partnerId: partnerId,
        partnerName: partnerName,
        amount: getDataField.amount,
        walletBalance: myWalletBalance,
        eligibleLimit: InEligibleLimit,
        channel: searchMode,
        latitude: latitude,
        longitude: longitude,
      }

      postRequest(POST_SETTLEMENT_TO_BANK_SAVE, payload, history).then((response) => {
        if (response.success === true) {
          setDataField([]);
          setDataError({});
          clearFormField();
          setSubmit(true)
          toast.success(response.message);
        } else {
          toast.error(response.message);
        }
      }).catch(function (error) {
        // console.log(error)
      })
    }
  } // eslint-disable-line react-hooks/exhaustive-deps

  const getParentProfile = () => {
    getRequest(`${GET_PARENT_PROFILE}`, history).then((response) => {
      if (response.success === true) {
        setWalletBalance(response.data.walletdetail.balance?.toFixed(2));
        setEligibleLimit((response.data.eligibleAmount).toFixed(2));
      } else {
        setWalletBalance(0);
        setEligibleLimit(0);
      }
    }).catch(function (error) {
      console.log(error)
    })
  } // eslint-disable-line react-hooks/exhaustive-deps

  const fetchLocation = async () => {
    let data = await getGeoLocation();
    setLatitude(data.latitude);
    setLongitude(data.longitude);
  }

  const getID = async () => {
    const transactionId = await getTransactionId();
    // console.log("Transaction ID:", transactionId);
    setDataField({ ...getDataField, transactionId: transactionId });
}

  useEffect(() => {
    getID();
    getParentProfile();
    fetchLocation();
    setAccountNumber(user.data.userDetails.accountNumber);
    setIfscCode(user.data.userDetails.ifscCode);
    setPartnerId(user.data.username);
    setPartnerName(user.data.name);
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const onChangeTypeMode = (value) => {
    // const { value } = event;
    // console.log(`selected ${value}`);
    setSearchMode(value);
  }

  const onchangeTab = (index, value) => {
    // setTabName(value.props.title);
  }

  return (
    <div>
      {isSubmit ? <AfterSave status={1} message={"Money send successful"} /> :
        <div className="content-wrapper pt-2">
          <div className="content-header my-0 py-0">
            <div className="container-fluid">
              <div className="row mb-0 align-items-center">
                <div className="col-sm-6">
                  <h1 className='page-title text-blue font-merriweather'>Settle To <span className='text-red'>Bank</span></h1>
                </div>
              </div>
            </div>
          </div>
          <div className="content">
            <div className="container-fluid pt-2 pb-5">
              <Tabs className="recharge-tabs" onSelect={(index, label) => onchangeTab(index, label)}>
                <Tab eventKey={1} label={<div className='tabtext' title='Bank'>Register Bank Transfer</div>}>
                  <div className="row">
                    <div className="col-md-12">
                      {(InEligibleLimit <= 0) ?
                        <div className="content card">
                          <div className="container-fluid pt-5 pb-5 text-center">
                            <h5 className="text-red mx-50"> ⁠You are not eligible to use this service. feel free to reach out to our Distributor / SR PAY Private Limited support team.<br /> Thank you for your understanding!</h5>
                          </div>
                        </div>
                        : <div className='card rounded-0 card-body'>
                          <div className='row'>
                            <div className='col-sm-6 col-md-4 col-lg-4'>
                              <div className="form-group">
                                <label>Transaction ID</label>
                                <input type="text" className={`form-control normal-light pr-110`} name="transactionId" value={getDataField && getDataField.transactionId} readOnly />
                              </div>
                            </div>
                            <div className='col-sm-6 col-md-4 col-lg-4'>
                              <div className="form-group">
                                <label>Partner ID</label>
                                <input type="text" className={`form-control normal-light pr-110`} name="partnerId" value={partnerId} readOnly />
                              </div>
                            </div>
                            <div className='col-sm-6 col-md-4 col-lg-4'>
                              <div className="form-group">
                                <label>Name</label>
                                <input type="text" className={`form-control normal-light pr-110`} name="partnerName" value={partnerName} readOnly={true} />
                              </div>
                            </div>
                            <div className='col-sm-6 col-md-4 col-lg-4'>
                              <div className="form-group">
                                <label>Account Number</label>
                                <input type="text" className={`form-control normal-light pr-110`} name="accountNumber" value={accountNumber} readOnly />
                              </div>
                            </div>
                            <div className='col-sm-6 col-md-4 col-lg-4'>
                              <div className="form-group">
                                <label>IFSC Code</label>
                                <input type="text" className={`form-control normal-light pr-110`} name="ifscCode" value={ifscCode} readOnly />
                              </div>
                            </div>
                            <div className='col-sm-6 col-md-4 col-lg-4'>
                              <div className="form-group">
                                <label>Wallet Balance</label>
                                <input type="text" className={`form-control normal-light pr-110`} name="walletbalance" value={myWalletBalance} readOnly />
                              </div>
                            </div>
                            <div className='col-sm-6 col-md-4 col-lg-4'>
                              <div className="form-group">
                                <label>Eligible Limit</label>
                                <input type="text" className={`form-control normal-light pr-110`} name="eligibleLimit" value={InEligibleLimit} readOnly />
                              </div>
                            </div>
                            <div className='col-sm-6 col-md-4 col-lg-4'>
                              <div className="form-group">
                                <label>Amount</label>
                                <input type="text" className={`form-control normal-light pr-110 ${getDataError && getDataError.amount ? "is-invalid" : ""}`} name="amount" ref={amountInput} maxLength={6} onChange={(e) => onChangeValue(e)} placeholder="Amount" onKeyDown={isNumber} value={getDataField && getDataField.amount} />
                                {getDataError && getDataError.amount && <span className="error text-danger">The field is required</span>}
                              </div>
                            </div>
                            <div className='col-sm-6 col-md-4 col-lg-4'>
                              <div className="form-group">
                                <label className=' normal-light pr-110'>&nbsp;</label>
                                <Select options={dmtModeInArray} className={`normal-light w-100`} classNamePrefix="select" placeholder="-- Payment Mode --" name='mode' onChange={(e) => onChangeTypeMode(e)} />
                              </div>
                            </div>

                            <div className='col-sm-6 col-md-4 col-lg-4'>
                              <label></label>
                              <div className='col-md-12 d-flex'>
                                <div className="form-group  pull-right text-lg-right mr-4">
                                  <button type="submit" className="btn btn-default rounded-pill pl-4 pr-4" onClick={() => clearFormField()} >Cancel</button>
                                </div>
                                <div className="form-group text-lg-right">
                                  <button type="submit" className="btn btn-primary rounded-pill pl-4 pr-4" onClick={() => handleSubmit()}>Process</button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <h3><u>Terms & Conditions</u></h3>
                          <div className="row ml-2 mt-2">
                            <div className="m-0">
                              <p className='m-0'>* Transfer timings are between 09:00 AM to 05:00 PM daily.</p>
                              <p className='m-0'>* Maximum 5 transaction allowed per day.</p>
                              <p className='m-0'>* Minimum amount per transaction is Rs.10000</p>
                            </div>
                          </div>
                        </div>}
                    </div>
                  </div>
                </Tab>
                <Tab eventKey={2} label={<div className='tabtext' title='UPI'>UPI Transfer</div>}>
                  {(InEligibleLimit <= 0) ?
                    <div className="content card">
                      <div className="container-fluid pt-5 pb-5 text-center">
                        <h5 className="text-red mx-50"> ⁠You are not eligible to use this service. feel free to reach out to our Distributor / SR PAY Private Limited support team.<br /> Thank you for your understanding!</h5>
                      </div>
                    </div>
                    : <UpiComponent />}
                </Tab>
                <Tab eventKey={3} label={<div className='tabtext' title='Remittance'>Remittance</div>}>
                  {(InEligibleLimit <= 0) ?
                    <div className="content card">
                      <div className="container-fluid pt-5 pb-5 text-center">
                        <h5 className="text-red mx-50"> ⁠You are not eligible to use this service. feel free to reach out to our Distributor / SR PAY Private Limited support team.<br /> Thank you for your understanding!</h5>
                      </div>
                    </div>
                    : <Payout />}
                </Tab>
              </Tabs>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={5000} limit={2} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="colored" />
        </div>
      }
    </div>
  )
}

export default MoveToBank