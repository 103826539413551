import React, { useEffect, useState, useRef } from 'react';
import DataTable from 'react-data-table-component';
// import { isNumber } from './../../../Utils/Common';
import { GET_SERVICES_OPERATOR_LIST, GET_SERVICES_OPERATOR } from './../../../Utils/appConstants';
import { getRequest, postRequest, putRequest } from './../../../Utils/AppApi';
import { useHistory } from "react-router-dom";
import { toast } from 'react-toastify';
// import { Switch, Modal } from 'antd';
// import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
// import Select from 'react-select';

function CreateOperators(props) {
    const history = useHistory();
    // const csvLink = useRef()
    const limit = 10;
    const [getDataField, setDataField] = useState([]);
    const [getDataError, setDataError] = useState([]);
    const [getData, setData] = useState([]);
    const [getTotalRecord, setTotalRecord] = useState(0);
    const [serviceId, setServiceId] = useState(props.id);
    const [page, setPage] = useState(1);
    const [isEdit, setEditModal] = useState(false);
    const nameInput = useRef(null);
    const codeInput = useRef(null);

    const clearFormField = () => {
        nameInput.current.value = "";
        codeInput.current.value = "";
        setDataField([]);
        setDataError({});
        setEditModal(false);
    };

    const columns = [{
        name: 'S. No.',
        selector: (row, index) => page === 1 ? (index + 1) : ((page - 1) * limit) + (index + 1),
        width: "80px"
    }, {
        name: 'Name',
        selector: row => row.name,
    }, {
        name: 'Code',
        selector: row => row.code,
    }, {
        name: 'Action',
        cell: row => <><div className='text-primary pointer' onClick={() => handleEditView(row)}>Edit</div></>,
    }];

    const objectToQueryString = (obj) => {
        var str = [];
        for (var p in obj)
            if (obj.hasOwnProperty(p)) {
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
        return str.join("&");
    }; // eslint-disable-line react-hooks/exhaustive-deps

    const params = { page: page, limit: limit };
    const getDataReports = () => {
        params.serviceId = props.id
        const queryString = objectToQueryString(params);
        getRequest(`${GET_SERVICES_OPERATOR_LIST}?${queryString}`, history).then((response) => {
            if (response.success === true) {
                setData(response.data);
                setTotalRecord(response.data.totalDocs);
                setPage(response.data.page);
            } else {
                setData([]);
                setTotalRecord(0);
                setPage(1);
            }
        }).catch(function (error) {
            console.log(error);
            // setLoading(false);
        });
    };

    const handlePageChange = page => {
        setPage(page);
    };

    const onChangeValue = (event) => {
        const { name, value } = event.target;
        if(name === 'code') {
            setDataField({ ...getDataField, [name]: value.toUpperCase() });
        } else {
            setDataField({ ...getDataField, [name]: value });
        }
    }

    const FormValidate = () => {
        let formValid = true;
        let formObject = [];
        setDataError({});
        if (getDataField && getDataField.length === 0) {
            formValid = false;
        }

        let array = [ "name", "code" ];
        array.forEach(element => {
            if (getDataField[element] === "" || typeof getDataField[element] === "undefined") {
                formObject[element] = true;
                formValid = false;
            }
        });

        setDataError(formObject);
        return formValid;
    };

    const handleEditView = (data) => {
        setDataField(data);
        setEditModal(true);
    };

    const handleSubmit = () => {
        // console.log("FormValidate ------------", FormValidate())
        if (FormValidate(true)) {
            const payload = {
                name: getDataField.name,
                code: getDataField.code,
                serviceId: serviceId
            };

            postRequest(GET_SERVICES_OPERATOR, payload, history).then((response) => {
                if (response.success === true) {
                    setDataField([]);
                    setDataError({});
                    clearFormField();
                    getDataReports();
                    toast.success(response.message);
                } else {
                    toast.error(response.message);
                }
            }).catch(function (error) {
                // console.log(error)
                // setLoading(false);
            });
        }
    };

    const handleEdit = () => {
        if (FormValidate(true)) {
            const payload = {
                name: getDataField.name,
                code: getDataField.code,
                serviceId: serviceId
            };
            putRequest(`${GET_SERVICES_OPERATOR}/${getDataField._id}`, payload, history).then((response) => {
                if (response.success === true) {
                    setDataField([]);
                    setDataError({});
                    clearFormField();
                    getDataReports();
                    toast.success(response.message);
                } else {
                    toast.error(response.message);
                }
            }).catch(function (error) {
                // console.log(error)
                // setLoading(false);
            });
        }
    };

    useEffect(() => {
        setServiceId(props.id)
        getDataReports();
    }, [page]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            <div className="col-md-12">
                <div className='card rounded-0'>
                    <div className='card-body'>
                        <div className='row'>
                            <div className='col-sm-6 col-md-6 col-lg-6'>
                                <div className="form-group">
                                    <label>Operator Name</label>
                                    <input type="text" className={`form-control normal-light ${getDataError && getDataError.name ? "is-invalid" : ""}`} name="name" ref={nameInput} maxLength={30} onChange={(e) => onChangeValue(e)} placeholder="Operator Name*" value={getDataField && getDataField.name} />
                                    {getDataError.name && <span className="error text-danger">The field is required</span>}
                                </div>
                            </div>
                            <div className='col-sm-6 col-md-6 col-lg-6'>
                                <div className="form-group">
                                    <label>Operator Code</label>
                                    <input type="text" className={`form-control normal-light ${getDataError && getDataError.code ? "is-invalid" : ""}`} name="code" ref={codeInput} maxLength={6} onChange={(e) => onChangeValue(e)} placeholder="Operator Code*" value={getDataField && getDataField.code} disabled={isEdit} />
                                    {getDataError.code && <span className="error text-danger">The field is required</span>}
                                </div>
                            </div>
                            <div className='col-sm-12 col-md-12 col-lg-12 text-right'>
                                <label className='d-none d-lg-block'>&nbsp;</label>
                                <div className="form-group">
                                    {isEdit ?
                                        <><button type="submit" className="btn btn-primary rounded-pill pl-4 pr-4" onClick={() => handleEdit()}>Update</button>
                                            <button type="clear" className="btn btn-default rounded-pill pl-4 pr-4" onClick={() => clearFormField()}>Cancel</button></>
                                        :
                                        <button type="submit" className="btn btn-primary rounded-pill pl-4 pr-4" onClick={() => handleSubmit()}>Submit</button>
                                    }
                                </div>
                            </div>
                        </div>

                        {/* <div className="content-header px-0"> */}
                        <h1 className='page-title text-blue font-merriweather mb-3'>Operator <span className='text-red'> List</span></h1>
                        {/* </div> */}

                        <div className='history-table'>
                            <div className="box-body">
                                <DataTable
                                    title={""}
                                    columns={columns}
                                    data={getData.docs}
                                    highlightOnHover
                                    pagination
                                    paginationServer
                                    paginationTotalRows={getTotalRecord}
                                    onChangeRowsPerPage={getDataReports}
                                    onChangePage={handlePageChange} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CreateOperators;
