import React, { useEffect, useState } from 'react';
import { isNumber, isNumberKey } from './../../Utils/Common';
import { AADHAAR_CARD_SEND_OTP_CODE, CREATE_USER_AADHARCARD_OTP_VERIFY, POST_DMT_PAY_SPRINT_CUSTOMER_DETAIL } from './../../Utils/appConstants';
import { postRequest } from './../../Utils/AppApi';
import { useHistory } from "react-router-dom";
import { toast } from 'react-toastify';
import { Modal } from 'antd';
// import { decryptAadhar } from '../../Utils/function';

function DmtAadharVerify(props) {
    const history = useHistory();
    // const [isWarning, setIsWarning] = useState(false);
    const [getDataField, setDataField] = useState({});
    const [aadharData, setAadharData] = useState(null);

    const [isCustomerVerifyModal, setCustomerVerifyModal] = useState(false);
    const [isOtpModal, setOtpModal] = useState(false);
    const [isOtp, setIsOtp] = useState(false);
    const [isOtpNumber, setIsOtpNumber] = useState(null);
    const [isRefId, setIsRefId] = useState(null);
    const [aadharNumber, setAadharNumber] = useState(null);
    const [aadharRegMobileNumber, setAadharRegMobileNumber] = useState(null);
    const [isServiceName, setServiceName] = useState(null);

    const aadharFormValidate = () => {
        let formValid = true;
        if (!aadharRegMobileNumber || aadharRegMobileNumber === "") {
            toast.error("Aadhaar Register Mobile Number is required");
            formValid = false;
        } else if (aadharRegMobileNumber.length !== 10) {
            toast.error("Aadhaar Register Mobile Number is must be 10 digit");
            formValid = false;
        } else if (!aadharNumber || aadharNumber === "") {
            toast.error("Aadhaar Number is required");
            formValid = false;
        }

        return formValid;
    }

    const handleAadharCardOtpVerify = () => {
        if (!aadharFormValidate()) {
            return false
        }
        if (isOtp) {
            const payload = { aadharcard: aadharNumber, aadharcardOtp: isOtpNumber, ref_id: isRefId, isKycCharge: 2 };
            postRequest(`${CREATE_USER_AADHARCARD_OTP_VERIFY}`, payload, history).then((response) => {
                if (response.success === true || response.alreadysuccess === true) {
                    setIsOtp(false)
                    setCustomerVerifyModal(true)
                    // instantKycCharge()
                    setAadharData(response.data);
                    handleComplete(response.data);
                    setOtpModal(false)
                    setDataField({ ...getDataField, "cardHolderName": response.data.name });
                    toast.success(response.message);
                } else {
                    if (!response.aadharNumber || !response.alreadysuccess) {
                        toast.error(response.message);
                    } else {
                        setIsOtp(false);
                        toast.error(response.message);
                    }
                }
            }).catch(function (error) {
                // console.log(error)
            })
        } else {
            const payload = { aadharcard: aadharNumber, mobile: props.mobile, service: props.serviceName, isInstantCharge: true };
            postRequest(`${AADHAAR_CARD_SEND_OTP_CODE}`, payload, history).then((response) => {
                if (response.success === true && response.alreadysuccess === true) {
                    setIsOtp(false)
                    setCustomerVerifyModal(true)
                    // instantKycCharge()
                    setAadharData(response.data);
                    handleComplete(response.data);
                    setOtpModal(false)
                    setDataField({ ...getDataField, "cardHolderName": response.data.name });
                    toast.success(response.message);
                } else if (response.success === true) {
                    setIsOtp(true);
                    setIsRefId(response.data.ref_id);
                    toast.success(response.message);
                } else {
                    toast.error(response.message);
                }
                setDataField({ ...getDataField, "customerMobile": aadharRegMobileNumber, "cardHolderName": response.data.name });
            }).catch(function (error) {
                // console.log(error)
                // setLoading(false);
            })
        }
    }

    const handleComplete = (data) => {
        // setCustomerVerifyModal(false)
        data.aadharRegMobileNumber = aadharRegMobileNumber;
        data.aadharNumber = aadharNumber;
        aadharDetail(data);
    }

    const aadharDetail = (data) => {
        // console.log("========== otpCode", otpCode)
        if (data) {
            const payload = {
                mobile: data.aadharRegMobileNumber,
                serviceName: isServiceName,
                name: data.name,
                city: data.split_address.dist,
                state: data.split_address.state,
                pincode: data.split_address.pincode,
                address: data.address,
                customerDob: data.dob,
                aadharNumberData: data
            };

            postRequest(`${POST_DMT_PAY_SPRINT_CUSTOMER_DETAIL}`, payload, history).then((response) => {
                if (response.success === true) {
                    // props.findCustomerInfo();
                    toast.success(response.message);
                } else {
                    toast.error(response.message);
                }
            }).catch(function (error) {
                // console.log(error)
            });
        }
    }

    const checkout = () => {
        setCustomerVerifyModal(false);
        props.findCustomerInfo();
    }

    useEffect(() => {
        console.log("props--------------", props)
        setOtpModal(true)
        setServiceName(props.serviceName)
        setAadharRegMobileNumber(props.mobile);
        // setAadharNumber(props.aadhar && decryptAadhar(props.aadhar));
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {/* {isWarning && (<Modal width={800} title={"Important Information"} open={isWarning} onCancel={() => history.push({ pathname: "/dashboard" })} onOk={() => { setIsWarning(false); setOtpModal(true) }} okText="I Agree" cancelText={false}>
                <div className="font-weight-bold text-center text-danger">
                    {props.policyHindi && <><p className="text-justify text-danger">{props.policyHindi}</p></>}
                    {props.policyEng && <><p className="text-justify text-danger">{props.policyEng}</p>
                        <p><Divider /></p></>}
                </div>
            </Modal>)} */}
            {isCustomerVerifyModal && <Modal
                title="Customer Aadhar Detail"
                width={600}
                open={isCustomerVerifyModal}
                onCancel={() => checkout()}
                okText={"Continue"}
                onOk={() => checkout()}
            ><div className="row"><div className="col-md-3">
                {aadharData && <img className="elevation-2" src={"data:image/png;base64," + aadharData.photo_link} alt="User Avatar" />}
            </div>
                    <div className="col-md-9">
                        <ol>
                            <li><strong>Name : </strong>{aadharData && aadharData.name}</li>
                            <li><strong>Father Name : </strong>{aadharData && aadharData.care_of}</li>
                            <li><strong>Address : </strong>{aadharData && aadharData.address}</li>
                        </ol>
                    </div>
                    {props.addtional && <><p className="pt-4 text-bold text-danger text-justify">{props.addtional}</p></>}
                </div>
            </Modal>}
            {isOtpModal && (<Modal
                title="Customer KYC"
                centered
                open={isOtpModal}
                onCancel={() => props.findCustomerInfo()}
                okText={"Submit"}
                // onOk={() => { (isCheckAadharAlreadyVerify) ? sendOTPOnMobileNumberVerfiy() : handleAadharCardOtpVerify() }}
                onOk={() => { handleAadharCardOtpVerify() }}
            >
                <div className=" mb-3">
                    <div className="form-group">
                        <label>Mobile Number</label>
                        <input type="text" className="form-control" name="aadharRegMobileNumber" placeholder="" onChange={(e) => setAadharRegMobileNumber(e.target.value)} autoComplete="off" readOnly value={aadharRegMobileNumber} maxLength={10} onKeyDown={isNumber} />
                    </div>
                </div>
                <div className="">
                    <div className="form-group">
                        <label>Aadhaar Number</label>
                        <input type="text" className="form-control" name="aadharNumber" placeholder="" onChange={(e) => setAadharNumber(e.target.value)} autoComplete="off" value={aadharNumber} maxLength={12} onKeyDown={() => isNumberKey} />
                    </div>
                </div>
                <hr />
                {isOtp &&
                    <div className="input-group">
                        <div className="custom-file">
                            <input type="text" className="form-control" name="otpNumber" placeholder="OTP Number" defaultValue={""} maxLength={8} onChange={(e) => setIsOtpNumber(e.target.value)} />
                        </div>
                    </div>
                }
                {props.addtional && <><p className="pt-4 text-bold text-danger text-justify">{props.addtional}</p></>}
            </Modal>)}
        </>
    )
}

export default DmtAadharVerify;