import React, { useEffect, useState, useRef } from 'react';
// import { APP_KEY, API_URL } from './../config';
// import { CSVLink } from 'react-csv';
// import DataTable from 'react-data-table-component';
// import { getTransactionId } from '../../Utils/Common';
import { SAVE_COMPLAINT } from '../../Utils/appConstants';
// import { getPaymentMode, /* getBankMode */ } from './../../Utils/function';
import { postRequest } from '../../Utils/AppApi';
// import AppHeader from '../Header/Header';
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
// import { data } from 'jquery';
import ComplaintSubmit from './../../container/complaintSubmit';
// // import Moment from 'react-moment';
import List from './list';
import { Modal } from 'antd';

function Complaint(props) {
    const history = useHistory();
    const [getDataField, setDataField] = useState([]);
    const [getDataError, setDataError] = useState({});
    const [isSubmit, setSubmit] = useState(false)

    const FormValidate = () => {
        let formValid = true;
        let formObject = {};
        setDataError({})
        if (getDataField && getDataField.length === 0) {
            formValid = false;
        }

        if (getDataField.transactionId === "" || typeof getDataField.transactionId === "undefined") {
            formObject.transactionId = true;
            formValid = false;
        }
        if (getDataField.clientReason === "" || typeof getDataField.clientReason === "undefined") {
            formObject.clientReason = true;
            formValid = false;
        }

        setDataError(formObject);
        return formValid;
    }

    const transactionIdInput = useRef(null);
    const clientReasonInput = useRef(null);

    const clearFormField = () => {
        transactionIdInput.current.value = "";
        clientReasonInput.current.value = "";
    }

    const handleSubmit = () => {
        if (FormValidate(true)) {
            const payload = {
                transactionId: getDataField.transactionId,
                clientReason: getDataField.clientReason
            }
            postRequest(SAVE_COMPLAINT, payload, history).then((response) => {
                if (response.success === true) {
                    // setSubmit(true)
                    setDataField([]);
                    setDataError({});
                    clearFormField();
                    toast.success(response.message);
                } else {
                    toast.error(response.message);
                }
            }).catch(function (error) {
                // console.log(error)
                // setLoading(false);
            })
        }
    } // eslint-disable-line react-hooks/exhaustive-deps

    const onChangeValue = (event) => {
        const { name, value } = event.target;
        // console.log("name -----------", name)
        // console.log("value -----------", value)
        setDataField({ ...getDataField, [name]: value });
    }

    useEffect(() => {
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            <div className="content-wrapper">
                <div className="content">
                    <div className="container-fluid pt-3">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="content-header">
                                    <div className="row mb-4 align-items-center">
                                        <div className="col-sm-12">
                                            <h1 className='page-title text-blue font-merriweather'>Complaint <span className='text-red'></span></h1>
                                        </div>
                                    </div>
                                </div>
                                <div className='card rounded-0'>
                                    <div className='card-body'>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <input type='text' name='transactionId' className="form-control" placeholder='Transaction Id' onChange={(e) => onChangeValue(e)} ref={transactionIdInput} />
                                                </div>
                                                {getDataError && getDataError.transactionId && <span className="error text-danger">Transaction Id is required</span>}
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <textarea type="text" rows={3} onChange={(e) => onChangeValue(e)} className="form-control" name='clientReason' placeholder="Complaint..." ref={clientReasonInput} />
                                                </div>
                                                {getDataError && getDataError.clientReason && <span className="error text-danger">Complaint is required</span>}
                                            </div>
                                        </div>
                                        <div className="card-footer text-right">
                                            <button type="submit" className="btn btn-primary col-md-2 rounded-pill pl-4 pr-4" onClick={() => handleSubmit()}>Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className='card rounded-0'>
                                    <div className='card-body'>
                                        <div className="content-header my-0 px-0 d-flex">
                                            <h1 className='page-title text-blue font-merriweather mb-3'>Complaint <span className='text-red'>History</span></h1>
                                        </div>
                                        <List />
                                    </div>
                                </div>
                            </div>
                            {isSubmit && (<Modal open={isSubmit} onCancel={() => setSubmit(false)} width={500} footer={false} title={<div className='col-md-12 row text-left'>
                                <div className='col-md-6'>
                                    <img className='text-left' src='../logo.png' alt='photos' width={"50%"} />
                                </div>
                                <div className='col-md-6 text-right'>
                                    <img className='text-right' src={'../images/bharat-billpay-logo-1@2x.png'} alt='photos' width={"40%"} />
                                </div>
                            </div>}>
                                <ComplaintSubmit message="Your complaint has been submitted successfully. Complaint no. #876398" />
                            </Modal>)}
                            <ToastContainer position="top-center" autoClose={5000} limit={2} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="colored" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Complaint