import React, { useEffect, useState, useRef } from 'react';
import { Button, Modal } from 'antd';
import { CREATE_USER_PANCARD_VERIFY, MOBILE_SEND_VERFIY, MOBILE_VERFIY_SEND, USER_ROLE, CREATE_ALL_TYPE_USERS, CREATE_USER_AADHARCARD_VERIFY, CREATE_USER_AADHARCARD_OTP_VERIFY } from './../../Utils/appConstants';
import { getRequest, postRequest } from './../../Utils/AppApi';
import { getUser, isNumber } from "./../../Utils/Common";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import RiseLoader from "react-spinners/RiseLoader";
// import AppFooter from './../Footer/Footer';
import Select from 'react-select';

const userData = getUser();

function CreateUser(props) {

    const userRoleRef = useRef(null);
    const mobileNumberRef = useRef(null);
    const otpNumberRef = useRef(null);
    const otpAadharNumberRef = useRef(null);
    const outletNameRef = useRef(null);
    const emailRef = useRef(null);
    const pancardNumberRef = useRef(null);
    const aadharNumberRef = useRef(null);

    const history = useHistory();
    const [isData, setData] = useState([]);
    const [fullName, setFullName] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [pancardData, setPancardData] = useState("");
    const [isOtpNumber, setOtpNumber] = useState(false);
    const [roleId, setRoleId] = useState(userData.data.role.code);
    const [userOrder, setUserOrder] = useState(userData.data.role.order);
    const [isMobileNumberVerify, setMobileNumberVerify] = useState(false);
    const [isPanNumberVerify, setPanNumberVerify] = useState(false);
    const [isStaff, setStaff] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [showingSubmitPopup, setShowingSubmitPopup] = useState(false);

    const [getRoleData, setRoleData] = useState([]);

    const [isAadharNumberVerify, setAadharNumberVerify] = useState(false);
    const [aadharData, setAadharData] = useState("");
    const [isOtpAadharNumber, setOtpAadharNumber] = useState(false);

    const [isOutletNameSubmit, setOutletNameSubmit] = useState(false);
    const [isEmailAddressSubmit, setEmailAddressSubmit] = useState(false);

    const clearFormField = () => {
        mobileNumberRef.current.value = "";
        otpNumberRef.current.value = "";
        otpAadharNumberRef.current.value = "";
        outletNameRef.current.value = "";
        emailRef.current.value = "";
        pancardNumberRef.current.value = "";
        aadharNumberRef.current.value = "";
        userRoleRef.current.value = "";
    };

    const handleGetRoles = () => {
        let userOrderId = userData.data.role.order;
        getRequest(`${USER_ROLE}/${userOrderId}`, history).then((response) => {
            if (response.success === true) {
                setRoleData(response.data.filter(((items) => items.code !== "V")).map((item) => {
                    return { "value": item.code, "label": item.name };
                }));
            } else {
                toast.error(response.message);
            }
        }).catch(function (error) {
            // console.log(error)
            // setLoading(false);
        })
    } // eslint-disable-line react-hooks/exhaustive-deps

    const FormValidate = () => {
        let formValid = true;
        let formObject = {};

        if (userOrder === 5 || userOrder === 4 || userOrder === 2) {
            if (isData && isData.length === 0) {
                formValid = false;
            }
            if (isData.userRole === "" || typeof isData.userRole === "undefined") {
                formObject.userRole = true;
                formValid = false;
                toast.error("Role is required");
            }
        }

        // if (isData.aadharNumber === "" || typeof isData.aadharNumber === "undefined") {
        //     formObject.aadharNumber = true;
        //     formValid = false;
        //     toast.error("Aadhar number is required");
        // }

        return formValid;
    } // eslint-disable-line react-hooks/exhaustive-deps

    const handleVerifyMobileNumber = () => {
        if (FormValidate()) {
            setLoading(true);
            const payload = {
                mobile: isData.mobileNumber,
                roleId: isData.userRole
            };
            postRequest(MOBILE_VERFIY_SEND, payload, history).then((response) => {
                if (response.success === true) {
                    toast.success(response.message);
                    setOtpNumber(true)
                } else {
                    toast.error(response.message);
                }
                setLoading(false);
            }).catch(function (error) {
                setLoading(false);
            })
        }
    } // eslint-disable-line react-hooks/exhaustive-deps

    const handleVerifyOtpMobileNumber = () => {
        setLoading(true);
        const payload = {
            mobile: isData.mobileNumber,
            otp: isData.OtpNumber
        }
        postRequest(MOBILE_SEND_VERFIY, payload, history).then((response) => {
            if (response.success === true) {
                toast.success(response.message);
                setOtpNumber(true)
                setMobileNumberVerify(true)
            } else {
                toast.error(response.message);
            }
            setLoading(false);
        }).catch(function (error) {
            // console.log(error)
            setLoading(false);
        })
    } // eslint-disable-line react-hooks/exhaustive-deps

    const pancardValidation = (text) => {
        let regex = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
        if (regex.test(text)) {
            return true;
        }
        return false;
    } // eslint-disable-line react-hooks/exhaustive-deps

    const VerifyPanNumber = (pancard) => {
        if (pancard.toUpperCase()) {
            if (pancardValidation(pancard.toUpperCase())) {
                return true;
            } else {
                toast.error("Pancard number is invalid");
                return false;
            }
        } else {
            toast.error("Pancard is required");
            return false;
        }
    } // eslint-disable-line react-hooks/exhaustive-deps

    const handleVerifyPancardNumber = async () => {
        if (VerifyPanNumber(isData.pancardNumber)) {
            setLoading(true)
            const payload = { pancard: isData.pancardNumber, roleId: isData.userRole };
            postRequest(`${CREATE_USER_PANCARD_VERIFY}`, payload, history).then((response) => {
                if (response.success) {
                    setPanNumberVerify(true)
                    setPancardData(response.data);
                    const [first, middle, last] = response.data.registered_name.split(' ');
                    // console.log("---first-------", first)
                    // console.log("---middle-------", middle)
                    // console.log("---last-------", last)
                    setFullName(response.data.registered_name);
                    setFirstName(first);
                    let lastNameAdd = '';
                    if (last) {
                        lastNameAdd = middle + ' ' + last;
                    } else {
                        lastNameAdd = middle;
                    }
                    setLastName(lastNameAdd);
                    toast.success(response.message);
                } else {
                    toast.error(response.message);
                }
                setLoading(false);
            }).catch(function (error) {
                // console.log(error)
                setLoading(false);
            })
        }
    } // eslint-disable-line react-hooks/exhaustive-deps

    const handleVerifyAadharNumber = async () => {
        // console.log("isData.userRole -----------------", isData.userRole)
        if (isData.aadharNumber) {
            setLoading(true)
            const payload = {
                aadharcard: isData.aadharNumber,
                roleId: isData.userRole,
                name: fullName
            };
            postRequest(`${CREATE_USER_AADHARCARD_VERIFY}`, payload, history).then((response) => {
                if (response.success) {
                    setOtpAadharNumber(true);
                    setAadharData(response.data);
                    if (response.alreadysuccess) {
                        setAadharNumberVerify(true);
                    }
                    if (!response.alreadysuccess && response.alreadysuccess === "undefined") {
                        toast.warning(response.message);
                    } else {
                        toast.success(response.message);
                    }
                } else {
                    toast.error(response.message);
                }
                setLoading(false);
            }).catch(function (error) {
                // console.log(error)
                setLoading(false);
            })
        }
    }

    const handleVerifyOtpAadharNumber = () => {
        setLoading(true);
        const payload = {
            aadharcard: isData.aadharNumber,
            aadharcardOtp: isData.OtpAadharNumber,
            ref_id: aadharData && aadharData.ref_id
        }
        postRequest(CREATE_USER_AADHARCARD_OTP_VERIFY, payload, history).then((response) => {
            if (response.success === true) {
                setOtpAadharNumber(false);
                setAadharNumberVerify(true);
                setAadharData(response.data);
                toast.success(response.message);
            } else {
                toast.error(response.message);
            }
            setLoading(false);
        }).catch(function (error) {
            // console.log(error)
            setLoading(false);
        })
    }

    const handleSubmit = async () => {
        const payload = {
            pancard: isData.pancardNumber,
            firstName: firstName,
            lastName: lastName,
            mobile: isData.mobileNumber,
            roleCode: roleId,
            userRole: isData.userRole,
            isStaff: isStaff,
            email: isData.email,
            outletName: isData.outletName,
            pancardData: pancardData,
            aadharcard: isData.aadharNumber,
            aadharNumberVerify: isAadharNumberVerify,
            aadharData: aadharData
        };
        postRequest(`${CREATE_ALL_TYPE_USERS}`, payload, history).then((response) => {
            if (response.success) {
                setShowingSubmitPopup(true)
                setUsername(response.data.username)
                setPassword(response.data.password)
                setMobileNumberVerify(false);
                setPanNumberVerify(false);
                setPancardData("");
                setFirstName("");
                setLastName("");
                setAadharData("");
                setOtpNumber(false);
                setData([]);
                clearFormField();
                toast.success(response.message);
            } else {
                toast.error(response.message);
            }
        }).catch(function (error) {
            // console.log(error)
            setLoading(false);
        })
    } // eslint-disable-line react-hooks/exhaustive-deps

    const handleCancelPopup = () => {
        setShowingSubmitPopup(false);
        setUsername("");
        setPassword("");
    }
    const onChangeValue = (event) => {
        const { name, value } = event.target;
        if (name === "pancardNumber") {
            setData({ ...isData, [name]: value.toUpperCase() });
        } else {
            setData({ ...isData, [name]: value });
        }
    } // eslint-disable-line react-hooks/exhaustive-deps

    const onChangeUserRoleValue = (event) => {
        const { value } = event;
        console.log("event --------------", event)
        setData({ ...isData, "userRole": value, "roleDataValue": event });
    } // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        handleGetRoles();
        setRoleId(userData.data.role.code);
        setStaff(userData.data.isStaff)
        setUserOrder(userData.data.role.order);
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    if (isLoading) {
        return <div className="content text-center" style={{ margin: "250px 0" }}>
            <RiseLoader color="#5851D2" loading={isLoading} size={25} />
        </div>
    }

    return (
        <div>
            <div className="content-wrapper pt-2">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-0 align-items-center">
                            <div className="col-sm-6">
                                <h1 className='page-title text-blue font-merriweather'>Create <span className='text-red'> Users</span></h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content">
                    <div className="container-fluid pt-2 pb-5">
                        <div className="row">
                            <div className="col-md-6">
                                <div className='card rounded-0'>
                                    <div className='card-body'>
                                        <div className="form-group">
                                            <label>Select Role</label>
                                            <div className="input-group">
                                                <Select options={getRoleData} className={`normal-light w-100`} classNamePrefix="select" placeholder="Select Role" ref={userRoleRef} value={isData && isData.roleDataValue} name='userRole' onChange={(e) => onChangeUserRoleValue(e)} />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label>Outlet Name</label>
                                            <div className="input-group">
                                                <div className="custom-file">
                                                    <input type="text" className="form-control" name="outletName" placeholder="Outlet Name" onChange={(e) => onChangeValue(e)} readOnly={isOutletNameSubmit ? true : false} 
                                                    autoComplete="off" ref={outletNameRef} value={isData && isData.outletName} />
                                                </div>
                                                <div className="input-group-append uploadbtn cursor-pointer" onClick={() => setOutletNameSubmit(true)}>
                                                    <span className="input-group-text">{isOutletNameSubmit ? "Save" : "Submit"}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label>Email Address</label>
                                            <div className="input-group">
                                                <div className="custom-file">
                                                    <input type="text" className="form-control" name="email" placeholder="Email Address" onChange={(e) => onChangeValue(e)} autoComplete="off" ref={emailRef} readOnly={isEmailAddressSubmit ? true : false} value={isData && isData.email}  />
                                                </div>
                                                <div className="input-group-append uploadbtn cursor-pointer" onClick={() => setEmailAddressSubmit(true)}>
                                                    <span className="input-group-text">{isEmailAddressSubmit ? "Save" : "Submit"}</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <label>Mobile Number</label>
                                            <div className="input-group">
                                                <div className="custom-file">
                                                    <input type="text" className="form-control" name="mobileNumber" placeholder="Mobile Number" onChange={(e) => onChangeValue(e)} autoComplete="off" maxLength={10} ref={mobileNumberRef} readOnly={isOtpNumber ? true : false} onKeyDown={isNumber} value={isData && isData.mobileNumber} />
                                                </div>
                                                {!isMobileNumberVerify && !isOtpNumber &&
                                                    <div className="input-group-append uploadbtn cursor-pointer" onClick={() => handleVerifyMobileNumber()}>
                                                        <span className="input-group-text">Verify</span>
                                                    </div>}
                                            </div>
                                        </div>
                                        {isOtpNumber && !isMobileNumberVerify &&
                                            <div className="form-group">
                                                <label>OTP Number</label>
                                                <div className="input-group">
                                                    <div className="custom-file">
                                                        <input type="text" className="form-control" name="OtpNumber" placeholder="OTP Number" onChange={(e) => onChangeValue(e)} autoComplete="off" ref={otpNumberRef} maxLength={4} onKeyDown={isNumber} />
                                                    </div>
                                                    <div className="input-group-append uploadbtn cursor-pointer" onClick={() => handleVerifyOtpMobileNumber()}>
                                                        <span className="input-group-text">Verify</span>
                                                    </div>
                                                </div>
                                            </div>}
                                        {isMobileNumberVerify &&
                                            <div className="form-group">
                                                <label>Pancard Number</label>
                                                <div className="input-group">
                                                    <div className="custom-file">
                                                        <input type="text" className="form-control" name="pancardNumber" placeholder="Enter Pancard Number" onChange={(e) => onChangeValue(e)} maxLength={10} readOnly={isPanNumberVerify ? true : false} ref={pancardNumberRef} value={isData && isData.pancardNumber} />
                                                    </div>
                                                    <div className="input-group-append uploadbtn cursor-pointer" onClick={() => handleVerifyPancardNumber()}>
                                                        <span className="input-group-text">{isPanNumberVerify ? "Verifed" : "Verify"}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {isPanNumberVerify &&
                                            <>
                                                <div className="form-group">
                                                    <label>First Name</label>
                                                    <div className="input-group">
                                                        <div className="custom-file">
                                                            <input type="text" className="form-control" defaultValue={firstName} readOnly />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label>Last Name</label>
                                                    <div className="input-group">
                                                        <div className="custom-file">
                                                            <input type="text" className="form-control" defaultValue={lastName} readOnly />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label>Aadhar Number</label>
                                                    <div className="input-group">
                                                        <div className="custom-file">
                                                            <input type="text" className="form-control" name="aadharNumber" placeholder="Enter Aadhar Number" onChange={(e) => onChangeValue(e)} maxLength={12} readOnly={(isAadharNumberVerify || isOtpAadharNumber) ? true : false} ref={aadharNumberRef} value={isData && isData.aadharNumber} />
                                                        </div>
                                                        {isAadharNumberVerify ?
                                                            <div className="input-group-append uploadbtn cursor-pointer">
                                                                <span className="input-group-text">Verifed</span>
                                                            </div>
                                                            :
                                                            <div className="input-group-append uploadbtn cursor-pointer" onClick={!isOtpAadharNumber ? () => handleVerifyAadharNumber() : ""}>
                                                                <span className="input-group-text">{isOtpAadharNumber ? "Verify" : "Verify"}</span>
                                                            </div>}
                                                    </div>
                                                </div>
                                                {isOtpAadharNumber && !isAadharNumberVerify &&
                                                    <div className="form-group">
                                                        <label>Aadhar OTP Number</label>
                                                        <div className="input-group">
                                                            <div className="custom-file">
                                                                <input type="text" className="form-control" name="OtpAadharNumber" placeholder="Aadhar OTP Number" onChange={(e) => onChangeValue(e)} autoComplete="off" ref={otpAadharNumberRef} maxLength={6} onKeyDown={isNumber} />
                                                            </div>
                                                            <div className="input-group-append uploadbtn cursor-pointer" onClick={() => handleVerifyOtpAadharNumber()}>
                                                                <span className="input-group-text">Confirm</span>
                                                            </div>
                                                        </div>
                                                    </div>}
                                            </>
                                        }

                                        {aadharData && aadharData.address && aadharData.name && aadharData.dob && <div className="form-group">
                                            <div className="custom-file">
                                                <ul>
                                                    <li>Address: <strong>{aadharData.address}</strong></li>
                                                    <li>Aadhar Name: <strong>{aadharData.name}</strong></li>
                                                    <li>Date Of Birth: <strong>{aadharData.dob}</strong></li>
                                                </ul>
                                            </div>
                                        </div>}

                                        {isPanNumberVerify && isMobileNumberVerify && isOtpNumber &&
                                            <div className="form-group">
                                                <div className="text-right">
                                                    <Button type="default" onClick={() => handleSubmit()} className='col-lg-3 ml-2'>Submit</Button>
                                                </div>
                                            </div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {showingSubmitPopup && (<Modal open={showingSubmitPopup} footer={false} onCancel={handleCancelPopup}>
                    <div className="font-weight-bold text-center text-danger" >
                        <div className="your-registration-is-successfull mt-3">
                            <p className="msg1">
                                Your Registration is successful. Please login using
                            </p>
                            <p className="user-id-xxxxx-one-time-pas">
                                Username - <span className="xxxxx-span">{username}</span> &amp; Password
                                - <span className="xxxxx-span">{password}</span>
                            </p>
                        </div>
                    </div>
                </Modal>)}
                <ToastContainer position="top-center" autoClose={5000} limit={2} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="colored" />
            </div>
        </div>
    )
}

export default CreateUser;