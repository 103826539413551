import React, { useEffect } from 'react';
import './../../../webcss/default.css';
import './../../../webcss/styles.css';
import Header from './../header';
import Footer from './../footer';

function BankingServices() {
    useEffect(() => {
        // setReportMode(BankingServicess);
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            <Header />
            <div>
                <div className="notice-section notice-section-sm border-top red">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-10 col-sm-10 col-12">
                                <div className="mt-5">
                                    <h6 className="fontwhite">Become a SR PAY Private Limited Franchise &amp; Grow your income 30% to 50% per month</h6>
                                </div>
                            </div>
                            <div className="col-md-2 col-sm-2 col-12">
                                <div className="right-holder-md">
                                    <a href="/joinus" className="primary-button button-lg mt-15-xs bluebtn">Join Now</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-title-section">
                    <div className="container">
                        <h1><span className="white">Utility </span> Services</h1>
                    </div>
                </div>
                <div className="section-block">
                    <div className="container">
                        <div className="blog-list-simple">
                            <div className="row">
                                <div className="col-md-5 col-sm-5 col-12">
                                    <div className="blog-list-simple-img">
                                        <img src="img/projects/utility-s1.jpg" alt="img" />
                                    </div>
                                </div>
                                <div className="col-md-7 col-sm-7 col-12">
                                    <div className="blog-list-simple-text">
                                        {/* <span>Services </span> */}
                                        <h4 className="bluefont">Broadband Bill <span className="white">Payment</span></h4>
                                        <p>Seamlessly pay your broadband bills, ensuring uninterrupted internet access.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="blog-list-simple">
                            <div className="row">
                                <div className="col-md-5 col-sm-5 col-12">
                                    <div className="blog-list-simple-img">
                                        <img src="img/projects/utility-s2.jpeg" alt="img" />
                                    </div>
                                </div>
                                <div className="col-md-7 col-sm-7 col-12">
                                    <div className="blog-list-simple-text">
                                        {/* <span>Services </span> */}
                                        <h4 className="bluefont">Electricity <span className="white">Bill</span></h4>
                                        <p>Easily pay your electricity bills and stay connected to the power grid.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="blog-list-simple">
                            <div className="row">
                                <div className="col-md-5 col-sm-5 col-12">
                                    <div className="blog-list-simple-img">
                                        <img src="img/projects/utility-s3.jpg" alt="img" />
                                    </div>
                                </div>
                                <div className="col-md-7 col-sm-7 col-12">
                                    <div className="blog-list-simple-text">
                                        {/* <span>Services </span> */}
                                        <h4 className="bluefont">Gas Cylinder <span className="white">Booking</span></h4>
                                        <p>Conveniently manage your gas bills with our user-friendly payment options.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="blog-list-simple">
                            <div className="row">
                                <div className="col-md-5 col-sm-5 col-12">
                                    <div className="blog-list-simple-img">
                                        <img src="img/projects/utility-s4.jpg" alt="img" />
                                    </div>
                                </div>
                                <div className="col-md-7 col-sm-7 col-12">
                                    <div className="blog-list-simple-text">
                                        {/* <span>Services </span> */}
                                        <h4 className="bluefont">Water <span className="white">Bill</span></h4>
                                        <p>Never miss a water bill payment and enjoy uninterrupted water supply.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="blog-list-simple">
                            <div className="row">
                                <div className="col-md-5 col-sm-5 col-12">
                                    <div className="blog-list-simple-img">
                                        <img src="img/projects/utility-s5.jpg" alt="img" />
                                    </div>
                                </div>
                                <div className="col-md-7 col-sm-7 col-12">
                                    <div className="blog-list-simple-text">
                                        {/* <span>Services </span> */}
                                        <h4 className="bluefont">Landline <span className="white">Bill</span></h4>
                                        <p>Recharge your prepaid mobile plans with ease and keep connected.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="blog-list-simple">
                            <div className="row">
                                <div className="col-md-5 col-sm-5 col-12">
                                    <div className="blog-list-simple-img">
                                        <img src="img/projects/utility-s6.webp" alt="img" />
                                    </div>
                                </div>
                                <div className="col-md-7 col-sm-7 col-12">
                                    <div className="blog-list-simple-text">
                                        {/* <span>Services </span> */}
                                        <h4 className="bluefont">Prepaid Mobile <span className="white">Recharge</span></h4>
                                        <p>Seamlessly pay your broadband bills, ensuring uninterrupted internet access.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="blog-list-simple">
                            <div className="row">
                                <div className="col-md-5 col-sm-5 col-12">
                                    <div className="blog-list-simple-img">
                                        <img src="img/projects/utility-s7.webp" alt="img" />
                                    </div>
                                </div>
                                <div className="col-md-7 col-sm-7 col-12">
                                    <div className="blog-list-simple-text">
                                        {/* <span>Services </span> */}
                                        <h4 className="bluefont">DTH and OTT  <span className="white">Subscriptions</span></h4>
                                        <p>Top up your DTH and OTT subscriptions effortlessly for endless entertainment.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="blog-list-simple">
                            <div className="row">
                                <div className="col-md-5 col-sm-5 col-12">
                                    <div className="blog-list-simple-img">
                                        <img src="img/projects/utility-s8.jpg" alt="img" />
                                        <small className="comming-soon">Coming Soon</small>
                                    </div>
                                </div>
                                <div className="col-md-7 col-sm-7 col-12">
                                    <div className="blog-list-simple-text">
                                        {/* <span>Services </span> */}
                                        <h4 className="bluefont">Holiday <span className="white">Packages</span></h4>
                                        <p>Explore exciting holiday packages and plan your dream vacations effortlessly.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="blog-list-simple">
                            <div className="row">
                                <div className="col-md-5 col-sm-5 col-12">
                                    <div className="blog-list-simple-img">
                                        <img src="img/projects/utility-s9.jpeg" alt="img" />
                                        <small className="comming-soon">Coming Soon</small>
                                    </div>
                                </div>
                                <div className="col-md-7 col-sm-7 col-12">
                                    <div className="blog-list-simple-text">
                                        {/* <span>Services </span> */}
                                        <h4 className="bluefont">Account <span className="white">Opening</span></h4>
                                        <p>Experience hassle-free account opening services to begin your financial journey.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="blog-list-simple">
                            <div className="row">
                                <div className="col-md-5 col-sm-5 col-12">
                                    <div className="blog-list-simple-img">
                                        <img src="img/projects/utility-s10.jpg" alt="img" />
                                        <small className="comming-soon">Coming Soon</small>
                                    </div>
                                </div>
                                <div className="col-md-7 col-sm-7 col-12">
                                    <div className="blog-list-simple-text">
                                        {/* <span>Services </span> */}
                                        <h4 className="bluefont">Health and Motor Insurance <span className="white">Premium</span></h4>
                                        <p>Safeguard your health and motor vehicles with our reliable insurance offerings.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="blog-list-simple">
                            <div className="row">
                                <div className="col-md-5 col-sm-5 col-12">
                                    <div className="blog-list-simple-img">
                                        <img src="img/projects/utility-s11.jpg" alt="img" />
                                    </div>
                                </div>
                                <div className="col-md-7 col-sm-7 col-12">
                                    <div className="blog-list-simple-text">
                                        {/* <span>Services </span> */}
                                        <h4 className="bluefont">Life Insurance <span className="white">Premium</span></h4>
                                        <p>Secure your family's future with our trusted life insurance plans.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="blog-list-simple">
                            <div className="row">
                                <div className="col-md-5 col-sm-5 col-12">
                                    <div className="blog-list-simple-img">
                                        <img src="img/projects/uti-pan.jpeg" alt="img" />
                                    </div>
                                </div>
                                <div className="col-md-7 col-sm-7 col-12">
                                    <div className="blog-list-simple-text">
                                        {/* <span>Services </span> */}
                                        <h4 className="bluefont">UTI <span className="white">Pan Card</span></h4>
                                        <p>Unlock the essential benefits of having a PAN Card – a must for every Indian citizen. At SR PAY Private Limited, we comprehend the complexities involved in obtaining a PAN Card, which is why we have streamlined the process to make it simple and hassle-free for our valued customers. Simply visit your nearby SR PAY Private Limited retailer and within a few minutes you’ll have your PAN Card. You can also update/replace your existing PAN Card without any fuss. Empower yourself with the ease of PAN Card services at SR PAY Private Limited!
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="blog-list-simple">
                            <div className="row">
                                <div className="col-md-5 col-sm-5 col-12">
                                    <div className="blog-list-simple-img">
                                        <img src="img/projects/cms.webp" alt="img" />
                                        <small className="comming-soon">Coming Soon</small>
                                    </div>
                                </div>
                                <div className="col-md-7 col-sm-7 col-12">
                                    <div className="blog-list-simple-text">
                                        {/* <span>Services</span> */}
                                        <h4 className="bluefont">CMS - <span className="white">Cash Management System</span></h4>
                                        <p>Experience the ultimate convenience with SR PAY Private Limited's CMS (Cash Management System) services, brought to you through our trusted partners. Now, you can visit any SR PAY Private Limited retailer and effortlessly deposit your monthly EMIs for loans, be it for personal or commercial vehicles and or two-wheelers. Say goodbye to long waits at bank branches and the hassle of standing in queues – at SR PAY Private Limited, we've made it easy for you to avail of this service hassle-free. With the added advantage of depositing your EMIs on time, even on bank holidays, you can bid farewell to late fees and embrace a stress-free financial journey. Simplify your life with SR PAY Private Limited's efficient CMS services today!</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="blog-list-simple">
                            <div className="row">
                                <div className="col-md-5 col-sm-5 col-12">
                                    <div className="blog-list-simple-img">
                                        <img src="img/projects/nsdl.jpg" alt="img" />
                                        <small className="comming-soon">Coming Soon</small>
                                    </div>
                                </div>
                                <div className="col-md-7 col-sm-7 col-12">
                                    <div className="blog-list-simple-text">
                                        <span>Services</span>
                                        <h4 className="bluefont">NSDL <span className="white">Pan Card</span></h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <Footer />
            </div>
        </div>
    )
}

export default BankingServices