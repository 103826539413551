import React, { useEffect, useState, useRef } from 'react';
import { CSVLink } from 'react-csv';
import DataTable from 'react-data-table-component';
import { GET_ALL_MOVE_TO_DISTRIBUTOR_REPORTS } from './../../Utils/appConstants';
import { getRequest } from './../../Utils/AppApi';
import { useHistory } from "react-router-dom";

import { DatePicker } from 'antd';
import { getExportFormat, getUTCToLocalDateTimeFormat } from '../../Utils/function';

function MoveToDistributorReports(props) {

    const history = useHistory();
    const csvLink = useRef()
    const limit = 10;

    const dateFormat = 'DD-MM-YYYY';
    // const [search, setSearch] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [getData, setData] = useState([]);
    const [getCsvData, setCsvData] = useState([]);
    const [getTotalRecord, setTotalRecord] = useState(0);
    const [page, setPage] = useState(1);
    const [searchUserId, setSearchUserId] = useState(null);

    const columns = [{
        name: 'Date',
        cell: row => getUTCToLocalDateTimeFormat(row.createdAt),
        width: "200px"
    }, {
        name: 'FTR Reference Number',
        selector: row => row.FTRReferenceNumber,
    }, {
        name: 'TXT ID',
        selector: row => row.transactionId,
    }, {
        name: 'Partner Name',
        selector: row => row.partnerDetails && row.partnerDetails.name,
    }, {
        name: 'Partner Id',
        selector: row => row.partnerDetails && row.partnerDetails.username,
    }, {
        name: 'Amount',
        selector: row => row.amount,
    }, {
        name: 'Status',
        cell: row => <>{(row.status===1) ? <div className="text-success">Approved</div> : row.status===2 ? <div className="text-danger">Rejected</div> : <div className="text-warning">Pending</div>}</>,
    },{
        name: 'Remark',
        selector: row => row.remark,
    }]

    const objectToQueryString = (obj) => {
        var str = [];
        for (var p in obj)
            if (obj.hasOwnProperty(p)) {
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
        return str.join("&");
    }

    const params = { page: page, limit: limit };

    const getDataReports = () => {
        if (startDate && endDate) {
            params.startDate = startDate ? startDate : "";
            params.endDate = endDate ? endDate : "";
        }
        params.searchUserId = searchUserId ? searchUserId : "";
        params.isReceive = true
        const queryString = objectToQueryString(params);
        getRequest(`${GET_ALL_MOVE_TO_DISTRIBUTOR_REPORTS}?${queryString}`, history).then((response) => {
            if (response.success === true) {
                setData(response.data);
                setTotalRecord(response.data.totalDocs);
                setPage(response.data.page);
            } else {
                setData([]);
                setTotalRecord(0);
                setPage(1);
            }
        }).catch(function (error) {
            console.log(error)
            // setLoading(false);
        })
    } // eslint-disable-line react-hooks/exhaustive-deps

    const getCSVDataList = () => {
        let params = {}
        params.exportsCsv = true;
        if (startDate && endDate) {
            params.startDate = startDate ? startDate : "";
            params.endDate = endDate ? endDate : "";
        }
        params.searchUserId = searchUserId ? searchUserId : "";
        params.isReceive = true
        const queryString = objectToQueryString(params);
        getRequest(`${GET_ALL_MOVE_TO_DISTRIBUTOR_REPORTS}?${queryString}`, history).then((response) => {
            if (response.success === true) {
                let csvdataRecord = [];
                let csvdata = response.data;
                csvdata.map((e) => {
                    return csvdataRecord.push({
                        "Date": getExportFormat(e.createdAt),
                        "TXT Id": e.transactionId,
                        "FTR Reference Number": e.FTRReferenceNumber,
                        "Partner Name": e.partnerDetails && e.partnerDetails.name,
                        "Partner ID": e.partnerDetails && e.partnerDetails.username,
                        "Amount": e.amount,
                        "Remark": e.remark,
                        "Status":  (e.status===1) ? "success" : (e.status===2) ? "rejected" : "pending"});
                });
                setCsvData(csvdataRecord);
                setTimeout(() => {
                    csvLink.current.link.click();
                }, 1000);
            } else {
                setCsvData([]);
            }
        }).catch(function (error) {
            console.log(error)
            // setLoading(false);
        })
    }

    const handlePageChange = page => {
        setPage(page);
    };

    const onChangeStartDate = (date, dateString) => {
        // console.log(date, dateString);
        setStartDate(dateString)
    };

    const onChangeEndDate = (date, dateString) => {
        // console.log(date, dateString);
        setEndDate(dateString)
    };

    const handleSearch = () => {
        getDataReports();
    }
    useEffect(() => {
        getDataReports();
    }, [page]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            {/* <AppHeader /> */}
            <div className="content-wrapper pt-2">
                <div className="content-header my-0 py-0">
                    <div className="container-fluid">
                        <div className="row mb-0 align-items-center">
                            <div className="col-sm-6">
                                <h1 className='page-title text-blue font-merriweather'>Move To <span className='text-red'>Distributor</span></h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content">
                    <div className="container-fluid pt-2 pb-5">
                        <div className="">
                            <div className='card col-md-12'>
                                <div className='card-body'>
                                    <div className="row">
                                        <div className='col-md-8'>
                                            <div className='date-search mb-0'>
                                                <div className='row'>
                                                    <div className='col-sm-4 col-lg-3'>
                                                        <div className="form-group">
                                                            <label className='date-search-label mb-0 text-blue'>From</label>
                                                            {/* <input type="text" className="form-control form-control-border" placeholder="27 September 2022" /> */}
                                                            <DatePicker className="form-control form-control-border" format={dateFormat} onChange={onChangeStartDate} />
                                                        </div>
                                                    </div>
                                                    <div className='col-sm-4 col-lg-3'>
                                                        <div className="form-group">
                                                            <label className='date-search-label mb-0 text-red'>To</label>
                                                            {/* <input type="text" className="form-control form-control-border" placeholder="27 September 2022" /> */}
                                                            <DatePicker className="form-control form-control-border" format={dateFormat} onChange={onChangeEndDate} />
                                                        </div>
                                                    </div>
                                                    <div className='col-sm-4 col-lg-3'>
                                                        <div className="form-group mr-3">
                                                            <label className='date-search-label mb-0 text-red'></label>
                                                            <input type="text" className="form-control form-control-border" placeholder="User ID / Name" onChange={(e) => setSearchUserId(e.target.value)} value={searchUserId} />
                                                        </div>
                                                    </div>
                                                    <div className='col-sm-4 col-lg-2'>
                                                        <div><label className='date-search-label mb-0 text-red'>&nbsp;</label></div>
                                                        <button type="submit" className="btn btn-primary rounded-pill pl-4 pr-4" onClick={() => handleSearch()}>Search</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className='float-sm-right'>
                                                <button type="button" className="btn btn-primary mr-3" onClick={() => getCSVDataList()} ><i className="fa fa-file-excel"></i> Export</button>
                                                <CSVLink
                                                    data={getCsvData}
                                                    filename='move-to-distributor-reports'
                                                    className='hidden'
                                                    ref={csvLink}
                                                    target='_blank'
                                                />
                                                {/* <button type="button" className="btn btn-danger"><i className="fa fa-file-pdf"></i> Export to PDF</button> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="container-fluid pt-2 pb-5">
                                        <DataTable
                                            title={""}
                                            columns={columns}
                                            data={getData.docs}
                                            highlightOnHover
                                            pagination
                                            paginationServer
                                            paginationTotalRows={getTotalRecord}
                                            onChangeRowsPerPage={getDataReports}
                                            onChangePage={handlePageChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MoveToDistributorReports