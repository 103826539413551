import React, { useEffect } from 'react';
// import "bootstrap/dist/css/bootstrap.css";
// import "react-bootstrap-carousel/dist/react-bootstrap-carousel.css";
// import moment from 'moment';
import './../../webcss/default.css';
import './../../webcss/styles.css';
import Header from './header';
import Footer from './footer';
// import { DatePicker, Input, Button, Select } from 'antd';

function Aboutus(props) {

    // const icon_glass = <span className="glyphicon glyphicon-glass" />;
    // const icon_music = <span className="glyphicon glyphicon-music" />;

    useEffect(() => {
        // setReportMode(Aboutuss);
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            <div>
                <Header />
                <div>
                    <div className="notice-section notice-section-sm border-top red">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-10 col-sm-10 col-12">
                                    <div className="mt-5">
                                        <h6 className="fontwhite">Become a SR PAY Private Limited Franchise &amp; Grow your income 30% to 50% per month</h6>
                                    </div>
                                </div>
                                <div className="col-md-2 col-sm-2 col-12">
                                    <div className="right-holder-md">
                                        <a href="/joinus" className="primary-button button-lg mt-15-xs bluebtn">Join Now</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="page-title-section">
                        <div className="container">
                            <h1>About <span className="white"> Us</span></h1>
                        </div>
                    </div>
                    <div className>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 col-sm-12 col-12">
                                    <div className="pl-30-md mt-40">
                                        <div className="text-content-big mt-20">
                                            <p>We “SR PAY Private Limited” with an endeavor to excel and a passion to prosper bring to you the best Fintech Service &amp; Utility Payment platform. Created with the best of efforts and with all our heart &amp; mind going behind this project we are proud to bring to you an ultimate platform for providing online payment services to the general public in India by empowering our distributors &amp; retailers through cutting edge technology to cater to their Banking and basic day to day Financial needs.</p>
                                            <p>
                                                There is nothing far and beyond technology and we “SR PAY Private Limited” believe that everyone in our country should at least have an excess to basic financial Services at the nearest point possible. In this era of technology many rural areas are still underserved and lag behind their Urban counterparts, We at "SR PAY" are committed to provide a Digital bridge between Rural &amp; Urban India by providing basic banking &amp; financial services to everyone – securely, instantly and in a hassle-free manner. </p>
                                            <p>
                                                Through unrivaled technology, we enable any local store to function as a Digital Fintech &amp; Utility Service Centre. "SR PAY Private Limited" is offering innovative banking solutions and Digital reach at Agents doorstep through its various services with industry Best Operators - like Cash Deposit, Cash Withdrawal, Balance Inquiry, Bill Payments, UPI, Bharat QR, Aadhaar Enabled Services, Air Time Recharge, POS Services, Railway Ticketing Services, etc. through our authorized agents across India. With the best possible Commission offers &amp; Dedicated Agent Care Network we strive to bring the best of services to merchants at the Lowest Cost possible in the furthermost corners of this vast country.</p>
                                            <p>
                                                Establishing ourselves as a key player in the field of online Fintech Service and utility service provider while generating handsome profits for our associates is our aim and we are committed to provide the best of services and customer satisfaction in doing so. Our employees are trained to handle the most difficult of situations with ease and a timely commitment to our partners.</p>
                                            <p>
                                                Our team has been working tirelessly and relentlessly 24*7 to integrate and provide best in class services and adding new services to develop a system wherein we could integrate the best of services and could provide one of the easiest application there is in this entire ecosystem that is easy to use and maintain and provide the best of user experience all over. Our entire focus is to always give class leading services and keep improving ourselves so as to provide a customer enriching experience.
                                            </p>
                                            <p>
                                                To be the leader in the fintech race and be at the forefront of this technological revolution is our motive and we are working whole heartedly towards achieving the same.
                                            </p>
                                            <div className="section-heading center-holder white-color">
                                                <a href="/aboutus-hindi" className="primary-button button-md mt-10">हिंदी में पढ़े</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Footer />
            </div>
        </div>
    )
}

export default Aboutus