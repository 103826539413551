import React, { useEffect, useState, useRef } from 'react';
import { CSVLink } from 'react-csv';
import DataTable from 'react-data-table-component';
import { GET_ADMIN_INSTANT_STATEMENT_REPORT } from './../../../Utils/appConstants';

import { getRequest } from './../../../Utils/AppApi';
import { useHistory } from "react-router-dom";

import { DatePicker, Tooltip } from 'antd';
import { toDayDate, queryDate } from '../../../Utils/function';

function InstantPayStatement(props) {
    const history = useHistory();
    const csvLink = useRef()
    const limit = 10;
    const dateFormat = 'DD-MM-YYYY';

    const [searchUserId, setSearchUserId] = useState(null);
    const [searchTransId, setSearchTransId] = useState(null);

    const [endDate, setEndDate] = useState(toDayDate());
    const [startDate, setStartDate] = useState(toDayDate());
    const [getData, setData] = useState([]);
    const [getCsvData, setCsvData] = useState([]);
    const [getTotalRecord, setTotalRecord] = useState(0);
    const [page, setPage] = useState(1);
    const [isLimit, setLimit] = useState(limit);

    const columns = [{
        name: 'txn Date Time',
        selector: row => <Tooltip placement="bottom" title={row.txnDateTime && row.txnDateTime}>{row.txnDateTime && row.txnDateTime}</Tooltip>,
        width: "180px",
    }, {
        name: 'Status',
        cell: row => row.status,
        width: "120px",
    }, {
        name: 'User ID',
        cell: row => row.userId,
        width: "120px",
    }, {
        name: 'ipay OrderId',
        selector: row => row.ipayOrderId,
        width: "200px",
    }, {
        name: 'Client OrderId',
        selector: row => <Tooltip placement="bottom" title={row.clientOrderId}>{row.clientOrderId}</Tooltip>,
        width: "150px",
    }, {
        name: 'Trans ID',
        selector: row => <Tooltip placement="bottom" title={row.transactionId}>{row.transactionId}</Tooltip>,
        width: "180px",
    }, {
        name: 'Product Code',
        selector: row => row.productCode,
    }, {
        name: 'Product Name',
        selector: row => row.productName,
        width: "250px",
    }, {
        name: 'Sub Product Code',
        selector: row => row.subProductCode,
        width: "120px",
    }, {
        name: 'txn Mode',
        selector: row => row.txnMode,
        width: "120px",
    }, {
        name: 'txn Charged Value',
        selector: row => row.txnChargedValue,
        width: "190px",
    }, {
        name: 'Order Value',
        selector: row => row.orderValue,
        width: "190px",
    }, {
        name: 'Convenience Fee',
        selector: row => row.convenienceFee,
        width: "190px",
    }, {
        name: 'txn Surcharge',
        selector: row => row.txnSurcharge,
        width: "190px",
    }, {
        name: 'txn Cashback',
        selector: row => row.txnCashback,
        width: "190px",
    }, {
        name: 'txn Tds',
        selector: row => row.txnTds,
        width: "190px",
    }, {
        name: 'Closing Balance',
        selector: row => row.closingBalance,
        width: "190px",
    }, {
        name: 'NarrationValue0',
        selector: row => row.narrationValue0,
        width: "190px",
    }, {
        name: 'NarrationValue1',
        selector: row => row.narrationValue1,
        width: "190px",
    }, {
        name: 'NarrationValue2',
        selector: row => row.narrationValue2,
        width: "190px",
    }, {
        name: 'NarrationValue3',
        selector: row => row.narrationValue3,
        width: "190px",
    }, {
        name: 'NarrationValue4',
        selector: row => row.narrationValue4,
        width: "190px",
    }, {
        name: 'NarrationValue4',
        selector: row => row.narrationValue4,
        width: "190px",
    }, {
        name: 'NarrationValue5',
        selector: row => row.narrationValue5,
        width: "190px",
    }, {
        name: 'NarrationValue6',
        selector: row => row.narrationValue6,
        width: "190px",
    }, {
        name: 'NarrationValue7',
        selector: row => row.narrationValue7,
        width: "190px",
    }, {
        name: 'NarrationValue8',
        selector: row => row.narrationValue8,
        width: "190px",
    }, {
        name: 'NarrationValue9',
        selector: row => row.narrationValue9,
        width: "190px",
    }, {
        name: 'Response Code',
        selector: row => row.responseCode,
        width: "190px",
    }, {
        name: 'Response Msg',
        selector: row => <Tooltip placement="left" title={row.responseMsg}>{row.responseMsg}</Tooltip>,
        width: "220px",
    }]

    const objectToQueryString = (obj) => {
        var str = [];
        for (var p in obj)
            if (obj.hasOwnProperty(p)) {
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
        return str.join("&");
    }

    const params = { page: page, limit: isLimit };

    const getDataReports = (data) => {
        if (data) {
            setLimit(data)
            params.limit = data;
        }
        if (startDate && endDate) {
            params.startDate = startDate ? queryDate(startDate) : "";
            params.endDate = endDate ? queryDate(endDate) : "";
        }

        params.searchTransId = searchTransId ? searchTransId : '';
        params.searchUserId = searchUserId ? searchUserId : '';

        const queryString = objectToQueryString(params);
        getRequest(`${GET_ADMIN_INSTANT_STATEMENT_REPORT}?${queryString}`, history).then((response) => {
            if (response.success === true) {
                setData(response.data);
                setTotalRecord(response.data.totalDocs);
                setPage(response.data.page);
            } else {
                setData([]);
                setTotalRecord(0);
                setPage(1);
            }
        }).catch(function (error) {
            console.log(error)
            // setLoading(false);
        })
    } // eslint-disable-line react-hooks/exhaustive-deps

    const getCSVDataList = () => {
        let params = {}
        params.exportsCsv = true;
        if (startDate && endDate) {
            params.startDate = startDate ? queryDate(startDate) : "";
            params.endDate = endDate ? queryDate(endDate) : "";
        }

        params.searchTransId = searchTransId ? searchTransId : '';
        params.searchUserId = searchUserId ? searchUserId : '';

        const queryString = objectToQueryString(params);
        getRequest(`${GET_ADMIN_INSTANT_STATEMENT_REPORT}?${queryString}`, history).then((response) => {
            if (response.success === true) {
                let csvdataRecord = [];
                let csvdata = response.data;
                csvdata.map((e) => {
                    return csvdataRecord.push({
                        "txn Date Time": e.txnDateTime,
                        "Status": e.status,
                        "User Id": e.userId,
                        "Ipay OrderId": e.ipayOrderId,
                        "Client OrderId": e.clientOrderId,
                        "transaction ID": e.transactionId,
                        "Ipay RefId": e.ipayRefId,
                        "Product Code": e.productCode,
                        "Product Name": e.productName,
                        "Sub Product Code": e.subProductCode,
                        "txn Mode": e.txnMode,
                        "txn Charged Value": e.txnChargedValue,
                        "Order Value": e.orderValue,
                        "Convenience Fee": e.convenienceFee,
                        "txn Surcharge": e.txnSurcharge,
                        "txn Cashback": e.txnCashback,
                        "txn Tds": e.txnTds,
                        "Closing Balance": e.closingBalance,
                        "NarrationValue0": e.narrationValue0,
                        "NarrationValue1": e.narrationValue1,
                        "NarrationValue2": e.narrationValue2,
                        "NarrationValue3": e.narrationValue3,
                        "NarrationValue4": e.narrationValue4,
                        "NarrationValue5": e.narrationValue5,
                        "NarrationValue6": e.narrationValue6,
                        "NarrationValue7": e.narrationValue7,
                        "NarrationValue8": e.narrationValue8,
                        "NarrationValue9": e.narrationValue9,
                        "Response Code": e.responseCode,
                        "Response Msg": e.responseMsg,
                    });
                });
                setCsvData(csvdataRecord);
                setTimeout(() => {
                    csvLink.current.link.click();
                }, 1000);
            } else {
                setCsvData([]);
            }
        }).catch(function (error) {
            console.log(error)
            // setLoading(false);
        })
    }

    const handlePageChange = (page, size) => {
        // console.log("-----", size)
        setPage(page);
    };

    const onChangeStartDate = (date, dateString) => {
        // console.log(date, dateString);
        setStartDate(date)
    };

    const onChangeEndDate = (date, dateString) => {
        // console.log(date, dateString);
        setEndDate(date)
    };

    const handleSearch = () => {
        getDataReports();
    }

    const handleClear = () => {
        setStartDate("");
        setEndDate("");
        setSearchTransId('');
        setSearchUserId('');
    }

    // useEffect(() => {
    //     if(searchAccountNumber || searchTransId || searchCustomerId || searchUserId) {
    //         handleSearch();
    //     }
    // }, [searchAccountNumber, searchTransId, searchCustomerId, searchUserId]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (page > 1) {
            getDataReports();
        }
    }, [page]) // eslint-disable-line react-hooks/exhaustive-deps
    // trans id | IFSC code | AC | AMT | BANK RRN | Status

    // if (isLoading) {
    //     return <div className="content text-center" style={{ margin: "250px 0" }}>
    //         <PropagateLoader color="#5851D2" loading={isLoading} size={25} />
    //     </div>
    // }

    return (
        <div>
            <div className="content-wrapper pt-2">
                <div className="content-header my-0 py-0">
                    <div className="container-fluid">
                        <div className="row mb-0 align-items-center">
                            <div className="col-sm-6">
                                <h1 className='page-title text-blue font-merriweather'>Instant Pay <span className='text-red'>Statement</span></h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content">
                    <div className="container-fluid pt-2 pb-5">
                        <div className="row">
                            <div className='card col-md-12 py-3'>
                                <div className='card-body p-0'>
                                    <div className="row">
                                        <div className='col-md-10 date-search mb-0'>
                                            <div className='row'>
                                                <div className='col-md-4 col-lg-4 d-flex'>
                                                    <div className="form-group mr-3">
                                                        <label className='date-search-label mb-0 text-blue'>From</label>
                                                        <DatePicker className="form-control form-control-border" value={startDate} format={dateFormat} onChange={onChangeStartDate} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label className='date-search-label mb-0 text-red'>To</label>
                                                        <DatePicker className="form-control form-control-border" value={endDate} format={dateFormat} onChange={onChangeEndDate} />
                                                    </div>
                                                </div>
                                                <div className='col-md-4 col-lg-4 d-flex'>
                                                    <div className="form-group mr-3">
                                                        <label className='date-search-label mb-0 text-red'></label>
                                                        <input type="text" className="form-control form-control-border" placeholder="User ID" onChange={(e) => setSearchUserId(e.target.value)} value={searchUserId} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label className='date-search-label mb-0 text-red'></label>
                                                        <input type="text" className="form-control form-control-border" placeholder="Trans ID" onChange={(e) => setSearchTransId(e.target.value)} value={searchTransId} />
                                                    </div>
                                                </div>
                                                <div className='col-md-2 col-lg-2'>
                                                    <div><label className='date-search-label mb-0 text-red'>&nbsp;</label></div>
                                                    <button type="submit" className="btn btn-primary rounded-pill pl-4 pr-4" onClick={() => handleSearch()}>Search</button>
                                                </div>
                                                <div className='col-md-1 col-lg-1'>
                                                    <div><label className='date-search-label mb-0 text-red'>&nbsp;</label></div>
                                                    <button type="submit" className="btn btn-default rounded-pill px-4" onClick={() => handleClear()}>Clear</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-2'>
                                            <div className='float-sm-right'>
                                                <button type="button" className="btn btn-primary mr-3" onClick={() => getCSVDataList()} ><i className="fa fa-file-excel"></i> Export</button>
                                                <CSVLink
                                                    data={getCsvData}
                                                    filename='instant-statement'
                                                    className='hidden'
                                                    ref={csvLink}
                                                    target='_blank'
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="container-fluid pt-2 pb-5">
                                        <DataTable
                                            title={""}
                                            columns={columns}
                                            data={getData.docs}
                                            highlightOnHover
                                            pagination
                                            paginationServer
                                            paginationTotalRows={getTotalRecord}
                                            onChangeRowsPerPage={getDataReports}
                                            onChangePage={handlePageChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InstantPayStatement