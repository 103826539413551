
import React, { useEffect, useState } from "react";
import { isNumber } from "../../Utils/Common";
import PropagateLoader from "react-spinners/PropagateLoader";
import { POST_SHOP_REGISTERD, GET_ADDRESS_FROM_LAT_LONG, UPLOAD_SHOP_IMAGE_FILE } from '../../Utils/appConstants';
import { useHistory } from "react-router-dom";
import { postRequest } from '../../Utils/AppApi';
import { toast } from 'react-toastify';
import { getGeoLocation } from './../../Utils/function';

function Shop(props) {
    const history = useHistory();
    // const customerMobile = props && props.mobile;
    const [isLoading, setLoading] = useState(false);
    // const [ifscCodeModal, setIfscCodeModal] = useState(false);
    // const [isOtpModal, setOtpModal] = useState(false);
    const [shopphoto, setShopPhoto] = useState(null)
    const [aadhaarCard, setAadhaarCard] = useState(null);
    const [shopProof, setShopProof] = useState(null);
    // const [longitude, setLongitude] = useState("");
    // const [bankId, setBankId] = useState(null);
    const [getDataError, setDataError] = useState({});
    const [getDataField, setDataField] = useState({});
    

    const FormValidate = () => {
        let formValid = true;
        let formObject = {};
        // setDataError({})
        if (getDataField && getDataField.length === 0) {
            formValid = false;
        }

        let array = ["shopname", "latitude", "longitude", "address"];
        array.forEach(element => {
            if (getDataField[element] === "" || typeof getDataField[element] === "undefined") {
                // console.log("response =============", getDataField)
                formObject[element] = true;
                formValid = false;
            }
        });

        if(!aadhaarCard) {
            formObject.aadhaarcard = true;
            formValid = false;
        }

        if(!shopphoto) {
            formObject.shopphoto = true;
            formValid = false;
        }

        setDataError(formObject);
        return formValid;
    }

    const onChangeValue = (event) => {
        const { name, value } = event.target;
        setDataField({ ...getDataField, [name]: value });
    }

    const handleSubmit = () => {
        if (FormValidate() === true) {
            setLoading(true)
            const payload = {
                shopname: getDataField.shopname,
                shopphoto: shopphoto,
                shopProof: shopProof,
                aadhaarcard: aadhaarCard,
                latitude: getDataField.latitude,
                longitude: getDataField.longitude,
                address: getDataField.address,
                responseObject: getDataField.responseObject
            };

            postRequest(`${POST_SHOP_REGISTERD}`, payload, history).then((response) => {
                if (response.success === true) {
                    props.shopregistered();
                    window.location.reload();
                    toast.success(response.message);
                } else {
                    toast.error(response.message);
                }
                setLoading(false)
            }).catch(function (error) {
                // console.log(error)
                setLoading(false)
            })
        }
    }

    const getlocationfromApi = (lat, long) => {
        const payload = { latitude: lat, longitude: long };
        postRequest(`${GET_ADDRESS_FROM_LAT_LONG}`, payload, history).then((response) => {
            if (response.success === true) {
                // console.log("response =============", response.data.display_name)
                setDataField({ ...getDataField, "responseObject": response.data, "address": response.data.display_name, "latitude": lat, "longitude": long });
                toast.success(response.message);
            } else {
                toast.error(response.message);
            }
        }).catch(function (error) {
        })
    }

    const getlocation = async () => {
        let test = await getGeoLocation();
        console.log("--------", test.latitude)
        console.log("--------", test.longitude)
        getlocationfromApi(test.latitude, test.longitude)
    }

    const handleFileChange = async (event, name) => {
        event.preventDefault();
        const data = new FormData();
        data.append('avatar', event.target.files[0], event.target.files[0].name);
        data.append('name', name);
        postRequest(UPLOAD_SHOP_IMAGE_FILE, data, history).then(async (response) => {
            if (response.success === true) {
                // console.log("response.data.fullpath ------------", response.data.fullpath)
                if(name === "shopphoto") {
                    setShopPhoto(response.data.fullpath);
                } else if(name === "aadhaarcard") {
                    setAadhaarCard(response.data.fullpath);
                } else if(name === "shopProof") {
                    setShopProof(response.data.fullpath);
                } 
                toast.success(response.message);
            } else {
                toast.error(response.message);
            }
        }).catch(function (error) {
            // console.log(error)
        })
    } // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {

    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    if (isLoading) {
        return <div className="content text-center" style={{ margin: "250px 0" }}>
          <PropagateLoader color="#5851D2" loading={isLoading} size={25} />
        </div>
      }
    return (
        <div className="row">
            <div className="col-xl-12">
                <div className="card m-0 ">
                    <div className="card-body m-0 py-0">
                        <div className="row gx-3 mb-3">
                            <div className="col-md-12">
                                <label className="small mb-1" >Shop Name</label>
                                <input className="form-control" type="text" placeholder="Enter Shop Name" name="shopname" value={getDataField.shopname} onChange={(e) => onChangeValue(e)} maxLength={50} />
                                {getDataError && getDataError.shopname && <span className="error text-danger">The field is required</span>}
                            </div>
                        </div>
                        <div className="row gx-3 mb-3">
                            <div className="col-md-6">
                                <label className="small mb-1" >Shop Photo</label>
                                <input type="file" name="shopphoto" value={getDataField.shopphoto}  onChange={(event) => handleFileChange(event, "shopphoto")} accept="image/png, image/jpeg, image/jpg" />
                                {getDataError && getDataError.shopphoto && <span className="error text-danger">The field is required</span>}
                            </div>
                            <div className="col-md-6">
                                <label className="small mb-1" >Aadhaar Card (Self Attested)</label>
                                <input type="file" name="aadhaarcard" onChange={(event) => handleFileChange(event, "aadhaarcard")} accept="image/png, image/jpeg, image/jpg" value={getDataField.aadhaarcard} />
                                {getDataError && getDataError.aadhaarcard && <span className="error text-danger">The field is required</span>}
                            </div>
                        </div>
                        <div className="row gx-3 mb-3">
                            <div className="col-md-6">
                                <label className="small mb-1" >Shop Proof</label>
                                <input type="file" name="shopProof" onChange={(event) => handleFileChange(event, "shopProof")} accept="image/png, image/jpeg, image/jpg" value={getDataField.shopProof} />
                            </div>
                        </div>
                        <div className="row gx-3 mb-3">
                            <p>Dear Partner we are marking this location as your Shop Location. Your will be able to Used our services within 1 KM of this location. Please ensure that you are on your Shop while Submitting this information.</p>
                            <p><hr/></p>
                            <p>Dear Partner, हम इस स्थान को आपकी दुकान के स्थान के रूप में चिह्नित कर रहे हैं। आप इस स्थान के 1 किमी के दायरे में हमारी सेवाओं का उपयोग कर सकेंगे। कृपया सुनिश्चित करें कि यह जानकारी सबमिट करते समय आप अपनी दुकान पर हैं</p>
                        </div>
                        <div className="row gx-3 mb-3">
                            <div className="col-md-6">
                                <label className="small mb-1" >Latitude</label>
                                <input className="form-control" type="text" placeholder="Latitude" name="latitude" onChange={(e) => onChangeValue(e)} value={getDataField.latitude} maxLength={10} onKeyDown={isNumber} readOnly />
                                {getDataError && getDataError.latitude && <span className="error text-danger">The field is required</span>}
                            </div>
                            <div className="col-md-6">
                                <label className="small mb-1" >Longitude</label>
                                <input className="form-control" type="text" placeholder="Longitude" name="longitude" onChange={(e) => onChangeValue(e)} value={getDataField.longitude} maxLength={10} onKeyDown={isNumber} readOnly />
                                {getDataError && getDataError.longitude && <span className="error text-danger">The field is required</span>}
                                <div className="text-right mt-1">
                                    <button className="btn btn-sm btn-info pull-right" onClick={() => getlocation()}>Get Location</button>
                                </div>
                            </div>
                        </div>
                        <div className="row gx-3 mb-3">
                            <div className="col-md-12">
                                <label className="small mb-1" >Address</label>
                                <input className="form-control" type="text" placeholder="Address" name="address" onChange={(e) => onChangeValue(e)} value={getDataField.address} readOnly />
                                {getDataError && getDataError.address && <span className="error text-danger">The field is required</span>}
                            </div>
                        </div>
                        <button className="btn btn-primary float-right" onClick={() => handleSubmit()} type="button">Submit</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Shop;
