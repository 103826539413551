import React, { useEffect, useState } from 'react';
// import { useHistory } from "react-router-dom";
function AfterKyc(props) {
  //   const history = useHistory();
  const [status, setStatus] = useState(0);
  const [message, setMessage] = useState("");
  useEffect(() => {
    if (props.message) {
      setMessage(props.message)
    }

    if (props.status) {
      setStatus(props.status)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="text-center my-3">
      <div className="container payment-card" style={{ border: "1px solid grey" }}>
        {status === 0 && <><div style={{ borderRadius: 200, height: 200, width: 200, background: '#ffe6e6', margin: '0 auto' }}>
          <b style={{ color: "#e60000" }} className="checkmark">x</b>
        </div><h1>Failed</h1></>}
        {status === 1 && <><div style={{ borderRadius: 200, height: 200, width: 200, background: '#F8FAF5', margin: '0 auto' }}>
          <i className="checkmark">✓</i>
        </div>
          <h1>Success</h1>
        </>
        }
        {(status === 1) ?
          <p className={'payment-card-content text-primary'} >{message}</p>
          :
          <p className={'payment-card-content text-danger'} >{message}</p>
        }
      </div>
    </div>
  )
}

export default AfterKyc;