import React, { useEffect, useState } from 'react';
// import { CSVLink } from 'react-csv';
import DataTable from 'react-data-table-component';
import { AEPS_REGISTER_DOWNLOAD_HISTORY, AEPS_REGISTER_DOWNLOAD_ZIP } from './../../../Utils/appConstants';
// import axios from 'axios';
// import { Select } from 'antd';
// import { dmtDetailStatus , dmtDetailStatusInArray } from './../../../Utils/Common';
import { getRequest, postDownloadRequest } from './../../../Utils/AppApi';
import { useHistory } from "react-router-dom";
// import PropagateLoader from "react-spinners/PropagateLoader";
import { DatePicker } from 'antd';
// import { toast } from 'react-toastify';
// import { getUTCToLocalDateTimeFormat } from '../../../Utils/function';

function AepsRegisterDownload(props) {
    const history = useHistory();
    // const csvLink = useRef()
    const limit = 10;
    const dateFormat = 'DD-MM-YYYY';
    
    // const [endDate, setEndDate] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [getData, setData] = useState([]);
    // const [getCsvData, setCsvData] = useState([]);
    const [getTotalRecord, setTotalRecord] = useState(0);
    // const [refundData, setRefundData] = useState(null);
    const [page, setPage] = useState(1);
    const [isLimit, setLimit] = useState(limit);
    // const [userId, setUserId] = useState(props.userId);
    // const [isChecked, setChecked] = useState([]);
    // const [isConfirmModal, setConfirmModal] = useState(false);

    const columns = [{
        name: 'Date',
        selector: row => row._id,
    }, {
        name: 'Action',
        selector: row => <div className="pointer" onClick={() => handleDownload(row._id)}><button className='btn btn-sm btn-success'>Download</button></div>,
    }]

    const handleDownload = (id) => {
        let payload = {}
        postDownloadRequest(`${AEPS_REGISTER_DOWNLOAD_ZIP}?date=`+id, payload, history).then((response) => {
            // console.log("response------", response)
            // if(!response.success) {
            //     console.log("url if------")
            //     toast.error(response.message)
            // } else {
                if(response) {
                    const url = window.URL.createObjectURL(new Blob([response]));
                    console.log("url------", url)
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', id+'_download.zip');
                    document.body.appendChild(link);
                    link.click();
                }
            // }
        }).catch(function (error) {
            console.log(error)
            console.error('Download error:', error);
            // setLoading(false);
        })
        // axios({
        //   url: "http://localhost:2000"+AEPS_REGISTER_DOWNLOAD_ZIP, // Replace with your backend URL
        //   method: 'POST',
        //   data: { date : id},
        //   responseType: 'arraybuffer',
        // })
        // .then((response) => {
        //   const url = window.URL.createObjectURL(new Blob([response.data]));
        //   const link = document.createElement('a');
        //   link.href = url;
        //   link.setAttribute('download', 'download.zip');
        //   document.body.appendChild(link);
        //   link.click();
        // })
        // .catch((error) => {
        //   console.error('Download error:', error);
        // });
      };

    const objectToQueryString = (obj) => {
        var str = [];
        for (var p in obj)
            if (obj.hasOwnProperty(p)) {
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
        return str.join("&");
    }

    const params = { page: page, limit: isLimit };

    const getDataReports = (data) => {
        if (data) {
            setLimit(data)
            params.limit = data;
        }
        if (startDate) {
            params.startDate = startDate ? startDate : "";
            // params.endDate = endDate ? endDate : "";
        }

        const queryString = objectToQueryString(params);
        getRequest(`${AEPS_REGISTER_DOWNLOAD_HISTORY}?${queryString}`, history).then((response) => {
            if (response.success === true) {
                setData(response.data);
                setTotalRecord(response.data.totalDocs);
                setPage(response.data.page);
            } else {
                setData([]);
                setTotalRecord(0);
                setPage(1);
            }
        }).catch(function (error) {
            console.log(error)
            // setLoading(false);
        })
    } // eslint-disable-line react-hooks/exhaustive-deps

    const handlePageChange = (page, size) => {
        // console.log("-----", size)
        setPage(page);
    };

    const onChangeStartDate = (date, dateString) => {
        // console.log(date, dateString);
        setStartDate(dateString)
    };

    // const onChangeEndDate = (date, dateString) => {
    //     // console.log(date, dateString);
    //     setEndDate(dateString)
    // };

    // const onChangeType = (value) => {
    //     // const { value } = event;
    //     // // console.log(`selected ${value}`);
    //     setSearchStatus(value);
    // }

    const handleSearch = () => {
        getDataReports();
    }

    const handleClear = () => {
        // setSearchAccountNumber('');
        // setSearchTransId('');
        // setSearchCustomerId('');
        // setSearchUserId('');
    }

    // useEffect(() => {
    //     handleSearch();
    // }, [searchAccountNumber, searchTransId, searchCustomerId, searchUserId]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        getDataReports();
    }, [page]) // eslint-disable-line react-hooks/exhaustive-deps
    // trans id | IFSC code | AC | AMT | BANK RRN | Status

// if (isLoading) {
//     return <div className="content text-center" style={{ margin: "250px 0" }}>
//         <PropagateLoader color="#5851D2" loading={isLoading} size={25} />
//     </div>
// }

    return (
        <div>
            <div className="content-wrapper pt-2">
                <div className="content-header my-0 py-0">
                    <div className="container-fluid">
                        <div className="row mb-0 align-items-center">
                            <div className="col-sm-6">
                                <h1 className='page-title text-blue font-merriweather'>AePS Download <span className='text-red'> Report</span></h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content">
                    <div className="container-fluid pt-2 pb-5">
                        <div className="row">
                            <div className='card col-md-12 py-3'>
                                <div className='card-body p-0'>
                                    <div className="row">
                                        <div className='col-md-12 date-search mb-0'>
                                            <div className='row'>
                                                <div className='col-md-2 col-lg-2 d-flex'>
                                                    <div className="form-group">
                                                        <label className='date-search-label mb-0 text-blue'>From</label>
                                                        <DatePicker className="form-control form-control-border" format={dateFormat} onChange={onChangeStartDate} />
                                                    </div>
                                                    {/* <div className="form-group">
                                                        <label className='date-search-label mb-0 text-red'>To</label>
                                                        <DatePicker className="form-control form-control-border" format={dateFormat} onChange={onChangeEndDate} />
                                                    </div> */}
                                                </div>
                                                <div className='col-md-1 col-lg-1'>
                                                    <div><label className='date-search-label mb-0 text-red'>&nbsp;</label></div>
                                                    <button type="submit" className="btn btn-primary rounded-pill pl-4 pr-4" onClick={() => handleSearch()}>Search</button>
                                                </div>
                                                <div className='col-md-1 col-lg-1'>
                                                    <div><label className='date-search-label mb-0 text-red'>&nbsp;</label></div>
                                                    <button type="submit" className="btn btn-default rounded-pill px-4" onClick={() => handleClear()}>Clear</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="container-fluid pt-2 pb-5">
                                        <DataTable
                                            title={""}
                                            columns={columns}
                                            data={getData.docs}
                                            highlightOnHover
                                            pagination
                                            paginationServer
                                            paginationTotalRows={getTotalRecord}
                                            onChangeRowsPerPage={getDataReports}
                                            onChangePage={handlePageChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AepsRegisterDownload