import React, { useEffect, useState } from 'react';
// import { APP_KEY, API_URL } from './../config';
// import { getToken } from './../Utils/Common';
import { getUTCToLocalTime } from './../Utils/function';
import { GET_MYPROFILE } from './../Utils/appConstants';
import { getRequest, /*postRequest*/ } from '../Utils/AppApi';
// import AppHeader from './Header/Header';
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
// import AppFooter from './Footer/Footer';
// import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';

function MyProfile(props) {

  const history = useHistory();
  const [getData, setGetData] = useState({});
  const [getDataDetail, setGetDetailData] = useState({});
  const [isProfileCheck, setIsProfileCheck] = useState(false);

  const getProfile = () => {
    // setLoading(true);
    getRequest(GET_MYPROFILE, history).then((response) => {
      if (response.success === true) {
        setGetData(response.data[0]);
        setGetDetailData(response.data[0].userdetail)
        console.log("response.data ========", response.data[0].userdetail)
        setIsProfileCheck(true);
      } else {
        toast.error(response.message);
        setIsProfileCheck(false);
      }
      // setLoading(false);
    }).catch(function (error) {
      // console.log(error)
      // setLoading(false);
    })
  }

  useEffect(() => {
    getProfile();
  },[isProfileCheck]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      {/* <AppHeader /> */}
      <div className="content-wrapper pt-2">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-0 align-items-center">
              <div className="col-sm-6">
                <h1 className='page-title text-blue font-merriweather'>Profile <span className='text-red'> Info</span></h1>
              </div>
              <div className="col-sm-6">
                <div className='float-sm-right settings-text'><a href='/account-settings'>Account Settings <i className='fas fa-cog text-blue'></i></a> </div>
              </div>
            </div>
          </div>
        </div>
        <div className="content">
          <div className="container-fluid pt-2 pb-5">
            <div className="row">
              <div className="col-md-6">
                <div className='card rounded-0'>
                  <div className='widget-user-2'>
                    <div className="widget-user-header rounded-0 bg-info profile-info">
                      <div className="widget-user-image">
                        {(getDataDetail.profilePic) 
                        ?
                        <img className="img-circle elevation-2" src={"data:image/png;base64,"+getDataDetail.profilePic} alt="User Avatar" />
                        :
                        <img className="img-circle elevation-2" src="../user-icon.png" alt="User Avatar" />}
                      </div>

                      <h4 className="widget-user-username font-weight-semibold text-white">{getData && getData.name }</h4>
                      <p className="widget-user-desc text-white">User ID: {getData && getData.username } <br />
                      {getData && getData.mobile }
                      </p>
                    </div>
                  </div>
                  <div className='card-body info-detail'>
                    <ul className="nav flex-column ">
                      <li className="nav-item">
                        <strong>Pan Number</strong> <span className="float-right">{getDataDetail && getDataDetail.pancardNumber }</span>
                      </li>
                      <li className="nav-item">
                        <strong>Profile Pin</strong> <span className="float-right">{getDataDetail && getDataDetail.profilePin }</span>
                      </li>
                      <li className="nav-item">
                        <strong>Outlet Name</strong> <span className="float-right">{getDataDetail && getDataDetail.outletName }</span>
                      </li>
                      <li className="nav-item">
                        <strong>What's App Number</strong> <span className="float-right">{getDataDetail && getDataDetail.whatsapp }</span>
                      </li>
                      <li className="nav-item">
                        <strong>Mobile Number</strong> <span className="float-right">{getDataDetail && getDataDetail.mobile }</span>
                      </li>
                      <li className="nav-item">
                        <strong>Email ID</strong> <span className="float-right">{getDataDetail && getDataDetail.email }</span>
                      </li>
                      <li className="nav-item">
                        <strong>SR PAY wallet ID</strong> <span className="float-right">{getDataDetail && getDataDetail.walletId }</span>
                      </li>
                      <li className="nav-item">
                        <strong>ID Creation Date</strong> <span className="float-right">{getDataDetail && getUTCToLocalTime(getDataDetail.createdAt) }</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className='card rounded-0'>
                  <div className='card-body'>
                    <div className="form-group">
                      <label>Communication Address</label>
                      <textarea className='form-control normal-dark' disabled rows={"4"} defaultValue={getDataDetail && getDataDetail.communicationAddress}></textarea>
                    </div>
                    <div className="form-group">
                      <label>Business Address</label>
                      <textarea className='form-control normal-dark' disabled rows={"4"} defaultValue={getDataDetail && getDataDetail.businessAddress}></textarea>
                    </div>
                    <div className="form-group">
                      <label>Registered Address</label>
                      <textarea className='form-control normal-dark' disabled rows={"4"} defaultValue={getDataDetail && getDataDetail.registeredAddress}></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer/>
      </div>
      {/* <AppFooter /> */}
    </div>
  )
}


// const useFormInput = initialValue => {
//   const [value, setValue] = useState(initialValue);
//   const handleChange = e => {
//     setValue(e.target.value);
//   }
//   return {
//     value,
//     onChange: handleChange
//   }
// }

export default MyProfile