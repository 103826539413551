import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { GET_BIO_DEVICE_LIST} from './../../Utils/appConstants';
import { getRequest } from './../../Utils/AppApi';
import { useHistory } from "react-router-dom";
import { Tooltip } from 'antd';

function Devicelist(props) {
    const history = useHistory();
    // const csvLink = useRef()
    // const limit = 10;
    const [getData, setData] = useState([]);
    // const [getCsvData, setCsvData] = useState([]);
    const [getTotalRecord, setTotalRecord] = useState(0);
    const [page, setPage] = useState(1);
    // const [isLimit, setLimit] = useState(limit);

    const columns = [{
        name: 'Date Time',
        cell: row => row.serialNumber,
        // width: "180px",
        headerStyle: (selector, id) => {
            return { textAlign: "center" };   // removed partial line here
        },
    }, {
        name: 'Name',
        selector: row => <Tooltip placement="bottom" title={row.name}>{row.name}</Tooltip>,
        // width: "150px",
        headerStyle: (selector, id) => {
            return { textAlign: "center" };   // removed partial line here
        },
    }, {
        name: 'Image',
        selector: row => <><img class="rounded-circle" width={"20%"} alt="device" src={row.image} /></>,
        // width: "150px",
        headerStyle: (selector, id) => {
            return { textAlign: "center" };   // removed partial line here
        },
    }, {
        name: 'Active',
        selector: row => <>{row.isDefault ? <span className="text-success">Active</span> : <span className="text-danger">Deactive</span>}</>,
        // width: "120px",
        headerStyle: (selector, id) => {
            return { textAlign: "center" };   // removed partial line here
        },
    // }, {
    //     name: 'Action',
    //     selector: row => <><i class="fa fa-download pointer" aria-hidden="true"></i></>,
    //     // width: "120px",
    //     headerStyle: (selector, id) => {
    //         return { textAlign: "center" };   // removed partial line here
    //     },
    }]

    const objectToQueryString = (obj) => {
        var str = [];
        for (var p in obj)
            if (obj.hasOwnProperty(p)) {
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
        return str.join("&");
    }

    const params = { };

    // const handleChange = (value) => {
    //     const payload = { deviceId: value }
    //     postRequest(POST_BIO_DEVICE_LIST, payload, history).then((response) => {
    //         if (response.success === true) {
    //             toast.success(response.message);
    //             getDataReports()
    //         } else {
    //             toast.error(response.message);
    //         }
    //     }).catch(function (error) {
    //         // console.log(error)
    //         // setLoading(false);
    //     })
    // };

    const getDataReports = () => {
        params.isWeb = true
        const queryString = objectToQueryString(params);
        getRequest(`${GET_BIO_DEVICE_LIST}?${queryString}`, history).then((response) => {
            if (response.success === true) {

                const dataWithSerialNumber = response.data.map((row, index) => ({
                    ...row,
                    serialNumber: index + 1,
                  }));

                setData(dataWithSerialNumber);
                setTotalRecord(response.data.totalDocs);
                setPage(response.data.page);
            } else {
                setData([]);
                setTotalRecord(0);
                setPage(1);
            }
        }).catch(function (error) {
            console.log(error)
            // setLoading(false);
        })
    } // eslint-disable-line react-hooks/exhaustive-deps

    const handlePageChange = (page, size) => {
        // console.log("-----", size)
        setPage(page);
    };

    const handleSearch = () => {
        getDataReports();
    }

    useEffect(() => {
        handleSearch();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        getDataReports();
    }, [page]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            <div className="container-fluid pt-2 pb-5">
                <DataTable
                    title={""}
                    columns={columns}
                    data={getData}
                    highlightOnHover
                    pagination
                    paginationServer
                    paginationTotalRows={getTotalRecord}
                    onChangeRowsPerPage={getDataReports}
                    onChangePage={handlePageChange}
                />
            </div>
        </div>
    )
}

export default Devicelist