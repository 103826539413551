import React, { useEffect, useState, useRef } from 'react';
import { isNumber } from './../../Utils/Common';
import { SEARCH_PARTNER_LEDGER_API, PROFILE_PIN_MATCH_API } from './../../Utils/appConstants';
import { postRequest } from './../../Utils/AppApi';
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import Ledger from './ledger';
// import AfterSave from './../../container/afterpayment';
// import moment from 'moment';
import { Divider, Modal } from 'antd';
// const user = getUser();
function PartnerLedger() {
    // console.log("user------", user.data)
    const history = useHistory();
    const [getDataField, setDataField] = useState({});
    const [getDataError, setDataError] = useState({});
    const [profileModel, setProfileModel] = useState(false);
    const [inUserId, setUserId] = useState(null);
    const userIdInput = useRef(null);

    const clearFormField = () => {
        userIdInput.current.value = "";
        setDataField({});
        setDataError({});
    }

    const onChangeValue = (event) => {
        const { name, value } = event.target;
        setDataField({ ...getDataField, [name]: value });
    }

    const FormValidate = () => {
        let formValid = true;
        let formObject = {};
        setDataError({})
        if (getDataField && getDataField.length === 0) {
            formValid = false;
        }
        
        if (getDataField.pin === "" || typeof getDataField.pin === "undefined") {
            formObject.pin = true;
            formValid = false;
        }

        setDataError(formObject);
        return formValid;
    }

    // const verifyUpiId = () => {
    //     if (getDataField.upiId) {
    //         const payload = { "upi": getDataField.upiId };
    //         postRequest(`${UPI_VERIFY}`, payload, history).then((response) => {
    //             if (response.success === true) {
    //                 let record = JSON.parse(response.data.data);
    //                 let Object = { "upiName": record.data.nameAtBank }
    //                 setDataField({ ...getDataField, ...Object });
    //             } else {
    //                 toast.error(response.message);
    //             }
    //         }).catch(function (error) {
    //             console.log(error)
    //         });
    //     } else {
    //         toast.error("Please enter valid UPI ID")
    //         let Object = { "upiName": '' }
    //         setDataField({ ...getDataField, ...Object });
    //     }
    // }

    const handleSubmit = () => {
        if (FormValidate()) {
            const payload = { username: getDataField.userId, pin: getDataField.pin }
            postRequest(PROFILE_PIN_MATCH_API, payload, history).then((response) => {
                if (response.success === true) {
                    handleHideModal();
                    setUserId(response.userId);
                    toast.success(response.message);
                } else {
                    toast.error(response.message);
                }
            }).catch(function (error) {
                // console.log(error)
            })
        }
    } // eslint-disable-line react-hooks/exhaustive-deps

    const FormValidate1 = () => {
        let formValid = true;
        let formObject = {};
        setDataError({})
        if (getDataField && getDataField.length === 0) {
            formValid = false;
        }
        // console.log("------", getDataField.mobile)
        // console.log("------", typeof getDataField.mobile)
        if (getDataField.userId === "" || typeof getDataField.userId === "undefined") {
            formObject.userId = true;
            formValid = false;
        }

        setDataError(formObject);
        return formValid;
    }

    const handleSearch = () => {
        if (FormValidate1()) {
            const payload = { username: getDataField.userId }
            postRequest(SEARCH_PARTNER_LEDGER_API, payload, history).then((response) => {
                if (response.success === true) {
                    setProfileModel(response.success);
                    toast.success(response.message);
                } else {
                    toast.error(response.message);
                }
            }).catch(function (error) {
                // console.log(error)
            })
        }
    } // eslint-disable-line react-hooks/exhaustive-deps

    const handleHideModal = () => {
        setProfileModel(false);
        clearFormField();
    }

    useEffect(() => {
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            <div className="row" style={{ minHeight: "550px"}}>
                <div className="col-md-12">
                    <div className='card rounded-0 card-body'>
                        <div className='row'>
                            <div className='col-sm-6 col-md-4 col-lg-4'>
                                <div className="form-group position-relative">
                                    <label>Partner ID </label>
                                    <input type="text" className={`form-control normal-light pr-110`} name="userId" onChange={(e) => onChangeValue(e)} value={getDataField && getDataField.userId} maxLength={12} ref={userIdInput} />
                                    {getDataError && getDataError.userId && <span className="error text-danger">The field is required</span>}
                                </div>
                            </div>
                            <div className='col-sm-6 col-md-4 col-lg-4'>
                                <label></label>
                                <div className="form-group">
                                    <button type="submit" className="btn btn-primary rounded-pill pl-4 pr-4" onClick={() => handleSearch()}>Search</button>
                                </div>
                            </div>
                        </div>
                        <Divider />
                        {inUserId && <Ledger userId={inUserId} />}
                    </div>
                </div>
                <ToastContainer position="top-center" autoClose={5000} limit={2} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="colored" />
                {profileModel && <Modal title={"Profile Pin"} width={500} open={profileModel} onOk={""} onCancel={handleHideModal} footer={null}>
                    <>
                        <div className='row'>
                            <div className='col-md-12 form-group'>
                                <label>Username: { getDataField.userId}</label>
                            </div>
                            <div className='col-md-12 form-group'>
                                <label>Profile Pin: </label>
                                <input type={'text'} className="form-group form-control" onKeyPress={isNumber} name='pin' onChange={(e) => onChangeValue(e)} maxLength={6} value={getDataField.pin} />
                                {getDataError && getDataError.pin && <span className="error text-danger">The field is required</span>}
                            </div>
                            
                            <div className='col-md-12 form-group text-right'>
                                <button type='button' onClick={() => handleSubmit()} className='btn btn-sm btn-primary'>Submit</button>
                            </div>
                        </div>
                    </>
                </Modal>}
            </div>
        </div>
    )
}

export default PartnerLedger