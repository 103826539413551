export const prepaidReports = [{
    "name": "Mobile Report",
}, {
    "name": "DTH Report",
}, {
    "name": "Wallet Report",
}];

export const uitilityReports = [{
    "name": "Eletricity Report",
}, {
    "name": "Water Report",
}, {
    "name": "Gas Report",
}, {
    "name": "Insurance Report",
}];

export const addServiceAndOparetor = [{
    "key" : "MS",
    "value" : "Mobile Service",
}, {
    "key" : "DS",
    "value" : "DTH Service",
}, {
    "key" : "FS",
    "value" : "Fastag Service",
}, {
    "key" : "WS",
    "value" : "Wallet Service",
}, {
    "key" : "ES",
    "value" : "Eletricity Service",
}, {
    "key" : "WTS",
    "value" : "Water Service",
}, {
    "key" : "GS",
    "value" : "Gas Service",
}, {
    "key" : "IS",
    "value" : "Insurance Service",
}];


export const bankModes = ["Cash", "CDM", "IMPS/NEFT/RTGS", "Cheque", "E-Collect", "UPI Transfer"];