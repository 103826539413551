import { useState, useEffect } from 'react';
// import axios from 'axios';
import { APP_INFO, EMPTY, POST_DMT_CHECK_SERVICE_TO_MOBILENUMBER } from './../../Utils/appConstants';
import { isNumber } from '../../Utils/Common';
import { useHistory } from "react-router-dom";
import PropagateLoader from "react-spinners/PropagateLoader";
import { postRequest } from '../../Utils/AppApi';

import InstantPay from '../partnerEKYC/instantPay';
import MoneyTransfer from '../moneyTransfer/landing';
import MoneyTransferV2 from '../moneyTransferV2/index';
import MoneyTransferV3 from '../moneyTransferV3/index';
import { toast } from 'react-toastify';
import { Button, Space, Table } from 'antd';
import { reactLocalStorage } from 'reactjs-localstorage';

const IndexComponent = () => {
    const history = useHistory();
    const [isLoading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState(null);
    const [isCustomerService, setCustomerService] = useState(null);
    const [searchButton, setSearchButton] = useState(true);

    const [appData, setAppData] = useState(null);
    const [isSrpayAvailableLimit, setSrpayAvailableLimit] = useState(null);

    const [isDmt, setDmt] = useState(false);
    const [isDmt1, setDmt1] = useState(false);
    const [isDmt2, setDmt2] = useState(false);
    const appLocalData = reactLocalStorage.get(APP_INFO, EMPTY, true)
    
    useEffect(() => {
        // console.log("AppLocalData----", appLocalData)
        if(appLocalData) {
            setAppData(JSON.parse(appLocalData))
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const handleSearch = (e) => {
        // console.log("searchText----",e.target.value)
        if (e.target.value.length === 10) {
            setSearchButton(false)
        } else {
            setCustomerService(null)
            setSearchButton(true)
        }
        setDmt(false)
        setDmt1(false)
        setDmt2(false)
        setSearchText(e.target.value);
    }

    const getCustomerInfo = async () => {
        setDmt(false)
        setDmt1(false)
        setDmt2(false)
        setLoading(true)
        if (!searchText) {
            toast.error("Please enter a Customer Number");
            setLoading(false)
            return false;
        } else if (searchText.length !== 10) {
            toast.error("Please enter a valid Mobile Number");
            setLoading(false)
            return false;
        }
        let payload = { "customerMobile": searchText }
        await postRequest(POST_DMT_CHECK_SERVICE_TO_MOBILENUMBER, payload, history).then((response) => {
            if (response.success === true) {
                // console.log("----------------", response.data);
                setSrpayAvailableLimit(response.srpayPayAvailableLimit)
                setCustomerService(response.data);
                // console.log("----------------", response.data);
            } else {
                // setCustomerNotExist(true);
                // console.log("----------------", response.success);
            }
            setLoading(false)
        }).catch(function (error) {
            console.log(error)
            setLoading(false);
        })
    }

    const columns = [
        {
            title: 'Service Name',
            dataIndex: 'serviceName',
            key: 'serviceName',
            render: (_, record) => (
                <><div className="text-danger">{record.serviceName}</div><sub className="text-semi-bold text-success">{record.detail}</sub></>
            )
        },
        {
            title: 'Status',
            dataIndex: 'customerStatus',
            key: 'customerStatus',
            render: (_, record) => (
                <><div className={record.customerStatus === "Registered" ? "text-success" : "text-danger" }>{record.customerStatus}</div></>
            )
        },
        {
            title: 'Service Status',
            dataIndex: 'serviceStatus',
            key: 'serviceStatus',
            render: (_, record) => (
                <><div className={record.serviceStatus === "Available" ? "text-success" : "text-danger" }>{record.serviceStatus}</div></>
            )
        },
        {
            title: 'Provider Limit',
            key: 'remainingLimit',
            dataIndex: 'remainingLimit'
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <Button onClick={() => handleSubmit(record)} disabled={record.serviceStatus !== "Available"} className={record.serviceStatus === "Available" ? "btn bg-primary border-radius" : "btn bg-default"}>GO</Button>
                </Space>
            ),
        },
    ];
    const data = isCustomerService;

    const handleSubmit = (data) => {
        console.log("data ---------", data)
        if(data) {
            setCustomerService(null);
            switch (data.code) {
                case "DMT": setDmt(true); break;
                case "DMT1": setDmt1(true); break;
                case "DMT2": setDmt2(true); break;
                default: break;
            }
        }
    }
    if (isLoading) {
        return <div className="content text-center" style={{ margin: "250px 0" }}>
            <PropagateLoader color="#5851D2" loading={isLoading} size={25} />
        </div>
    }
    return (
        <div>
            <div className="">
                <div className="">
                    <div className='row mb-3'>
                        <div className="input-group">
                            <div className="col-md-2"></div>
                            <div className="col-md-4">
                                <input className="form-control py-2 border" type="search" defaultValue={searchText} value={searchText} onKeyDown={isNumber} placeholder='Search ...' maxLength={10} onChange={(e) => handleSearch(e)} />
                            </div>
                            <button onClick={() => getCustomerInfo()} className="btn btn-primary ml-3 border-left-0 border" disabled={searchButton} type="button">
                                <i className="fa fa-search" /> Search
                            </button>
                        </div>
                    </div>
                </div>
                {(appData && isCustomerService && isCustomerService.length > 0 && appData.dmtTransactionLimit) && <div className="text-right mr-5 mb-1 text-default">Available Limit Rs: <span className="blink_text text-danger">{isSrpayAvailableLimit}</span></div>}
                { isCustomerService && isCustomerService.length > 0 && <><Table className="table" columns={columns} dataSource={data} size="small" />
                <p className="text-center">एक महीने में आप सभी विकल्पों से केवल Rs. 25000 / Customer ही ट्रांसफर कर सकते हैं!</p></>}
                {isDmt && <><MoneyTransfer mobile={searchText} /></> }
                {isDmt1 && <><InstantPay /><MoneyTransferV2 mobile={searchText} /></> }
                {isDmt2 && <MoneyTransferV3 mobile={searchText}/> }
            </div>
        </div>
    )
}

export default IndexComponent;