import React, { useEffect, useState } from 'react';
// import { CSVLink } from 'react-csv';
import DataTable from 'react-data-table-component';
import { POST_PAYOUT_GET_TRANSACTION_HISTORY, POST_PAYOUT_GET_TRANSACTION_INQUIRY } from './../../Utils/appConstants';
import { dmtDetailStatus } from './../../Utils/Common';
import { getRequest, postRequest } from './../../Utils/AppApi';
import { useHistory } from "react-router-dom";
import { toast } from 'react-toastify';
import PropagateLoader from "react-spinners/PropagateLoader";
import PayoutInvoice from "../../container/payoutInvoice";
import { Button, DatePicker, Modal, Tooltip } from 'antd';
import { getUTCToLocalDateTimeFormat, queryDate, toDayDate } from '../../Utils/function';

function PayoutHistory(props) {
    const history = useHistory();
    // const csvLink = useRef()
    const limit = 10;
    const dateFormat = 'DD-MM-YYYY';

    const [searchAccountNumber, setSearchAccountNumber] = useState(null);
    const [searchTransId, setSearchTransId] = useState(null);
    const [searchCustomerId, setSearchCustomerId] = useState(null);

    const [isLoading, setLoading] = useState(false);
    const [endDate, setEndDate] = useState(toDayDate());
    const [startDate, setStartDate] = useState(toDayDate());
    const [getData, setData] = useState([]);
    // const [getCsvData, setCsvData] = useState([]);
    const [getTotalRecord, setTotalRecord] = useState(0);
    // const [refundData, setRefundData] = useState(null);
    const [page, setPage] = useState(1);
    const [isLimit, setLimit] = useState(limit);
    const [userId, setUserId] = useState(props.userId);
    // const [isOtp, setOtp] = useState(null);
    // const [isOtpModal, setOtpModal] = useState(false);

    const [invoiceDataModal, setInvoiceDataModal] = useState(false);
    const [invoiceData, setInvoiceData] = useState(null);

    const columns = [{
        name: 'Date Time',
        cell: row => getUTCToLocalDateTimeFormat(row.createdAt),
        width: "200px",
    }, {
        name: 'Trans ID',
        selector: row => <Tooltip placement="bottom" title={row.transactionId}>{row.transactionId}</Tooltip>,
        width: "180px",
    }, {
        name: 'Customer ID',
        selector: row => <Tooltip placement="bottom" title={row.payoutcustomers.customerMobile}>{row.payoutcustomers.customerMobile}</Tooltip>,
        width: "130px",
    }, {
        name: 'Bank Name',
        selector: row => row.bankName,
        width: "220px",
    }, {
        name: 'IFSC Code',
        selector: row => row.ifsccode,
        width: "120px",
    }, {
        name: 'Account Number',
        selector: row => row.accountNumber,
        width: "170px",
    }, {
        name: 'Bene Name',
        selector: row => row.baneName,
        width: "190px",
    }, {
        name: 'Amount',
        selector: row => row.amount,
        width: "100px",
    }, {
        name: 'Status',
        selector: row => !row.txtStatus ? "Initiated" : dmtDetailStatus(row.txtStatus),
    }, {
        name: 'Action',
        selector: row => <><button type="button" className='btn btn-sm border border-success text-success' onClick={() => handleInvoice(row)} title='Print Invoice'><i class="fa fa-print" aria-hidden="true"></i></button> {row.txtStatus === null && <Button className="ml-3 btn btn-warning bg-warning" onClick={() => transactionInquiry(row)} size={"small"}>INQUIRY</Button>} </>,
        width: "250px",
    }]

    const handleInvoice = (data) => {
        setInvoiceDataModal(true);
        setInvoiceData(data);
    }

    const objectToQueryString = (obj) => {
        var str = [];
        for (var p in obj)
            if (obj.hasOwnProperty(p)) {
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
        return str.join("&");
    }

    const params = { page: page, limit: isLimit };

    const getDataReports = (data) => {
        if (data) {
            setLimit(data)
            params.limit = data;
        }
        if (startDate && endDate) {
            params.startDate = startDate ? queryDate(startDate) : "";
            params.endDate = endDate ? queryDate(endDate) : "";
        }

        if (props.userId) {
            params.userId = userId ? userId : props.userId;
        }

        params.searchTransId = searchTransId ? searchTransId : "";
        params.searchAccountNumber = searchAccountNumber ? searchAccountNumber : "";
        params.searchCustomerId = searchCustomerId ? searchCustomerId : "";

        const queryString = objectToQueryString(params);
        getRequest(`${POST_PAYOUT_GET_TRANSACTION_HISTORY}?${queryString}`, history).then((response) => {
            if (response.success === true) {
                setData(response.data);
                setTotalRecord(response.data.totalDocs);
                setPage(response.data.page);
            } else {
                setData([]);
                setTotalRecord(0);
                setPage(1);
            }
        }).catch(function (error) {
            console.log(error)
            // setLoading(false);
        })
    } // eslint-disable-line react-hooks/exhaustive-deps

    // const calculateTimeDifference = (row) => {
    //     const targetTime = new Date(getUTCToLocalDateTimeFormat(row.createdAt)).getTime(); // "29 Oct 2023, 04:39 PM" in milliseconds
    //     const currentTime = new Date().getTime();

    //     const differenceInMinutes = Math.floor((currentTime - targetTime) / (60 * 1000));

    //     return differenceInMinutes;
    // };

    const transactionInquiry = (data) => {
        // console.log("data -------------", data.parentTransactionId)
        setLoading(true)
        const payload = {
            transactionId: data.transactionId,
        }
        postRequest(`${POST_PAYOUT_GET_TRANSACTION_INQUIRY}`, payload, history).then((response) => {
            if (response.success === true) {
                toast.success(response.message);
                getDataReports();
            } else {
                toast.error(response.message);
            }
            setLoading(false);
        }).catch(function (error) {
            // console.log(error)
            setLoading(false);
        })
    }

    // const refundTransaction = (data) => {
    //     // console.log("data -------------", data.parentTransactionId)
    //     setRefundData(data);
    //     setOtpModal(true);
    // }

    // const handleRefundTransaction = () => {
    //     setLoading(true)
    //     const payload = {
    //         transactionId: refundData.transactionId,
    //         otp: isOtp
    //     }
    //     postRequest(`${POST_DMT_REFUND_TRANSACTION}`, payload, history).then((response) => {
    //         if (response.success === true) {
    //             toast.success(response.message);
    //             getDataReports();
    //         } else {
    //             toast.error(response.message);
    //         }
    //         setLoading(false);
    //     }).catch(function (error) {
    //         // console.log(error)
    //         setLoading(false);
    //     })
    // }

    const handlePageChange = (page, size) => {
        // console.log("-----", size)
        setPage(page);
    };

    const onChangeStartDate = (date, dateString) => {
        // console.log(date, dateString);
        setStartDate(date)
    };

    const onChangeEndDate = (date, dateString) => {
        // console.log(date, dateString);
        setEndDate(date)
    };

    const handleHideModal = () => {
        // setOtpModal(false);
        setInvoiceDataModal(false);
    }

    const handleSearch = () => {
        getDataReports();
    }

    const handleClear = () => {
        setStartDate('');
        setEndDate('');
        setSearchAccountNumber('');
        setSearchTransId('');
        setSearchCustomerId('');
    }

    useEffect(() => {
        if(searchAccountNumber || searchCustomerId || searchTransId) {
            handleSearch();
        }
    }, [searchAccountNumber, searchCustomerId, searchTransId]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (props.userId) {
            setUserId(props.userId)
        }
        getDataReports();
    }, [page]) // eslint-disable-line react-hooks/exhaustive-deps
    // trans id | IFSC code | AC | AMT | BANK RRN | Status
   
    if (isLoading) {
        return <div className="content text-center" style={{ margin: "250px 0" }}>
            <PropagateLoader color="#5851D2" loading={isLoading} size={25} />
        </div>
    }

    
    // const BootyCheckbox = React.forwardRef((event, data, row) => (
    //     <div className="form-check">
    //         {console.warn("row ---------", row)}
    //       <input
    //         htmlFor="booty-check"
    //         type="checkbox"
    //         className="form-check-input pointer"
    //         onClick={(e) => onClick(e.target.value)}
    //       />
    //       <label className="form-check-label" id="booty-check" />
    //     </div>
    //   ));

    // const selectProps = (event) => { console.log("isIndeterminate--1--------------", event) };
    // // const selectProps = (data) => { console.log("data--1--------------", data) };

    // const rowDisabledCriteria = row => row.txtStatus;

    return (
        <div className="">
            <div className="content p-0">
                <div className="container-fluid">
                    <div className='card'>
                        <div className='card-body'>
                            <div className="row">
                                <div className='col-md-12 date-search mb-0'>
                                    <div className='row'>
                                        <div className='col-md-4 col-lg-4 d-flex'>
                                            <div className="form-group mr-3">
                                                <label className='date-search-label mb-0 text-blue'>From</label>
                                                <DatePicker className="form-control form-control-border" value={startDate} format={dateFormat} onChange={onChangeStartDate} />
                                            </div>
                                            <div className="form-group">
                                                <label className='date-search-label mb-0 text-red'>To</label>
                                                <DatePicker className="form-control form-control-border" value={endDate} format={dateFormat} onChange={onChangeEndDate} />
                                            </div>
                                        </div>
                                        <div className='col-md-2 col-lg-2'>
                                            <div className="form-group">
                                                <label className='date-search-label mb-0 text-red'></label>
                                                <input type="text" className="form-control form-control-border" placeholder="Trans ID" value={searchTransId} onChange={(e) => setSearchTransId(e.target.value)} />
                                            </div>
                                        </div>
                                        <div className='col-md-2 col-lg-2'>
                                            <div className="form-group">
                                                <label className='date-search-label mb-0 text-red'></label>
                                                <input type="text" className="form-control form-control-border" placeholder="A/C Number" value={searchAccountNumber} onChange={(e) => setSearchAccountNumber(e.target.value)} />
                                            </div>
                                        </div>
                                        <div className='col-md-2 col-lg-2'>
                                            <div className="form-group">
                                                <label className='date-search-label mb-0 text-red'></label>
                                                <input type="text" className="form-control form-control-border" placeholder="Customer ID" value={searchCustomerId} onChange={(e) => setSearchCustomerId(e.target.value)} />
                                            </div>
                                        </div>
                                        <div className='col-md-1 col-lg-1'>
                                            <div><label className='date-search-label mb-0 text-red'>&nbsp;</label></div>
                                            <button type="submit" className="btn btn-primary rounded-pill px-3" onClick={() => handleSearch()}>Search</button>
                                        </div>
                                        <div className='col-md-1 col-lg-1'>
                                            <div><label className='date-search-label mb-0 text-red'>&nbsp;</label></div>
                                            <button type="submit" className="btn btn-default rounded-pill px-4" onClick={() => handleClear()}>Clear</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="container-fluid pt-2 pb-5">
                                <DataTable
                                    title={""}
                                    columns={columns}
                                    data={getData.docs}
                                    defaultSortFieldID={1}
                                    highlightOnHover
                                    pagination
                                    paginationServer
                                    // expandableRows
                                    // expandableRowsComponent={ExpandableComponent}
                                    // selectableRows
		                            // selectableRowDisabled={rowDisabledCriteria}
                                    // selectableRowsComponent={BootyCheckbox}
                                    // selectableRowsComponentProps={selectProps}
                                    paginationTotalRows={getTotalRecord}
                                    onChangeRowsPerPage={getDataReports}
                                    onChangePage={handlePageChange}
                                />
                            </div>
                        </div>
                    </div>
                    {invoiceDataModal && <Modal open={invoiceDataModal} width={800} footer={false} onCancel={handleHideModal} closable={false}>
                        <PayoutInvoice transactionId={invoiceData.transactionId} />
                    </Modal>}
                </div>
            </div>
        </div>
    )
}

export default PayoutHistory