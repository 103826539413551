import React from 'react';
import './index.css'; // Import CSS file for spinner styling

const Spinner = () => {
  return (
    <div className="spinner">
      <img src="./spinner/spinner.gif" alt="Spinner" width={"40%"}/>
    </div>
  );
}

export default Spinner;
